import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  open:boolean;
  onClose:()=>void;
  t:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  animationKey:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End



export default class ChallengeShuffleModalController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            animationKey:1
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start
    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
     if(prevProps.open!== this.props.open) { 
      if(this.props.open){
         this.setState((prev)=>({
            animationKey: prev.animationKey+1
         }))
       }
      }
    }
    // Customizable Area End
}