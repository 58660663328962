// Customizable Area End
// Customizable Area Start
import  { TFunction } from "i18next";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area End

// Customizable Area Start
export type IList={
  
    option:string,
    optionData: string,
    time: string
}
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  id: string;
  // Customizable Area Start
 t:TFunction
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start

  dailyGoal: string;
  dailyGoalData: Array<IList>;
  filterDailyGoalData:Array<IList>;
  dailyGoalFlag: boolean;
  dailyGoalSelected: string;

  streakGoal: string;
  streakGoalData: Array<IList>;
  filterStreakGoalData:Array<IList>;
  streakGoalFlag: boolean;
  streakGoalSelected: string;

  search: string;
  selectValue: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FirstLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  firstLoginAPICallId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      dailyGoal: "",
      dailyGoalSelected: "",
      streakGoalSelected: "",
      filterDailyGoalData:[],
      dailyGoalData: [
        {
          option: "Super easy",
          optionData: "super_easy",
          time: `1 ${this.props.t("lesson")}`,
        },
        {
          option: "Easy",
          optionData: "easy",
          time: `2 ${this.props.t("lessons")}`,
        },
        {
          option: "Really committed",
          optionData: "really_committed",
          time:  `3 ${this.props.t("lessons")}`,
        },
        {
          option: "Totally dedicated",
          optionData: "totally_dedicated",
          time:  `4 ${this.props.t("lessons")}`,
        },
      ],
      dailyGoalFlag: false,

      streakGoal: "",
      filterStreakGoalData:[],
      streakGoalData: [
        {
          option: "Easy",
          optionData: "easy",
          time: `2 ${this.props.t("days")}`,
        },
        {
          option: "Dedicated",
          optionData: "dedicated",
          time: `4 ${this.props.t("days")}`,
        },
        {
          option: "Super dedicated",
          optionData: "super_dedicated",
          time: `7 ${this.props.t("days")}`,
        },
        {
          option: "Wow! look at you",
          optionData: "wow_look_at_you",
          time: `10 ${this.props.t("days")}`,
        },
      ],
      streakGoalFlag: false,

      search: "",
      selectValue: "",

      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
  // Customizable Area Start
  componentDidMount=async()=>{
    this.setState({
      filterDailyGoalData:this.state.dailyGoalData,
      filterStreakGoalData:this.state.streakGoalData
    })
 }
  handleSubmit = () => {
    if (this.state.dailyGoal === "" || this.state.streakGoal === "") {
      return
    } else {
      this.props.navigation.navigate("FirstLoginSelectAvatar", {
        dailyGoal: this.state.dailyGoalSelected,
        streakGoal: this.state.streakGoalSelected,
      });
    }
  };

  handleSearchDailyGoal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredData = this.state.filterDailyGoalData.filter((item) => {
      return item.option.includes(event.target.value);
    });
    this.setState({ dailyGoalData: filteredData });
  };

  handleSearchSteakGoal = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const filteredData = this.state.filterStreakGoalData.filter((item) => {
      return item.option.includes(event.target.value);
    });
    this.setState({ streakGoalData: filteredData });
  };

  handleDailyOpenDropDown = () => {
    this.setState({
      dailyGoalFlag: !this.state.dailyGoalFlag,
      streakGoalFlag: false,
      search: "",
    });
  };
  handleSteakOpenDropDown = () => {
    this.setState({
      streakGoalFlag: !this.state.streakGoalFlag,
      dailyGoalFlag: false,
      search: "",
    });
  };

  handleDailyGoalSelectOption = (item:IList) => {
    this.setState({
      dailyGoal: item.option,
      dailyGoalSelected: item.optionData,
      dailyGoalFlag: false,
      search: "",
    });
  };
  handleStreakGoalSelectOption = (item: IList) => {
    this.setState({
      streakGoal: item.option,
      streakGoalSelected: item.optionData,
      streakGoalFlag: false,
      search: "",
    });
  };
  handleBackFirstlogin = () => {
    this.props.navigation.navigate("EmailLogin");
  };
 
  // Customizable Area End
}
