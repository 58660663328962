// Customizable Area Start
import React from 'react'
import { Typography, Box, styled } from '@material-ui/core'
import GuestExerciseController, { Props } from './GuestExerciseController'
import Header from '../../../components/src/Header'
import { loader } from './assets'
import { withTranslation } from 'react-i18next'
import SingleChoiceExercise from '../../../components/src/MicrolessonExercise/SingleChoiceExercise'
import MultipleChoiceExercise from '../../../components/src/MicrolessonExercise/MultipleChoiceExercise'
import MatchingExercise from '../../../components/src/MicrolessonExercise/MatchingExercise'
import FillBlanksExercise from '../../../components/src/MicrolessonExercise/FillBlanksExercise'
import SequenceExercise from '../../../components/src/MicrolessonExercise/SequenceExercise'
import ProgressBar from '../../cftableforlessons/src/ProgressBar'

class GuestExercise extends GuestExerciseController {
    constructor(props: Props) {
        super(props)
    }

    handleGoBack = () => {
        this.props.navigation.goBack()
    }

    submitExerciseAnswer = ({ correct }: { correct: boolean }) => {
        if (correct) {
            this.setState((prev) => {
                return { guestXP: prev.guestXP + 10 }
            })
        }
    }

    handleClickContinue = () => {
        if (this.state.questionIndex === this.state.exerciseData.length - 1) {
            this.props.navigation.navigate('GuestScore', {
                guestXP: this.state.guestXP
            })
            return
        }
        this.setState({ questionIndex: this.state.questionIndex + 1 })
    }

    renderMainContent = () => {
        const QuestionList = {
            TRUE_FALSE: SingleChoiceExercise,
            MULTIPLE_CHOICE: MultipleChoiceExercise,
            MATCHING: MatchingExercise,
            FILL_IN_BLANK: FillBlanksExercise,
            SEQUENCE: SequenceExercise,
        }

        const currentQuestion = this.state.exerciseData?.[this.state.questionIndex]
        const Component =
            QuestionList[
            currentQuestion?.attributes?.questionType as keyof typeof QuestionList
            ]

        if (this.state.isLoading) {
            return (
                <Box className='loadingWrapper'>
                    <img src={loader} alt="Loading" />
                </Box>
            )
        }

        if (this.state.exerciseData.length === 0) {
            return (
                <Box className='loadingWrapper'>
                    <Typography className='noChallengeText'>{this.props.t("There is no exercise.")}</Typography>
                </Box>
            )
        }

        return (
            <Component
                key={this.state.questionIndex}
                {...currentQuestion}
                onClickContinue={this.handleClickContinue}
                exerciseAnswer={undefined}
                isGuest
                onSubmitExerciseAnswer={this.submitExerciseAnswer}
                lessonId=''
                microLessonId={1}
                onUpdateXP={(token: string) => {}}
                shareRecordType='EXERCISE'
            />
        )
    }

    render() {
        return (
            <GuestExerciseContainer>
                <Header showGoBackBtn onGoBack={this.handleGoBack} />
                <Box style={{ width: '100%', height: '100%', maxWidth: '960px', margin: '0 auto', display: 'flex', flexDirection: 'column' }}>
                    <Box className='progressBarWrapper'>
                        <ProgressBar
                            currentLessonNumber={this.state.questionIndex + 1}
                            totalLessonCount={this.state.exerciseData.length}
                            number={this.state.guestXP}
                            isHideCloseBtn
                        />
                    </Box>
                    <Box style={{ width: '100%', height: '100%', flex: 1 }}>
                        {this.renderMainContent()}
                    </Box>
                </Box>
            </GuestExerciseContainer>
        )
    }
}


export default withTranslation()(GuestExercise)

const GuestExerciseContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',

    '& .loadingWrapper': {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    '& .noChallengeText': {
        fontSize: '20px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        color: '#363636',
    },

    '& .progressBarWrapper': {
        padding: '24px 26px',
        [theme.breakpoints.up('md')]: {
            padding: '24px 60px',
        },
    },

}))
// Customizable Area End
