import React from "react";
// Customizable Area Start
import Trophies from "../../landingpage/src/Trophies.web";
import Goal from "../../landingpage/src/Goal.web";
import { Box, Typography, styled, Backdrop, Button, IconButton } from "@material-ui/core";
import NavigateLayout from "../../../components/src/NavigateLayout";
import CenterCard from "../../../components/src/CenterCard.web";
import { groupDecoration, skipTeddy, groupMultilevel, groupscroll, buttonMultilevel, groupAvtar, imageFlag, buttonNotification, rectangle, groupMaskMath } from '../../landingpage/src/assets';
import MainMenuControllerWeb from "./MainMenuController.web"
import { group_edit, skip_challengesIcon, skip_closedbook } from "./assets";
import { withTranslation } from "react-i18next";
// import Maths from "./Math.web";
// Customizable Area End

class MainMenu extends MainMenuControllerWeb {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {t} = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Menu">
        <SettingContainer>
          <Box className="mainContentWrapper">
            <Box className="settingBackground">
              <Box className="settingImgWrapper" 
              style={this.state.firstLogin ? {} : { backgroundImage: `url(${groupMaskMath})` }}>
                <Box className="nomargin">
                  <CenterCard 
                  data-testid="centerCard"
                  Topic={this.state.topicName}
                   Unit={this.state.unitName} 
                   openDrawer={this.state.openDrawer} 
                   trophiesOpen={this.state.trophiesOpen} 
                   goalsOpen={this.state.goalsOpen} 
                   handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
                    navigation={this.props.navigation} />
                  <Trophies 
                  data-testid="trophies"
                  stateOpen={this.state.trophiesOpen} 
                  drawerOpen={this.state.drawerSide} 
                  closeDrawer={this.closeDrawer} />
                  <Goal 
                  data-testid="goal"
                  stateOpen={this.state.goalsOpen} 
                  drawerOpen={this.state.drawerSide} 
                  closeDrawer={this.closeDrawer} />
                </Box>
                <MenuContainer>
                  <Box className="ButtonGroup">
                  <Box className="LessonBox">
                    <Button 
                    onClick={()=>this.handleLessonNavigation()}
                     variant="contained"
                     className="btn LessonButton"
                     startIcon={<IconButton 
                     component="span" 
                     className="icon_btn">
                          <img
                            src={group_edit}
                            style={{ width: "24px", height: "24px" }}
                            />
                     </IconButton>}
                     >
                      {t("Lessons")}
                    </Button>
                    </Box>
                    <Box className="ChallengeBox">
                    <Button 
                    onClick={()=>this.handleChallengeNavigation()}
                    variant="contained" 
                    data-testid = "challenge-btn"
                    className="btn ChallengeButton"
                    startIcon={<IconButton component="span"  className="icon_btn">
                      <img
                        src={skip_challengesIcon}
                        style={{ width: "24px", height: "24px" }}
                        />
                 </IconButton>}
                    >
                      {t("Challenges")}
                    </Button>
                    </Box>
                    <Box className="MaterialBox">
                    <Button 
                    onClick={()=>this.handleMaterialNavigation()}
                    variant="contained" 
                    data-testid = "material-btn"
                    id={"material-btn"} 
                    className="btn MaterialButton"
                    startIcon={<IconButton component="span" className="icon_btn">
                      <img
                        src={skip_closedbook}
                        style={{ width: "24px", height: "24px" }}
                        />
                    </IconButton>}
                    >
                      {t("Materials")}
                    </Button>
                    </Box>
                  </Box>
                </MenuContainer>
              </Box>
              <Box className={
                this.state.isScrolled ? "showblock" : "hiddenBlock"}>
                <Box className="scrollBox" >
                  <Box className="iconContainerscroll">
                    <img  width="51px" src={groupAvtar}/>
                    <Box>
                      <Box className="scrollboxtitlewrapper">
                        <img width="16px" src={groupMultilevel} />
                        <Typography >Algebra</Typography>
                      </Box>
                      <Typography className="scrollboxdetail" >
                        Unit 1: Set of Real Numbers
                        </Typography>
                    </Box>
                  </Box>
                  <Box className="languageButton1">
                    <img src={imageFlag} height="22px" width="31px"  />
                    <img src={buttonNotification} height="24px" width="20.6px"/>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="mainLanding">
             
            </Box>
          {/* <Maths navigation={undefined} id={""} mathOpen={this.state.mathOpen} closeDrawer={this.closeDrawer}/> */}
          </Box>
        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    );
  }
}

export default withTranslation() (MainMenu)
// Customizable Area Start

export const MenuContainer = styled(Box)(({ theme }) => ({
  width: "313px",
  display: 'flex',
  position:'relative',
  margin: 'auto',
  padding:"5px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.10)",
  justifyContent: 'center',
  alignItems: 'center',
  zIndex:1,
  height: "404px",
  background: 'var(--primary-blue-blue-primary-light, #C9EEFF)',
  border: '5px solid var(--primary-neutrals-warm-gray-10, #F7F7F7)',
  borderRadius: 45,
  '&::before':{
    top: "120px",
    left: "-12px",
    content:'""',
    position: "absolute",
    border: "6px solid #f0efec",
    display: "inline-block",
    padding: "5px",
    borderRadius:"6px",
    transform: "rotate(136deg)",
    "@media(max-width: 960px)": {
      left: 'calc(50% - 6px)',
      top:"calc(99% - 4px)",
      position: "absolute",
      border: "5px solid rgb(255 255 255 / 78%)",
      display: "inline-block",
      padding: "4px 6px 6px 4px",
      boxShadow:"rgb(0 0 0 / 9%) -3px -4px 2px 0px",
      borderRadius:"5px",
      transform: "rotate(225deg)",
    },
  },
  '&::after':{
    top: "121px",
    content: '""',
    left: "-7.5px",
    backgroundColor:"c9eeff",
    position: "absolute",
    border: "5.2px solid #c9eeff",
    borderWidth: "7px 0px 0px 7px",
    display: "inline-block",
    padding: "6.75px",
    borderRadius:"6px",
    transform: "rotate(313deg)",
    "@media(max-width: 960px)": {
      left: 'calc(50% - 6px)',
      top:'calc(99% - 8px);',
      position: "absolute",
      border: "5px solid #c9eeff",
      borderWidth: "7px 0px 0px 7px",
      display: "inline-block",
      padding: "4px 6px 6px",
      borderRadius:"5px",
      transform: "rotate(225deg)",
    },
  },
  "@media(max-width: 960px)": {
    top: '112%',
    width:"68%",
    // border:'',
    position: 'absolute',
  },
  "@media(max-width: 760px)": {
    top: '120%',
    width:'80%'
  },
  '& .ButtonGroup': {
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
  },
  
  '& .btn': {
    height: 70,
    width: 225,
    color: "var(--basic-white, #FFF)",
    fontFamily: "Nunito",
    fontSize: "16px",
    textTransform:'uppercase',
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
  },

  '& .btn > .MuiButton-label': {
    height: 70,
    width: 225,
    color: "var(--basic-white, #FFF)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    justifyContent:"unset",
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
  },

  '& .LessonButton': {
    borderRadius: "12px",
    border: "2px solid var(--primary-blue-blue-shadows, #00AEFF)",
    background: " var(--primary-blue-blue-primary-dark, #36BFFF)"
  },


  '& .ChallengeBox':{
    marginTop:24,
    padding:10,
  },
  '& .ChallengeBox > button':{
     boxShadow: '0px 4px 1px 1px #25B078'
  },
 
  '& .LessonBox:hover ':{
    marginTop:22,
    borderRadius: "19px",
    border: "2px solid rgb(98 205 255 / 52%)",
  },

  '& .LessonBox > button':{
    boxShadow: '0px 4px 1px 1px #00AEFF'
 },
 

  '& .LessonBox':{
    marginTop:24,
    padding:10,
  },
  '& .MaterialBox':{
    marginTop:24,
      padding:10,
  },
  '& .MaterialBox > button':{
    boxShadow: '0px 4px 1px 1px #F17D01'
 },

  '& .ChallengeButton': {
    borderRadius: "12px",
    border: "2px solid #25B078",
    background: "var(--secondary-green, #2BCE8C)"

  },
  '& .MaterialButton': {
    borderRadius: '12px',
    border: "2px solid #F17D01",
    background: "var(--secondary-orange, #FF8400)"
  },

  '& .icon_btn':{
    margin:"0px 8px",
    background:"rgba(255, 255, 255, 0.70)",
    width:"44px",
    height:"44px"
  },
})
)

export const Multilevel: React.ComponentType = styled(Box)(({ theme }) => ({
  alignItems: "center",
  gap: '15px',
  display: "flex",
  "@media(max-width: 959px)": {
    top: '33%',
    position: 'absolute',
  },
  "& .MultilevelButton": {
    "& .MuiButton-label": {
      borderRadius: '100%',
      border: '1px solid #fff',
      width: '50px',
      height: '50px',
      background: '#62CDFF',
    }
  },
  "& .subjectName_1": {
    fontWeight: 900,
    lineHeight: "21px",
    color: '#fff',
    fontSize: "20px",
    fontStyle: "normal",
  }
}))
export const SettingContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: 'rgb(255 255 255 / 80%)',
  "@media(max-width: 959px)": {
    backgroundColor: '#fff'
  },
  '& .showblock': {
    position:'relative',
    zIndex:3,
    display: 'block'
  },
  '& .nomargin': {
    width: '100% !important',
    "@media(min-width: 960px)": {
      display:'none',
  }
  },
  '& .mainWhiteBox > .ractangleBlock':{
   background: "rgb(98, 205, 255 0.3)",
   opacity:0.3
  },

  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 ':{
    backgroundColor:' #62CDFF !important'
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 > .rectangleBlock':{
    backgroundColor:' #62CDFF !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox':{
    backgroundColor:' #62CDFF !important',
    '@media(max-width:960px)':{
      backgroundColor:' #FFF !important',
    }
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle':{
    backgroundColor:' #00AEFF !important'
  },
  "& .rightcard-inner1":
  {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: ' var(--primary-neutrals-warm-gray-10, #F7F7F7)',
    backgroundImage: `url(${groupDecoration})`,
    "@media(max-width: 959px)": {
      width: '100%',
      height: '585px'
    }
  },
  "& .rightcard-inner":
  {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: '#C9EEFF',
    "@media(max-width: 959px)": {
      width: '100%',
      height: '585px'
    }
  },
  "& .login-btn": {
    color: "white",
    textAlign: "center",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.8px",
    backgroundColor: '#36BFFF',
    width: '260px',
    textTransform: 'none',
    height: '44px',
    boxShadow: '0px 4px #00AEFF',
    borderRadius: '16px',
    marginTop: '35px',
    "@media (max-width:600px)": {
        width: '260px',
        fontSize: '16px'
    },
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage':{
    opacity:1,
    '@media(max-width:960px)':{
      opacity:' 0.3 !important',
    }
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle > .units':{
    backgroundColor:' #00AEFF !important',
    color : '#fff',
    opacity:0.9,
   },

  '& .mainWhiteBox > .viewRectangle':{
    background: "rgb(rgb(0, 174, 255 0.3)",
    opacity:0.3
   },
  '& .hiddenBlock': {
    display: 'none'
  },
  '& .settingBackground': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .backdrop': {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  '& .mainLanding': {
    flex: 6,
    backgroundColor: '#C9EEFF',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    "@media(max-width: 959px)": {
      width: '100%',
    }
  },
  '& .mainLanding > .MuiBox-root > .contentContainer':{
    "@media(max-width: 959px)": {
      overflowY:'hidden',
    }

  },

  '& .iconContainerscroll': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)',
    height: '65px',
    display: 'flex',
    alignItems: "center",
    gap: '15px',
    justifyContent: 'center',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    marginTop: "60px",
    padding: "10px",
  },
  '& .scrollBox': {
    alignItems: "baseline",
    backgroundSize: "cover",
    justifyContent: "center",
    display: "flex",
    padding: "10px",
    minHeight: "150px",
    backgroundImage: `url(${groupscroll})`,
    backgroundRepeat: "no-repeat",
    top: 0,
    backgroundPosition: "100%",
    gap: '10px',
    position: 'fixed',
    width: '100%'
  },
  '& .scrollboxtitlewrapper': {
    display: 'flex',
    gap: '10px',
    lineHeight: "18.5px",
    letterSpacing: "0.128px",
    color: "#00AEFF",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "16px",
  },
  '& .scrollboxdetail': {
    letterSpacing: "0.104px",
    color: "#7F7F7F",
    fontStyle: "normal",
    fontWeight: 800,
    fontFamily: "Nunito",
    fontSize: "13px",
    lineHeight: "15px",

  },
  '& .languageButton1': {
    display: 'flex',
    position: "relative", top: "-12px",
    gap: '10px',

  },
  '& .readyText': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0.144px',
    flexShrink: 0,
    marginTop: '10px'
  },
  '& .settingImgWrapper': {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    position:'relative',
    flexDirection: 'column',
    backgroundColor: '#00AEFF',
    height: '100vh',
    backgroundSize: '80%',
    borderBottomRightRadius: '0px',
    "@media(max-width: 960px)": {
      height: 'auto',
      position:'relative',
      backgroundColor: '#FFF',
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .mainContentWrapper': {
      marginLeft: '240px',
      flexDirection: 'row',
      width: `calc(100% - 240px)`,
    },
    '& .settingBackground': {
      flex: 5,
      height: 'auto'
    },
    '& .settingImgWrapper': {
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',
      height: '100vh',
    },
    '& .nomargin': {
      margin: 'auto !important',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapper': {
      marginLeft: '282px',
      width: `calc(100% - 282px)`,
    },

  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      width: '100% !important',
      margin: 0,
    },
  }
}))

// Customizable Area End