import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
interface RequestParams {
    method?: HttpMethods;
    headers?: object;
    body?: object | FormData | any;
}
export function sendAPIRequest(endpoint: string, params?: RequestParams) {
    const { method="GET", headers, body } = params || {}
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endpoint
    )
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    )
    if(body)
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body?.append ? body : JSON.stringify(body)
        )

    if(headers)
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return callId
}
export const CommonApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    let token=localStorage.getItem('token')
    const header = {
      "Content-Type": contentType,
        token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

function splitLaTeXText(inputString: string) {
  // Function to split a single text block from \text{}
  function splitTextBlock(textBlock: string) {
    return textBlock.split(' ').map(word => `\\text{${word} }`).join('')
  }

  // Regular expression to match \text{} content
  const textRegex = /\\text\{([^}]+?)\}/g;

  // Replace each \text{} block in the input string
  return inputString.replace(textRegex, (match, textContent) => {
    // If the text content within \text{} is longer than maxLen, split it
    return splitTextBlock(textContent);
  });
}

export const convertLatex = (text: string) => {
  const element = document.createElement('div')
  element.innerHTML = text
  return splitLaTeXText(element.textContent || '')  || ''
}

export const debounce=(callback: any,delay:number)=>{
let timer: string | number | NodeJS.Timeout | undefined 
return (...args: any)=>{
    clearTimeout(timer)
    timer = setTimeout(()=>callback(...args),delay)
  }
}