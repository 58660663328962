// Customizable Area Start
import React from 'react'
import { Box, Modal, Theme, Typography, styled } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share'
import { shareCloseBtn, shareDownloadIcon } from '../../../blocks/cftableforlessons/src/assets'
import ShareModalController from './ShareModalController.web'

class ShareModal extends ShareModalController {
  shareDesktopOptions = [
    {
      id: 0,
      text: "WhatsApp",
      icon: WhatsappIcon,
      element: WhatsappShareButton,
      ref: this.whatsappRef,
      isDownload: false,
    },
    {
      id: 1,
      text: "Facebook",
      icon: FacebookIcon,
      element: FacebookShareButton,
      ref: this.facebookRef,
      isDownload: false,
    },
    {
      id: 2,
      text: "Twitter",
      icon: TwitterIcon,
      element: TwitterShareButton,
      ref: this.twitterRef,
      isDownload: false,
    },
    {
      id: 3,
      text: "Telegram",
      icon: TelegramIcon,
      element: TelegramShareButton,
      ref: this.telegramRef,
      isDownload: false,
    },
    {
      id: 4,
      text: "Save image",
      icon: shareDownloadIcon,
      element: TelegramShareButton,
      ref: this.telegramRef,
      isDownload: true,
    },
  ]

  render() {
    const { dynamicShareUrl } = this.state

    return (
      <ShareModalStyled open={this.props.open} onClose={this.props.handleClickClose}>
        <Box className="shareDesktopWrapper">
          <Box className="shareWrapper" id="img-for-share">
            <Box className="shareContentContainer">{this.props.children}</Box>

            <Box className="shareOptionsWrapper">
              <Box className="shareOptionsTitle">
                <img
                  src={shareCloseBtn}
                  alt="Close"
                  width={25}
                  height={25}
                  className="shareOptionsTitleCloseBtn"
                  onClick={this.props.handleClickClose}
                  data-test-id="share-modal-close-btn"
                />

                <Typography component="span" className="shareOptionsTitleText">
                  {this.props.t('SHARE YOUR LESSON')}
                </Typography>
              </Box>

              <Box className="shareOptionsContent">
                {this.shareDesktopOptions.map((item) => {
                  const ShareComponent = item.element
                  const ShareIcon = item.icon
                  if (item.isDownload) {
                    return (
                      <Box
                        key={item.id}
                        className="shareOptionsItem"
                        onClick={this.handleSaveShareImage}
                      >
                        <img
                          src={item.icon}
                          alt={item.text}
                          className="shareOptionsImg"
                        />
                        <Typography component="span" className="shareOptionsItemText">
                          {this.props.t(item.text)}
                        </Typography>
                      </Box>
                    )
                  }
                  return dynamicShareUrl ? (
                    <ShareComponent
                      ref={item.ref}
                      key={item.id}
                      url={dynamicShareUrl}
                      onShareWindowClose={() => this.setState({ dynamicShareUrl: '' })}
                      className="shareOptionsItem"
                    >
                      <ShareIcon size={50} round />
                      <Typography component="span" className="shareOptionsItemText">
                        {item.text}
                      </Typography>
                    </ShareComponent>
                  ) : (
                    <Box
                      key={item.id}
                      className="shareOptionsItem"
                      onClick={() => this.submitShareAction(item.text)}
                    >
                      <ShareIcon size={50} round />
                      <Typography component="span" className="shareOptionsItemText">
                        {this.props.t(item.text)}
                      </Typography>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>

          <Box className="shareOptionsContentDesktop">
            {this.shareDesktopOptions.map((item) => {
              const ShareComponent = item.element
              const ShareIcon = item.icon
              if (item.isDownload) {
                return (
                  <Box
                    key={item.id}
                    className="shareOptionsItem"
                    onClick={this.handleSaveShareImage}
                  >
                    <img
                      src={item.icon}
                      alt={item.text}
                      className="shareOptionsImg"
                    />
                    <Typography component="span" className="shareOptionsItemText">
                      {this.props.t(item.text)}
                    </Typography>
                  </Box>
                )
              }
              return dynamicShareUrl ? (
                <ShareComponent
                  ref={item.ref}
                  key={item.id}
                  url={dynamicShareUrl}
                  onShareWindowClose={() => this.setState({ dynamicShareUrl: '' })}
                  className="shareOptionsItem"
                >
                  <ShareIcon size={50} round />
                  <Typography component="span" className="shareOptionsItemText">
                    {item.text}
                  </Typography>
                </ShareComponent>
              ) : (
                <Box
                  key={item.id}
                  className="shareOptionsItem"
                  onClick={() => this.submitShareAction(item.text)}
                >
                  <ShareIcon size={50} round />
                  <Typography component="span" className="shareOptionsItemText">
                    {this.props.t(item.text)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        </Box>
      </ShareModalStyled>
    )
  }
}

const ShareModalStyled = styled(Modal)(
  ({ theme, customStyle }: { customStyle?: any, theme: Theme }) => ({
    '& .shareOptionsImg': {
      width: '50px',
      height: '50px',
    },

    '& .shareOptionsItemText': {
      fontWeight: '500',
      fontSize: '12px',
      color: '#363636',
      fontFamily: 'Quicksand',
    },

    '& .shareOptionsItem': {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      cursor: 'pointer',
      alignItems: 'center',
    },

    '& .shareOptionsContent': {
      width: '100%',
      marginTop: '38px',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
    },

    '& .shareOptionsTitleCloseBtn': {
      position: 'absolute',
      left: '5px',
      cursor: 'pointer',
    },

    '& .shareOptionsTitleText': {
      fontWeight: '700',
      fontSize: '18px',
      color: '#CCCCCC',
      fontFamily: 'Quicksand',
    },

    '& .shareOptionsTitle': {
      textAlign: 'center',
      position: 'relative',
    },

    '& .shareOptionsWrapper': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      backgroundColor: 'white',
      padding: '21px 19px 40px',
    },

    '& .shareWrapper': {
      backgroundColor: '#fff',
      padding: '0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'scroll',
      width: '340px',
      height: '452px',
      borderRadius: '20px',
      margin: "auto",
      marginTop: "15vh",
      scrollbarWidth: 'none'
    },

    '& .shareWrapper::-webkit-scrollbar': {
      display: 'none'
    },

    '& .shareContentContainer': {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      justifyContent: 'space-between',
    },

    '& .shareDesktopWrapper': {
      maxHeight: 'calc(100vh - 230px)',
      overflow: 'auto',
    },

    '& .shareOptionsContentDesktop': {
      display: 'none',
    },

    [theme.breakpoints.between('md', 'lg')]: {
      '& .shareOptionsWrapper': {
        paddingLeft: '200px',
        paddingRight: '200px',
      },
    },

    [theme.breakpoints.up('lg')]: {
      overflow: 'auto',

      '& .shareOptionsWrapper': {
        display: 'none',
      },

      '& .shareDesktopWrapper': {
        height: 'auto',
        width: '430px',
        margin: 'auto',
        marginTop: '10vh',
        textAlign: 'center',
        borderRadius: '12px',
        backgroundColor: 'rgb(240,240,240)',
        maxHeight: 'initial',
      },

      '& .shareWrapper': {
        marginTop: '45px',
      },

      '& .shareOptionsContentDesktop': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '0 15px 20px',
        marginTop: '30px',
      },

      '& .shareOptionsContentDesktop .shareOptionsItem': {
        padding: '5px',
        borderRadius: '5px',

        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
      },
    },

    ...customStyle,
  })
)

export default withTranslation()(ShareModal)
// Customizable Area End
