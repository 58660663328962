import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { removeStorageData } from "framework/src/Utilities";
import {readyBlue, readyBrown, readyGreen, readyLightgreen, readyOrange, readyPurple, readyRed, readyYellow} from './assets'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectValue: string;
  open: boolean;
  mathOpen: boolean;
  firstLogin: boolean;
  topicName: string;
  unitName: string;
  errorMessage: string;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  drawerSide: boolean;
  openDrawer: boolean;
  childGrage9: boolean;
  childMath: boolean;
  isScrolled: boolean;
  countryId:number|null;
  unitId:any;
  showAvatar: any;
  avatarColor:any;
  readyTeddy:any;
  showLessons: any;
  lessonData:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MainMenuControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {

    // Customizable Area Start
    validationApiCallId: string = ''
    getLessonsList:string = ''
    // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.MaterialPagePropsMessage),
      getName(MessageEnum.LessonPagePropsMessage),
      getName(MessageEnum.NavigationMaterialMessage),
      getName(MessageEnum.NavigationChallengeMessage),
      getName(MessageEnum.NavigationLessonMessage),
      getName(MessageEnum.ChallengePagePropsMessage),
    ];

    this.state = {
      selectValue: '',
      open:false,
      isScrolled: false,
      firstLogin: false,
      topicName: " ",
      unitName: " ",
      errorMessage: "",
      mathOpen: false,
      trophiesOpen: false,
      goalsOpen: false,
      drawerSide: false,
      openDrawer: false,
      childGrage9: true,
      childMath: true,
      countryId:null, 
      unitId:null, 
      showAvatar: false,
      avatarColor: '', 
      readyTeddy:'', 
      showLessons: false,
      lessonData:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
     async componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
      window.addEventListener('scroll', this.handleScroll);  
      this.handleWindowSizeChange();
      this.handleName()
      this. GetValidationApiCall()
     }
  
    async componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
      await removeStorageData('LessonMaterial') 
      await removeStorageData('challengeLesson') 
    }


    handleWindowSizeChange = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 960) {
        this.setState({ 
            drawerSide: false 
        });
      } else {
        this.setState({
             drawerSide: true 
            });
      }
    };
   

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.validationApiCallId) {
          this.LoginCheckData(responseJson)
      }
  }
}

  GetValidationApiCall() {
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getMsgValidations = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getMsgValidations.messageId;

    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getMsgValidations.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountCheckApi
    );

    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMsgValidations.id, getMsgValidations);
  }

  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, mathOpen: false });
  };
  

 handleMaterialNavigation=()=>{
  const addMessage: Message = new Message(getName(MessageEnum.NavigationMaterialMessage));
  addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  addMessage.addData(getName(MessageEnum.MaterialPagePropsMessage),this.state.countryId);
  this.send(addMessage);
 }

 handleChallengeNavigation=()=>{
  const addMessage: Message = new Message(getName(MessageEnum.NavigationChallengeMessage));
  addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  addMessage.addData(getName(MessageEnum.ChallengePagePropsMessage),this.state.countryId);
  this.send(addMessage);
 }

 handleLessonNavigation=()=>{
    const addMessage: Message = new Message(getName(MessageEnum.NavigationLessonMessage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    addMessage.addData(getName(MessageEnum.LessonPagePropsMessage),this.state.countryId);
    this.send(addMessage);
 }

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    }
  }

  LoginCheckData = async (responseJson: any) => {
    if(responseJson?.data){
      this.setState({
        countryId:responseJson.data?.attributes?.app_country?.id,
        unitId:responseJson?.data?.attributes?.unitid,
        avatarColor: responseJson?.data?.attributes?.favorite_color,
      })
      this.updateReadyAvatar()
    }
  
  }

  updateReadyAvatar = () => {
    const colorToReadyTeddyMap = {
        '#ff8400': readyOrange,
        '#62cdff': readyBlue,
        '#b7f0ad': readyLightgreen,
        '#a95edb': readyPurple,
        '#f96855': readyRed,
        '#884a39': readyBrown,
        '#fdce36': readyYellow,
        '#2bce8c': readyGreen
    };
  
    const readyTeddy = (colorToReadyTeddyMap as any)[this.state.avatarColor] || readyBlue;
        this.setState({ readyTeddy: readyTeddy });
  }
  

  handleName = () => {
    if (this.state.firstLogin === true) {
      this.setState({
        unitName: "Let's explore the world of knowledge",
        topicName: "Welcome to Tedix",
      })
    } else {
      this.setState({
        unitName: "Unit 1: Set of Real Numbers",
        topicName: "Algebra",
      })
    }
  }

  // Customizable Area End
}
