export const imgBell = require("../assets/bell.png");
export const tedixIcon = require("../assets/tedix-icon.svg");
export const notificationIcon = require("../assets/notification-icon.svg");
export const arrowUp = require("../assets/arrowUp-icon.svg");
export const pandaSkip = require("../assets/pandaSkip-icon.svg");
export const rectengleIcon = require("../assets/view-rectengle.svg");
export const smallNotification = require("../assets/smallNotification.svg");
export const ArrowIcon  =require('../assets/button_back.png');
export const BgImage =require('../assets/Bg.svg');
export const CardIcon =require('../assets/CardIcon.svg');
export const CardIcon2 =require('../assets/CardIcon2.png');
export const CardIcon3 =require('../assets/CardIcon3.svg');
export const CardIcon4 =require('../assets/CardIcon4.svg');
export const CardIcon5 =require('../assets/CardIcon5.png');
export const CardIcon6 =require('../assets/CardIcon6.svg');
export const CardIcon7 =require('../assets/CardIcon7.svg');
export const DetailFooter =require('../assets/DetailsFooter.png');
export const MenuIcon =require('../assets/MenuIcon.svg');
export const NotificationIcon1 =require('../assets/NotificationIcon1.svg');
export const NotificationIcon2 =require('../assets/NotificationIcon2.svg');
export const NotificationIcon3 =require('../assets/NotificationIcon3.svg');
export const NotificationIcon4 =require('../assets/NotificationIcon4.svg');
export const MarkAsReadIcon =require('../assets/MarkAsReadIcon.svg');
export const MarkAsReadIcon2 =require('../assets/MarkAsReadIcon2.svg');
export const DeleteIcon =require('../assets/DeleteIcon.svg');
export const DeleteIcon2 =require('../assets/DeleteIcon2.svg');
export const CancelIcon =require('../assets/Cancel.svg');
export const DeletModalIcon =require('../assets/DeleteIconModal.svg');
export const FooterIcon1 =require('../assets/FooterIcon1.svg');
export const FooterIcon2 =require('../assets/FooterIcon2.svg');
export const FooterIcon3 =require('../assets/FooterIcon3.svg');
export const FooterIcon4 =require('../assets/FooterIcon4.svg');
export const FooterIcon5 =require('../assets/FooterIcon5.svg');
export const MarkAsReadDetail =require('../assets/MarkAsRead.svg');
export const DeleteIconDetail =require('../assets/DeleteIconDetail.svg');
export const RecoverNotification =require('../assets/RecoverNotification.svg');
export const CardDeleteIcon =require('../assets/CardDeleteIcon.svg');
export const RecoverIconDeleteScreen =require('../assets/RecoverIconDeleteScreen.svg');
export const NotificationDetailBg =require('../assets/notificationDetailBg.svg');
export const CenterCardBg =require("../assets/centerCardBg.svg");
export const imgHomeMenu = require("../assets/homeMenu.svg");
export const imgProfileMenu = require("../assets/profileMenu.svg");
export const imgHomeMenuActive = require("../assets/homeMenuActive.svg");
export const imgLeaderBoardMenuActive = require("../assets/leaderBoardMenuActive.svg");
export const imgLeaderBoardMenu = require("../assets/leaderBoardMenu.svg");
export const imgSettingMenu = require("../assets/settingMenu.svg");
export const imgProfileMenuActive = require("../assets/profileMenuActive.svg");
export const imgSettingMenuActive = require("../assets/settingMenuActive.svg");
export const imgChallengeMenu = require("../assets/challengeMenu.svg");
export const imgMenuMenu = require("../assets/menuMenu.svg");
export const imgMenuMenuActive = require("../assets/menuMenuActive.svg");
export const imgLogoTedix = require("../assets/logoTedix.png");
export const groupMaskMath = require("../assets/group_maskMath.svg");
export const groupDecoration = require("../assets/group_decoration.svg");
export const groupscroll = require("../assets/Group_19250.svg");
export const groupAvtar = require("../assets/group_avatar.svg");
export const buttonTrophies = require("../assets/button_trophies.svg");
export const buttonGoals = require("../assets/button_goals.svg");
export const buttonGrade = require("../assets/button_grade.svg");
export const buttonLangCountry = require("../assets/button_languagecountry.svg");
export const buttonNotification = require("../assets/button_notifications.svg");
export const buttonpxMonth = require("../assets/button_pxMonth.svg");
export const buttonMultilevel = require('../assets/button_multiLevel.svg');
export const rectangle = require("../assets/view_rectangle.svg");
export const trophies = require('../assets/skip_trophies.svg');
export const goalMedal = require('../assets/goalMedal.svg');
export const swipeReadIcon = require('../assets/readNotificationBell.svg')
export const DeleteDisable = require("../assets/deleteDisable.svg");
export const MarkAsReadDisable = require("../assets/markAsReadDisable.svg")
export const RecoverDisable = require('../assets/recoverDisable.svg')