import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  Card,
  CardContent,
  Table, TableContainer, TableBody, TableHead, TableRow, TableCell,
  Paper,
  Slider,
  TextareaAutosize,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WellDoneShare from "./WellDoneShare";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
import BottomMenu from "../../../components/src/BottomMenu";
import { bolt, maskMath, skipFeedback, skipGrade, skipVector, skipVectorBg, groupDot, feedback1, feedback2, backButton, shareBtnEnable,  thankReportBg, buttonClose, tedixLogo, skipStarIcon } from './assets';
import EolscorecardController, {
  Props,
} from "./EolscorecardController";
import NavigateLayout from "../../../components/src/NavigateLayout";
import FeedBackDialogueBox from './FeedBackDialogueBox';
import MainContentCard from "./MainContentCard";
import CustomRating from "./customizedRating.web";
import ProgressUpdate from "./ProgressUpdate.web";
import { loader } from "../../stripepayments/src/assets";
import ShareModal from '../../../components/src/MicrolessonExercise/ShareModal.web'
// Customizable Area End

class Eolscorecard extends EolscorecardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleFeedbackOpen = this.handleFeedbackOpen.bind(this);
    this.handleFeedbackClose = this.handleFeedbackClose.bind(this);
    this.handleOpenFeedback = this.handleOpenFeedback.bind(this);
    this.handleCloseFeedback = this.handleCloseFeedback.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  CustomSlider = styled(Slider)(({ theme }) => ({
    marginTop: '21px',

    "& .MuiSlider-root": {
      height: '24px',
      borderRadius: '20px',
      width: '100%',
      border: "3px solid #F2F2F2",
    },
    "& .MuiSlider-thumb": {
      backgroundImage: `URL(${skipStarIcon})`,
      backgroundSize: 'cover',
      width: '29.2px',
      height: '29px',
      backgroundColor: 'transparent',
      marginTop: '-17px',
      marginLeft: '-10px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-25px'
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '-25px'
      },
      borderRadius: '0'
    },
    "& .MuiSlider-rail": {
      display: 'none'
    },
    "& .MuiSlider-track": {
      height: '17px',
      borderRadius: '20px',
      backgroundColor: '#62CDFF',
      marginTop: '-10px',
      marginLeft: '3px',
      marginRight: '3px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '-18px'
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '-18px'
      }
    }
  }));

  renderConent = () => {
    if (this.state.isLoading) {
      return (
        <Box className="eolContentContainer">
          <Box style={{height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={loader} alt="Loading" />
          </Box>
        </Box>
      )
    }

    if (this.state.progressUpdateOpen) {
      return (
        <ProgressUpdate
          currentdailyGoal={this.state.dailyGoal}
          currentStreakGoal={this.state.streakGoal}
          goalProgress={this.state.goalProgress}
          dailyGoalSettings={this.state.dailyGoalSettings}
          streakGoalSettings={this.state.streakGoalSettings}
          todayCompletedLessonCounts={this.state.todayCompletedLessonCounts}
          navigation={this.props.navigation}
          lessonName={this.state.lessonName}
          lessonId={this.state.lessonId}
        />
      )
    }

    if (this.state.showFeedbackContainer) {
      return (
        <Box className="eolContentContainer" style={{ backgroundImage: "initial" }}>
          <Box className="feedbackContainer">
            <button
              data-testId="backButton"
              onClick={this.handleCloseFeedback}
              className="closePostion"
            >
              <img src={backButton} />
            </button>
            <Box className="eolFeedbackContainer">
              <Box className="feedbackImage">
                <img src={this.state.honestTeddy} alt="feedbackTeddy" className="teddy-image" />
                <img src={feedback1} alt="feedback1" className="teddy-background-image1" />
                <img src={feedback2} alt="feedback2" className="teddy-background-image2" />
              </Box>
            </Box>
            <Card className="feedbackCardWrapper">
              <Typography className="feedbackMiddleText" >
                {this.props.t("Your opinion matters")}
              </Typography>
              <CardContent className="feedbackMsgWrapper">
                <TextareaAutosize aria-label="maximum height" minRows={15} placeholder={`${this.props.t("Tell us your lesson journey")}...`} className="feedbackInnerMsg"
                  value={this.state.feedbackValue}
                  onChange={this.handleChangeFeedbackValue}
                  data-test-id="feedback-text-area" />
              </CardContent>
            </Card>
            <Box className="bottomButtons">
              <Button className="sendButton" fullWidth onClick={this.handleFeedbackOpen} disabled={!this.state.feedbackValue.trim()}>
                {this.props.t("Send")}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    }

    return (
      <Box className="eolContentContainer">
        <Box className="sideContentContainer">
          <Box className="primaryContainer">
            <Box className="FirstBox" >
              <img src={this.state.partyTeddy} alt="skipPartyTeddy" className="TeddyImg" ></img>
              <Card
                className="FirstMainCardWrapper">
                <Typography
                  className="FirstCardHeaderText">
                  {this.props.t("Lesson")} {" "}
                  <Typography className="InsideFirstHeaderText">
                    {this.props.t("Completed")}
                  </Typography>
                </Typography>
              </Card>
            </Box>
            <Box className="middleContent">
              {this.state.showMainCard && (
                <>
                  <Card className="mainCardWrapper" onClick={this.showTotalXpSection} data-test-id="openTotalXpBtn">
                    <Typography className="middleCardHeaderText" >
                      {this.props.t("Total XP")}
                    </Typography>
                    <CardContent className="cardContentWrapper">
                      <img style={{ height: '23px' }} src={bolt} alt="bolt"></img>
                      <Typography className="middleCardText">{`${this.state.totalXP} XP`}</Typography>
                    </CardContent>
                  </Card>
                  <Card className="mainCardWrapper" onClick={this.showAvgGradeSection} data-test-id="openAvgGradeBtn">
                    <Typography className="middleCardHeaderText">
                      {this.props.t("Avg. Grade")}
                    </Typography>
                    <CardContent className="cardContentWrapper">
                      <img style={{ height: '23px' }} src={skipGrade} alt="skipGrade"></img>
                      <Typography className="middleCardText">
                        {this.state.avgScore}
                      </Typography>
                    </CardContent>
                  </Card>
                </>
              )}
              {this.state.showTotalXp && (
                <Box
                  className="TotalXp"
                  style={{
                    textAlign: "center",
                    width: "267px",
                    borderRadius: "12px",
                    border: "1px solid #00AEFF",
                    background: "#00AEFF",
                  }}
                >
                  <TableContainer
                    component={Paper}
                    style={{
                      borderRadius: "12px",
                      border: "2px solid #00AEFF",
                      background: "#FFF",
                    }}
                  >
                    <Table>
                      <TableHead className="tableHeadCustom" onClick={this.showMainCard}>
                        <TableRow>
                          <TableCell className="middleCardHeaderText textLeft">{this.props.t("Total XP")}</TableCell>
                          <TableCell className="middleCardHeaderText">
                            <Typography className="customGap textRight">
                              <img style={{ height: '17px', marginTop: '3px' }} src={bolt} alt="bolt"></img>
                              <Typography className="middleCardHeaderText">{`${this.state.totalXP} XP`}</Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="tableCustom">
                        {this.state.summaryList.map((lesson, index) => (
                          <TableRow key={index}>
                            <TableCell className="textLeft">{this.props.t(this.formatLessonName(lesson.name))}</TableCell>
                            <TableCell className="textRight">{this.props.t(lesson.XP)} XP</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              {this.state.showAvgGrade && (
                <Box className="AvgGrade">
                  <TableContainer className="AvgGradeTableContainer"
                    component={Paper}
                  >
                    <Table>
                      <TableHead
                        className="tableHeadCustom"
                        onClick={this.showMainCard}
                      >
                        <TableRow>
                          <TableCell className="middleCardHeaderText textLeft">{this.props.t("Avg. Grade")} </TableCell>
                          <TableCell className="middleCardHeaderText">
                            <Typography className="customGap textRight">
                              <img style={{ height: '18px', marginTop: '5px' }} src={skipGrade} alt="skipGrade"></img>
                              <Typography className="middleCardHeaderText">
                                {this.state.avgScore}
                              </Typography>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                    <Box>
                      <Typography className="gradeText">
                      {this.props.t("You outdid yourself with a")}{this.state.avgScore}, {this.props.t("even better than your average of")} {this.state.lastAvgScore} {this.props.t(" Keep it up!")}
                      </Typography>
                      <Box
                        className="sliderCustom"
                      >
                        <this.CustomSlider
                          aria-label="Temperature"
                          defaultValue={Number(this.state.avgScore) / 10 * 100}
                          color="primary"
                          disabled
                        />
                      </Box>
                      <Box className="ratingDigitsBox">
                        <Typography className="ratingDigitText">
                          {this.state.avgScoreStart}
                        </Typography>
                        <Typography className="ratingDigitText">
                          {this.state.avgScoreEnd}
                        </Typography>
                      </Box>
                    </Box>
                  </TableContainer>
                </Box>
              )}
            </Box>
            <Box className="LastBox" style={{ display: (this.state.showAvgGrade || this.state.showTotalXp) ? 'none' : 'flex' }}>
              <Card className="lastMainCardWrapper">
                <Typography className="lastCardHeaderText">
                  {this.props.t("Rate this lesson!")}
                </Typography>
                <CardContent className="lastCardContent">
                  <CustomRating
                    data-testId="rating"
                    value={this.state.ratingValue}
                    onChange={this.handleClickRating}
                  />
                </CardContent>
              </Card>
            </Box>
          </Box>
          <Box className="secondaryContainer">
            <Box className="upperButtons">
              <Button data-test-id="openWellDoneShare" className="buttonStyle" fullWidth onClick={this.handleWellDoneShareModal}>
                <img src={shareBtnEnable} alt="share" className="buttonImage"></img>
                {this.props.t("Share")}
              </Button>
              <Button className="buttonStyle" fullWidth onClick={this.handleOpenFeedback}>
                <img src={skipFeedback} alt="skipFeedback" className="buttonImage"></img>
                {this.props.t("Feedback")}
              </Button>
            </Box>
            <Box className="bottomButtons">
              <Button
                data-test-id="continueBtn"
                onClick={this.handleClickContinue}
                className="continueButton" fullWidth>
                {this.props.t('Continue')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Home">
        <EolscorecardContainer>
          <Box className="eolmainContentWrapper">
            <MainContentCard
              goalsOpen={this.state.goalsOpen}
              closeDrawer={this.closeDrawer}
              lessonName={this.state.lessonName}
              handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
              navigation={this.props.navigation}
              onClickBackLandingPage={this.handleClickBackLandingPage}
              openDrawer={this.state.openDrawer}
              topicName={this.state.topicName}
              unitName={this.state.unitName}
              medianOpen={this.state.medianOpen}
              drawerSide={this.state.drawerSide}
              trophiesOpen={this.state.trophiesOpen}
              lessonNumber={localStorage.getItem('lessonNumber')}
               unitNumber={localStorage.getItem('unitNumber')}
            />
            {this.renderConent()}
            <WellDoneShare
              data-testId="wellDoneShare"
              isScoreCardShare
              open={this.state.iswellDoneShareModelOpen}
              onClose={() => this.setState({ iswellDoneShareModelOpen: false })}
            />
            <FeedBackDialogueBox
              data-test-id="wellDoneShare"
              open={this.state.openFeedback}
              onClose={this.handleFeedbackClose}
            />

            <RatingModalStyled open={this.state.openRating}>
              <Box className="ratingContainer">
                <div data-test-id="rating-close-btn" className="ratingCloseBtn" onClick={this.handleRatingClose}>
                  <img src={buttonClose} height={10} width={10} />
                </div>

                <img src={this.state.honestTeddy} className="ratingThankTeddy" />

                <Typography style={{textAlign:'center'}} className="ratingTitle">{this.props.t("Honest Assessment, Philanthropist!")}</Typography>

                <Box className="ratingDescription">
                <Typography className="ratingDescriptionText">{this.props.t("for your valuable lesson rating. Romania")}</Typography>
                  <Typography className="ratingDescriptionText" component="span">
                    {this.props.t("You've earned")}
                  </Typography>
                  <img src={bolt} className="ratingBoltIcon" />
                  <Typography className="ratingDescriptionText bold" component="span">10 XP </Typography>
                  <Typography className="ratingDescriptionText" component="span"> {this.props.t("for your valuable lesson rating. English")}</Typography>
                </Box>
              </Box>
            </RatingModalStyled>

            <ShareModal
              id="share-modal"
              open={this.state.openShare}
              handleClickClose={() => this.setState({openShare: false})}
              lessonAttemptId={this.state.lessonAttemptId}
              lessonId={this.state.lessonId}
              shareRecordType="SCORECARD"
              onAfterShare={() => this.setState({openShare: false})}
              onUpdateXp={this.getLessonSummaryListApi}
              microLessonId={0}
              assessmentId={0}
              questionBankId={0}
            >
              <Box
                style={{
                  backgroundColor: '#fff',
                  padding: '0',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  backgroundImage: `URL(${skipVectorBg})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain",
                  overflow: "hidden",
                  width: '340px',
                  height: "452px",
                  borderRadius: "20px",
                }}
              >
                <Box
                  style={{
                    width: '90%',
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    style={{
                      backgroundImage: `URL(${groupDot})`,
                      backgroundRepeat: 'round',
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative"
                      }}
                    >
                      <img 
                        src={this.state.partyTeddy} 
                        alt="skipPartyTeddy" 
                        style={{
                          position: 'relative',
                          top: "60px",
                          width: "106px",
                          height: "107px",
                        }}
                      />
                      <Card
                        style={{
                          position: "relative",
                          top: "25px",
                          width: "218px",
                          height: '83px',
                          backgroundColor: "#00AEFF",
                          padding: "18px",
                          marginBottom: "20px",
                          backgroundImage: `URL(${skipVector}), URL(${skipVector})`,
                          backgroundPosition:
                            "top left, bottom right",
                          backgroundRepeat: "no-repeat, no-repeat",
                          backgroundSize: "50% 100%"
                        }}
                      >
                        <Typography
                          style={{
                            color: "#fff",
                            fontSize: "18px",
                            fontFamily: "Nunito",
                            fontWeight: 900,
                            wordWrap: "break-word",
                            textAlign: "center",
                            textTransform: 'uppercase',
                            margin: '0',
                          }}
                        >
                          {this.props.t("Lesson")} {" "}
                          <Typography
                            style={{
                              marginTop: "0",
                              color: "white",
                              fontSize: "24px",
                              fontFamily: "Nunito",
                              fontWeight: 900,
                              lineHeight: "30px",
                              textTransform: 'uppercase',
                              letterSpacing: '3px'
                            }}
                          >
                            {this.props.t("Completed")}
                          </Typography>
                        </Typography>
                      </Card>
                    </Box>

                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "17px",
                        marginTop: '20px'
                      }}
                    >
                      <Card
                        style={{
                          width: "100px",
                          textAlign: "center",
                          height: "68px",
                          border: "1px solid #00AEFF",
                          borderRadius: "12px",
                          background: "#00AEFF"
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            color: 'white',
                            fontFamily: 'Nunito',
                            fontWeight: 900,
                            wordWrap: 'break-word',
                            letterSpacing: 0.80,
                            textAlign: 'center',
                            marginTop: '3px'
                          }}
                        >
                          {this.props.t("Total XP")}
                        </Typography>

                        <CardContent
                          style={{
                            display: "flex",
                            height: '42px',
                            alignItems: "center",
                            border: "2px solid #00AEFF",
                            borderRadius: "12px",
                            justifyContent: "center",
                            background: "#FFF",
                            padding: "6px",
                            gap: "5px",
                          }}
                        >
                          <img style={{ height: '23px' }} src={bolt} alt="bolt" />

                          <Typography
                            style={{
                              fontSize: '18px',
                              fontWeight: 900,
                              fontFamily: "Nunito",
                              whiteSpace: 'nowrap'
                            }}
                          >{`${this.state.totalXP} XP`}</Typography>
                        </CardContent>
                      </Card>

                      <Card
                        style={{
                          width: "100px",
                          height: "68px",
                          textAlign: "center",
                          border: "1px solid #00AEFF",
                          borderRadius: "12px",
                          background: "#00AEFF"
                        }}
                      >
                        <Typography
                          style={{
                            marginTop: '3px',
                            fontSize: "14px",
                            color: 'white',
                            fontFamily: 'Nunito',
                            letterSpacing: 0.80,
                            fontWeight: 900,
                            wordWrap: 'break-word',
                            textAlign: 'center',
                          }}
                        >
                          {this.props.t("Avg. Grade")}
                        </Typography>

                        <CardContent
                          style={{
                            display: "flex",
                            height: '42px',
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                            border: "2px solid #00AEFF",
                            gap: "5px",
                            background: "#FFF",
                            padding: "6px"
                          }}
                        >
                          <img style={{ height: '23px' }} src={skipGrade} alt="skipGrade" />

                          <Typography
                            style={{
                              whiteSpace: 'nowrap',
                              fontFamily: "Nunito",
                              fontSize: '18px',
                              fontWeight: 900,
                            }}
                          >
                            {this.state.avgScore}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Box>

                    <Box
                      style={{
                        marginTop: '26px',
                        textAlign: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Quicksand",
                          fontSize: '15px',
                          fontWeight: 500,
                          color: '#666666',
                          margin: '0',
                          letterSpacing: 'initial',
                        }}      
                      >
                        {this.props.t("I've completed the lesson on")}
                      </Typography>

                      <Typography
                        style={{
                          margin: '0',
                          fontSize: '15px',
                          color: '#666666',
                          fontWeight: 700,
                          letterSpacing: 'initial',
                          fontFamily: "Quicksand"
                        }}
                      >
                        {this.state.lessonName}
                      </Typography>
                    </Box>

                    <Box
                      style={{
                        marginTop: '31px',
                        textAlign: 'center'
                      }}
                    >
                      <img src={tedixLogo} alt="Tedix" />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ShareModal>

            <BottomMenu />
          </Box>
        </EolscorecardContainer>
      </NavigateLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(Eolscorecard)

const RatingModalStyled = styled(Modal)(() => ({
  '& .ratingContainer': {
    top: '50%',
    position: 'absolute',
    left: '50%',
    width: 296,
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#e3cff4',
    backgroundImage: `url(${thankReportBg})`,
    borderRadius: 12,
    padding: 26,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .ratingCloseBtn': {
    justifyContent: 'flex-end',
    width: '100%',
    display: 'flex',
  },

  '& .ratingTitle': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    color: '#363636',
    fontSize: '18px',
  },

  '& .ratingDescription': {
    marginTop: '10px',
    marginBottom: '15px',
    textAlign: 'center'
  },

  '& .ratingBoltIcon': {
    marginRight: '4px',
    width: '10px',
    marginLeft: '4px',
    height: '13px',
  },

  '& .ratingDescriptionText': {
    color: '#666666',
    fontFamily: 'Quicksand',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: '500',
  },

  '& .bold': {
    color: '#666666',
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: '700',
  },

  '& .ratingThankTeddy': {
    marginBottom: '14px',
    width: '96px',
    height: '100px',
  },
}))

export const EolscorecardContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',

  '& .eolmainContentWrapper': {
    overflowX: "hidden !important",
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  '& .eolsummaryContainer': {
    display: 'none',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#00AEFF',
    backgroundImage: `url(${maskMath})`,
  },
  '& .MuiTableCell-root': {
    fontFamily: 'Nunito',
    padding: '2px',
    borderBottom: 'hidden',
    textAlign: 'center',
    fontSize: '16px',
    LineHeight: '11.5px',
    fontWeight: 800,
    color: '#666666'
  },
  '& .sendButton': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0.8px',
    backgroundColor: '#36BFFF',
    boxShadow: '0px 3px #00AEFF',
    borderRadius: '10px',
    textTransform: 'none',
    position: 'relative',
    marginBottom: '60px',
    '& :before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      bottom: 0,
      transform: 'translateX(-50%)',
      left: '50%',
      width: '100%',
      background: 'white',
      height: '100%',
      top: '45px'
    },
    '&.Mui-disabled': {
      backgroundColor: '#f7f7f7',
      color: '#ccc',
      boxShadow: '0px 3px #ccc',
    },
    '&:hover': {
      boxShadow: '0px 3px #36BFFF',
      backgroundColor: '#00AEFF',
      '&.Mui-disabled': {
        backgroundColor: '#f7f7f7',
      }
    },
    '& .MuiButton-label': {
      fontFamily: 'Nunito',
    },
  },
  '& .closePostion': {
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    cursor: 'pointer'
  },
  '& .ratingDigitsBox': {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '5px'
  },
  '& .ratingDigitText': {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: '13px',
    lineHeight: '16.5px',
    letterSpacing: '0.8%'
  },
  '& .eolunitName': {
    height: '12px',
    top: '565px',
    left: '367px',
    fontFamily: 'Nunito',
    fontSize: '17px',
    fontWeight: '800',
    lineHeight: '13px',
    textAlign: 'center',
    backgroundColor: '#00AEFF',
    padding: '20px',
    color: 'var(--primary-blue-blue-primary-light, #C9EEFF)',
    fontStyle: 'normal',
    letterSpacing: '0.144px'
  },
  '& .eolsummaryCard': {
    marginTop: '170px',
  },

  '& .FirstBox': {
    display: "flex", flexDirection: "column", alignItems: "center", position: "relative"
  },
  '& .TeddyImg': {
    position: 'relative', top: 50, 
    width:'131px', height:'130px'
  },
  '& .FirstMainCardWrapper': {
    position: "relative",
    top: "60%",
    width: "267px",
    backgroundColor: "#00AEFF",
    padding: "3px",
    marginBottom: "20px",
    backgroundImage: `URL(${skipVector}), URL(${skipVector})`,
    backgroundPosition:
      "top left, bottom right",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "50% 100%"
  },
  '& .FirstCardHeaderText': {
    marginBlock: "20px",
    color: "#fff",
    fontSize: "20px",
    fontFamily: "Nunito",
    fontWeight: "900",
    wordWrap: "break-word",
    textAlign: "center",
    textTransform: 'uppercase'
  },
  '& .InsideFirstHeaderText': {
    marginTop: "5px",
    color: "white",
    fontSize: "30px",
    fontFamily: "Nunito",
    fontWeight: "900",
    lineHeight: "30px",
    textTransform: 'uppercase',
    letterSpacing: '3px'
  },
  '& .LastBox': {
    marginBottom: '20px',
    display: "flex",
    justifyContent: "center",
  },
  '& .lastCardContent': {
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    background: "#FFF",
    gap: "10px",
    padding: "12px"
  },
  '& .lastMainCardWrapper': {
    width: "277px",
    backgroundColor: "#FDCE36",
    padding: "3px",
    borderRadius: "12px"
  },
  '& .lastCardHeaderText': {
    color: "#363636",
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: "900",
    wordWrap: "break-word",
    textAlign: "center"
  },
  '& .sideContentContainer': {
    width: '90%',
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    justifyContent: "space-between",
    paddingTop: '60px'
  },
  '& .primaryContainer': {
    backgroundImage: `URL(${groupDot})`,
    backgroundRepeat: 'round',
  },
  '& .middleContent': {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    width: '267px',
    margin: '0 auto 20px'
  },
  '& .mainCardWrapper': {
    textAlign: "center",
    width: "130px",
    height: "73px",
    borderRadius: "12px",
    border: "1px solid #00AEFF",
    background: "#00AEFF"
  },
  '& .cardContentWrapper': {
    height: '48px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    border: "2px solid #00AEFF",
    background: "#FFF",
    gap: "8px",
    padding: "6px"
  },
  '& .middleCardHeaderText': {
    color: 'white',
    fontSize: "16px",
    fontFamily: 'Nunito',
    fontWeight: '900',
    letterSpacing: 0.80,
    wordWrap: 'break-word',
    textAlign: 'center',
    marginTop: '3px',
  },
  "& .textLeft": {
    textAlign: 'start',
    paddingLeft: '27px'
  },
  "& .textRight": {
    textAlign: 'end',
    paddingRight: '29px',
    justifyContent: 'end'
  },
  '& .middleCardText': {
    color: '#363636',
    fontSize: "20px",
    fontFamily: 'Nunito',
    fontWeight: '900',
    letterSpacing: 0.16,
    lineHeight: '15px',
    textAlign: 'center'
  },
  '& .upperButtons': {
    display: "flex",
    justifyContent: "center",
    gap: "21px",
    marginBottom: "20px"
  },
  '& .buttonImage': {
    marginRight: "10px",
  },
  '& .buttonStyle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '41px',
    color: '#62CDFF',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0.8px',
    backgroundColor: '#fff',
    boxShadow: '0px 3px #E6E6E6',
    border: "2px solid #E6E6E6",
    borderRadius: '12px',
    gap: "15px",
    textTransform: 'none',
    '& .MuiButton-label': {
      fontFamily: 'Nunito',
    },
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: '0px 3px #36BFFF',
      '&.Mui-disabled': {
        backgroundColor: '#fff',
      }
    },
    '&.Mui-disabled': {
      color: '#ccc',
      backgroundColor: '#f7f7f7',
      boxShadow: '0px 3px #ccc',
    },
  },
  '& .bottomButtons': {
    display: "flex",
    justifyContent: "center",
    gap: "21px"
  },
  '& .continueButton': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: '0.8px',
    backgroundColor: '#36BFFF',
    boxShadow: '0px 3px #00AEFF',
    borderRadius: '10px',
    textTransform: 'none',
    position: 'relative',
    '& :before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '100%',
      background: 'white',
      top: '45px'
    },
    '& .MuiButton-label': {
      fontFamily: 'Nunito',
    },
    '&:hover': {
      backgroundColor: '#00AEFF',
      boxShadow: '0px 3px #36BFFF',
      '&.Mui-disabled': {
        backgroundColor: '#f7f7f7',
      }
    },
    '&.Mui-disabled': {
      color: '#ccc',
      backgroundColor: '#f7f7f7',
      boxShadow: '0px 3px #ccc',
    },
  },
  '& .eolSettingBackground': {
    display: 'none',
    flexDirection: 'column',
    gap: '15px',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#00AEFF',
    backgroundImage: `url(${maskMath})`,
  },
  '& .eolunitNameRectangle': {
    height: '78px',
    top: '720px',
    left: '303px',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px 7px #0000000D',
    margin: 'auto',
    Width: '100%',
    alignSelf: 'flex-end',
  },
  '& .medianFooterName': {
    height: '12px',
    top: '565px',
    left: '367px',
    fontFamily: 'Nunito',
    fontSize: '17px',
    fontWeight: '800',
    lineHeight: '13px',
    textAlign: 'center',
    backgroundColor: '#00AEFF',
    padding: '20px',
    color: 'var(--primary-blue-blue-primary-light, #C9EEFF)',
    fontStyle: 'normal',
    letterSpacing: '0.144px'
  },
  '& .medianSettingImgWrapper': {
    marginTop: '170px',
  },
  '& .eolContentContainer': {
    flex: '6',
    width: '100%',
    backgroundColor: '#fff',
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `URL(${skipVectorBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  '& .continueBtn': {
    backgroundColor: '#f2f2f2',
    fontFamily: 'Nunito',
    color: '#B3B3B3',
    width: "100%"
  },
  '& .feedbackCardWrapper': {
    textAlign: "center",
    borderRadius: "12px",
    border: "1px solid #00AEFF",
    background: "#00AEFF",
    marginBottom: "40px",
  },
  '& .secondaryContainer': {
    marginBottom: '60px'
  },
  [theme.breakpoints.up('md')]: {
    '& .secondaryContainer': {
      marginBottom: '0px'
    },
    '& .primaryContainer': {
      backgroundImage: `URL(${groupDot})`,
      backgroundRepeat: 'round',
    },
    '& .sendButton': {
      marginBottom: '0px'
    },
    '& .eolmainContentWrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
    '& .eolsummaryContainer': {
      flex: 5,
      display: 'flex',
    },
    '& .eolunitName': {
      fontWeight: '17px',
    },
    '& .eolContentContainer': {
      padding: '32px 24px',
    },
    '& .eolSettingBackground': {
      flex: 5,
      display: 'flex',
    },
    '& .eolunitNameRectangle': {
      Width: '393px',
      alignSelf: 'flex-end',
    },
    '& .medianFooterName': {
      fontWeight: '17px',
    },
  },

  [theme.breakpoints.up('lg')]: {
    '& .eolmainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },
    '& .accountWrapper': {
      maxWidth: 600,
    },
  },
  ' & .MuiTypography-colorTextSecondary': {
    color: "white"
  },
  '& .tableHeadCustom': {
    background: "#00AEFF",
  },
  '& .tableHeadCustom > th': {
    color: "white"
  },
  '& .AvgGrade': {
    textAlign: "center",
    width: "267px",
    borderRadius: "12px",
    border: "1px solid #00AEFF",
    background: "#00AEFF",
    height: '228px',
    "@media(max-width: 959px)": {
      height: '212px',
    }
  },
  '& .AvgGradeTableContainer': {
    height: '225px',
    borderRadius: "12px",
    border: "2px solid #00AEFF",
    background: "#FFF",
    "@media(max-width: 959px)": {
      height: '210px',
    }
  },
  '& .sliderCustom': {
    marginLeft: "40px", width: "70%",
    height: '28px',
    borderRadius: '20px',
    border: "3px solid #F2F2F2",
    backgroundColor: "#F2F2F2",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& .gradeText': {
    padding: '14px 25px',
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: '16px',
    lineHeight: '23px',
    letterSpacing: '0.8%',
    textAlign: 'center',
    color: '#666666'
  },
  '& .tableCustom > td': {
    borderBottom: "hidden",
    marginBottom: "2px",
    borderRadius: '12px'
  },
  '& .tableHeadCustom > tr > td': {
    padding: "5px"
  },
  '& .customGap': {
    gap: "5px",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold"
  },
  '& .feedbackMiddleText': {
    color: 'white',
    fontSize: "22px",
    fontFamily: 'Nunito',
    fontWeight: '900',
    letterSpacing: 0.80,
    wordWrap: 'break-word',
    textAlign: 'center',
    LineHeight: '18.5px'
  },
  '& .feedbackMsgWrapper': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "12px",
    border: "2px solid #00AEFF",
    background: "#FFF",
    gap: "4px",
    padding: "6px",
    height: "100%",
    width: "100%",
    fontFamily: "Quicksand"
  },
  '& .feedbackContainer': {
    width: '100%',
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    justifyContent: "space-between",
  },
  '& .feedbackImage ': {
    position: "relative",
    marginBottom: "37px",
    marginTop: "37px",
  },
  '& .teddy-image ': {
    position: "relative",
    zIndex: 1,
    height:'131px', 
    width:'130px'
  },
  '& .teddy-background-image1': {
    position: "absolute",
    top: "-39px",
    left: "66px",
    zIndex: 0,
  },
  '& .teddy-background-image2': {
    position: "absolute",
    top: "31px",
    left: "-61px",
    zIndex: 0,
  },
  '& .eolFeedbackContainer': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  '& .feedbackInnerMsg': {
    width: '100%',
    height: '95% !importatnt',
    border: 'none',
    fontFamily: 'Quicksand',
    resize: "none",
    fontSize: '14px',
    outline: 'none',
  },
}))
// Customizable Area End
