import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import {
  orangeViewRectangle,
  viewRectangle,
  greyViewRectangle,
} from '../../../blocks/cftableforlessons/src/assets'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import { useTranslation } from 'react-i18next'
import {runningTeddyBlue, runningTeddyBrown, runningTeddyGreen, runningTeddyMint, runningTeddyOrange, runningTeddyPurple, runningTeddyRed, runningTeddyYellow} from '../assets'
interface SelebrationPageProps {
  onClickContinue: ({
    isClickFromCelebrate,
  }: {
    isClickFromCelebrate?: boolean
  }) => void
}

const SelebrationPage: React.FC<SelebrationPageProps> = ({
  onClickContinue,
}) => {
  const  { t } = useTranslation()
  const userName = localStorage.getItem('displayName') || 'Amy'
  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': runningTeddyOrange,
    '#62cdff': runningTeddyBlue,
    '#b7f0ad': runningTeddyMint,
    '#a95edb': runningTeddyPurple,
    '#f96855': runningTeddyRed,
    '#884a39': runningTeddyBrown,
    '#fdce36': runningTeddyYellow,
    '#2bce8c': runningTeddyGreen
  };

  const updatedTeddy = colorToUpdateTeddy[avatarColor] || runningTeddyBlue;
  return (
    <SelebrationPageContainer>
      <Box style={{ display: 'flex' }}>
        <Box style={{ position: 'relative' }}>
          <img src={viewRectangle} width={180} height="auto" />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#A95EDB' }}>
              {t("Congratulations!")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="titleWrapper">
        <Typography className="titleText" style={{ fontSize: '20px' }}>
        {t("FANTASTIC")}
        </Typography>
        <Typography className="titleText" style={{ fontSize: '30px' }}>
        {t("BEGINNING!")}
        </Typography>
      </Box>
      <Box className="imageTeddy">
        <img src={updatedTeddy} width={380} height="auto" />
        <Box
          style={{
            position: 'absolute',
            top: '10px',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <Box style={{ position: 'relative', bottom:'30px' }}>
            <img src={greyViewRectangle} width={78} height="auto" />
            <Box className="textWrapper">
              <Typography className="styledText" style={{ color: '#666666' }}>
                {t("+ 10 XP!")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="downContainer">
        <Box style={{ position: 'relative' }}>
          <img src={orangeViewRectangle} width={180} height="auto" />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#FF8400' }}>
              {t(`Great job,`)}{' '}{userName}!
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="buttonContainer" onClick={() => onClickContinue({isClickFromCelebrate: true})}>
        <StyledButton>{t("Continue")}</StyledButton>
      </Box>
    </SelebrationPageContainer>
  )
}

const SelebrationPageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '55px',

  '& .textWrapper': {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#363636',
  },

  '& .styledText': {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },

  '& .titleWrapper': {
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .imageTeddy': {
    marginTop: '18px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },

  '& .downContainer': {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '48px',
  },

  '& .buttonContainer': {
    flex: 1,
    margin: '32px 26px 40px',
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  [theme.breakpoints.up('md')]: {
    '& .buttonContainer': {
      margin: '32px 60px 40px',
    },
  }
}))

export default SelebrationPage
