import React, { useState } from "react";
// Customizable Area Start
import { Box, Card, Typography, styled, IconButton } from "@material-ui/core";
import {
  buttonShow,
  buttonHide,
  skipShowLeft,
  skipShowRight,
  skipCircle
} from "./assets";
import ChartGrade from "./ChartGrade.web";
import "../../../components/src/I18nextConfig/i18n";
import  {useTranslation} from "react-i18next"

interface AverageGradeProps {
  type:any;
  dataChart: number[];
  labels: string[];
  startDate:any;
  endDate:any;
  onClickBack: any;
  onClickNext: any;
}

function AverageGrade({ dataChart, labels, startDate, endDate, onClickBack, onClickNext, type }: Readonly<AverageGradeProps>) {
  const [isOpenToogleGraph, setIsOpenToogleGraph] = useState(false);
  const [data, setData] = useState([...dataChart]);
  const  { t } = useTranslation()
  const handleToogleGraph = () => {
    setIsOpenToogleGraph(!isOpenToogleGraph);
  };
  const handleRenderAdditionalContent = () => {
    if (isOpenToogleGraph) {
      return (
        <AdditionBoxContentStyle>
          <img
            src={skipCircle}
            alt="Circle image"
            data-testid="addtionalBox__circle"
            className="addtionalBox__circle"
          />
          <Typography className="you_style">{t('You')}</Typography>
        </AdditionBoxContentStyle>
      );
    }
    return (
      <Box className="averageGradeContent__additional">
        <ChartGrade isMoreDetail={false} labels={labels} dataChart={dataChart} />
      </Box>
    );
  };
  // Customizable Area Start
  const formatDateRange = (startDateStr:any, endDateStr:any) => {
    const formatDate = (dateStr:any) => {
      const [day, month, year] = dateStr.split('-');
      const date = new Date(year, month - 1, day);
      return date.toLocaleString('default', { month: 'short' }) + ' ' + day;
    };
    return `${formatDate(startDateStr)} - ${formatDate(endDateStr)}`;
  }

 const extractMonth = (date: any) => {
    const dateParts = date.split('-');
    const dateObject = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
  
    const month = dateObject.toLocaleString('en-US', { month: 'long' });
    return month;
  }

  const extractYear = (date: any) => {
    const dateParts = date.split('-');
    const dateObject = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]));
  
    const year = dateObject.getFullYear();
    return year;
  }

  function getTimelineContent(type:any, startDate:any, endDate:any) {
    if (type === "weekly") {
      return formatDateRange(startDate, endDate);
    } else if (type === "monthly") {
      return extractMonth(startDate);
    } else {
      return extractYear(startDate);
    }
  }
  


  return (
    <AverageGradeBoxStyle>
      <Card className="averageGrade">
        <Box className="averageGradeHeader">
          <Typography component="div" className="averageGradeHeader__title">
            {t('Avg. Grade')}
          </Typography>
          <Box className="averageGradeHeader__action">
            <IconButton
              data-testid="averageGradeHeader__actionPrev"
              aria-label="prev"
              onClick={() => {
                onClickBack()
              }}
            >
              <img src={skipShowLeft} alt="Prev action" />
            </IconButton>
            <IconButton
              data-testid="averageGradeHeader__actionNext"
              aria-label="next"
              onClick={() => {
                onClickNext()
              }}
            >
              <img src={skipShowRight} alt="Next action" />
            </IconButton>
          </Box>
          <Box className="averageGrade__graphToogle">
            <IconButton
              aria-label="graphToogle"
              data-testid="graphToogle"
              onClick={handleToogleGraph}
            >
              <img
                src={isOpenToogleGraph ? buttonHide : buttonShow}
                alt="Toogle Graph"
              />
            </IconButton>
          </Box>
        </Box>
        <Box className="averageGradeContent">
        <Box className="averageGradeContent__timeline">
        <Typography component="div">
         {getTimelineContent(type, startDate, endDate)}
        </Typography>
          </Box>

          <Box className="averageGradeContent__additional">
            {handleRenderAdditionalContent}
          </Box>
        </Box>
        <Box id="averageGradeGraph">
          {isOpenToogleGraph && (
            <ChartGrade isMoreDetail={true} labels={labels} dataChart={dataChart} />
          )}
        </Box>
      </Card>
    </AverageGradeBoxStyle>
  );
  // Customizable Area End
}
// Customizable Area Start

const AverageGradeBoxStyle = styled(Box)(({ theme }) => ({
  margin: "15px 0px",
  "& .averageGrade": {
    minWidth: "329px",
    borderRadius: "10px",
    border: "2px solid #F2F2F2",
    background: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    boxShadow: "none",
    '@media(max-width:599px)':{
      width:"329px"
    }
    
  },
  "& .averageGradeHeader": {
    margin: "14px 0px 0px 16px",
    display: "flex",
    alignItems: "center"
  },
  "& .averageGradeHeader__title": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.2",
    flex: "0 0 35%",
    maxWidth: "35%"
  },
  "& .averageGradeHeader__action": {
    flex: "0 0 25%",
    maxWidth: "25%"
  },
  "& .averageGrade__graphToogle": {
    flex: "0 0 40%",
    maxWidth: "40%",
    display: "flex",
    flexDirection: "row-reverse"
  },
  "& .averageGradeContent": {
    display: "flex",
    width: "100%",
    padding: "0px 12px 24px 16px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .averageGradeContent .averageGradeContent__timeline .MuiTypography-body1": {
    paddingBottom: "4px",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontSize: "14px",
    color: "#999999"
  },
  "& .averageGradeContent__additional": {
    flex: "0 0 60%",
    maxWidth: "60%"
  },
  "& #averageGradeGraph": {
    margin: "-24px 12px 16px 16px"
  },
  [theme.breakpoints.up("md")]: {
    // height: "100vh"
  }
}));

const AdditionBoxContentStyle = styled(Box)(() => ({
  display: "inline-flex",
  "& .addtionalBox__circle": {
    marginRight: "4px"
  },
  "& .you_style": {
    color: "var(--Primary-Blue-Blue-Primary, #62CDFF)",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "9.5px",
  }
}));

// Customizable Area End

export default AverageGrade;
