import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionCancelledPremiumController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    } 
    getCurrentDate() {
        return new Date().toLocaleDateString('en-GB').split(',')[0]
    }
}