import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
  // Customizable Area Start

  // Customizable Area End
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: (screenName: string) => void;
  };
  trophiesOpen: boolean;
  goalsOpen: boolean;
  openDrawer: boolean;
  topicName:string;
  unitName:string;
  unreadNotifcations?:boolean
  handleOpenDrawer: (name: string) => void;
  children:JSX.Element
  // Customizable Area End
}
interface S {
  // Customizable Area Start
 
  // Customizable Area End
}

  // Customizable Area Start

// Customizable Area End

interface SS {
  id: string;
}
export default class InAppNotificationLayoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start

      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  // Customizable Area Start
   
  // Customizable Area End
}
