import React, { Component } from "react";
// Customizable Area Start
import { Box, Typography, styled, IconButton, CircularProgress, } from "@material-ui/core";
import {
  buttonShow,
  buttonHide,
  free,
  skipTime,
  disableicon,
  skipGrade
} from "./assets";
import {MainLessonDetailObject} from "./MedianController.web"
import MedianGradeWeeklyDataController, { Props } from "./MedianGradeWeeklyDataController.web";
import ReportLessonPageWeb from "./ReportLessonPage.web";
import { withTranslation } from "react-i18next";

// Customizable Area End
// Customizable Area Start
 class MedianWeeklyData extends MedianGradeWeeklyDataController {
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    // Customizable Area End
    
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start


  handleToogleMultipleLesson(index: number, item: any) {

    this.state.toogleShowMultipleLesson === `show${index}`
      ? this.emptyShowMultipleLesson()
      : this.changeShowMultipleLesson(index,item)
  }

  emptyShowMultipleLesson = () => {
    this.setState({
      toogleShowMultipleLesson: ``
    })
  }

  changeShowMultipleLesson = (index: number,item:any) => {
    this.setState({
      toogleShowMultipleLesson: `show${index}`,
      loading: true
    });
    this.getLessonDetailsOfMedianGrade(item.startDate, item.endDate)
  }


  toggleIcon(index: number) {
    return this.state.toogleShowMultipleLesson === `show${index}`
      ? buttonHide
      : buttonShow;
  }


  handleToggle=(index:number)=>{
    if(this.state.lessonDetailOpen === `show${index}`){
      this.setState({
        lessonDetailOpen:""
      })
    }else{
      this.setState({
        lessonDetailOpen:`show${index}`
      })
    }
    
  }



  renderReportLessonData = () => {
    return (
      <>
        {this.state.loading ?
          <CircularProgress style={{ color: '#A95EDB' }} />
          :
          <>
            {this.state.reportLesson.length > 0 ?

              this.state.reportLesson.map((item: MainLessonDetailObject,index:number) => {
                return (
                  <ReportLessonPageWeb 
                   key={index}
                   data-testid = {`showReportLessonPageWeb${index}`}
                   toggleShow={()=>this.handleToggle(index)}
                   index={index} 
                   isOpen={this.state.lessonDetailOpen}
                   Lessondata={{...item,startDate:this.state.startDate,endDate:this.state.endDate}}/>
                )

              })

              : <Box className="empty_lesson_box">
                <Typography className="reportMultipleDate_empty_lesson">
                  {this.props.t("No lessons completed.")}
                </Typography>
              </Box>
            }
          </>
        }
      </>
    )
  }

  renderWeekData() {
    const { mockWeekYearData } = this.props;
    return (
      <Box>
        
        {mockWeekYearData.length>0 && mockWeekYearData.map((item: any, index: number) => {
          const showIndex = `show${index}`;
          const getClassName = `multipleReport ${this.state.toogleShowMultipleLesson === showIndex
            ? "multipleReport--show"
            : ""
            }`;
          return (
            <Box key={item} className={getClassName}>
              <Box className="Weekly_multipleReport__header">
                <Typography>{item.label}</Typography>
                <IconButton
                  className="btnToogleShow"
                  data-testid={`btnToogle${index}`}
                  disabled={item.lessons.length === 0}
                  onClick={() => {
                    this.handleToogleMultipleLesson(index, item);
                  }}
                >
                  <img
                    src={
                      item.lessons.length > 0
                        ? this.toggleIcon(index)
                        : disableicon
                    }
                    width={18}
                    height={18}
                  />
                </IconButton>
              </Box>

              <Box className="reportDetail__reportLessons">
                {this.state.toogleShowMultipleLesson === `show${index}` ? (
                  this.renderReportLessonData()
                ) : (
                  <>
                    {item.lessons.length > 0 ? (
                      <>
                        {item.lessons.map((lesson: any) => {
                          return (
                            <Box key={lesson} className="reportMultipleInDate">
                              <Typography className="reportMultipleInDate__title">
                                {lesson.name}
                              </Typography>
                              <Typography className="reportMultipleInDate__shortDescription">
                                {lesson.content}
                              </Typography>
                            </Box>
                          );
                        })}

                        <Box className={`reportMultiple_Lesson `}>
                          <Box className="reportMultipleLesson__item">
                            <img src={free} height={18} />
                            <Typography>{item.earned_xp} XP</Typography>
                          </Box>
                          <Box className="reportMultipleLesson__item">
                            <img src={skipTime} height={18} />
                            <Typography>{item.minutes} MIN</Typography>
                          </Box>
                          <Box className="reportMultipleLesson__item">
                            <img src={skipGrade} height={18} />
                            <Typography>{item.grade}</Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <Box className="empty_lesson_box">
                        <Typography className="reportMultipleDate_empty_lesson">
                         {this.props.t("No lessons completed.")}
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <Container> {this.renderWeekData()}</Container>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(MedianWeeklyData)
const Container: React.ComponentType = styled(Box)(() => ({
  "& .reportMultipleDate_empty_lesson": {
    color: "var(--Primary-Neutrals-Warm-gray-200, #CCC)",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px" /* 92.857% */
  },

  "& .multipleReport": {
    width: "100%",
    // position: "absolute",
    top: "180px",
    padding: "12px 32px"
  },
  "& .multipleReport--show": {
    background: "#C9EEFF4D"
  },
  "& .Weekly_multipleReport__header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
    // marginBottom: "8px"
  },
  "& .Weekly_multipleReport__header p": {
    color: "#62CDFF",
    fontFamily: "Nunito",
    fontWeight: 500,
    fontSize: "16px"
  },

  "& .reportDetail__reportLessons": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "15px"
  },

  "& .reportMultipleInDate": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "0px 20px 0px 20px"
  },
  "& .reportMultipleInDate__title": {
    color: "#00AEFF",
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: "16px"
  },
  "& .reportMultipleInDate__shortDescription": {
    color: "#999999",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontSize: "14px"
  },
  "& .reportMultipleLesson__item .MuiTypography-body1": {
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: "16px",
    color: "#363636"
  },

  "& .reportMultiple_Lesson": {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0px 24px",
    borderBottom: "1px solid var(--Primary-Neutrals-Warm-gray-50, #F2F2F2)"
  },
  "& .reportMultiple_Lesson.toogleShow": {
    background: "#36BFFF",
    color: "#F2F2F2",
    padding: "12px",
    margin: "0 -20px"
  },

  "& .reportMultipleLesson__item img": {
    margin: "0 12px"
  },

  "& .reportMultipleLesson__item": {
    display: "flex",
    alignItems: "center"
  },

  "& .empty_lesson_box": {
    padding: "10px 20px 20px",
    borderBottom: "1px solid  var(--Primary-Neutrals-Warm-gray-50, #F2F2F2)"
  }
}));

// Customizable Area End