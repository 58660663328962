// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import {parsePhoneNumberFromString} from "libphonenumber-js"
import { sendAPIRequest, CommonApiCall } from '../../../components/src/utils'
export const configJSON = require('./config')
import { Message } from '../../../framework/src/Message'
import {orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried} from './assets';

export interface Country {
  id: string
  type: string
  attributes: {
    name: string
    country_code: string
    alpha2: string
    alpha3: string
  }
}

export interface ICountry {
  id: string
  type: string
  attributes: {
      name: string
      country_code: string
      alpha2: string
      alpha3: string
  }
  update?:boolean
}
interface City {
  id: string
  type: string
  attributes: {
    name: string
    country_id: number
  }
}

interface School {
  id: string
  type: string
  attributes: {
    name: string
    city_id: number
  }
}

interface Grade {
  id: string
  type: string
  attributes: {
    name: string
  }
}

export interface Props {
  navigation: any
  id: string
  t?:any
}

interface S {
  selectedCountry: string
  defaultCountry:string
  countriesData: Array<Country>
  countriesDisplayList: Array<string>
  selectedCity: string
  citiesData: Array<City>
  citiesDisplayList: Array<string>
  selectedSchool: string
  schoolsData: Array<School>
  schoolsDisplayList: Array<string>
  selectedGrade: string
  gradesData: Array<Grade>
  gradesDisplayList: Array<string>
  initialData: {
    firstName: string | null
    lastName: string | null
    userName: string | null
    email: string | null
    studentPhone: string | null
    parentPhone: string | null
    parentEmail: string | null
    teacherEmail: string | null
    country: string | null
    city: string | null
    school: string | null
    grade: string | null
  }
  currentLanguage: 'English' | 'German' | 'Hungarian' | 'Romanian'
  showEmailConfirmModal: boolean
  showDeleteConfirmModal: boolean
  isClickSubmitBtn: boolean
  password: string
  submitData: {
    firstName: string
    lastName: string
    userName: string
    email: string
    studentPhone: string
    parentPhone: string
    parentEmail: string
    teacherEmail: string
  }
  accountId: string,
  parentPhoneCountryCode:Country|undefined
  childPhoneCountryCode:Country|undefined
  defaultParentPhone:string
  defualtChildPhone:string
  profileId?: number
  userNameError: string
  showAlert?: boolean
  popupData: {
    text: string
    type: 'success' | 'error'
  }
  token: string
  CountryId:number|null,
  saveButtonText: string, 
  avatarHealth: any;
  avatarColor: any;
  avataIcon:any;
  isLoading: boolean
}

interface SS {
  id: any
}

export default class EditAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSpecificAccountId: string = ''
  getCurrentProfileId: string = ''
  getCountriesId: string = ''
  getCitiesId: string = ''
  getGradesByCountryId:string=""
  getGradesId: string = ''
  changeunitIdAPICallId:string=""
  getSchoolsId: string = ''
  submitEditAccountId: string = ''
  submitEditAccountChangeEmailId: string = ''
  deleteAccountId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      selectedCountry: '',
      defaultCountry:'',
      countriesData: [],
      countriesDisplayList: [],
      selectedCity: '',
      citiesData: [],
      citiesDisplayList: [],
      parentPhoneCountryCode:undefined,
      childPhoneCountryCode:undefined,
      selectedSchool: '',
      schoolsData: [],
      schoolsDisplayList: [],
      selectedGrade: '',
      CountryId:null,
      gradesData: [],
      gradesDisplayList: [],
      initialData: {
        firstName: null,
        lastName: null,
        userName: null,
        email: null,
        studentPhone: null,
        parentPhone: null,
        parentEmail: null,
        teacherEmail: null,
        country: null,
        city: null,
        school: null,
        grade: null,
      },
      currentLanguage: 'English',
      showEmailConfirmModal: false,
      showDeleteConfirmModal: false,
      isClickSubmitBtn: false,
      defaultParentPhone:"",
      defualtChildPhone:'',
      password: '',
      submitData: {
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        studentPhone: '',
        parentPhone: '',
        parentEmail: '',
        teacherEmail: '',
      },
      accountId: '',
      userNameError: '',
      showAlert: false,
      popupData: {
        text: '',
        type: 'success',
      },
      token: '',
      saveButtonText: 'Save',
      avatarHealth: 'healthy', 
      avatarColor: '',
      avataIcon: '',
      isLoading:true
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const token = localStorage.getItem('token') || ''
    this.getSpecificAccount(token)
    this.getCurrentUserProfile(token)
    this.setState({ token: token })
  }

  getFormatedPhonenNumber=(number:string)=>{
    const phoneNumber = parsePhoneNumberFromString(number)
    if(phoneNumber){
      const formattedNumber = phoneNumber.formatInternational() 
      return formattedNumber
    }else{
      return number
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Received', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const httpStatus = message.getData(
      getName(MessageEnum.RestAPIResponceHttpStatus)
    )

    this.apiSuccessCallBackController(apiRequestCallId, responseJson, httpStatus)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any,
    httpStatus?: number
  ) => {
    const successCallbackMap = {
      [this.getSpecificAccountId]: this.handleGetSpecificAccountResponse,
      [this.getCurrentProfileId]: this.handleGetCurrentProfileResponse,
      [this.getCountriesId]: this.handleGetCountriesResponse,
      [this.getCitiesId]: this.handleGetCitiesResponse,
      [this.getSchoolsId]: this.handleGetSchoolsResponse,
      [this.getGradesId]: this.handleGetGradesResponse,
      [this.getGradesByCountryId]: this.handleGetGradesByCountryResponse,
      [this.submitEditAccountId]: this.handleSubmitEditAccountResponse,
      [this.submitEditAccountChangeEmailId]: this.handleSubmitEditAccountChangeEmailResponse,
      [this.deleteAccountId]: this.handleDeleteAccountResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any, httpStatus?: number) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON, httpStatus)
    }
  }

  handleGetSpecificAccountResponse = (responseJson: any) => {
    this.setState({
      initialData: {
        ...this.state.initialData,
        email: responseJson?.data?.attributes?.email,
      },
      currentLanguage:
        responseJson?.data?.attributes?.app_language_name,
      accountId: responseJson?.data?.id,
    })
  }

  handleGetCurrentProfileResponse = (responseJson: any) => {
    this.setState({
      initialData: {
        ...this.state.initialData,
        firstName: responseJson?.data?.attributes?.first_name,
        lastName: responseJson?.data?.attributes?.last_name,
        userName: responseJson?.data?.attributes?.display_name,
        studentPhone: this.getFormatedPhonenNumber(responseJson?.data?.attributes?.phone),
        parentPhone: this.getFormatedPhonenNumber(responseJson?.data?.attributes?.parent_phone),
        parentEmail: responseJson?.data?.attributes?.parent_email,
        teacherEmail: responseJson?.data?.attributes?.teacher_email,
        country: this.checkInitialValue(responseJson?.data?.attributes?.country_name),
        city: this.checkInitialValue(responseJson?.data?.attributes?.city),
        school: this.checkInitialValue(responseJson?.data?.attributes?.school),
        grade: this.checkInitialValue(responseJson?.data?.attributes?.grade),
      },
      defaultParentPhone:this.getFormatedPhonenNumber(responseJson?.data?.attributes?.parent_phone),
      defualtChildPhone:this.getFormatedPhonenNumber(responseJson?.data?.attributes?.phone),
      selectedCountry: this.checkInitialValue(responseJson?.data?.attributes?.country_name),
      defaultCountry: this.checkInitialValue(responseJson?.data?.attributes?.country_name),
      selectedCity: this.checkInitialValue(responseJson?.data?.attributes?.city),
      selectedSchool: this.checkInitialValue(responseJson?.data?.attributes?.school),
      selectedGrade: this.checkInitialValue(responseJson?.data?.attributes?.grade),
      profileId: responseJson?.data?.attributes?.id,
      CountryId:responseJson?.data?.attributes?.country_id, 
      avatarHealth: responseJson?.data?.attributes?.avatar_health,
      avatarColor: responseJson?.data?.attributes?.favorite_color
    })
    this.updateAvatar()
    this.getCountries()

  }

  handleGetCountriesResponse = (responseJson: any) => {
    this.setState({
      countriesData: responseJson?.data,
      countriesDisplayList: responseJson?.data?.map(
        (item: Country) => item?.attributes?.name
      ),
    })
    this.getCities()
  }

  handleGetCitiesResponse = (responseJson: any) => {
    this.setState({
      citiesData: responseJson?.data,
      citiesDisplayList: responseJson?.data?.map(
        (item: City) => item?.attributes?.name
      ),
    })
    let country = this.state.countriesData.find((items:ICountry) => items.attributes.name == this.state.selectedCountry) as ICountry
    this.getGradesByCountry(country)
    const currentCityId = this.state.citiesData.find(item => item.attributes.name === this.state.initialData.city)?.id
    if (currentCityId) {
      this.getSchools(currentCityId)
    }
  }

  handleGetSchoolsResponse = (responseJson: any) => {
    this.setState({
      schoolsData: responseJson?.data,
      schoolsDisplayList: responseJson?.data?.map(
        (item: School) => item?.attributes?.name
      ),
    })
  }

  handleGetGradesResponse = (responseJson: any) => {
    this.setState({
      gradesData: responseJson?.data,
      gradesDisplayList: responseJson?.data?.map(
        (item: Grade) => item?.attributes?.name
      ),
    })
  }

  handleGetGradesByCountryResponse = (responseJson: any) => {
    if (responseJson?.data && responseJson?.data.length>0) {
      this.setState({
        gradesData: responseJson?.data,
        gradesDisplayList: responseJson?.data?.map(
          (item: Grade) => item?.attributes?.name
        ),
      })
    } else {
       this.setState({
        gradesData: [],
        gradesDisplayList:[],
        selectedCity: 'International',
        selectedSchool: '007 School',
        selectedGrade: '007 Grade',
      })
    }
  }

  handleSubmitEditAccountResponse = (responseJson: any, httpStatus?: number) => {
    if (httpStatus === 422) {
      this.setState({
        userNameError: `User name ${responseJson?.[0]?.['profile.display_name']}`,
      })
      return
    }
    if(this.state.selectedCountry!==this.state.defaultCountry){
      localStorage.removeItem("updateName")
     this.updateUnitId()
    }
    this.setState({saveButtonText: 'Saved'})
    this.getSpecificAccount(this.state.token)
    this.getCurrentUserProfile(this.state.token)
  }

  handleSubmitEditAccountChangeEmailResponse = (responseJson: any, httpStatus?: number) => {
    if (httpStatus === 422) {
      this.setState({
        showAlert: true,
        popupData: {
          text: 'Password is not correct!',
          type: 'error',
        },
      })
      return
    }
    this.setState({saveButtonText: 'Saved'})
    this.getSpecificAccount(this.state.token)
    this.getCurrentUserProfile(this.state.token)
  }

  handleDeleteAccountResponse = (responseJson: any) => {
    if (responseJson?.errors) {
      this.setState({
        showAlert: true,
        popupData: {
          text: responseJson?.errors?.[0]?.error,
          type: 'error',
        },
      })
      return
    }
    this.props.navigation.navigate('EmailRegistrationWeb')
    localStorage.removeItem('token')
  }

  checkInitialValue = (value: string | null) => value ?? ''

  getCountries = () => {
    this.getCountriesId = sendAPIRequest('account_block/countries', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  }

  getSchools = (cityId: string) => {
    this.getSchoolsId = sendAPIRequest(
      `account_block/schools?city_id=${cityId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  getGrades = () => {
    this.getGradesId = sendAPIRequest('bx_block_categories/categories', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  }


  getGradesByCountry = (country:{id:string}) => {
    this.getGradesByCountryId = sendAPIRequest(`bx_block_categories/categories/get_grades_by_country_id?country_id=${country?.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }


  getCities = () => {
    this.getCitiesId = sendAPIRequest('account_block/cities?country_id=138', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getSpecificAccount = (token: string) => {
    this.getSpecificAccountId = sendAPIRequest(
      'account_block/accounts/specific_account',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getCurrentUserProfile = (token: string) => {
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  updateUnitId= async () => {
    let profile={
      topic_name: null,
      unitid:null,
      unit_name: null,
    }
    this.changeunitIdAPICallId = await CommonApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: 'bx_block_profile/profiles/update_user_profile',
      body:profile
    });
  };

  handleCloseEmailModal = () => {
    this.setState({
      showEmailConfirmModal: false,
      password: '',
    })
  }
  handleCloseDeleteModal = () => {
    this.setState({
      showDeleteConfirmModal: false,
      password: '',
    })
  }

  handleChangePassword = (e: any) => {
    this.setState({ password: e.target.value })
  }

  getColorMood = () => {
    return {
        '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
        '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
        '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
        '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
        '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
        '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
        '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar},
        '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
    };
};

updateAvatar() {
  const color = this.state.avatarColor;
  const health = this.state.avatarHealth;

  const colorMood = this.getColorMood();

  const avatarIcon = (color !== undefined && health !== undefined) 
  ? ((colorMood as any)[color]?.[health] || null) 
  : blueHealthy;

      this.setState({ avataIcon: avatarIcon, isLoading: false });
}

  submitEditAccount = ({
    firstName,
    lastName,
    parentEmail,
    parentPhone,
    studentPhone,
    teacherEmail,
    userName,
  }: {
    firstName: string
    lastName: string
    parentEmail: string
    parentPhone: string
    studentPhone: string
    teacherEmail: string
    userName: string
  }) => {
    const categoryId = this.state.gradesData.map(item=>item.attributes.name===this.state.selectedGrade)
    const body = {
      data: {
        attributes: {
          profile_attributes: {
            id: this.state.profileId,
            display_name: userName,
            phone: studentPhone,
            parent_phone: parentPhone,
            country: this.state.selectedCountry,
            city: this.state.selectedCity,
            school: this.state.selectedSchool,
            grade: this.state.selectedGrade,
            first_name: firstName,
            last_name: lastName,
            parent_email: parentEmail,
            country_id:this.state.CountryId,
            teacher_email: teacherEmail,
            category_id:categoryId
          },
        },
      },
    }
    this.submitEditAccountId = sendAPIRequest(
      `account_block/accounts/${this.state.accountId}`,
      {
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
      }
    )
  }

  submitEditAccountChangeEmail = () => {
    const body = {
      check_password: this.state.password,
      data: {
        attributes: {
          email: this.state.submitData.email,
          profile_attributes: {
            id: this.state.profileId,
            display_name: this.state.submitData.userName,
            phone: this.state.submitData.studentPhone,
            parent_phone: this.state.submitData.parentPhone,
            country: this.state.selectedCountry,
            city: this.state.selectedCity,
            school: this.state.selectedSchool,
            grade: this.state.selectedGrade,
            first_name: this.state.submitData.firstName,
            last_name: this.state.submitData.lastName,
            parent_email: this.state.submitData.parentEmail,
            teacher_email: this.state.submitData.teacherEmail,
          },
        },
      },
    }
    this.submitEditAccountChangeEmailId = sendAPIRequest(
      `account_block/accounts/${this.state.accountId}`,
      {
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
      }
    )
    this.setState({
      password: '',
      showEmailConfirmModal: false,
    })
  }

  deleteAccount = () => {
    const body = {
      check_password: this.state.password,
    }
    this.deleteAccountId = sendAPIRequest(
      `account_block/accounts/${this.state.accountId}`,
      {
        method: 'DELETE',
        body,
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
      }
    )
    this.setState({
      password: '',
      showDeleteConfirmModal: false,
    })
  }


}
// Customizable Area End
