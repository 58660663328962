import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { createRef } from "react";

// Customizable Area Start
import { teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export const configJSON = require("./config");

interface CardItem {
    id: string,
    attributes: {
        billing_details: {
            name: string
        },
        card: {
            brand: string
            exp_month: number | null
            exp_year: number | null
            funding: string
            last4: string,
            checks: {
                cvc_checks: boolean
            }
        },
        card_id: number
    }
}
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string,
    isEdit: boolean,
    cardErrorMessageStripe: string,
    isNewCard: boolean,
    isUpdateForm: boolean,
    isOpenPopup: boolean,
    isShowDeleteCard: boolean,
    indexCardItem: number,
    defaultPaymentId: string,
    isValidField: {
        cardholderName: boolean,
        cardNumber: boolean,
        expire: boolean,
        cvv: boolean
    },
    formData: {
        cardholderName: string,
        cardNumber: string,
        expire: string,
        cvv: string,
        isSaveCard: boolean,
        isSetPrimaryPayment: boolean,
    },
    tempFormData: {
        cardholderName: string,
        cardNumber: string,
        expire: string,
        cvv: string,
        isSaveCard: boolean,
        isSetPrimaryPayment: boolean,
    }
    listCard: CardItem[]
    loading: boolean;
    teddyBear: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PlanDetailsMethodController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getCurrentAccountId = ''
    getListCardId = ''
    createCardTokenStripeId = ''
    handleCardId = ''
    deleteCardId = ''
    carousel: any = createRef()
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.goto = this.goto.bind(this)
        // Customizable Area Start
        this.handleClickGoBack = this.handleClickGoBack.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            indexCardItem: 0,
            cardErrorMessageStripe: "",
            isEdit: false,
            isNewCard: false,
            isUpdateForm: false,
            isOpenPopup: false,
            isShowDeleteCard: false,
            token: "",
            defaultPaymentId: "",
            isValidField: {
                cardholderName: true,
                cardNumber: true,
                expire: true,
                cvv: true,
            },
            formData: {
                cardholderName: '',
                cardNumber: '',
                expire: '',
                cvv: '',
                isSaveCard: true,
                isSetPrimaryPayment: false,
            },
            tempFormData: {
                cardholderName: '',
                cardNumber: '',
                expire: '',
                cvv: '',
                isSaveCard: true,
                isSetPrimaryPayment: false,
            },
            listCard: [],
            loading: false,
            teddyBear:''
            // Customizable Area End
        }

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.getCurrentAccountId) {
            const handleCallback = (jsonData: any) => {
                const { default_payment_method_id } = jsonData.attributes
                this.setState({ defaultPaymentId: default_payment_method_id })
            }
            if (responseJson.data)
                this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
            else this.props.navigation.navigate('EmailLogin')
        }
        if (apiRequestCallId === this.getListCardId) {
            const handleCallback = (jsonData: any) => {
                this.goto({ target: 0 })
                this.setState({ listCard: jsonData })
                const { name, last4, expire, isSetPrimaryPayment } = this.handleReturnDataCard(jsonData[0]);
                this.setState({
                    formData: {
                        cardholderName: name,
                        cardNumber: `•••• •••• •••• ${last4}`,
                        expire: expire,
                        cvv: '•••',
                        isSaveCard: true,
                        isSetPrimaryPayment,
                    }
                })
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
        if (apiRequestCallId === this.createCardTokenStripeId) {
            this.handleRecieveRestApi(responseJson, () => this.handleCallbackCardTokenStripe(responseJson))
        }
        if (apiRequestCallId === this.handleCardId) {
            this.handleRecieveRestApi(responseJson, () => this.handleCallbackCardAction(responseJson))
        }
    }
    async componentDidMount() {
        window.scrollTo(0, 0);
        const token = localStorage.getItem("token") ?? ""
        this.setState({ token })
        this.getCurrentAccount(token)
        this.getListCard(token)
        this.updateTeddyBear()
    }

    updateTeddyBear = () => {
        const color = localStorage.getItem('avatarColor') || '#62cdff';
        const colorToUpdateTeddy:any = {
            '#ff8400': teddyOrange,
            '#62cdff': teddyBlue,
            '#b7f0ad': teddyLightgreen,
            '#a95edb': teddyPurple,
            '#f96855': teddyRed,
            '#884a39': teddyBrown,
            '#fdce36': teddyYellow,
            '#2bce8c': teddyGreen
        };
      
        const partyTeddy = colorToUpdateTeddy[color] || teddyBlue;
            this.setState({  teddyBear: partyTeddy });
      } 

    handleClickGoBack() {
        this.props.navigation.navigate("PlanDetails")
    }

    goto({ target }: any) {
        this.carousel.goTo(Number(target))
    }

    handleCallbackCardTokenStripe(jsonData: any) {
        this.setState({ loading: false })
        if (!jsonData?.id) {
            const { message } = jsonData.error;
            this.setState({ cardErrorMessageStripe: message })
        }
        const { id } = jsonData
        if (id) {
            if (this.state.isNewCard)
                this.addNewCard(this.state.token, id)
            if (this.state.isEdit)
                this.updateCard(this.state.token, id, this.state.listCard[this.state.indexCardItem].attributes.card_id)
        }
    }

    handleCallbackCardAction(jsonData: any) {
        this.setState({ loading: false })
        // Handle delete card
        if (this.state.isOpenPopup) {
            this.getCurrentAccount(this.state.token)
            this.getListCard(this.state.token)
            this.handleResetTempFormData()
        } else {
            // Handle add and update card
            const { id } = jsonData.data
            if (id) {
                this.props.navigation.navigate("PlanDetails")
            }
        }
    }

    handleRecieveRestApi(jsonData: any, callback: () => void) {
        const isDataValid = jsonData !== null
        if (isDataValid) {
            callback();
        }
    }

    handleGetValueInput() {
        if (this.state.isEdit || this.state.isNewCard)
            return this.state.tempFormData
        else
            return this.state.formData
    }

    handleInputChange(event: any) {
        this.setState({ isUpdateForm: true })
        const { name, value, type, checked, textLength } = event.target;
        let formattedValue: string | boolean;
        if (type === 'checkbox') {
            formattedValue = checked;
        } else if (name === "expire") {
            formattedValue = value.toString().replace(/\D/g, '').slice(0, 4);

            if (textLength > 2 || value.toString().length > 2) {
                formattedValue = value.toString().replace(/[^\d]/g, '').replace(/(\d{2})(\d{2})/, '$1/$2').slice(0, 5);
            }

            this.handleValidateField(name, formattedValue as string)
        } else if (name === "cardholderName") {
            formattedValue = value.replace(/[^a-zA-Z\s]/g, '');
            this.handleValidateField(name, formattedValue as string)
        } else if (name === "cardNumber") {
            formattedValue = value.replace(/\D/g, '').slice(0, 16);
            this.handleValidateField(name, formattedValue as string)
        } else if (name === "cvv") {
            formattedValue = value.replace(/\D/g, '').slice(0, 3);
            this.handleValidateField(name, formattedValue as string)
        }
        this.setState((prevData) => ({
            ...prevData,
            tempFormData: {
                ...prevData.tempFormData,
                [name]: formattedValue
            }
        }));
    }
    handleValidateField(name: string, formattedValue: string) {
        const isValidate = this.validateInputCard(name, formattedValue)
        this.setState({ isValidField: { ...this.state.isValidField, [name]: isValidate } })
    }

    getCurrentAccount(token: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCurrentAccountId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSpecificAccountEndpoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSpecificAccountMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    createCardTokenStripe() {
        if (!this.state.isValidField.cardholderName) {
            return this.setState({ cardErrorMessageStripe: "Invalid cardholder name." })
        }

        if (!this.state.isValidField.cardNumber) {
            return this.setState({ cardErrorMessageStripe: 'Invalid card number.' })
        }

        if (!this.state.isValidField.expire) {
            return this.setState({ cardErrorMessageStripe: 'Invalid expire date.' })
        }

        if (!this.state.isValidField.cvv) {
            return this.setState({ cardErrorMessageStripe: "Invalid cvv code." })
        }

        this.setState({ loading: true })

        const stripeSecretKey = 
            baseURL === 'https://tedix-335846-ruby.b335846.prod.germanywestcentral.az.svc.builder.ai'
            ? 'pk_live_51NmhSIEHcDhXTyQacEzlg0W0BMJwipV9b22Fufx3I7zJ5RHHPigDP5CQG2JK9pdUd8i8una62KDQRrRfLc7M3dSO00fg7fFUSi' 
            : 'pk_test_51NmhSIEHcDhXTyQaBywa47u5PhZGodznJiuFLKLKvl9hSNgLWaNNqI58r4CgIAcUwZ8OVloi0Ux6BWumK8e2LHFi00rUBxYUkB'

        const getDataForm = {
            "card[number]": this.state.tempFormData.cardNumber,
            "card[exp_month]": this.state.tempFormData.expire.split('/')[0] || "",
            "card[exp_year]": this.state.tempFormData.expire.split("/")[1] || "",
            "card[cvc]": this.state.tempFormData.cvv,
            "card[name]": this.state.tempFormData.cardholderName
        }

        const data = getDataForm;
        const headers = {
            "Content-Type": 'application/x-www-form-urlencoded',
            "Authorization": `Bearer ${stripeSecretKey}`,
        };
        const body = Object.entries(data)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&')
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.createCardTokenStripeId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createStripeTokenEndPoint
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.createStrpieTokenMethod
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            headers
        )

        runEngine.sendMessage(requestMessage.id, requestMessage)
    }
    getListCard(token: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getListCardId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListCardEndpoit}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getListCardMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    addNewCard(token: string, tokenStripe: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const httpBody = {
            card: {
                token: tokenStripe,
                primary: this.handleGetValueInput().isSetPrimaryPayment
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.handleCardId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.actionCardEndpoint}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.addNewCardMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateCard(token: string, tokenStripe: string, cardId: number) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const httpBody = {
            card: {
                token: tokenStripe,
                primary: this.state.tempFormData.isSetPrimaryPayment
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.handleCardId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.actionCardEndpoint}/${cardId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateCardMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleClickDeleteCard(currentBoolean: boolean) {
        this.setState({ isShowDeleteCard: !currentBoolean })
    }
    handleCloseCardError() {
        this.setState({ cardErrorMessageStripe: "", loading: false })
    }
    handlePopupClose() {
        this.setState({ isOpenPopup: false, isShowDeleteCard: false })
    }
    handleShowDeleteModal() {
        this.setState({
            isOpenPopup: true,
        })
    }

    handleDeleteCard() {
        const currentPayment = this.state.listCard[this.state.indexCardItem].id
        this.deleteCard(this.state.token, currentPayment)
    }

    deleteCard(token: string, cardId: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.handleCardId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.actionCardEndpoint}/${cardId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteCardMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleReturnDataCard(cardItem: Pick<CardItem, "attributes" | "id">) {
        const { name } = cardItem.attributes.billing_details;
        const { brand, funding, last4, exp_month, exp_year } = cardItem.attributes.card;
        const formatMonth = exp_month && exp_month < 10 ? '0' + exp_month : exp_month;
        const expire = `${formatMonth}/${exp_year?.toString().slice(2)}`
        const paymentMethodId = cardItem.id;
        const checkIsPramaryPayment = paymentMethodId === this.state.defaultPaymentId
        return {
            cardBrand: brand,
            funding,
            last4,
            name,
            expire,
            isSetPrimaryPayment: checkIsPramaryPayment
        }
    }
    handleResetTempFormData() {
        // Reset state status
        // Make sure set state isEdit and isNew
        this.setState({ isEdit: false, isNewCard: false, isUpdateForm: false, isOpenPopup: false, isShowDeleteCard: false })
        this.setState({
            isValidField: {
                cardholderName: true,
                cardNumber: true,
                expire: true,
                cvv: true
            }
        })
        this.setState({
            tempFormData: {
                cardholderName: '',
                cardNumber: '',
                expire: '',
                cvv: '',
                isSaveCard: true,
                isSetPrimaryPayment: false,
            }
        })
    }
    handleButtonEdit() {
        if (!this.state.isUpdateForm) {
            this.setState({ isEdit: false, isNewCard: false })
        }
        if (!this.state.isNewCard && !this.state.isEdit) {
            this.handleToogleEdit(this.handleDisableInput())
        } else {
            this.handleImplementCardStripe()
        }
    }
    handleSwipeCard(index: number) {
        this.setState({ indexCardItem: index, loading: false });
        // Reset tempFormData
        this.handleResetTempFormData()
        // Handle default formData add card
        if (!this.state.listCard[index]) {
            this.setState({
                formData: {
                    cardholderName: '',
                    cardNumber: '',
                    expire: '',
                    cvv: '',
                    isSaveCard: true,
                    isSetPrimaryPayment: this.state.tempFormData.isSetPrimaryPayment,
                }
            })
        }
        else {
            const { name, isSetPrimaryPayment, expire, last4 } = this.handleReturnDataCard(this.state.listCard[index]);
            this.setState({
                formData: {
                    cardholderName: name,
                    cardNumber: `•••• •••• •••• ${last4}`,
                    expire: expire,
                    cvv: this.handleGetValueInput().cvv,
                    isSaveCard: true,
                    isSetPrimaryPayment,
                }
            })
        }
    }
    handleToogleEdit(currentBoolean: boolean) {
        this.setState({ isEdit: !currentBoolean })
    }
    handleToogleAddCard(currentBoolean: boolean) {
        this.setState({ isNewCard: !currentBoolean })
    }
    handleDisableInput() {
        return this.state.isNewCard || this.state.isEdit;
    }
    handleImplementCardStripe() {
        if (this.state.isUpdateForm)
            this.createCardTokenStripe()
    }

    validateInputCard(key: string, value: string = "") {
        switch (key) {
            case "cardholderName":
                return /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(value);
            case "cardNumber":
                return /^\d{14,16}$/.test(value);
            case "expire":
                return /^(0[1-9]|1[0-2])\/(\d{2})$/.test(value);
            case "cvv":
                return /^\d{3}$/.test(value) && value !== "000";
        }
        return true;
    }
}