// Customizable Area Start
import React from "react";
import {
    Box,
    InputAdornment,
    Typography,
    styled
} from '@material-ui/core'
import { ButtonCustom, TextInputCustom, } from '../../../components/src/MaterialTheme'
import { withTranslation } from "react-i18next";
import ChildEmailVerificationController from "./ChildEmailVerificationController.web";
import ParentSmsLayoutWeb from "./parentSmsLayout.web";
import { Formik, FormikTouched, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { checkedIcon,uncheckIcon,lockIcon } from "./assets";
// Customizable Area End

class ChildEmailVerification extends ChildEmailVerificationController {
    // Customizable Area Start

    emailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

    emailSchema = Yup.object({
        childEmail: Yup.string()
            .trim()
            .matches(this.emailRegex, this.props.t('Email invalid'))
            .email(this.props.t('Email invalid'))
            .required(this.props.t("Uh-oh! Something's not right with your email.")),

            checked:Yup.boolean()
            .oneOf([true],'You must agree to the terms & conditions')
            .required("Please accept the terms & conditions")   

    })

    showError = (touched: FormikTouched<{ childEmail: string , checked:boolean }>, errors: FormikErrors<{ childEmail: string ,checked:boolean }>, value: string) => {
        return errors[value as keyof typeof errors] && touched[value as keyof typeof touched] && <div className="required-error"><Typography className="text-danger">{errors[value as keyof typeof errors]}</Typography></div>
    }


    checkTouchedAndErrorInput = (name: boolean | undefined, error: string | undefined) => {
        return name && Boolean(error)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <BoxContainer>
                <ParentSmsLayoutWeb showGoBackBtn={true} navigation={this.props.navigation}>
                    <Box className="description_container">
                        <Typography className="email_description">{t("To complete the verification process, please provide the email linked to your child's account.")}</Typography>
                    </Box>
                    <Box>
                        <Formik
                            data-testid="formik"
                            initialValues={{
                                childEmail: "",
                                checked:false
                            }}
                            onSubmit={this.handleFormSubmit}
                            validationSchema={this.emailSchema}
                        >
                            {({ errors, touched, handleSubmit, handleChange, values,setFieldValue}) => (
                                <form className="formContainer" onSubmit={handleSubmit}>
                                    <TextInputCustom
                                        value={values.childEmail}
                                        onChange={handleChange}
                                        error={this.checkTouchedAndErrorInput(touched.childEmail, errors.childEmail)}
                                        autoComplete="off"
                                        name="childEmail"
                                        data-testid="childEmail"
                                        placeholder={this.props.t("Child’s Email")}
                                        variant="outlined"
                                        style={{ marginBottom: '16px' }}
                                        InputProps={{
                                            style: {
                                                color: 'black',
                                                fontFamily: 'Quicksand'
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img src={lockIcon} alt='emailIcon' />
                                                </InputAdornment>
                                            )
                                        } }
                                    />
                                    {this.showError(touched, errors, 'childEmail')}

                                    <Typography className="terms_condition">
                                    <Typography id='checkboxClick'  data-testid="checkboxClick" onClick={() => this.handleCheckClick(setFieldValue)} style={{ padding: '0px 8px 0px 15px', cursor: 'pointer' }}>
                                        <input name="checked" type="checkbox" id="checkbox" style={{ display: 'none' }} />
                                        {this.state.checkFlag ? <img src={checkedIcon} alt="checkicon" /> : <img src={uncheckIcon} alt="uncheck" className={errors.checked && touched.checked ? 'witherror' : ''} />}
                                    </Typography>

                                    <Typography className="terms_condition_desc">
                                    {t("By checking this box, I confirm my understanding and agreement to Tedix's")}{" "}<span className="terms_condition_text">{t("Terms & Conditions")}.</span> * 
                                    </Typography>
                                    </Typography>
                                    {this.showError(touched, errors, 'checked')}

                                    <ButtonCustom
                                        variant="contained"
                                        type="submit"
                                        disabled={values.childEmail===""||!values.checked}
                                        style={{ marginTop: '36px' }}
                                        data-test-id="submit"
                                    >
                                        {this.props.t("Submit verification")}
                                    </ButtonCustom>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </ParentSmsLayoutWeb>
            </BoxContainer>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(ChildEmailVerification)

const BoxContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    "& .email_description": {
        color: "var(--Primary-Neutrals-Warm-gray-400, #999)",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
    },

    "& .description_container": {
        marginBottom: 24
    },
    "& .text-danger": {
        color: "#F96855",
        fontStyle: "normal",
        textAlign: 'start',
        fontWeight: 500,
        fontFamily: "Quicksand",
        fontSize: "13px",
        lineHeight: "12px",
        "@media (max-width:600px)": {
            width: '300px'
        }
    },
      "& .required-error": {
        textAlign: "start",
        width: "395px",
        marginTop: "0px",
        marginBottom: "10px",
        "@media (max-width:600px)": {
            display: "flex",
            justifyContent: "center",
        }
    },
    "& .formContainer":{
        display:'flex',
        flexDirection:'column',
        alignItems:"center",
        justifyContent:'center',
    }, 
    "& .terms_condition_desc":{
        color: "var(--Primary-Neutrals-Warm-gray-400, #999)",
        fontFamily: "Quicksand",
        minWidth:244,
        maxWidth:390,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "18px",
    },
    "& .terms_condition_text":{
        color: "var(--Primary-Neutrals-Warm-gray-400, #999)",
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "18px",
        textDecorationLine: "underline",  
    },
    "& .terms_condition":{
     display:"flex",
     marginBottom:10
    }
}))
// Customizable Area End