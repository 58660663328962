import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../components/src/utils";
import { teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  settingTeddy:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PremiumDashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.MaterialPagePropsMessage),
      getName(MessageEnum.NavigationMaterialMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      settingTeddy:'',
      // Customizable Area End
    };
  
    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() { 
   this.updateTeddy()
  }

  updateTeddy = () => {
    let avatarColor = localStorage.getItem('avatarColor') || '#62cdff';
    const colorToUpdateTeddy = {
        '#ff8400': teddyOrange,
        '#62cdff': teddyBlue,
        '#b7f0ad': teddyLightgreen,
        '#a95edb': teddyPurple,
        '#f96855': teddyRed,
        '#884a39': teddyBrown,
        '#fdce36': teddyYellow,
        '#2bce8c': teddyGreen
    };
    const updateTeddy = (colorToUpdateTeddy as any)[avatarColor] || teddyBlue;
        this.setState({settingTeddy: updateTeddy });
  }

  // Customizable Area End
}
