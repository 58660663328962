import React, {Component} from "react";
// Customizable Area Start
import {Box, Typography, styled, Button} from "@material-ui/core"
import {percentIcon, addIcon, equalIcon, multipleIcon, divideIcon, rootIcon, minusIcon, yellowIcon, backButton, wrenchIcon, healthyTeddy1, healthyTeddy2, healthyTeddy3, healthyTeddy4, healthyTeddy5, healthyTeddy6, healthyTeddy7, healthyTeddy8} from './assets'
import ChangeAvatarController from "./ChangeAvatarController.web";

export default class ChangeAvatar extends ChangeAvatarController {

  render() {
    return (
    <AvatarContainer>
      <div className="mainTop">
       <div className="AvatarTop">
        <div className="AvatarTopContent">
          <img
            src={backButton}
            className="notificationArrowIcon"
            alt="backImg"
            onClick={this.backClick}
          />
          <p className="notificationTitle">Avatar</p>
        </div>
        <img src={wrenchIcon} className="AvatarTopBgImage" alt="wrenchIcon"/>
      </div>
      </div>
        <div className="cornerShape" >
      <div className="AvatarMessage">
        <Typography className="msg_1">Personalize Your Look</Typography>
        <Typography className="msg_2">Change your avatar</Typography>
      </div>

      <div className="AvatarMessage getspace">
        <div className="avatarGrid_1">
        <img src={addIcon} onClick={()=>this.changeTeddy(1)} className={this.state.centerTeddyImage === healthyTeddy1 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar1"}/>
        </div>
        
        <div className="avatarGrid_2">
            <img src={percentIcon} onClick={()=>this.changeTeddy(2)} className={this.state.centerTeddyImage === healthyTeddy2 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar2"}/>
            <img src={equalIcon} onClick={()=>this.changeTeddy(3)} className={this.state.centerTeddyImage === healthyTeddy3 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar3"}/>
        </div>
        <div className="avatarGrid_3">
            <img src={multipleIcon} onClick={()=>this.changeTeddy(4)} className={this.state.centerTeddyImage === healthyTeddy4 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar4"}/>
            <img src={this.state.centerTeddyImage} width="105.322px" height='133px'/>
            <img src={divideIcon} onClick={()=>this.changeTeddy(5)} className={this.state.centerTeddyImage === healthyTeddy5 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar5"}/>
        </div>
        <div className="avatarGrid_4">
            <img src={rootIcon} onClick={()=>this.changeTeddy(6)} className={this.state.centerTeddyImage === healthyTeddy6 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar6"}/>
            <img src={yellowIcon} onClick={()=>this.changeTeddy(7)} className={this.state.centerTeddyImage === healthyTeddy7 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar7"}/>
        </div>
        <div className="avatarGrid_5" >
        <img src={minusIcon} onClick={()=>this.changeTeddy(8)} className={this.state.centerTeddyImage === healthyTeddy8 ? "images-bg images-bg-active" : "images-bg"} data-testid={"selectAvatar8"}/>
        </div>
      </div>
     <div className="buttonPosition">
      <Button className="saveChanges" onClick={this.changeAvatar} data-testid={"changeAvatarBtn"}>Apply Changes</Button>
      </div>
      </div>
    </AvatarContainer>
    );
    
  }
}

const AvatarContainer = styled(Box)(({theme}) => ({ 
  width:'100%',
  background:'#ff8400',
  '& .mainTop': {
     backgroundColor:'#fff'
  },
  "& .AvatarTop": {
    width: "100%",
    height: "148px",
    borderRadius: "0px 0px 20px 0px",
    background: "#FF8400",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  "& .AvatarTopContent": {
    width: "100%",
    height: "45px",
    flexShrink: 0,
    color: "#fff",
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "26px",
    letterSpacing: "0.176px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative"
  },
  "& .notificationArrowIcon": {
    width: "16px",
    height: "16px",
    flexShrink: 0,
    position: "absolute",
    left: "38.3px",
    cursor: "pointer"
  },
  "& .notificationTitle": {
    margin: "0px",
    width: "min-content",
    height: "45px",
    flexShrink: 0,
    color: "var(--primary-blue-white, #fff)",
    textAlign: "center",
    fontFamily: '"Nunito", sans-serif',
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "26px",
    letterSpacing: "0.176px"
  },
  "& .AvatarTopBgImage": {
    position: "absolute",
    bottom: "0px",
    right: "5%"
  }, 
  "& .AvatarMessage": {
    display:'flex', 
    flexDirection:'column',
    justifyContent:'center', 
    paddingTop:'10%',
    alignItems: 'center'
  }, 
  "& .msg_1": {
    width: "243px",
    height: "24px",
    flexShrink: 0,
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "26px"
  }, 
  "& .msg_2": {
    width: "280px",
    height: "16px",
    flexShrink: 0,
    color: "var(--Primary-Neutrals-Warm-gray-600, #666)",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "20px",
    margintop: "15px"
  }, 
  "& .avatarGrid_1": {
    display:'flex',
     justifyContent:'center',
     width: '50%'
  }, 
  "& .avatarGrid_2": {
    display:'flex', 
    justifyContent:'space-between', 
    width: '40%',
    "@media(max-width: 959px)": {
        width: '60%'
    }
  }, 
  "& .avatarGrid_3": {
    display:'flex',
     justifyContent:'space-between', 
     width: '60%',
     alignItems:'center',
     "@media(max-width: 959px)": {
        width: '80%'
    }
  }, 
  "& .avatarGrid_4": {
    display:'flex',
     justifyContent:'space-between', 
     width: '40%',
  "@media(max-width: 959px)": {
    width: '60%'
}
  }, 
  "& .avatarGrid_5": {
    display:'flex', 
    justifyContent:'center', 
    width: '50%'
  }, 
  "& .cornerShape": {
    borderRadius: '30px 0 0 0',
    background: 'white', 
    maxHeight: '740px',
    overflow: 'auto',
    "@media(max-width: 959px)": {
        borderRadius: '0px'
    },
    "@media(max-width: 575px)": {
      maxHeight: 'calc(100vh  - 250px)'
  }
  },
  '& .saveChanges': {
    width: "260px",
    height: "40px",
    flexShrink: 0,
    borderRadius: "12px",
    background: "var(--Primary-Blue-Blue-Primary-Dark, #36BFFF)",
    color: "var(--Primary-Blue-White, var(--0, #FFF))",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "16px",
    letterSpacing: "0.8px", 
    textTransform: 'none'
  }, 
  '& .buttonPosition': {
    display:'flex',
    justifyContent:'center',
    alignItems: 'center', 
    marginTop:'40px'
  },

  '& .images-bg': {
    padding: '10px',
    borderRadius: '10px',
    height:'max-content'
  },
  '& .images-bg-active': {
    background: '#F2F2F2',
  }, 
  "& .getspace": {
   gap: '25px',
  "@media(max-width: 959px)": {
    gap:0
}
  }, 

}))
// Customizable Area End
