import React from "react";
import {
  // Customizable Area Start
  Box, Typography, Container, styled, Snackbar
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import * as Yup from 'yup';
import ForgotPasswordControllerWeb, {
  Props,
} from "./ForgotPasswordController.web";
import { Formik } from "formik";
import { Error } from "@material-ui/icons";
import { pandaIcon } from '../src/assets'
import { ButtonCustom, TextInputCustom } from "../../../components/src/MaterialTheme";
import SelectLanguage from "../../../components/src/SelectLanguage";
import Header from "../../../components/src/Header";
import { withTranslation } from "react-i18next";

// Customizable Area End

 class ForgotPassword extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  emailSchema = Yup.object().shape({
    email: Yup.string().email(this.props.t('Invalid email')).required(this.props.t('Please enter email address')),
  })

  handleClose = () => {
    this.setState({showErrorAlert: false})
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ForgotPasswordContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <div />
          <Box marginTop='24px' flexDirection="column" alignItems="center" display="flex">
            <Box className="pandaWrapper">
              <img alt="panda" className="pandaImage" src={pandaIcon} />
            </Box>
            <Typography className="forgotPasswordTitle">
              {this.props.t("Forgotten your password? No worries!")}
            </Typography>
            <Typography className="forgotPasswordDesc">
              {this.props.t("Enter the email linked to your account, and we'll send you a password reset link.")}
            </Typography>
            <Formik
              initialValues={{ email: '' }}
              validationSchema={this.emailSchema}
              onSubmit={(values) => {
                console.log('click form submit')
                this.submitFormData(values.email)
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form
                  className="formContainer"
                  onSubmit={handleSubmit}
                >
                  <TextInputCustom
                    value={values.email}
                    onChange={handleChange}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                    disabled={this.state.isLoading}
                    name="email"
                    placeholder={this.props.t("Email")}
                    variant="outlined"
                    autoComplete="off"
                    style={{ marginBottom: '32px' }}
                  />
                  <ButtonCustom
                    variant="contained"
                    type="submit"
                    disabled={this.state.isLoading}
                  >
                    {this.props.t("Send Link")}
                  </ButtonCustom>
                </form>
              )}
            </Formik>
          </Box>
          <SelectLanguage style={{ marginBottom: '72px', marginTop: '24px' }} />
        </Container>
        <Snackbar 
          className="snackBar"
          open={this.state.showErrorAlert} 
          onClose={this.handleClose}  
          anchorOrigin={{horizontal: 'right', vertical: 'top'}} 
          autoHideDuration={2000}
        >
          <Box className="snackBarWrapper">
            <Error />
            <Typography className="snackBarText" >{this.state.errorText}</Typography>
          </Box>
        </Snackbar>
      </ForgotPasswordContainer>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export default withTranslation()(ForgotPassword)
const ForgotPasswordContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",


  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .pandaWrapper': {
    backgroundColor: '#f7f7f7',
    borderRadius: '20px',
    marginBottom: '66px',
    boxShadow: '0 4px 10px #ddd'
  },

  '& .pandaImage': {
    width: '100px',
    height: "auto",
  },

  '& .forgotPasswordTitle': {
    fontSize: '22px',
    fontWeight: '900',
    textAlign: 'center',
    fontFamily: "Nunito",
    color: '#363636',
  },

  '& .forgotPasswordDesc': {
    color: '#666666',
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: "Quicksand",
    fontWeight: '700',
  },

  '& .formContainer': {
    marginTop: '36px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .snackBarWrapper': {
    maxWidth: '300px', 
    borderRadius: '4px', 
    backgroundColor: '#F96855', 
    padding: '6px 16px', 
    display: 'flex', 
    alignItems: 'center', 
    color: '#fff',
  },

  '& .snackBarText': {
    overflow: 'hidden',
    wordWrap: 'break-word',
    fontSize: '16px',
    fontFamily: "Quicksand",
    fontWeight: '500',
    marginLeft: '12px',
  },
}))

  // Customizable Area End