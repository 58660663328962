import React, { useState } from "react";
import { loadStripe } from '@stripe/stripe-js';

// Replace 'your_publishable_key' with your actual Stripe publishable key
const stripePromise = loadStripe('pk_test_51O7F7qSAe557wV2oIdoyiO29BEyvD1ohZP2nK6wxoTBbk4znDOCvSKsF2sEHiZr0Pk4DMQ4w34p4SZdmOvGLfi5x007pVmCwtM');
import Carousel from 'react-elastic-carousel';
import Grid from '@material-ui/core/Grid';
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
    Dialog,
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    button_back,
    group_state2,
    skip_men,
    skip_password,
    skip_calendar,
    skip_card,
    group_teddy1,
    group_mail,
    add_card,
    skip_delete,
    card_bg,
    groupBear,
    loader
} from "./assets";
import { withTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End
import { Props } from "./StripePayments";
import StripePaymentsMethodController from "./StripePaymentsMethodController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";
// Customizable Area Start
class StripePaymentsMethod extends StripePaymentsMethodController {
    constructor(props: Props) {
        super(props);
    }

    renderCarouselCard(item: any, index: number) {
        return (
            <Box>
                <Box
                    onClick={this.handleShowDeleteCard} id="card-container">
                    <Box
                        style={{
                            position: 'relative'
                        }}
                    >
                        <Box className="card-bg" />
                        <Box className="card-bg top" />
                        <Box className="parentTextSection" style={{ filter: this.state.showDeleteCard ? 'blur(5px)' : 'none' }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }} className="creditTitle">
                                <span className="creditTitileText">{this.props.t("Credit")}</span>
                                <span> <img src={this.getCardLogo(item.brand)} className="" alt="visa img" /></span>
                            </div>
                            <Typography className="cardNumber">
                                •••• •••• •••• {item.cardNumber}
                            </Typography>
                            <Grid container spacing={2}>

                                <Grid item xs={4}>
                                    <div className="mainSection1">
                                        <span className="textTitle1">{this.props.t("CARDHOLDER")}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="mainSection1">
                                        <span className="textTitle2">{this.props.t("EXPIRE")}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="mainSection1">
                                        <span className="textTitle3">CVV</span>
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div className="mainSection">
                                        <span className="textTitileCard" style={{ width: '100px' }}>{item.cardholderName}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="mainSection">
                                        <span className="datetext">{item.expire}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="mainSection">
                                        <span className="textcvv">•••</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>

                        {this.state.showDeleteCard &&
                            <Box className="deleteCardOverlay" onClick={this.handleClickDeleteCard} id="delete-card-overlay">
                                <div className="deleteCardOverlayContent">
                                    <img src={skip_delete} alt="group_mail" />
                                    <Typography className="deleteCardOverlayText">
                                        {this.props.t("Delete card")}
                                    </Typography>
                                </div>

                            </Box>
                        }
                    </Box>
                </Box>

            </Box>
        )
    }

    renderCarouselCardContainer(items: any) {
        return items.map((item: any, index: any) => {
            return this.renderCarouselCard(item, index);
        });
    };

    render() {
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <NavigateLayout menuName="Settings" isShowBottomMenu={false}>
                    <SettingContainer>
                        <Box className="mainPremiumWrSubWrapper">
                            <Box className="settingCheckBackground">
                                {/* <Box className="settingImgesWrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className="settingesTitleMeth">{this.props.t("Settings")}</Typography>
                                    <img src={group_teddy1} className="teddyesImageMeth" alt="Teddy Setting" style={{ alignSelf: 'center' }} />
                                </Box> */}
                                <CenterPortion navigation={undefined} id={""} />
                            </Box>
                            <Box className="contentPreminumContainer">
                                <Box style={{ display: 'flex', alignItems: 'flex-start', width: '100%', justifyContent: 'space-between', }}>
                                    <img src={button_back} className="" alt="back button" style={{ marginRight: '10px' }} onClick={this.handleClickGoBack} id="go-bck-btn" />
                                    <Typography style={webStyle.premiumtextTitle}>
                                        {this.props.t("Payment method")}
                                    </Typography>
                                    <Typography style={{ ...webStyle.canceltextTitle, cursor: 'pointer' }} onClick={this.handleClickCancel} data-test-id="cancel-btn">
                                        {this.props.t("Cancel")}
                                    </Typography>
                                </Box>

                                <Box>
                                    <img src={group_state2} className="group_stateImage" alt="group Setting" />
                                </Box>

                                {this.state.getCardListLoading ? <img src={loader} alt="Loading..." style={{ marginTop: '100px' }} /> : <Carousel isRTL={false} showArrows={false} onChange={this.handleChangeCarousel} data-test-id="carousel">
                                    {this.renderCarouselCardContainer(this.state.itemsArray)}
                                    <Box>
                                        <Box className="" data-test-id="btnSubmitThir" onClick={this.handleClickAddNewCard}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: '65px',
                                            }}>
                                                <img src={add_card} className="" alt="add card" />
                                            </div>
                                        </Box>
                                    </Box>
                                </Carousel>}


                                <div style={{
                                    width: '292px',
                                    height: '281px',
                                    top: '286px',
                                    left: '51px',
                                    borderRadius: '12px',
                                    marginBottom: '40px',
                                }}>
                                    <Dialog
                                        open={this.state.openPopup}
                                        onClose={this.handlePopupClose}
                                        maxWidth="sm"
                                        fullWidth
                                        style={{
                                            width: '100%',
                                            borderColor: '#E6E6E6',
                                        }}
                                    >
                                        <div style={{ padding: '30px 26px 25px 26px', borderRadius: '12px', border: 'solid 2px #E6E6E6', textAlign: 'center' }}>
                                            <Box>
                                                <img src={group_mail} alt="group_mail" style={{ display: 'block', margin: 'auto' }} />
                                                <Typography className="deleteCardText" style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '18px',
                                                    fontWeight: 900,
                                                    lineHeight: '26px',
                                                    letterSpacing: '0em',
                                                    textAlign: 'center',
                                                    color: '#363636',
                                                }}>
                                                    {this.props.t("Delete card")}
                                                </Typography>
                                                <Typography className="textTitlemiddle">
                                                    {this.props.t("Are you sure you want to delete this credit/debit card?")}
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                padding: '10px 21px',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                lineHeight: '12px',
                                                letterSpacing: '0.800000011920929px',
                                                textAlign: 'center',
                                            }}>
                                                <Button
                                                    id="cancel-delete-btn"
                                                    onClick={this.handlePopupClose}
                                                    color="primary"
                                                    className="cancelBtnText"
                                                    data-test-id="btnSubmitSec"
                                                    style={{
                                                        width: '110px',
                                                        height: '41.9px',
                                                        borderRadius: '12px',
                                                        background: 'white',
                                                        border: '2px solid #CCCCCC',
                                                        color: '#CCCCCC',
                                                        textTransform: 'none',
                                                        fontWeight: 700,
                                                    }}
                                                >
                                                    {this.props.t("Cancel")}
                                                </Button>
                                                <Button color="primary" className="deleteBtnText" style={{
                                                    width: '110px',
                                                    height: '44px',
                                                    backgroundColor: '#F96855',
                                                    borderRadius: '12px',
                                                    border: '0px solid #EA5A47',
                                                    borderBottomWidth: '2px',
                                                    textTransform: 'none',
                                                    fontWeight: 700,
                                                    color: '#fff'
                                                }}
                                                    onClick={this.deleteCard} id="confirm-delete-btn">
                                                    {this.props.t("Delete")}
                                                </Button>
                                            </Box>
                                        </div>
                                    </Dialog>
                                </div>

                                {this.state.isNewCard &&
                                    <>
                                        <div>
                                            <Box>
                                                <Box style={webStyle.textFieldBox}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={this.props.t("Cardholder name")}
                                                        name="cardholderName"
                                                        value={this.state.formData.cardholderName}
                                                        onChange={this.handleInputChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={skip_men} alt="Image center" style={webStyle.iconImg} />
                                                                </InputAdornment>
                                                            ),
                                                            style: {

                                                                fontFamily: 'Quicksand',
                                                                fontSize: '16px',
                                                                fontWeight: 500,
                                                                lineHeight: '12px',
                                                                letterSpacing: '0px',
                                                                textAlign: 'left',
                                                                color: '#4D4D4D',
                                                                ...!this.validate('cardholderName', this.state.formData.cardholderName) && this.state.edittedField.cardholderName && {
                                                                    border: '1px solid red'
                                                                }
                                                            },
                                                        }}
                                                        style={webStyle.cardtextField}
                                                    />
                                                </Box>
                                                <Box style={webStyle.textFieldBox}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={this.props.t("Card number")}
                                                        name="cardNumber"
                                                        value={this.state.formData.cardNumber}
                                                        onChange={this.handleInputChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={skip_card} alt="Image" style={webStyle.iconImg} />
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                ...!this.validate('cardNumber', this.state.formData.cardNumber) && this.state.edittedField.cardNumber && {
                                                                    border: '1px solid red'
                                                                }
                                                            }
                                                        }}
                                                        style={webStyle.cardtextField}
                                                    />
                                                </Box>
                                                <div style={webStyle.textFieldBox1}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder={this.props.t("Expire")}
                                                        name="expire"
                                                        value={this.state.formData.expire}
                                                        onChange={this.handleInputChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={skip_calendar} alt="Image" style={webStyle.iconImg} />
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                ...!this.validate('expire', this.state.formData.expire) && this.state.edittedField.expire && {
                                                                    border: '1px solid red'
                                                                }
                                                            }
                                                        }}
                                                        style={webStyle.cardtextField1}
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="CVV"
                                                        name="cvv"
                                                        value={this.state.formData.cvv}
                                                        onChange={this.handleInputChange}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <img src={skip_password} alt="Image" style={webStyle.iconImg} />
                                                                </InputAdornment>
                                                            ),
                                                            style: {
                                                                ...!this.validate('cvv', this.state.formData.cvv) && this.state.edittedField.cvv && {
                                                                    border: '1px solid red'
                                                                }
                                                            }
                                                        }}
                                                        style={{ ...webStyle.cardtextField1, marginRight: 0 }}
                                                    />
                                                </div>
                                            </Box>

                                            <Box style={{ width: "250px", margin: "10px" }}>
                                                <label className='containerCheckbox' style={webStyle.checkboxLabel2}>
                                                    <input name="agreeToTerms" onChange={this.handleInputChange} type="checkbox" checked={this.state.formData.agreeToTerms} />
                                                    <span style={webStyle.checkboxText}>
                                                        {this.props.t("Save card and details securely")}
                                                    </span>
                                                    <span className={`checkmark${!this.state.formData.agreeToTerms ? ' unchecked' : ""}`}></span>
                                                </label>
                                            </Box>
                                        </div>
                                    </>
                                }

                                <Button style={this.disablePayBtn() ? webStyle.btnStyleTextDisabled : webStyle.btnStyleText} onClick={this.handleFormSubmit} data-test-id="btnSubmit" disabled={this.disablePayBtn()}>
                                    {this.state.createNewCardLoading ? <img src={loader} alt="Loading..." width={40} /> : <span className="pay-btn-text" style={this.disablePayBtn() ? webStyle.btnStyleHeadDisabled : webStyle.btnStyleHead}>
                                        {this.props.t("Pay")} {this.state.amountToPay} €
                                    </span>}
                                </Button>

                                <Box style={webStyle.textlastTitle}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            lineHeight: '15px',
                                            letterSpacing: '0px',
                                            textAlign: 'center',
                                            color: '#E6E6E6',
                                            marginTop: '10px'
                                        }}
                                    >
                                        {this.props.t("Under 18? Ask a grown-up for help. Tedix cares about your safety!")}
                                    </Typography>
                                </Box>

                                <DialoagContainer
                                    data-testid='dialog-card-error'
                                    open={Boolean(this.state.cardErrorMessageStripe)}
                                    onClose={this.handlePopupClose}
                                    fullWidth
                                    maxWidth="xs"
                                    className="error-dialog"
                                >
                                    <div className="dialog-content-wrapper">
                                        <Box>
                                            <img src={groupBear} className="group-bear-img" alt="group_mail" />
                                            <Typography className="try-again-text">
                                                {this.props.t("Let's try again")}
                                            </Typography>
                                            <Typography className="error-message">
                                                {this.props.t(this.state.cardErrorMessageStripe)}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <Box className="dialog-btn-container">
                                        <Button
                                            id="try-again-btn"
                                            onClick={() => this.handleCloseCardError()}
                                            color="primary"
                                            data-test-id="btnTryAgain"
                                            className="dialog-btn-text"
                                        >
                                            {this.props.t("Try again")}
                                        </Button>
                                    </Box>
                                </DialoagContainer>
                            </Box>
                        </Box>
                    </SettingContainer>
                </NavigateLayout>
            </>
            // Customizable Area End
        );
    };
}
// Customizable Area Start

const DialoagContainer = styled(Dialog)(({ theme }) => ({
    ".MuiPaper-root": {
        borderRadius: '12px'
    },

    "& .dialog-content-wrapper": {
        padding: '30px 26px 25px 26px',
        textAlign: 'center'
    },

    "& .group-bear-img": {

        display: 'block',
        margin: 'auto'
    },

    "& .try-again-text": {

        fontFamily: 'Nunito',
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: 900,
        letterSpacing: '0em',
        color: '#363636',
        textAlign: 'center'
    },

    "& .error-message": {
        marginTop: '12px',
    },

    "& .dialog-btn-container": {

        display: 'flex',
        justifyContent: 'center',
        padding: '21px 0px',
        fontFamily: 'Nunito',
        fontSize: '16px',
        lineHeight: '12px',
        letterSpacing: '0.8px',
        textAlign: 'center'
    },

    "& .dialog-btn-text": {

        width: '110px',
        height: '41.9px',
        borderRadius: '12px',
        background: '#36BFFF',
        border: '2px solid #36BFFF',
        color: '#FFFFFF',
        fontWeight: 700,
        borderBottom: "4px solid #00AEFF",
        textTransform: 'none'
    }
}))

const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .rec-dot": {
        width: '8px',
        height: '8px',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#CCCCCC'
    },

    "& .rec-dot_active": {
        border: 'none',
        backgroundColor: 'white'
    },

    "& .rec-dot:hover": {
        boxShadow: 'none'
    },

    "& .rec-dot:focus": {
        boxShadow: 'none'
    },

    "& .pay-btn-text": {
        textTransform: 'none'
    },

    "& .containerCheckbox": {
        position: 'relative',

    },

    "& .checkmark": {
        "border-radius": "4px",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "height": "18px",
        "width": "18px",
        "border": "3px solid #36BFFF",
        "backgroundColor": "#eee"
    },
    '& .unchecked': {
        border: "2px solid #E6E6E6"
    },

    "& .containerCheckbox input": {
        width: '18px',
        height: '18px',
        margin: 0,
        opacity: 0,
        position: 'relative'
    },
    "& .containerCheckbox:hover input ~ .checkmark": {
        "backgroundColor": "#ccc"
    },
    "& .containerCheckbox input:checked ~ .checkmark": {
        "backgroundColor": "#FFF"
    },
    "& .checkmark:after": {
        "content": "\"\"",
        "position": "absolute",
        "display": "none"
    },
    "& .containerCheckbox input:checked ~ .checkmark:after": {
        "display": "block"
    },
    "& .containerCheckbox .checkmark:after": {
        "left": "3px",
        "top": "1px",
        "width": "6px",
        "height": "8px",
        "border": "solid #62CDFF",
        "borderWidth": "0 3px 3px 0",
        "WebkitTransform": "rotate(45deg)",
        "MsTransform": "rotate(45deg)",
        "transform": "rotate(45deg)"
    },

    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },

    '& .MuiSvgIcon-root': {
        fill: 'white'
    },
    '& .textcvv': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .cardNumber': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.45em',
        textAlign: 'left',
        color: '#FFFFFF',
        marginTop: '35px',
    },
    '& .deleteBtnText': {
        width: '110px',
        height: '44px',
        borderColor: '#E6E6E6',
        backgroundColor: '#F96855',
        color: '#fff',
    },
    '& .deleteCardText': {
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#363636',
    },
    '& .textTitlemiddle': {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0px',
        textAlign: 'center',
        color: '#666666',
        marginTop: '10px',
    },
    '& .cancelBtnText': {
        width: '110px',
        height: '41.9px',
        borderRadius: '12px',
        background: 'white',
        border: '2px solid #CCCCCC',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#CCCCCC',
    },
    '& .creditTitileText': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
    },
    "& .creditTitle": {
        position: 'relative',

        "& img": {
            width: '49px',
            position: 'absolute',
            right: '0',
            top: '-5px'
        }
    },
    '& .datetext': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .textTitileCard': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '& .mainSection': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
        maxWidth: '200px',
        maxHeight: '100px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    '& .deleteCardOverlay': {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        width: '100%',
        height: '100%',
        top: 0,
        borderRadius: '15px',
        display: 'flex',
        color: 'white',
        zIndex: 4,
    },
    '& .deleteCardOverlayContent': {
        display: 'flex',
        margin: 'auto',
        gap: '16px'
    },
    '& .deleteCardOverlayText': {
        fontSize: '16px',
        fontWeight: 700
    },
    '& .mainSection1': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '32px',
        maxWidth: '200px',
        maxHeight: '100px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    '& .textTitle1': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
    },
    '& .textTitle2': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .textTitle3': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .parentTextSection': {
        width: '320px',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        borderRadius: '12px',
        padding: '30px',
        marginTop: '55px',
        position: 'relative',
        zIndex: 3
    },
    "& .card-bg": {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
        backgroundImage: `url(${card_bg})`,
        backgroundSize: 'cover'
    },
    "& .card-bg.top": {
        zIndex: 2,
        filter: 'blur(10px)'
    },
    '& .slider-dots': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },

    // '& .dot': {
    //     width: '10px',
    //     height: '10px',
    //     backgroundColor: '#aaa',
    //     borderRadius: '50%',
    //     margin: '0 5px',
    //     cursor: 'pointer',
    // },

    '& .dot.active': {
        backgroundColor: '#333',
    },

    '& .mainPremiumWrSubWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .settingCheckBackground': {
        backgroundColor: '#fff',
    },

    '& .group_stateImage': {
        marginTop: '29px'
    },


    '& .settingImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },


    '& .settingesTitleMeth': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .contentPreminumContainer': {
        flex: '6',
        display: 'flex',
        padding: '32px 24px 100px',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
        overflow: 'auto',
    },



    '& .teddyesImageMeth': {
        bottom: '0px',
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',

    },

    [theme.breakpoints.up('md')]: {
        '& .mainPremiumWrSubWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },

        '& .settingCheckBackground': {
            flex: 5,
            position: 'fixed',
            width: 'calc(50% - 120px)',
            height: '100vh'
        },
        '& .settingImgesWrapper': {
            paddingTop: 'unset',
            padding: 0,
        },

        '& .settingesTitleMeth': {
            fontSize: '30px',
            marginBottom: 'unset',
        },


        '& .teddyesImageMeth': {
            maxWidth: '240px',
        },

        '& .contentPreminumContainer': {
            paddingTop: '90px',
            maxWidth: '50%',
            position: 'relative',
            left: "50%"
        },

    },

    [theme.breakpoints.up('lg')]: {
        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

        "& .teddyesImageMeth": {
            maxWidth: 'unset'
        }
    },
    [theme.breakpoints.down('sm')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
    },
    [theme.breakpoints.down('xs')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
    },

}))

const webStyle = {
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },
    textlastTitle: {
        width: '224px',
        height: '35px',
        top: '784px',
        left: '85px',
    },
    btnStyleText: {
        backgroundColor: '#36BFFF',
        width: '260px',
        height: '40px',
        borderRadius: '12px',
        marginTop: '12px',
    },
    btnStyleTextDisabled: {
        height: '40px',
        backgroundColor: '#E6E6E6',
        width: '260px',
        marginTop: '12px',
        borderRadius: '12px',
    },
    btnStyleHead: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.8px',
        textAlign: 'center' as 'center',
        color: '#fff',
        height: '40px',
        padding: '13px',
    },
    btnStyleHeadDisabled: {
        fontFamily: 'Nunito',
        letterSpacing: '0.8px',
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'center' as 'center',
        color: '#B3B3B3',
        fontWeight: 700,
        height: '40px',
        padding: '13px',
    },
    checkboxLabel: {
        display: "flex",
        alignItems: "center",
        marginTop: '10px'
    },

    checkboxLabel2: {
        display: "flex",
        alignItems: "center",
    },
    checkboxText: {
        textAlign: "left" as "left",
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0px',
        color: '#F2F2F2',
        marginLeft: '14px',
    },
    checkboxsText: {
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
    },
    // checkboxText: {
    //     textAlign: "left" as "left",
    //     fontFamily: 'Quicksand',
    //     fontSize: '14px',
    //     fontWeight: 500,
    //     lineHeight: '10px',
    //     letterSpacing: '0px',
    //     color: '#F2F2F2',
    // },

    cardtextField: {
        borderRadius: "10px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "12px",
        letterSpacing: "0px",
        textAlign: "left" as "left",
        color: "#999999",
        backgroundColor: "white",
    },
    cardtextField1: {
        borderRadius: "10px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "12px",
        letterSpacing: "0px",
        textAlign: "left" as "left",
        color: "#999999",
        backgroundColor: "white",
        marginRight: "10px",
    },
    iconImg: {
        marginRight: "10px", // Margin for spacing
        width: "24px", // Adjust width as needed
        height: "24px", // Adjust height as needed
    },
    textFieldBox: {
        margin: "20px 0",
    },
    textFieldBox1: {
        display: "flex",
        alignItems: "center",
        marginRight: "0px",
    },
    canceltextTitle: {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontWeight: 900,
        lineHeight: '12px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#fff',
        display: 'flex',
        width: '16px',
        justifyContent: "end"
    },
    premiumtextTitle: {
        fontFamily: 'Nunito',
        fontSize: '25px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        width: '102px',
        paddingLeft: '20px',
        margin: '0 auto',
        padding: '0px',

    },
    premiumText: {
        fontFamily: 'Quicksand',
        fontSize: '22px',
        fontWeight: 700,
        color: '#fff'
    },
};

export default withTranslation()(StripePaymentsMethod)
// Customizable Area End
