// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  styled
} from "@material-ui/core";
import * as Yup from "yup";
import SignUpController, { Props, configJSON } from "./SignUpController";
import { Formik } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  ButtonCustom,
  SecondaryButtonCustom,
  TextInputCustom
} from "../../../components/src/MaterialTheme";
import Header from "../../../components/src/Header";
import SelectLanguage from "../../../components/src/SelectLanguage";
import {checkedIcon, emailIcon, lockIcon } from "./assets";
import GoogleSignUpComponent from "../../../components/src/GoogleSignUP";
import { Toaster } from "../../../components/src/Toaster";
import { withTranslation } from "react-i18next";

 class SignUp extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  validatePassword = [
    {
      regex: configJSON.lower,
      text: this.props.t("One lowercase letter")
    },
    {
      regex: configJSON.length,
      text: this.props.t("8 characters minimum")
    },
    {
      regex: configJSON.digit,
      text: this.props.t("One number")
    }
  ];
  
  signUpSchema = Yup.object({
    email: Yup.string()
      .email(this.props.t("Uh-oh! Something's not right with your email"))
      .matches(
        /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        this.props.t("Uh-oh! Something's not right with your email")
      )
      .required(this.props.t("Please enter your email address")),
    password: Yup.string()
      .required(this.props.t("Please enter your password"))
  });

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: "18px",
          height: "18px",
          color: "#ccc"
        }}
      />
    ) : (
      <Visibility
        style={{
          width: "18px",
          height: "18px",
          color: "#ccc"
        }}
      />
    );
  };

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  checkValidatePassword = (password: string) => {
    let isValidPassword = true;
    this.validatePassword.forEach(item => {
      if (!item.regex.test(password)) {
        isValidPassword = false;
      }
    });
    return isValidPassword;
  };

  renderValidatePassword = (password: string) => (
    <div className="validatePasswordWrapper">
      {this.validatePassword.map(item => {
        const isValid = item.regex.test(password);
        return (
          <div
            className="validatePasswordItem"
            style={{
              backgroundColor: isValid ? "#E3F9F0" : "#F2F2F2",
              color: isValid ? "#2BCE8C" : "#999999"
            }}
          >
            {isValid && (
              <img
                src={checkedIcon}
                alt="checkIcon"
                className="check-icon"
                style={{ marginRight: "8px" }}
              />
            )}
            {item.text}
          </div>
        );
      })}
    </div>
  );


  handleClickShowPassword = () =>
    this.setState({
      showPassword: !this.state.showPassword
    });

  handleNavigateTermsConds = () =>
    this.props.navigation.navigate("TermsConditionsRegister");

  handleGetExplore = () =>
    this.props.navigation.navigate("Categoriessubcategories");

  render() {
    return (
      <SignUpContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentWrapper" maxWidth="md">
          <Box className="content">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="numberWrapper"
                style={{
                  backgroundColor: "#62CDFF",
                  marginRight: "-2px"
                }}
              >
                1
              </div>
              <div className="lineWrapper" />
              <div
                className="numberWrapper"
                style={{
                  backgroundColor: "#C9EEFF",
                  marginLeft: "-2px"
                }}
              >
                2
              </div>
            </div>
            <Typography className="signUpTitle">{this.props.t("Start your profile")}</Typography>
            <Toaster label={this.props.t(this.state.formError.email)} />
            <Formik
              initialValues={{
                email: this.state.signUpData.email || "",
                password: this.state.signUpData.password || ""
              }}
              validationSchema={this.signUpSchema}
              onSubmit={values => {
                if (this.checkValidatePassword(values.password)) {
                  this.submitValidateAccount(values.email, values.password);
                }
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  <TextInputCustom
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && !!errors.email} 
                    helperText={touched.email && errors.email}
                    disabled={this.state.isLoading}
                    autoComplete="off"
                    name="email"
                    placeholder={this.props.t("Email")}
                    variant="outlined"
                    style={{ marginBottom: "16px", marginTop: "16px" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled edge="start">
                            <img
                              src={emailIcon}
                              style={{ width: "18px", height: "18px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextInputCustom
                    value={values.password}
                    onChange={handleChange}
                    error={
                      Boolean(touched.password && errors.password) ||
                      Boolean(this.state.formError.password)
                    }
                    helperText={
                      (touched.password && errors.password) ||
                      this.state.formError.password
                    }
                    disabled={this.state.isLoading}
                    autoComplete="off"
                    name="password"
                    placeholder={this.props.t("Password")}
                    variant="outlined"
                    type={!this.state.showPassword ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="passwordConfirmationInput"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            data-test-id="showPasswordIconButton"
                          >
                            {this.renderIconShowPassword(
                              !this.state.showPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled edge="start">
                            <img
                              src={lockIcon}
                              style={{ width: "18px", height: "18px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  {values.password.trim().length > 0 &&
                    this.renderValidatePassword(values.password)}
                  <ButtonCustom
                    disabled={this.state.isLoading}
                    style={{ marginTop: "36px" }}
                    variant="contained"
                    type="submit"
                  >
                    {this.props.t("Create account")}
                  </ButtonCustom>
                   
                 <div className="google-signup-btn">
                    <GoogleSignUpComponent
                    handleGoogleLogin={this.handleGoogleSignup} 
                   onGoogleSignupSuccess={this.handleGoogleSignupSuccess} 
                    />
                  </div> 
                </form>
              )}  
            </Formik> 
            <Typography className="termText">
             {this.props.t("By signing in to Tedix, you agree to our")}{" "}
              <span
                className="termTextHighLight"
                onClick={this.handleNavigateTermsConds}
              >
                {this.props.t("Terms & Conditions")}.
              </span>
            </Typography>
            <div className="orContainer">
              <div className="orLine" />
              <Typography className="orText">{this.props.t("Or")}</Typography>
              <div className="orLine" />
            </div>
            <SecondaryButtonCustom onClick={this.handleGetExplore}>
             {this.props.t("I just want to explore")}
            </SecondaryButtonCustom>
          </Box>
          <SelectLanguage style={{ marginBottom: "48px", marginTop: "24px" }} />
        </Container>
      </SignUpContainer>
    );
  }
}

export default withTranslation()(SignUp) 

const SignUpContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",

  "& .contentWrapper": {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  "& .content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "24px"
  },

  "& .numberWrapper": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 22,
    height: 22,
    borderRadius: "50%",
    fontFamily: "Nunito",
    fontWeight: "700",
    fontSize: "12px",
    color: "#fff",
    backgroundColor: "#62CDFF",
    zIndex: 9
  },

  "& .lineWrapper": {
    width: "44px",
    height: "6px",
    backgroundColor: "#C9EEFF"
  },

  "& .signUpTitle": {
    fontSize: "22px",
    fontWeight: "900",
    fontFamily: "Nunito",
    textAlign: "center",
    color: "#363636",
    marginTop: "32px",
    marginBottom: '48px',
  },

  "& .formContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  "& .termText": {
    fontFamily: "Quicksand",
    fontSize: "14px",
    color: "#999999",
    fontWeight: "300",
    marginTop: "32px",
    maxWidth: "250px",
    textAlign: "center"
  },

  "& .termTextHighLight": {
    fontWeight: "500",
    cursor: "pointer",
    textDecoration: "underline",
  },

  "& .orContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "32px",
    marginBottom: "32px"
  },

  "& .orLine": {
    height: "2px",
    backgroundColor: "#E6E6E6",
    flex: 1
  },

  "& .orText": {
    fontFamily: "Nunito",
    fontWeight: 900,
    color: "#E6E6E6",
    textTransform:"uppercase",
    fontStyle: "normal",
    fontSize: "13px",
    margin: "0 16px"
  },

  "& .validatePasswordWrapper": {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "300px"
  },

  "& .validatePasswordItem": {
    marginTop: "8px",
    marginRight: "10px",
    padding: "4px 8px",
    borderRadius: "12px",
    fontSize: "13px",
    fontWeight: "500",
    fontFamily: "Quicksand"
  },
 "& .google-signup-btn": {
        borderRadius: "5px",
        width: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0 0 0',
        "@media (max-width:599px)": {
            width: '100%',
        },
        "& span": {
            color: "var(--primary-neutrals-warm-gray-500, #7F7F7F)",
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Nunito",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: '700 !important',
            lineHeight: "12px",
            letterSpacing: "0.8px",
            "@media (max-width:600px)": {
                fontSize: "16px",
            }
        },
         "& button": {
            width: '400px',
            borderRadius: "12px !important",
            border: "2px solid #999 !important",
            background: "#FFF",
            boxShadow: '0px 2px #999999 !importavnt',
            height: '44px',
            justifyContent: 'center',
            "@media (max-width:600px)": {
                width: '260px',
            }
        },
    },
  [theme.breakpoints.up("md")]: {
    "& .validatePasswordWrapper": {
      maxWidth: "400px"
    }
  }
}));

// Customizable Area End
