import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import { TFunction } from "i18next";


export interface IError{
  errors:string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation:any
  t:TFunction
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkFlag:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End



export default class ChildEmailVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
     parentVerification:string=""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      this.state = {
        checkFlag:false
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   // Customizable Area Start

   handleCheckClick = ( setValue: (key: string, value: boolean) => void) => {
    const check = document.getElementById('checkbox')
    if (check) {
        this.setState({ checkFlag: !this.state.checkFlag })
        setValue('checked', !this.state.checkFlag)
    }
}
   handleFormSubmit=async (value:{childEmail:string})=>{
  const Phonenumber = JSON.parse(localStorage.getItem("parentPhone") as string)
    const body = {
      data: {
        email: value.childEmail,
        phone_number:Phonenumber.parentPhone
    }}
    
   this.parentVerification = sendAPIRequest('account_block/accounts/parent_verify', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body
    })
    }

    handleParentVarification=(responseJson:IError)=>{
      if(responseJson?.errors){
        this.props.navigation.navigate("ParentVerificationFailure")
      }else{
         this.props.navigation.navigate("ParentVerificationSuccess")
      }
    }

    async receive(from: string, message: Message) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId===this.parentVerification){
          this.handleParentVarification(responseJson)
        }
      }

    }

   // Customizable Area End

}    