// Customizable Area Start
import { BlockComponent } from 'framework/src/BlockComponent'
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum'
import { runEngine } from 'framework/src/RunEngine'
import { IBlock } from 'framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
import { QuestionData } from './MicroLessonExercise'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string
  t?:any;
}

export type QuestionTypeKey = 'TRUE_FALSE' | 'MULTIPLE_CHOICE' | 'MATCHING' | 'SEQUENCE' | 'FILL_IN_BLANK';

export interface QuestionAnswerItem {
  id: string
  type: string
  attributes: {
    accountId: number
    microLessonId: number
    lessonId: number
    assessmentId: number
    accountEventPointId: number
    questionBankId: number
    questionBody: string
    correctAnswer: string
    userAnswer: string
    correct: boolean
    challenge: boolean 
    timeCompleteInSeconds: number
    earnedXp: number
    grade: number
    questionType: string
    lessonCompleted: boolean
  }
}

interface S {
  miroLessonId: number
  token: string
  questionIndex: number
  isAnswerWrong: boolean
  isLoading: boolean
  exerciseData: QuestionData[]
  lessonNumber: number
  numberOfLesson: number
  lessonId: string
  microLessonName: string
  lessonName: string
  topicName: string
  unitName: string
  trophiesOpen: boolean
  goalsOpen: boolean
  mathOpen: boolean
  drawerSide: boolean
  openDrawer: boolean
  medianOpen: boolean
  showGrade:boolean
  questionAnswerList: QuestionAnswerItem[]
  totalXP: number
  latestAttemptLessonId: string | null
  reportData: string
  numberOfLessonAttempts: number
  hasCorrectAnswerInLesson: boolean
  showCelebrate: boolean
  isAlreadyShowCelebra: boolean
}

interface SS {
  id: any
}

const customQuestionType = (type: string) => {
  switch (type) {
    case 'true_false':
      return 'TRUE_FALSE'
    case 'mcq':
      return 'MULTIPLE_CHOICE'
    case 'match_the_column':
      return 'MATCHING'
    case 'sequence':
      return 'SEQUENCE'
    case 'fill_in_the_blank':
      return 'FILL_IN_BLANK'
    default:
      return 'TRUE_FALSE'
  }
}

export const handleCustomExerciseData = (responseData: any) => {
  const customResponseData: QuestionData[] = responseData?.map(
    (question: any) => {
      const customOptions = question?.attributes?.options?.data?.map(
        (option: any) => {
          return {
            id: option?.id,
            type: option?.type,
            attributes: {
              id: option?.attributes?.id,
              option: option?.attributes?.option,
              isCorrect: option?.attributes?.is_correct,
              sequenceNumber: option?.attributes?.sequence_number,
              description: option?.attributes?.description,
            },
          }
        }
      )
      return {
        id: question?.id,
        type: question?.type,
        assessmentId: question?.assessmentId,
        attributes: {
          id: question?.attributes?.id,
          question: question?.attributes?.question,
          questionBody: question?.attributes?.question_body,
          solution: question?.attributes?.solution,
          hint: question?.attributes?.hint,
          questionType: customQuestionType(
            question?.attributes?.question_type
          ),
          options: customOptions,
        },
      }
    }
  )
  return customResponseData
}

export default class MicroLessonExerciseController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMicroLessonExercisesId: string = ''
  submitExerciseAnswerId: string = ''
  getListExerciseAnswerId: string = ''
  getSpecificAccountId: string = ''
  getLatestLessonAttempsId: string = ''
  createNewLessonAttemptId: string = ''
  submitReportId: string = ''
  updateLessonProgressId: string = ''
  getLessonAttempListId: string  = ''
  submitShareActionId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      miroLessonId: 0,
      token: '',
      questionIndex: 0,
      isAnswerWrong: false,
      isLoading: true,
      exerciseData: [],
      lessonNumber: 0,
      numberOfLesson: 0,
      lessonId: '0',
      microLessonName: '',
      lessonName: '', 
      topicName: '',
      unitName: '',
      trophiesOpen: false,
      goalsOpen: false,
      mathOpen: false,
      drawerSide: false,
      openDrawer: false,
      medianOpen: false,
      showGrade:false,
      questionAnswerList: [],
      totalXP: 0,
      latestAttemptLessonId: null,
      reportData: '',
      numberOfLessonAttempts: 0,
      hasCorrectAnswerInLesson: false,
      showCelebrate: false,
      isAlreadyShowCelebra: false
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const lessonId = this.props.navigation.getParam('lessonId')
    const token = localStorage.getItem('token') || ''
    const lessonNumber = this.props.navigation.getParam('lessonNumber')
    const lessonName = this.props.navigation.getParam('lessonName') || ''
    const customLessonNumber = Number(lessonNumber)
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
    this.setState({
      lessonId: lessonId,
      lessonNumber: isNaN(customLessonNumber) ? 0 : customLessonNumber,
      lessonName: lessonName,
      unitName : updatedName.unitName,
      topicName: updatedName.topicName,
      token: token,
    })
    this.getSpecificAccount(token)
    this.getLatestLessonAttemps(token, lessonId)
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getMicroLessonExercisesId]: this.handleGetExerciseListQuestionResponse,
      [this.getListExerciseAnswerId]: this.handleGetExerciseListAnswerResponse,
      [this.getSpecificAccountId]: this.handleGetSpecificAccount,
      [this.submitExerciseAnswerId]: this.handleSubmitExerciseAnswer,
      [this.getLatestLessonAttempsId]: this.handleGetLatestLessonAttempResponse,
      [this.createNewLessonAttemptId]: this.handleCreateNewLessonAttemptResponse,
      [this.getLessonAttempListId]: this.handleGetLessonAttempListResponse,
      [this.submitReportId]: this.handleSubmitReportResponse,
      [this.submitShareActionId]: this.handleSubmitShareActionResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJson)
    }
  }

  handleSubmitShareActionResponse = () => {
    this.getSpecificAccount(this.state.token)
  }

  handleSubmitReportResponse = () => {
    this.getSpecificAccount(this.state.token)
  }

  handleGetLessonAttempListResponse = (responseJson: any) => {
    const numberOfAttemps = responseJson?.data?.length || 0
    this.setState({numberOfLessonAttempts: numberOfAttemps})
    this.getMicroLessonExercises(this.state.token, this.state.lessonId)
  }

  handleGetLatestLessonAttempResponse = (responseJson: any) => {
    const attemptId = responseJson?.data?.id
    const lessonCompleted = responseJson?.data?.attributes?.lesson_completed
    
    if (attemptId && !lessonCompleted) {
      this.setState({
        latestAttemptLessonId: attemptId
      })
      this.getListExerciseAnswer()
      this.getLessonAttempList()
    } else {
      this.createNewLessonAttempt()
    }
  }

  handleCreateNewLessonAttemptResponse = (responseJson: any) => {
    const attemptId = responseJson?.data?.id
    
    this.setState({
      latestAttemptLessonId: attemptId
    })
    this.getListExerciseAnswer()
    this.getLessonAttempList()
  }

  handleGetExerciseListQuestionResponse = (responseJson: any) => {
    this.setState({
      isLoading: false,
      numberOfLesson: responseJson?.data?.length,
      microLessonName:
        responseJson?.data?.[this.state.lessonNumber]?.attributes?.name,
      miroLessonId: responseJson?.data?.[this.state.lessonNumber]?.attributes?.id,
    })
    this.getListExerciseAnswer()
    
    const assessments =
      responseJson?.data?.[this.state.lessonNumber]?.attributes?.assessments?.data

    let responseData: any = []

    assessments.forEach((assessment: any) => {
      const questions = assessment?.attributes?.questions?.data
      const customQuestion = questions?.map((question: any) => {
        return {
          ...question,
          assessmentId: assessment?.attributes?.id
        }
      })
      responseData = [...responseData, ...customQuestion ]
    })
    
    const customResponseData: QuestionData[] = handleCustomExerciseData(responseData)

    if (customResponseData?.length > 0) {
      this.setState({ exerciseData: customResponseData })
      return
    } else {
      this.handleCheckToNavigate()
    }
  }

  handleCheckToNavigate = () => {
    if (this.state.numberOfLessonAttempts === 1) {
      this.updateLessonProgress()
    }
    if (this.state.lessonNumber < this.state.numberOfLesson - 1) {
      this.props.navigation.navigate('Cftableforlessons', {
        lessonId: this.state.lessonId,
        lessonNumber: this.state.lessonNumber + 1,
        lessonName: this.state.lessonName,
      })
      return
    } else {
      this.props.navigation.navigate('Eolscorecard', {
        lessonName: this.state.lessonName,
        lessonId: this.state.lessonId,
      })
      return
    }
  }

  handleGetExerciseListAnswerResponse = (responseJson: any) => {
    const customData: QuestionAnswerItem[] = responseJson?.data?.map((item: any) => {
      return {
        id: item?.id,
        type: item?.type,
        attributes: {
          accountId: item?.attributes?.account_id,
          microLessonId: item?.attributes?.micro_lesson_id,
          lessonId: item?.attributes?.lesson_id,
          assessmentId: item?.attributes?.assessment_id,
          accountEventPointId: item?.attributes?.account_event_point_id,
          questionBankId: item?.attributes?.question_bank_id,
          questionBody: item?.attributes?.question_body,
          correctAnswer: item?.attributes?.correct_answer,
          userAnswer: item?.attributes?.user_answer,
          correct: item?.attributes?.correct,
          challenge: item?.attributes?.challenge,
          timeCompleteInSeconds: item?.attributes?.time_complete_in_seconds,
          grade: item?.attributes?.grade,
          lessonCompleted: item?.attributes?.lesson_completed,
          questionType: item?.attributes?.question_type,
          earnedXp: item?.attributes?.earned_xp,
        }
      }
    })
    const hasCorrectAnswerInLesson = customData.some(item => item?.attributes?.correct)

    const matchMicroLessonData = customData.filter(item => item?.attributes?.microLessonId === this.state.miroLessonId)
    this.setState({ questionAnswerList: matchMicroLessonData, hasCorrectAnswerInLesson })
  }

  handleGetSpecificAccount = (responseJson: any) => {
    const userXP = responseJson?.data?.attributes?.total_xp
    return userXP && this.setState({totalXP: userXP})
  }

  handleSubmitExerciseAnswer = () => {
    this.getSpecificAccount(this.state.token)
  }

  getExerciseAnswer = (questionId: number) => {
    const answers = this.state.questionAnswerList.filter(item => item.attributes.questionBankId === questionId)
    return answers?.[answers?.length - 1]
  }

  convertType: { [key in QuestionTypeKey]: string } = {
    'TRUE_FALSE': 'true_false',
    'MULTIPLE_CHOICE': 'mcq',
    'MATCHING': 'match_the_column',
    'SEQUENCE': 'sequence',
    'FILL_IN_BLANK': 'fill_in_the_blank',
  }

  getMicroLessonExercises = (token: string, lessonId: string) => {
    this.getMicroLessonExercisesId = sendAPIRequest(
      `bx_block_profile/list_micro_lessons?parent_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getSpecificAccount = (token: string) => {
    this.getSpecificAccountId = sendAPIRequest(
      'account_block/accounts/specific_account',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getListExerciseAnswer = () => {
    this.getListExerciseAnswerId = sendAPIRequest(
      `bx_block_educational_gamification/question_and_answers?lesson_id=${this.state.lessonId}&lesson_attempt_id=${this.state.latestAttemptLessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
      }
    )
  }

  getLatestLessonAttemps = (token: string, lessonId: number) => {
    this.getLatestLessonAttempsId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts/latest?lesson_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getLessonAttempList = () => {
    this.getLessonAttempListId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts?lesson_id=${this.state.lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
      }
    )
  }

  createNewLessonAttempt = () => {
    const body = {
      lesson_id: this.state.lessonId
    }
    this.createNewLessonAttemptId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  submitExerciseAnswer = (
    {
      assessmentId,
      questionBankId,
      questionType,
      questionBody,
      correctAnswer,
      userAnswer,
      correct,
      grade,
      timeCompleteInSeconds,
      lessonCompleted,
    }: {
      assessmentId: number
      questionBankId: number
      questionType: QuestionTypeKey
      questionBody: string
      correctAnswer: string
      userAnswer: string
      correct: boolean
      grade: number
      timeCompleteInSeconds: number
      lessonCompleted: boolean
    }
  ) => {
    const body = {
      question_and_answer: {
        lesson_id: +this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        assessment_id: assessmentId,
        question_bank_id: questionBankId,
        question_type: this.convertType[questionType],
        question_body: questionBody,
        correct_answer: correctAnswer,
        user_answer: userAnswer,
        correct,
        grade,
        time_complete_in_seconds: timeCompleteInSeconds,
        lesson_completed: lessonCompleted,
        lesson_attempt_id: this.state.latestAttemptLessonId
      },
    }

    this.submitExerciseAnswerId = sendAPIRequest(
      `bx_block_educational_gamification/question_and_answers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  handleSelectReportOption = (option: string) => {
    this.setState({ reportData: option })
  }

  submitReport = () => {
    const body = {
      data: {
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        lesson_attempt_id: this.state.latestAttemptLessonId,
        problem: this.state.reportData
      }
    }
    this.submitReportId = sendAPIRequest(
      `bx_block_request_management/report_problem`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  updateLessonProgress = () => {
    const body = {
      lesson_progress: {
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        status: "start" 
      }
    }
    this.updateLessonProgressId = sendAPIRequest(
      'bx_block_educational_gamification/start_end_learning',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  submitShareAction = ({assessmentId, questionBankId}: {assessmentId: number, questionBankId: number}) => {
    const body = {
      data: {
        share_record_type: "exercise",
        lesson_attempt_id: this.state.latestAttemptLessonId,
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        assessment_id: assessmentId,
        question_bank_id: questionBankId
      }
    }
    this.submitShareActionId = sendAPIRequest(
      "bx_block_share/share",
      {
        method: "POST",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body
      }
    )
  }
}
// Customizable Area End
