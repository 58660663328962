import React from "react";
import { Line } from "react-chartjs-2";
import { Plugin } from "chart.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartGradeProps {
  isMoreDetail: boolean;
  labels: string[];
  dataChart: number[];
}

const ChartGrade = ({ isMoreDetail, labels, dataChart }: ChartGradeProps) => {
  console.log(isMoreDetail,labels,dataChart)
  const mappingLabelValidData = labels.filter((label, index) => {
    if (isMoreDetail) {
      return label;
    }
    if (dataChart[index] !== undefined) {
      return label;
    }
  });
  const mappingColor = mappingLabelValidData.map(label => {
    if (mappingLabelValidData[dataChart.length - 1] === label) {
      return "#00AEFF";
    }
    return "#B3B3B3";
  });
  const getLabelFromListLabel = (index: number) => {
    if (mappingLabelValidData[index].length > 2) {
      return mappingLabelValidData[index].charAt(0);
    }
    return mappingLabelValidData[index];
  };
  const maxGrade = Math.max(...dataChart);
  const maxGradeGraph = 2 * Math.floor(maxGrade / 2);
  const options = {
    responsive: true,
    events: [],
    plugins: {
      legend: {
        display: false
      }
    },
    labels: {
      display: false
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        beginAtZero: true,
        ticks: {
          display: isMoreDetail,
          callback: function(value: any) {
            return getLabelFromListLabel(value);
          },
          color: mappingColor,
          padding: 10 // add padding to ticks
        }
      },
      y: {
        grid: {
          display: true,
          drawBorder: false,
          lineWidth: 1.5
        },
        beginAtZero: true,
        ticks: {
          callback: function(value: any) {
            if (isMoreDetail) {
              return value;
            }
            if (value === 0 || value === maxGradeGraph) {
              return value;
            }
          },
          color: "#B3B3B3"
        }
      }
    }
  };

  const data = {
    labels: mappingLabelValidData,
    datasets: [
      {
        data: dataChart,
        borderColor: "#62CDFF",
        backgroundColor: "#62CDFF"
      },
      {
        data: mappingLabelValidData.map(label => {
          if (mappingLabelValidData[dataChart.length - 1] === label) {
            return 0;
          }
          return null;
        }) as number[],
        backgroundColor: "#00AEFF",
        pointRadius: 5,
        pointHoverRadius: 5,
        pointStyle: "rect"
      }
    ]
  };
  const plugins: Plugin<"line">[] = [
    {
      id: "customLabels",
      afterDraw: chart => {
        const ctx = chart.ctx;
        const xAxis = chart.scales.x;
        data.labels.forEach((label: any, index) => {
          const xPos = xAxis.getPixelForValue(label);
          const yPos = chart.height - 14; // Adjust this value to set the label position
          if (
            mappingLabelValidData[dataChart.length - 1] !== label ||
            !isMoreDetail
          ) {
            return;
          }
          // Draw background
          ctx.fillStyle = "#00AEFF";
          // Custom function to draw a rounded rectangle
          function roundRect(
            x: number,
            y: number,
            width: number,
            height: number,
            radius: number
          ) {
            ctx.beginPath();
            ctx.moveTo(x + radius, y);
            ctx.arcTo(x + width, y, x + width, y + height, radius);
            ctx.arcTo(x + width, y + height, x, y + height, radius);
            ctx.arcTo(x, y + height, x, y, radius);
            ctx.arcTo(x, y, x + width, y, radius);
            ctx.closePath();
          }
          roundRect(xPos - 11, yPos - 14, 22, 20, 4); // Adjust the radius as needed
          ctx.fill();
          // Draw label text
          ctx.fillStyle = "#FFFFFF";
          ctx.textAlign = "center";
          const validLabel = getLabelFromListLabel(index);
          ctx.fillText(validLabel, xPos, yPos);
        });
      }
    }
  ];
  return (
    <>
      <Line options={options} data={data} plugins={plugins} redraw />
    </>
  );
};

export default ChartGrade;
