import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography, styled, useTheme, useMediaQuery } from '@material-ui/core'
import _ from 'lodash'

import {
  Option,
  QuestionProps,
  StyledButton,
  reportOptions,
} from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  blueFlag,
  greenFlag,
  greenShare,
  groupConfetti,
  noteBookLines,
  redFlag,
  redShare,
} from '../../../blocks/cftableforlessons/src/assets'
import GroupActionsExercise from './GroupActionsExercise'
import ActionAlert from './ActionAlert'
import ReportModal from '../../../blocks/cftableforlessons/src/ReportModal'
import WellDoneShare from '../../../blocks/cftableforlessons/src/WellDoneShare'
import ThankReportModal from '../../../blocks/cftableforlessons/src/ThankReportModal'
import { CorrectAnswerButton, InCorrectAnswerButton, QuestionOption } from './SingleChoiceExercise'
import { convertLatex } from '../utils'
import { HintText, MathFormula, QuestionText } from './ExerciseComponents'
import  {useTranslation} from "react-i18next"
import SolutionPage from './SolutionPage'
import ShareModal from './ShareModal.web'
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../assets'

const MultipleChoiceExercise: React.FC<QuestionProps> = ({
  attributes,
  onClickContinue,
  assessmentId,
  onSubmitExerciseAnswer,
  exerciseAnswer,
  isLastQuestion,
  onSelectReportOption,
  onSubmitReport,
  isGuest,
  onUpdateXP,
  lessonId,
  microLessonId,
  lessonAttemptId,
  shareRecordType,
}) => {
  const {hint, options, question, solution, questionBody, questionType, id} = attributes
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [showInCorrectAnswer, setShowInCorrectAnswer] = useState(false)
  const [showHintAlert, setShowHintAlert] = useState(false)
  const [showSkipAlert, setShowSkipAlert] = useState(false)
  const [showSolutionAlert, setShowSolutionAlert] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [showWellDoneShareModal, setShowWellDoneShareModal] = useState(false)
  const [isDisableSelectOption, setIsDisableSelectOption] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState<Array<Option>>([])
  const [isCheckAnswer, setIsCheckAnswer] = useState(false)
  const [showThankReportModal, setShowThankReportModal] = useState(false)
  const [showHint, setShowHint] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const  { t } = useTranslation()

  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [solutionFromValue, setSolutionFromValue] = useState<'SOLUTION' | 'INCORRECT' | null>(null)
  const [startTime, setStartTime] = useState(0)
  const [listOption, setListOption] = useState<Option[]>([])

  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': orangePartyTeddy,
    '#62cdff': bluePartyTeddy,
    '#b7f0ad': mintPartyTeddy,
    '#a95edb': purplePartyTeddy,
    '#f96855': redPartyTeddy,
    '#884a39': brownPartyTeddy,
    '#fdce36': yellowPartyTeddy,
    '#2bce8c': greenPartyTeddy
  };

  const partyTeddy = colorToUpdateTeddy[avatarColor] || bluePartyTeddy;

  useEffect(() => {
    if (options && options.length > 0) {
      setListOption(options)
    }
  }, [options])

  useEffect(() => {
    if (exerciseAnswer) {
      const userAnswerString = exerciseAnswer?.attributes?.userAnswer
      const userAnswerArray = userAnswerString?.split('(split-space)')
      const userAnswer = userAnswerArray?.map(item => {
        return JSON.parse(item)
      })
      const userSelectedOptions = options?.filter(item => {
        const selectedIndex = userAnswer?.findIndex(i => i?.id === item.id)
        return selectedIndex !== -1
      })
      if (userSelectedOptions?.length > 0) {
        const customData = _.cloneDeep(userSelectedOptions)
        setSelectedAnswer(customData)
        setIsDisableSelectOption(true)
        setIsCheckAnswer(true)

        const isInCorrect = handleCheckCorrect(customData)
        if (isInCorrect) {
          setShowInCorrectAnswer(true)
        } else {
          setShowCorrectAnswer(true)
        }
      }
    } else {
      setStartTime(Date.now())
    }
  }, [exerciseAnswer, options])

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setWidth(boxRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, [])

  const handleCheckCorrect = (answerList: Option[]) => {
    const selectedIncorrectAnswer = answerList.find(
      (item) => item.attributes.isCorrect === false
    )
    const unselectOptions = options.filter((item) => {
      const selectedIndex = answerList.findIndex(
        (selectedOption) => selectedOption.id === item.id
      )
      return selectedIndex === -1
    })
    const correctAnswerInUnselectOptions = unselectOptions.find(
      (item) => item.attributes.isCorrect === true
    )
    return !!(selectedIncorrectAnswer || correctAnswerInUnselectOptions)
  }

  const checkOptionStatus = (option: Option) => {
    if (isCheckAnswer) {
      const existOptionIndex = selectedAnswer.findIndex(
        (item) => item.id === option.id
      )
      if (existOptionIndex !== -1) {
        if (option.attributes.isCorrect) {
          return {
            borderColor: '#2BCE8C',
            color: '#2BCE8C',
          }
        } else {
          return {
            borderColor: '#F96855',
            color: '#F96855',
          }
        }
      } else {
        return { borderColor: '#E6E6E6', color: '#7F7F7F' }
      }
    } else if (selectedAnswer.find((item) => item.id === option.id)) {
      return { borderColor: '#62CDFF', color: '#62CDFF' }
    } else {
      return { borderColor: '#E6E6E6', color: '#7F7F7F' }
    }
  }

  return (
    <MultipleChoiceExerciseContainer {...{ ref: boxRef }}>
      {solutionFromValue ? (
        <SolutionPage
          solutionContent={solution} 
          onContinue={() => {
            const param = solutionFromValue === 'SOLUTION' ? {isUseSolution: true} : {isAnswerWrong: true}
            onClickContinue(param)
          }}
          width={isMediumScreen ? width - 120 : width - 52}
          onClickReport={() => setShowReportModal(true)}
          isGuest={isGuest}
        />
      ) : (
        <Box className="mainContainer">
          <Box className="questionWrapper">
            <QuestionText question={question} width={isMediumScreen ? width - 120 : width - 52} />
            <Box style={{ width: '100%' }}>
              <Typography className="chooseAllText">
                {t("Choose all that apply.")}
              </Typography>
            </Box>
            <Box>
              {listOption.map((option) => {
                const optionColor = checkOptionStatus(option)
                return (
                  <QuestionOption
                    key={option.id}
                    onClick={() => {
                      if (!isDisableSelectOption) {
                        const existOptionIndex = selectedAnswer.findIndex(
                          (item) => item.id === option.id
                        )
                        if (existOptionIndex !== -1) {
                          const remainOptions = selectedAnswer.filter(
                            (item) => item.id !== option.id
                          )
                          setSelectedAnswer(remainOptions)
                        } else {
                          setSelectedAnswer((prev) => [...prev, option])
                        }
                      }
                    }}
                    style={{
                      boxShadow: `0px 2px ${optionColor.borderColor}`,
                      borderColor: optionColor.borderColor,
                    }}
                  >
                    <MathFormula 
                      formula={convertLatex(option.attributes.option)}
                      color={optionColor.color}
                      fontFamily='Quicksand'
                      fontSize={16}
                      fontWeight={700}
                      lineHeight='8px'
                      width={260}
                    />
                  </QuestionOption>
                )
              })}
            </Box>
          </Box>
          {!showCorrectAnswer &&
            !showInCorrectAnswer &&
            !showHint && (
              <GroupActionsExercise
                isDisableButton={selectedAnswer.length === 0}
                onCheckAnswer={() => {
                  setIsDisableSelectOption(true)
                  setIsCheckAnswer(true)
                  
                  const isInCorrect = handleCheckCorrect(selectedAnswer)
                  if (isInCorrect) {
                    setShowInCorrectAnswer(true)
                  } else {
                    setShowCorrectAnswer(true)
                  }

                  const endTime = Date.now();
                  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
                  const userAnswer = selectedAnswer?.map(item => {
                    return JSON.stringify({
                      text: item.attributes.option,
                      id: item.id
                    })
                  })
                  const correctAnswerOptions = options.filter(item => item?.attributes?.isCorrect)
                  const correctAnswer = correctAnswerOptions?.map(item => {
                    return JSON.stringify({
                      text: item.attributes.option,
                      id: item.id
                    })
                  })
                  const userAnswerString = userAnswer?.join('(split-space)')
                  const correctAnswerString = correctAnswer?.join('(split-space)')

                  const submitData = {
                    assessmentId: assessmentId,
                    questionBankId: attributes?.id,
                    questionType: questionType,
                    questionBody: questionBody,
                    correctAnswer: correctAnswerString,
                    userAnswer:  userAnswerString,
                    correct: !isInCorrect,
                    grade: !isInCorrect ? 10 : 0,
                    timeCompleteInSeconds: Math.floor(elapsedTimeInSeconds),
                    lessonCompleted: isLastQuestion,
                  }
                  onSubmitExerciseAnswer && onSubmitExerciseAnswer(submitData)
                }}
                onShowHintAlert={() => {
                  const isSkipShowHintAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowHintAlertMicroLesson')
                  )
                  if (isSkipShowHintAlertMicroLesson) {
                    setShowHint(true)
                  } else {
                    setShowHintAlert(true)
                  }
                }}
                onShowSkipAlert={() => {
                  const isSkipShowSkipAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSkipAlertMicroLesson')
                  )
                  if (isSkipShowSkipAlertMicroLesson) {
                    onClickContinue({isSkip: true})
                  } else {
                    setShowSkipAlert(true)
                  }
                }}
                onShowSolutionAlert={() => {
                  const isSkipShowSolutionAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSolutionAlertMicroLesson')
                  )
                  if (isSkipShowSolutionAlertMicroLesson) {
                    setIsDisableSelectOption(true)
                    setSolutionFromValue('SOLUTION')
                  } else {
                    setShowSolutionAlert(true)
                  }
                }}
              />
            )}
        </Box>
      )}
      {showHint && (
        <Box className="solutionWrapper">
          <Box className="solutionContentWrapper">
            <HintText hint={hint} width={isMediumScreen ? width - 120 : width - 52} />
            {isGuest ? (<Box />) : (
              <img
                src={blueFlag}
                width={15}
                height={15}
                onClick={() => setShowReportModal(true)}
              />
            )}
          </Box>
          <StyledButton
            onClick={() => {
              setShowHint(false)
            }}
          >
            {t("Continue")}
          </StyledButton>
        </Box>
      )}
      {showCorrectAnswer && (
        <Box className="correctAnswerWrapper">
          <Box className="correctAnswerContentWrapper">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img src={partyTeddy} className="partyTeddy" />
              <Typography className="correctAnswerText">
                {t("Awesome work!")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={greenFlag}
                  width={15}
                  height={15}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  src={greenShare}
                  width={15}
                  height={15}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <CorrectAnswerButton
            onClick={() => {
              const param = exerciseAnswer ? {} : {isAnswerCorrect: true}
              onClickContinue(param)
            }}
          >
             {t("Continue")}
          </CorrectAnswerButton>
        </Box>
      )}
      {showInCorrectAnswer && (
        <Box className="incorrectAnswerWrapper">
          <Box className="incorrectAnswerContentWrapper">
            <Box>
              <Typography className="incorrectAnswerTitle">
                {t("Oh oh! Correct answer:")}
              </Typography>
              <Typography className="incorrectAnswerDesc">
                {t("Continue to see the solution...")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={redFlag}
                  width={15}
                  height={15}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  src={redShare}
                  width={15}
                  height={15}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <InCorrectAnswerButton 
            onClick={() => {
              setShowInCorrectAnswer(false)
              if (exerciseAnswer) {
                setSolutionFromValue('SOLUTION')
              } else {
                setSolutionFromValue('INCORRECT')
              }
            }}
          >
          {t("Continue")}
          </InCorrectAnswerButton>
        </Box>
      )}
      <ShareModal 
        id="share-modal" 
        open={shareModalOpen} 
        handleClickClose={() => setShareModalOpen(false)}
        assessmentId={assessmentId}
        questionBankId={id}
        microLessonId={microLessonId}
        lessonId={lessonId}
        lessonAttemptId={lessonAttemptId}
        shareRecordType={shareRecordType}
        onAfterShare={() => {
          setShareModalOpen(false)
          setShowWellDoneShareModal(true)
        }}
        onUpdateXp={onUpdateXP}
      >
        <Box style={{width: '100%', padding: '24px 16px', textAlign: 'left'}}>
          <QuestionText question={question} width={308} isSharing />
          <Typography 
            style={{
              fontFamily: 'Nunito',
              color: '#363636',
              fontSize: '12px',
              fontWeight: 600,
              marginTop: '-32px',
              marginBottom: '20px'
            }}
          >
            {t("Choose all that apply.")}
          </Typography>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {listOption.map((option) => {
              const optionColor = checkOptionStatus(option)
              return (
                <QuestionOption
                  key={option.id}
                  style={{
                    boxShadow: `0px 2px ${optionColor.borderColor}`,
                    borderColor: optionColor.borderColor,
                    width: '260px',
                    marginBottom: '12px',
                    minHeight: '36px',
                    padding: '4px 16px'
                  }}
                >
                  <MathFormula 
                    formula={convertLatex(option.attributes.option)}
                    color={optionColor.color}
                    fontFamily='Quicksand'
                    fontSize={12}
                    fontWeight={700}
                    lineHeight='4px'
                    width={228}
                  />
                </QuestionOption>
              )
            })}
          </Box>
        </Box>
      </ShareModal>
      <ActionAlert
        open={showSolutionAlert}
        onClose={() => setShowSolutionAlert(false)}
        title={t("Solution Alert")}
        description={t("Please note that choosing to view the solution will not earn you any XP.")}
        actionText={t("Solution")}
        onClickAction={() => {
          setShowSolutionAlert(false)
          setIsDisableSelectOption(true)
          setSolutionFromValue('SOLUTION')
        }}
        actionType="SOLUTION"
      />
      <ActionAlert
        open={showHintAlert}
        isHint
        onClose={() => setShowHintAlert(false)}
        title={t("Hint Alert")}
        description={t("If you choose to access a hint, you won't earn any XP.")}
        actionText={t("Show hint")}
        onClickAction={() => {
          setShowHintAlert(false)
          setShowHint(true)
        }}
        actionType="HINT"
      />
      <ActionAlert
        open={showSkipAlert}
        onClose={() => setShowSkipAlert(false)}
        title={t("Skip Alert")}
        description={t(`skipDescription`)}
        actionText={t("Skip")}
        onClickAction={() => onClickContinue({isSkip: true})}
        actionType="SKIP"
      />
      <ReportModal
        reportOptions={reportOptions}
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        title={t("Report a problem")}
        onSubmitReport={() => {
          onSubmitReport && onSubmitReport()
          setShowThankReportModal(true)
        }}
        handleOption={(value: string) => {
          onSelectReportOption && onSelectReportOption(value)
        }}
      />
      <WellDoneShare
        open={showWellDoneShareModal}
        onClose={() => setShowWellDoneShareModal(false)}
        isExerciseShare
      />
      <ThankReportModal
        open={showThankReportModal}
        onClose={() => setShowThankReportModal(false)}
      />
    </MultipleChoiceExerciseContainer>
  )
}

const MultipleChoiceExerciseContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '& .mainContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 26px 0',
  },

  '& .questionWrapper': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .imageBg': {
    backgroundImage: `url(${noteBookLines})`,
    padding: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #E6E6E6',
    borderRadius: '8px',
    marginBottom: '24px',
  },

  '& .imageText': {
    fontFamily: 'Quicksand',
    fontWeight: '700',
    fontSize: '31px',
    color: '#363636',
  },

  '& .chooseAllText': {
    marginTop: '-24px',
    marginBottom: '24px',
    fontFamily: 'Nunito',
    color: '#363636',
    fontSize: '16px',
    fontWeight: '600',
  },

  '& .solutionWrapper': {
    backgroundColor: '#C9EEFF',
    width: '100%',
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
  },

  '& .solutionContentWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '28px',
  },

  '& .solutionText': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
    color: '#00AEFF',
  },

  '& .correctAnswerWrapper': {
    width: '100%',
    backgroundColor: '#d5f5e8',
    backgroundImage: `url(${groupConfetti})`,
    borderRadius: '20px 20px 0 0',
    padding: '36px 26px 40px',
  },

  '& .correctAnswerContentWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .partyTeddy': {
    marginBottom: '-28px',
    marginLeft: '12px',
    width: '84px',
    height: '84px',
  },

  '& .correctAnswerText': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
    color: '#2BCE8C',
    marginLeft: '10px',
  },

  '& .incorrectAnswerWrapper': {
    width: '100%',
    backgroundColor: '#fee1dd',
    borderRadius: '20px 20px 0 0',
    padding: '36px 26px 40px',
  },

  '& .incorrectAnswerContentWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },

  '& .incorrectAnswerTitle': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
    color: '#F96855',
  },

  '& .incorrectAnswerDesc': {
    fontSize: '18px',
    fontFamily: 'Quicksand',
    fontWeight: '600',
    color: '#F96855',
  },

  [theme.breakpoints.up('md')]: {
    '& .mainContainer': {
      padding: '32px 60px 0',
    },
  },
}))

export default MultipleChoiceExercise
