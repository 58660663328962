import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
  
  // Customizable Area End
export const configJSON = require("./config");

 // Customizable Area Start
  // Customizable Area End

interface S {
  // Customizable Area Start
  // Customizable Area End
}
export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: (screenName: string) => void;
  };

  heading: string;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class InAppNotificationHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  // Customizable Area Start
  handleGoBack = () => {
    window.history.back();
  };
  // Customizable Area End
}
