// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const pandaIcon =require('../assets/panda-Icon.svg')
export const pandaIcon2 =require('../assets/pandaIcon2.svg')
export const warningIcon =require('../assets/warning.svg')
export const checkedIcon =require('../assets/check.svg')
export const uncheckIcon =require('../assets/uncheck.svg')
export const lockIcon =require('../assets/lock-icon.svg')
export const skipBanner =require('../assets/skip_banner.png')
export const teddyParty =require('../assets/skip_partyTeddy.png')
export const skipConfetti =require('../assets/skip_confetti.png')
export const skipTeddy =require('../assets/skip_Teddy.png')
export const successText =require('../assets/successText.png')
export const supportText =require('../assets/group_Text.png')
// Customizable Area End