// Customizable Area Start
import React from 'react'
import {
  Box,
  InputAdornment,
  Typography,
  styled,
  Snackbar,
} from '@material-ui/core'
import {
  SecondaryButtonCustom,
  TextInputCustom,
} from '../../../components/src/MaterialTheme'
import EditAccountController, { Props } from './EditAccountController'
import CustomSelect from '../../../components/src/CustomSelect'
import NavigateLayout from '../../../components/src/NavigateLayout'
import {
  imgEnglishFlag,
  imgGermanFlag,
  imgHungarianFlag,
  imgRomanianFlag,
} from '../../../components/src/assets'
import * as Yup from 'yup'
import { Formik } from 'formik'
import SettingBackground from '../../settings2/src/SettingBackground'
import SettingHeader from '../../settings2/src/SettingHeader'
import {
  imgDeleteConfirm,
  imgEditAccountHeader,
  imgEmailConfirm,
} from '../../settings2/src/assets'
import ConfirmModal from './ConfirmModal'
import { withTranslation } from 'react-i18next'
import {CountryCode, isValidPhoneNumber,parsePhoneNumberFromString} from "libphonenumber-js"
import CustomCountrySelector from '../../../components/src/CustomCountrySelector'
import {loader} from "./assets"

 class EditAccount extends EditAccountController {
  constructor(props: Props) {
    super(props)
  }

  countries = ['Romania', 'England', 'Germany', 'Hungary']
  phoneNumberRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
  languageOptions = {
    English: imgEnglishFlag,
    German: imgGermanFlag,
    Hungarian: imgHungarianFlag,
    Romanian: imgRomanianFlag,
  }

  handleClickGoBack = () => {
    this.props.navigation.goBack()
  }

  editAccountSchema = Yup.object({
    firstName: Yup.string().trim(),
    lastName: Yup.string().trim(),
    userName: Yup.string()
      .trim()
      .required(this.props.t('User name is required')),
    email: Yup.string()
      .trim()
      .required(this.props.t('Email is required'))
      .email(this.props.t('Email invalid')),
    studentPhone: Yup.string()
      .required(this.props.t('Student phone name is required'))
      .test(
        'is-valid-phone',
        this.props.t('Phone number is not valid'),
        value => value ? isValidPhoneNumber((`${value}`),this.state.childPhoneCountryCode?.attributes?.alpha2 as CountryCode) : false
      ),
    parentPhone: Yup.string()
      .required(this.props.t('Parent phone name is required'))
      .test(
        'is-valid-phone',
        this.props.t('Phone number is not valid'),
        value => value ? isValidPhoneNumber((`${value}`),this.state.parentPhoneCountryCode?.attributes?.alpha2 as CountryCode) : false
      ),
    parentEmail: Yup.string()
      .trim()
      .email(this.props.t('Email invalid')),
    teacherEmail: Yup.string().trim(),
  })

  handleClickBtnHeader = () => this.setState({ isClickSubmitBtn: true })

  handleSelectCountry = (item: string) => {
    this.setState({ selectedCountry: item })
    if (item !== 'Romania') {
      let CountryData : any = this.state.countriesData.find((items:any) => items.attributes.name == item)
      this.setState({
        CountryId:CountryData?.id,
        selectedCity: 'International',
        selectedSchool: '007 School', 
        selectedGrade: '',
      })
      this.getGradesByCountry(CountryData)
    } else {
      let CountryData : any = this.state.countriesData.find((items:any) => items.attributes.name == item)
      this.setState({
        CountryId:CountryData?.id, 
        selectedCity: '',
        selectedSchool: '',
        selectedGrade: '',
      })
      this.getGradesByCountry(CountryData)
    }
  }

  handleSelectCity = (item: string) => {
    const selectedCityId =
      this.state.citiesData?.find((city) => city.attributes.name === item)
        ?.id || '1'
    this.setState({
      selectedCity: item,
      selectedSchool: '',
      selectedGrade: '',
    })
    this.getSchools(selectedCityId)
  }

  handleSelectSchool = (item: string) =>
    this.setState({
      selectedSchool: item,
      selectedGrade: '',
    })

  handleSelectGrade = (item: string) => this.setState({ selectedGrade: item })

  handleClickDeleteAccount = () =>
    this.setState({ showDeleteConfirmModal: true })
  
  handleCloseAlert = () => {
    this.setState({ showAlert: false })
  }

  checkTouchedAndErrorInput = (touched: any, errors: any) => {
    return Boolean(touched && errors)
  }

  renderHelperText = (touched: boolean | undefined, errors: string | undefined) => touched && errors

  checkErrorSelect = (isClickBtn: boolean, selectedItem: string) => isClickBtn && selectedItem === ''

  checkDisableHeaderButton = (array: Array<boolean>) => {
    let isDisable = true
    for (const item of array) {
      if (!item) {
        isDisable = false
      }
    }
    return isDisable
  }

  flagImg = () => this.languageOptions[this.state.currentLanguage || 'English']
  
  checkDisableCityField = () => this.state.selectedCountry !== 'Romania'

  checkDisableSchoolField = () => 
    this.state.selectedCountry !== 'Romania' || this.state.selectedCity === ''

  checkDisableGradeField = () => (this.state.selectedCountry === 'Romania' && this.state.selectedSchool === '') || this.state.gradesDisplayList.length===0
  
 
  handlePhoneNumberChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,handleChange:any,name:string,phoneCountrycode:any)=>{
    if(e.target.value.length > phoneCountrycode?.attributes?.country_code?.length+1){
      const numericValue = e.target.value.replace(/[^\d+]/g, ''); 
      const phoneNumber = parsePhoneNumberFromString(numericValue,phoneCountrycode?.attributes?.alpha2)
      if(phoneNumber){
        const formattedNumber = phoneNumber.formatInternational()
        handleChange({ target: { name: name, value: formattedNumber} });
      }else{
        handleChange({ target: { name: name, value: numericValue } });
      }
    }
  }

  handleParentPhoneNumber=(value:any,setFieldValue:any,)=>{
    this.setState({
      parentPhoneCountryCode:value
    })
    if(value.update){
    setFieldValue("parentPhone",`+${value.attributes.country_code}`)
    }
  }
  handleChildPhoneNumber=(value:any,setFieldValue:any,)=>{
    this.setState({
      childPhoneCountryCode:value
    })
    if(value.update){
    setFieldValue("studentPhone",`+${value.attributes.country_code}`)
    }
  }

  render() {
   const {t} = this.props
     return (
      <NavigateLayout menuName="Settings">
        <EditAccountContainer>
        {this.state.isLoading ? (
        <Box className="loaderShown">
        <img src={loader} alt="Loading" />
      </Box>
    ) : 
    <>
          <SettingBackground />
          <Box className="contentContainer">
            <Formik
              data-testId="editAccountForm"
              enableReinitialize
              initialValues={{
                firstName: this.checkInitialValue(this.state.initialData.firstName),
                lastName: this.checkInitialValue(this.state.initialData.lastName),
                userName: this.checkInitialValue(this.state.initialData.userName),
                email: this.checkInitialValue(this.state.initialData.email),
                studentPhone: this.checkInitialValue(this.state.initialData.studentPhone),
                parentPhone: this.checkInitialValue(this.state.initialData.parentPhone),
                parentEmail: this.checkInitialValue(this.state.initialData.parentEmail),
                teacherEmail: this.checkInitialValue(this.state.initialData.teacherEmail),
              }}
              validationSchema={this.editAccountSchema}
              onSubmit={({
                email,
                firstName,
                lastName,
                parentEmail,
                parentPhone,
                studentPhone,
                teacherEmail,
                userName,
              }) => {
                if (
                  this.state.isClickSubmitBtn &&
                  (this.state.selectedCountry === '' ||
                    this.state.selectedCity === '' ||
                    this.state.selectedSchool === '' ||
                    this.state.selectedGrade === '')
                ) {
                  return
                }
                if (email !== this.state.initialData.email) {
                  this.setState({
                    showEmailConfirmModal: true,
                    submitData: {
                      email,
                      firstName,
                      lastName,
                      parentEmail,
                      parentPhone,
                      studentPhone,
                      teacherEmail,
                      userName,
                    },
                  })
                } else {
                  this.submitEditAccount({
                    firstName,
                    lastName,
                    parentEmail,
                    parentPhone,
                    studentPhone,
                    teacherEmail,
                    userName,
                  })
                }
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values, setFieldValue }) => (
                <form className='form' onSubmit={handleSubmit}
                >
                  <SettingHeader
                    data-testId='settingHeader'
                    onGoBack={this.handleClickGoBack}
                    title={t("Edit Account")}
                    headerImage={imgEditAccountHeader}
                    showAvatar
                    onClickButton={this.handleClickBtnHeader}
                    typeButton="submit"
                    actionButtonText={this.state.saveButtonText}
                    avatarIcon={this.state.avataIcon}
                    disableButton={this.checkDisableHeaderButton([
                      values.firstName === this.state.initialData.firstName,
                      values.lastName === this.state.initialData.lastName,
                      values.userName === this.state.initialData.userName,
                      values.email === this.state.initialData.email,
                      values.studentPhone === this.state.initialData.studentPhone,
                      values.parentPhone === this.state.initialData.parentPhone,
                      values.parentEmail === this.state.initialData.parentEmail,
                      values.teacherEmail === this.state.initialData.teacherEmail,
                      this.state.selectedCountry === this.state.initialData.country,
                      this.state.selectedCity === this.state.initialData.city,
                      this.state.selectedSchool === this.state.initialData.school,
                      this.state.selectedGrade === this.state.initialData.grade
                    ])}
                  />
                  <Box className="editAccountContainer">
                    <Box className="editAccountWrapper">
                      <Box className="formContainer">
                        <Box className="formWrapper">
                          <Box className="formHeader">{t("Student")}</Box>
                          <Box className="textFieldWrapper">
                            <Typography className="labelText">
                            {t("First Name")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.firstName}
                              onChange={handleChange}
                              error={this.checkTouchedAndErrorInput(
                                touched.firstName, errors.firstName
                              )}
                              autoComplete="off"
                              name="firstName"
                              className='InputText'
                              helperText={this.renderHelperText(touched.firstName, errors.firstName)}
                              placeholder={t('First Name')}
                            />

                            <Typography className="labelText">
                              {t("Last Name")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.lastName}
                              onChange={handleChange}
                              error={this.checkTouchedAndErrorInput(
                                touched.lastName, errors.lastName
                              )}
                              autoComplete="off"
                              name="lastName"
                              className={"InputText"}
                              helperText={this.renderHelperText(touched.lastName, errors.lastName)}
                              placeholder={t('Last Name')}
                            />

                            <Typography className="labelText">
                              {t("Username")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.userName}
                              onChange={handleChange}
                              error={
                                this.checkTouchedAndErrorInput(touched.userName, errors.userName) ||
                                !!this.state.userNameError
                              }
                              autoComplete="off"
                              name="userName"
                              helperText={
                                this.renderHelperText(touched.userName, errors.userName) ||
                                this.state.userNameError
                              }
                            />

                            <Typography className="labelText">{t("Email")}</Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.email}
                              onChange={handleChange}
                              error={this.checkTouchedAndErrorInput(touched.email, errors.email)}
                              autoComplete="off"
                              name="email"
                              helperText={this.renderHelperText(touched.email, errors.email)}
                            />

                            <Typography className="labelText">{t("Phone")}</Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.studentPhone}
                              onChange={(e) => {
                                this.handlePhoneNumberChange(
                                  e,
                                  handleChange,
                                  'studentPhone',
                                  this.state.childPhoneCountryCode)
                              }}
                              data-testid="studentPhone"
                              error={this.checkTouchedAndErrorInput(
                                touched.studentPhone, errors.studentPhone
                              )}
                              autoComplete="off"
                              name="studentPhone"
                              helperText={
                                this.renderHelperText(touched.studentPhone, errors.studentPhone)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginRight:10}}>
                                  <CustomCountrySelector 
                                   showSearchInput
                                   flag={this.state.childPhoneCountryCode}
                                   list={this.state.countriesData}
                                   data-testid="studentcountryCode"
                                   value={this.state.defualtChildPhone}
                                   setValue={ (value:any)=>{
                                    this.handleChildPhoneNumber(value,setFieldValue)
                                   }}
                                  />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Typography className="labelText">
                              {t("Parent Phone")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.parentPhone}
                              onChange={(e) => {
                                this.handlePhoneNumberChange(
                                  e,
                                  handleChange,
                                  'parentPhone',
                                  this.state.parentPhoneCountryCode)
                              }}
                              error={this.checkTouchedAndErrorInput(
                                touched.parentPhone, errors.parentPhone
                              )}
                              data-testid="parentPhone"
                              autoComplete="off"
                              name="parentPhone"
                              helperText={
                                this.renderHelperText(touched.parentPhone, errors.parentPhone)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" style={{marginRight:10}}>
                                  <CustomCountrySelector 
                                   showSearchInput
                                   data-testid="parentcountryCode"
                                   flag={this.state.parentPhoneCountryCode}
                                   list={this.state.countriesData}
                                   value={this.state.defaultParentPhone}
                                   setValue={ (value:any)=>{
                                    this.handleParentPhoneNumber(value,setFieldValue)
                                   }}
                                  />
                                  </InputAdornment>
                                ),
                              }}
                            />

                            <Typography className="labelText">
                              {t("Parent Email")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.parentEmail}
                              onChange={handleChange}
                              error={this.checkTouchedAndErrorInput(
                                touched.parentEmail, errors.parentEmail
                              )}
                              autoComplete="off"
                              name="parentEmail"
                              helperText={
                                this.renderHelperText(touched.parentEmail, errors.parentEmail)
                              }
                              placeholder='parent@email.com'
                            />

                            <Typography className="labelText">
                              {t("Country")}
                            </Typography>
                            <CustomSelect
                              data-testId='selectCountry'
                              list={this.state.countriesDisplayList}
                              value={this.state.selectedCountry}
                              showSearchInput
                              setValue={this.handleSelectCountry}
                              isError={
                                this.checkErrorSelect(this.state.isClickSubmitBtn,
                                this.state.selectedCountry)
                              }
                            />

                            <Typography className="labelText">{("City")}</Typography>
                            <CustomSelect
                              data-testId='selectCity'
                              list={this.state.citiesDisplayList}
                              value={this.state.selectedCity}
                              setValue={this.handleSelectCity}
                              showSearchInput
                              isError={
                                this.checkErrorSelect(this.state.isClickSubmitBtn,
                                this.state.selectedCity)
                              }
                              disableSelect={this.checkDisableCityField()}
                            />
                          </Box>
                        </Box>

                        <Box className="formWrapper" marginTop="32px">
                          <Box className="formHeader">{t("School")}</Box>
                          <Box className="textFieldWrapper">
                            <Typography className="labelText">
                            {t("School Name")}
                            </Typography>
                            <CustomSelect
                              data-testId='selectSchool'
                              list={this.state.schoolsDisplayList}
                              value={this.state.selectedSchool}
                              setValue={this.handleSelectSchool}
                              showSearchInput
                              disableSelect={this.checkDisableSchoolField()}
                              isError={
                                this.checkErrorSelect(this.state.isClickSubmitBtn,
                                this.state.selectedSchool)
                              }
                            />

                            <Typography className="labelText">{t("Grade")}</Typography>
                            <CustomSelect
                              data-testId='selectGrade'
                              list={this.state.gradesDisplayList}
                              value={this.state.selectedGrade}
                              setValue={this.handleSelectGrade}
                              showSearchInput
                              disableSelect={this.checkDisableGradeField()}
                              isError={
                                this.checkErrorSelect(this.state.isClickSubmitBtn,
                                this.state.selectedGrade)
                              }
                            />

                            <Typography className="labelText">
                              {t("Teacher Email")}
                            </Typography>
                            <TextInputCustom
                              variant="outlined"
                              value={values.teacherEmail}
                              onChange={handleChange}
                              error={this.checkTouchedAndErrorInput(
                                touched.teacherEmail, errors.teacherEmail
                              )}
                              autoComplete="off"
                              name="teacherEmail"
                              helperText={
                                this.renderHelperText(touched.teacherEmail, errors.teacherEmail)
                              }
                              placeholder='teacher@gmail.com'
                            />
                          </Box>
                        </Box>
                        <SecondaryButtonCustom
                          data-testId='deleteButton'
                          onClick={this.handleClickDeleteAccount}
                          style={{ marginTop: '22px', color: '#F96855' }}
                        >
                          {t("Delete account")}
                        </SecondaryButtonCustom>
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Snackbar
            data-testId='snackBar'
            open={this.state.showAlert}
            onClose={this.handleCloseAlert}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            autoHideDuration={2000}
          >
            <Box
              className="snackBarContainer"
              style={{
                backgroundColor:
                  this.state.popupData.type === 'error' ? '#F96855' : '#62CDFF',
              }}
            >
              <Typography className="alertText">
                {this.state.popupData.text}
              </Typography>
            </Box>
          </Snackbar>
          </>
       }
        </EditAccountContainer>
        <ConfirmModal
          data-testID='changeEmailModal'
          open={this.state.showEmailConfirmModal}
          onClose={this.handleCloseEmailModal}
          image={imgEmailConfirm}
          title={t("Update confirmation")}
          description={t("Before you update your email, let's have a quick password check.")}
          inputValue={this.state.password}
          onChangePassword={this.handleChangePassword}
          onClick={this.submitEditAccountChangeEmail}
        />
        <ConfirmModal
          data-testID='deleteModal'
          open={this.state.showDeleteConfirmModal}
          onClose={this.handleCloseDeleteModal}
          image={imgDeleteConfirm}
          title={t("Delete account confirmation")}
          description={t("This is a big decision. Once you delete it, it's permanent. Type your password to continue.")}
          subDes={t("Thanks for being with us!")}
          isDeleteModal
          inputValue={this.state.password}
          onChangePassword={this.handleChangePassword}
          onClick={this.deleteAccount}
        />
      </NavigateLayout>
    )
  }
}

export default withTranslation()(EditAccount)

const EditAccountContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#A95EDB',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: '6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    maxHeight:"100vh",
    overflow:'auto'
  },

  '& .form': {
    flex: 1, 
    width: '100%', 
    // overflow: 'scroll', 
    display: 'flex',
    flexDirection: 'column',
    // scrollbarWidth: 'none'
  },

  "& .form > .MuiBox-root":{
   top:'unset'
  },

  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(80% - 240px)",
     marginLeft: "240px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },

  '& .form::-webkit-scrollbar': {
    display: 'none'
  },

  '& .editAccountContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#A95EDB',
    // overflow: 'scroll',
    // scrollbarWidth: 'none'
  },

  '& .editAccountContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .editAccountWrapper': {
    flex: 1,
    backgroundColor: '#fff',
    // overflow: 'scroll',
    paddingTop: '98px',
    // scrollbarWidth: 'none'
  },

  '& .editAccountWrapper::-webkit-scrollbar': {
    display: 'none'
  },

  '& .formContainer': {
    backgroundColor: '#fff',
    padding: '0 24px 88px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .formWrapper': {
    border: '2px solid #e6e6e6',
    width: 'fit-content',
    borderRadius: '12px',
    // overflow: 'hidden',
  },

  '& .formHeader': {
    paddingLeft: '20px',
    backgroundColor: '#e6e6e6',
    fontFamily: 'Quicksand',
    fontSize: '12px',
    textTransform:"uppercase",
    fontWeight: '700',
    color: '#999999',
    lineHeight: '40px',
  },

  '& .InputText ::placeholder':{
     textTransform:'lowercase'
  },
  '& .textFieldWrapper': {
    padding: '8px 20px 16px',
  },

  '& .labelText': {
    fontFamily: 'Quicksand',
    fontSize: '12px',
    fontWeight: '700',
    color: '#7F7F7F',
    textTransform:'uppercase',
    marginBottom: '10px',
    marginTop: '16px',
  },

  '& .snackBarContainer': {
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px 16px',
    color: '#fff',
  },

  '& .alertText': {
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .editAccountWrapper': {
      paddingTop: '108px',
      borderRadius: '24px 0 0 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))

// Customizable Area End
