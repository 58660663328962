// Customizable Area Start
import React, { useState } from 'react'
import {
  Box,
  Typography,
  styled,
  Modal,
  Slide,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core'
import { TextInputCustom } from '../../../components/src/MaterialTheme'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { lockIcon } from '../../email-account-registration/src/assets'
import { useTranslation } from 'react-i18next'
// Customizable Area End
// Customizable Area Start
interface ConfirmModalProps {
  open: boolean
  onClose: () => void
  image: any
  title: string
  description: string
  subDes?: string
  isDeleteModal?: boolean
  inputValue: string
  onChangePassword: (e: any) => void
  onClick: () => void
}
// Customizable Area End
const ConfirmModal = ({
  // Customizable Area Start
  open,
  onClose,
  image,
  title,
  description,
  subDes,
  isDeleteModal,
  inputValue,
  onChangePassword,
  onClick,
  // Customizable Area End
}: ConfirmModalProps) => {
  // Customizable Area Start
  const [showPassword, setShowPassword] = useState(true)
   const {t} = useTranslation()
  const handleShowPassword = () => setShowPassword(!showPassword)
  // Customizable Area End
  return (
    // Customizable Area Start
    <ModalStyled open={open} onClose={onClose}>
      <Slide in={open} direction="up">
        <Box className="container">
          <img src={image} width={70} height="auto" />
          <Typography className="title">{title}</Typography>
          <Typography className="description">{description}</Typography>
          <TextInputCustom
            value={inputValue}
            onChange={onChangePassword}
            error={false}
            helperText={''}
            autoComplete="off"
            name="password"
            placeholder=" Confirm password"
            variant="outlined"
            type={showPassword ? 'password' : 'text'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    id="passwordInput"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff
                        style={{
                          width: '18px',
                          height: '18px',
                          color: '#ccc',
                        }}
                      />
                    ) : (
                      <Visibility
                        style={{
                          width: '18px',
                          height: '18px',
                          color: '#ccc',
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton disabled edge="start">
                    <img
                      src={lockIcon}
                      style={{ width: '18px', height: '18px' }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {subDes && <Typography className="subDes">{subDes}</Typography>}
          <Box className="buttonWrapper">
            <CancelButton style={{ marginRight: '20px' }} onClick={onClose}>
              {t("Cancel")}
            </CancelButton>
            {isDeleteModal ? (
              <DeleteButton onClick={onClick} disabled={inputValue === ''}>
                {t("Delete")}
              </DeleteButton>
            ) : (
              <SaveButton onClick={onClick} disabled={inputValue === ''}>
                {t("Save")}
              </SaveButton>
            )}
          </Box>
        </Box>
      </Slide>
    </ModalStyled>
  )
  // Customizable Area End
}
// Customizable Area Start
const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    padding: '26px 26px 42px',
    borderRadius: '12px 12px 0 0',
    display: 'flex',
    border: '2px solid #e6e6e6',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .title': {
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontWeight: '900',
    color: '#363636',
    textAlign: 'center',
    marginBottom: '16px',
    marginTop: '32px',
  },

  '& .description': {
    fontWeight: '500',
    fontFamily: 'Quicksand',
    fontSize: '15px',
    textAlign: 'center',
    color: '#666666',
    marginBottom: '24px',
  },

  '& .buttonWrapper': {
    marginTop: '24px',
    display: 'flex',
  },

  '& .subDes': {
    fontFamily: 'Quicksand',
    fontSize: '13px',
    color: '#999999',
    marginTop: '16px',
    fontWeight: '500',
  },
}))

const SaveButton = styled(Button)(() => ({
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  letterSpacing: '0.8px',
  fontSize: '16px',
  fontWeight: 700,
  backgroundColor: '#36BFFF',
  boxShadow: '0px 4px #00AEFF',
  borderRadius: '12px',
  textTransform: 'none',
  width: '110px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    boxShadow: '0px 4px #00AEFF',
    backgroundColor: '#36BFFF',
  },
  '&.Mui-disabled': {
    color: '#B3B3B3',
    boxShadow: 'unset',
    backgroundColor: '#E6E6E6',
  },
}))

const DeleteButton = styled(Button)(() => ({
  width: '110px',
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Nunito',
  textAlign: 'center',
  color: 'white',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  fontSize: '16px',
  backgroundColor: '#f96855',
  boxShadow: '0px 4px #ea5948',
  borderRadius: '12px',
  textTransform: 'none',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#f96855',
    boxShadow: '0px 4px #ea5948',
  },
  '&.Mui-disabled': {
    backgroundColor: '#E6E6E6',
    color: '#B3B3B3',
    boxShadow: 'unset',
  },
}))

export const CancelButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  textAlign: 'center',
  color: '#CCC',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  letterSpacing: '0.8px',
  fontWeight: 700,
  backgroundColor: '#fff',
  boxShadow: '0px 4px #CCC',
  border: '2px solid #CCC',
  borderRadius: '12px',
  textTransform: 'none',
  width: '110px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#CCC',
    boxShadow: '0px 4px #CCC',
  },
  '&.Mui-disabled': {
    color: '#B3B3B3',
    backgroundColor: '#E6E6E6',
    boxShadow: 'unset',
    borderWidth: '0',
  },
}))

export default ConfirmModal
// Customizable Area End