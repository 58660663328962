// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
import { setStorageData } from 'framework/src/Utilities'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string
  t?:any
}
 
interface S {
  isLoading: boolean
  showPassword: boolean
  profileType: string
  signUpData: {
    email: string
    password: string
  }
  googleSignupData:{
    email:string
    uniqueAuthId:string
  }
  formError: {
    email: string
    password: string
  }
  isGoogleSignup: boolean;
}

interface SS {
  id: any
}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  validateAccountId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      isLoading: false,
      showPassword: false,
      profileType: 'Student',
      signUpData: {
        email: '',
        password: '',
      }, 
      formError: {
        email: '',
        password: '',
      },
      isGoogleSignup: false,
      googleSignupData:{
        email:'',
        uniqueAuthId:''
      }
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
 console.log(responseJson,"-----------------------")
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.validateAccountId
    ) {
      if (responseJson?.errors?.length !== 0) {
        this.setState({
          isLoading: false,
          formError: {
            email: responseJson?.errors?.[0]?.account
              ? responseJson?.errors?.[0]?.account
              : '',
            password: responseJson?.errors?.[0]?.password
              ? responseJson?.errors?.[0]?.password
              : '',
          },
        })
        return
      }
      this.handleNavigateCompleteProfile()
    }
  }

  handleNavigateCompleteProfile = () => {
     if (this.state.isGoogleSignup) {
      localStorage.setItem(
        'googleSignupData',
        JSON.stringify(this.state.googleSignupData)
      );
    this.props.navigation.navigate('CompleteProfile');
    } else {
      localStorage.setItem(
        'signUpData',
        JSON.stringify(this.state.signUpData)
      );
    this.props.navigation.navigate('CompleteProfile');
    }

  };
 
  submitValidateAccount = (email: string, password: string) => {
    const data = {
      type: 'email_account',
      attributes: {
        email,
        password,
      },
    }
    let body: object = { data }
    this.setState({
      isLoading: true,
      signUpData: {
        email: email,
        password: password,
      },
    })
    this.validateAccountId = sendAPIRequest(
      'account_block/accounts/validate_account',
      {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
  handleGoogleSignupSuccess = (token: string) => {
      console.log(token)
};
handleGoogleSignup = (profileObj:{email:string, firstName:string, lastName:string},uniqueAuthId: string) => {
  const data = {
    type: 'social_account',
    attributes: {
      email: profileObj.email,
      unique_auth_id: uniqueAuthId,
    },
  };
  const body = { data };

  setStorageData("profileObj",JSON.stringify(profileObj))

  this.setState({
    isGoogleSignup: true,
    isLoading: true,
    googleSignupData: {
      email: profileObj.email ,
      uniqueAuthId,
    },
  });

  this.validateAccountId = sendAPIRequest(
    'account_block/accounts/validate_account',
    {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  
};   
}
// Customizable Area End
