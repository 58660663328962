import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import html2canvas from 'html2canvas'
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import { ChangeEvent } from "react";
import {reportBlue, reportBrown, reportPurple, reportMint, reportGreen, reportOrange, reportRed, reportYellow } from './assets'
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../../../components/src/assets'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?: any;
  // Customizable Area Start

  // Customizable Area End

}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showTotalXp: boolean;
  showAvgGrade: boolean;
  showMainCard: boolean;
  open: boolean;
  showFeedbackContainer: boolean;
  openFeedback: boolean;
  iswellDoneShareModelOpen: boolean;
  topicName: string;
  unitName: string;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  mathOpen: boolean;
  drawerSide: boolean;
  openDrawer: boolean;
  medianOpen: boolean;
  lessonName: string;
  lessonNumber: number,
  parentId: string,
  ratingValue: number | null;
  openRating: boolean;
  openShare: boolean;
  feedbackValue: string;
  customShareOpen: boolean;
  token: string;
  avgScore: string;
  avgScoreStart: string;
  avgScoreEnd: string;
  lastAvgScore: string;
  summaryList: Array<{ name: string, XP: number }>
  totalXP: number;
  progressUpdateOpen: boolean;
  lessonId: string,
  lessonAttemptId: string
  dailyGoal: string
  streakGoal: string
  currentStreak: string;
  goalProgress: {
    medal: number;
    dailyGoalAchieve: boolean;
    streakGoalDates: Array<string>;
    streakGoalAchieve: boolean;
  }
  dailyGoalSettings: any;
  streakGoalSettings: any;
  todayCompletedLessonCounts: number;
  isLoading: boolean;
  honestTeddy: any;
  partyTeddy:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EolscorecardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  rateCallId: string = '';
  feedbackCallId: string = '';
  shareCallId: string = '';
  questionAndAnswerCallId: string = '';
  lessonSumaryCallId: string = ''
  getLatestLessonAttempsId: string = ''
  updateLessonAttemptId: string = ''
  totalSummaryAverageId: string = ''
  getGoalProgressId: string = ''
  getCurrentUserProfileId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showTotalXp: false,
      showAvgGrade: false,
      showMainCard: true,
      open: false,
      showFeedbackContainer: false,
      openFeedback: false,
      iswellDoneShareModelOpen: false,
      topicName: '',
      unitName: '',
      mathOpen: false,
      trophiesOpen: false,
      goalsOpen: false,
      drawerSide: false,
      openDrawer: false,
      medianOpen: false,
      lessonName: '',
      lessonNumber: 0,
      parentId: '',
      ratingValue: 0,
      openRating: false,
      openShare: false,
      feedbackValue: '',
      customShareOpen: false,
      token: '',
      avgScore: '',
      avgScoreEnd: '',
      avgScoreStart: '',
      lastAvgScore: '0',
      summaryList: [],
      totalXP: 0,
      progressUpdateOpen: false,
      lessonId: '',
      lessonAttemptId: '',
      dailyGoal: '',
      currentStreak: '',
      streakGoal: '',
      goalProgress: {
        medal: 0,
        dailyGoalAchieve: false,
        streakGoalDates: [],
        streakGoalAchieve: false
      },
      dailyGoalSettings: {},
      streakGoalSettings: {},
      todayCompletedLessonCounts: 0,
      isLoading: true, 
      honestTeddy:'', 
      partyTeddy:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
  }

  getCurrentUserProfile = (token: string) => {
    this.getCurrentUserProfileId = sendAPIRequest(
      "bx_block_profile/profiles/current_user_profile",
      {
        method: "GET",
        headers: {
          token,
          "Content-Type": "application/json"
        }
      }
    )
  }

  handleClickContinue = () => {
    this.getGoalProgressId = sendAPIRequest(
      "bx_block_profile/profiles/goal_progress",
      {
        method: "GET",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
      }
    )
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.rateCallId]: this.handleRateResponse,
      [this.feedbackCallId]: this.handleFeedbackResponse,
      [this.shareCallId]: this.handleShareResponse,
      [this.questionAndAnswerCallId]: this.handleQuestionAndAnswerResponse,
      [this.lessonSumaryCallId]: this.handleLessonSumaryResponse,
      [this.getLatestLessonAttempsId]: this.handleGetLatestLessonAttempResponse,
      [this.updateLessonAttemptId]: this.handleUpdateLessonAttemptResponse,
      [this.totalSummaryAverageId]: this.handleGetTotalSummaryAverageResponse,
      [this.getGoalProgressId]: this.handleGetGoalProgressResponse,
      [this.getCurrentUserProfileId]: this.handleGetCurrentUserProfileResponse
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }

  handleGetCurrentUserProfileResponse = (responseJSON: any) => {
    const {daily_goal, streak_goal} = responseJSON?.data?.attributes

    this.setState({dailyGoal: daily_goal, streakGoal: streak_goal})
  }

  handleGetGoalProgressResponse = (responseJSON: any) => {
    const {medal, today_completed_lesson_counts, daily_goal_settings, streak_goal_settings, streak_goal_dates,daily_goal_achieve,streak_goal_achieve} = responseJSON

    this.setState({
      goalProgress: {
        medal,
        dailyGoalAchieve: daily_goal_achieve,
        streakGoalDates: streak_goal_dates,
        streakGoalAchieve: streak_goal_achieve
      },
      dailyGoalSettings: daily_goal_settings,
      streakGoalSettings: streak_goal_settings,
      todayCompletedLessonCounts: today_completed_lesson_counts,
      progressUpdateOpen: true
    })
  }

  handleGetTotalSummaryAverageResponse = (responseJSON: any) => {
    const totalSummaryAverage = responseJSON?.average_grade
    if (totalSummaryAverage) {
      this.setState({lastAvgScore: String(totalSummaryAverage)})
    }
  }

  handleGetLatestLessonAttempResponse = (responseJson: any) => {
    const attemptId = responseJson?.data?.id
    const lessonCompleted = responseJson?.data?.attributes?.lesson_completed
    this.setState({ lessonAttemptId: attemptId })

    if (attemptId && !lessonCompleted) {
      this.updateLessonAttempt(attemptId)
    } else {
      this.getLessonSummaryListApi()
    }
  }

  handleUpdateLessonAttemptResponse = () => {
    this.getLessonSummaryListApi()
  }

  handleRateResponse = (responseJSON: any) => {
    if (responseJSON?.meta?.message === "rating submited") {
      this.getLessonSummaryListApi()
      this.setState({ openRating: true });
    } else {
      alert("Error rating lesson!");
    }
  }

  handleFeedbackResponse = (responseJSON: any) => {
    if (responseJSON?.meta?.message === "feedback submited") {
      this.getLessonSummaryListApi()
      this.setState({ openFeedback: true, showFeedbackContainer: false })
    } else {
      alert("Error feedback lesson!");
    }
  }

  handleShareResponse = (responseJSON: any) => {
    if (responseJSON.data) {
      this.getLessonSummaryListApi()
      this.setState({
        iswellDoneShareModelOpen: true,
        openShare: false
      })
    } else {
      alert("Error sharing score!");
    }
  }

  handleQuestionAndAnswerResponse = (responseJSON: any) => {
    if (responseJSON?.data?.length) {
      let averageScore: number = responseJSON.data.reduce((prev: number, cur: any) => {
        const { grade } = cur.attributes

        return prev + grade;
      }, 0) / responseJSON.data.length;

      if (averageScore % 1 !== 0) {
        averageScore = Number(averageScore.toFixed(1))
      }

      responseJSON.data.pop()

      const integerPart = Math.floor(averageScore);
      const decimalPart = averageScore - integerPart;

      let beforeNumber, afterNumber;
      if (decimalPart === 0) {
        beforeNumber = integerPart - 1;
        afterNumber = integerPart;
      } else {
        beforeNumber = integerPart;
        afterNumber = integerPart + 1;
      }

      this.setState({ avgScore: String(averageScore || "0"), avgScoreEnd: String(afterNumber), avgScoreStart: String(beforeNumber) })
    } else {
      this.setState({ avgScore: "0", avgScoreEnd: "0", avgScoreStart: "0" })
    }
  }

  handleLessonSumaryResponse = (responseJSON: any) => {
    const microLessons = responseJSON?.micro_lessons
    microLessons.sort((a: any, b: any) => a?.micro_lesson_id - b?.micro_lesson_id)

    const otherData = [
      {
        name: 'socializer',
        XP: responseJSON?.socializer?.earned_xp
      },
      {
        name: 'philantropist',
        XP: responseJSON?.philantropist?.earned_xp
      }
    ]

    const microLessonsData = microLessons?.map((item: any) => {
      return {
        name: item?.name,
        XP: item?.earned_xp
      }
    })

    const sumaryData = [...microLessonsData, ...otherData]
    const totalXPData: number = sumaryData?.reduce((prev: number, cur: any) => {
      return prev + cur?.XP;
    }, 0)

    this.setState({ summaryList: sumaryData, totalXP: totalXPData, isLoading: false })
  }

  async componentDidMount() {
    this.updateAvatar()
    const token = localStorage.getItem('token') || ""
    localStorage.getItem('updatedName')
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
    const lessonName = this.props.navigation.getParam('lessonName');
    const lessonId = this.props.navigation.getParam('lessonId');
    this.setState({
      topicName: updatedName.topicName,
      unitName: updatedName.unitName,
      lessonName: decodeURIComponent(lessonName),
      token,
      lessonId
    })

    this.getLatestLessonAttemps(token, lessonId)
    this.getCurrentUserProfile(token)
  }

  getLessonSummaryListApi = () => {
    this.getQuestionAndAnswer()
    this.getLessonSumary()
    this.getTotalSummaryAverage()
  }

  formatLessonName = (str: string) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }

  showTotalXpSection = () => {
    this.setState({
      showTotalXp: true,
      showAvgGrade: false,
      showMainCard: false,
    });
  };

  showAvgGradeSection = () => {
    this.setState({
      showTotalXp: false,
      showAvgGrade: true,
      showMainCard: false,
    });
  };

  showMainCard = () => {
    this.setState({
      showTotalXp: false,
      showAvgGrade: false,
      showMainCard: true,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickBackLandingPage = () => {
    localStorage.setItem('isBackFromMicrolessonVideo', 'true')
    this.props.navigation.navigate('LandingPageWeb')
  }

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    }
    else if (type === 'median') {
      this.setState({ medianOpen: true, openDrawer: true })
    }
  }

  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, mathOpen: false, medianOpen: false });

  };

  handleCloseFeedback = () => {
    this.setState({ showFeedbackContainer: false });
  };

  handleOpenFeedback = () => {
    this.setState({ showFeedbackContainer: true });
  };

  handleChangeFeedbackValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ feedbackValue: e.target.value })
  }

  handleFeedbackOpen = () => {
    this.feedbackCallId = sendAPIRequest(
      "bx_block_profile/feedback_records",
      {
        method: "POST",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body: {
          data: {
            lesson_id: Number(this.props.navigation.getParam("lessonId")),
            opinion_matter: this.state.feedbackValue,
            lesson_attempt_id: this.state.lessonAttemptId
          }
        }
      }
    )
  };

  handleFeedbackClose = () => {
    this.setState({ openFeedback: false, showFeedbackContainer: false, feedbackValue: '' });
  };

  handleWellDoneShareModal = () => {
    this.setState({
      openShare: true,
      customShareOpen: true
    });
  };

  getLatestLessonAttemps = (token: string, lessonId: number) => {
    this.getLatestLessonAttempsId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts/latest?lesson_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  updateLessonAttempt = (attemptId: string) => {
    const body = {
      lesson_completed: true,
      lesson_completed_at: new Date().toISOString()
    }

    this.updateLessonAttemptId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts/${attemptId}`,
      {
        method: 'PUT',
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body
      }
    )
  }

  getQuestionAndAnswer = () => {
    this.questionAndAnswerCallId = sendAPIRequest(
      `bx_block_educational_gamification/question_and_answers?lesson_id=${this.state.lessonId}&lesson_attempt_id=${this.state.lessonAttemptId}`,
      {
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      }
    )
  }

  getLessonSumary = () => {
    this.lessonSumaryCallId = sendAPIRequest(
      `bx_block_educational_gamification/accounts_event_points/base_by_lesson?lesson_id=${this.state.lessonId}&lesson_attempt_id=${this.state.lessonAttemptId}`,
      {
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      }
    )
  }

  getTotalSummaryAverage = () => {
    this.totalSummaryAverageId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts/summarize_by_end_of_lesson?lesson_id=${this.state.lessonId}&lesson_attempt_id=${this.state.lessonAttemptId}`,
      {
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        }
      }
    )
  }

  handleCallingShareAPI = () => {
    this.shareCallId = sendAPIRequest(
      "bx_block_share/share",
      {
        method: "POST",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body: {
          data: {
            share_record_type: "scorecard",
            lesson_attempt_id: this.state.lessonAttemptId,
            lesson_id: Number(this.props.navigation.getParam("lessonId"))
          }
        }
      }
    )
  }

  handleRatingClose = () => {
    this.setState({
      openRating: false
    })
  };

  handleCloseShareOptions = () => {
    this.setState({
      openShare: false
    })
  }

  handleAfterShare = (text: string) => {
    const element = document.getElementById("img-for-share");
    if (element) {
      html2canvas(element)
        .then((canvas: any) => {
          const imgData = canvas.toDataURL("img/png");

          const link = document.createElement("a");
          link.href = imgData;
          link.download = "tedix.png";
          link.click();
        })
        .then(this.handleCallingShareAPI)
    }
  }

  handleClickRating = (value: number | null = 1) => {
    this.setState({
      ratingValue: value
    })
    this.rateCallId = sendAPIRequest(
      "bx_block_profile/ratings",
      {
        method: "POST",
        body: {
          data:
          {
            lesson_id: Number(this.props.navigation.getParam("lessonId")),
            rate: value,
            lesson_attempt_id: this.state.lessonAttemptId
          }
        },
        headers: {
          "Content-Type": "application/json",
          token: this.state.token,
        }
      }
    )
  }

  updateAvatar = () => {
    const color = localStorage.getItem('avatarColor') || '#62cdff';
    const colorToreportTeddyMap = {
        '#ff8400': reportOrange,
        '#62cdff': reportBlue,
        '#b7f0ad': reportMint,
        '#a95edb': reportPurple,
        '#f96855': reportRed,
        '#884a39': reportBrown,
        '#fdce36': reportYellow,
        '#2bce8c': reportGreen
    };
    const colorToUpdateTeddy:any = {
      '#ff8400': orangePartyTeddy,
      '#62cdff': bluePartyTeddy,
      '#b7f0ad': mintPartyTeddy,
      '#a95edb': purplePartyTeddy,
      '#f96855': redPartyTeddy,
      '#884a39': brownPartyTeddy,
      '#fdce36': yellowPartyTeddy,
      '#2bce8c': greenPartyTeddy
    };
  
    const partyTeddy = colorToUpdateTeddy[color] || bluePartyTeddy;
  
    const reportTeddy = (colorToreportTeddyMap as any)[color];
        this.setState({ honestTeddy: reportTeddy, partyTeddy: partyTeddy });
  } 

  // Customizable Area End
}
