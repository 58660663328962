export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const tedixIcon =require('../assets/tedix-icon.svg')
export const pandaIcon =require('../assets/pandaIcon.svg')
export const imgGroupLogo = require("../assets/group_logo.png");
export const imgGroupMail = require("../assets/group_mail.png");
export const imgEnglish = require("../assets/image_english.png");
export const imgLock = require("../assets/lock.png");
export const createNewPassword =require('../assets/create-new-password.svg')
export const imgTermsConds = require("../assets/terms_conditions.svg");
