import React from "react";
// Customizable Area Start
import {
    TextField,
    Box,
    Button,
    Typography,
    Grid,
    styled,
    Dialog,
    IconButton
} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import BottomMenu from "../../../components/src/BottomMenu";
import Carousel from 'react-elastic-carousel';
import {
    skip_wrench,
    btn_back,
    skip_men,
    skip_password,
    skip_calendar,
    skip_card,
    imageMastercard,
    skipAdd,
    skipDelete,
    backgroundCard,
    imageVisa,
    group_mail,
    groupBear,
    group_teddy1,
    white_loader
} from "./assets";
import NavigateLayout from "../../../components/src/NavigateLayout";
import PlanDetailsMethodController from "./PlanDetailsMethodController.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

class PlanDetailsMethod extends PlanDetailsMethodController {
    // Customizable Area Start
    renderCarouselCard({
        cardBrand,
        funding,
        last4,
        name,
        expire
    }: any) {
        return (
            <Box className="parentCardSection" style={{ position: 'relative' }}>
                <Box className="backgroundCard backgroundCardBlur" style={{ position: "absolute" }}>
                </Box>
                <Box className="backgroundCard" style={{ position: "absolute" }}>
                </Box>
                <Box data-testid="btnCardDelete" onClick={() => this.handleClickDeleteCard(this.state.isShowDeleteCard)} id="card-container">
                    <Box style={{ position: 'relative', zIndex: 4 }}>
                        <Box className="parentTextSection" style={{ filter: this.state.isShowDeleteCard ? 'blur(5px)' : 'none' }}>
                            <div className='cardFunding'>
                                <Typography component='span' className="creditTitileText">{funding}</Typography>
                                <span> <img src={cardBrand === 'visa' ? imageVisa : imageMastercard} className="" alt="visa img" /></span>
                            </div>
                            <Typography className="cardNumberMeth">
                                {this.state.tempFormData.cardNumber || `•••• •••• •••• ${last4}`}
                            </Typography>
                            <Grid container spacing={2} className='cardInformation'>
                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSectionMeth1">
                                        <span className="textTitle1">{this.props.t("CARDHOLDER")}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSectionMeth1">
                                        <span className="textTitle2">{this.props.t("EXPIRE")}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSectionMeth1">
                                        <span className="textTitle3">CVV</span>
                                    </div>
                                </Grid>

                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSection">
                                        <span className="textTitileCard" style={{ width: '100px' }}>{this.state.tempFormData.cardholderName || name}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSection">
                                        <span className="datetext">{this.state.tempFormData.expire || expire}</span>
                                    </div>
                                </Grid>
                                <Grid item xs={4} className='formItem'>
                                    <div className="mainSection">
                                        <span className="textcvvMet">{this.state.tempFormData.cvv || "•••"}</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        {this.state.isShowDeleteCard &&
                            <Box className="deleteCardOverlay" id="delete-card-overlay">
                                <div data-testid="btnShowPopupDelete" className="deleteCardOverlayContent" style={{ cursor: "pointer" }} onClick={() => this.handleShowDeleteModal()}>
                                    <img src={skipDelete} alt="group_mail" />
                                    <Typography className="deleteCardOverlayText">
                                        {this.props.t("Delete card")}
                                    </Typography>
                                </div>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        )
    }
    renderFormSection() {
        return (
            <div className="formParentSection">
                <Box>
                    <Box style={webStyle.textFieldBox}>
                        <TextField
                            data-testid='inputFieldCardName'
                            disabled={!this.handleDisableInput()}
                            variant="outlined"
                            className={this.state.isValidField.cardholderName ? "" : 'errorBorder'}
                            fullWidth
                            required
                            placeholder={this.props.t("Cardholder name")}
                            name="cardholderName"
                            value={this.handleGetValueInput().cardholderName}
                            onChange={(e) => this.handleInputChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={skip_men} alt="Image center" style={webStyle.iconImg} />
                                    </InputAdornment>
                                ),

                            }}
                            style={webStyle.cardtextField}
                        />
                    </Box>
                    <Box style={webStyle.textFieldBox}>
                        <TextField
                            data-testid='inputFieldCardNumber'
                            disabled={!this.handleDisableInput()}
                            className={this.state.isValidField.cardNumber ? "" : 'errorBorder'}
                            variant="outlined"
                            fullWidth
                            placeholder={this.props.t("Card number")}
                            name="cardNumber"
                            value={this.handleGetValueInput().cardNumber}
                            onChange={(e: any) => this.handleInputChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={skip_card} alt="Image" style={webStyle.iconImg} />
                                    </InputAdornment>
                                ),
                            }}
                            style={webStyle.cardtextField}
                        />
                    </Box>

                    <div style={webStyle.textFieldBox1}>
                        <TextField
                            data-testid='inputFieldExpire'
                            disabled={!this.handleDisableInput()}
                            variant="outlined"
                            className={this.state.isValidField.expire ? "" : 'errorBorder'}
                            fullWidth
                            placeholder={this.props.t("Expire")}
                            name="expire"
                            value={this.handleGetValueInput().expire}
                            onChange={(e) => this.handleInputChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={skip_calendar} alt="Image" style={webStyle.iconImg} />
                                    </InputAdornment>
                                ),
                            }}
                            style={webStyle.cardtextField1}
                        />
                        <TextField
                            data-testid='inputFieldCvv'
                            disabled={!this.handleDisableInput()}
                            variant="outlined"
                            className={this.state.isValidField.cvv ? "" : 'errorBorder'}
                            fullWidth
                            inputProps={{ maxLength: 3 }}
                            placeholder="CVV"
                            name="cvv"
                            value={this.handleGetValueInput().cvv}
                            onChange={(e) => this.handleInputChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={skip_password} alt="Image" style={webStyle.iconImg} />
                                    </InputAdornment>
                                ),
                            }}
                            style={{ ...webStyle.cardtextField1, marginRight: '0' }}
                        />
                    </div>
                </Box>

                <Box style={{ width: "250px", margin: "10px" }}>
                    <label className={`containerCheckbox disableCheckbox`} style={webStyle.checkboxLabel2}>
                        <span style={webStyle.checkboxText}>
                            {this.props.t("Save card and details securely")}
                        </span>
                        <input disabled={true} name="isSaveCard" type="checkbox" checked={this.state.formData.isSaveCard} />
                        <span className="checkmark"></span>
                    </label>
                </Box>
                <Box style={{ width: "200px", marginLeft: "10px" }}>
                    <label className={`containerCheckbox ${!this.handleDisableInput() ? "disableCheckbox" : ""} ${this.handleGetValueInput().isSetPrimaryPayment ? "" : "unchecked"}`} style={webStyle.checkboxLabel2}>
                        <span style={webStyle.checkboxText}>
                            {this.props.t("Set as Primary Payment")}
                        </span>
                        <input data-testid='checkedPrimaryPayment' onChange={(e) => this.handleInputChange(e)} disabled={!this.handleDisableInput()} name="isSetPrimaryPayment" type="checkbox" checked={this.handleGetValueInput().isSetPrimaryPayment} />
                        <span className="checkmark"></span>
                    </label>
                </Box>
            </div>
        )
    }
    renderAddCard() {
        return (
            <Box className="btnShowAddForm" data-testid='btnShowAddForm' onClick={() => this.handleToogleAddCard(this.state.isNewCard)}>
                <Box style={{ display: "flex", gap: "16px", alignItems: 'center' }}>
                    <img src={skipAdd} width={18} height={18} />
                    <Typography style={{ fontFamily: "Quicksand", fontWeight: 700, fontSize: "16px", color: "#FFFFFF" }}>
                        {this.props.t("Add new card")}
                    </Typography>
                </Box>
            </Box>
        )
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings">
                <SettingContainer>
                    <Box className="mainContentWrapper">
                        <Box className="settingBackground">
                            <Box className="settingImgWrapper">
                                <img src={skip_wrench} className="teddyImage" alt="Teddy Setting" />
                                <img src={this.state.teddyBear} className="teddy-setting-image" alt="Teddy Setting" style={{ alignSelf: 'center' }} />
                                <Box className="settingTitle">
                                    <IconButton data-test-id='btnBack' onClick={this.handleClickGoBack} className="btn-back-mobile">
                                        <img src={btn_back} className="arrowImage" alt="arrow btn" width={16} height={16} style={{ marginRight: '5px' }} />
                                    </IconButton>
                                    <Box className='settingTitle__text mobile'>
                                        {this.props.t("Payment Method")}
                                    </Box>
                                    <Box className='settingTitle__text tablet-up'>
                                        {this.props.t("Settings")}
                                    </Box>
                                    {/* <div style={{ alignSelf: 'auto', marginTop: '-7px' }}>Method</div> */}
                                </Box>
                                <Box className="ButtonSection">
                                    <Button
                                        disabled={!this.state.isNewCard && this.state.indexCardItem === this.state.listCard.length}
                                        data-testid='btnTextTitle'
                                        className={`buttonTextTitle ${!this.state.isNewCard && this.state.indexCardItem === this.state.listCard.length ? "disableEdit" : ""}`}
                                        onClick={() => this.handleButtonEdit()}
                                    >
                                        {this.state.loading ? <img src={white_loader} width={25} alt="Loading" /> : (this.state.isEdit || this.state.isNewCard) ? this.props.t('Save') : this.props.t('Edit')}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="contentContainer">
                            <Box className="title-wrapper">
                                <img src={btn_back} alt="ArrowBack" data-test-id="btn-back" className="title-btn-back-desktop" width={16} height={16} onClick={this.handleClickGoBack} />

                                <Typography className="title-text">{this.props.t("Payment Method")}</Typography>
                            </Box>
                            <Box className="btn-active-payment-wrapper" style={{
                                padding: '0px 50px',
                                marginBottom: '30px',
                                visibility: this.state.listCard[this.state.indexCardItem]?.id === this.state.defaultPaymentId ? "visible" : "hidden"
                            }}>
                                <Button data-testid='btnActivePayment' className='btnActivePayment' style={{
                                    width: '180px',
                                    height: 'auto',
                                    padding: '8px 0',
                                    borderRadius: '14px',
                                    background: '#F7F7F733',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {this.props.t("Active Payment Method")}
                                </Button>
                            </Box>
                            <Carousel ref={ref => (this.carousel = ref)} isRTL={false} itemsToShow={1} outerSpacing={40} showArrows={false} onChange={(_currentItemObject, currentPageIndex) => this.handleSwipeCard(currentPageIndex)} >
                                {this.state.listCard.map(({ attributes, id }) => (this.renderCarouselCard(this.handleReturnDataCard({ attributes, id }))))}
                                {this.renderAddCard()}
                            </Carousel>
                            {(this.state.indexCardItem < this.state.listCard.length || this.state.isNewCard) && this.renderFormSection()}
                            <div style={{
                                width: '292px',
                                height: '281px',
                                top: '286px',
                                left: '51px',
                                borderRadius: '12px',
                                marginBottom: '40px',
                            }}>
                                <Dialog
                                    open={this.state.isOpenPopup}
                                    onClose={this.handlePopupClose}
                                    fullWidth
                                    maxWidth="sm"
                                    style={{
                                        width: '100%',
                                        borderColor: '#E6E6E6',
                                    }}
                                >
                                    <div style={{ padding: '30px 26px 25px 26px', border: 'solid 2px #E6E6E6', borderRadius: '12px', textAlign: 'center' }}>
                                        <Box>
                                            <img src={group_mail} style={{ display: 'block', margin: 'auto' }} alt="group_mail" />
                                            <Typography className="deleteCardText" style={{
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                lineHeight: '26px',
                                                fontWeight: 900,
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: '#363636',
                                            }}>
                                                {this.props.t("Delete card")}
                                            </Typography>
                                            <Typography className="textTitlemiddle">
                                                {this.props.t("Are you sure you want to delete this credit/debit card?")}
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '10px 21px',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            lineHeight: '12px',
                                            letterSpacing: '0.800000011920929px',
                                            textAlign: 'center',
                                        }}>
                                            <Button
                                                id="cancel-delete-btn"
                                                onClick={() => this.handlePopupClose()}
                                                color="primary"
                                                className="cancelBtnText"
                                                data-testid="btnSubmitSec"
                                                style={{
                                                    width: '110px',
                                                    height: '41.9px',
                                                    borderRadius: '12px',
                                                    background: 'white',
                                                    border: '2px solid #CCCCCC',
                                                    color: '#CCCCCC',
                                                    textTransform: 'none',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {this.props.t("Cancel")}
                                            </Button>
                                            <Button data-testid="btnDelete" color="primary" className="deleteBtnText" style={{
                                                width: '110px',
                                                height: '44px',
                                                backgroundColor: '#F96855',
                                                borderRadius: '12px',
                                                border: '0px solid #EA5A47',
                                                borderBottomWidth: '2px',
                                                textTransform: 'none',
                                                fontWeight: 700,
                                                color: '#fff'
                                            }} onClick={() => this.handleDeleteCard()} id="confirm-delete-btn">
                                                {this.props.t("Delete")}
                                            </Button>
                                        </Box>
                                    </div>
                                </Dialog>
                            </div>
                            <div style={{
                                width: '292px',
                                height: '281px',
                                top: '286px',
                                left: '51px',
                                borderRadius: '12px',
                                marginBottom: '40px',
                            }}>
                                <Dialog
                                    data-testid='dialog-card-error'
                                    open={Boolean(this.state.cardErrorMessageStripe)}
                                    onClose={this.handlePopupClose}
                                    fullWidth
                                    maxWidth="xs"
                                    style={{
                                        width: '100%',
                                        borderColor: '#E6E6E6',
                                    }}
                                >
                                    <div style={{ padding: '30px 26px 25px 26px', textAlign: 'center' }}>
                                        <Box>
                                            <img src={groupBear} style={{ display: 'block', margin: 'auto' }} alt="group_mail" />
                                            <Typography className="cardMessageError" style={{
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                lineHeight: '26px',
                                                fontWeight: 900,
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: '#363636',
                                            }}>
                                                {this.props.t("Let's try again")}
                                            </Typography>
                                            <Typography className="textTitlemiddle" style={{
                                                marginTop: "12px"
                                            }}>
                                                {this.state.cardErrorMessageStripe}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        padding: '10px 21px',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        lineHeight: '12px',
                                        letterSpacing: '0.8px',
                                        textAlign: 'center',
                                    }}>
                                        <Button
                                            id="try-again-btn"
                                            onClick={() => this.handleCloseCardError()}
                                            color="primary"
                                            className="tryAgainBtn"
                                            data-testid="btnTryAgain"
                                            style={{
                                                width: '110px',
                                                height: '41.9px',
                                                borderRadius: '12px',
                                                background: '#36BFFF',
                                                border: '2px solid #36BFFF',
                                                color: '#FFFFFF',
                                                textTransform: 'none',
                                                fontWeight: 700,
                                                borderBottom: "4px solid #00AEFF"
                                            }}
                                        >
                                            {this.props.t("Try again")}
                                        </Button>
                                    </Box>
                                </Dialog>
                            </div>

                        </Box>
                        <BottomMenu />
                    </Box>
                </SettingContainer>
            </NavigateLayout>
            // Customizable Area End
        );
    }
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',
    "& .teddy-setting-image": {
        bottom: '0px',
        marginRight: '10px',
        Width: '245px',
        padding: '0',
        position: 'absolute',
        marginLeft: '0',
        display: 'none',
        left: 'calc(45% - 207px)',

    },
    "& .title-text": {
        fontSize: '25px',
        fontFamily: 'Nunito',
        fontWeight: '900'
    },
    "& .title-wrapper": {
        color: "rgb(255, 255, 255)",
        width: "100%",
        display: "none",
        position: "relative",
        textAlign: "center",
        fontFamily: "Nunito",
        fontWeight: "900",
        paddingTop: "22px",
        justifyContent: "center",
    },
    "& .title-btn-back-desktop": {
        position: 'absolute',
        left: '24px',
        top: '55%',
        cursor: 'pointer'
    },
    "& .btn-back-mobile": {
        display: 'initial'
    },
    // Custom checkbox
    '& .btnActivePayment .MuiButton-label': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '8px',
        letterSpacing: '0px',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'capitalize',
    },

    '& .cardFunding': {
        display: 'flex',
        justifyContent: 'space-between',
        margin: "15px 15px 0 15px"
    },
    '& .cardInformation': {
        margin: '0 15px 24px 15px'
    },
    "& .formParentSection .containerCheckbox": {
        "display": "block",
        "position": "relative",
        "paddingLeft": "35px",
        "marginBottom": "12px",
        "cursor": "pointer",
        "fontSize": "22px",
        "WebkitUserSelect": "none",
        "MozUserSelect": "none",
        "MsUserSelect": "none",
        "userSelect": "none"
    },
    "& .containerCheckbox input": {
        "position": "absolute",
        "opacity": "0",
        "cursor": "pointer",
        "height": "0",
        "width": "0"
    },
    "& .checkmark": {
        "border": "3px solid #36BFFF",
        "border-radius": "4px",
        "position": "absolute",
        "top": "0",
        "left": "0",
        "height": "20px",
        "width": "20px",
        "backgroundColor": "#F7F7F7"
    },
    '& .disableCheckbox': {
        "& .checkmark": {
            "border": "3px solid #999999",
        },
        "& .checkmark:after": {
            "border": "solid #CCCCCC",
            "left": "5px",
            "top": "1px",
            "width": "5px",
            "height": "10px",
            "borderWidth": "0 3px 3px 0",
            "WebkitTransform": "rotate(45deg)",
            "MsTransform": "rotate(45deg)",
            "transform": "rotate(45deg)"
        },
    },

    '& .unchecked': {
        "& .checkmark": {
            "border": "3px solid #E6E6E6",
        },
    },
    "& .containerCheckbox:hover input ~ .checkmark": {
        "backgroundColor": "#ccc"
    },
    "& .containerCheckbox input:checked ~ .checkmark": {
        "backgroundColor": "#FFF"
    },
    "& .checkmark:after": {
        "content": "\"\"",
        "position": "absolute",
        "display": "none"
    },
    "& .containerCheckbox input:checked ~ .checkmark:after": {
        "display": "block"
    },
    "& .containerCheckbox .checkmark:after": {
        "left": "5px",
        "top": "1px",
        "width": "5px",
        "height": "10px",
        "border": "solid #62CDFF",
        "borderWidth": "0 3px 3px 0",
        "WebkitTransform": "rotate(45deg)",
        "MsTransform": "rotate(45deg)",
        "transform": "rotate(45deg)"
    },
    // End custom checkbox
    '& .formItem': {
        padding: "4px 0"
    },
    "& .MuiButtonBase-root": {
        width: "38px",
        height: "38px"
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: "#62CDFF"
    },
    "& .btnShowAddForm": {
        width: "300px",
        height: "170px",
        marginTop: "15px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFFFFF33',
        borderRadius: "16px",
        cursor: 'pointer'
    },
    '& .errorBorder .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'red',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'red',
        },
    },
    '& .deleteCardOverlay': {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        width: '100%',
        height: '100%',
        top: 0,
        borderRadius: '15px',
        display: 'flex',
        color: 'white'
    },
    '& .deleteCardOverlayContent': {
        display: 'flex',
        margin: 'auto',
        gap: '16px'
    },
    '& .deleteCardOverlayText': {
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: "Quicksand"
    },

    '& .rec-carousel-wrapper .rec-dot': {
        backgroundColor: '#CCCCCC',
        boxShadow: 'none'
    },

    '& .rec-carousel-wrapper .rec-dot_active': {
        backgroundColor: '#FFFFFF',
        boxShadow: 'none'
    },

    "& .ButtonSection .disableEdit": {
        backgroundColor: '#F7F7F7',
        color: '#CCCCCC'
    },

    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },
    '& .formParentSection': {
        padding: '0px 55px',
        '& .MuiOutlinedInput-root': {
            height: '40px'
        },
        '& .MuiInputBase-input': {
            height: '0px'
        }
    },
    '& .parentCardSection': {
        width: "330px",
        height: "220px"
    },
    '& .mainContentWrapper': {
        display: 'flex',
        background: "linear-gradient(180deg, #A95EDB 0%, #62CDFF 100%)",
        flexDirection: 'column',
        height: 'auto%',
    },
    '& .textTitle1': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
    },
    '& .textTitle2': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .textTitle3': {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .textTitileCard': {
        textOverflow: "ellipsis",
        overflow: 'hidden',
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF'
    },
    '& .mainSection': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
        maxWidth: '200px',
        maxHeight: '100px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: "hidden"
    },
    '& .mainSectionMeth1': {
        display: 'flex',
        maxHeight: '100px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        justifyContent: 'space-between',
        maxWidth: '200px',
        overflow: "hidden"
    },
    '& .textcvvMet': {
        lineHeight: '18px',
        color: '#FFFFFF',
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
    },
    '& .cardNumberMeth': {
        fontFamily: 'Quicksand',
        textAlign: 'left',
        color: '#FFFFFF',
        margin: "15px",
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0.45em',
    },
    '& .deleteBtnText': {
        width: '110px',
        height: '44px',
        borderColor: '#E6E6E6',
        backgroundColor: '#F96855',
        color: '#fff',
    },

    '& .creditTitileText': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
        textTransform: 'capitalize'
    },
    '& .datetext': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '18px',
        color: '#FFFFFF',
    },
    '& .backgroundCard': {
        position: 'absolute',
        top: "0%",
        width: "100%",
        height: "100%",
        backgroundImage: `url(${backgroundCard})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    '& .backgroundCardBlur': {
        filter: 'blur(10px)',
        zIndex: 4
    },
    '& .parentTextSection': {
        width: '100%',
        height: '90%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        borderRadius: '12px',
        padding: '20px',
    },
    '& .buttonTextTitle': {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#FFFFFF',
        backgroundColor: '#36BFFF',
        textTransform: 'none',
        padding: '12px',
        borderRadius: '10px',
    },
    '& .ButtonSection': {
        width: '70px',
        borderRadius: '10px',
        backgroundColor: '#36BFFF',
        marginRight: '22px',
    },

    '& .mainContentFirstWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    '& .mainPremiumWrSubWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    '& .settingBackground': {
        backgroundColor: '#A95EDB',
        marginBottom: "-12px"
    },
    '& .settingpremiumBackground': {
        backgroundColor: '#fff',
    },
    '& .settingImgWrapper': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },

    '& .settingTitle': {
        display: "flex",
        marginBottom: '12px',
        width: '100%',
        position: 'relative',
    },
    '& .settingTitle .settingTitle__text': {
        marginLeft: "40%",
        marginRight: "30%",
        flex: "1 0",
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        textAlign: 'center',
    },

    "& .settingTitle .settingTitle__text.mobile": {
        display: 'block'
    },


    "& .settingTitle .settingTitle__text.tablet-up": {
        display: 'none'
    },
    "& .settingTitle button": {
        position: "absolute",
        left: 0,
        top: "-4px",
        marginLeft: "10px",
        padding: "4px"
    },
    '& .teddyImage': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "10%"
    },
    '&.arrowImage': {
        width: "30%",
        height: "auto",
    },

    '&.arrowsImage': {
        width: "30%",
        height: "auto",
        position: 'absolute',
        left: '25%',
        top: '29px',
    },
    '& .group_stateImage': {
        marginTop: '40px'
    },
    '& .contentContainer': {
        flex: '6',
        backgroundColor: '#fff',
        overflowY: 'scroll',
        padding: '32px 0px 100px',
        flexDirection: 'column',
        alignItems: 'center',
        background: 'linear-gradient(rgb(169, 94, 219) 0%, rgb(98, 205, 255) 100%)',
    },
    '& .contentbenefitContainer': {
        flex: '6',
        backgroundColor: '#fff',
        overflow: 'scroll',
        padding: '32px 24px 100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    '& .contentPreminumContainer': {
        flex: '6',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
        overflow: 'scroll',
        padding: '32px 24px 100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    '& .BenefitsWrapper': {
        width: '100%',
        maxWidth: 600,
        border: '2px solid #e6e6e6',
    },


    '& .benefitsListTitle': {
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
    },

    [theme.breakpoints.up('md')]: {
        "& .teddy-setting-image": {
            width: '360px',
            maxWidth: 'unset',
            left: 'calc(30% - 120px)',
            display: 'initial'
        },
        "& .btn-active-payment-wrapper": {
            marginTop: '20px'
        },
        "& .title-wrapper": {
            display: 'flex'
        },
        "& .btn-back-mobile": {
            display: 'none'
        },

        "& .settingTitle .settingTitle__text.mobile": {
            display: 'none'
        },


        "& .settingTitle .settingTitle__text.tablet-up": {
            display: 'block',
            fontSize: '30px'
        },

        '& .cardNumberMeth': {
            // margin: "4px 15px"
        },
        '& .mainContentFirstWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },
        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },
        '& .contentContainer': {
            paddingTop: '0',
        },
        '& .contentPreminumContainer': {
            paddingTop: '90px',
        },
        '& .settingBackground': {
            flex: 5,
            height: "100%"
        },
        '& .settingpremiumBackground': {
            flex: 5,
        },
        '& .settingImgWrapper': {
            paddingTop: 'unset',
        },
        '& .mainContentWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
            height: "100%"
        },

        '& .teddyImage': {
            display: 'none'
        },

        '& .settingTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .BenefitsWrapper': {
            maxWidth: 450,
        },

    },

    [theme.breakpoints.up('lg')]: {
        "& .teddy-setting-image": {
            left: 'calc(50% - 207px)',
            width: '360px',
        },


        '& .cardNumberMeth': {
            margin: "15px"
        },
        '& .mainContentWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },
        '& .mainContentFirstWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },
        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

        '& .BenefitsWrapper': {
            maxWidth: 600,
        },

    },

    [theme.breakpoints.down('sm')]: {
        '& .settingTitle button': {
            marginLeft: "-8px"
        },
        '& .cardNumberMeth': {
            margin: "15px",
            fontSize: "13px"
        },
        "& .btnShowAddForm": {
            width: "270px",
            height: "155px"
        },
        '&  .arrowsImage': {
            position: 'absolute',
            left: '15%', top: '75px',
        },
        '& .contentContainer': {
            flex: '6',
            overflow: 'scroll',
            padding: '32px 0px 100px',
            alignItems: 'center',
            backgroundColor: '#fff',
            flexDirection: 'column',
            marginBottom: '50px',
        },

    },
    [theme.breakpoints.down('xs')]: {

        '& .cardNumberMeth': {
            margin: "4px 15px"
        },
        '&  .arrowsImage': {
            top: '75px',
            position: 'absolute',
            left: '15%',
        },
        '& .contentContainer': {
            flex: '6',
            flexDirection: 'column',
            backgroundColor: '#fff',
            overflow: 'scroll',
            alignItems: 'center',
            marginBottom: '50px',
            padding: '32px 0px 100px',
        },
    },

    [theme.breakpoints.down(375)]: {
        '& .btnShowAddForm': {
            marginTop: "0px"
        },
        "& .cardNumberMeth": {
            fontSize: "12px",
            margin: '0px 4px'
        },
        '& .parentTextSection': {
            height: "70%"
        },
        '& .cardInformation': {
            width: 'calc(100% + 45px)'
        },
        '& .cardFunding,.cardInformation': {
            margin: '0px 4px 8px',
        }
    },

    [theme.breakpoints.between('xs', 345)]: {
        '& .parentTextSection .cardNumberMeth': {
            fontSize: "11px"
        },
        '& .deleteCardOverlay': {
            height: "90%"
        },
        '& .textTitle1': {
            fontSize: '10px'
        },
        '& .cardNumberMeth': {
            margin: '0px'
        },
        '& .cardFunding,.cardInformation': {
            margin: '0px 0px 8px',
        }
    },

    [theme.breakpoints.down(725)]: {
        "& .settingTitle button": {
            top: "10px"
        }
    },
    [theme.breakpoints.between(960, 1870)]: {
        "& .settingTitle button": {
            top: "10px"
        }
    },

    [theme.breakpoints.between('xs', 408)]: {
        '& .rec-carousel-item .rec-item-wrapper': {
            // minWidth: "250px"
        },
    },
    [theme.breakpoints.between(400, 600)]: {
        '& .rec-carousel-item .rec-item-wrapper': {
            // minWidth: "250px"
        },
        '& .cardNumberMeth': {
            margin: "15px"
        },
    },
}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },
    textFieldBox: {
        margin: "20px 0",
        '& input::placeholder': {
            color: 'red',
            opacity: '1',
        }
    },
    iconImg: {
        marginRight: "10px",
        width: "24px",
        height: "24px",
    },

    premiumtextTitle: {
        fontFamily: 'Nunito',
        fontSize: '25px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        width: '100%',
        paddingLeft: '20px'
    },
    cardtextField: {
        borderRadius: "10px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "12px",
        letterSpacing: "0px",
        textAlign: "left" as "left",
        color: "#999999",
        backgroundColor: "white",
    },
    textFieldBox1: {
        display: "flex",
        alignItems: "center",
        marginRight: "0px",
    },
    cardtextField1: {
        borderRadius: "10px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "12px",
        letterSpacing: "0px",
        textAlign: "left" as "left",
        color: "#999999",
        backgroundColor: "white",
        marginRight: "10px",
    },

    checkboxLabel: {
        display: "flex",
        alignItems: "center",
        marginTop: '16px'
    },
    checkboxLabel2: {
        display: "flex",
        alignItems: "center",
    },
    checkboxsText: {
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '18px',
        height: '18px',
    },
    checkboxText: {
        textAlign: "left" as "left",
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '0px',
        color: '#F2F2F2',
    },
    textMainsection: {
        marginTop: '10px',
    },
    textSubFirst: {
        fontFamily: 'Quicksand',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#FFFFFF',
    },
    textSubSecond: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#FFFFFF',
    },




    benefitsParent: {
        width: '100%',
        height: 'auto',
        top: 'auto',
        bottom: '0',
        backgroundColor: '#FFFFFF',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
    },

    ExploreText: {
        lineHeight: '8.5px',
        padding: '12px 1px 12px 12px',
        textAlign: 'right' as 'right',
        color: '#00AEFF',
        fontSize: '14px',
        fontFamily: 'Quicksand',
        fontWeight: 700,
    },


    benefitsText: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636'
    },

    benefitsTextFirst: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        color: '#363636',
        lineHeight: '16px',
        marginTop: '5px',
    },



    benefitMain: {
        backgroundColor: '#fff',
        marginTop: '30px',
        height: 'auto',
        width: '240px',
        textAlign: 'right' as 'right',
        display: 'flex',
        borderRadius: '0 12px 12px 0',
        justifyContent: 'right',
    },

    benefitxpMain: {
        marginTop: '10px',
        padding: '10px 31px 40px 0px',
    },
    getbenefitFirstTitle: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '10px'
    },
    getbenefitSecondTitle: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '5px'
    },
    parentpremium: {
        paddingLeft: '20px'
    },

    getSecondtitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999'
    },

    textbenefitsTtile: {
        fontFamily: 'Quicksand',
        fontSize: '27px',
        fontWeight: 700,
        lineHeight: '29px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999'
    },



    mainContent: {
        flex: 1,
        padding: "16px",
        backgroundColor: "#A95EDB",
    },
    header: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
        backgroundColor: "#A95EDB",
        padding: "16px",
        backgroundImage: 'url({skip_wrench})',
    },
    headerText: {
        fontFamily: "Nunito",
        fontSize: "22px",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "0.008em",
        textAlign: "center" as "center",
        color: "#FFFFFF",
    },
    secTitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999',
        top: '193px',
        padding: '20px',
        backgroundColor: '#E6E6E6',
        boxSizing: 'border-box' as 'border-box',
    }

};

export default withTranslation()(PlanDetailsMethod)
// Customizable Area End
