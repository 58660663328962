import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Characters, CharatersInArray } from "./personas-avatar/types";
import { toPng } from "html-to-image";
import ViewShot from "react-native-view-shot";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  testID?: string;
  "data-test-id"?: string;
  onSave: (uri: string, character: string) => void;
  character: string | null;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  body: Characters["body"];
  eyes: Characters["eyes"];
  facialHair: Characters["facialHair"];
  hair: Characters["hair"];
  mouth: Characters["mouth"];
  nose: Characters["nose"];
  bgColor: Characters["backgroundColor"];
  skinColor: Characters["skinColor"];
  hairColor: Characters["hairColor"];
  facialHairColor: Characters["facialHairColor"];
  bodyColor: Characters["bodyColor"];
  open: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EditAvatarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  avatar: React.RefObject<{ randomize: () => void }>;
  avatarIcon: React.RefObject<ViewShot>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      body: "body2",
      eyes: "eyes1",
      facialHair: "facialHair1",
      hair: "hair3",
      mouth: "mouth5",
      nose: "nose2",
      bgColor: "bgc2",
      skinColor: "sc3",
      hairColor: "hc8",
      facialHairColor: "fhc1",
      bodyColor: "bc2",
      open: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (this.props.character) {
      this.randomCharacter(this.props.character);
    }
  }

  getCharacter = () => {
    return `${this.state.body}|${this.state.eyes}|${this.state.facialHair}|${this.state.hair}|${this.state.mouth}|${this.state.nose}|${this.state.bgColor}|${this.state.skinColor}|${this.state.hairColor}|${this.state.facialHairColor}|${this.state.bodyColor}`;
  };

  randomCharacter = (charactersStr: string) => {
    const characters = charactersStr.toString().split("|") as CharatersInArray;
    characters &&
      this.setState({
        body: characters[0],
        eyes: characters[1],
        facialHair: characters[2],
        hair: characters[3],
        mouth: characters[4],
        nose: characters[5],
        bgColor: characters[6],
        skinColor: characters[7],
        hairColor: characters[8],
        facialHairColor: characters[9],
        bodyColor: characters[10],
      });
  };

  setFacialAttribute = (attribute: string, value: string) => {
    this.setState({ ...this.state, [attribute]: value });
  };

  onCapture = async () => {
    if (this.isPlatformWeb()) {
      const avatar = document.getElementById("avatar");
      try {
        if (avatar) {
          const dataUrl = await toPng(avatar);
          this.props.onSave(dataUrl, this.getCharacter());
        }
      } catch (error) {
        this.showAlert(configJSON.somethingWentWrongTitle, JSON.stringify(error));
      }
    } else {
      if(this.avatarIcon.current?.capture) {
        const avatarUri = await this.avatarIcon.current.capture()
        this.props.onSave(avatarUri, this.getCharacter());
      }
    }
  };

  openOption = (value: string) => this.setState({ open: value });

  // Customizable Area End
}
