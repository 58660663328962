import React from "react"
// Customizable Area Start
import {
    Box,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListSubheader,
    styled
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import XPTrackerController, { Props , IXpTracker, IXpTrackerAttributes} from './XPTrackerController.web';
import BottomMenu from "../../../components/src/BottomMenu";
import { xpskipwrench, free, premimum, backgroundImage, loader } from "./assets";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { withTranslation } from "react-i18next";
// Customizable Area End
// Customizable Area Start
 class XPTracker extends XPTrackerController {
    // Customizable Area End
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start
     handleImage=(XpTrackerAttributes:IXpTrackerAttributes)=>{
         const XpTrackerPoint = XpTrackerAttributes.event_point_key
        const getXPNumper = this.handleXpPoints(XpTrackerPoint)
      return <div className="firstImg">X{getXPNumper}</div>
     }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
                <NavigateLayout menuName="Home">
            {this.state.loading ? 
                    <LoadingContainer className="loaderShown"><img src={loader} alt="Loading" /> </LoadingContainer>
                    :
                    <LanguageContainer>
                                    <Box className="mainTrackerWrapper">
                                        <TrackerBackground>
                                            <Box className="TrackerImgWrapper">
                                                <Box className="cardWrapper">
                                                    <div className="card_Box">
                                                        <div className="back"><ArrowBackIosIcon className="backIcon" data-testid="back_btn" onClick={this.handleGoBack} /></div>
                                                        <div className="xp-tracker">{this.props.t("XP Tracker")}</div>
                                                        <div className="skip"><img src={xpskipwrench} /></div>
                                                    </div>
                                                </Box>
                                            </Box>
                                        </TrackerBackground>
                                        <Box className="contentContainer">
                                            <div className="progressWrapper">
                                                <div className='main-bg-box'>
                                                    <div className="back-box">
                                                    </div>
                                                </div>
                                                <div style={{ position: 'absolute', zIndex: 1, top: '-22px' }}>
                                                    <img src={free} alt='flashIcon' />
                                                    <div className="icon-text">{this.props.t("Free")}</div>
                                                </div>
                                                <div style={{ margin: '-10px auto 10px auto', width: '100%', }}>
                                                    <div className='main-bg-box'>
                                                        <div className="double-line-text">{this.state.total_xp} XP</div>
                                                    </div>
                                                <LinearProgress variant="determinate" value={this.state.premiumUser ? 100 : this.getTotalXpProgressValue()} className="points-mobile" />
                                                </div>
                                                <div className="main-Premium">
                                                    <img src={premimum} alt='flashIcon' />
                                                <div className="icon-text">{this.props.t("Premium")}</div>
                                                </div>
                                            </div>
                                            <List
                                                component="nav"
                                                className="historyWrapper"
                                                subheader={
                                                    <ListSubheader disableSticky component="div" className="historyTitle">
                                                       {this.props.t("HISTORY")}
                                                    </ListSubheader>
                                                }
                                            >
                                                <div className="todayText">TODAY</div>
                                            {this.state.XpData.map((item:IXpTracker)=>{
                                                const xpItem = this.getXpTrackerData(item.attributes)
                                                return (
                                                    <div key={item.id} data-testid="Xpitems" className="fieldBox" style={{ background: xpItem.backgroundColor }}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                        {xpItem.image === null ? this.handleImage(item.attributes): <img height={32} width={32} src={xpItem.image as string} />}
                                                        </ListItemAvatar>
                                                        <div>
                                                            <div className="firstHader">{xpItem.xpPoints>0?`+${xpItem.xpPoints} XP`:`${xpItem.xpPoints} XP`}</div>
                                                            <div className="secondHader">{xpItem.description}</div>
                                                        </div>
                                                    </ListItem>
                                                </div>
                                                )
                                            })
                                            }
                                            </List>
                                        </Box>
                                        <BottomMenu />
                                    </Box>
                    </LanguageContainer>
                }
                </NavigateLayout>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
export default withTranslation()(XPTracker)

export const LoadingContainer=styled(Box)(({theme})=>({
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    display: 'flex', 
    background:"#36BFFF",
    width: "calc(100% - 240px)",
     marginLeft: "240px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
}))

export const TrackerBackground=styled(Box)(({theme})=>({
    backgroundColor: '#fff',
    flex:5,

    '& .TrackerImgWrapper': {
        backgroundColor: '#00AEFF',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderBottomRightRadius: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage:`url(${backgroundImage})`,
        "@media(max-width:960px)":{
            height: 'unset',
            paddingTop: '55px',
            backgroundImage:"unset"
        }
    },

    "& .cardWrapper": {
        width: "393px",
        height: "150px",
        backdropFilter: "blur(2px)",
        borderRadius: "20px",
        background: "#FFF",
        alignItems: 'end',
        margin: '10px',
        display: 'flex',
        "@media (max-width:960px)": {
            width: '100%',
            background:'none',
            margin:0,
        }
    },

    '& .card_Box': {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems:"center"
    },
    "& .back":{
        width:112,
        marginBottom:28,
    },

    "& .backIcon": {
        display:"none",
        "@media(max-width:960px)":{
            margin: "5px 0px 0px 20px",
            display: "flex",
            height: "16px",
            cursor: 'pointer',
            width: "16px",
            color: 'white',
        }
    },
    "& .xp-tracker": {
        textAlign: "center",
        color: "#666",
        fontStyle: "normal",
        fontFamily: "Nunito",
        lineHeight: "26px",
        marginBottom:28,
        letterSpacing: "0.176px",
        fontSize: "22px",
        fontWeight: 900,
        "@media (max-width:960px)": {
            color: "#FFF",
        },
        "@media(max-width:420px)":{
            marginLeft:18
          }
    },


}))

const LanguageContainer = styled(Box)(({ theme }) => ({
    width: '100%',
    backgroundColor: '#00AEFF',
    height: '100%',

    '& .mainTrackerWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow:'auto',
    '@media(min-width:959px)':{
        width: `calc(100% - 240px)`,
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '240px',
        }
    },

    '& .contentContainer': {
        flex: '5',
        overflow: 'auto',
        flexDirection: 'column',
        padding: '0px 24px 100px',
        backgroundColor: '#fff',
        display: 'flex',
        paddingTop: '50px',
        alignItems: 'center',
        
        "@media(max-width:960px)":{ 
            paddingTop:0,
            marginBottom:62
        }
    },
    
    '& .historyWrapper': {
        borderRadius: '12px',
        paddingBottom: '14px',
        width: '100%',
        border: '2px solid #e6e6e6',
        maxWidth: 600,
        '@media(min-width:960px)':{
            maxWidth:450,
            paddingBottom:0,
        }
    },

    '& .historyTitle': {
        color: '#999999',
        borderTopLeftRadius: '8px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        fontSize: '12px',
        borderTopRightRadius: '8px',
    },
    "& .changeLanguageTitle": {
        fontSize: '12px',
        backgroundColor: '#e6e6e6',
        fontFamily: 'Quicksand',
        color: '#999999',
        fontWeight: '700',
    },

    '& .firstHader': {
        color: "#363636",
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "13px"
    },
    '& .secondHader': {
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: "#666",
        fontWeight: 600,
        lineHeight: "13px",
        fontStyle: "normal",
        fontFamily: "Quicksand",
        fontSize: "14px",
    },
    '& .fieldBox': {
        margin: '10px 25px 10px 18px',
        background: "#C9EEFF",
        flexShrink: 0,
        borderRadius: "10px",
    },
    '& .fieldBox div, .fieldBox li ': {
        paddingBottom: '4px',
        paddingTop: '4px',
    },
    '& .communication-icon': {
        margin: '0px 6px'
    },
    
    '& .MuiListItemAvatar-root': {
        minWidth: '35px'
    },
    "& .points-mobile": {
        backgroundColor: "rgb(236, 236, 236)",
        width: '95%',
        height: "12px",
        borderRadius: "5px",
        margin: '0 10px',
        border: '3px solid rgb(236, 236, 236)',
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#62CDFF",
            borderRadius: "5px",
        }
    },
    "& .card-2": {
        borderRadius: "15px",
        height: "64px",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-around"
    },
    "& .back-box": {
        borderRadius: "14px",
        background: "rgb(236, 236, 236)",
        width: "98px",
        height: "45px",
        position: 'absolute',
        top: '-28px'
    },
    "& .double-line-text": {
        position: 'absolute',
        zIndex: 2,
        textShadow: "#666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px,\n #666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px",
        color: "var(--basic-white, #FFF)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "8px",
        letterSpacing: "3px"
    },
    "& .main-bg-box": {
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center"
    },
    '& .firstImg': {
        textShadow: "#00AEFF 0px 0px 2px,#00AEFF 0px 0px 2px,#00AEFF 0px 0px 2px,\n #00AEFF 0px 0px 2px,#00AEFF 0px 0px 2px,#00AEFF 0px 0px 2px",
        color: "var(--secondary-light-green, #B7F0AD)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "2.36px",
        marginLeft: '-8px'
    },
    "& .main-Premium": {
        position: 'absolute',
        zIndex: 1,
        right: '0px',
        top: '-16px',
        textAlign: 'end',
        paddingRight: '3px'
    },
    '& .progressWrapper': {
        width: '100%',
        display: 'flex',
        position: 'relative',
        maxWidth: '600px',
        margin: '50px 0'
    },
    "& .icon-text": {
        color: "var(--primary-blue-dark-gray, #363636)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "8px",
        margin: '5px auto'
    },
    '& .todayText': {
        color: "var(--primary-neutrals-warm-gray-400, #999)",
        textAlign: "right",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px",
        margin: '10px 25px 0px 18px',
        '@media(min-width:960px)':{
            margin: '22px 25px 23px 18px'
        }
    },
    [theme.breakpoints.up('md')]: {
  
        '& .fieldBox': {
            margin: '22px 25px 23px 18px',
        },
    },

    [theme.breakpoints.up('lg')]: {
        '& .mainTrackerWrapper': {
            marginLeft: '282px',
            width: `calc(100% - 282px)`,
        },

        '& .historyWrapper': {
            maxWidth: 600,
        },
    }
}))
// Customizable Area End