import React from "react";
import {
    // Customizable Area Start
    Box, Container,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
// Customizable Area End

export default function Loader() {
    return (
        <PandaLoaderStyle>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around", alignItems: "center" }}>
                        <div className='tedix-icon'><img src={require("./tedixIcon.svg")} alt="tedix-icon" /></div>
                        <div className='pandaIcon'><img src={require("./pandaIcon.svg")} alt="pandaIcon" /></div>
                        <div><img src={require("./assets/Loader.svg")}/></div>
                    </div>
                </Box>
            </Container>
        </PandaLoaderStyle>
    );
}

// Customizable Area Start

const PandaLoaderStyle = styled('div')({
    backgroundColor: '#62CDFF',
    height: '100%',
    "& .tedix": {
        padding: '50px 0px 30px 50px',
        "@media (max-width:600px)": {
            padding: '35px 0px 45px 0px',
            display: 'flex',
            justifyContent: 'center'
        }
    },
})

  // Customizable Area End