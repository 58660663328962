// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'

export const configJSON = require('./config')

interface SubCategory {
  id: string
  name: string
}

interface Lesson {
  id: string
  name: string
}

interface S {
  token: string
  selectedSubject: number
  selectedLesson: Lesson | null
  listSubCategories: Array<SubCategory>
  selectedSubCategory: SubCategory | null
  listLessons: Array<Lesson>
  isLoading: boolean
}

interface SS {
  id: any
}

export interface Props {
  navigation: any
  id: string
  t?:any
}

export default class ExploreLessonController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSubCategoriesByGradeId: string = ''
  getLessonsBySubCategoryId: string = ''

  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      token: '',
      selectedSubject: 0,
      selectedLesson: null,
      listSubCategories: [],
      selectedSubCategory: null,
      listLessons: [],
      isLoading: false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const gradeId = this.props.navigation.getParam('gradeId')
    this.getSubCategoriesByGrade(gradeId)
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const httpStatus = message.getData(
      getName(MessageEnum.RestAPIResponceHttpStatus)
    )

    this.apiSuccessCallBackController(apiRequestCallId, responseJson, httpStatus)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any,
    httpStatus?: number
  ) => {
    const successCallbackMap = {
      [this.getSubCategoriesByGradeId]: this.handleGetSubCategoriesByGradeResponse,
      [this.getLessonsBySubCategoryId]: this.handleGetLessonsBySubCategorResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any, httpStatus?: number) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON, httpStatus)
    }
  }

  handleGetLessonsBySubCategorResponse = (responseJson: any) => {
    if (responseJson?.data) {
      const lessons = responseJson?.data?.map((item: any) => ({
        id: item?.id,
        name: item?.attributes?.name
      }))
      
      this.setState({ listLessons: lessons, isLoading: false })
    } else {
      this.setState({ listLessons: [], isLoading: false })
    }
  }

  handleGetSubCategoriesByGradeResponse = (responseJson: any) => {
    const subCategories = responseJson?.data?.map((item: any) => ({
      id: item?.id,
      name: item?.attributes?.name
    }))

    this.setState({
      listSubCategories: subCategories,
      selectedSubCategory: subCategories[0]
    })

    if (subCategories?.length > 0) {
      this.getLessonsBySubCategory(subCategories[0].id)
    }
  }

  getSubCategoriesByGrade = (gradeId: string) => {
    this.getSubCategoriesByGradeId = sendAPIRequest(
      `bx_block_categories/sub_categories/by_category?category_id=${gradeId}`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  getLessonsBySubCategory = (subCategoryId: string) => {
    this.setState({isLoading: true})
    this.getLessonsBySubCategoryId = sendAPIRequest(
      `bx_block_profile/lessons/by_sub_category?sub_category_id=${subCategoryId}`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}
// Customizable Area End
