import React, { useState } from 'react'
import { Box, Button, Modal, Typography, styled } from '@material-ui/core'
import { checkBox, checkedBox, solutionHintSkipIcon } from '../../../blocks/cftableforlessons/src/assets'
import  {useTranslation} from "react-i18next"
interface ActionAlertProps {
  open: boolean
  onClose: () => void
  title: string
  description: string
  actionText: string
  onClickAction: () => void
  actionType: 'SOLUTION' | 'HINT' | 'SKIP'
  isHint?: boolean
}

const ActionAlert = ({
  open,
  onClose,
  title,
  description,
  actionText,
  onClickAction,
  actionType,
  isHint = false
}: ActionAlertProps) => {
  const [checked, setChecked] = useState(false)
  const  { t } = useTranslation()

  const handleCheckShowAgain = () => {
    setChecked(!checked)
  }

  const skipActionsLocalStorage = {
    SOLUTION: 'isSkipShowSolutionAlertMicroLesson',
    HINT: 'isSkipShowHintAlertMicroLesson',
    SKIP: 'isSkipShowSkipAlertMicroLesson',
  }

  const handleClickAction = () => {
    onClickAction()
    if (checked) {
      localStorage.setItem(skipActionsLocalStorage[actionType], 'true')
    }
  }

  return (
    <ModalStyled open={open} onClose={onClose}>
      <Box className="container">
        <img src={solutionHintSkipIcon} width={32} height={43} />
        <Typography className="title">{title}</Typography>
        <Typography className="description">{description}</Typography>
        <Box className="showAgainContainer">
          <img
            data-testId="showAgainBtn"
            src={checked ? checkedBox : checkBox}
            width={18}
            height={18}
            onClick={handleCheckShowAgain}
            style={{ cursor: 'pointer' }}
          />
          <Typography className="showAgainText">{t("Don’t show again")}</Typography>
        </Box>
        <Box className="buttonWrapper">
          <ActionButton data-testId="actionBtn" style={isHint ? {width:'110px'} : {}} onClick={handleClickAction}>{actionText}</ActionButton>
          <CancelButton data-testId="cancelBtn" style={isHint ? {width:'110px'} : {}} onClick={onClose}>{t("Cancel")}</CancelButton>
        </Box>
      </Box>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 296,
    backgroundColor: '#fff',
    padding: 26,
    borderRadius: 12,
    border: '2px solid #e6e6e6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .title': {
    fontWeight: '900',
    fontFamily: 'Nunito',
    fontSize: '18px',
    color: '#363636',
    marginTop: '22px',
  },

  '& .description': {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: '500',
    color: '#666666',
    textAlign: 'center',
    marginTop: '10px',
  },

  '& .showAgainContainer': {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
    marginBottom: '28px',
  },

  '& .showAgainText': {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    color: '#999999',
    fontWeight: '500',
    marginLeft: '14px',
  },

  '& .buttonWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
  },
}))

const CancelButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#36BFFF',
  boxShadow: '0px 4px #00AEFF',
  borderRadius: '12px',
  textTransform: 'none',
  width: '89px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#36BFFF',
    boxShadow: '0px 4px #00AEFF',
  },
}))

const ActionButton = styled(Button)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '42px',
  color: '#cccccc',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#fff',
  boxShadow: '0px 2px #cccccc',
  borderRadius: '12px',
  border: '2px solid #cccccc',
  textTransform: 'none',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
}))

export default ActionAlert
