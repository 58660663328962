import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { IList } from "./GoalController.web";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
    t?:any;
    streakGoalList:Array<IList>
    dailyGoalList:Array<IList>
    streakGoal:string
    goBack:()=>void
    dailyGoal:string
    handleUpdateUser:(value:{dailyGoal:string,streakGoal:string})=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  streakGoal:IList
  dailyGoal:IList
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class GoalDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
          streakGoal:{
            data:0,
            option:"",
            optionData:""
          },
          dailyGoal:{
            data:0,
            option:"",
            optionData:""
          }
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    componentDidMount=async()=> {
      this.setState({
        streakGoal:this.getStreakGoal(this.props.streakGoal),
        dailyGoal:this.getDailyGoal(this.props.dailyGoal)
      })
    }

     componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
       if(prevProps.streakGoal!==this.props.streakGoal){
            this.setState({
              streakGoal:this.getStreakGoal(this.props.streakGoal)
            })
       }
       if(prevProps.dailyGoal!==this.props.dailyGoal){
        this.setState({
          dailyGoal:this.getDailyGoal(this.props.dailyGoal)
        })
   }
     }

     getDisabled=()=>{
      if(this.props.streakGoal===this.state.streakGoal.optionData&&
        this.props.dailyGoal===this.state.dailyGoal.optionData){
          return true
        }else{
          return false
        }
     }


     handleUpdateGoals=()=>{
       this.props.handleUpdateUser({
        dailyGoal:this.state.dailyGoal.optionData,
        streakGoal:this.state.streakGoal.optionData
       })
     }


     handleUpdate=(value:IList, key:string)=>{
       this.setState((prevState)=>({
            ...prevState,
            [key]:value
       }))
     }

     getDailyGoal=(dailyGoal:string)=>{
      const data = this.props.dailyGoalList.find((item:IList)=>item.optionData=== dailyGoal)
      return data ?? {data:0,option:"",optionData:""}
    }

    getDailyGoalValue=(item:IList)=>{
      const optionValue =  `${this.props.t(item.option)} (${item.data} ${item.data===1?this.props.t("lesson"):this.props.t("lessons")})`
       return {...item,option:optionValue}
    }

    getStreakGoalValue=(item:IList)=>{
       const optionValue =  `${this.props.t(item.option)} (${item.data} ${this.props.t("days")})`
       return {...item,option:optionValue}
    }

     getStreakGoal=(streakGoal:string)=>{

      const data = this.props.streakGoalList.find((item:IList)=>item.optionData=== streakGoal)
      return data ?? {data:0,option:"",optionData:""}
    }
    // Customizable Area End

}    

// Customizable Area Start
// Customizable Area End