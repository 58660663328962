import React from 'react'
import { Box, Modal, Typography, styled } from '@material-ui/core'
import { bolt, buttonClose, thankReportBg, reportBlue, reportBrown, reportPurple, reportMint, reportGreen, reportOrange, reportRed, reportYellow } from './assets'
import  {useTranslation} from "react-i18next"

interface ThankReportModalProps {
  open: any
  onClose: () => void
}

const ThankReportModal = ({ onClose, open }: ThankReportModalProps) => {
  const  { t } = useTranslation()
  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': reportOrange,
    '#62cdff': reportBlue,
    '#b7f0ad': reportMint,
    '#a95edb': reportPurple,
    '#f96855': reportRed,
    '#884a39': reportBrown,
    '#fdce36': reportYellow,
    '#2bce8c': reportGreen
  };

  const updatedTeddy = colorToUpdateTeddy[avatarColor] || reportBlue;
  return (
    <ModalStyled open={open} onClose={onClose}>
      <Box className="container">
        <div className="closeBtn" onClick={onClose}>
          <img src={buttonClose} width={10} height={10} data-testid="btnClose"/>
        </div>
        <img src={updatedTeddy} className="thankTeddyImg" />
        <Typography className="title">{t("Thanks for reporting!")}</Typography>
        <Box className="descriptionGroup">
          <Typography className="description">{t("You've earned")}</Typography>
          <img src={bolt} className="boltImg" />
          <Typography className="descriptionBold">{t("10 XP")}.</Typography>
        </Box>
        <Typography className="description">
          {t("Your report helps us make the app even better. We're on it!")}
        </Typography>
      </Box>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 296,
    backgroundColor: '#e3cff4',
    backgroundImage: `url(${thankReportBg})`,
    padding: 26,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .closeBtn': {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },

  '& .title': {
    fontWeight: '900',
    fontFamily: 'Nunito',
    fontSize: '18px',
    color: '#363636',
  },

  '& .descriptionGroup': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },

  '& .boltImg': {
    width: '10px',
    height: '13px',
    marginLeft: '4px',
    marginRight: '4px',
  },

  '& .description': {
    fontFamily: 'Quicksand',
    fontWeight: '500',
    fontSize: '15px',
    color: '#666666',
    textAlign: 'center',
  },

  '& .descriptionBold': {
    fontFamily: 'Quicksand',
    fontWeight: '700',
    color: '#666666',
    fontSize: '15px',
  },

  '& .thankTeddyImg': {
    width: '96px',
    height: '100px',
    marginRight: '20px',
    marginBottom: '13px',
  },
}))

export default ThankReportModal
