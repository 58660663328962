// Customizable Area Start
import React from "react";
import { Path } from "react-native-svg";
import { Characters } from "../types";

export default function Eyes({ value }: { value: Characters["eyes"] }) {
  switch (value) {
    case "eyes1":
      return <Eyes1 />;
    case "eyes2":
      return <Eyes2 />;
    case "eyes3":
      return <Eyes3 />;
    case "eyes4":
      return <Eyes4 />;
    case "eyes5":
      return <Eyes5 />;
    case "eyes6":
      return <Eyes6 />;
    default:
      return <Eyes1 />;
  }
}

function Eyes1() {
  return (
    <>
      <Path
        fill="#1b0640"
        d="M26 30a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm12 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
      />
      <Path fill="#fff" d="M23 26h6v6h-6zm12 0h6v6h-6z" opacity={0.303} />
      <Path
        fill="#1b0640"
        d="M31 25.053h2c1.5 0 2.5-1.053 5-1.053 1.667 0 3.333.35 5 1.053l1 .526v1.579l-1 .526v3.158C43 32.586 41.657 34 40 34h-4c-1.657 0-3-1.414-3-3.158v-3.684h-2v3.684C31 32.586 29.657 34 28 34h-4c-1.657 0-3-1.414-3-3.158v-3.158l-1-.526v-1.58l1-.525C22.667 24.35 24.333 24 26 24c2.5 0 3.5 1.053 5 1.053zm-2.757 1.47c-.844-.296-1.425-.418-2.243-.418-.995 0-1.993.15-3 .45v4.287c0 .581.448 1.053 1 1.053h4c.552 0 1-.472 1-1.053v-4.051c-.209-.07-.447-.155-.757-.267zm7.514 0c-.31.113-.548.198-.757.268v4.051c0 .581.448 1.053 1 1.053h4c.552 0 1-.472 1-1.053v-4.287c-1.007-.3-2.005-.45-3-.45-.818 0-1.399.122-2.243.419z"
      />
    </>
  );
}

function Eyes2() {
  return (
    <Path
      fill="#000"
      d="M24.712 29.737a.75.75 0 11-1.424-.474c.434-1.301 1.383-2.013 2.712-2.013s2.278.712 2.712 2.013a.75.75 0 11-1.424.474c-.233-.699-.617-.987-1.288-.987s-1.055.288-1.288.987zm12 0a.75.75 0 01-1.424-.474c.434-1.301 1.383-2.013 2.712-2.013s2.278.712 2.712 2.013a.75.75 0 01-1.424.474c-.233-.699-.617-.987-1.288-.987s-1.055.288-1.288.987z"
    />
  );
}

function Eyes3() {
  return (
    <Path
      fill="#000"
      d="M25.5 30a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm13 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
    />
  );
}

function Eyes4() {
  return (
    <Path
      d="M36.004 29.368a1 1 0 11.992-1.736c.438.25.852.368 1.254.368s.816-.118 1.254-.368a1 1 0 11.992 1.736c-.73.417-1.482.632-2.246.632s-1.517-.215-2.246-.632zm-12 0a1 1 0 01.992-1.736c.438.25.852.368 1.254.368s.816-.118 1.254-.368a1 1 0 01.992 1.736c-.73.417-1.482.632-2.246.632s-1.517-.215-2.246-.632z"
      fill="#000"
    />
  );
}

function Eyes5() {
  return (
    <>
      <Path
        d="M26 30a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm12 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
        fill="#000"
      />
      <Path d="M23 26h6v6h-6zm12 0h6v6h-6z" opacity={0.801} fill="#000" />
      <Path
        d="M33 25.053c1.5 0 2.5-1.053 5-1.053 1.667 0 3.333.35 5 1.053l1 .526v1.579l-1 .526v3.158C43 32.586 41.657 34 40 34h-4c-1.657 0-3-1.414-3-3.158v-3.684h-2v3.684C31 32.586 29.657 34 28 34h-4c-1.657 0-3-1.414-3-3.158v-3.158l-1-.526v-1.58l1-.525C22.667 24.35 24.333 24 26 24c2.5 0 3.5 1.053 5 1.053zm-4.757 1.47c-.844-.296-1.425-.418-2.243-.418-.995 0-1.993.15-3 .45v4.287c0 .581.448 1.053 1 1.053h4c.552 0 1-.472 1-1.053v-4.051c-.209-.07-.447-.155-.757-.267zm7.514 0c-.31.113-.548.198-.757.268v4.051c0 .581.448 1.053 1 1.053h4c.552 0 1-.472 1-1.053v-4.287c-1.007-.3-2.005-.45-3-.45-.818 0-1.399.122-2.243.419z"
        fill="#000"
      />
    </>
  );
}

function Eyes6() {
  return (
    <Path
      d="M25.5 30a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm14.907-2.744a.75.75 0 01.186 1.488l-4 .5a.75.75 0 01-.186-1.488z"
      fill="#000"
    />
  );
}
// Customizable Area End
