// Customizable Area Start
import React from "react"
import {
    Box,
    Drawer,
    IconButton,
    LinearProgress,
    styled
} from "@material-ui/core";
import { medal, groupconfetti, buttonDown, goalMedal, calender, lessonGoal, EditPencil } from "./assets";
import  { Calendar } from "react-multi-date-picker"
import { withTranslation } from "react-i18next";
import GoalController, { IList } from "./GoalController.web";
import GoalDetailsWeb from "./GoalDetails.web";
// Customizable Area End
// Customizable Area Start
class Goal extends GoalController {
    // Customizable Area End
    // Customizable Area Start
    getLine = (list: Array<IList>, goal: string) => {
        let elements = [];
        const temp = this.getGoalOptiondata(list, goal)
        for (let item = 1; item < temp; item++) {
            const number = item
            elements.push(<div key={item} data-testid={goal} className="double-line-text">{number}</div>)
        }

        return elements
    }
    // Customizable Area End
    render() {
        
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <ResponsiveDrawer
                    id="goalId"
                    open={this.props.stateOpen}
                    anchor={this.props.drawerOpen ? 'right' : 'bottom'}
                    onClose={this.props.closeDrawer}

                >
                    <DrawerStyle>
                        {!this.props.drawerOpen && <img src={buttonDown} />}
                        {this.state.toggleGoalDetails ?
                            <GoalDetailsWeb
                                data-testid="GoalDetails"
                                dailyGoalList={this.state.dailyGoalList}
                                streakGoalList={this.state.streakGoalList}
                                streakGoal={this.props.streakGoal ?? ''}
                                dailyGoal={this.props.dailyGoal ?? ''}
                                goBack={this.handelGoBack}
                                handleUpdateUser={this.handleUpdateUser}
                            />
                            :
                            <>
                                <div>
                                    <IconButton className="Edit_Button" data-testid="Edit_Button" onClick={this.handleGoalDetails}>
                                        <img src={EditPencil} alt="edit" />
                                    </IconButton>
                                </div>
                                <div className='goalBox'>
                                    <img src={goalMedal} />
                                    <div className="goalNumber">{this.state.medal}</div>
                                </div>
                                <div className="firstWrapper">
                                    <div className="dailyWrapper">
                                        <div className="strick">{this.props.t('Daily Goal')}</div>
                                        <div className="Dedicated">{`${this.getheLessonCountOfDailyGoal(this.props.dailyGoal ?? '')} ${this.props.t('lessons')}`}</div>
                                    </div>
                                    <div className='linerWrapper1'>
                                        <div className='main-bg-box'>
                                            {this.getLine(this.state.dailyGoalList, this.props.dailyGoal ?? "")}
                                        </div><LinearProgress variant="determinate" value={this.getDailyGoalProgress()} className="points-mobile" />
                                        <div className="lessonGoal"><img src={lessonGoal} alt='lessonGoal' /></div>
                                    </div>
                                </div>
                                <div className="secondWrapper">
                                    <div className="streakWrapper">
                                        <div className="strick">{this.props.t('Streak Goal')}</div>
                                        <div className="Dedicated">{this.props.t(this.getStreakGoal(this.props.streakGoal ?? '')??'')}</div>
                                    </div>
                                    <div className='linerWrapper2'>
                                        <div className='main-bg-box'>
                                            {this.getLine(this.state.streakGoalList, this.props.streakGoal ?? "")}
                                        </div>
                                        <LinearProgress variant="determinate" value={this.getStreakGoalProgress()} className="points-mobile" />
                                        <div className="calender"><img src={calender} alt='calender' /></div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Calendar
                                            value={this.convertDateStringtoDate(this.state.streakGoalDates)}
                                            multiple
                                            sort
                                            disabled
                                            weekStartDayIndex={1}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </DrawerStyle>
                </ResponsiveDrawer>
                {/* </StyleDiv> */}
            </Box>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(Goal)
const DrawerStyle = styled('div')(({ theme }) => ({
    padding: '10px',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${groupconfetti})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    height: 'auto',
    "& .strick": {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "13px"
    },

    "& .Edit_Button": {
        width: "33px",
        position: "absolute",
        zIndex: 1,
        height: "33px",
        padding: "0px",
        right: "20px",
        borderRadius: "10px",
        boxShadow: "0px 2px 1px 1px #CCCCCC",
        background: "var(--Primary-Neutrals-Warm-gray-10, #F7F7F7)",
    },

    "& .Dedicated": {
        color: "var(--primary-neutrals-warm-gray-300, #B3B3B3)",
        textAlign: "right",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "13px"
    },
    "& .points-mobile": {
        backgroundColor: "#FFFFFF",
        width: '91%',
        height: "12px",
        borderRadius: "5px",
        border: '3px solid #FFFFFF',
        "& .MuiLinearProgress-bar": {
            backgroundColor: "#62CDFF",
            borderRadius: "5px",
        }
    },
    "& .rmdp-day": {
        height: '36px',
        width: '35px'
    },
    "& .rmdp-day span": {
        borderRadius: "0%",
        bottom: "0px",
        fontSize: "12px",
        left: "2px",
        position: "absolute",
        right: "0px",
        top: "0px"
    },
    '& .rmdp-day.rmdp-selected span:not(.highlight)': {
        boxShadow: 'none',
        backgroundImage: `url(${medal})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "1px 4px",
        backgroundColor: "#ffffff",
        textShadow: "#666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px,\n #666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "12px",
        fontStyle: "normal",
        color: '#fff',
        fontWeight: 400,
        lineHeight: "10.5px",
        letterSpacing: "-0.16px",
    },
    '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
        color: "black",
        textShadow: "#666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px,\n #666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundColor: "#ffffff"
    },
    "& .rmdp-day.rmdp-today span": {
        backgroundColor: '#ffffff',
        color: 'black'
    },
    "& .rmdp-ym .rmdp-day span": {
        color: 'black !important',
        textShadow: 'none !important',
        backgroundImage: 'none !important'
    },

    "& .rmdp-week-day": {
        color: "rgba(60, 60, 67, 0.60)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "8px"
    },
    "& .rmdp-arrow-container": {
        display: 'none'
    },
    "& .rmdp-header-values": {
        margin: '5px 0'
    },
    "& .rmdp-header-values span": {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px",
        letterSpacing: "0.19px"
    },
    '& .rmdp-shadow': {
        boxShadow: 'none',
        borderRadius: '19.5px',
        background: '#FFF'
    },
    "& .firstWrapper": {
        width: '100%',
        maxWidth: "329px",
        height: "80px",
        borderRadius: "10px",
        background: "var(--primary-neutrals-warm-gray-50, #F2F2F2)",
        padding: '15px'
    },
    '& .secondWrapper': {
        boxShadow: 'none',
        width: '100%',
        maxWidth: "329px",
        flexShrink: 0,
        borderRadius: "10px",
        background: "var(--primary-neutrals-warm-gray-50, #F2F2F2)",
        padding: '15px',
        margin: '15px auto'

    },
    '& .goalBox': {
        width: "134px",
        height: "64px",
        padding: 20,
        borderRadius: "10px",
        opacity: 0.9,
        background: "var(--primary-blue-blue-primary-light, #C9EEFF)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px auto'
    },
    '& .goalNumber': {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Nunito",
        fontSize: "45px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "21px",
        margin: '0 0 0 10px'
    },
    "& .calender, .lessonGoal": {
        position: 'absolute',
        zIndex: 1,
        right: '0px',
        top: '-8px',
        textAlign: 'end',
        paddingRight: '3px'
    },
    "& .main-bg-box": {
        width: "95%",
        position: "absolute",
        display: "flex",
        justifyContent: "space-evenly",
    },
    "& .double-line-text": {
        zIndex: 2,
        textShadow: "#666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px,\n #666 0px 0px 1px,#666 0px 0px 1px,#666 0px 0px 1px",
        color: "var(--basic-white, #FFF)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "8px",
        letterSpacing: "3px"
    },
    '& .linerWrapper1, .linerWrapper2': {
        margin: '22px auto',
        width: '100%',
        position: 'relative'
    },
    '& .dailyWrapper': {
        display: "flex",
        justifyContent: "space-between"
    },
    '& .streakWrapper': {
        display: "flex",
        justifyContent: "space-between"
    },

    [theme.breakpoints.up('md')]: {
        height: '100vh',
    }

}))
const ResponsiveDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paperAnchorBottom': {
        borderRadius: '20px 20px 0 0',
    },
    '& .MuiDrawer-paperAnchorBottom,.MuiDrawer-paperAnchorRight': {
        width: '100%',
        display: 'flex',
        position: 'fixed',
        maxWidth: 'auto',
        bottom: 0,
        right: 0,
        left: 'auto',
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDrawer-paperAnchorBottom,.MuiDrawer-paperAnchorRight': {
            maxWidth: '450px',
        }
    },

}));
// Customizable Area End