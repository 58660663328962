// Customizable Area Start
export const facialHairs = {
  facialHair1: true,
  facialHair2: true,
  facialHair3: true,
  facialHair4: true,
  facialHair5: true,
  facialHair6: true,
};

export const facialHairColors = {
  fhc1: "#362d46",
  fhc2: "#665e97",
  fhc3: "#5ac4d4",
  fhc4: "#dee2f5",
  fhc5: "#6b4445",
  fhc6: "#f29c64",
  fhc7: "#e16381",
  fhc8: "#e15b65",
};
// Customizable Area End
