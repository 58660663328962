import { Box, IconButton, MenuItem, MenuList, Popover, styled,Typography } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowLeft } from '@material-ui/icons'
import React, { useState, useEffect } from 'react'
//@ts-ignore
import Cookies from "js-cookie"
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
//@ts-ignore
import { baseURL } from "../../framework/src/config.js"
import { imgEnglishFlag, imgGermanFlag, imgHungarianFlag, imgLogoTedix, imgRomanianFlag } from './assets'

interface HeaderProps {
  showGoBackBtn?: boolean
  onGoBack?: () => void
  onLogoClick?: () => void
  showLanguageOption?: boolean
}

interface LanguageType {
  id: string
  type: string
  attributes: {
    id: number
    name: 'English' | 'German' | 'Hungarian' | 'Romanian'
    language_code: string
    is_content_language: string
    is_app_language: string
    emoji_flag: string
    created_at: string
    updated_at: string
  }
}



const Header = ({ showGoBackBtn, onGoBack, onLogoClick, showLanguageOption }: HeaderProps) => {

  const JsonData = Cookies.get('webAppLanguage') || "{}"
  const LanguageCookie = JSON.parse(JsonData)
  const language = LanguageCookie?.name || 'Romanian'

  const { t } = useTranslation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(language)  
  const [languageList,setLanguageList] = useState<LanguageType[]>([])

  const languageOptions:any = {
    English: imgEnglishFlag,
    German: imgGermanFlag,
    Hungarian: imgHungarianFlag,
    Romanian: imgRomanianFlag
  }

  useEffect(()=>{
    getAppLanguageList()
  },[])


  const handleClickLanguage = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseLanguage = () => {
    setAnchorEl(null)
  }

 

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleLogoClick = () => {
    onLogoClick && onLogoClick()
   }

   const getAppLanguageList = async() => {
    const responseJson:any = await fetch(`${baseURL}/bx_block_language_options/languages`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    const response: any = await responseJson.json()
    if(response.data){
       if(Cookies.get('webAppLanguage')===undefined){
         const RomanianObject =  response.data.find((item:LanguageType)=>item.attributes.name=='Romanian')
         Cookies.set("webAppLanguage", JSON.stringify({name:RomanianObject.attributes.name, id:RomanianObject.id }))
       }
    setLanguageList(response?.data)
    }
}

const setLanguageData=(language:LanguageType)=>{
  setSelectedLanguage(language.attributes.name)
    Cookies.set("webAppLanguage", JSON.stringify({name:language.attributes.name, id:language.id }))
  i18next.changeLanguage(language.attributes.name as string)
  setAnchorEl(null)
}


  return (
    <HeaderContainer>
      {showGoBackBtn ? (
        <IconButton className="goBackBtn" onClick={onGoBack}>
          <KeyboardArrowLeft style={{ color: 'black' }} />
        </IconButton>
      ) : (<div className="fakeLeftElement" />)}
      <Box className="imageWrapper">
        <img
          alt="logo"
          src={imgLogoTedix}
          width={100}
          height="auto"
          onClick={handleLogoClick}
        />
      </Box>
      {showLanguageOption ? (
        <IconButton className='selectLanguage' onClick={handleClickLanguage}>
          <img src={languageOptions[selectedLanguage]}className='flagImg' />
          <KeyboardArrowDown className='arrowDownIcon'  />
        </IconButton>
      ) : (<div className="fakeRightElement" />)}

      <Popover
        id={id}
        open={open}
        onClose={handleCloseLanguage}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList>
        {
        languageList.length >0 ? languageList.map((item:LanguageType)=>{
           return(
            <MenuItem value={item.attributes.name} onClick={()=>setLanguageData(item)}key={item.id}style={{}}>
            <img
              src={languageOptions[item.attributes.name]}
              style={{ marginRight: '12px' }}
              alt="english"
              className="flagItem"
            />
            <ItemText>{item.attributes.name}</ItemText>
          </MenuItem>
           )
        }):
        <Typography>{t("No Result found")}</Typography>
        
    
      }
        </MenuList>
      </Popover>
    </HeaderContainer>
  )
}

const ItemText = styled('p')({
  color: '#363636',
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
})

const HeaderContainer = styled(Box)(({ theme }) => ({
  marginTop: '36px',
  display: 'flex',
  alignItems: 'center',

  '& .goBackBtn': {
    marginLeft: '20px',
   },

  '& .imageWrapper': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },

  '& .fakeLeftElement': {
    width: '48px',
    height: '48px',
    marginLeft: '20px',
  },

  '& .fakeRightElement': {
    width: '48px',
    height: '48px',
    marginRight: '20px',
  },

  '& .selectLanguage': {
    marginRight: '20px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  },

  '& .flagImg': {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
  },

  '& .arrowDownIcon': {
    color: 'black',
    width: '16px',
    height: '16px',
    marginLeft: '4px'
  },

  [theme.breakpoints.up('md')]: {
    marginTop: '68px',
    '& .goBackBtn': {
      display: 'none',
    },
    '& .imageWrapper': {
      marginLeft: '100px',
      justifyContent: 'flex-start',
    },

    '& .selectLanguage': {
      marginRight: '100px'
    },
  },
}))

export default Header
