// Customizable Area Start
export const bodies = {
  body1: true,
  body2: true,
  body3: true,
  body4: true,
};

export const bodyColors = {
  bc1: "#456dff",
  bc2: "#5a45ff",
  bc3: "#6dbb57",
  bc4: "#f55c80",
  bc5: "#7554ca",
  bc6: "#e24552",
  bc7: "#54d8c7",
  bc8: "#f3b63b",
};
// Customizable Area End
