import React from "react";
import {
    // Customizable Area Start
    Box, Typography, Button, Container, Grid,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import NotificationControllerWeb, {
    Props,
} from "./NotificationController.web";
import { notificationIcon, pandaSkip, arrowUp, rectengleIcon, smallNotification } from './assets';
import SelectLanguage from "../../../components/src/SelectLanguage";
import { withTranslation } from "react-i18next";
import Header from "../../../components/src/Header";

// Customizable Area End

 class NotificationWeb extends NotificationControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <NotificationStyle>
                    <Header />
                    <Container maxWidth="xs">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                            }}
                        >
                            <div>
                                <Grid container spacing={0} style={{ alignItems: 'center' }}>
                                    <Grid xs >
                                        <div className="dailyAlerts">{this.props.t("Enable daily alerts to turn your goal into a habit")}</div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="box">
                                    <div className="mobileView">
                                        <Typography className="tedix-notification">“Tedix” {this.props.t("would like to send you notifications")}</Typography>
                                        <div style={{ display:'flex', justifyContent: 'space-between', marginTop: '18px' }}>
                                            <Button onClick={() => this.props.navigation.navigate('EmailRegistrationSuccess')} variant="contained" className="DonotAllow">{this.props.t("Don't allow")}</Button>
                                            <Button onClick={() => this.props.navigation.navigate('EmailRegistrationSuccess')} variant="contained" className="allow">{this.props.t("Allow")}</Button>
                                        </div>
                                    </div>
                                     <div className="desktopView">
                                        <Typography className="tedix-com">www.tedix.com wants to</Typography>
                                        <div style={{ display: 'flex' }}>
                                            <img src={smallNotification} alt='notification' />
                                            <Typography className="show-notification">{this.props.t("Show notifications")}</Typography>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
                                            <Button onClick={() => this.props.navigation.navigate('EmailRegistrationSuccess')} variant="contained" className="notAllow">{this.props.t("Block")}</Button>
                                            <Button onClick={() => this.props.navigation.navigate('EmailRegistrationSuccess')} variant="contained" className="showallow">{this.props.t("Allow")}</Button>
                                        </div>
                                    </div>
                            </div>
                            <div className='arrowUp'><img src={arrowUp} alt="arrowUp" /></div>
                            <div className="rectengle-keeptrack">
                                <div className='rectengleIcon'><img src={rectengleIcon} alt="rectengleIcon" /></div>
                                <div className="keep-track">{this.props.t("To keep you on track!")}</div>
                            </div>
                            <div className='notification-pandaskip'>
                                <div className='notificationIcon'><img src={notificationIcon} alt="notificationIcon" /></div>
                                <div className='pandaSkip'><img src={pandaSkip} alt="pandaSkip" /></div>
                                <div style={{ position: 'absolute', top: '165px' }}><SelectLanguage /></div>
                            </div>
                        </Box>
                    </Container>
                </NotificationStyle>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default withTranslation()(NotificationWeb)

const NotificationStyle = styled('div')({
    "& .dailyAlerts": {
        color: "#363636",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        padding: '20px 30px 0px 30px',
        "@media (max-width:600px)": {
            padding: '20px 50px 0px 50px',
            fontSize: "22px",
        }
    },
    "& .box": {
        height: "166px",
        flexShrink: 0,
        borderRadius: "12px",
        border: "2px solid #E6E6E6",
        background: "#F7F7F7",
        boxShadow: "4px 4px 6px 0px rgba(0, 0, 0, 0.10)",
        padding: '30px 25px 26px',
        margin: '75px auto 0',
        maxWidth: '292px',
        position: 'relative',
        width: '292px'
    },
    "& .tedix-notification": {
        color: "#666",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22.5px",
        margin: '0 0 20px 0'
    },
    "&  .allow, .showallow": {
        color: "white",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        letterSpacing: "0.8px",
        backgroundColor: '#36BFFF',
        width: '110px',
        boxShadow: '0px 4px #00AEFF',
        borderRadius: '12px',
        textTransform: 'none',
        "& span": {
            fontFamily: "Nunito",
        }
    },
    "& .notAllow, .DonotAllow": {
        borderRadius: "12px",
        border: "2px solid #999",
        background: "#FFF",
        color: "#7F7F7F",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        letterSpacing: "0.8px",
        width: '110px',
        boxShadow: '0px 2px #999999',
        textTransform: 'none',
        padding: '6px 0',
        "& span": {
            fontFamily: "Nunito",
        }
    },
    "& .arrowUp": {
        margin: '15px 27% 0 auto',
    },
    "& .keep-track": {
        position: 'absolute',
        color: "#666",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "19.5px",
        margin: '25px 15px 10px',
        width: '115px',

    },
    "& .rectengleIcon": {
        position: 'absolute',
        top: '10px'
    },
    "& .notificationIcon": {
        zIndex: 5,
        top: "20px",
        right: "95px",
        position: "absolute"
    },
    "& .pandaSkip": {
        position: "absolute",
        top: "70px",
        right: "5px"
    },
    "& .notification-pandaskip": {
        position: 'relative',
        width: '60%',
        left: '160px',
        "@media (max-width:450px)": {
            width: '60%',
            left: '170px',
        },
        "@media (max-width:380px)": {
            width: '85%',
            left: '100px',
        },
    },
    "& .rectengle-keeptrack": {
        position: 'relative',
        margin: '69px auto 0px 60px',
        "@media (max-width:450px)": {
            margin: '69px auto 0px 45px',
        },
        "@media (max-width:380px)": {
            margin: '69px 0px 0px 5%',
        },
    },
    "& .tedix-com, .show-notification": {
        color: "#666",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22.5px",
        textAlign: 'start'
    },
    "& .show-notification": {
        marginLeft: '10px'
    },
    "& .closeIcon": {
        position: 'absolute',
        right: "26px",
        top: '23px',
        color: '#CCCCCC',
        cursor: 'pointer',
        width: '15px',
        height: '15px'
    },
    "& .mobileView":{
        display:'none',
        "@media (max-width:600px)": {
            display:'block'
        }
    },
    "& .desktopView":{
        display:'block',
        "@media (max-width:600px)": {
            display:'none'
        }
    }
})

  // Customizable Area End