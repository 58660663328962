import React from 'react'
import { Box, Typography, styled } from '@material-ui/core'
import { blueFlag } from '../../../blocks/cftableforlessons/src/assets'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import { convertLatex } from '../utils'
import  {useTranslation} from "react-i18next"
import { MathFormula } from './ExerciseComponents'

interface SolutionPageProps {
    solutionContent: string
    onContinue: () => void
    width: number
    onClickReport: () => void
    isGuest?: boolean
}

const SolutionPage: React.FC<SolutionPageProps> = ({
    solutionContent,
    onContinue,
    width,
    onClickReport,
    isGuest,
  }) => {
  
  const  { t } = useTranslation()
    
  return (
    <SolutionPageContainer>
      <Box className="solutionTitleContainer">
        <Typography className="solutionTitleText">{t("Solution")} :</Typography>
        {isGuest ? <Box /> : (
          <img width={15} height={15} src={blueFlag} onClick={onClickReport} />
        )}
      </Box>
      <Box className="mainSolutionContent">
        <MathFormula 
          formula={convertLatex(solutionContent || '')}
          color='#00AEFF'
          fontFamily='Quicksand'
          fontSize={18}
          fontWeight={600}
          lineHeight='16px'
          width={width - 60}
        />
      </Box>
      <StyledButton onClick={onContinue}>{t('Continue')}</StyledButton>
    </SolutionPageContainer>
  )
}

const SolutionPageContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 26px 40px',
  backgroundColor: '#c9eeff',
  borderRadius: '20px 20px 0 0',
  height: '100%',

  '& .solutionTitleContainer': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '22px',
  },

  '& .solutionTitleText': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
    color: '#00AEFF',
  },

  '& .mainSolutionContent': {
    flex: 1,
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: '20px 20px 0 0',
    marginBottom: '20px',
    padding: '30px',
    overflowY: 'scroll',
    height: 'calc(100vh - 332px)',
  },

  [theme.breakpoints.up('md')]: {
    padding: '32px 60px 40px',
  },
}))

export default SolutionPage
