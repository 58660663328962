import React from "react";
// Customizable Area Start
import InAppNotifiocationMainController, {
  Props,
  configJSON,
} from "./InAppNotifiocationMainController.web";
import { Checkbox, Modal } from "@material-ui/core";
import {
  MenuIcon,
  NotificationIcon1,
  NotificationIcon2,
  NotificationIcon3,
  NotificationIcon4,
  MarkAsReadIcon2,
  DeleteIcon2,
  DeleteIcon,
  CancelIcon,
  MarkAsReadIcon,
  swipeReadIcon,
  MarkAsReadDisable,
  DeleteDisable,
} from "./assets";
import InAppNotificationHeader from "./InAppNotificationHeader.web";
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
  Type as ListType,
} from "react-swipeable-list";
import InAppNotificationMainLayout from "./InAppNotificationLayout.web";
import { withTranslation } from "react-i18next";
export interface ResDataItem {
  id: number;
  type: string;
  attributes:  {
    id: number,
    status: string,
    subject: string,
    content: string,
    content_as_plain_text: string,
    only_informative: boolean,
    user_name: string,
    image: string,
    is_button: false,
    button_url: string,
    button_content: string,
    time_ago: string
  };
}
// Customizable Area End

interface CardData {
  // Customizable Area Start
  id: number;
  image: string;
  title: string;
  desc: string;
  timeAgo: string;
  markAsRead: boolean;
  // Customizable Area End
}

class InAppNotifiocationMain extends InAppNotifiocationMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderMainNotificationMenu = () => {
    return (
      <ul className="notificationTopMenuList">
        <li
          className="notificationTopMenuListItem"
          onClick={this.handleselectNotifications}
          data-testid="selectNotification"
        >
          <img
            src={NotificationIcon1}
            className="notificationTopMenuListItemImage"
          />
          <p className="notificationTopMenuListItemTitle">
            {this.props.t(configJSON.mainSelectNotifications)}
          </p>
        </li>
        <li className="notificationTopMenuListItem" onClick={this.inAppMarkAsAllRead} data-testid="markAsAllReadTestID">
          <img
            src={NotificationIcon2}
            className="notificationTopMenuListItemImage"           
          />
          <p className="notificationTopMenuListItemTitle">
          {this.props.t(configJSON.mainMarkAsAllRead)}
          </p>
        </li>
        <li
          className="notificationTopMenuListItem"
          data-testid="deleteAllTestId"
          onClick={()=>this.handleOpenDeleteModal("deleteAll")}
        >
          <img
            src={NotificationIcon3}
            className="notificationTopMenuListItemImage"
          />
          <p className="notificationTopMenuListItemTitle">
            {this.props.t(configJSON.mainDeleteAllText)}
          </p>
        </li>
        <li
          className="notificationTopMenuListItem"
          onClick={this.openDeletedNotification}
          data-testid="showRecentlyDeleted"
        >
          <img
            src={NotificationIcon4}
            className="notificationTopMenuListItemImage"
          />
          <p className="notificationTopMenuListItemTitle">
            {this.props.t(configJSON.mainShowRecentlyDeleted)}
          </p>
        </li>
      </ul>
    );
  };

  renderMainMenu = () => {
    return (
      <div className="notificationCenter">
        <div className="notificationTopMenu">
          <div
            className={
              this.state.openNotificationMenu
                ? "notificationTopMenuContainer notificationTopMenuContainerActive"
                : "notificationTopMenuContainer "
            }
            data-testid="menuBtn"
            onClick={this.handleOpenNotificationMainMenu}
          >
            <img src={MenuIcon} className="notificationTopMenuIcon" />
            {this.state.openNotificationMenu && this.renderMainNotificationMenu()}
          </div>
        </div>
      </div>
    );
  };

  renderMainMarkAsReadTopMenu = () => {
    return (
      <div className="renderMarkAsReadTop">
        <div
          className="renderMarkAsReadTopContainer"
          data-testid="topMenuMarkAsRead"
          onClick={this.inAppMarkAsReadSelectedNotification}
        >
          <img src={this.state.selectedNotifications.length === 0 ? MarkAsReadDisable: MarkAsReadIcon2} className="renderMarkAsReadIcon1" />
          <p className={this.state.selectedNotifications.length === 0 ? "renderMarkAsReadTextDisable" : "renderMarkAsReadText"}>{this.props.t(configJSON.mainMarkAsRead)}</p>
        </div>
        <div
          className="renderMarkAsReadDeleteContainer"
          onClick={()=>this.handleOpenDeleteModal("selectedNotifications")}
          data-testid="openDeleteModalTestId"
        >
          <img src={this.state.selectedNotifications.length === 0 ? DeleteDisable :DeleteIcon2} className="renderMarkAsReadIcon2" />
          <p className={this.state.selectedNotifications.length === 0 ? "renderMarkAsReadDeleteTextDisable":"renderMarkAsReadDeleteText"}>{this.props.t(configJSON.mainDelete)}</p>
        </div>
        <div
          className="renderMarkAsReadCancelContainer"
          onClick={this.handleCancelSelectNotification}
          data-testid="cancelButtonTestId"
        >
          <img src={CancelIcon} className="renderMarkAsReadIcon3" />
          <p className="renderMarkAsReadCancelText">{this.props.t(configJSON.mainCancel)}</p>
        </div>
      </div>
    );
  };

  renderNotificationDescription = (id:number,item: ResDataItem,index:number) => {
    return <p className="notificationPara">  
    {this.state.showNotificationDescription && this.state.showNotificationDescriptionId === item.attributes.id ? item.attributes.content_as_plain_text+'.' : item.attributes.content_as_plain_text.slice(0, 58)}
    {item.attributes.content_as_plain_text.length > 58 && <span  className="notificationParaMoreBtn">
      {(!this.state.showNotificationDescription && item.attributes.only_informative === false) && <span data-testid={`moreBtn_${index}`} onClick={() => this.openNotification(item.attributes.id)}><span style={{color:'#363636'}}>...</span>{this.props.t("more")}</span>}</span>}
   </p>
  }


  renderleadingActions = (id: number, index: number,status: string) => {
    return (
      <LeadingActions>
        <SwipeAction
          onClick={() => {status === "unread" ? this.inAppMarkAsReadSingleNotification(id) : this.inAppUnreadNotificationNotifications(id)}}
          data-testid={`swipeMarkAsReadBtn_${index}`}
        >
          <div className="notificationCardMarkAsRead">
            {status === "unread" && <img src={MarkAsReadIcon} className="notificationCardMarkasReadActive" />}
            {status === "read" && <img src={swipeReadIcon} className="notificationCardMarkasRead" />}
          </div>
        </SwipeAction>
      </LeadingActions>
    );
  };

  renderTrailingActions = (id: number, index: number) => {
    return (
      <TrailingActions data-testid={`swipeTrailingTestId_${index}`}>
        <SwipeAction
          onClick={() => {this.inAppSwipeDeleteSingleNotification(id)}}
          data-testid={`swipeDeleteBtnTestId_${index}`}
        >
          <div className="notificationCardDelete">
            <img src={DeleteIcon} className="notificationCardDeleteIcon" />
          </div>
        </SwipeAction>
      </TrailingActions>
    );
  };

  renderAllNotification = () => {
    return (
      <div className="allNotificationContainer">
        {this.state.cardData.map((item: ResDataItem, index: number) => {
          return (
            <SwipeableList
              key={index}
              threshold={0.3}
              type={ListType.IOS}
              fullSwipe={false}
              className="swipeableList"
              data-testid={`singleNotificationMain_${index}`}
            >
              <SwipeableListItem
                leadingActions={this.renderleadingActions(item.attributes.id, index,item.attributes.status)}
                trailingActions={this.renderTrailingActions(item.attributes.id, index)}
                maxSwipe={0.5}
                key={index}
              >
                <div
                  className={
                    item?.attributes.status==="read"
                      ? "notificationCard"
                      : "notificationCard notificationCardMainMarkAsRead"
                  }
                  key={index}
                >
                  <div className="notificationCardImage">
                    {!this.state.selectNotification && (
                      <img src={item.attributes.image} className="cardIcon" />
                    )}
                    {this.state.selectNotification && (
                      <>
                        <Checkbox
                          data-testid={`inAppNotificationCheckBox_${index}`}
                          value={item.id}
                          className={"cardCheckbox"}
                          onChange={()=>this.handleCheckboxChange(item.id)}
                        />
                      </>
                    )}
                  </div>
                  <div className="notificationCardDetails">
                    <div
                      className="notificationHeading"
                      onClick={()=>item.attributes.only_informative === false && this.openNotification(item.attributes.id)}
                      data-testid={`singleNotification_${index}`}
                    >
                      {item.attributes.subject}
                    </div>
                    {this.renderNotificationDescription(item.id,item,index)}
                    <div className="notificationTime">{item.attributes.time_ago}</div>
                  </div>
                </div>
              </SwipeableListItem>
            </SwipeableList>
          );
        })}
      </div>
    );
  };

  renderMainNotificationDeletedModal = () => {
    return (
      <Modal open={this.state.openDeleteModal}>
        <div className="deleteModalBox" data-testid="deleteModal">
          <div className="deleteModalInnerBox">
            <img src={DeleteIcon2} className="deleteModalImage" />
          </div>
          <p className="deleteModalTitle">{this.props.t(configJSON.mainModaltitle)}</p>
          <p className="deleteModalWarningText">
            {this.props.t(configJSON.mainModalSubTitle)}
          </p>
          <div className="deleteModalBtnContainer">
            <button
              data-testid="deleteModalCancelBtn"
              onClick={this.handleModalDeleteNotificationBtn}
              className="deleteModalCancelBtn"
            >
              {this.props.t(configJSON.mainCancel)}
            </button>
            <button
              className="modalDeleteBtn"
              data-testid="deleteModalDeleteButtton"
              onClick={this.handleDeleteModalApiCall}
            >
              {this.props.t(configJSON.mainDelete)}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  renderMainNotificationScreen = () => {
    return (
      <div className="inAppNotificationMain">
        <InAppNotificationHeader
          heading={this.props.t("Notifications")}
          navigation={{
            navigate: function (screenName: string): void {},
          }}
        />
        {!this.state.selectNotification && this.renderMainMenu()}
        {this.state.selectNotification && this.renderMainMarkAsReadTopMenu()}
        {this.renderAllNotification()}
        {this.renderMainNotificationDeletedModal()}
      </div>
    );
  };
  // Customizable Area End

  render = () => {
    // Customizable Area Start
    return (
    <div className="notificationMainScreen">
      <InAppNotificationMainLayout children={this.renderMainNotificationScreen()} topicName={this.state.topicName} unitName={this.state.unitName} unreadNotifcations={this.state.unreadNotifications} openDrawer={this.state.openDrawer} trophiesOpen={this.state.trophiesOpen} goalsOpen={this.state.goalsOpen} handleOpenDrawer={(name:string)=>this.handleOpenDrawer(name)} navigation={this.props.navigation} data-testid={'centerCardTestId'}/>
    </div>
    );
    // Customizable Area End
  };
}
export default withTranslation()(InAppNotifiocationMain);
