import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
//@ts-ignore
import { SelectEvent } from 'react-graph-vis';
import { sendAPIRequest } from '../../../components/src/utils'
import { algebra,orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried, greyImage } from "./assets";
import { setStorageData } from "framework/src/Utilities";
import { TFunction } from "i18next";


type EventHandler = (event: SelectEvent) => void;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t:TFunction
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  graph?: {};
  translate: { x: number, y: number };
  algebra: boolean;
  materialModal: HTMLElement | null;
  topic: string | undefined;
  summaryPdf:string;
  summaryMp4:string;
  cheetsheetPdf:string;
  description: string;
  CurrentNode: ICurrentMaterialNode |undefined;
  topicName:string,
  UnitName:string,
  showGraph:boolean,
  gradeCountryId:number|null,
  selectedGrade:number|undefined,
  gradeList:Array<IGrade>;
  avatarIcon:any, 
  avatarHealth: any,
  avatarColor: any,
  PremiumUser:boolean,
  drawerSide: boolean;
  lessonData:any
  courseNode: ICourseNode | undefined;
  storeSubCategory:Object
  profileLoading:boolean,
  searchItem:ISearchedItems|undefined
  loadSubCatGraph:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


interface TreeNode {
  // Customizable Area Start
  name: string;
  children?: TreeNode[]; // Make children optional
  img?: string;
  // Customizable Area End
}


// Customizable Area Start

export interface IGradeResponse{
  data: Array<IGrade>
}
export interface IGrade{
  id:string,
  type:string,
  attributes:IGradeAttributes
}

export interface IGradeAttributes{
  name:string,
  image:string,
  id:number
}

function wrapLabel(text: string, maxLength: number) {
  const temp = text.replace(/\n/g, ' ')
  const words = temp.split(' ');
  let currentLine = words[0];
  let lines = [];
  for (let textItem = 1; textItem < words.length; textItem++) {
    if (currentLine.length + words[textItem].length <= maxLength) {
      currentLine += ' ' + words[textItem];
    } else {
      lines.push(currentLine);
      currentLine = words[textItem];
    }
  }
  lines.push(currentLine);
  return lines.join('\n');
}

function flattenData(data: Array<INodeData>) {
  const flattenedData: { id: string; label: string | undefined; font: number|undefined; image: string; size: number; type: string | undefined; shape: string; }[]=[];

  function flatten(item: INodeData) {
    flattenedData.push({
      id: item.id,
      label: item.label,
      font:item.font,
      image: item.image,
      size: item.size,
      type: item.type,
      shape: item.shape,
    });

    if (item.child && Array.isArray(item.child)) {
      item.child.forEach(flatten);
    }

  }
  data.forEach(flatten);
  return flattenedData;
}


function createEdges(node: INodeData): Array<Object> {
  const edges = [];
  if (node?.child && node?.child.length > 0) {
    for (const childNode of node.child) {
      edges.push({ from: node.id, to: childNode.id });
      edges.push(...createEdges(childNode));
    }
  }
  return edges;
}
export interface IChildData{
  data:Array<INodeInfo>
}

export interface ISearchedItems{
  data:INodeInfo,
  section:string,
  name:string,

}
export interface IGradeAttributes{
  name:string,
  image:string,
  id:number
}

export interface IError {
  errors: Array<string>
}
export interface IGrade{
  id:string,
  type:string,
  attributes:IGradeAttributes
}
export interface IAttributes{
  cheatsheet_pdf: string;
  summary_pdf: string;
  summary_video: string;
  id: number,
  name: string,
  description: string,
  content: string,
  course_id: number,
  created_at: string,
  updated_at: string,
  category_id: number,
  sub_category_id: number,
  unit_id: number,
  unit_name:string,
  unit_number:string,
  main_id: number,
  image?:string;
  message?:string,
  course_name?:string,
  unit?:IChildData ,
  courses?:IChildData,
  main?:IChildData ,
  lesson?:IChildData,
}

export interface INodeData{
  id:string,
  type?:string,
  label?:string,
  image:string,
  font?:number,
  size:number,
  shape:string,
  child?: Array<INodeData> 
}

export interface ISubCategoryResponse{
  id:string,
  is_default_Grade:boolean,
  category_image_url:string,
  child:IChildData,
  grade:string,
  summary_pdf_url:string,
  summary_video_url:string,
  cheatsheet_pdf_url:string,
}

export interface ICurrentProfileResponse{
  data:{
      attributes:ICurrentResponseAttributes
  }
}

export interface ICurrentResponseAttributes{
  avatar_health:string,
  favorite_color:string,
  app_country:{
      id:number
  },
  payment_premium:boolean
}
export interface INodeInfo{
  index: number | undefined;
  id:string,
  type:string,
  attributes:IAttributes
}

interface  ICurrentMaterialNode{
  id: string|number;
  index?: number;
  show?: boolean;
  image: string;
  label: string;
  summaryPdf: string;
  summaryMp4: string;
  cheetsheetPdf: string
}

interface INode{
  id: string;
  image: string;
  label: string;
  shape: string;
  type: string;
  size: number;
  child?: IChildData
}

interface ICourseNode{
  id: string;
    image: string;
    label: string;
    shape: string;
    type: string;
    size: number;
}
// Customizable Area End

export default class MaterialController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getSubCategories: string = "";
  getGradesByCountryId:string="";
  getProfileId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.MaterialPagePropsMessage),
      getName(MessageEnum.MaterialSearchedMessage),
      getName(MessageEnum.NavigationMaterialMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationNonPremiumUser)
    ]; 

    this.state = {
      drawerSide: false, // Provide a default value
      algebra: false, // Provide a default value
      graph: {
        nodes: [],
        edges: [],
      },
      storeSubCategory:{},
      topic: this.props.t("Current Grade"),
      description: "",
      materialModal: null,
      translate: { x: 0, y: 0 }, // Provide default values
      courseNode: undefined,
      PremiumUser:false,
      showGraph:false,
      lessonData:[],
      topicName:'',
      UnitName:'',
      CurrentNode: undefined,
      summaryPdf:"",
      summaryMp4:"",
      cheetsheetPdf:'',
      gradeCountryId:null,
      selectedGrade:undefined,
      gradeList:[],
      avatarIcon:'',
      avatarHealth: 'healthy', 
      avatarColor: '',
      profileLoading:false,
      searchItem:undefined,
      loadSubCatGraph:false
      // countryId:null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.handleWindowSizeChange();
    this.getProfile(); 
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,): void {
     if(prevState.gradeCountryId!=this.state.gradeCountryId){
      this.getGradeByCountry()
     }
  }

  getCategories = (grade_id:number) => {
    this.setState({
      loadSubCatGraph:true
    })
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getMsgValidations = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategories = getMsgValidations.messageId;

    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgValidations.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategories}?grade_id=${grade_id}`
    );
    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMsgValidations.id, getMsgValidations);
  }

 
  handleOpenCategory=(data: IGrade) => {
      this.setState({
        selectedGrade:data?.attributes?.id,
        showGraph:true,
        description:data?.attributes?.name
      })
      if(this.state.searchItem===undefined){
        this.getCategories(data?.attributes?.id)
      }
    }
  
  

  NodeFunction:EventHandler = ({ nodes, edges }) => {
    const nodeData = JSON.parse(nodes)
    if(nodeData.type == "maingrade") {
      this.setState({
        showGraph:false,
        selectedGrade:undefined,
        searchItem:undefined,
        description:'',
        graph:{
          nodes:[],
          edges:[]
        }
      })
    }else{
      if(this.state.storeSubCategory){
        this.handleSearchedGraph(nodeData)
      }
    }

  }


  generateNewNodesAndEdges = (newNodesData: Array<INodeData>) => {
    const newNodes = newNodesData;
    const newEdges = createEdges(newNodes[0]);
    const nodesNew = flattenData(newNodes);

    this.setState(({
      graph: {
        edges: newEdges,
        nodes: nodesNew,
      },
    }));
  };

  getType=(nodeType:INodeInfo)=>{
    switch(nodeType.type){
       
      case configJSON.subCategoryType:  
      return nodeType.attributes.courses

      case configJSON.course:  
      return nodeType.attributes.main

      case configJSON.main_course:
        return nodeType.attributes.unit
       
      case configJSON.unit:  
        return nodeType.attributes.lesson  
    }
  }
  handleDisableImage=(data:IChildData,image:string)=>{
    return data.data.length > 0 ? image : greyImage;
   }

  handleCustomdata=(nodeData:INodeInfo)=>{
    const defaultData = {data:[]}
    const name = nodeData.attributes.course_name || nodeData.attributes.name
    const getType= this.getType(nodeData)
   return { 
    id:JSON.stringify(nodeData),
    image:  this.handleDisableImage(getType||defaultData, nodeData.attributes.image || algebra),
    label: wrapLabel(name,15),
    shape: "circularImage",
    size: 25,
    }
  }

  getMapping=(nodeArray:IChildData,)=>{
    const defaultData = {data:[]}
    const result = nodeArray.data.map((item: INodeInfo,index:number) => {
      const name = item.attributes.name || item.attributes.course_name
      const getChildLength = this.getType(item) 
      return {
        id: JSON.stringify({...item,index:index+1}),
        image: this.handleDisableImage(getChildLength||defaultData , item.attributes.image  || algebra),
        label: name && wrapLabel(name, 15),
        shape: "circularImage",
        type: 'grade',
        size: 25,
        fixed:false
      }
    })
    return result
  }

  handleTypeSubCategory=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.id
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) 
    if(subCategoryNode){
    const subCategoryNodeData= this.handleCustomdata(subCategoryNode)
     
     const subCategoryChildNodeData = {...subCategoryNodeData,child:this.getMapping(subCategoryNode.attributes.courses as IChildData)}
     const childata =this.getChild(storeSubCategory.child.data)
     const GradeNodeChild =childata?.map((item:INode)=>{
      if(JSON.parse(item.id).id==subCategory){
        return subCategoryChildNodeData
      }else{
      return  item
      }
      
    })
    const GradeNode = [{
      id: JSON.stringify({id: "ddsgdg", type:'maingrade'}),
      image: storeSubCategory.category_image_url || algebra,
      label: storeSubCategory.grade,
      shape: "circularImage",
      size: 25,
      child: GradeNodeChild
      }] as INodeData[]
     this.setState({
      topic:storeSubCategory.grade,
      description:searchedGraph.attributes.name
     })
      this.generateNewNodesAndEdges(GradeNode)
    }
     
   } 

   handleLessonTypeGraphData=(searchedGraph:INodeInfo)=>{
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategory = searchedGraph.attributes.sub_category_id
    const main = searchedGraph.attributes.main_id
    const course = searchedGraph.attributes.course_id 
    const unit = searchedGraph.attributes.unit_id
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course) as INodeInfo
    const mainNode = courseNode.attributes.main?.data?.find((mainItem:INodeInfo)=>parseInt(mainItem.id)==main)as INodeInfo
    const unitNode = mainNode.attributes.unit?.data?.find((unitItem:INodeInfo)=>parseInt(unitItem.id)==unit)as INodeInfo

    const LessonData = unitNode.attributes.lesson?.data?.map((item: INodeInfo) => {
      const name = item.attributes.name 
      return {
        id: JSON.stringify(item),
        shape: "circularImage",
        type: 'grade',
        size: 25,
        image: item.attributes.image  || algebra,
        label: name && wrapLabel(name, 15),
        fixed:false
      }
    })

    setStorageData("LessonMaterial", JSON.stringify(
      {
      lessonData:LessonData,
      topicName:courseNode.attributes.course_name as string,
      UnitName:unitNode.attributes.name,
      isPremium:this.state.PremiumUser
      })
      )
    this.props.navigation.navigate('LessonMaterial')

  }
   
   handleCourseTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>courseItem.id==searchedGraph.id) as INodeInfo

    const courseNodeData= this.handleCustomdata(courseNode)
    const courseChildNode={...courseNodeData,child:this.getMapping(courseNode.attributes.main as IChildData)}

    const subCategoryNodeData = this.handleCustomdata(subCategoryNode)
    const subCategoryChildNode=[ { ...subCategoryNodeData ,child:[{...courseChildNode}]} ]
    this.setState({
      topic : subCategoryNode.attributes.name,
      description:searchedGraph.attributes.course_name??''
    })
    this.generateNewNodesAndEdges(subCategoryChildNode)
  }

  handleUnitTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const course = searchedGraph.attributes.course_id 
    const main = searchedGraph.attributes.main_id
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course) as INodeInfo
    const mainNode = courseNode.attributes.main?.data?.find((mainItem:INodeInfo)=>parseInt(mainItem.id)==main)as INodeInfo
    const unitNode = mainNode.attributes.unit?.data?.find((unitItem:INodeInfo)=>unitItem.id==searchedGraph.id)as INodeInfo

    const LessonData = unitNode.attributes.lesson?.data?.map((item: INodeInfo) => {
      const name = item.attributes.name 
      return {
        id: JSON.stringify(item),
        image: item.attributes.image  || algebra,
        label: name && wrapLabel(name, 15),
        shape: "circularImage",
        type: 'grade',
        size: 25,
        fixed:false
      }
    })

    const unitNodeData = this.handleCustomdata(unitNode)
    const unitChildNode={...unitNodeData, child: LessonData}

    const mainNodeData = this.handleCustomdata(mainNode)
    const mainChildNode={...mainNodeData, child:[{...unitChildNode}]}
  
  const courseNodeData= this.handleCustomdata(courseNode)
  const courseChildNode={...courseNodeData,child:[{...mainChildNode}]}

  const subCategoryNodeData = this.handleCustomdata(subCategoryNode)
  const subCategoryChildNode=[{ ...subCategoryNodeData ,child:[{...courseChildNode}]}]

  this.setState({
    topic: mainNode.attributes.name, 
    topicName:courseNode.attributes.course_name as string,
    UnitName:unitNode.attributes.name,
    lessonData:LessonData,
    description:searchedGraph.attributes.name
  })

  this.generateNewNodesAndEdges(subCategoryChildNode)
     
  }

  handleMainTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const course = searchedGraph.attributes.course_id  
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course)as INodeInfo
    const mainNode = courseNode.attributes.main?.data.find((mainItem:INodeInfo)=>mainItem.id==searchedGraph.id)as INodeInfo

    const mainNodeData = this.handleCustomdata(mainNode)
    const mainChildNode={...mainNodeData, child: this.getMapping(mainNode.attributes.unit as IChildData)}
  
  const courseNodeData= this.handleCustomdata(courseNode)
  const courseChildNode={...courseNodeData,child:[{...mainChildNode}]}

  const subCategoryNodeData = this.handleCustomdata(subCategoryNode)

  const subCategoryChildNode=[{ ...subCategoryNodeData ,child:[{...courseChildNode}]}]

    this.setState({
      topic: courseNode.attributes.course_name as string, 
      description:searchedGraph.attributes.name
    })
  this.generateNewNodesAndEdges(subCategoryChildNode)

  }


  handleSearchedGraph=(searchedGraph:INodeInfo)=>{

     this.setState({
      CurrentNode: {
        id: searchedGraph.id,
        label: searchedGraph.attributes.name || searchedGraph.attributes.course_name as string,
        summaryPdf:searchedGraph.attributes?.summary_pdf,
        summaryMp4:searchedGraph.attributes?.summary_video ,
        cheetsheetPdf:searchedGraph.attributes?.cheatsheet_pdf,
        index:searchedGraph?.index,
        show:searchedGraph.type!==configJSON.subCategoryType,
        image: searchedGraph.attributes.image||algebra,
        
      }
    })
    switch(searchedGraph.type){

      case configJSON.subCategoryType:
        return this.handleTypeSubCategory(searchedGraph)
       
      case configJSON.course:
        return this.handleCourseTypeGraphData(searchedGraph)

      case configJSON.main_course:
        return this.handleMainTypeGraphData(searchedGraph)  

      case configJSON.unit:
        return this.handleUnitTypeGraphData(searchedGraph) 
        
      case configJSON.lesson:
        return this.handleLessonTypeGraphData(searchedGraph)  
    }
}

  handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 960) {
      this.setState({ drawerSide: false });
    } else {
      this.setState({ drawerSide: true });
    }
  };

  handleSubCategory = (data: Array<INodeInfo>) => {
    const defaultData={data:[]}
    const result = data.map((item: INodeInfo) => {
      const getChildLength= this.getType(item)
     const image= this.handleDisableImage(getChildLength||defaultData , item.attributes.image  || algebra)
      return {
        id: JSON.stringify(item),
        label: wrapLabel(item.attributes.name, 15),
        image: image,
        shape: "circularImage",
        type: 'grade',
        size: 25
      }
    })
    return result
  }


  getChild = (data: Array<INodeInfo>) => {
    if (data?.length> 0 && data[0]?.type === configJSON.subCategoryType) {
      const result = this.handleSubCategory(data)
      return result
    }
  }

  handleGraphData = (responseJson: ISubCategoryResponse) => {

    this.setState({
      storeSubCategory:responseJson,
      loadSubCatGraph:false
    })

    let newNodes = [
      {
        id: JSON.stringify({id: "adsjaklsdja", type:'maingrade'}),
        size: 25,
        label: responseJson.grade,
        shape: "circularImage",
        child: this.getChild(responseJson.child?.data),
        image: responseJson.category_image_url || algebra,
      },
    ]
    if(this.state.searchItem===undefined){
    this.setState({
      CurrentNode: {
        label: responseJson.grade,
        summaryPdf:responseJson?.summary_pdf_url,
        summaryMp4:responseJson?.summary_video_url,
        cheetsheetPdf:responseJson?.cheatsheet_pdf_url,
        id: "adsjaklsdja",
        image: responseJson.category_image_url || algebra,
      }
    })

    const newEdges = createEdges(newNodes[0]);
    const nodesNew = flattenData(newNodes)

    this.setState(({
      graph: {
        nodes: nodesNew,
        edges: newEdges
      }
    }));
  }else{
    this.handleSearchedGraph(this.state.searchItem.data)
  }
  }


handleOnClickMaterial=(event: React.MouseEvent<HTMLButtonElement>) => {
    if(this.state.PremiumUser){
    this.setState({ materialModal: event?.currentTarget })
    }else{
    const addMessage: Message = new Message(getName(MessageEnum.NavigationNonPremiumUser));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addMessage);
    }
  }


  getProfile = () => {
    this.setState({
      profileLoading:true
   })
    const token = localStorage.getItem('token')
    this.getProfileId = sendAPIRequest('bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getGradeByCountry = async() => {
      this.getGradesByCountryId = sendAPIRequest(`bx_block_categories/categories/get_grades_by_country_id?country_id=${this.state.gradeCountryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
  }

  getColorMap = () => {
    return {
        '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
        '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
        '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
        '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar},
        '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
        '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
        '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
        '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
    };
};

iconAvatar() {
  const { avatarColor, avatarHealth } = this.state;
  
  const colorMap = this.getColorMap();

        const avatar = (avatarColor !== undefined && avatarHealth !== undefined) 
    ? ((colorMap as any)[avatarColor]?.[avatarHealth] || null) 
    : blueHealthy;

        this.setState({ avatarIcon: avatar ,profileLoading:false});
}


backButton=() => { 
  if(!this.state.showGraph){
  this.props.navigation.navigate("MainMenuWeb")
  }else{
    this.setState({
      showGraph:false,
      selectedGrade:undefined,
      description:'',
      graph:{
        nodes:[],
        edges:[]
      }
    })
  }
}

handleSubCategoryResponse=(responseJson:ISubCategoryResponse & IError)=>{
  if(!responseJson.errors){
    this.handleGraphData(responseJson)
   }
}

handleCurrentProfileResponse=(responseJson:ICurrentProfileResponse & IError )=>{
  this.setState({
    avatarColor: responseJson?.data?.attributes?.favorite_color,
    avatarHealth: responseJson?.data?.attributes?.avatar_health, 
    PremiumUser: responseJson?.data?.attributes?.payment_premium,
    gradeCountryId:responseJson?.data?.attributes?.app_country?.id,
     })
    this.iconAvatar();
}


handleGradeByCountryResponse=(responseJson:IGradeResponse & IError)=>{
  this.setState({
    gradeList:responseJson?.data,
   })
}


handleResponse=(responseJson:ISubCategoryResponse & IGradeResponse &  IError & ICurrentProfileResponse , apiRequestCallId:string)=>{
  switch(apiRequestCallId){
      case this.getSubCategories:
         return this.handleSubCategoryResponse(responseJson)
      case this.getProfileId:
          return this.handleCurrentProfileResponse(responseJson)
      case this.getGradesByCountryId:    
          return this.handleGradeByCountryResponse(responseJson)
  }
}

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const countryId = await message.getData(getName(MessageEnum.MaterialPagePropsMessage));
      if(countryId){
      this.setState({
        gradeCountryId:countryId
      })
      }
    }

    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const searchedGraph = await message.getData(getName(MessageEnum.MaterialSearchedMessage));
      if(searchedGraph){
       this.setState({
         showGraph:true,
         searchItem:searchedGraph
       })
       const categoryId= searchedGraph?.data?.attributes?.category_id as number
       this.getCategories(categoryId)
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.handleResponse(responseJson,apiRequestCallId)
    }
  }

  // Customizable Area End

}

// Customizable Area Start
// Customizable Area End