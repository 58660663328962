import React from "react";
import {
    // Customizable Area Start
    Box, Button, Container, FormControl, Grid, MenuItem, Select, Typography,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import { EnglishFlag, GermanFlag, HungarianFlag, RomanianFlag, pandaIcon, tedixBlackIcon } from "./assets";
import EmailRegistrationController, { Props } from "./EmailRegistrationController.web";
import Loader from "../../../components/src/pandaLoader.web";
import { KeyboardArrowDown } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import SelectLanguage from "../../../components/src/SelectLanguage";
// Customizable Area End

 class DesktopScreen extends EmailRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {

        return (
            // Customizable Area Start
            <>
                {this.state.spinner ? <DesktopStyle>
                    <Container maxWidth="xs">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <div className="">
                                <Typography className="welcome-to">
                                    {this.props.t("Welcome to")}
                                </Typography>
                                <div>
                                    <Grid container spacing={0} style={{ alignItems: 'center' }}>
                                        <Grid xs className="plus">
                                            +
                                        </Grid>
                                        <Grid xs className="mines">
                                            -
                                        </Grid>
                                        <Grid xs className="cros">
                                            +
                                        </Grid>
                                        <Grid xs className="division">
                                            ÷
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ display: "flex", justifyContent: "end", margin: '38px 0 0 0' }}>
                                    <div className="tedix">
                                        <img src={tedixBlackIcon} alt='tedixBlackIcon' />
                                    </div>
                                    <img src={pandaIcon} className="panda-icon" alt='panda-icon' />
                                </div>
                                <div className="explore-learn">
                                  { this.props.t("Explore")}.<br />{this.props.t("Learn")}.
                                </div>
                                <Typography className="understand">{this.props.t("Understand")}.</Typography>
                                <Typography>
                                    <Button variant="contained" className="Get-Started" onClick={ this.getStarted}>{this.props.t("Get Started")}</Button>
                                </Typography>
                                <Typography>
                                    <Button variant="contained" className="have-account" onClick={this.haveAccount}>{this.props.t("I already have an account")}</Button>
                                </Typography>
                                <Typography style={{ textAlign: 'center'}}>
                                    <SelectLanguage />
                                </Typography>
                            </div>
                        </Box>
                    </Container>
                </DesktopStyle> : <Loader />}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTranslation()(DesktopScreen)

const DesktopStyle = styled('div')({
    "@media (max-width:600px)": {
        overflowX:'hidden',
        "& .MuiContainer-root": {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        "& .panda-icon":{
            margin:'0 -6px 0 0'
        },
    },
    "& .tedix": {
        margin: '26px 0 0 0'
    },
    "& .welcome-to": {
        color: "#363636",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "23px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        margin: '55px 0 40px 0',
        "@media (max-width:600px)": {
            fontSize: "20px",
        },
    },
    "& .explore-learn": {
        color: "#363636",
        fontFamily: "Quicksand",
        fontSize: "20px",
        lineHeight: "29px",
        fontWeight: 700,
        textAlign: "center",
        fontStyle: "normal",
        margin: '0 0 10px 0'
    },
    "& .understand": {
        fontFamily: "Quicksand",
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: "20px",
        textAlign: "center",
        color: "#62CDFF",
        lineHeight: "29px",
        letterSpacing:8
    },
    "& .Get-Started": {
        color: "white",
        fontFamily: "Nunito",
        fontSize: "20px",
        textAlign: "center",
        fontStyle: "normal",
        width: '400px',
        height: '44px',
        fontWeight: 700,
        lineHeight: "16px",
        backgroundColor: '#36BFFF',
        boxShadow: '0px 4px #00AEFF',
        margin: '74px 0 15px 0',
        letterSpacing: "0.8px",
        borderRadius: '12px',
        textTransform: 'none',
        "@media (max-width:600px)": {
            width: '260px',
            fontSize: '16px'
        }
    },
    "& .have-account": {
        borderRadius: "12px",
        border: "2px solid #999",
        background: "#FFF",
        boxShadow: '0px 2px #999999',
        color: "#7F7F7F",
        fontFamily: "Nunito",
        fontStyle: "normal",
        letterSpacing: "0.8px",
        fontWeight: 700,
        margin: '0 0 35px 0',
        fontSize: "20px",
        width: '400px',
        textTransform: 'none',
        height: '44px',
        padding: '0px',
        "& span": {
            fontFamily: "Nunito",
        },
        "@media (max-width:600px)": {
            width: '260px',
            fontSize: '16px'
        }
    },
    "& .plus": {
        fontStyle: "normal",
        textAlign: "center",
        lineHeight: "17px",
        fontFamily: "Nunito",
        fontSize: "40px",
        color: "#FF8400",
        fontWeight: 700,
        margin: '70px 0 0 0'
    },
    "& .mines": {
        color: "#2BCE8C",
        fontStyle: "normal",
        fontWeight: 700,
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "40px",
        lineHeight: "17px"
    },
    "& .cros": {
        textAlign: "center",
        fontSize: "40px",
        transform: "rotate(-45deg)",
        color: "#A95EDB",
        fontStyle: "normal",
        fontWeight: 700,
        fontFamily: "Nunito",
        lineHeight: "17px",
    },
    "& .division": {
        fontFamily: "Nunito",
        fontSize: "40px",
        fontWeight: 700,
        lineHeight: "17px",
        fontStyle: "normal",
        textAlign: "center",
        color: "#F96855",
        margin: '70px 0 0 0'
    },
    "& .flagIcon": {
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        margin: '0 13px',
    },
    "& .MuiSelect-select": {
        color: "#363636",
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "15px",
        display: 'flex',
        alignItems: 'center',
        fontSize:'12px'
    },
    '& .MuiSelect-icon': {
        color: '#000',
    },
})

  // Customizable Area End