// Customizable Area Start
export const eyeses = {
  eyes1: true,
  eyes2: true,
  eyes3: true,
  eyes4: true,
  eyes5: true,
  eyes6: true,
};
// Customizable Area End
