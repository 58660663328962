import React, { useEffect, useState, useRef, useMemo } from 'react'
import { Box, TextField, Typography, styled, useTheme, useMediaQuery } from '@material-ui/core'
import _ from 'lodash'
import {
  QuestionBodyItem,
  QuestionProps,
  StyledButton,
  reportOptions,
} from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  blueFlag,
  greenFlag,
  greenShare,
  groupConfetti,
  redFlag,
  redShare,
} from '../../../blocks/cftableforlessons/src/assets'
import GroupActionsExercise from './GroupActionsExercise'
import ActionAlert from './ActionAlert'
import ReportModal from '../../../blocks/cftableforlessons/src/ReportModal'
import WellDoneShare from '../../../blocks/cftableforlessons/src/WellDoneShare'
import ThankReportModal from '../../../blocks/cftableforlessons/src/ThankReportModal'
import {
  CorrectAnswerButton,
  InCorrectAnswerButton,
} from './SingleChoiceExercise'
import { convertLatex } from '../utils'
import { HintText, MathFormula, QuestionText } from './ExerciseComponents'
import  {useTranslation} from "react-i18next"
import "../I18nextConfig/i18n"
import SolutionPage from './SolutionPage'
import ShareModal from './ShareModal.web'
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../assets'

const FONT_SIZE = 31
const DEFAULT_MIN_WIDTH = 64
const DEFAULT_MAX_WIDTH = 160

interface CustomQuestionBodyItem extends QuestionBodyItem {
  id: number
}

interface BlankWidthItem {
  id: number
  width: number
}

const FillBlanksExercise: React.FC<QuestionProps> = ({
  onClickContinue,
  assessmentId,
  attributes,
  onSubmitExerciseAnswer,
  exerciseAnswer,
  isLastQuestion,
  onSelectReportOption,
  onSubmitReport,
  isGuest,
  onUpdateXP,
  lessonId,
  lessonAttemptId,
  microLessonId,
  shareRecordType,
}) => {
  const { question, questionBody, options, hint, solution, questionType, id } = attributes
  const  { t } = useTranslation()
  const [showInCorrectAnswer, setShowInCorrectAnswer] = useState(false)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [showHintAlert, setShowHintAlert] = useState(false)
  const [showSolutionAlert, setShowSolutionAlert] = useState(false)
  const [showSkipAlert, setShowSkipAlert] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isDisableTextInput, setIsDisableTextInput] = useState(false)
  const [showWellDoneShareModal, setShowWellDoneShareModal] = useState(false)
  const [showThankReportModal, setShowThankReportModal] = useState(false)
  const [showHint, setShowHint] = useState(false)

  const [questionBodyState, setQuestionBodyState] =
    useState<CustomQuestionBodyItem[]>([])
  const [blankTextWidths, setBlankTextWidths] = useState<BlankWidthItem[]>([])

  const [startTime, setStartTime] = useState(0)
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [solutionFromValue, setSolutionFromValue] = useState<'SOLUTION' | 'INCORRECT' | null>(null)

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': orangePartyTeddy,
    '#62cdff': bluePartyTeddy,
    '#b7f0ad': mintPartyTeddy,
    '#a95edb': purplePartyTeddy,
    '#f96855': redPartyTeddy,
    '#884a39': brownPartyTeddy,
    '#fdce36': yellowPartyTeddy,
    '#2bce8c': greenPartyTeddy
  };

  const partyTeddy = colorToUpdateTeddy[avatarColor] || bluePartyTeddy;

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setWidth(boxRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const questionBodyTransfrom = typeof questionBody === 'string' ? [] : questionBody

    const customQuestionBody: CustomQuestionBodyItem[] = _.cloneDeep(questionBodyTransfrom).map(
      (item, index) => {
        const customText = item.isBlank ? '' : item.text
        return {
          ...item,
          text: customText,
          id: index,
        }
      }
    )
    const defaultBlankTextWidths = customQuestionBody.map((item) => {
      return {
        id: item.id,
        width: DEFAULT_MIN_WIDTH,
      }
    })
    setBlankTextWidths(defaultBlankTextWidths)

    if (exerciseAnswer) {
      const userAnswerString = exerciseAnswer?.attributes?.userAnswer
      const userAnswerArray = userAnswerString?.split('(split-space)')
      const userAnswer = userAnswerArray?.map(item => {
        return JSON.parse(item)
      })
      const answerQuestionBody = _.cloneDeep(customQuestionBody).map(item => {
        const answerText = userAnswer?.find(i => i?.id === item?.correctAnswerId)?.text
        return {
          ...item,
          text: answerText ? answerText : item.text
        }
      })
      setQuestionBodyState(answerQuestionBody)
      setIsDisableTextInput(true)
      const isInCorrect = answerQuestionBody.some((item) => {
        const correctText = options.find(
          (option) => option.attributes.id === item.correctAnswerId
        )?.attributes.option
        return item.isBlank && item.text.trim() !== correctText
      })
      if (isInCorrect) {
        setShowInCorrectAnswer(true)
      } else {
        setShowCorrectAnswer(true)
      }
    } else {
      setStartTime(Date.now())

      setQuestionBodyState(customQuestionBody)
    }
  }, [questionBody, exerciseAnswer])

  useEffect(() => {
    if (blankTextWidths.length > 0) {
      const changedWidths = blankTextWidths.map((item) => {
        const text = questionBodyState.find((i) => i.id === item.id)?.text || ''
        if (text.length * FONT_SIZE < DEFAULT_MIN_WIDTH) {
          return {
            ...item,
            width: DEFAULT_MIN_WIDTH,
          }
        } else if (
          text.length * FONT_SIZE > DEFAULT_MIN_WIDTH &&
          text.length * FONT_SIZE < DEFAULT_MAX_WIDTH
        ) {
          return {
            ...item,
            width: text.length * FONT_SIZE,
          }
        } else {
          return {
            ...item,
            width: DEFAULT_MAX_WIDTH,
          }
        }
      })
      setBlankTextWidths(changedWidths)
    }
  }, [questionBodyState])

  const questionBodyTransfrom = useMemo(() => {
    return typeof questionBody === 'string' ? [] : _.cloneDeep(questionBody) 
  }, [questionBody])

  return (
    <FillBlanksExerciseContainer {...{ ref: boxRef }}>
      {solutionFromValue ? (
        <SolutionPage
          solutionContent={solution} 
          onContinue={() => {
            const param = solutionFromValue === 'SOLUTION' ? {isUseSolution: true} : {isAnswerWrong: true}
            onClickContinue(param)
          }}
          width={isMediumScreen ? width - 120 : width - 52}
          onClickReport={() => setShowReportModal(true)}
          isGuest={isGuest}
        />
      ) : (
        <Box className="mainContainer">
          <Box className="questionWrapper">
            <QuestionText question={question} width={isMediumScreen ? width - 120 : width - 52} />
            <Box className="questionBodyWrapper">
              {questionBodyTransfrom.map((item, index) => {
                return item.isBlank ? (
                  <BlankTextField
                    key={`${index} - ${item.text}`}
                    value={questionBodyState[index]?.text}
                    onChange={(e) => {
                      const updateQuestionBody = _.cloneDeep(questionBodyState)
                      updateQuestionBody[index].text = e.target.value
                      setQuestionBodyState(updateQuestionBody)
                    }}
                    disabled={isDisableTextInput}
                    InputProps={{
                      style: { width: `${blankTextWidths[index]?.width}px` },
                    }}
                  />
                ) : (
                  <Typography key={`${index} - ${item.text}`} className="questionBodyText">
                    <MathFormula
                      formula={convertLatex(item.text)}
                      color='#363636'
                      fontFamily='Quicksand'
                      fontSize={20}
                      fontWeight={700}
                      lineHeight='16px'
                    />
                  </Typography>
                )
              })}
            </Box>
          </Box>
          {!showCorrectAnswer &&
            !showInCorrectAnswer &&
            !showHint && (
              <GroupActionsExercise
                isDisableButton={questionBodyState.some(
                  (item) => item.isBlank && item.text === ''
                )}
                onCheckAnswer={() => {
                  setIsDisableTextInput(true)
                  const isInCorrect = questionBodyState.some((item) => {
                    const correctText = options.find(
                      (option) => option.attributes.id === item.correctAnswerId
                    )?.attributes.option
                    return item.isBlank && item.text.trim() !== correctText
                  })
                  if (isInCorrect) {
                    setShowInCorrectAnswer(true)
                  } else {
                    setShowCorrectAnswer(true)
                  }

                  const endTime = Date.now();
                  const elapsedTimeInSeconds = (endTime - startTime) / 1000;

                  const optionString = _.cloneDeep(questionBodyTransfrom)
                    .filter((item) => item.isBlank)
                    .map((item) => {
                      const correctText = options.find(
                        (option) => option.attributes.id === item.correctAnswerId
                      )?.attributes.option
                      return correctText
                    })
                    .join('(split-space)')

                  const questionBodyText = questionBodyTransfrom.map(item => item.text ? item.text : '')
                  const questionBodyStr = questionBodyText.join('(split-space)')

                  const questionBlanks = questionBodyState.filter(item => item.isBlank)
                  const questionBlanksText = questionBlanks.map(item => {
                    return JSON.stringify({
                      text: item.text,
                      id: item.correctAnswerId
                    })
                  })
                  const questionBlanksStr = questionBlanksText.join('(split-space)')
                  
                  const submitData = {
                    assessmentId: assessmentId,
                    questionBankId: attributes?.id,
                    questionType: questionType,
                    questionBody: questionBodyStr,
                    correctAnswer: optionString,
                    userAnswer:  questionBlanksStr,
                    correct: !isInCorrect,
                    grade: isInCorrect ? 0 : 10,
                    timeCompleteInSeconds: Math.floor(elapsedTimeInSeconds),
                    lessonCompleted: isLastQuestion,
                  }
                  onSubmitExerciseAnswer && onSubmitExerciseAnswer(submitData)
                }}
                onShowSolutionAlert={() => {
                  const skipShowSolutionAlert = Boolean(
                    localStorage.getItem('isSkipShowSolutionAlertMicroLesson')
                  )
                  if (!skipShowSolutionAlert) {
                    setShowSolutionAlert(true)
                  } else {
                    setSolutionFromValue('SOLUTION')
                    setIsDisableTextInput(true)
                  }
                }}
                onShowSkipAlert={() => {
                  const skipShowSkipAlert = Boolean(
                    localStorage.getItem('isSkipShowSkipAlertMicroLesson')
                  )
                  if (!skipShowSkipAlert) {
                    setShowSkipAlert(true)
                  } else {
                    onClickContinue({isSkip: true})
                  }
                }}
                onShowHintAlert={() => {
                  const skipShowHintAlert = Boolean(
                    localStorage.getItem('isSkipShowHintAlertMicroLesson')
                  )
                  if (!skipShowHintAlert) {
                    setShowHintAlert(true)
                  } else {
                    setShowHint(true)
                  }
                }}
              />
            )}
        </Box>
      )}
      {showHint && (
        <Box className="solutionContainer">
          <Box className="solutionContentContainer">
            <HintText hint={hint} width={isMediumScreen ? width - 120 : width - 52} />
            {isGuest ? (<Box />) : (
              <img
                width={15}
                height={15}
                src={blueFlag}
                onClick={() => setShowReportModal(true)}
              />
            )}
          </Box>
          <StyledButton
            onClick={() => {
              setShowHint(false)
            }}
          >
            {t("Continue")}
          </StyledButton>
        </Box>
      )}
      {showInCorrectAnswer && (
        <Box className="incorrectAnswerContainer">
          <Box className="incorrectAnswerContentContainer">
            <Box>
              <Typography className="incorrectAnswerTitle">
                {t("Oh oh! Correct answer:")}
              </Typography>
              <Typography className="incorrectAnswerDesc">
                {t("Continue to see the solution...")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  width={15}
                  height={15}
                  src={redFlag}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  width={15}
                  height={15}
                  src={redShare}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <InCorrectAnswerButton 
            onClick={() => {
              setShowInCorrectAnswer(false)
              if (exerciseAnswer) {
                setSolutionFromValue('SOLUTION')
              } else {
                setSolutionFromValue('INCORRECT')
              }
            }}
          >
          {t("Continue")}
          </InCorrectAnswerButton>
        </Box>
      )}
      {showCorrectAnswer && (
        <Box className="correctAnswerContainer">
          <Box className="correctAnswerContentContainer">
            <Box className="correctAnswerContentWrapper">
              <img className="partyTeddy" src={partyTeddy} />
              <Typography className="correctAnswerText">
                {t("Awesome work!")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box className="correctAnswerImageWrapper">
                <img
                  height={15}
                  width={15}
                  src={greenFlag}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  height={15}
                  width={15}
                  src={greenShare}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <CorrectAnswerButton onClick={() => {
            const param = exerciseAnswer ? {} : {isAnswerCorrect: true}
            onClickContinue(param)
          }}>
          {t("Continue")}
          </CorrectAnswerButton>
        </Box>
      )}
      <ShareModal 
        id="share-modal" 
        open={shareModalOpen} 
        handleClickClose={() => setShareModalOpen(false)}
        questionBankId={id}
        assessmentId={assessmentId}
        lessonId={lessonId}
        lessonAttemptId={lessonAttemptId}
        microLessonId={microLessonId}
        shareRecordType={shareRecordType}
        onAfterShare={() => {
          setShareModalOpen(false)
          setShowWellDoneShareModal(true)
        }}
        onUpdateXp={onUpdateXP}
      >
        <Box style={{width: '100%', padding: '24px 16px', textAlign: 'left'}}>
          <QuestionText question={question} width={308} isSharing />
          <Box style={{
            display: 'flex',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            marginTop: '-32px',
          }}>
              {questionBodyTransfrom.map((item, index) => {
                return item.isBlank ? (
                  <BlankTextField
                    key={`${index} - ${item.text}`}
                    value={questionBodyState[index]?.text}
                    disabled={true}
                    InputProps={{
                      style: { width: `${blankTextWidths[index]?.width}px` },
                    }}
                  />
                ) : (
                  <Typography key={`${index} - ${item.text}`} className="questionBodyText">
                    <MathFormula
                      formula={convertLatex(item.text)}
                      color='#363636'
                      fontFamily='Quicksand'
                      fontSize={20}
                      fontWeight={700}
                      lineHeight='16px'
                    />
                  </Typography>
                )
              })}
            </Box>
        </Box>
      </ShareModal>
      <ActionAlert
        open={showSolutionAlert}
        onClose={() => setShowSolutionAlert(false)}
        title={t("Solution Alert")}
        description={t("Please note that choosing to view the solution will not earn you any XP.")}
        actionText={t("Solution")}
        onClickAction={() => {
          setShowSolutionAlert(false)
          setIsDisableTextInput(true)
          setSolutionFromValue('SOLUTION')
        }}
        actionType="SOLUTION"
      />
      <ActionAlert
        actionType="HINT"
        open={showHintAlert}
        title={t("Hint Alert")}
        isHint
        onClose={() => setShowHintAlert(false)}
        actionText={t("Show hint")}
        description={t("If you choose to access a hint, you won't earn any XP.")}
        onClickAction={() => {
          setShowHintAlert(false)
          setShowHint(true)
        }}
      />
      <ActionAlert
        actionType="SKIP"
        open={showSkipAlert}
        onClose={() => setShowSkipAlert(false)}
        title={t("Skip Alert")}
        actionText={t("Skip")}
        description={t(`skipDescription`)}
        onClickAction={() => onClickContinue({isSkip: true})}
      />
      <ReportModal
        title={t("Report a problem")}
        reportOptions={reportOptions}
        open={showReportModal}
        onClose={() => setShowReportModal(false)}
        onSubmitReport={() => {
          onSubmitReport && onSubmitReport()
          setShowThankReportModal(true)
        }}
        handleOption={(value: string) => {
          onSelectReportOption && onSelectReportOption(value)
        }}
      />
      <WellDoneShare
        onClose={() => setShowWellDoneShareModal(false)}
        open={showWellDoneShareModal}
        isExerciseShare
      />
      <ThankReportModal
        onClose={() => setShowThankReportModal(false)}
        open={showThankReportModal}
      />
    </FillBlanksExerciseContainer>
  )
}

const FillBlanksExerciseContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '& .mainContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 26px 0',
  },

  '& .questionWrapper': {
    flex: 1,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .questionBodyWrapper': {
    marginBottom: '56px',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },

  '& .questionBodyText': {
    '& span': {
      textAlign: 'left !important',
    },
  },

  '& .solutionContainer': {
    width: '100%',
    padding: '36px 26px 40px',
    backgroundColor: '#C9EEFF',
    borderRadius: '20px 20px 0 0',
  },

  '& .solutionContentContainer': {
    display: 'flex',
    marginBottom: '28px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .solutionText': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
    color: '#00AEFF',
  },

  '& .correctAnswerContainer': {
    width: '100%',
    backgroundColor: '#d5f5e8',
    backgroundImage: `url(${groupConfetti})`,
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
  },

  '& .correctAnswerContentContainer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .correctAnswerContentWrapper': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .correctAnswerImageWrapper': {
    display: 'flex',
    gap: '10px',
  },

  '& .partyTeddy': {
    marginLeft: '12px',
    height: '84px',
    marginBottom: '-28px',
    width: '84px',
  },

  '& .correctAnswerText': {
    fontFamily: 'Nunito',
    color: '#2BCE8C',
    fontSize: '20px',
    fontWeight: '900',
    marginLeft: '10px',
  },

  '& .incorrectAnswerContainer': {
    backgroundColor: '#fee1dd',
    width: '100%',
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
  },

  '& .incorrectAnswerContentContainer': {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .incorrectAnswerTitle': {
    color: '#F96855',
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '900',
  },

  '& .incorrectAnswerDesc': {
    color: '#F96855',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontWeight: '600',
  },

  [theme.breakpoints.up('md')]: {
    '& .mainContainer': {
      padding: '32px 60px 0',
    },
  },
}))

const BlankTextField = styled(TextField)(() => ({
  marginLeft: '12px',
  marginRight: '12px',
  marginBottom: '8px',

  '& .MuiInputBase-input': {
    fontFamily: 'Quicksand',
    fontSize: '20px',
    fontWeight: 700,
    color: '#666666',
    textAlign: 'center',
    paddingBottom: '4px',
  },

  '& .MuiInput-underline:after': {
    borderBottom: '3px solid #62CDFF',
  },

  '& .MuiInput-underline:before': {
    borderBottom: '3px solid #62CDFF',
  },

  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: '3px solid #62CDFF',
  },
}))

export default FillBlanksExercise
