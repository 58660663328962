// Customizable Area Start

import React from 'react'
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  styled,
} from '@material-ui/core'

import Header from '../../../components/src/Header'
import TermsConditionsRegisterController, {
  Props,
} from './TermsConditionsRegisterController'
import { imgTermsConds } from './assets'
import { withTranslation } from 'react-i18next'

class TermsConditionsRegister extends TermsConditionsRegisterController {
  constructor(props: Props) {
    super(props)
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  render() {
    const  {t} = this.props
    return (
      <TermsCondsAtRegisterContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} showLanguageOption />
        <Container className="contentContainer" maxWidth="md">
          <Box width="100%" style={{ position: 'relative' }}>
            <Typography className="TermsCondsAtRegisterTitle">
              {t("Terms & Conditions")}
            </Typography>
            <img src={imgTermsConds} className="termsCondsImg" />
          </Box>
          {this.state.isLoading ? (
            <Box component="div" className="loadingWrapper">
              <CircularProgress style={{ color: '#00AEFF' }} />
            </Box>
          ) : (
            <>
              <Box width="100%" marginTop="46px">
                <Typography className="termsCondsTitle">
                { t("General provisions")}
                </Typography>
              </Box>
              <div className='termsCondsDesc' dangerouslySetInnerHTML={{ __html: this.state.descriptions }}></div>
            </>
          )}
        </Container>
      </TermsCondsAtRegisterContainer>
    )
  }
}

export default withTranslation()(TermsConditionsRegister)

const TermsCondsAtRegisterContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '42px',
    paddingLeft: '46px',
    paddingRight: '46px',
  },

  '& .termsCondsImg': {
    position: 'absolute',
    width: '64px',
    height: 'auto',
    top: '0',
    right: '-46px',
  },

  '& .TermsCondsAtRegisterTitle': {
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Nunito',
  },

  '& .termsCondsTitle': {
    color: '#363636',
    fontSize: '18px',
    fontFamily: 'Nunito',
    fontWeight: '700',
    marginBottom: '12px',
  },

  '& .loadingWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '48px',
  },

  '& .termsCondsDesc': {
    color: '#666666',
    fontSize: '14px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    marginBottom: '24px',
    textAlign: 'justify',
  },

  [theme.breakpoints.up('md')]: {
    '& .termsCondsImg': {
      width: '72px'
    },
  },
}))
