import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";

interface ILessonMaterial{
  id:string;
  image:string;
  label:string;
  shape:string;
  type:string;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    topicName: string;
    unitName: string;
    trophiesOpen: boolean;
    goalsOpen: boolean;
    isPremium:boolean;
    drawerSide: boolean;
    openDrawer: boolean;
    isScrolled: boolean;
    LessonData:ILessonMaterial[]
    unitId: string
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }

  export default class ChallengeLessonController extends BlockComponent<
  Props,
  S,
  SS
> {

   // Customizable Area Start
   // Customizable Area End

   constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      unitName: " ",
      LessonData:[],
      drawerSide: false,
      trophiesOpen: false,
      goalsOpen: false,
      isScrolled: false,
      topicName: " ",
      openDrawer: false,
      isPremium:false,
      unitId: ''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('scroll', this.handleScroll);  
    this.handleWindowSizeChange();
    const lessonData = await getStorageData('challengeLesson',true)
    this.setState({
      topicName:lessonData?.topicName,
      LessonData:lessonData?.lessonData,
      unitName:lessonData?.UnitName,
      isPremium:lessonData?.isPremium,
      unitId: lessonData?.unitId
    })
   }  
  
  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, });
  };

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

 goBackScreen=()=>{
  this.props.navigation.navigate('ChallengePage')
}
  
  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 960) {
      this.setState({ 
          drawerSide: false 
      });
    } else {
      this.setState({
           drawerSide: true 
          });
    }
  };

  handleNonPremiumSceen=()=>{
    const addMessage: Message = new Message(getName(MessageEnum.NavigationNonPremiumUser));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(addMessage);
  }

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    }
  }
 
// Customizable Area End
}   

// Customizable Area Start
// Customizable Area End