// Customizable Area Start
import React from 'react'
import { Container, Typography, Box, styled } from '@material-ui/core'
import ExploreLessonController, { Props } from './KeepTrackQuestionController'
import {
  ButtonCustom,
  SecondaryButtonCustom,
} from '../../../components/src/MaterialTheme'
import SelectLanguage from '../../../components/src/SelectLanguage'
import Header from '../../../components/src/Header'
import { imgNerdTeddy } from './assets'
import { withTranslation } from 'react-i18next'

 class KeepTrackQuestion extends ExploreLessonController {
  constructor(props: Props) {
    super(props)
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  handleContinue = () => {
    this.props.navigation.navigate('GuestExercise', {
      subCategoryId: this.state.subCategoryId,
      lessonId: this.state.lessonId,
    })
  }

  render() {
    return (
      <KeepTrackQuestionContainer> 
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <div />
          <Box
            marginTop="24px"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography className="keepTrackQuestionTitle">
              {`${this.state.subCategory} ${this.props.t('progress made easy!')}`}
            </Typography>
            <Typography className="keepTrackQuestionDesc">
              {this.props.t("Want to track your journey with Tedix?")}
            </Typography>
            <img src={imgNerdTeddy} className="nerdTeddyImg" alt="Nerd Teddy" />
            <ButtonCustom
              variant="contained"
              className="signupAndTrack"
              onClick={() =>
                this.props.navigation.navigate('EmailRegistrationWeb')
              }
            >
              {this.props.t("Sign up & Track")}
            </ButtonCustom>
            <SecondaryButtonCustom
              variant="contained"
              className="continueWithoutAccount"
              style={{ marginTop: '16px' }}
              onClick={this.handleContinue}
            >
              {this.props.t("Continue without account")}
            </SecondaryButtonCustom>
          </Box>
          <SelectLanguage style={{ marginBottom: '72px', marginTop: '24px' }} />
        </Container>
      </KeepTrackQuestionContainer>
    )
  }
}


export default withTranslation()(KeepTrackQuestion)

const KeepTrackQuestionContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .contentContainerBlur': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    filter: 'blur(4px)'
  },

  '& .keepTrackQuestionTitle': {
    fontSize: '22px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    fontWeight: '900',
    color: '#363636',
  },

  '& .keepTrackQuestionDesc': {
    color: '#666666',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    marginTop: '16px',
    textAlign: 'center',
  },

  '& .nerdTeddyImg': {
    width: '164px',
    height: 'auto',
    marginTop: '88px',
    marginBottom: '88px',
  },

  [theme.breakpoints.up('md')]: {
    '& .keepTrackQuestionTitle': {
      fontSize: '24px',
    },

    '& .keepTrackQuestionDesc': {
      fontSize: '20px',
    },
  },
}))
// Customizable Area End
