// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { sendAPIRequest } from "../../../components/src/utils";

export interface Props {
    t?: any;
    navigation?: any;
    currentdailyGoal: string;
    currentStreakGoal: string;
    goalProgress: {
        medal: number;
        dailyGoalAchieve: boolean;
        streakGoalDates: Array<string>;
        streakGoalAchieve: boolean;
    }
    dailyGoalSettings: any;
    streakGoalSettings: any;
    todayCompletedLessonCounts: number;
    lessonName: string
    lessonId: string
}

interface StreakGoalItem {
    option: string
    optionData: string
    day: number
}

interface S {
    progressUpdateOpen: boolean;
    dailyGoalCompleteOpen: boolean;
    medalAchieveOpen: boolean;
    currentDailyGoalSetting: number;
    currentStreakGoalSettingData: StreakGoalItem
}

interface SS {

}

class ProgressUpdateController extends BlockComponent<Props, S, SS> {
    updateStreakGoalId: string = ''

    constructor(props: Props) {
        super(props);

        this.state = {
            progressUpdateOpen: true,
            dailyGoalCompleteOpen: false,
            medalAchieveOpen: false,
            currentDailyGoalSetting: 1,
            currentStreakGoalSettingData: this.streakGoalData[0]
        }
    }

    async componentDidMount() {
        const { currentdailyGoal, goalProgress, dailyGoalSettings, streakGoalSettings, todayCompletedLessonCounts, currentStreakGoal } = this.props
        const currentStreakGoalData = this.streakGoalData.find(item => item.optionData === currentStreakGoal)
        this.setState({
            currentDailyGoalSetting: dailyGoalSettings[currentdailyGoal],
            currentStreakGoalSettingData: currentStreakGoalData || this.streakGoalData[0]
        })
    }

    streakGoalData = [
        {
            option: this.props.t('Easy'),
            optionData: "easy",
            day: 2,
        },
        {
            option: this.props.t('Dedicated'),
            optionData: "dedicated",
            day: 4,
        },
        {
            option: this.props.t('Super dedicated'),
            optionData: "super_dedicated",
            day: 7,
        },
        {
            option: this.props.t('Wow! look at you'),
            optionData: "wow_look_at_you",
            day: 10,
        },
    ]

    handleContinueProgressUpdate = () => {
        if (
            this.props.goalProgress.dailyGoalAchieve &&
            this.state.currentDailyGoalSetting === this.props.todayCompletedLessonCounts) 
        {
            this.setState({
                progressUpdateOpen: false,
                dailyGoalCompleteOpen: true,
                medalAchieveOpen: false
            })
        } else if (this.props.goalProgress.streakGoalAchieve) {
            this.setState({
                progressUpdateOpen: false,
                dailyGoalCompleteOpen: false,
                medalAchieveOpen: true
            })
        } else {
            this.props.navigation.navigate('lessonSummary', {
                lessonName: this.props.lessonName, 
                lessonId: this.props.lessonId
            })
        }
    }

    handleContinueDailyGoal = () => {
        if (this.props.goalProgress.streakGoalAchieve) {
            this.setState({
                progressUpdateOpen: false,
                dailyGoalCompleteOpen: false,
                medalAchieveOpen: true
            })
        } else {
            this.props.navigation.navigate('lessonSummary', {
                lessonName: this.props.lessonName, 
                lessonId: this.props.lessonId
            })
        }
    }

    handleContinueMedalAchieve = () => {
        this.props.navigation.navigate('lessonSummary', {
            lessonName: this.props.lessonName, 
            lessonId: this.props.lessonId
        })
    }

    updateStreakGoal = (option: StreakGoalItem) => {
        this.updateStreakGoalId = sendAPIRequest(
            "bx_block_profile/profiles/update_user_profile",
            {
              method: "PUT",
              headers: {
                token: localStorage.getItem('token'),
                "Content-Type": "application/json"
              },
              body: {
                streak_goal: option.optionData,
              }
            }
        )
    }
}

export default ProgressUpdateController
// Customizable Area End