import React from "react"
// Customizable Area Start
import {Box,styled} from "@material-ui/core";
import { loader } from "./assets";
import { withTranslation } from "react-i18next";
import GradeListController from "./GradeListController.web";
//@ts-ignore
import Graph from "react-graph-vis";
// Customizable Area End

class GradeList extends GradeListController  {
 
    // Customizable Area Start
    // Customizable Area End

  // Customizable Area Start
   options = {
    autoResize: true,
    physics: {
      enabled: true,
      solver: "forceAtlas2Based",    
      forceAtlas2Based: {
        gravitationalConstant: -50,
        centralGravity: 0.004,
        springLength: 200,
        springConstant: 0.09,
        damping: 0.4,
        avoidOverlap: 1,
      },
      repulsion: {
        nodeDistance: 300,
        centralGravity: 0.1,
        springLength: 300,
        springConstant: 0.05,
        damping: 0.09,
      },
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 25,
      },
    },
    nodes: {
      borderWidth:0,
      widthConstraint:false,
      shapeProperties: {
        interpolation:false,
        useImageSize: false,
        borderRadius:0,
        useBorderWithImage:true
      },
      font: {
        multi: true,
        size: 14,
         face: 'Quicksand',
        color:'#666',
        bold: {
          color: "#666"
        }
      },
      
    },

    layout: {
      randomSeed: undefined,
      improvedLayout: true,
    },
    edges: {
      arrows: {
        to: {
          enabled: false,
          imageHeight: undefined,
          imageWidth: undefined,
          scaleFactor: 1,
          src: undefined,
          type: "arrow"
        },
      },
    
      color: {
        color:'#848484',
        border:'unset',
        highlight:'#848484',
        background:'unset',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
       length: 200,
    },
    interaction: {
      dragNodes: true,
        zoomView: false,
        dragView: false,
    },
  };
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
        // Customizable Area Start
        <Box style={{  width: '100%', height: '100%', backgroundColor: '#fff',}}>
         {this.state.loading?  <LoadingContainer >
      <img src={loader} alt="Loading" />
      </LoadingContainer>:
        <Graph
        data-testId= "selected_graph"
        graph={this.state.graph}
        events={{ select: this.openCategories }}
        options={this.options}
        style={{ height: "100vh", width: '100%', overflow: 'auto' }}
      />}
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withTranslation()(GradeList)
const LoadingContainer=styled(Box)(({theme})=>({
  alignItems: 'center', 
  justifyContent: 'center', 
  height: '100%', 
  display: 'flex', 
  width: "100%",
   "@media(max-width: 959px)": {
    width: '100%', 
    marginLeft:0
   }

}))
// Customizable Area End


