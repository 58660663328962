import React, { useEffect } from 'react';
//@ts-ignore
import { GoogleLogin } from "react-google-login";
import {gapi} from "gapi-script"
import { useTranslation } from 'react-i18next';
interface Response {
  /** This field is the returned ID token */
  credential?: string;
  clientId?: string;
  /** This field sets how the credential is selected */
  select_by?: 'auto' | 'user' | 'user_1tap' | 'user_2tap' | 'btn' | 'btn_confirm' | 'brn_add_session' | 'btn_confirm_add_session';
}
interface Props {
  handleGoogleLogin: (res?: string) => void;
  token?: (token: {profileObj:{email:string},googleId:string}) => void
}

const GoogleLoginComponent = (props: Props) => {
  const {t} = useTranslation()
  useEffect(() => {
    // Initialize gapi when the component mounts
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: '494702856114-pfd9vabhflt32hmnr18j0p6kup1if4qi.apps.googleusercontent.com', // Replace with your Google OAuth client ID
      });
    });
  }, []);

  function responseGoogle(response: any) {
    if(props.token){
      props.token(response)
    }
    props.handleGoogleLogin(response.credential)
  }
  return (
    <GoogleLogin
    clientId="494702856114-pfd9vabhflt32hmnr18j0p6kup1if4qi.apps.googleusercontent.com"
    buttonText={t("Sign in with Google")}
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
    prompt="select_account"
  />
  )
}

export default GoogleLoginComponent