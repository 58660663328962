import React,{useEffect} from 'react'
import { Box, Typography, makeStyles, styled, Theme } from '@material-ui/core'
import MathJax from 'react-mathjax'
import { convertLatex } from '../utils'
import { useTranslation } from 'react-i18next'
import html2canvas from 'html2canvas'

export const mathJaxOptions = {
  CommonHTML: {
    linebreaks: { automatic: true },
  },
  'HTML-CSS': {
    linebreaks: { automatic: true },
  },
  SVG: {
    linebreaks: { automatic: true },
  },
}

export const handleSaveImage = () => {
  const element = document.getElementById("img-for-share");
  if (element) {
    html2canvas(element)
      .then ((canvas: any) => {
        const imgData = canvas.toDataURL("img/png");

        const link = document.createElement("a");
        link.href = imgData;
        link.download = "tedix.png";
        link.click();
      })
      .catch (err => {
        console.log('Share error!', err)
      })
  }
}

interface QuestionTextProps {
  question: string
  width: number
  isSharing?: boolean
}

export const QuestionText = ({ question, width, isSharing }: QuestionTextProps) => {
  return (
    <QuestionTextContainer>
      <MathFormula 
        formula={convertLatex(question)}
        color='#363636'
        fontFamily='Quicksand'
        fontSize={isSharing ? 14 : 20}
        fontWeight={800}
        lineHeight={isSharing ? '6px' : '16px'}
        width={width}
      />
    </QuestionTextContainer>
  )
}

const QuestionTextContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '48px',
}))

interface HintTextProps {
  hint: string
  width: number
}

export const HintText = ({ hint, width }: HintTextProps) => {
  const  { t } = useTranslation()
  return (
    <HintTextContainer>
      <span className="hintTileText">{t("Hint")}: </span>
      <MathFormula 
        formula={convertLatex(hint)}
        color='#00AEFF'
        fontFamily='Quicksand'
        fontSize={18}
        fontWeight={600}
        width={width}
      />
    </HintTextContainer>
  )
}

const HintTextContainer = styled(Typography)(({ theme }) => ({
  '& .hintTileText': {
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '900',
    color: '#00AEFF',
  },
}))

declare global {
  interface Window {
    MathJax: any; 
  }
}
interface MathFormulaStyleProps {
  color: string
  fontSize: number
  fontWeight: number
  fontFamily: string
  lineHeight?: string
  width?: number
}

interface MathFormulaProps extends MathFormulaStyleProps {
  formula: string
  inline?: boolean
  className?: string
}

export const MathFormula = ({
  formula,
  color = '#363636',
  fontFamily = 'Quicksand',
  fontSize = 20,
  fontWeight = 800,
  lineHeight = '8px',
  className = '',
  inline = false,
  width
}: MathFormulaProps) => {
  const classes = useStyles({
    color,
    fontSize,
    fontWeight,
    fontFamily,
    lineHeight,
    width
  })

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]);
    }
  }, [formula]);

  return (
    <MathJax.Provider options={mathJaxOptions}>
      <Box className={`${classes.mathFormula} ${className}`}>
        <MathJax.Node key={formula} inline={inline} formula={formula} />
      </Box>
    </MathJax.Provider>
  )
}

const useStyles = makeStyles<Theme, MathFormulaStyleProps>(() => ({
  mathFormula: {
    maxWidth: '100%',
    fontSize: ({ fontSize }) => `${(fontSize * 100) / 118}px`,

    '& .MathJax_CHTML, & .MJXc-display': {
      color: ({ color }) => color,
      fontSize: ({ fontSize }) => `${(fontSize * 100) / 118}px`,
      margin: 0,
      fontWeight: '500',
      textAlign: 'left !important'
    },

    '& .mjx-char': {
      fontWeight: ({ fontWeight }) => fontWeight,
    },

    '& .MJXc-TeX-math-I': {
      fontFamily: ({ fontFamily }) => `${fontFamily} !important`,
      fontWeight: ({ fontWeight }) => fontWeight,
    },

    '& .mjx-mn': {
      '& .MJXc-TeX-main-R': {
        fontFamily: ({ fontFamily }) => `${fontFamily} !important`,
        fontWeight: ({ fontWeight }) => fontWeight,
      },
    },

    '& .mjx-mtext': {
      '& .MJXc-TeX-main-R, & .MJXc-TeX-unknown-R': {
        fontFamily: ({ fontFamily }) => `${fontFamily} !important`,
        fontWeight: ({ fontWeight }) => fontWeight,
        lineHeight: ({ lineHeight }) => `${lineHeight}`,
      },
    },

    '& .mjx-chtml': {
      whiteSpace: "normal",
    },

    '& [id^="MathJax-Element-"]': {
      minWidth: 'unset !important',
    },

    '& .mjx-stack': {
      width: ({ width }) =>  `${width}px !important`
    },

    '& .MJXc-stacked': {
      whiteSpace: "nowrap",
    },

    '& .mjx-block': {
      display: 'block',
      fontWeight: '500',
      textAlign: 'left !important'
    }
  },
}))
