import { MenuItem, Select, Typography, styled } from '@material-ui/core'
import React, { useState , useEffect } from 'react'
//@ts-ignore
import Cookies from "js-cookie"
//@ts-ignore
import { baseURL } from "../../framework/src/config.js"
import { KeyboardArrowDown } from '@material-ui/icons'
import {
  imgEnglishFlag,
  imgGermanFlag,
  imgHungarianFlag,
  imgRomanianFlag,
} from './assets'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

interface SelectLanguageProps {
  style?: React.CSSProperties
}

interface LanguageType {
  id: string
  type: string
  attributes: {
    id: number
    name: 'English' | 'German' | 'Hungarian' | 'Romanian'
    language_code: string
    is_content_language: string
    is_app_language: string
    emoji_flag: string
    created_at: string
    updated_at: string
  }
}

interface LanguageResponse{
  data : Array<LanguageType>
}

const SelectLanguage = ({ style }: SelectLanguageProps) => {

  const JsonData = Cookies.get('webAppLanguage') || "{}"
  const LanguageCookie = JSON.parse(JsonData)
  const language = LanguageCookie?.name || 'Romanian'

  const [value, setValue] = useState(language)
  const { t } = useTranslation()
  const [languageList,setLanguageList] = useState<LanguageType[]>([])

  useEffect(()=>{
    getAppLanguageList()
  },[])

  const LanguageImageOption ={
    English: imgEnglishFlag,
    German: imgGermanFlag,
    Hungarian: imgHungarianFlag,
    Romanian: imgRomanianFlag
  }

  const getAppLanguageList = async() => {
      const responseJson:any = await fetch(`${baseURL}/bx_block_language_options/languages`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      const response: any = await responseJson.json()
      if(response.data){
         if(Cookies.get('webAppLanguage')===undefined){
           const RomanianObject =  response.data.find((item:LanguageType)=>item.attributes.name=='Romanian')
           Cookies.set("webAppLanguage", JSON.stringify({name:RomanianObject.attributes.name, id:RomanianObject.id }))
         }
      setLanguageList(response?.data)
      }
  }

  const setLanguageData=(language:LanguageType)=>{
    setValue(language.attributes.name as string)
      Cookies.set("webAppLanguage", JSON.stringify({name:language.attributes.name, id:language.id }))
    i18next.changeLanguage(language.attributes.name as string)
  }

  return (
    <SelectWrapper
      disableUnderline
      style={style}
      displayEmpty
      name="select"
      value={value}
      IconComponent={KeyboardArrowDown}
    >
      {
        languageList.length >0 ? languageList.map((item:LanguageType)=>{
           return(
            <MenuItem value={item.attributes.name} onClick={()=>setLanguageData(item)}key={item.id}style={{}}>
            <img
              src={LanguageImageOption[item.attributes.name]}
              style={{ marginRight: '12px' }}
              alt="english"
              className="flagItem"
            />
            <ItemText>{item.attributes.name}</ItemText>
          </MenuItem>
           )
        }):
        <Typography>{t("No Result found")}</Typography>
        
    
      }
    </SelectWrapper>
  )
}

const SelectWrapper = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .MuiInputBase-input:focus': {
    backgroundColor: '#fff',
  },

  '& .MuiSelect-icon': {
    color: '#000',
  },

  '& .flagItem': {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
  },
}))

const ItemText = styled('p')({
  color: '#363636',
  fontFamily: 'Quicksand',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '13px',
})

export default SelectLanguage
