import { Box, Typography, styled } from "@material-ui/core";
import React from "react";
import { StyledButton } from "../../../blocks/educationalgamification/src/MicroLessonExercise";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

interface MicroSummaryProps {
  onClose: () => void;
  carouselData: Array<{
    src: string,
    alt: string
  }>
}
interface CustomIndicatorProps {
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const MicroSummary: React.FC<MicroSummaryProps> = ({ onClose , carouselData }) => {
  const { t } = useTranslation();

  const CustomIndicator: React.FC<CustomIndicatorProps> = ({
    isSelected,
    onClick,
  }) => (
    <div
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        background: isSelected ? "#363636" : "#E6E6E6",
        margin: "0 5px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
  
  return (
    <MicroSummaryContainer>
      <Box className="microSummaryContainer">
        <Typography className="summaryTitleText">
          {t("Microsummary")} :
        </Typography>
      </Box>
      <Box className="mainSummaryContent">
        {carouselData.length === 0 ? (
          <Typography className="noContentText">
            There is no summary content
          </Typography>
        ) : (
          <Carousel
            showThumbs={false}
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected) => (
              <CustomIndicator
                isSelected={isSelected}
                onClick={(e: any) => onClickHandler(e)}
              />
            )}
          >
             {carouselData.map((item: {src: string, alt: string}) => (
              <div key={item.src} className="imageWrapper" style={{backgroundImage: `url("${item.src}")`}} />
            ))}
          </Carousel>
        )}
      </Box>
      <StyledButton onClick={onClose}>{t("Close")}</StyledButton>
    </MicroSummaryContainer>
  );
};

const MicroSummaryContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: '100%',
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px 26px 90px",
  backgroundColor: "#c9eeff",
  borderRadius: "20px 20px 0 0",
  position: 'absolute',
  zIndex: 10,

  "& .microSummaryContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "22px",
  },
  "& .carousel": {
    height: '100%',
  },
  "& .carousel .control-dots": {
    display: "flex",
    justifyContent: "center",
  },
  "& .carousel .slider-wrapper": {
    height: '100%',

    '& .slider': {
      height: '100%',
    }
  },
  "& .carousel .slider-wrapper.axis-horizontal .slider .slide": {
    padding: "25px",
    "& div": {
      borderRadius: "8px",
      border: "2px solid #E6E6E6",
    },
  },

  '& .imageWrapper': {
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },

  "& .summaryTitleText": {
    fontFamily: "Nunito",
    fontWeight: "900",
    fontSize: "20px",
    color: "#00AEFF",
  },

  "& .mainSummaryContent": {
    flex: 1,
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "20px 20px 0 0",
    marginBottom: "20px",
    height: "calc(100vh - 332px)",
  },

  "& .noContentText": {
    marginTop: '20px', 
    marginLeft: '20px', 
    fontFamily: 'Quicksand', 
    fontSize: '18px', 
    color: '#363636', 
    fontWeight: 500
  },

  [theme.breakpoints.up("md")]: {
    padding: "32px 60px 40px",
  },
}));

export default MicroSummary;
