// Customizable Area Start

import React from 'react';
import { Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Typography, TableCellProps } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import ProjectReportingController, {
    Props,
} from "./ProjectReportingController.web";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import { Formik, FormikTouched, FormikErrors } from "formik";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CSVLink } from 'react-csv';
class ProjectReporting extends ProjectReportingController {
    constructor(props: Props) {
        super(props);
    }
    questionSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required('Project name is required'),
            client_id: Yup.string()
                .required('Client name is required'),
            start_date: Yup.string()
                .required('Start date is required'),
            end_date: Yup.string()
                .required('End date is required')
        });
    }
    renderTable = () => {
        return (
            <Paper className='table_data'>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {this.state.columns.map((column: {
                                    columnId: string;
                                    label?: string;
                                    minWidth?: number;
                                    align?: TableCellProps['align'];
                                }) => (
                                    <TableCell
                                        key={column.columnId}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        className="table_head"
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((
                                rowItem: {
                                    name: string;
                                    start_date: string;
                                    end_date: string;
                                    manager_id: string;
                                    co_manager_id: string;
                                    client_id: string;
                                }, index: number) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {this.state.columns.map((column: {
                                            columnId: string;
                                            label?: string;
                                            minWidth?: number;
                                            align?: TableCellProps['align'];
                                        }) => {

                                            const value = rowItem[column.columnId as keyof typeof rowItem];
                                            return (
                                                <>
                                                    <TableCell key={column.columnId} align={column.align}>
                                                        {value}
                                                    </TableCell>
                                                </>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component='div'
                    count={this.state.rows.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
            </Paper >
        )
    }
    getErrorMessage = (touched: FormikTouched<{ name: string; client_id: string; start_date: string; end_date: string; }>, errors: FormikErrors<{ name: string; client_id: string; start_date: string; end_date: string; }>, value: string) => {
        return touched[value as keyof typeof touched] && errors[value as keyof typeof errors] &&
            <div className="text-danger">{errors[value as keyof typeof errors]}</div>
    }

    getOptions = (data: {
        label: string;
        value: string;
    }[]) => {
        return (data.map((item: {
            label: string;
            value: string;
        }, index: number) => {
            return (<MenuItem value={item.value} key={index}>{item.label}</MenuItem>)
        }))
    };

    communSelector = (label: string, SelectId: string, data: {
        label: string;
        value: string;
    }[]) => {
        return (<FormControl style={{ width: '100%' }}>
            <InputLabel >{label}</InputLabel>
            <Select
                inputProps={{
                    'data-test-id': { SelectId }
                }}
                onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => this.handleChange(event.target)}
                name={SelectId}
            >
                {this.getOptions(data)}
            </Select>
        </FormControl>)
    }

    createFormDilog = () => {
        return (
            <>
                <Grid className='Project_reporting_header'>
                    <Grid item xs={6}>
                        <Typography variant="h4">Project Reporting Web</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'end' }}>
                        <Button data-test-id='csv-donwload' variant="outlined" color="primary" onClick={() => { this.downloadCsvFile() }}>
                            <CSVLink data={this.state.csvData} color="primary" style={{ display: 'flex' }}>
                                <GetAppIcon style={{ margin: 'auto 4px' }} /> Download
                            </CSVLink>
                        </Button>
                        <Button data-test-id='Create-Project' variant="contained" color="primary" className='create_button' onClick={this.handleClickOpen}>
                            Create Project
                        </Button>
                    </Grid>
                </Grid>
                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" className='dialog_title'>create Project Details</DialogTitle>
                    <Formik
                        initialValues={{
                            name: '',
                            client_id: '',
                            start_date: '',
                            end_date: ''
                        }}
                        validationSchema={this.questionSchema}
                        onSubmit={this.handleSubmitData}
                    >
                        {({ errors, touched, setFieldValue, handleSubmit }) => (
                            <form
                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                <DialogContent>
                                    <TextField
                                        inputProps={{
                                            'data-test-id': "name"
                                        }}
                                        autoFocus
                                        margin="dense"
                                        name="name"
                                        label="Project Name"
                                        type="text"
                                        fullWidth
                                        onChange={(event) => {
                                            this.handleOnchange('name', event, setFieldValue)
                                        }}
                                        value={this.state.tempObj.name}
                                    />

                                    {this.getErrorMessage(touched, errors, "name")}
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label">client id</InputLabel>
                                        <Select
                                            inputProps={{
                                                'data-test-id': "client"
                                            }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            fullWidth
                                            onChange={(event: React.ChangeEvent<{ name?: string, value: unknown }>, child: React.ReactNode) => {
                                                this.handleOnchange('client_id', event, setFieldValue);
                                            }}
                                            name="client_id"
                                        >
                                            {this.getOptions(this.state.client)}
                                        </Select>
                                        {this.getErrorMessage(touched, errors, "client_id")}
                                    </FormControl>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            inputProps={{
                                                'data-test-id': "startDate"
                                            }}
                                            name='start_date'
                                            onFocus={this.startDateFocus}
                                            onBlur={this.startDateBlur}
                                            fullWidth
                                            onChange={(event) => {
                                                setFieldValue("start_date", event.target.value);
                                                this.startDateChange(event)
                                            }}
                                            label="Start Date"
                                            value={this.state.tempObj.start_date}
                                            type={this.state.startHasValue || this.state.startDateFocus ? "date" : "text"}
                                        />
                                        {this.getErrorMessage(touched, errors, "start_date")}
                                    </FormControl>
                                    <FormControl style={{ width: '100%' }}>
                                        <TextField
                                            name="end_date"
                                            onFocus={this.endDateFocus}
                                            onBlur={this.endDateBlur}
                                            fullWidth
                                            onChange={(event) => {
                                                setFieldValue("end_date", event.target.value);
                                                this.endDateChange(event)
                                            }}
                                            inputProps={{
                                                'data-test-id': "endDate"
                                            }}
                                            label="End Date"
                                            type={this.state.endHasValue || this.state.endDateFocus ? "date" : "text"}
                                            value={this.state.tempObj.end_date}
                                        />
                                        {this.getErrorMessage(touched, errors, "end_date")}
                                    </FormControl>
                                    {this.communSelector("Manager", "manager_id", this.state.managerList)}
                                    {this.communSelector("Co-Manager", "co_manager_id", this.state.coManagerList)}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.handleClose} variant="contained" color="secondary">
                                        Cancel
                                    </Button>
                                    <Button type='submit' variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                </Dialog>
            </>
        );
    }

    render() {
        return (
            <ProjectReportStyle>
                {this.createFormDilog()}
                {this.renderTable()}
            </ProjectReportStyle>
        );
    }
}
export default ProjectReporting;

const ProjectReportStyle = styled("div")({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    "& .Project_reporting_header": {
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        padding: '15px 15px'
    },
    "& .create_button": {
        margin: '3% 2%',
    },
    "& .dialog_title": {
        textAlign: 'center'
    },

    "& .table_data": {
        width: '80%',
    },
    "& .table_head": {
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 'bold'
    },
    "& .edit_button": {
        margin: '4px'
    },
    "& .delete_button": {
        margin: '4px'
    },
    "& .edit_icon": {
        color: '#303f9f',
    },
    "& .delete_icon": {
        color: 'red',
    }
})
  // Customizable Area End
// Customizable Area End
