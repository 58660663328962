import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import {
  orangeViewRectangle,
  greyCloseButton,
  exerciseImg,
  bolt,
} from '../../../blocks/cftableforlessons/src/assets'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  challengeCelebration,
  cloundLeft,
  cloundRight,
  flagHeader,
  purpleRectangle,
  teddyBlueCelebrate,
  teddyBrownCelebrate,
  teddyGreenCelebrate,
  teddyLightGreenCelebrate,
  teddyOrangeCelebrate,
  teddyPurpleCelebrate,
  teddyRedCelebrate,
  teddyYellowCelebrate,
} from '../../../blocks/assessmenttest/src/assets'
import { useTranslation } from 'react-i18next'

interface ChallengeSelebrationProps {
  onClickContinue: ({
    isClickFromCelebrate,
  }: {
    isClickFromCelebrate?: boolean
  }) => void
  totalXP: number
  onGoBack: () => void
  teddyColor: string
}

const ChallengeSelebration: React.FC<ChallengeSelebrationProps> = ({
  onClickContinue,
  totalXP,
  onGoBack,
  teddyColor,
}) => {
  const userName = localStorage.getItem('displayName') || 'Amy'
  const { t } = useTranslation()

  const colorToUpdateTeddy: {[key: string]: string} = {
    '#ff8400': teddyOrangeCelebrate,
    '#62cdff': teddyBlueCelebrate,
    '#b7f0ad': teddyLightGreenCelebrate,
    '#a95edb': teddyPurpleCelebrate,
    '#f96855': teddyRedCelebrate,
    '#884a39': teddyBrownCelebrate,
    '#fdce36': teddyYellowCelebrate,
    '#2bce8c': teddyGreenCelebrate,
  }

  return (
    <ChallengeSelebrationContainer>
      <img src={colorToUpdateTeddy[teddyColor] || colorToUpdateTeddy['#62cdff']} className="teddyBear" />
      <img src={flagHeader} className="flagIcon" />
      <img src={cloundLeft} className="cloundLeft" />
      <img src={cloundRight} className="cloundRight" />
      <Box className="headerWrapper">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <img src={greyCloseButton} width={14} height={14} onClick={onGoBack} />
          <div className="brainImgMain">
            <img src={exerciseImg} width={16} height={16} />
          </div>
          <Typography className="headerText">{t('CHALLENGE')}</Typography>
        </Box>
        <Box className="boltCountMain">
          <img src={bolt} width={14.12} height={19.16} />
          <Typography className="number">{totalXP}</Typography>
        </Box>
      </Box>
      <Box
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <Box className="titleWrapper">
          <Typography className="titleText" style={{ fontSize: '20px' }}>
            {t('FANTASTIC')}
          </Typography>
          <Typography className="titleText" style={{ fontSize: '30px' }}>
            {t('BEGINNING')}!
          </Typography>
        </Box>
        <Box style={{ display: 'flex' }}>
          <Box style={{ position: 'relative' }}>
            <img src={purpleRectangle} width={180} height={55} />
            <Box className="textWrapper">
              <Typography className="styledText" style={{ color: '#A95EDB' }}>
                {t('Congratulations!')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="downContainer">
          <Box style={{ position: 'relative' }}>
            <img src={orangeViewRectangle} width={180} height="auto" />
            <Box className="textWrapper">
              <Typography className="styledText" style={{ color: '#FF8400' }}>
              {t(`Great job,`)}{' '}{userName}!
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="buttonContainer">
          <StyledButton
            onClick={() => onClickContinue({ isClickFromCelebrate: true })}
          >
            {t('Continue')}
          </StyledButton>
        </Box>
      </Box>
    </ChallengeSelebrationContainer>
  )
}

const ChallengeSelebrationContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundSize: '100% 100%',
  backgroundImage: `url(${challengeCelebration})`,
  position: 'relative',

  '& .teddyBear': {
    width: '102px',
    height: '100px',
    position: 'absolute',
    bottom: '55.5%',
    left: '12%',
    transform: 'translate(-50%, 0%)',
  },

  '& .flagIcon': {
    width: '39px',
    height: '44px',
    position: 'absolute',
    bottom: '71.5%',
    right: '9%',
    transform: 'translate(50%, 0%)',
    zIndex: 10,
  },

  '& .cloundLeft': {
    width: '130px',
    height: '106px',
    position: 'absolute',
    top: '14%',
    left: '20%',
    transform: 'translate(-50%, 0%)',
  },

  '& .cloundRight': {
    width: '130px',
    height: '106px',
    position: 'absolute',
    bottom: '74%',
    right: '20%',
    transform: 'translate(50%, 0%)',
  },

  '& .headerWrapper': {
    width: '100%',
    height: '146px',
    backgroundSize: '100% 100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '26px',
    paddingRight: '26px',
    justifyContent: 'space-between',
    zIndex: 11,
  },

  '& .headerText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    fontSize: '16px',
    color: '#2BCE8C',
  },

  '& .buttonContainer': {
    padding: '32px 26px 40px',
    display: 'flex',
    flexDirection: 'column-reverse',
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: '20px 20px 0 0',
  },

  '& .titleWrapper': {
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '64px',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#fff',
    textTransform: 'capitalize',
  },

  '& .textWrapper': {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
  },

  '& .styledText': {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },

  '& .downContainer': {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '62px',
  },

  [theme.breakpoints.up('md')]: {
    '& .buttonContainer': {
      padding: '32px 60px 40px',
    },
  },
}))

export default ChallengeSelebration
