import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Box, IconButton, InputAdornment, Popover, TextField, Typography, styled } from '@material-ui/core'
import {  CheckCircle, Search } from '@material-ui/icons'
import { imgDropDown, skipCategory } from './assets'
import  {useTranslation} from "react-i18next"

interface CustomSelectProps {
    list: Array<string>
    value: string
    setValue: (item: string) => void
    setId?:(item:number)=>void
    placeholder?: string
    showSearchInput?: boolean
    disabledItemList?:Array<string>
    showCategoriesIcon?: boolean
    isError?: boolean
    style?: any
    disableSelect?: boolean
}

const CustomSelect = ({
  list,
  value,
  setValue,
  placeholder,
  showSearchInput,
  disabledItemList,
  showCategoriesIcon,
  isError,
  style,
  disableSelect 
} : CustomSelectProps) => {
  const customList = useMemo(() => {
    return [...new Set(list)]
  }, [list])
  
  const  { t } = useTranslation()
  const [listData, setListData] = useState(customList)

  const handleSearch = (searchTerm: string) => {
    const filtered = customList.filter((item) =>
      item.toLowerCase().includes(searchTerm.trim().toLowerCase())
    )
    setListData(filtered)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [highlightIndex, setHighlightIndex] = useState<number>(0)
  const customSelectRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLUListElement>(null)

  const handleClick = (event: any) => {
    !disableSelect && setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const getDisabeld=(value:string)=>{
   const name = disabledItemList?.find(item=>item===value)
   if(name){
    return "disabled"
   }
   return ""
  }

  useEffect(() => {
    if (!open) {
      setListData(customList)
    }
  }, [open, customList])

  const handleOptionClick = (option: string) => {
    if(getDisabeld(option)===""){
    setHighlightIndex(customList.indexOf(option));
    setListData(customList)
    setValue(option);
    handleClose();
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.persist()
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightIndex((prevIndex) => {
        const newIndex = e.key === 'ArrowDown'
          ? (prevIndex + 1) % listData.length
          : (prevIndex - 1 + listData.length) % listData.length;
        
        if (listRef.current) {
          const itemHeight = listRef.current.scrollHeight / listData.length;
          const scrollTop = itemHeight * newIndex;
          listRef.current.scrollTop = scrollTop - itemHeight * 3;
        }

        return newIndex;
      });
    } else if (e.key === 'Enter' && highlightIndex !== -1) {
      const selectedValue = listData[highlightIndex];
      handleOptionClick(selectedValue);
    }
  }

  const refFunc = () => {
    if (listRef.current) {
      const itemHeight = listRef.current.scrollHeight / listData.length;
      const scrollTop = itemHeight * highlightIndex;
      listRef.current.scrollTop = scrollTop - itemHeight * 2;
    }
  }

  useEffect(() => {
    setHighlightIndex(customList.indexOf(value))
    if (open && value !== '' && customList.length > 0) {
      setTimeout(() => {
        refFunc()
      }, 0)
    }
  }, [open, value, customList.length])

  return (
    <StyledContainer {...{ ref: customSelectRef }} tabIndex={0} onKeyDown={handleKeyDown}>
      <TextInputCustomSelect
        aria-describedby={id}
        value={value}
        autoComplete="off"
        onClick={handleClick}
        placeholder={placeholder}
        variant="outlined"
        error={isError}
        style={style}
        className={disableSelect ? 'disableSelect' : ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled edge="end">
                <img src={imgDropDown} width={18} height={18} />
              </IconButton>
            </InputAdornment>
          ),
          startAdornment: showCategoriesIcon ? (
            <InputAdornment position="start">
              <IconButton disabled edge="start">
                <img src={skipCategory} width={18} height={18} />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />

      <PopoverStyled
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CustomSelectWrapper>
          {showSearchInput && (
            <Typography
              style={{ padding: '15px 15px 0px 15px', marginBottom: '6px' }}
            >
              <TextField
                className="search-field"
                name="Search"
                id="search"
                placeholder={t('Search')}
                fullWidth
                onChange={(e) => handleSearch(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" disablePointerEvents>
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </Typography>
          )}
          <ul className="itemWrapper" ref={listRef}>
            {listData.length === 0 ? (
              <li className="item">{t('No result found!')}</li>
            ) : (
              listData.map((item, index) => {
                return (
                  <li
                    key={item}
                    className={`item ${getDisabeld(item)} ${index === highlightIndex ? 'highlight' : ''}`}
                    onClick={() => handleOptionClick(item)}
                  >
                    {item}
                    {value === item && (
                      <CheckCircle
                        style={{
                          width: '18px',
                          height: '18px',
                          color: '#62CDFF',
                        }}
                      />
                    )}
                  </li>
                )
              })
            )}
          </ul>
        </CustomSelectWrapper>
      </PopoverStyled>
    </StyledContainer>
  )
}

export const StyledContainer = styled('div')({
  '& .disableSelect': {
    '&:hover fieldset': {
      borderColor: '#e6e6e6!important',
    },
    '& .Mui-focused fieldset': {
      borderColor: '#e6e6e6!important',
    },
    '& .MuiOutlinedInput-root': {
      color: '#999999',
      cursor: 'default'
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'default',
    },
}
})

export const TextInputCustomSelect = styled(TextField)(({ theme }) => ({
    caretColor: 'transparent',
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '300px',
    },
    '& fieldset': {
      borderColor: '#e6e6e6',
      borderWidth: '2px',
    },
    '&:hover fieldset': {
      borderWidth: '2px',
      borderColor: '#363636!important',
    },
    '& .Mui-focused fieldset': {
      borderColor: '#363636!important',
    },
    '& .Mui-error fieldset': {
      borderColor: '#F96855!important',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#f7f7f7',
      borderRadius: '10px',
      fontSize: '16px',
      fontFamily: 'Quicksand',
      fontWeight: '500',
      color: '#4D4D4D',
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: '10.5px',
      paddingBottom: '10.5px',
      cursor: 'pointer',
    },
    '& .MuiFormHelperText-root': {
      margin: '10px 0 0 0',
      fontSize: '14px',
      color: '#999999',
      fontFamily: "Quicksand",
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: '#F96855',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '6px',
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '6px',
    },
    '& .Mui-disabled fieldset': {
      borderColor: '#e6e6e6',
      borderWidth: '2px',
    },
  }))

export const PopoverStyled = styled(Popover)({
    marginTop: '15px',

    '& .MuiPopover-paper': {
        borderRadius: '10px',
    },
})

export const CustomSelectWrapper = styled(Box)(({ theme }) => ({
    width: '400px',
    [theme.breakpoints.down('xs')]: {
        width: '300px',
    },
    background: '#F7F7F7',
    borderRadius: '10px',
    border: '2px solid #999',
  
    '& .search-field': {
      '& fieldset': {
        borderWidth: '0',
      },
      '& .Mui-focused fieldset': {
        borderWidth: '0px',
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#e6e6e6',
        borderRadius: '8px',
        fontSize: '16px',
        fontFamily: 'Quicksand',
        fontWeight: '500',
        color: '#4D4D4D',
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: '6.5px',
        paddingBottom: '6.5px',
        cursor: 'text',
      },
      '& .MuiInputAdornment-positionStart': {
        marginRight: '6px',
      },
    },
  
    '& .item': {
      listStyleType: 'none',
      borderBottom: '1px solid #E6E6E6',
      color: '#363636',
      fontFamily: 'Quicksand',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '500',
      padding: '8px 15px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '& .disabled':{
      opacity:0.6,
      cursor:'not-allowed'
    },
    '& .item:hover': {
      backgroundColor: '#e6e6e6'
    },

    '& .item.highlight': {
      backgroundColor: '#e6e6e6'
    },

    '& .itemWrapper': {
      padding: '0 0 15px',
      maxHeight: '146px',
      overflow: 'auto',
    },
  }))

export default CustomSelect
