
import React from 'react';
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
    Switch,
    IconButton,
} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    skip_wrench,
    btn_back,
    calendar_latest,
    first_calendar,
    icon_msg,
    visa_img,
    group1_mail,
    group_popup,
    diamond,
    arrowDetail,
    masterCardLogo,
} from "./assets";
import { withTranslation } from "react-i18next";
import Loader from '../../../components/src/Loader';
// Customizable Area End
import PlanDetailsController from './PlanDetailsController.web';
import NavigateLayout from '../../../components/src/NavigateLayout';

// Customizable Area Start
// Customizable Area End
class PlanDetails extends PlanDetailsController {
    render() {
        const backgroundBlur = this.state.isModalOpen || this.state.isModalPopOpen ? {
            background: "rgba(0, 0, 0, 0.25)",
            filter: " blur(1px)",
        } : {}
        return (
            // Customizable Area Start
            <>
                <Box style={backgroundBlur}>
                    <NavigateLayout menuName="Settings">
                        {this.state.loader && <Loader loading />}

                        <SettingContainer>
                            <Box className="mainContentPlanWrapper">
                                <Box className="settingBackgroundPlan">
                                    <Box className="settingImgWrapperPlan planDetailsSettingWrapper">
                                        <img src={skip_wrench} className="teddysImageplan" alt="Teddy Setting" />
                                        <Typography component="div" className="settingTitlePlan">
                                            <IconButton style={{ position: 'absolute', left: '0' }} data-testid='btn-back' onClick={() => this.props.navigation.goBack()}>
                                                <img src={btn_back} className="arrowImage" alt="arrow btn" width={16} height={16} style={{ marginRight: '5px' }} />
                                            </IconButton>
                                            {this.props.t("Plan Details")}
                                        </Typography>
                                    </Box>
                                    <Box className="settingImgWrapperPlan settingWrapper">
                                        <img src={skip_wrench} className="teddysImageplan" alt="Teddy Setting" />
                                        <img src={this.state.teddyBear} className="teddy-settings-image" alt="Teddy Setting" style={{ alignSelf: 'center' }} />

                                        <Typography component="div" className="settingTitlePlan">
                                            {this.props.t("Settings")}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className="contentContainerPlan">
                                    <Box className="titlePlanDetails">
                                        <Typography component="div" className="settingTitlePlan">
                                            <IconButton style={{ position: 'absolute', left: '0' }} data-testid='btn-back' onClick={() => this.props.navigation.goBack()}>
                                                <img src={btn_back} className="arrowImage" alt="arrow btn" width={16} height={16} style={{ marginRight: '5px' }} />
                                            </IconButton>
                                            {this.props.t("Plan Details")}
                                        </Typography>
                                    </Box>
                                    <Box className="blockItem">
                                        <Typography component='div' className="blockItem__headerTitle" style={{ textTransform: 'uppercase' }}>
                                            {this.props.t("Current Plan")}
                                        </Typography>
                                        <Box className="blockItem__content">
                                            <Box className="blockItem__content-img">
                                                <img src={diamond} alt="diamond" width={32} height={32} />
                                            </Box>
                                            <Box className="blockItem__content-textBox blockItem__content--column ">
                                                <Typography className="blockItem__content-textMain" >
                                                    {this.state.planName} {this.props.t("Premium")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="blockItem">
                                        <Typography component='div' className="blockItem__headerTitle" style={{ textTransform: 'uppercase' }}>
                                            {this.props.t("Subscription Start Date")}
                                        </Typography>
                                        <Box className="blockItem__content">
                                            <Box className="blockItem__content-img">
                                                <img src={calendar_latest} width={32} height={32} alt="diamond" />
                                            </Box>
                                            <Box className="blockItem__content-textBox blockItem__content--column ">
                                                <Typography className="blockItem__content-textMain">
                                                    {this.convertDate(this.state.subscriptionStartDate)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box className="blockItem">
                                        <Typography component='div' className="blockItem__headerTitle" style={{ textTransform: 'uppercase' }}>
                                            {this.props.t("Renewal Date")}
                                        </Typography>
                                        <Box className="blockItem-RenewalDate">
                                            <Box className="blockItem__content" style={{ paddingTop: "15px" }}>
                                                <Box className="blockItem__content-img">
                                                    <img src={first_calendar} width={32} height={32} alt="diamond" />
                                                </Box>
                                                <Box className="blockItem__content-textBox blockItem__content--column ">
                                                    <Typography className="blockItem__content-textMain">
                                                        {this.convertDate(this.state.subscriptionRenewalDate)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className="blockItem__content" style={{ paddingLeft: "15px" }}>
                                                <Box className="blockItem__content-img">
                                                    <Switch size="small" style={{ width: "24px", height: "26px" }} data-testid='toogleAutoRenewal' defaultChecked onClick={() => this.handleSwitchModal()} checked={this.state.autoRenewal} />
                                                </Box>
                                                <Box className="blockItem__content-textBox blockItem__content--column" style={{ marginLeft: '14px' }}>
                                                    <Typography className="blockItem__content-textMain" data-test-id="btnSubmited">
                                                        {this.props.t("Automatic Renewal")}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box className="blockItem__content blockItem__content-borderBottomRadius" style={{ paddingBottom: "15px" }}>
                                                <Box className="blockItem__content-textBox blockItem__content--column">
                                                    <Typography className="blockItem__content-textSub" >
                                                        You will be automatically charged {(this.state.planPrice)} € for a full year of <span style={{ fontWeight: 700 }}>Tedix {this.state.planName} Premium</span> once this day arrives.
                                                    </Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>

                                    <Box className="blockItem">
                                        <Typography component='div' className="blockItem__headerTitle" style={{ textTransform: 'uppercase' }}>
                                            {this.props.t("Payment Method")}
                                        </Typography>
                                        <Box className="blockItem__content" style={{ cursor: 'pointer' }} data-testid='btnPaymentMethod' onClick={() => this.props.navigation.navigate('PlanDetailsMethod')}>
                                            <Box className="blockItem__content-img" style={{ flex: "0 0 5%" }}>
                                                <img src={icon_msg} width={32} height={30} alt="diamond" />
                                            </Box>
                                            <Box className="blockItem__content-textBox blockItem__content--column" style={{ flex: "0 0 70%", maxWidth: "70%" }}>
                                                <Typography className="blockItem__content-textMain">
                                                    {this.props.t("VISA ending in")} {this.state.lastDigitCard}
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "baseline" }}>
                                                    <Typography style={webStyle.visasecText}>
                                                        {this.props.t("Update payment method")}
                                                    </Typography>
                                                    <img style={{ marginLeft: '5px' }} src={arrowDetail} />
                                                </Box>
                                            </Box>
                                            <Box className="blockItem__content-img" style={{ flex: "0 0 15%", maxWidth: "15%" }}>
                                                <img src={this.getBrandLogo()} width={37} height={37} alt="diamond" />
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box style={{
                                        width: '260px',
                                        height: '44px',
                                    }}>
                                        <button className="btnText"
                                            data-testid='btn-cancelSubscription'
                                            onClick={() => this.handleOpenPopModal()}>
                                            {this.props.t("Cancel subscription")}
                                        </button>
                                    </Box>
                                </Box>
                                <BottomMenu />
                            </Box>
                        </SettingContainer>
                    </NavigateLayout>
                </Box>
                <ModalPopup style={{ display: this.state.isModalOpen ? 'flex' : 'none', zIndex: 999 }} >
                    <div style={{ textAlign: 'center' }}>
                        <img src={group1_mail} alt="Image" style={{ width: '64px', height: '64px' }} />
                    </div>
                    <Typography
                        id="modal-title" style={webStyle.firstTitleText}
                    >
                        {this.props.t("Turn off auto-renewal?")}
                    </Typography>
                    <div>
                        <Typography
                            id="modal-description"
                            style={webStyle.textTitlethird}
                        >
                            Disabling this means no automatic renewal on {this.convertDate(this.state.subscriptionRenewalDate, "dd/mm/yyyy")}. Are you sure you want to proceed?
                        </Typography>
                    </div>
                    <Box className='btnGroup' style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                        <Button data-test-id="btnSubmitedSec"
                            className='btnTextTitle'
                            onClick={() => this.handleAutoRenewal()}
                            color="primary"
                        >
                            {this.props.t("Turn off")}
                        </Button>
                        <Button
                            data-testid='btn-cancelAuto'
                            color="primary"
                            className='btnTextTitleSec'
                            onClick={() => this.handleCloseModal()}
                        >
                            {this.props.t("Keep it on")}
                        </Button>
                    </Box>
                </ModalPopup>
                <ModalPopup style={{ display: this.state.isModalPopOpen ? 'flex' : 'none', zIndex: 999 }} >
                    <div style={{ textAlign: 'center' }}>
                        <img src={group_popup} alt="Image" style={{ width: '64px', height: '64px' }} />
                    </div>
                    <Typography
                        id="modal-title" style={webStyle.firstTeddyText}
                    >
                        {this.props.t("Going so soon?")}
                    </Typography>
                    <div>
                        <Typography style={webStyle.textTitlethirdPop}
                            id="modal-description"
                        >
                            {this.props.t("Are you sure you want to cancel? You'll miss exclusive benefits.")}
                        </Typography>
                    </div>
                    <div className='parentBtnSection'>
                        <Button
                            data-testid="btn-keepPremium"
                            className='btnTextTitle btnKeepPremium'
                            color="primary"
                            onClick={() => this.handleClosePopModal()}>
                            {this.props.t("Keep Premium")}
                        </Button>
                        <Button className='btnConfirmCancel' data-testid="btn-confirmCancel" onClick={() => this.handleCancelSubscription()} color="primary" >
                            {this.props.t("Cancel")}
                        </Button>
                    </div>
                </ModalPopup>
            </>
            // Customizable Area End
        );
    }
};


// Customizable Area Start
const ModalPopup = styled(Box)(({ theme }) => ({
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '320px',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    background: 'linear-gradient(0deg, #F7F7F7, #F7F7F7), linear-gradient(0deg, #E6E6E6, #E6E6E6)',
    boxShadow: '4px 4px 6px 0px #0000001A',
    backgroundColor: 'white',
    padding: '20px',
    '& .btnGroup .btnTextTitleSec': {
        width: '120px',
        height: '40px',
        borderRadius: '12px',
        backgroundColor: '#36BFFF',
        boxShadow: "0px 4px #00AEFF",
    },
    '& .btnTextTitleSec .MuiButton-label': {
        letterSpacing: '0.800000011920929px',
        textTransform: 'none',
        fontFamily: 'Nunito',
        textAlign: 'center',
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
    },

    "& .btnTextTitle": {
        width: '110px',
        height: '41.9px',
        borderRadius: '12px',
        border: '2px solid #CCCCCC',
        color: '#CCCCCC',
        marginRight: '10px',
        boxShadow: "0px 4px #CCCCCC",
        backgroundColor: "#FFFFFF"
    },

    '& .btnTextTitle .MuiButton-label': {
        color: "#CCC",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "12px",
        letterSpacing: "0.8px",
        textTransform: "none",
    },

    '& .parentBtnSection': {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '15px'
    },
    "& .btnKeepPremium": {
        width: '170px',
        height: '41.9px',
        borderRadius: '12px',
        border: '2px solid #CCCCCC',
        color: '#CCCCCC',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.8px',
        textAlign: 'center',
        boxShadow: "rgb(204, 204, 204) 0px 2px",
        textTransform: 'none',
    },
    '& .btnConfirmCancel': {
        height: '40px',
        borderRadius: '12px',
        backgroundColor: '#F96855',
        padding: '10px',
        boxShadow: '0px 4px #EA5A47'
    },
    '& .btnConfirmCancel .Muibutton-label': {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'none',
    }
}))
const SettingContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    "& .teddy-settings-image": {
        bottom: '0px',
        position: 'absolute',
        Width: '245px',
        padding: '0',
        marginLeft: '0',
        display: 'block',
        marginRight: '10px',
        left: 'calc(45% - 207px)',

    },
    '& .settingImgWrapperPlan.planDetailsSettingWrapper': {
        display: 'none'
    },
    '& .blockItem__content-img': {
        flex: '0 0 5%',
    },
    '& .MuiSwitch-thumb': {
        borderRadius: 4,
    },
    '& .MuiSwitch-track': {
        borderRadius: 4,
        backgroundColor: '#B3B3B3',
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
        color: "#F7F7F7",
        borderRadius: "4px"
    },
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: "#62CDFF",
        opacity: "1"
    },
    '& .btnText': {
        width: '100%',
        height: '41.9px',
        borderRadius: '12px',
        border: '2px solid #E6E6E6',
        background: '#fff',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#F96855',
        marginBottom: '20px',
        cursor: "pointer"
    },

    '& .contentContainerPlan': {
        flex: '6',
        gap: "20px",
        background: 'linear-gradient(180deg, #A95EDB 0%, #62CDFF 100%)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        alignItems: 'center',
        padding: '32px 24px 100px',
    },
    '& .settingBackgroundPlan': {
        backgroundColor: '#A95EDB',
        marginBottom: "-12px",
        height: '111px'
    },


    '& .settingTitlePlan': {
        display: 'flex',
        justifyContent: 'center',
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        width: '100%',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
        position: "relative"
    },

    '& .settingImgWrapperPlan': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },
    '& .settingListTitle': {
        color: '#7F7F7F',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },

    "& .MuiGrid-spacing-xs-2": {
        margin: "0px",
        alignItems: "center"
    },

    "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
        padding: "0px"
    },

    '& .mainContentPlanWrapper': {
        display: 'flex',
        background: "linear-gradient(180deg, #A95EDB 0%, #62CDFF 100%)",
        flexDirection: 'column',
        height: 'auto',
    },

    '&.arrowImage': {
        width: "30%",
        height: "auto",
    },

    '& .teddysImageplan': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "10%"
    },

    "& .blockItem": {
        width: '100%',
        maxWidth: 600,
        border: '2px solid #F2F2F2',
        borderRadius: "12px"
    },
    "& .blockItem__headerTitle": {
        color: '#999999',
        fontSize: '12px',
        lineHeight: "12px",
        padding: "14px 18px",
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },

    "& .blockItem__content": {
        width: '100%',
        alignItems: 'center',
        padding: '16px 20px',
        backgroundColor: '#0000004D',
        display: 'flex',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
    },

    "& .blockItem__content--column": {
        flexDirection: "column"
    },

    "& .blockItem__content-textBox": {
        marginLeft: "15px"
    },
    "& .blockItem-RenewalDate .blockItem__content": {
        padding: "4px 20px",
        borderRadius: '0'
    },
    "& .blockItem-RenewalDate .blockItem__content-borderBottomRadius": {
        borderBottomLeftRadius: "12px",
        borderBottomRightRadius: "12px",
    },
    "& .blockItem__content-textMain": {
        fontFamily: 'Quicksand',
        fontSize: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '18px',
    },
    "& .blockItem__content-textSub": {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#fff',
        marginLeft: "-15px"
    },

    [theme.breakpoints.up('md')]: {
        height: "100vh",
        "& .teddy-settings-image": {
            maxWidth: 'unset',
            width: '360px',
            left: 'calc(30% - 120px)'
        },
        '& .settingBackgroundPlan': {
            flex: 5,
            height: "100%"
        },

        '& .settingTitlePlan': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .blockItem': {
            maxWidth: 450,
        },

        '& .settingImgWrapperPlan': {
            paddingTop: 'unset',
        },
        '& .mainContentPlanWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
            height: "100%"
        },
        '& .teddysImageplan': {
            maxWidth: 'unset',
            width: "30%",
            display: 'none'
        },
        '& .contentContainerPlan': {
            paddingTop: '0',
            overflowX: "hidden",
            overflowY: "scroll",
            height: "100%"
        },
        '& .titlePlanDetails': {
            width: '100%',
            '& .settingTitlePlan': {
                fontSize: "25px"
            }
        },
        '& .btnText': {
            width: '100%',
            height: '41.9px',
            borderRadius: '12px',
            border: '2px solid #E6E6E6',
            background: '#fff',
            fontFamily: 'Nunito',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '12px',
            letterSpacing: '0.800000011920929px',
            textAlign: 'center',
            color: '#F96855',
        },
    },

    [theme.breakpoints.up('lg')]: {
        "& .teddy-settings-image": {
            width: '360px',
            left: 'calc(45% - 207px)',
        },
        '& .mainContentPlanWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },
        '& .blockItem': {
            maxWidth: '100%',
        },
    },
    [theme.breakpoints.down('sm')]: {
        "& .settingTitlePlan button": {
            position: "absolute",
            left: 0,
            top: "50%",
            marginLeft: "-8px",
            padding: "4px"
        },

        '& .titlePlanDetails': {
            display: "none"
        },
        '& .settingImgWrapperPlan.settingWrapper': {
            display: 'none'
        },
        '& .settingImgWrapperPlan.planDetailsSettingWrapper': {
            display: 'block'
        },
    }
}))

const webStyle = {
    btnTextTitlePre: {
        width: '170px',
        height: '41.9px',
        borderRadius: '12px',
        border: '2px solid #CCCCCC',
        color: '#CCCCCC',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center' as 'center',
        borderBottom: '4px solid #CCCCCC',
        textTransform: 'none' as 'none',
    },
    textTitlethird: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#666666',
        marginTop: '11px',
    },
    textTitlethirdPop: {
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#666666',
        marginTop: '11px',
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '23px',
    },
    firstTitleText: {
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0em',
        textAlign: 'center' as 'center',
        color: '#363636',
    },
    firstTeddyText: {
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0em',
        fontSize: '18px',
        textAlign: 'center' as 'center',
        color: '#363636',
        fontFamily: 'Nunito',
    },
    visasecText: {
        color: '#C9EEFF',
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '15px',
        textAlign: 'left' as 'left',
        marginTop: '10px',
    },
};

export default withTranslation()(PlanDetails)
// Customizable Area End