import React from "react"
// Cutomizable Area Start
import GoalDetailsController from "./GoalDetailsController.web";
import { withTranslation } from "react-i18next";
import { Box, Button, IconButton, InputAdornment, Typography, styled } from "@material-ui/core";
import { clock, dropDown, skipCalender, vectorArrowLeft } from "./assets";
import CustomSelect from "../../../components/src/CustomGoal";
// Customizable Area End

class GoalDetails extends GoalDetailsController {
    // Cutomizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start   
            <StyledContainer>
                <Box className="action_buttons_container">
                    <IconButton className="go-back-btn" onClick={this.props.goBack}>
                        <img src={vectorArrowLeft} alt="back-btn" />
                    </IconButton>
                    <Button className="save-btn" data-testid="save-btn" disabled={this.getDisabled()} onClick={this.handleUpdateGoals}>
                        {this.props.t("Save")}
                    </Button>
                </Box>

                <Box className="Head_container">
                    <Typography className="Head_text">{this.props.t("Customize your \n ideal goal combination!")}</Typography>
                </Box>

                <Box className="Goal_Container">
                    <Box className="boxContainer">
                        <Typography className="goal_text">{this.props.t("Daily Goal")}</Typography>
                        <CustomSelect
                            list={this.props.dailyGoalList}
                            style={{ width: "100%"}}
                            isDailyGoal
                            showSearchInput
                            data-testid="daily-goal-select"
                            value={this.getDailyGoalValue(this.state.dailyGoal)}
                            setValue={(value)=>this.handleUpdate(value,'dailyGoal')}
                            placeholder="Daily Goal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled edge="end">
                                            <img src={dropDown} width={18} height={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start" style={{marginRight:10}}>
                                            <img src={clock} width={18} height={18} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>

                <Box className="Goal_Container">
                    <Box className="boxContainer">
                        <Typography className="goal_text">{this.props.t("Streak Goal")}</Typography>
                        <CustomSelect
                            list={this.props.streakGoalList}
                            style={{ width: "100%"}}
                            data-testid="streak-goal-select"
                            showSearchInput
                            value={this.getStreakGoalValue(this.state.streakGoal)}
                            setValue={(value)=>this.handleUpdate(value,'streakGoal')}
                            placeholder="Streak Goal"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled edge="end">
                                            <img src={dropDown} width={18} height={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start" style={{marginRight:10}}>
                                            <img src={skipCalender} width={18} height={18} />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                </Box>
            </StyledContainer>
            // Customizable Area End
        )
    }
}
// Cutomizable Area Start
export default withTranslation()(GoalDetails)
const StyledContainer = styled(Box)(({ theme }) => ({
    padding: "0px 16px 16px 16px",
    width: "100%",
    "& .action_buttons_container": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'ceter',
    },
    "& .save-btn": {
        width: "70px",
        height: "33px",
        textTransform: 'none',
        borderRadius: "10px",
        boxShadow: "0px 2px 1px 1px #CCCCCC",
        background: "var(--Primary-Neutrals-Warm-gray-10, #36BFFF)",
        color: "white",
        textAlign: "center",
        fontSize: '14px',
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px", /* 85.714% */
        letterSpacing: "0.8px",
    
    "& .MuiButton-label": {
        fontFamily: "Nunito",
    },

    '&.Mui-disabled': {
        color: ' #CCC',
        boxShadow: '0px 2px 1px 1px #CCCCCC',
        backgroundColor: '#F7F7F7',
      },

   },

    "& .Head_text": {
        color: "var(--Primary-Neutrals-Warm-gray-500, #7F7F7F)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "19px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "24px",
        padding: "24px",
        whiteSpace: "break-spaces"
    },

    "& .Goal_Container": {
        margin: 12,
        height: "108px",
        padding: 16,
        borderRadius: "10px",
        background: "var(--Primary-Neutrals-Warm-gray-50, #F2F2F2)",
    },

    "& .goal_text": {
        color: "var(--Primary-Blue-Dark-Gray, #363636)",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "13px",
        marginBottom:20,
    },
    "& .boxContainer > .styled_container > .MuiTextField-root > .MuiOutlinedInput-root": {
        backgroundColor:"unset !important",
    }
}))
// Customizable Area End
