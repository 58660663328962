import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subscriptionStartDate: string,
    subscriptionRenewalDate: string,
    token: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PremiumHighlightsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSpecificAccountIdPremiumHighlights = ''
    getSubscriptionIdPremiumHighlights = ''
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            subscriptionStartDate: "",
            subscriptionRenewalDate: "",
            token: ""
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.getSpecificAccountIdPremiumHighlights) {
            const handleCallback = (jsonData: any) => {
                const { current_subscription_id, last_expired_subscription_id } = jsonData.attributes
                const subscriptionId = current_subscription_id ?? last_expired_subscription_id
                this.getSubscription(this.state.token, subscriptionId)
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
        if (apiRequestCallId === this.getSubscriptionIdPremiumHighlights) {
            const handleCallback = (jsonData: any) => {
                const { end_date, start_date } = jsonData.attributes
                this.setState({ subscriptionStartDate: start_date, subscriptionRenewalDate: end_date })
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
    }
    async componentDidMount() {
        const token = localStorage.getItem("token") || ""
        this.setState({ token })
        this.getSpecificAccount(token)
    }
    formatDate(date: string) {
        return new Date(date).toLocaleString('en-GB').split(',')[0]
    }
    handleRecieveRestApi(jsonData: any, callback: () => void) {
        const isDataValid = jsonData?.attributes?.account_id !== null
        if (isDataValid) {
            callback();
        }
    }
    getSubscription(token: string, subscriptionId: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSubscriptionIdPremiumHighlights = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSubscriptionEndpoint}/${subscriptionId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSubscriptionMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSpecificAccount(token: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSpecificAccountIdPremiumHighlights = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSpecificAccountEndpoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSpecificAccountMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}