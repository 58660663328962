

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {CommonApiCall} from '../../../components/src/utils'
// Customizable Area End

// Customizable Area Start

export const configJSON = require("./config");

import {
  healthyTeddy1,
  healthyTeddy2,
  healthyTeddy3,
  healthyTeddy4,
  healthyTeddy5,
  healthyTeddy6,
  healthyTeddy7,
  healthyTeddy8,
} from "./assets";
import i18next from "i18next";
// Customizable Area End

export interface Props {
  
  // Customizable Area Start
  
  navigation:any;
  t:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  language:string;
  centerImage:any;
  firstLogin: any;
  selectedColor:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FirstLoginSelectAvatarController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  firstLoginAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      firstLogin: [],
      language:"",
      centerImage:healthyTeddy8,
      selectedColor:"#62cdff"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  responseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.firstLoginAPICallId) {
      this.firstLoginSucessCallBack(responseJson);
    }
  };

  responseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.firstLoginAPICallId) {
      this.firstLoginFailureCallBack(responseJson);
    }
  };

  finishSelectAvatar = async () => {
    let profile={
    daily_goal:this.props.navigation?.getParam("dailyGoal"),
    streak_goal:this.props.navigation?.getParam("streakGoal"),
    favorite_color:this.state.selectedColor
    }
    this.firstLoginAPICallId = await CommonApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: 'bx_block_profile/profiles/update_user_profile',
      body:profile
    });
    localStorage.setItem('avatarColor',this.state.selectedColor)
  };

  firstLoginSucessCallBack = (res: any) => {
    console.log("@@@ ====== first login success call back", res);
    this.props.navigation.navigate('NotificationWeb')

  };
  firstLoginFailureCallBack = (res: any) => {
    console.log("@@@ ====== first login  Failure success call back", res);
  };

  handleSelectLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ language: event.target.value as string });
    i18next.changeLanguage(event.target.value as string)
  };
  
  handleButtonClick1=()=>{
    this.setState({centerImage:healthyTeddy5,selectedColor:"#f96855"})
  }
  handleButtonClick2=()=>{
    this.setState({centerImage:healthyTeddy4,selectedColor:"#fdce36"})
  }
  handleButtonClick3=()=>{
    this.setState({centerImage:healthyTeddy3,selectedColor:"#2bce8c"})
  }
  handleButtonClick4=()=>{
    this.setState({centerImage:healthyTeddy6,selectedColor:"#884a39"})
  }
  handleButtonClick5=()=>{
    this.setState({centerImage:healthyTeddy7,selectedColor:"#a95edb"})
  }
  handleButtonClick6=()=>{
    this.setState({centerImage:healthyTeddy8,selectedColor:"#62cdff"})
  }
  handleButtonClick7=()=>{
    this.setState({centerImage:healthyTeddy1,selectedColor:"#ff8400"})
  }
  handleButtonClick8=()=>{
    this.setState({centerImage:healthyTeddy2,selectedColor:"#b7f0ad"})
  }
  handleBackFirstlogin=()=>{

    this.props.navigation.goBack()
    }
  // Customizable Area End
}

