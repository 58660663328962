// Customizable Area Start
// import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import React from 'react';
//@ts-ignore
// Customizable Area Start
import {GoogleLogin, GoogleLoginResponse,GoogleLoginResponseOffline } from "react-google-login";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
// Customizable Area End

// Customizable Area Start

interface IProfileObj{
  email: string;
  firstName:string;
  lastName:string;
};
interface GoogleSignUpResponse {
  /** This field is the returned ID token */
  profileObj: IProfileObj;
  googleId: string;
  /** This field sets how the credential is selected */
}
interface Props {
// Customizable Area Start
  handleGoogleLogin: (profileObj: IProfileObj, uniqueAuthId: string) => void; // Modify the prop to accept email and password
  onGoogleSignupSuccess: (token: string) => void;
// Customizable Area End

}
// Customizable Area End

const GoogleSignUpComponent = (props: Props) => {
   const {t} = useTranslation()
  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    console.log('Google response:', response);

    if ('profileObj' in response && 'googleId' in response) {
      const googleResponse: GoogleSignUpResponse = {
        profileObj: {
          email: response.profileObj.email,
          firstName:response.profileObj.givenName,
          lastName:response.profileObj.familyName,
        },
        googleId: response.googleId,
      };
    if (props.onGoogleSignupSuccess) {
        props.onGoogleSignupSuccess(googleResponse.googleId);
      }
      const uniqueAuthId = googleResponse.googleId;
      props.handleGoogleLogin(googleResponse.profileObj, uniqueAuthId)
    }
  };


  return (
// Customizable Area Start
    <GoogleLogin
    clientId='494702856114-pfd9vabhflt32hmnr18j0p6kup1if4qi.apps.googleusercontent.com'
    buttonText={t("Sign up with Google")}
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    prompt="select_account"
  />
//   <GoogleOAuthProvider clientId='494702856114-pfd9vabhflt32hmnr18j0p6kup1if4qi.apps.googleusercontent.com'>
//     <GoogleLogin
     
//       onSuccess={responseGoogle}
//     />
// </GoogleOAuthProvider>
// Customizable Area End
  )
}

export default GoogleSignUpComponent
// Customizable Area End
