import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@material-ui/core'
import {
  imgLogoTedix,
  imgHomeMenu,
  imgProfileMenu,
  imgSettingMenu,
  imgHomeMenuActive,
  imgLeaderBoardMenuActive,
  imgLeaderBoardMenu,
  imgProfileMenuActive,
  imgSettingMenuActive,
  imgMenuMenu,
  imgMenuMenuActive,
} from './assets'
import "./I18nextConfig/i18n"
import { withRouter } from 'react-router-dom'
import  {useTranslation} from "react-i18next"


const SideBar = (props: any) => {
  const  { t } = useTranslation()
  const drawerList = [
    {
      title: t('Home'),
      icon: (
        <img
          src={props.menuName === 'Home' ? imgHomeMenuActive : imgHomeMenu}
          width={24}
          height="auto"
        />
      ),
      navigate: 'LandingPageWeb',
    },
    {
      title: t('Leaderboard'),
      icon: (
        <img
          src={
            props.menuName === 'Leaderboard'
              ? imgLeaderBoardMenuActive
              : imgLeaderBoardMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'Leaderboard',
    },
    {
      title: t('Menu'),
      icon: (
        <img
          src={
            props.menuName === 'Menu'
              ? imgMenuMenuActive
              : imgMenuMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'mainMenuWebPage',
    },
    {
      title: t('Profile'),
      icon: (
        <img
          src={
            props.menuName === 'Profile' ? imgProfileMenuActive : imgProfileMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'CustomisableUserProfiles',
    },
    {
      title: t('Settings'),
      icon: (
        <img
          src={
            props.menuName === 'Settings' ? imgSettingMenuActive : imgSettingMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'Settings2',
    },
  ]

  const drawer = (
    <div>
      <List>
        {drawerList.map((item) => (
          <ListItem
            key={item.title}
            button
            className={item.title === t(props.menuName) ? 'active' : ''}
            onClick={() => {
              item.navigate && props.history.push(`/${item.navigate}`)
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  )

  return (

    <DrawerWrapper variant="permanent" open>
      <img
        alt="logo"
        src={imgLogoTedix}
        width={100}
        height="auto"
        style={{ margin: '66px 0 66px 18px' }}
      />
      {drawer}
    </DrawerWrapper>
  )
}

export default withRouter(SideBar)

const DrawerWrapper = styled(Drawer)(({ theme }) => ({
  display: 'none',

  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    paddingLeft: '32px',
    paddingRight: '32px',
    borderRadius: '0 20px 20px 0',
    width: '240px',
    boxShadow: '4px 0px 16px 0px rgba(0, 0, 0, 0.2)',
  },

  '& .MuiButtonBase-root': {
    marginBottom: '24px',
    borderRadius: '12px',
    backgroundColor: '#fff',

    '&:hover': {
      boxShadow: '0 0 10px #ccc',
    },
  },

  '& .active': {
    boxShadow: '0 0 10px #ccc',
  },

  '& .MuiListItemText-root': {
    '& span': {
      fontSize: '18px',
      fontWeight: '700',
      fontFamily: 'Nunito',
    },
  },

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },

  [theme.breakpoints.up('lg')]: {
    '& .MuiDrawer-paper': {
      width: '282px',
    },
  },
}))
