import React, {Component} from "react"
// Customizable Area Start
import {
    Box,
    Typography,
    styled
} from "@material-ui/core";
import { leftLine, centerLine, rightLine, messageIcon } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';

interface ILesson{
    id:number,
    name:string,
    image:string,
    progress:number
}

const side = [
    { side: rightLine },
    { side: centerLine },
    { side: leftLine }
]

interface Props {   
    lessonData: any,  
    navigation: any,
    progressStart?:{
        id:number,
        progress:number
    }
  }

export default class Lessons extends Component<Props>{

     result =  this.props.lessonData.map((lesson: any) => ({
        id: JSON.parse(lesson.id).attributes.id,
        name: lesson.label,
        image: lesson.image, 
        progress: lesson.progress
      }))

    getPatternIndex = (index: number) => {
        const number = index % 3
        return number
    }

    getBoxImageContainer=(index:number)=>{
        return  index === 0 ? "imageBoxContainer" : "imageBoxContainer2"
      }

      getUpsideclassName =(index:number)=>{
        return index === 0 ? "imageUpside" : "imageUpside2"
       }
   
       getTextContainer = (index:number)=>{
          return index === 0 ? "textContainer" : "textContainer2"
       }   
  
      getDownSideImage=(index:number)=>{
         return index === 0 ? "downsideImage" : "downsideImage2"
      }

    cssOfgetItem = (patternIndex: number, progressText?:{id:number}) => {
        return (<Box className={this.result.length - 1 !== patternIndex?"boxNodeContainer":"boxNodeContainer1"}>
            {this.result.slice(patternIndex, patternIndex + 2).map((item: ILesson, index: number) => {
                return (
                    <Box key={index} className="boxNode">
                        <Box className="ImageTextContainer">
                            <Box style={{textAlign:'center', position:'relative'}} data-testid={`lessonImageNew${patternIndex+index}`} onClick={() => this.props.navigation.navigate('Cftableforlessons', { lessonId: item.id, lessonNumber: 0,  lessonName: encodeURIComponent(item.name)})}>
                            <CircularProgress className="progressbar" variant="determinate" value={item.progress} />
                                <Box>
                                   {progressText?.id === item.id && <Typography className="startLesson">Start!</Typography>}
                                    <img src={item.image} width={88} height={88} style={{borderRadius:'50%'}} />
                                </Box>
                                <Box style={{ width: '100%', maxWidth: "150px", padding: "0 10px" }}>
                                    <Typography  className="lesson_name">
                                        {item.name}
                                    </Typography>
                                </Box>
                            </Box>
                            {(index === 0 && this.result.length - 1 !== patternIndex) && <Box style={{ marginTop: '-50px', padding: "0px 24px" }}>
                                <img src={side[1].side} />
                            </Box>}
                        </Box>
                    </Box>
                )
            })}
        </Box>
     )
    }

    render() {
        const {progressStart} = this.props
        return (
            <SettingContainers>
                {this.result.map((item: ILesson, index: number) => {
                    const patternIndex = this.getPatternIndex(index)
                    if (patternIndex === 0) {
                        return (
                            <Box key={index} className={this.getBoxImageContainer(index)}  data-testid={`lessonImage${index}`} onClick={() => this.props.navigation.navigate('Cftableforlessons', { lessonId: item.id, lessonNumber: 0, lessonName: encodeURIComponent(item.name) })}>
                                    
                                <Box className="containerBox" >
                                  {this.result.length-1!==index ?
                                
                                  <Box>
                                  <Box className={this.getDownSideImage(index)}>
                                        <img src={side[2].side} />
                                    </Box>
                                    </Box>
                                    :
                                    <Box style={{marginRight:60}}></Box>}
                                    <Box style={{position: 'relative'}}>
                                     <CircularProgress className="progressbar" variant="determinate" value={item.progress} />

                                        {progressStart?.id ===item.id && <Typography className="startLesson">Start!</Typography>}
                                        <img src={item.image} width={88} height={88} style={{borderRadius:'50%'}}/>
                                    </Box>
                                    {index !== 0 && <Box className={this.getUpsideclassName(index)}>
                                        <img src={side[0].side} />
                                    </Box>}
                                </Box>
                                <Box
                                    className={this.getTextContainer(index)}>
                                    <Typography className="lesson_name" >
                                        {item.name}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    }
                    else if (index === 1 || patternIndex === 1) {
                            return this.cssOfgetItem(index , progressStart)              
                    }
                })}
            </SettingContainers>
        )
    }
}

const SettingContainers = styled(Box)(({ theme }) => ({
    background: '#C9EEFF',
    overflow: 'auto',
    width: "100%",
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    '@media(max-width:960px)':{
     paddingBottom:60,
    height:'calc(100vh - 260px)'
    },

    '& .containerBox': {
        display: 'flex',
        alignItems: 'center',
        marginTop: '40px', 
        position:'relative'
    },
    '& .boxNode': {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        marginTop: 0
    },

    '& .boxNodeContainer': {
        display: 'flex',
        maxWidth: "400px",
        alignSelf:"center",
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 0
    },

    "& .boxNodeContainer1":{
        display: 'flex',
        maxWidth: "400px",
        justifyContent: 'start',
        alignItems: 'center',
        width:"100%",
        margin:"0px auto",
    },

    '& .lesson_name': {
        textAlign: 'center',
        color: "#666666",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "15px",
        letterSpacing: "0.32px",
        marginTop:'10px',
        '&:hover': {
            color: "#00AEFF",
            cursor:'pointer'
        }
    },
    '& .startLesson': {
        backgroundImage: `url(${messageIcon})`,
        width: "70px", 
        height: "33.115px",
        backgroundRepeat: "no-repeat",
        color: "var(--Primary-Blue-Blue-Primary, #62CDFF)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "8px",
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position: 'absolute',
        left: '0',
        right: '0',
        margin: '0 auto',
        paddingBottom: '7px',
        top: '-15px'
    },

    '& .imageBoxContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 90,
        marginBottom: 20,
        marginTop:'20px',
        alignSelf:'center',
        maxWidth:'490px'
    },
    '& .progressbar': {
        width: "90px !important",
        height: "90px !important",
        transform: "rotate(-90deg)",
        position: "absolute",
        left: "0",
        right: "0",
        margin: "0 auto", 
        top: '-1px',
        "& svg": {
            "& circle":{
                strokeWidth: '2px !important'
            }
        }, 
    },
    '& .imageBoxContainer2': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "",
        marginBotton: ""
    },
    '& .textContainer2': {
        width: '100%',
        maxWidth: "184px",
        padding: "0 10px"
    },

    '& .ImageTextContainer': {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
    },

    '& .downsideImage': {
        marginTop: '60px',
        marginRight: '40px',
        '@media(max-width:375px)': {
            marginRight: "16px",
            marginLeft: "-24px",
        }
    },
    '& .imageUpside2': {
        marginTop: '-32px',
        marginLeft: '40px'
    },

    '& .imageUpside': {
        marginTop: '-32px',
        marginLeft: '40px'
    },
   
    '& .textContainer': {
        width: '100%',
        maxWidth: "150px",
        padding: "0 10px",
        marginTop:'20px'
    },

    '& .downsideImage2': {
        marginTop: '60px',
        marginRight: '40px',
    },

    '& .MuiCircularProgress-colorPrimary': {
        color:'#00AEFF !important'
    }
   
}))
// Customizable Area End
