import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import {healthyTeddy1, healthyTeddy2, healthyTeddy3, healthyTeddy4, healthyTeddy5, healthyTeddy6, healthyTeddy7, healthyTeddy8 } from "./assets";
import {CommonApiCall} from '../../../components/src/utils'

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  t?:any;
  callbackFromParent: any;
  avatarColor:any;
  callParentFunction: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  centerTeddyImage: any,
  selectedColor: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


// Customizable Area Start

// Customizable Area End

export default class ChangeAvatarController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getSubCategories: any = ""; 
  changeColorAPICallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
     centerTeddyImage: this.getInitialTeddyImage(), 
     selectedColor: this.props.avatarColor

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  changeTeddy = (data:number) => {
    if(data === 1) {
      this.setState({centerTeddyImage: healthyTeddy1, selectedColor: '#ff8400'})
    } else if(data === 2) {
      this.setState({centerTeddyImage: healthyTeddy2, selectedColor:'#62cdff'})
    } else if(data === 3) {
      this.setState({centerTeddyImage: healthyTeddy3, selectedColor:'#b7f0ad'})
    } else if(data === 4) {
      this.setState({centerTeddyImage: healthyTeddy4, selectedColor:'#a95edb'})
    } else if(data === 5) {
      this.setState({centerTeddyImage: healthyTeddy5, selectedColor:'#f96855'})
    } else if(data === 6) {
      this.setState({centerTeddyImage: healthyTeddy6, selectedColor:'#884a39'})
    } else if(data === 7) {
      this.setState({centerTeddyImage: healthyTeddy7, selectedColor:'#fdce36'})
    } else if(data === 8) {
      this.setState({centerTeddyImage: healthyTeddy8, selectedColor:'#2bce8c'})
    }
}

backClick = () => {
  this.props.callbackFromParent()
}

changeAvatar= async () => {
  let profile={
  favorite_color:this.state.selectedColor
  }
  this.changeColorAPICallId = await CommonApiCall({
    contentType: "application/json",
    method: "PUT",
    endPoint: 'bx_block_profile/profiles/update_user_profile',
    body:profile
  });
  localStorage.setItem('avatarColor', this.state.selectedColor)
  setTimeout(()=> {
    this.props.callParentFunction();
    this.backClick()
  }, 2000)  
};

getInitialTeddyImage = () => {
  const colorToImageMap = {
      '#ff8400': healthyTeddy1,
      '#62cdff': healthyTeddy2,
      '#b7f0ad': healthyTeddy3,
      '#a95edb': healthyTeddy4,
      '#f96855': healthyTeddy5,
      '#884a39': healthyTeddy6,
      '#fdce36': healthyTeddy7,
      '#2bce8c': healthyTeddy8
  };

  return (colorToImageMap as any)[this.props.avatarColor] || healthyTeddy2;
}

  // Customizable Area End

}

// Customizable Area Start
// Customizable Area End