// Customizable Area Start
import React from 'react'
import MotivationalPageController, {
  Props,
} from './MotivationalPageController'
import { Box, styled } from '@material-ui/core'
import NavigateLayout from '../../../components/src/NavigateLayout'
import MotivationalPage from '../../../components/src/MicrolessonExercise/MotivationalPage'
import ProgressBar from '../../cftableforlessons/src/ProgressBar'
import MainContentCard from './MainContentCard'

export default class MotivationalScreen extends MotivationalPageController {
  constructor(props: Props) {
    super(props)
  }

  handleNavigate = () => {
    this.props.navigation.navigate('MicroLessonExercise', {
      lessonId: this.state.lessonId,
      lessonNumber: this.state.lessonNumber,
      lessonName: this.state.lessonName,
    })
  }

  handleClickBackLandingPage = () => {
    localStorage.setItem('isBackFromMicrolessonVideo', 'true')
    this.props.navigation.navigate('LandingPageWeb')
  }

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ openDrawer: true, trophiesOpen: true })
    } else if (type === 'goal') {
      this.setState({ openDrawer: true, goalsOpen: true })
    } else if (type === 'median') {
      this.setState({ openDrawer: true, medianOpen: true  })
    }
  }

  closeDrawer = () => {
    this.setState({
      trophiesOpen: false,
      goalsOpen: false,
      mathOpen: false,
      openDrawer: false,
      medianOpen: false,
    })
  }

  render() {
    return (
      <NavigateLayout isShowBottomMenu={false} menuName="Home">
        <MicroLessonExerciseContainer>
          <MainContentCard
            closeDrawer={this.closeDrawer}
            drawerSide={this.state.drawerSide}
            goalsOpen={this.state.goalsOpen}
            handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
            lessonName={this.state.lessonName}
            medianOpen={this.state.medianOpen}
            navigation={this.props.navigation}
            onClickBackLandingPage={this.handleClickBackLandingPage}
            openDrawer={this.state.openDrawer}
            topicName={this.state.topicName}
            trophiesOpen={this.state.trophiesOpen}
            unitName={this.state.unitName}
            lessonNumber={localStorage.getItem('lessonNumber')}
            unitNumber={localStorage.getItem('unitNumber')}
          />
          <Box className="contentContainers">
            <Box className="mainContentWrappers">
              <Box className="contentHeaderWrappers">
                <ProgressBar
                  currentLessonNumber={this.state.lessonNumber}
                  totalLessonCount={this.state.totalLessonCount}
                  number={this.state.totalXP}
                  navigation={this.props.navigation}
                />
              </Box>
              <MotivationalPage
                onClickContinue={this.handleNavigate}
              />
            </Box>
          </Box>
        </MicroLessonExerciseContainer>
      </NavigateLayout>
    )
  }
}

const MicroLessonExerciseContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#00AEFF',
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  
  '& .contentContainers': {
    flex: '6',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: '#fff',
    scrollbarWidth: 'none',
    overflow: 'auto',
  },

  '& .contentContainers::-webkit-scrollbar': {
    display: 'none',
  },

  '& .mainContentWrappers': {
    flex: 1,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .contentHeaderWrappers': {
    width: '100%',
    padding: '32px 26px 0',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    paddingLeft: '240px',

    '& .contentHeaderWrappers': {
      padding: '32px 60px 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))
// Customizable Area End
