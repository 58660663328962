import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";
import {
    group_divine,
    sadBlue, sadBrown, sadGreen, sadOrange, sadPurple, sadRed, sadYellow, sadmint
} from "./assets";
import { Props } from './StripePayments';
import { useTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web';
// Customizable Area End
import { ViewProps } from "./StripePayments";

// Customizable Area Start


// Customizable Area End
// Customizable Area Start
const PaymentError: React.FC<Props & { avatarColor: string }> = ({ navigation, avatarColor })  => {
    const { t } = useTranslation();

    const handleTryAgain = () => {
        navigation.navigate('StripePaymentsMethod')
    }

    const handleClickContact = () => {
        navigation.navigate("Contactus")
    }

    const colorToUpdateTeddy:any = {
      '#ff8400': sadOrange,
      '#62cdff': sadBlue,
      '#b7f0ad': sadmint,
      '#a95edb': sadPurple,
      '#f96855': sadRed,
      '#884a39': sadBrown,
      '#fdce36': sadYellow,
      '#2bce8c': sadGreen
    };

    const updatedTeddy = colorToUpdateTeddy[avatarColor] || sadBlue;
// Customizable Area End
    return (
        // Customizable Area Start

        <SettingContainer>
            <SideBar />
            <Box className="mainPaymentWrapper" >
                <Box className="settingCheckBackground">
                <CenterPortion navigation={undefined} id={""} />
                </Box>
                <Box className="ProcessErrContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box className="skipPenImgesWrapper" >
                        <Box style={webStyle.teddyImageLoaparent} >
                            <img src={updatedTeddy} alt="Loading" width="102px" height="130px"/>
                        </Box>
                        <Box>
                            <Typography style={webStyle.titlePayText}>
                                {t("Payment declined")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={webStyle.checkboxErrText}>
                                {t("Please check your details and try again")}
                                {t("Need help?")} <span>&nbsp;</span>
                                <b style={webStyle.contactUsBtn} onClick={handleClickContact} data-test-id="contact-btn">{t("Contact our support team.")}</b>
                            </Typography>
                        </Box>
                        <Box className="btnparentSection" >
                            <Button className="btnTextTitle" onClick={handleTryAgain} data-test-id="try-again-btn">
                                {t("Try again")}
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </SettingContainer>

        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    '& .btnparentSection': {
        width: '260px',
        height: '41.9px',
        borderRadius: '12px',
        background: '#fff',
        borderColor: '#999999',
        marginTop: '94px',
        border: '2px solid #999999',
        borderBottom: '4px solid #999999',
    },

    '& .btnTextTitle': {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#7F7F7F',
        width: '100%',
        padding: '10px',
        textTransform: 'none',
    },

    '& .mainPaymentWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .settingCheckBackground': {
        backgroundColor: '#fff',
    },



    '& .settingPenImgesWrapper': {
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: '30px',
        position: 'relative',
        height: '100%',
        display: 'flex',
    },

    '& .skipPenImgesWrapper': {
        height: '100%',
        alignItems: 'center',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        paddingLeft: '33px',
        paddingBottom: '30px',
    },


    '& .settingesTitleErr': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .ProcessErrContainer': {
        flex: '6',
        display: 'flex',
        padding: '254px 24px 100px',
        backgroundColor: '#F96855',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${group_divine})`,
        overflow: 'auto',
    },

    '& .teddyesImage': {
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',

    },




    [theme.breakpoints.up('md')]: {


        '& .mainPaymentWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },


        '& .settingPenImgesWrapper': {
            paddingTop: 'unset',
        },


        '& .ProcessErrContainer': {
            paddingTop: '90px',
        },

        '& .skipPenImgesWrapper': {
            paddingTop: 'unset',
        },

        '& .teddyesImage': {
            maxWidth: 'unset',
        },

        '& .settingCheckBackground': {
            flex: 5,
        },


        '& .settingesTitleErr': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

    },

    [theme.breakpoints.up('lg')]: {


        '& .mainPaymentWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


    },
    [theme.breakpoints.down('sm')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
        '& .ProcessErrContainer': {
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            overflow: 'auto',
            flex: '6',
            display: 'flex',
            backgroundImage: `url(${group_divine})`,
            padding: '254px 24px 100px',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundSize: 'cover',

        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipPenImgesWrapper': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',

            position: 'relative',
            alignItems: 'center',
            borderBottomRightRadius: '20px',
            paddingLeft: '33px',
            paddingBottom: '30px',
        },

    },
    [theme.breakpoints.down('xs')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
        '& .ProcessErrContainer': {
            flex: '6',
            display: 'flex',
            padding: '254px 24px 100px',
            flexDirection: 'column',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            alignItems: 'center',
            backgroundImage: `url(${group_divine})`,
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipPenImgesWrapper': {
            height: '100%',
            display: 'flex',
            borderBottomRightRadius: '20px',
            paddingLeft: '33px',
            paddingBottom: '30px',
            flexDirection: 'column',
            alignItems: 'center',

            position: 'relative',
        },
    },

}))

const webStyle = {
    contactUsBtn: {
        cursor: 'pointer'
    },
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },
    checkboxErrText: {
        textAlign: "center" as "center",
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: '27px',
        letterSpacing: '0px',
        color: '#FFFFFF',
        marginTop: '10px'
    },
    titlePayText: {
        textAlign: "center" as "center",
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#FFFFFF',
        marginTop: '20px'
    },
    teddyImageLoaparent: {
        alignItems: 'center',
        justifyContent: 'center',
        // width: '232px'
    }



};
// Customizable Area End

export default PaymentError;
