Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.getSpecificAccountEndPoint = "/account_block/accounts/specific_account";
exports.getSpecificAccountMethod = "GET";
exports.getSpecificAccountContentType = "application/json";

exports.getSubscriptionDetailEndPoint = "/bx_block_stripe_integration/subscriptions";
exports.getSubscriptionDetailMethod = "GET";
exports.getSubscriptionDetailContentType = "application/json";
// Customizable Area End