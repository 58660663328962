import React from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  styled,
  LinearProgress,
  Slider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { withTranslation } from 'react-i18next'
import LessonSummaryController, { Props } from './lessonSummaryController.web'
import {
  VolumeMute,
  disableReport,
  enableReport,
  expand,
  lessonHubIcon,
  minimize,
  pause,
  playIcon,
  replayIcon,
  skipChallengesIcon,
  volume,
  volumeDown,
} from './assets'
import ReactPlayer from 'react-player'
import ReportModal from './ReportModal'
import NavigateLayout from '../../../components/src/NavigateLayout'
import MainContentCard from './MainContentCard'
import { loader } from '../../stripepayments/src/assets'
import ThankReportModal from './ThankReportModal'
import { imgHomeMenu, imgHomeMenuActive } from '../../../components/src/assets'
import { StyledButton } from '../../educationalgamification/src/MicroLessonExercise'
// Customizable Area End

export class LessonSummary extends LessonSummaryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  reportOptions = [
    {
      id: 1,
      value: "The audio doesn't sound correct.",
    },
    {
      id: 2,
      value: 'The audio is missing.',
    },
    {
      id: 3,
      value: 'The video is not loading.',
    },
    {
      id: 4,
      value: 'The video quality is poor.',
    },
    {
      id: 5,
      value: 'The video and audio are out of sync.',
    },
    {
      id: 6,
      value: 'Other',
    },
  ]

  get isDisableButtons() {
    if (!this.state.premiumUser || !this.state.videoUrl) return false

    return this.state.ended ? false : true
  }

  handleEndVideo = () => this.setState({ ended: true })
  handleGoToPremiumSubscription = () => this.props.navigation.navigate('StripePaymentsView')
  handleGoToChallenge = () => this.props.navigation.navigate('ChallengePage')
  handleGoToLandingPage = () => this.props.navigation.navigate('LandingPageWeb')
  handleCloseReportModal = () => this.setState({ isReportModalOpen: false })
  handleSubmitReport = () => {
    this.submitReportProblem()
    this.setState({ isThankreportModelOpen: true })
  }
  handleCloseThankReportModal = () => this.setState({ isThankreportModelOpen: false })
  handleMouseEnter = () => this.onMouseEvent('enter')
  handleMouseLeave = () => this.onMouseEvent('out')

  selectVolumnIcon = () => {
    if (this.state.isMuted) return VolumeMute
    return this.state.volumeLevel < 60 ? volumeDown : volume
  }

  renderMainContent = () => {
    if (!Boolean(this.state.videoUrl)) {
      return <Box style={{ width: '100%', height: '100%' }} />
    }

    return (
      <Box className="videoContainerMain">
        <div
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          style={{ position: 'relative' }}
        >
          <ReactPlayer
            className="reactPlayerMainDiv"
            volume={this.state.volumeLevel / 100}
            style={this.getVideoClarityStyle()}
            url={this.state.videoUrl}
            ref={this.player}
            controls={false}
            playing={this.state.isPlaying}
            onProgress={(progress: any) => this.handleProgress(progress)}
            onDuration={(duration: number) => this.handleDuration(duration)}
            onClick={this.toggleControl}
            onEnded={this.handleEndVideo}
            height="100%"
            width="100%"
          />
          {this.state.showVideoControls &&
            this.state.isPlaying &&
            !this.state.ended && (
              <Box className="videoControlMainDiv">
                <IconButton
                  onClick={this.handlePauseBtnClick}
                >
                  <img className="pauseBotton" src={pause} />
                </IconButton>
                <CustomVideoSlider
                  aria-labelledby="input-slider"
                  className="videoSliderProgress"
                  onChange={this.handleSlideChange}
                  value={(this.state.currentTime / this.state.duration) * 100}
                />
                <div>
                  <Typography className="timeDigit" variant="body2">
                    {this.formatTime(this.state.currentTime)} /{' '}
                    {this.formatTime(this.state.duration)}
                  </Typography>
                </div>
                <IconButton
                  onClick={this.handleVolumnBotton}
                >
                  <img
                    className="volumeBotton"
                    src={this.selectVolumnIcon()}
                  />
                </IconButton>
                {this.state.handleVolumnBtn && (
                  <div className="volumeSliderBox">
                    <VerticalVolumnSlider
                      aria-labelledby="vertical-slider"
                      orientation="vertical"
                      value={this.state.volumeLevel}
                      onChange={(_, newValue) => this.handleVolume(newValue)}
                      defaultValue={100}
                    />
                  </div>
                )}
                <IconButton
                  onClick={this.handleExpandBtnClick}
                >
                  <img
                    className="expandBotton"
                    src={this.state.isExpanded ? minimize : expand}
                    alt="Minimize"
                  />
                </IconButton>
              </Box>
            )}
          {this.state.isPlaying &&
            !this.state.showVideoControls &&
            !this.state.ended && (
              <LinearProgress
                value={this.state.videoProgress}
                className="linearVideoProgressBar"
                variant="determinate"
              />
            )}
          {!this.state.isPlaying && (
            <Button
              onClick={this.togglePlay}
              className="playBtn"
              color={'primary'}
            >
              <img
                src={playIcon}
                className="playBottonIcon"
                style={{ display: !this.state.isPlaying ? 'block' : 'none' }}
              />
            </Button>
          )}
          {this.state.ended && (
            <Button
              color={'primary'}
              className="replayBtn"
              onClick={this.handleReplayClick}
            >
              <img className="replayBottonIcon" src={replayIcon} />
            </Button>
          )}
          {this.state.premiumUser && (
            <Button
              onClick={this.handleMaterial}
              className="materialBtn"
            >
              Materials
            </Button>
          )}

          {this.state.isMaterialModelOpen && (
            <Box className="completeVideoContent">
              <Typography className="modalTextSpan">
                {this.props.t('Ready to take your learning further?')}
              </Typography>
              <Button className="downloadBotton" onClick={this.handleDownloadPDF}>
                {this.props.t('Download lesson PDF')}
              </Button>
              <Button
                style={{ marginTop: '26px', padding: '0' }}
                onClick={this.handlePlay}
              >
                <img className="completeVideoReplayBtn" src={playIcon} />
              </Button>
            </Box>
          )}

          {this.state.ended && this.state.premiumUser && (
            <Box className="completeVideoContent">
              <Typography className="modalTextSpan">
                <span>{this.props.t('What an ')}</span>
                <span className="highlightModalText">
                  {this.props.t('awesome lesson!')}
                </span>
                <br />
                <span>
                  {this.props.t(
                    "Now, let's dive into the materials to reinforce what you've learned."
                  )}
                </span>
              </Typography>
              <Button className="downloadBotton" onClick={this.handleDownloadPDF}>
                {this.props.t('Download lesson PDF')}
              </Button>
              <Button
                style={{ marginTop: '26px', padding: '0' }}
                onClick={this.handleReplayClick}
              >
                <img className="completeVideoReplayBtn" src={replayIcon} />
              </Button>
            </Box>
          )}

          {this.state.showFreeUpgradePremium && (
            <Box className="completeVideoContent">
              <Typography className="modalTextSpan">
                {this.props.t('Unlock the full video and access materials!')}
              </Typography>
              <Button
                className="premiumBtn"
                onClick={this.handleGoToPremiumSubscription}
              >
                {this.props.t('GO PREMIUM')}
              </Button>
              <Button
                style={{ marginTop: '26px', padding: '0' }}
                onClick={this.handleReplayClick}
              >
                <img className="completeVideoReplayBtn" src={replayIcon} />
              </Button>
            </Box>
          )}
        </div>
      </Box>
    )
  }

  renderContent = () => {
    if (this.state.loading) {
      return (
        <Box className="loadingWrapper">
          <img src={loader} alt="Loading" />
        </Box>
      )
    }

    return (
      <Box className="videoContainer">
        <Box className="titleMain">
          <Typography className="videoTitle">
            {this.state.videoUrl
              ? this.props.t('Watch the Video Lesson Summary:')
              : ''}
          </Typography>
        </Box>
        <Box style={{ flex: 1, width: '100%' }}>{this.renderMainContent()}</Box>
        <Box onClick={this.handleGoToChallenge} className="exploreMain">
          <img className="exploreIcon" src={skipChallengesIcon} />
          <Box>
            <Typography className="exploreTitle">
              {this.props.t('EXPLORE FREE CHALLENGES')}
            </Typography>
            <Typography className="exploreSubTitle">
              {this.props.t('Activate your math skills')}
            </Typography>
          </Box>
        </Box>
        <Box className="actionBottonMain">
          <Button
            onClick={this.handleOpenReportModal}
            disabled={this.isDisableButtons}
            className="reportBtn"
            style={{
              backgroundColor: this.isDisableButtons ? '#E6E6E6' : '#F7F7F7',
              borderBottomColor: this.isDisableButtons ? '#f2f2f2' : '#ccc',
            }}
          >
            <img
              width={18}
              height={20}
              src={this.isDisableButtons ? disableReport : enableReport}
            />
          </Button>
          <Button
            onClick={this.handleGoToLandingPage}
            disabled={this.isDisableButtons}
            className="reportBtn"
            style={{
              backgroundColor: this.isDisableButtons ? '#E6E6E6' : '#F7F7F7',
              borderBottomColor: this.isDisableButtons ? '#f2f2f2' : '#ccc',
            }}
          >
            <img
              width={18}
              height={20}
              src={this.isDisableButtons ? imgHomeMenu : imgHomeMenuActive}
            />
          </Button>
          <StyledButton
            disabled={this.isDisableButtons}
            onClick={this.handleGoToLandingPage}
          >
            {this.props.t('Continue')}
          </StyledButton>
        </Box>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Home" isShowBottomMenu={false}>
        <LessonContainer>
          <MainContentCard
            goalsOpen={this.state.goalOpen}
            closeDrawer={this.closeTrophieDrawer}
            lessonName={this.state.lessonsName}
            handleOpenDrawer={(name: string) => this.handleCenterDrawer(name)}
            navigation={this.props.navigation}
            onClickBackLandingPage={this.handleBackLandingPage}
            openDrawer={this.state.openDrawers}
            topicName={this.state.topicsName}
            unitName={this.state.unitsName}
            medianOpen={this.state.medianGradeOpen}
            drawerSide={this.state.drawer}
            trophiesOpen={this.state.trophieOpen}
            lessonNumber={localStorage.getItem('lessonNumber')}
            unitNumber={localStorage.getItem('unitNumber')}
          />
          <Box className="contentContainer fullScreenContent">
            <Box className="mainContentWrapper">
              <Box className="lessonImgWrapper">
                <Box className="imgBox">
                  <img src={lessonHubIcon} alt="lesson Hub" />
                </Box>
                <Box className="titleBox">
                  <Typography className="lessonMainTitle">
                    {this.props.t('Lesson Hub')}
                  </Typography>
                  <Typography className="lessonSubTitle">
                    {this.state.summaryVideoName}
                  </Typography>
                </Box>
              </Box>
              {this.renderContent()}
            </Box>
          </Box>
          <ReportModal
            reportOptions={this.reportOptions}
            open={this.state.isReportModalOpen}
            onClose={this.handleCloseReportModal}
            title={this.props.t('Report a problem')}
            handleOption={this.handleOption}
            onSubmitReport={this.handleSubmitReport}
          />
          <ThankReportModal
            open={this.state.isThankreportModelOpen}
            onClose={this.handleCloseThankReportModal}
          />
        </LessonContainer>
      </NavigateLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(LessonSummary)

export const CustomVideoSlider = styled(Slider)(({ theme }) => ({
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-rail': {
    backgroundColor: 'white',
    borderRadius: '20px',
    height: '14px',
    marginTop: '-6px',
  },
  '& .MuiSlider-track': {
    height: '10px',
    borderRadius: '20px',
    backgroundColor: '#62CDFF',
    marginTop: '-4px',
  },
}))

export const VerticalVolumnSlider = styled(Slider)(({ theme }) => ({
  backgroundColor: 'white',
  outline: '5px solid #f2f2f2 !important',
  outlineOffset: '2px !important',
  borderTopLeftRadius: '3px',
  borderTopRightRadius: '3px',
  '& .MuiSlider-rail': {
    border: '2px',
    backgroundColor: '#FFFFFF',
    width: '10px',
    marginLeft: '-4px',
    height: '82px',
    borderRadius: '4px',
  },
  '&.MuiSlider-vertical': {
    height: '100%',
    padding: '0 8px',
  },
  '& .MuiSlider-thumb': {
    border: '2px solid #E6E6E6',
    color: '#E6E6E6',
    marginLeft: '-5.6px',
    backgroundColor: 'white',
    width: '12px',
    height: '12px',
    borderRadius: '4px',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgba(98, 205, 255, 0.16)',
    },
  },
  '& .MuiSlider-track': {
    borderColor: '#F2F2F2',
    borderRadius: '44px',
    backgroundColor: '#62CDFF',
    width: '10px',
    height: '82px',
    border: '2px',
    marginLeft: '-4px',
  },
}))

export const LessonContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#00AEFF',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: '6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    overflow: 'auto',
    scrollbarWidth: 'none',
  },

  '& .contentContainer::-webkit-scrollbar': {
    display: 'none',
  },

  '& .mainContentWrapper': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .lessonImgWrapper': {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#00AEFF',
    borderBottomRightRadius: '20px',
    paddingTop: '35px',
    width: '100%',
  },

  '& .imgBox': {
    marginLeft: '-20px',

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },

  '& .titleBox': {
    marginLeft: '-15px',
    marginRight: '2px',

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },

  '& .lessonMainTitle': {
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#FFFFFF',

    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
    },
  },

  '& .lessonSubTitle': {
    fontSize: '14px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#C9EEFF',
    letterSpacing: '0.8%',

    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },

  '& .loadingWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },

  '& .videoContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '29px 26px 0',

    [theme.breakpoints.up('md')]: {
      padding: '29px 60px 0',
    },
  },

  '& .titleMain': {
    alignSelf: 'flex-start',
    marginBottom: '20px',
  },

  '& .videoTitle': {
    fontFamily: 'Nunito',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '26px',
    color: '#363636',
    textAlign: 'left',

    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
    },
  },
  '& .exploreMain': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    backgroundColor: 'white',
    cursor: 'pointer',
    marginTop: '20px',

    [theme.breakpoints.down('md')]: {
      padding: '14px',
    },
  },

  '& .exploreIcon': {
    marginRight: '16px',
    width: '24px',
    height: '24px',
  },

  '& .exploreTitle': {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '12px',
    letterSpacing: '0.8px',
    color: '#62CDFF',

    [theme.breakpoints.up('md')]: {
      fontSize: '17px',
    },
  },

  '& .exploreSubTitle': {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '12px',
    letterSpacing: '0.8px',
    color: '#62CDFF',
    marginTop: '5px',
    textAlign: 'start',

    [theme.breakpoints.up('md')]: {
      fontSize: '17px',
    },
  },

  '& .actionBottonMain': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '40px',
    marginTop: '20px',
    gap: '10px',
  },

  '& .reportBtn': {
    height: '40px',
    width: '44px',
    padding: 0,
    minWidth: 'unset',
    borderRadius: '10px',
    borderStyle: 'solid',
    borderBottomWidth: '4px',
  },

  '& .videoContainerMain': {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  '& .reactPlayerMainDiv': {
    maxWidth: '420px',
    borderRadius: '8px',
    overflow: 'hidden',
  },

  '& .playBtn': {
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50% , -50%)',
    left: '50%',
  },

  '& .playBottonIcon': {
    height: '50px',
    borderRadius: '10px',
  },

  '& .videoControlMainDiv': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#F2F2F2',
    borderRadius: '8px',
    height: '33px',
    position: 'absolute',
    bottom: '0',
    transform: 'translate(-50% , -50%)',
    left: '50%',

    [theme.breakpoints.up('md')]: {
      bottom: '-10px',
    },
  },

  '& .pauseBotton': {
    border: '3px',
    height: '13px',
  },

  '& .videoSliderProgress': {
    marginRight: '15px',
  },

  '& .timeDigit': {
    fontFamily: 'Nunito',
    color: '#B3B3B3',
    lineHeight: '12.4px',
    fontWeight: '700',
    width: '72px',
    fontSize: '14px',
  },

  '& .volumeBotton': {
    height: '18px',
    width: '18px',
    left: '299px',
    top: '697px',
  },

  '& .volumeSliderBox': {
    top: '-62',
    position: 'relative',
    height: '82px',
    width: '0',
    backgroundColor: '#F2F2F2',
    left: '-37',

    [theme.breakpoints.up('md')]: {
      left: '-35',
    },
  },

  '& .expandBotton': {
    height: '18px',
  },

  '& .linearVideoProgressBar': {
    height: '7px',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    color: '#62CDFF',
    borderRadius: '0 0 10px 10px',
    '@media(max-width: 768px)': {
      bottom: '0px',
    },
    '@media(max-width: 320px)': {
      bottom: '5px',
    },
  },

  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#62CDFF !important',
  },

  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: 'transparent',
  },

  '& .replayBtn': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50% , -50%)',
  },

  '& .replayBottonIcon': {
    height: '50px',
    borderRadius: '10px',
  },

  '& .materialBtn': {
    position: 'absolute',
    top: '36px',
    right: '20px',
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '12px',
    letterSpacing: '0.8%',
    alignItems: 'center',
    borderRadius: '10px',
    border: '2px solid #E6E6E6',
    color: '#62CDFF',
    height: '35px',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.1)',
    width: '109px',
    textTransform: 'none',
  },

  '& .completeVideoContent': {
    position: 'absolute',
    zIndex: 99,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },

  '& .modalTextSpan, & .modalTextSpan span': {
    textAlign: 'center',
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22.5px',
    color: '#666666',
  },

  '& .highlightModalText': {
    backgroundImage: 'linear-gradient(270deg, #A95EDB 16.78%, #62CDFF 59.7%)',
    WebkitBackgroundClip: 'text',
    color: 'transparent !important',
    fontWeight: '700 !important',
  },

  '& .downloadBotton': {
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '12px',
    letterSpacing: '0.8px',
    textAlign: 'center',
    color: '#62CDFF',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.1)',
    marginTop: '20px',
    padding: '14px 20px',
    textTransform: 'none',
    '& .MuiButton-label': {
      fontFamily: 'Nunito',
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

  '& .completeVideoReplayBtn': {
    width: '35px',
    height: '35px',
  },

  '& .premiumBtn': {
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.8px',
    textAlign: 'center',
    color: 'white',
    background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
    borderRadius: '12px',
    marginTop: '20px',
    padding: '14px 24px',
    '& .MuiButton-label': {
      fontFamily: 'Nunito',
      fontSize: '16px',
    },
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },

  '&  :fullscreen .materialBtn, :fullscreen .exploreMain, :fullscreen .actionBottonMain, :fullscreen .titleMain':
    {
      display: 'none',
    },
}))

// Customizable Area End
