import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { TFunction } from "i18next";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  t:TFunction
  navigation:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End



export default class ParentVerificationFailureController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start


    // Customizable Area End

}    