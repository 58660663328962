import React, {Component} from "react";
// Customizable Area Start
import {Box,styled} from "@material-ui/core"
import { backButton, awardWrench, awardlist1, awardlist2, awardlist3, awardlist4} from './assets'


interface Props {   
    callbackParent: any;
  }

export default class Awards extends Component<Props>{

  render() {
    return (
    <AwardContainer>
      
      <div className="topHead">
       <div className="AwardTop">
        <div className="AwardTopContent">
          <img
            src={backButton}
            className="awardArrowIcon"
            alt="backImg"
            onClick={this.props.callbackParent}
          />
          <p className="awardTitle">Awards</p>
        </div>
        <img src={awardWrench} className="AwardTopBgImage" alt="wrenchIcon"/>
      </div>
      </div> 

      <div className="corner" >
        <div style={{display:'flex', flexDirection:'column'}}>
            <img src={awardlist1} width='100%' height='130px' style={{marginTop:'10px'}} />
            <img src={awardlist2} width='100%' height='130px' style={{marginTop:'10px'}}/>
            <img src={awardlist3} width='100%' height='130px' style={{marginTop:'10px'}}/>
            <img src={awardlist4} width='100%' height='130px' style={{marginTop:'10px'}}/>
            <img src={awardlist4} width='100%' height='130px' style={{marginTop:'10px'}}/>
        </div>
      </div>
    </AwardContainer>
    );
    
  }
}

const AwardContainer = styled(Box)(({theme}) => ({ 
  width:'100%',
  background:'#ff8400',
  '& .topHead': {
     backgroundColor:'#fff'
  },
  "& .AwardTopContent": {
    width: "100%",
    height: "45px",
    flexShrink: 0,
    color: "#fff",
    letterSpacing: "0.176px",
    display: "flex",
    textAlign: "center",
    fontFamily: "sans-serif",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "26px",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative"
  },
  "& .AwardTop": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "148px",
    borderRadius: "0px 0px 20px 0px",
    background: "#FF8400",
    position: "relative",
   
  },
  "& .awardTitle": {
    margin: "0px",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 900,
    width: "min-content",
    height: "45px",
    flexShrink: 0,
    color: "var(--primary-blue-white, #fff)",
    textAlign: "center",
    fontFamily: '"Nunito", sans-serif',
    lineHeight: "26px",
    letterSpacing: "0.176px"
  },
  "& .awardArrowIcon": {
    width: "16px",
    height: "16px",
    flexShrink: 0,
    position: "absolute",
    left: "38.3px",
    cursor: "pointer"
  },
  "& .AwardTopBgImage": {
    position: "absolute",
    bottom: "0px",
    right: "5%"
  }, 
  "& .corner": {
    borderRadius: '30px 0 0 0',
    overflow: 'auto',
    background: 'white', 
    maxHeight: '740px',
    "@media(max-width: 959px)": {
        borderRadius: '0px'
    }
  },

}))
// Customizable Area End
