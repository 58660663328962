import React, {Component} from "react";
// Customizable Area Start
import {Box, Typography, styled} from "@material-ui/core"
import {moreButton, bolt, rectangle, time, challenge, trophies, goal,doublex} from './assets'
import { Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.min.css"; // core Swiper
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import { withTranslation } from "react-i18next";

interface Props {
  premium: boolean;
  staticsData: any;
  t?:any
}


class StatisticsDetail extends Component<Props> {
 
  StatisticsData = 
  [
     {name: "Total XP Earned", points: this.props.staticsData?.total_xp, image: bolt},
     {name: "Lessons Completed", points: this.props.staticsData?.completed_lessons, image: rectangle},
     {name: "Minutes Spent", points: this.props.staticsData?.minutes_spent, image: time},
     {name: "Challenges Overcome", points: this.props.staticsData?.challenge_completed, image: challenge},
     {name: "Epic Trophies", points: this.props.staticsData?.epic_trophies, image: trophies},
     {name: "Medals Achieved", points: this.props.staticsData?.medals_achieved, image: goal},
     {name: "Daily Streak Boost", points: this.props.staticsData?.daily_streak_boots, image: doublex},
 ]


 mappingGroupPaginationSwiper(data: any) {
  const result = [];
  for (let i = 0; i < data.length; i += 4) {
    result.push(data.slice(i, i + 4));
  }
  return result;
}

  render() {
   

    return (
        <StatisticsContainer>
          <Box className="headStatiscs">
            <Typography className="sta_head">{this.props.t("Statistics")}</Typography>
          </Box>
          <Box >
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {this.mappingGroupPaginationSwiper(this.StatisticsData).map((newData:any) => (
              <SwiperSlide className="displayList">
                {newData.map((item:any)=> (
                  <Box className="boxes">
            <img src={item.image} width="15px" height="20px" alt="img"/>
            <Box className="containerpoints">
              <Typography className="boxPoint">{item.points}</Typography>
              <Typography className="textShow">{this.props.t(item.name)}</Typography>
            </Box>
          </Box>
                ))}
              
          </SwiperSlide>
            ))} 
</Swiper>
        </Box>
    
      </StatisticsContainer>
    );
  
  }
}
export default withTranslation()(StatisticsDetail)

const StatisticsContainer = styled(Box)(({theme}) => ({ 
  borderRadius: "10px",
  border: "2px solid  #F7F7F7",
  background: "#F7F7F7", 
  padding: "10px 20px 10px 20px",
  marginTop:'20px',

  '@media(max-width:375px)':{
    padding: "10px",
  },

  '& .sta_head': {
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    letterSpacing: "1px"
  },

  "& .mySwiper":{
    width:'400px !important',
    height:'220px',
    '& .swiper-pagination-bullet-active':{
      background: "#000"
    },
    '@media(max-width:520px)':{
      width:"100% !important"
    }
  },
  '& .headStatiscs': {
    display:'flex',
    justifyContent:'space-between', 
    marginBottom:'20px'
  }, 

  '& .boxes': {
  width: "145px",
  height: "68px",
  flexShrink: 0,
  borderRadius: "10px",
  border: "2px solid #E6E6E6",
  background: "#FFF", 
  padding:'10px 16px 10px 16px',
  display:'flex',
  justifyContent:'space-around', 
  gap:'10px',
  alignItems:'center'
  }, 

  '& .containerpoints': {
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-around'
  },

  '& .boxPoint': {
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "14.4px",
    marginBottom:'5px'
  },

  '& .textShow': {
    color: "#363636",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "14px", 
    textTransform: "uppercase",
  }, 
  '& .displayList': {
    display:'flex',
    flexFlow:'wrap',
    justifyContent:'space-between'
  }

}))
// Customizable Area End
