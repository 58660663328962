// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from 'framework/src/Message'

export interface Props {
  navigation: any
  id: string
  t?:any
}

interface S {
  emailResetPassword: string
  isLoading: boolean
  showAlert: boolean
  popupData: {
    text: string
    type: 'success' | 'error'
  }
}

interface SS {
  id: any
}

export default class CheckEmailForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendEmailForgotPasswordId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      emailResetPassword: '',
      isLoading: false,
      showAlert: false,
      popupData: {
        text: '',
        type: 'success',
      },
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.setState({
      emailResetPassword: localStorage.getItem('emailResetPassword') || '',
    })
    localStorage.setItem('emailResetPassword', '')
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )

    if (apiRequestCallId && responseJson) {
      console.log(
        'http status',
        message.getData(getName(MessageEnum.RestAPIResponceHttpStatus))
      )
      if (apiRequestCallId === this.sendEmailForgotPasswordId) {
        if (responseJson?.errors) {
          this.setState({
            isLoading: false,
            showAlert: true,
            popupData: {
              text: responseJson?.errors?.[0]?.otp,
              type: 'error',
            },
          })
          return
        }
        this.setState({
          isLoading: false,
          showAlert: true,
          popupData: {
            text: 'Reset email is sent successfully!',
            type: 'success',
          },
        })
      }
    }
  }

  reSendEmail = () => {
    const data = { email: this.state.emailResetPassword }
    let body: object = {
      data: {
        attributes: data,
      },
    }
    this.setState({ isLoading: true })
    this.sendEmailForgotPasswordId = sendAPIRequest(
      'bx_block_forgot_password/otps',
      {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}
// Customizable Area End
