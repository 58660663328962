import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    

} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    skip_wrench,
    btn_back,
    diamond,
    arrowDetail,
    premiumDashboard1,
    premiumDashboard3,
    premiumDashboard2,
    premiumDashboard4,
} from "./assets";
import { withTranslation } from "react-i18next";
import CenterPortion from "./CenterPortion.web"
// Customizable Area End
import PremiumDashboardController from "./PremiumDashboardController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";

// Customizable Area Start
const sections = [
    {
        text: 'Premium lessons',
        text2: "You're currently experiencing + 1000 extra full, live and premium lessons.",
        text3: 'Coming Soon',
        img_src: premiumDashboard1,
    },
    {
        text: 'Customize Avatar',
        text2: 'A splash of fun with vibrant colors to make it yours!',
        text3: 'Coming Soon',
        img_src: premiumDashboard2,
    },
    {
        text: 'Unlimited lessons',
        text2: "You're learning anything you want with no limits!",
        text3: 'Coming Soon',
        img_src: premiumDashboard3,
    },
    {
        text: 'Win cool prizes',
        text2: 'Compete with others and win exciting rewards.',
        text3: '',
        img_src: premiumDashboard4,
    },

];


// Add more objects as needed

// Customizable Area End
class PremiumDashboard extends PremiumDashboardController {

    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings">
                <SettingContainer>
                    <Box className="mainContentDasWrapper">

                        <Box className="settingDasBackground">
                        <CenterPortion navigation={undefined} id={""} />
                        </Box>

                        <Box className="contentContainerDas">
                            <Box className="setting-background">
                                <Box className="setting-img-wrapper">
                                    <img src={skip_wrench} className="teddy-image" alt="Teddy Setting" />
                                    <Typography className="setting-title">
                                        <img src={btn_back} className="arrow-image" alt="arrow btn" style={{ marginRight: '5px' }} onClick={this.props.navigation.goBack} data-test-id="btn-back" />
                                        <Typography className="setting-title-text">{this.props.t("Subscription")}</Typography>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className="currentPlan">
                                <Typography component='div' className="currentPlan__title" style={{ textTransform: 'uppercase' }}>
                                    {this.props.t("Current Plan")}
                                </Typography>
                                <Box className="currentPlan__content">
                                    <Box style={webStyle.freeimg}>
                                        <img src={diamond} alt="diamond" />
                                    </Box>
                                    <Box className="currentPlan__content-textBox currentPlan__content--column ">
                                        <Typography style={webStyle.frfirstText}>
                                            {this.props.t("Premium")}
                                        </Typography>
                                        <Typography style={webStyle.frsecondText} >
                                            {this.props.t("Exclusive benefits, limitless learning!")}
                                        </Typography>
                                        <Box data-testid="link-detail" style={{ display: "flex", alignItems: "baseline", width: "100px", gap: "5px", cursor: "pointer", marginTop: "10px" }} onClick={() => this.props.navigation.navigate('PlanDetails')}>
                                            <Typography style={webStyle.frthirdText} >
                                                {this.props.t("See details")}
                                            </Typography>
                                            <img src={arrowDetail} alt="skip_arrow" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={webStyle.premiumTextTitle}>
                                <Typography style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    lineHeight: '12px',
                                    letterSpacing: '0px',
                                    textAlign: 'left',
                                    color: '#FFFFFF',
                                }}>
                                    {this.props.t("Premium Dashboard")}
                                </Typography>
                            </Box>

                            <Box className="sectionsBlock" style={{ width: '100%', display: "flex", flexDirection: "column", gap: "12px", padding: "20px 0px" }}>
                                {sections.map((section) => (
                                    <Box key={section.text} className="currentPlan" style={{ marginTop: 0 }}>
                                        <Box className="currentPlan__content currentPlan__title">
                                            <Box >
                                                <div style={webStyle.freeimg}>
                                                    <img src={section.img_src} alt="imgIcon" width={37} height={37} />
                                                </div>
                                            </Box>
                                            <Box className="currentPlan__content-textBox currentPlan__content--column ">
                                                <Typography style={webStyle.premiumText}>
                                                    {this.props.t(section.text)}
                                                </Typography>
                                                <Typography style={webStyle.premiumTextFirst} >
                                                    {this.props.t(section.text2)}
                                                </Typography>

                                                <Box data-testid='link-detail' style={{ display: "flex", alignItems: "baseline", width: "100%", gap: "5px", cursor: "pointer", marginTop: '11px' }}>
                                                    <Typography style={webStyle.frthirdText} >
                                                        {this.props.t(section.text3)}
                                                    </Typography>
                                                </Box>

                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <BottomMenu />
                    </Box>
                </SettingContainer>
            </NavigateLayout>

            // Customizable Area End
        );
    }
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    "& .setting-title-text": {
        fontWeight: '900',
        fontSize: '22px',
        color: '#fff',
        fontFamily: 'Nunito'
    },

    '& .arrow-image': {
        left: '0',
        position: 'absolute',
        top: '55%',
    },


    '& .teddy-image': {
        Width: '245px',
        position: 'absolute',
        bottom: '0px',
        marginRight: '10px',
        marginLeft: '0',
        display: 'block',
        left: '34px',
        padding: '0',
    },

    '& .setting-background': {
        height: '111px',
        width: 'calc(100% + 48px)',
        background: "#A95EDB",
        borderBottomRightRadius: '20px'
    },
    "& .teddy-setting-image": {
        bottom: '0px',
        Width: '245px',
        marginLeft: '0',
        position: 'absolute',
        display: 'block',
        marginRight: '10px',
        padding: '0',
        left: 'calc(45% - 207px)',

    },
    "& .setting-title": {
        fontWeight: '900',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        marginBottom: '12px',
        fontSize: '22px',
        paddingTop: '22px',
        fontFamily: 'Nunito',
        width: '100%',
        position: 'relative'

    },
    "& .setting-img-wrapper": {
        height: '100%',
        flexDirection: 'column',
        paddingLeft: '33px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        display: 'flex',
        paddingBottom: '30px',
        position: 'relative',

    },
    "& .currentPlan": {
        width: '100%',
        maxWidth: 600,
        border: '2px solid #F2F2F2',
        borderRadius: "12px",
        marginTop: '30px'
    },
    "& .sectionsBlock": {
        maxWidth: 600,
        marginTop: '20px'
    },
    "& .currentPlan__title": {
        color: '#999999',
        fontSize: '12px',
        lineHeight: "12px",
        padding: "14px 18px",
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },

    "& .currentPlan__content": {
        width: '100%',
        alignItems: 'center',
        padding: '16px 20px',
        backgroundColor: '#0000004D',
        display: 'flex',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
    },

    "& .currentPlan__content--column": {
        flexDirection: "column"
    },

    "& .currentPlan__content-textBox": {
        marginLeft: "15px"
    },

    "& .MuiGrid-spacing-xs-2": {
        margin: "0px",
        alignItems: "center"
    },

    "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
        padding: "0px"
    },

    '& .mainContentDasWrapper': {
        display: 'flex',
        background: "linear-gradient(180deg, #A95EDB 0%, #62CDFF 100%)",
        flexDirection: 'column',
        height: 'auto%',
        paddingBottom: '60px'
    },

    '& .settingDasImgWrapper': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        justifyContent: 'center',
        flexDirection: 'row',
        position: 'relative',
    },
    '& .settingDasBackground': {
        marginBottom: "-12px",
        backgroundColor: '#A95EDB',
    },
    '& .settingTitleDas': {
        display: 'flex',
        justifyContent: "center",
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        width: '100%',
        fontWeight: '900',
        paddingTop: '22px',
        fontFamily: 'Nunito',
        textAlign: 'center',
        position: "relative"
    },
    '&.arrowImage': {
        width: "30%",
        height: "auto",
    },

    '& .teddysImageDas': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "10%"
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },
    '& .contentContainerDas': {
        flex: '6',
        background: 'linear-gradient(180deg, #A95EDB 0%, #62CDFF 100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'scroll',
        padding: '32px 24px 100px',
        paddingTop: '0',
    },

    '& .benefitsListTitle': {
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
    },

    [theme.breakpoints.up('md')]: {
        // "& .currentPlan": {
        //     marginTop: '0'
        // },
        '& .teddy-image': {
            display: 'none',
            maxWidth: 'unset',
        },

        '& .setting-background': {
            height: '111px',
            width: 'calc(100% + 48px)',
        },
        "& .teddy-setting-image": {
            width: '240px',
            maxWidth: 'unset',
            left: 'calc(45% - 120px)'
        },
        "& .setting-title": {
            fontSize: '30px',
            marginBottom: 'unset'
        },
        "& .setting-img-wrapper": {
            paddingTop: 'unset'
        },
        '& .settingDasBackground': {
            flex: 5,
            height: "100%"
        },

        '& .settingTitleDas': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .currentPlan,.sectionsBlock': {
            maxWidth: 450,
        },

        '& .settingDasImgWrapper': {
            paddingTop: 'unset',
        },
        '& .mainContentDasWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
            height: "100%",
            paddingBottom: 0
        },

        '& .contentContainerDas': {
            paddingTop: '0',
            overflowX: "hidden",
            overflowY: "scroll",
            height: "100%"
        },

        '& .teddysImageDas': {
            maxWidth: 'unset',
            width: "30%",

        },
    },

    [theme.breakpoints.up('lg')]: {
        "& .teddy-setting-image": {
            left: 'calc(45% - 207px)',
            width: 'auto'
        },
        '& .mainContentDasWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },
        '& .currentPlan,.sectionsBlock': {
            maxWidth: '100%',
        },

    },
    [theme.breakpoints.down('sm')]: {
        // "& .settingTitleDas button": {
        // },
        "& .contentContainerDas": {
            padding: '32px 24px 65px',
            overflow: 'scroll',
            alignItems: 'center',
            backgroundColor: '#fff',
            flexDirection: 'column',
            display: 'flex',
            paddingTop: '0',
            flex: '6',

        },
        "& .setting-title img": {
            position: 'absolute',
            bottom: '10px',
            left: '0'
        },
        "& .settingDasBackground": {
            display: 'none'
        },
        "& .settingTitleDas button": {
            marginLeft: "-8px",
            position: "absolute",
            left: 0,
            top: "50%",
            padding: "4px"
        }
    },

    [theme.breakpoints.down("xs")]: {

        "& .settingDasBackground": {
            display: 'none'
        },

        "& .contentContainerDas": {

            flex: '6',
            padding: '32px 24px 10px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            overflowX: 'hidden',
            backgroundColor: '#fff',
            overflow: 'scroll',
            paddingTop: '0'
        }
    }


}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },
    premiumTextTitle: {
        marginTop: '40px',
    },
    premiumtextTitle: {
        fontFamily: 'Nunito',
        fontSize: '25px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        width: '100%',
        paddingLeft: '20px'
    },
    textMainsection: {
        marginTop: '10px',
    },
    freeimg: {
        background: 'transparent',
        borderRadius: '50%',
        width: '37px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    frfirstText: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        color: 'transparent',
        lineHeight: '15px',
        background: 'linear-gradient(90deg, #4DEAFF 0%, #D693FF 39.2%, #FFFFFF 83.62%), linear-gradient(90deg, #51E8FF 0%, #D693FF 38.36%, #FFFFFF 82.76%)',
        '-webkit-background-clip': 'text',
        'background-clip': 'text',
    },
    frsecondText: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        color: '#fff',
        lineHeight: '15px',
        marginTop: '9px'
    },
    frthirdText: {
        lineHeight: '9px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#B7F0AD',
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 700,
    },
    premiumText: {
        fontFamily: 'Quicksand',
        fontSize: '22px',
        fontWeight: 700,
        color: '#fff'
    },
    premiumTextFirst: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#FFFFFF',
    },
    premiumTextLast: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '9px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#B7F0AD',
        marginTop: '9px',
    },

};

export default withTranslation()(PremiumDashboard)
// Customizable Area End
