import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isLoading: boolean
    showErrorAlert: boolean
    errorText: string
    emailResetPassword: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordControllerWeb extends BlockComponent<
    Props,
    S,
    SS
>{
    // Customizable Area Start
    sendEmailForgotPasswordId: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            showErrorAlert: false,
            errorText: '',
            emailResetPassword: ''
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const errorResponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );
          
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.sendEmailForgotPasswordId) {
                if (responseJson?.errors) {
                    this.setState({
                        isLoading: false, 
                        errorText: responseJson?.errors?.[0]?.otp,
                        showErrorAlert: true
                    })
                    return 
                }
                  localStorage.setItem("emailResetPassword", this.state.emailResetPassword)
                  this.setState({isLoading: false})
                  this.onNavigate('CheckEmailForgotPassword')
            }
        }
    }

    submitFormData = (email: string) => {
        const data = { email: email }
        let body: object = {
            data: {
                attributes: data
            }
        }
        this.setState({isLoading: true, emailResetPassword: email})
        this.sendEmailForgotPasswordId = sendAPIRequest('bx_block_forgot_password/otps', {
            method: "POST",
            body: body,
            headers: { 
                'Content-Type': 'application/json',
             },
        });
    }

    onNavigate(navigation: string) {
        this.props.navigation?.navigate(navigation);
    }

    // Customizable Area End
}
