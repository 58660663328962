import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  LinearProgress,
  Slider,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ReactPlayer from "react-player";
import WellDoneShare from "./WellDoneShare";
import ReportModal from "./ReportModal";
import IconButton from "@material-ui/core/IconButton";
import {
  playIcon,
  replayIcon,
  pause,
  volume,
  expand,
  reportenable,
  flag,
  minimize,
  volumeDown,
  VolumeMute,
  infoSummary,
} from "./assets";
import { withTranslation } from "react-i18next";
export const configJSON = require("../../../framework/src/config");
import MicroSummary from "./MicroSummary.web";
// Customizable Area End

import CftableforlessonsvideoController, {
  Props,
} from "./CftableforlesonsvideoController";
import ThankReportModal from "./ThankReportModal";
import { t } from "i18next";
import { convertLatex } from "../../../components/src/utils";
import { MathFormula } from "../../../components/src/MicrolessonExercise/ExerciseComponents";

class Cftableforlessonsvideo extends CftableforlessonsvideoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  volumeImgIcon = () => {
    return this.state.volumeLevel < 60 ? (
      <img className="volumeBtn" src={volumeDown} />
    ) : (
      <img className="volumeBtn" src={volume} />
    );
  };
  reportOptions = [
    {
      id: 1,
      value: "The video is not loading.",
    },
    {
      id: 2,
      value: "The video quality is poor.",
    },
    {
      id: 3,
      value: "The audio is missing.",
    },
    {
      id: 4,
      value: "The images are not loading.",
    },
    {
      id: 5,
      value: "The text is not displaying correctly.",
    },
    {
      id: 6,
      value: "Other",
    },
  ];

  CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-thumb": {
      display: "none",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "white",
      borderRadius: "20px",
      height: "14px",
      marginTop: "-6px",
    },
    "& .MuiSlider-track": {
      height: "10px",
      borderRadius: "20px",
      backgroundColor: "#62CDFF",
      marginTop: "-4px",
    },
  }));

  verticalSlider = styled(Slider)(({ theme }) => ({
    outline: "5px solid #f2f2f2 !important",
    outlineOffset: "2px !important",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    backgroundColor: "white",
    "&.MuiSlider-vertical": {
      padding: "0 8px",
      height: "100%",
    },
    "& .MuiSlider-thumb": {
      color: "#E6E6E6",
      marginLeft: "-5.6px",
      backgroundColor: "white",
      width: "12px",
      height: "12px",
      borderRadius: "4px",
      border: "2px solid #E6E6E6",
      "&:hover, &.Mui-focusVisible": {
        boxShadow: "0px 0px 0px 8px rgba(98, 205, 255, 0.16)",
      },
    },
    "& .MuiSlider-track": {
      borderRadius: "44px",
      backgroundColor: "#62CDFF",
      width: "10px",
      height: "82px",
      border: "2px",
      marginLeft: "-4px",
      borderColor: "#F2F2F2",
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#FFFFFF",
      width: "10px",
      marginLeft: "-4px",
      height: "82px",
      borderRadius: "4px",
      border: "2px",
    },
  }));

  renderVideoPlayer() {
    return (
      <Box className={`player ${this.state.isExpanded ? "expanded" : ""}`}>
        <div
          data-test-id="player"
          onMouseEnter={() => this.onMouseEvent("enter")}
          onMouseLeave={() => this.onMouseEvent("out")}
          style={{ position: 'relative' }}
        >
          <ReactPlayer
            className="reactPlayerDiv"
            url={this.props?.videoUrl?.attributes?.summary_video}
            ref={this.handleRef}
            data-test-id="reactPlayer"
            controls={false}
            playing={this.state.isPlaying}
            onProgress={(progress: any) => this.handleProgress(progress)}
            onDuration={(duration: number) => this.handleDuration(duration)}
            onClick={this.toggleControls}
            onEnded={() => this.setState({ ended: true, isPlaying: false })}
            volume={this.state.volumeLevel / 100}
            width="100%"
            height="unset"
            onReady={this.handleSetVideoReady}
          />
          {!this.state.showVideoControls &&
            this.state.isPlaying &&
            !this.state.ended && (
              <Box
                className={`videoControlMain ${this.state.isExpanded ? "expanded" : ""
                  }`}
              >
                <IconButton
                  data-test-id="pauseBtn"
                  onClick={this.handlePauseClick}
                >
                  <img className="pauseBtn" src={pause} />
                </IconButton>
                <this.CustomSlider
                  className="videoSlider"
                  data-test-id="videoSlider"
                  value={(this.state.currentTime / this.state.duration) * 100}
                  onChange={this.handleSliderChange}
                  aria-labelledby="input-slider"
                />
                <div>
                  <Typography className="timingDigits" variant="body2">
                    {this.formatTime(this.state.currentTime)} /{" "}
                    {this.formatTime(this.state.duration)}
                  </Typography>
                </div>
                <IconButton
                  data-test-id="volumnBtn"
                  onClick={this.handleVolumnBtn}
                >
                  {this.state.isMuted ? (
                    <img className="volumeBtn" src={VolumeMute} />
                  ) : (
                    this.volumeImgIcon()
                  )}
                </IconButton>
                {this.state.handleVolumnBtn && (
                  <div className="volumeSliderContainer">
                    <this.verticalSlider
                      data-test-id="volumeSlider"
                      orientation="vertical"
                      value={this.state.volumeLevel}
                      onChange={(_, newValue) =>
                        this.handleVolumeChange(newValue)
                      }
                      defaultValue={100}
                      aria-labelledby="vertical-slider"
                    />
                  </div>
                )}
                {this.state.isExpanded ? (
                  <IconButton
                    data-test-id="minimizeClick"
                    onClick={this.handleExpandClick}
                  >
                    <img
                      className="expandBtn"
                      src={minimize}
                      alt="Minimize"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    data-test-id="expandClick"
                    onClick={this.handleExpandClick}
                  >
                    <img className="expandBtn" src={expand} alt="Expand" />
                  </IconButton>
                )}
              </Box>
            )}
          {this.state.showVideoControls &&
            this.state.isPlaying &&
            !this.state.ended && (
              <LinearProgress
                className="linearProgressBar"
                variant="determinate"
                value={this.state.videoProgress}
              />
            )}
        </div>
        {this.state.isVideoReady && !this.state.isPlaying && (
          <Button
            data-test-id="toggelPlayBtn"
            className="playButton"
            color={"primary"}
            onClick={this.togglePlay}
          >
            <img
              className="playBtnIcon"
              style={{
                borderRadius: "10px",
                display: !this.state.isPlaying ? "block" : "none",
              }}
              src={playIcon}
            />
          </Button>
        )}
        {this.state.ended && (
          <Button
            data-test-id="toggelReplayBtn"
            className="replayButton"
            color={"primary"}
            onClick={this.handleReplayClick}
          >
            <img
              className="replayBtnIcon"
              src={replayIcon}
              style={{
                borderRadius: "10px",
              }}
            />
          </Button>
        )}
      </Box>
    );
  }

  renderTextBox() {
    let tempArr: any = [];
    let temp =
      this.props?.videoUrl?.attributes?.summary_video.split("_IMAGEURL_");
    temp.map((item: string) => {
      if (item.startsWith(configJSON.baseURL)) {
        tempArr.push({
          type: "image",
          data: item,
        });
      } else {
        tempArr.push({
          type: "text",
          data: item,
        });
      }
    });
    return (
      <div
        id="textBox"
        className="textBoxMain"
        data-testId="videoContentBox"
        ref={this.divRef}
        
      >
        <div ref={this.typographyRef} className="microlessonText" onScroll={this.handleScroll}>
          {tempArr.map((item: any, index: number) => {
            if (item.type === "text") {
              return (
                <MathFormula
                  key={`text_${index}`}
                  formula={convertLatex(item.data)}
                  color='#666666'
                  fontFamily='Quicksand'
                  fontSize={18}
                  fontWeight={500}
                  lineHeight='12px'
                  width={this.state.componentWidth - 40}
                />
              );
            } else if (item.type === "image") {
              return <img className="textImage" key={`image_${index}`} src={item.data} alt="Image" />;
            }
          })}
        </div>
      </div>
    );
  }

  renderNoData() {
    return (
      <Typography
        style={{ padding: "20px", fontFamily: "Nunito", fontSize: "16px" }}
      >
        {this.props.t("There is no video.")}
      </Typography>
    );
  }

  renderActionButtons() {
    return (
      <Box className="actionBtnMain">
        <Button
        data-test-id="handleMicroSummaryBtn"
          onClick={this.handleMicroSummary}
          style={{
            height: "44px",
            width: "44px",
          }}
        >
            <img
              style={{
                borderRadius: "10px",
                backgroundColor: "#f2f2f2",
              }}
              src={infoSummary}
              alt=""
            />
        </Button>
        <Button
          data-test-id="openReportModal"
          onClick={this.handleOpenReportModal}
          disabled={!this.state.ended && !this.state.isScrollDown}
          style={{
            height: "44px",
            width: "44px",
            marginLeft: !this.state.ended ? "-8px" : "-9px",
          }}
        >
          {!this.state.isScrollDown && !this.state.ended ? (
            <img
              style={{
                borderRadius: "10px",
                backgroundColor: "#f2f2f2",
                padding: "10px",
              }}
              src={flag}
              alt=""
            />
          ) : (
            <img
              style={{
                borderRadius: "10px",
                backgroundColor: "#f2f2f2",
              }}
              src={reportenable}
              alt=""
            />
          )}
        </Button>
        {!this.state.ended && !this.state.isScrollDown ? (
          <Button
            onClick={this.handleNavigateMicrolessonExercise}
            disabled={!this.state.ended && !this.state.isScrollDown}
            className="continueBtn"
          >
            {this.props.t("Continue")}
          </Button>
        ) : (
          <Button
            data-test-id="continueBtn"
            onClick={this.handleNavigateMicrolessonExercise}
            className="continueBtnEnable"
          >
            {this.props.t("Continue")}
          </Button>
        )}
      </Box>
    );
  }
  // Customizable Area End
  render() {
    const { videoUrl } = this.props;
    const hasSummaryVideo = videoUrl?.attributes?.summary_video;
    let contentToRender;
    if (hasSummaryVideo) {
      const isHttpUrl = videoUrl.attributes?.summary_video.startsWith("http");
      contentToRender = isHttpUrl
        ? this.renderVideoPlayer()
        : this.renderTextBox();
    } else {
      contentToRender = this.renderNoData();
    }
    return (
      // Customizable Area Start
      <CftableforlessonsVideoContainer>
        <Box className="videoContainer">
          {contentToRender}
          {this.state.showMicroSummary && (
            <MicroSummary
              onClose={() => this.setState({ showMicroSummary: false })} 
              carouselData={this.state.carouselDataImages}       
            />
          )}
          {hasSummaryVideo && this.renderActionButtons()}
          <ReportModal
            data-test-id="reportModal"
            reportOptions={this.reportOptions}
            open={this.state.isReportModalOpen}
            onClose={() => this.setState({ isReportModalOpen: false })}
            title={t("Report a problem")}
            handleOption={this.handleOption}
            onSubmitReport={() => {
              this.ReportProblem();
              this.setState({ isThankreportModelOpen: true });
            }}
          />
          <ThankReportModal
            data-test-id="thankReportModal"
            open={this.state.isThankreportModelOpen}
            onClose={() => this.setState({ isThankreportModelOpen: false })}
          />
          <WellDoneShare
            data-test-id="wellDoneShare"
            open={this.state.iswellDoneShareModelOpen}
            onClose={() => this.setState({ iswellDoneShareModelOpen: false })}
          />
        </Box>
      </CftableforlessonsVideoContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(Cftableforlessonsvideo);
export const CftableforlessonsVideoContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",

  " & .player": {
    flex: 1,
    display: "flex",
    justifyContent: 'center',
    position: "relative !important",
    width: "100%",
    alignItems: "center",
    marginTop: "15px",
    padding: '0 26px',
  },
  "& .reactPlayerDiv": {
    borderRadius: '8px',
    overflow: 'hidden',
    maxWidth: 'calc((100vw) * 0.8)',

    '& video': {
      height: 'unset !important',
    }
  },
  " & .playButton": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50% , -50%)",
  },
  " & .replayButton": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50% , -50%)",
  },
  "& .playBtnIcon": {
    height: "50px",
  },
  "& .replayBtnIcon": {
    height: "50px",
  },
  "& .linearProgressBar": {
    height: "7px",
    width: "100%",
    position: "absolute",
    bottom: "0",
    color: "#62CDFF",
    borderRadius: "8px",
    "@media(max-width: 768px)": {
      bottom: "0px",
    },
    "@media(max-width: 320px)": {
      bottom: "5px",
    },
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "#62CDFF !important",
  },
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "transparent",
  },
  "& .videoContainer": {
    flex: "6",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100% !important",
    position: 'relative',
  },

  "& .textBoxMain": {
    width: '100%',
    
    
    marginTop: "20px",
    padding: '0 26px',
    
    "&::before ": {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '6px',
      height: '100%',
      backgroundColor: '#CCCCCC',
      borderRadius: '6px'
    },
  },
  "& .textImage": {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    "@media (minWidth: 768px)": {
      maxWidth: '80%',
    },
    "@media (minWidth: 1200px)": {
      maxWidth: '60%',
    }
  },
  "& .microlessonText": {
    padding: "20px",
    border: "2px solid #E6E6E6",
    borderRadius: "8px",
    overflow: "auto",
    height: "calc(100vh - 300px)",
    "@media (min-width: 320px) and (max-width: 768px)": {
      height: "calc(100vh - 300px)!important",
    },
    "@media (min-width: 768px) and (max-width: 960px)": {
      height: "calc(100vh - 300px)!important",
    },
    "@media (min-width: 960px) and (max-width: 2560px)": {
      height: "calc(100vh - 300px)!important",
    },

    "&::-webkit-scrollbar": {
      width: "6px",
      marginTop: "5px",
      marginRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#CCCCCC",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },
  },
  "& .videoControlMain": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#F2F2F2",
    borderRadius: "8px",
    height: "33px",
    position: "absolute",
    bottom: "0",
    transform: "translate(-50% , -50%)",
    left: "50%",
  },
  "& .pauseBtn": {
    height: "13px",
    top: "702px",
    left: "49px",
    border: "3px",
  },
  "& .videoSlider": {
    marginRight: "15px",
  },
  "& .timingDigits": {
    color: "#B3B3B3",
    fontFamily: "Nunito",
    fontWeight: "700",
    lineHeight: "12.4px",
    fontSize: "14px",
    width: "72px",
  },
  "& .volumeBtn": {
    width: "18px",
    height: "18px",
    top: "697px",
    left: "299px",
  },
  "& .volumeSliderContainer": {
    position: "relative",
    top: "-62",
    width: "0",
    height: "82px",
    left: "-37",
    backgroundColor: "#F2F2F2",
  },
  "& .expandBtn": {
    height: "18px",
  },
  " & .actionBtnMain": {
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    margin: "30px 0 90px",
    padding: '0 26px',
  },
  "& .continueBtn": {
    backgroundColor: "#f2f2f2",
    fontFamily: "Nunito",
    color: "#B3B3B3",
    width: "100%",
    borderRadius: "12px",
    textTransform: "capitalize",
    height: "40px",
    fontWeight: "bold",
    "& .MuiButton-label": {
      fontFamily: "Nunito",
      fontSize: "16px",
    },
  },
  "& .continueBtnEnable": {
    backgroundColor: "#36BFFF",
    fontFamily: "Nunito",
    color: "white",
    width: "100%",
    borderRadius: "12px",
    textTransform: "capitalize",
    height: "40px",
    fontWeight: "bold",
    "& .MuiButton-label": {
      fontFamily: "Nunito",
      fontSize: "16px",
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .reactPlayerDiv": {
      maxWidth: 'calc((100vw - 282px) * 0.4)',
    },
    "& .playBtnIcon": {
      height: "70px",
    },
    " & .player": {
      padding: '0 60px',
    },
    "& .videoContainer": {
      height: "100% !important",
    },

    "& .replayBtnIcon": {
      height: "70px",
    },
    "& .videoControlMain": {
      bottom: "-10px",
      width: "100%",
    },
    "& .volumeBtn": {
      width: "18px",
      height: "18px",
      top: "697px",
      left: "299px",
    },
    "& .timingDigits": {
      lineHeight: "12.4px",
      color: "#B3B3B3",
      fontFamily: "Nunito",
      fontWeight: "700",
      fontSize: "14px",
    },
    "& .volumeSliderContainer": {
      position: "relative",
      top: "-62",
      width: "0",
      height: "82px",
      left: "-35",
      backgroundColor: "#F2F2F2",
    },
    " & .actionBtnMain": {
      padding: '0 60px',
      marginBottom: '40px',
    },

    '& .textBoxMain': {
      padding: '0 60px',
    }
  },
  [theme.breakpoints.up("lg")]: {
    "& .mainContentWrapper": {
      width: `calc(100% - 282px)`,
      paddingLeft: "282px",
    },
  },
  [theme.breakpoints.up('xl')]: {
    "& .reactPlayerDiv": {
      maxWidth: 'calc((100vw - 282px) * 0.3)',
    },
  }
}));

// Customizable Area End
