import React from "react";
// Customizable Area Start
import { Box, Typography, styled, IconButton } from "@material-ui/core";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { rectangle, } from '../../landingpage/src/assets';
import { withTranslation } from "react-i18next";
import { Back, Search, groupscroll, maskMath, groupMaskMath } from "./assets";
import LessonController from "./LessonController.web";
//@ts-ignore
import Graph from "react-graph-vis"

import MaterialModal from "./MaterialModal.web";
import MaterialGradeScreenWeb from "./MaterialGradeScreen.web";
// Customizable Area End
class LessonPage extends LessonController {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  options = {
    autoResize: true,
    physics: {
      solver: "barnesHut",
      barnesHut: {
        avoidOverlap: 0.8,
        theta: 0.7,
        springLength: 120,
        springConstant: 0.05,
        damping: 0.3,
      },
      maxVelocity: 10,
      minVelocity: 0.1
    },
    edges: {
      arrows: {
        to: {
          enabled: false,
          src: undefined,
          type: "arrow",
          imageHeight: undefined,
          imageWidth: undefined,
          scaleFactor: 1,
        },
      },
      interaction: {
        dragNodes: true,
          zoomView: false,
          dragView: false,
      },
      nodes: {
        shapeProperties: {
          useImageSize: false
        },
        font: {
          multi: true,
          size: 14,
           face: 'Quicksand',
          color:'#666',
          bold: {
            color: "#666"
          }
        },
        
      },
      color: {
        color:'#848484',
        highlight:'#848484',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
       length: 150,
    },
   
  };

  handleClose = () => {
    this.setState({ materialModal: null });
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Menu">
        
        <SettingContainer>
          <Box className="mainContentWrapper">
            <Box className="settingBackground">

              <Box className="settingImgWrapper"
                style={{ backgroundImage: `url(${groupMaskMath})`, objectFit: "cover" }}
              >
                <Box className="scrollBox" >
                  <Box className="BackImage">
                    <IconButton data-testid="back-btn"
                      onClick={() => { 
                        if(!this.state.showGraph){
                        this.props.navigation.navigate("MainMenuWeb")
                        }else{
                          this.setState({
                            showGraph:false,
                            selectedGrade:undefined,
                            description:'',
                            graph:{
                              nodes:[],
                              edges:[]
                            }
                          })
                        }
                      }}
                    >
                      <img src={Back} width="20px" height="22px" />
                    </IconButton>
                  </Box>
                  <Box className="iconContainerscroll">
                    <img src={this.state.avatarIcon} width="51px" />
                    <Box>
                      <Box className="scrollboxtitlewrapper">
                        <Typography className="fontSet">
                          {this.state.topic}
                        </Typography>
                      </Box>
                      <Typography className="scrollboxdetail">
                        {this.state.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="languageButton1">
                    <IconButton >
                      <img src={Search} width="31px" height="22px" />
                    </IconButton>
                  </Box>
                  <img src={maskMath} className="imagePosition" />
                </Box>
                <Box className="nomargin">
                </Box>
              </Box>
            </Box>
            <Box className="mainLanding">
              <Box style={{ position: "relative" }}>
                {this.state.CurrentNode && <MaterialModal
                  data-testid="MaterialModal"
                  Material={this.state.CurrentNode}
                  open={this.state.materialModal}
                  onClose={() => this.handleClose()}
                />}
                
                  {! this.state.showGraph?
                    <MaterialGradeScreenWeb
                    data-testid = "MaterialGrade"
                      selectedGrade={this.state.selectedGrade}
                      gradesData={this.state.gradeList}
                      openCategories={(data: any) => {
                        this.setState({
                          selectedGrade:data?.attributes?.id,
                          showGraph:true,
                          description:data?.attributes?.name
                        })
                       this.getCategories(data?.attributes?.id)
                      }}
                    />
                    :
                  <DrawerStyle>
                  <Graph
                    graph={this.state.graph}
                    options={this.options}
                    events={{ select: this.NodeFunction }}
                    style={{ height: "100vh", width: '100%', overflow: 'auto' }}
                    data-testid="graph"
                    />
                </DrawerStyle>
                }
              </Box>
            </Box>
          </Box>
        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    )
  }
}
export default withTranslation()(LessonPage)
// Customizable Area Start

export const SettingContainer = styled(Box)(({ theme }) => ({
  // backgroundColor: '#C9EEFF',
  height: '100%',
  width: '100%',
  "@media(max-width: 959px)": {
    backgroundColor: '#fff',
  },
  '& .nomargin': {
    width: '100% !important',
  },
  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .settingBackground': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .fontSet': {
    textTransform: 'uppercase',
    color: 'var(--primary-blue-dark-gray, #363636)',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '10px',
    letterSpacing: '0.096pxpx',
  },
  '& .mainLanding': {
    flex: 6,
    height: 'calc(100% - 136px)',
    alignItems: 'center',
    margin: 'auto',
    overflow: 'hidden',
    backgroundColor: '#fff',
    // backgroundImage: `url(${groupDecoration})`,
    "@media(max-width: 959px)": {
      width: '100%',
      position: 'absolute',
      flex: 'unset',
      zIndex: 100,
      top: 'calc(0% + 136px)',
      borderRadius: '25px',
      boxShadow: "rgba(0, 0, 0, 0.2) 0px -2px 12px",
    }
  },

  '& .BackImage': {
    marginTop: 42,
    zIndex: 1,
    alignSelf: "flex-start",
    marginRight: '15px',
    '& .MuiIconButton-root': {
      padding: "2px"
    },
    "@media(max-width: 520px)": {
      marginRight: '0px',
    },
  },

  '& .imagePosition': {
    position: 'absolute',
    top: '0',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  '& .button_position': {
    position: "absolute",
    textAlign: 'center',
    bottom: "5%",
    width: '100%',
    "@media screen and (max-height: 420px) and (max-width: 960px)": {
      bottom: '36%',
    },
    "@media screen and (max-height: 980px) and (max-width: 960px)": {
      bottom: '18%',
    },
    "@media screen and (max-height: 1500px) and (max-width: 960px)": {
      bottom: '14%',
    },
    "@media screen and (max-height: 830px) and (max-width: 960px)": {
      bottom: '21%',
    },
    "@media screen and (max-height: 1200px) and (max-width: 960px)": {
      bottom: '16%',
    },
    "@media screen and (max-width: 960px) and (max-height: 550px)": {
      bottom: '28%',
    },
    "@media screen and (max-height: 500px) and (max-width: 960px)": {
      bottom: '32%',
    },

    "@media screen and (max-height: 670px) and (max-width: 960px)": {
      bottom: '25%',
    },

  },
  '& .Material-btn': {
    zIndex: `100`,
    color: "var(--primary-blue-blue-primary, #62CDFF)",
    textDecoration: "uppercase",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    height: "41px",
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
    borderRadius: "12px",
    background: "var(--basic-white, #FFF)",
    border: "2px solid var(--primary-blue-blue-primary-light, #C9EEFF)",
  },

  '& .Material-btn > .MuiButton-label': {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
  },

  '& .iconContainerscroll': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    height: '65px',
    zIndex: 1,
    display: 'flex',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    marginTop: "24px",
    alignItems: "center",
    gap: '15px',
    padding: "10px",
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)'
  },
  '& .scrollBox': {
    display: "flex",
    padding: "10px",
    minHeight: "150px",
    margin: '50% auto auto',
    alignItems: "baseline",
    backgroundSize: "cover",
    justifyContent: "center",
    background: "rgb(0, 174, 255)",
    backgroundImage: `url(${groupscroll})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100%",
    gap: '10px',
    position: 'relative',
    width: '80%',
    top: 0,
    '@media(max-width:960px)': {
      margin: '0px !important',
    },
    '@media(max-width:520px)': {
      width: '100%',
      margin: 0,
    }
  },
  '& .scrollboxtitlewrapper': {
    display: 'flex',
    gap: '10px',
    color: "#00AEFF",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "18.5px",
    letterSpacing: "0.128px"
  },
  '& .scrollboxdetail': {
    color: "rgb(0, 174, 255)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "14px",
    letterSpacing: "0.128px",
    marginTop: '8px'
  },

  '& .languageButton1': {
    display: 'flex',
    gap: '10px',
    zIndex: 1,
    position: "relative", top: "-12px",
    marginLeft: 5,
    '& .MuiIconButton-root': {
      padding: "2px"
    },
    '@media(max-width:520px)': {
      marginLeft: 0
    }
  },
  '& .settingImgWrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: "rgb(0, 174, 255)",
    borderBottomRightRadius: '0px',
    height: '100vh',
    backgroundSize: '80%',
    "@media(max-width: 959px)": {
      height: 'auto'
    }
  },
  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },
  [theme.breakpoints.up('md')]: {
    '& .settingImgWrapper': {
      height: '100vh',
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',

    },
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      flexDirection: 'row',
      marginLeft: '240px',
    },
    '& .settingBackground': {
      flex: 5,
      height: 'auto'
    },
    '& .nomargin': {
      margin: 'auto !important',
    },
  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      margin: 0,
      width: '100% !important',
    }
  }
}))

export const DrawerStyle = styled('div')(({ theme }) => ({
  // padding: '10px',
  display: 'flex',
  fontFamily: "Quicksand",
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  }

}))

// Customizable Area End
