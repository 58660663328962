import React, { Component } from "react";
// Customizable Area Start
import { buttonNotification, rectangle, trophies, goalMedal, maskMath, grades, unreadNotifications, bolt, diamond, listIcon, orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried, medianGrade, goals, trophy, premimumtext, yourtext,PremiumShadow, searchIcon } from './assets';
import { Box, Typography, styled } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import GoalWeb from "../../blocks/landingpage/src/Goal.web";
const baseURL = require("../../framework/src/config.js").baseURL;

export interface Props {
    handleOpenDrawer?: (name: string) => void;
    openDrawer?: boolean;
    trophiesOpen?: boolean;
    goalsOpen?: boolean;
    navigation?: any;
    Topic?: string;
    unreadNotifications?:boolean;
    Unit?: string;
    topicIcon?: any,
    multilevelIcon?:any,
    toggleState?: () => void;
    medianOpen?: boolean;
    isChallenge?: boolean;
    t?: any;
}

export interface S {
    unreadNotification:boolean;
    avatarHealth:any;
    avatarColor:any;
    totalXP:any;
    totalAwards:any;
    avgMedian:any;
    premiumUser: any;
    totalMedals:any;
    goalOpen:boolean;
    dailyGoal:string;
    streakGoal:string;
    drawerSide:boolean;
    loadData:boolean
}
 class CenterCard extends Component<Props, S>  {
 
    constructor(props:Props){
        super(props)
        this.state={
        unreadNotification: false, 
        avatarHealth:'healthy', 
        avatarColor:'#62cdff',
        totalXP: 0,
         totalAwards: 0,
         avgMedian: 0,
         drawerSide:false,
         premiumUser: false, 
         totalMedals: 0,
         goalOpen:false,
         dailyGoal:"",
         streakGoal:"",
         loadData:false
        }
    }

    
    componentDidMount(): void {
        window.addEventListener('resize', this.handleWindowSizeChange);
        this.handleWindowSizeChange();   
        if(this.props.unreadNotifications===undefined){
        this.getNotification();
        }
        this.getCurrentUserDetail();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
        if(this.props.unreadNotifications!==undefined){
        if(prevProps.unreadNotifications!==this.props.unreadNotifications){
            this.setState({
                unreadNotification:this.props.unreadNotifications
            })
        }
      }
    }

    getNotification=async()=>{
        let token = localStorage.getItem("token");
      const  resp = await fetch(
        `${baseURL}/bx_block_notifications/notifications`,
         {
           headers:{ 
            "Content-Type":"application/json",
            token:token ?? ''
           }
         }
          )
        const response = await resp.json()
        if(response?.data){
         const number = response.data.filter((item:any) => item.attributes.status === 'unread')
         if(number.length>0){
            this.setState({
                unreadNotification:true
            })
         }
        }
         
    }

    getCurrentUserDetail=async()=>{
        this.setState({
            loadData:true
        })
        let token = localStorage.getItem("token");
      const  resp = await fetch(
        `${baseURL}/bx_block_profile/profiles/current_user_profile`,
         {
           headers:{ 
            "Content-Type":"application/json",
            token:token ?? ''
           }
         }
          )
        const response = await resp.json()
        if(response?.data){
            this.setState({
                avatarHealth: response?.data?.attributes?.avatar_health, 
                avatarColor:response?.data?.attributes?.favorite_color, 
                totalXP:response?.data?.attributes?.total_xp,
                totalAwards: response?.data?.attributes?.total_award,
                avgMedian:Math.round(response?.data?.attributes?.avg_median_grade),
                premiumUser: response?.data.attributes?.payment_premium, 
                totalMedals: response?.data.attributes?.medals,
                dailyGoal:response?.data.attributes?.daily_goal,
                streakGoal:response?.data.attributes?.streak_goal,
                loadData:false
            })
            localStorage.setItem('avatarColor',response?.data?.attributes?.favorite_color)
        }   
    }

    handleGoalOpen=()=>{
       this.setState({
         goalOpen:true
       })
    }

    closeDrawer=()=>{
        this.setState({
            goalOpen:false
          })
    }

    handleWindowSizeChange = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 960) {
          this.setState({ drawerSide: false });
        } else {
          this.setState({ drawerSide: true });
        }
      };

       getColorMoodMap = () => {
        return {
            '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
            '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
            '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
            '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
            '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
            '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
            '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
            '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried,ghostly: ghostlyAvatar }
        };
    };

       getColorMood = () => {
        const color = this.state.avatarColor;
        const health = this.state.avatarHealth;
    
        if (color === undefined) {
            return null;
        }
        if (health === undefined) {
            return null;
        }
        const colorMoodMap = this.getColorMoodMap();
    
        if (color in colorMoodMap) {
            return (colorMoodMap as any)[color][health] || null;
        }
        return null;
    };

    getNotificationImage=()=>{
        return this.state.unreadNotification ?unreadNotifications: buttonNotification
    }

    getStyles=()=>{
        return this.state.unreadNotification? {marginTop:-3}:{}
    }

    getWidth=()=>{
        return this.state.unreadNotification?"25px":"20.6px";
    }

    getHeight=()=>{
        return this.state.unreadNotification?"26px":"24px";
    }

   getPremiumUserProgressBar=()=>{
    const { t } = this.props;
    if(this.state.premiumUser || this.state.totalXP > 1000){
    return(
        <Box className="progressBar" style={{position:'relative'}}>
            <div style={{display:'flex',justifyContent:'center',margin:"20px auto",}}>
            <img src={PremiumShadow}/>
            </div>
            <div style={{position:"absolute",width:'100%',display:'flex',margin:'20px auto',top:10}}>
           <div className="premium-progress" >
            <img src={bolt} className="flash"/>
            <div className="diamond_image_container">
             <img src={yourtext} className="star1"  />
             <img src={diamond} className="star3" onClick={()=>this.props.navigation.navigate("PremiumDashboard")} />
             <img src={premimumtext} className="star2"/>
            </div>
            <div className="premium-score"  style={{ width: (this.state.totalXP > 1000 ? 100 : this.state.totalXP / 10)  + '%' }}></div>
            <div className="premium-score-board" onClick={()=>this.props.navigation.navigate("XPTracker")}>
            {this.state.totalXP} XP
            </div>
            </div>
        </div>
        <div className="premium-free-text">
            <Typography className="userProfile">{t('Free')}</Typography>
        </div>
        </Box>
    )
    }else{
       return(
        <Box className="progressBar">
        <div className="progress">
            <img src={bolt} className="flash"/>
            <img src={diamond} className="star" onClick={()=>this.props.navigation.navigate("StripePaymentsView")}/>
            <div className="score" style={{ width: this.state.premiumUser ? '100%' : (this.state.totalXP > 1000 ? 100 : this.state.totalXP / 10)  + '%' }}></div>
            <div className="score-board" onClick={()=>this.props.navigation.navigate("XPTracker")}>
            {this.state.totalXP} XP
            </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between',width: '320px',margin: '0 auto'}}>
            <Typography className="userProfile">{t('Free')}</Typography>
            <Typography className="userProfile">{t('Premium')}</Typography>
        </div>
        </Box>
       )
    }
   }

    render() {
        const { t } = this.props;
        let imgContent;

        if (!this.props.openDrawer&&!this.state.goalOpen) {
            imgContent = (
                <>   <div style={{display:'flex', alignItems:'center'}}>
                      <img id='openDrawer' src={trophy} width="25px" height='25px' onClick={() => this.props.handleOpenDrawer?.('trophi')} />
                      <Typography className="showValue">{this.state.totalAwards}</Typography>
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <img src={goals} width="25px" height='25px' onClick={this.handleGoalOpen}/>
                    <Typography className="showValue">{this.state.totalMedals}</Typography>
                    </div>
                     <div style={{display:'flex', alignItems:'center'}}>
                     <img src={medianGrade} width="24px" height='24px' onClick={() => this.props.handleOpenDrawer?.('median')} />
                     <Typography className="showValue">{this.state.avgMedian}</Typography>
                     </div>     
                </>
            );
        } else if (this.props.trophiesOpen) {
            imgContent = (
                <div className="trophiWrapper">
                    <img src={trophies} width={25} height={25} />
                    <Typography className="Trophies">{t('Trophies')}</Typography>
                </div>
            );
        } else if (this.state.goalOpen) {
            imgContent = (
                <div className="trophiWrapper">
                    <img src={goalMedal} width={25} height={25} />
                    <Typography className="Goals">{t('Goals')}</Typography>
                </div>
            );
        } else if (this.props.medianOpen) {
            imgContent = (
                <div className="trophiWrapper">
                    <img src={grades} width={23} height={25} />
                    <Typography className="Median">{t('Median Grade')}</Typography>
                </div>
            );
        }
        else {
            imgContent = '';
        }

        
    const avatarIcon = this.getColorMood();
        const NotificationImage = this.getNotificationImage()
        const Width = this.getWidth()
        const Height =this.getHeight();
        const styles =this.getStyles()
        return (
            <SettingContainer>
                <Box className="mainWhiteBox">
                    <Box className="whiteBox">
                        <Box className="topBox">
                            <Box className="iconContainer">
                                <Box className="groupAvtar">
                                    <img src={avatarIcon} width="51px" onClick={() => this.props.navigation.navigate('CustomisableUserProfiles')} style={{cursor: 'pointer'}} />
                                </Box>
                                <Box className="iconContainer1">
                                    {imgContent}
                                </Box>
                            </Box>
                            <Box className="languageButton">
                                <img src={searchIcon} width="31px" height="22px" style={{cursor:"pointer"}}onClick={() => this.props.navigation.navigate('Search')} />                            
                                <img src={NotificationImage} width={Width} height={Height} style={styles} onClick={() => this.props.navigation.navigate('InAppNotification')} />
                            </Box>
                        </Box>
                        {!this.state.loadData&&this.getPremiumUserProgressBar()}
                    </Box>
                    <Box className="backImage">
                        <Box className="rectangleBlock1" style={{ justifyContent: 'center', backgroundColor: this.props.isChallenge ? '#29ce8c' : '#62CDFF' }}>
                            <Box className="rectangleBlock" style={{ backgroundColor: this.props.isChallenge ? '#29ce8c' : '#62CDFF' }}>
                                <img src={this.props.multilevelIcon||listIcon} className='multiLevel' onClick={this.props.toggleState} />
                                <Typography className="subjectName">{t(this.props.Topic)}</Typography>
                            </Box>
                            {this.props.topicIcon && <Box className="topicIcon"
                            >
                                <img src={this.props.topicIcon} />
                            </Box>}
                        </Box>
                        <Box className='viewRectangle' style={{backgroundColor: this.props.isChallenge ? '#25b178' : '#00AEFF'}}>
                            <Typography className='units' style={{backgroundColor: this.props.isChallenge ? '#25b178' : '#00AEFF'}}>{t(this.props.Unit)}</Typography>
                        </Box>
                        <img src={maskMath} className="imagePosition" onClick={this.props.toggleState}/>
                    </Box>
                </Box>

            <GoalWeb 
            stateOpen={this.state.goalOpen} 
            streakGoal={this.state.streakGoal}
            dailyGoal={this.state.dailyGoal} 
            drawerOpen={this.state.drawerSide} 
            closeDrawer={this.closeDrawer}    
            updateCurrentProfile={this.getCurrentUserDetail}        
             />
        </SettingContainer>
    )
    }
}

export default withTranslation()(CenterCard)
const SettingContainer = styled(Box)(({ theme }) => ({

    '& .mainWhiteBox': {
        width: '393px',
        height: '246px',
        borderRadius: '20px 20px 0px 0px',
        backgroundColor: '#62CDFF',
        display: 'block',
        margin: 'auto',

        "@media(max-width: 1140px)": {
            width: '85%',
        },
        "@media(max-width: 959px)": {
            borderRadius: '0px 0px 20px 0px',
            width: '100%',
            height: 'auto'

        },
    },
    '& .progressBar': {
        width: "100%", 
        height: "100px", 
        padding: "15px 0" 
    },
    '& .progress': {
        width: "300px",
        cursor:"pointer",
        height: "10px !important",
        backgroundColor: "#f2f2f2 !important",
        content: "''",
        display: "block !important",
        margin: "20px auto",
        position: "relative",
        overflow: "visible !important"
    },

    '& .premium-progress': {
        width: "320px",
        cursor:"pointer",
        display: "block !important",
        margin: "20px auto",
        position: "relative",
        overflow: "visible !important"
    },

    '& .flash': {
        position: "absolute",
        left: "-10px",
        top: "-10px",
        zIndex: 9
    },
    '& .showValue': {
        paddingLeft: "5px",
        fontFamily: "Quicksand",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "21px",
        letterSpacing: "0px",
        textAlign: "left",
        color: "#999"
    },
    '& .star':{
        position: "absolute",
        right: "-10px",
        top: "-10px",
        zIndex: 9
    },

    '& .star3':{
        position: "absolute",
        right: "-10px",
        top: "-7px",
        zIndex: 9
    },
    '& .star1':{
        position: "absolute",
        right: "-12px",
        top: "-32px",
        zIndex: 9
    },
    '& .star2':{
        position: "absolute",
        right: "-20px",
        top: "12px",
        zIndex: 9
    },
    '& .score':{
        position: "absolute",
        top: "2px",
        left: "0",
        height: "6px !important",
        backgroundColor: "#62CDFF !important",
        content: "''" 
    },

    '& .premium-score':{
        position: "absolute",
        top: "2px",
        left: "0",
        height: "6px !important",
        background: "linear-gradient(90deg, #62CDFF 0%, #A95EDB 100%) !important",
        content: "''" 
    },

    '& .diamond_image_container':{
       display:'flex',
       flexDirection:'column'
    },
    '& .score-board':{
            left: "0",
            right: "0",
            position: "absolute",
            margin: "0 auto",
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            width: "80px",
            height: "30px",
            content: "''",
            backgroundColor: "#f2f2f2 !important",
            top: "-12px",
            borderRadius: "10px",
            textAlign: "center",
            fontSize: "16px",
            color: "#fff",
            textShadow:
              "1px 0 #b3b3b3, -1px 0 #b3b3b3, 0 1px #b3b3b3, 0 -1px #b3b3b3,\n               1px 1px #b3b3b3, -1px -1px #b3b3b3, 1px -1px #b3b3b3, -1px 1px #b3b3b3" 

    },

    '& .premium-score-board':{
        left: "0",
        right: "0",
        position: "absolute",
        margin: "0 auto",
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        width: "90px",
        height: "34px",
        content: "''",
        background: "linear-gradient(90deg, #62CDFF -50%, #A95EDB 125%) !important",
        top: "-12px",
        borderRadius: "10px",
        textAlign: "center",
        fontSize: "16px",
        color: "#fff",
        textShadow:
          "1px 0 #b3b3b3, -1px 0 #b3b3b3, 0 1px #b3b3b3, 0 -1px #b3b3b3,\n               1px 1px #b3b3b3, -1px -1px #b3b3b3, 1px -1px #b3b3b3, -1px 1px #b3b3b3" 
      },
    '& .userProfile': {
        color: "var(--Primary-Blue-Dark-Gray, #363636)",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "8px"
    },

    '& .topBox': {
        display: 'flex',
        padding: '20px 20px 0',
        "@media(max-width: 959px)": {
            justifyContent: 'center'
        }
    },
    '& .whiteBox': {
        backgroundColor: '#fff',
        borderRadius: '20px 20px 0px 0px',
        "@media(max-width: 959px)": {
            borderRadius: '0px 0px 20px 0px'
        },
    },

    '& .rectangleBlock': {
        width: '393px',
        height: '59px',
        top: '488px',
        left: '302px',
        display: 'flex',
        padding: '15px',
        gap: '10px',
        alignItems: ' center',
        "@media(max-width: 959px)": {
            width: '100%'
        },
    },
    '& .rectangleBlock1': {
       // width: '393px',
        height: '59px',
        top: '488px',
        left: '302px',
        display: 'flex',
        padding: '15px',
        gap: '10px',
        alignItems: ' center',
        "@media(max-width: 959px)": {
            width: '100%'
        },
    },
    '& .backImage': {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            position: 'unset'
        }

    },

    '&  .topicIcon': {
        width: 40,
        height: 40,
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: -20,
        marginRight:20,
        borderRadius: '0px 0px 12px 12px ',
        background: 'rgba(255, 255, 255, 0.38)'
    },
    '& .imagePosition': {
        position:'absolute', 
        top:'0', 
        width:'95%', 
        height:'85%',
        right: '0',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },

    '& .viewRectangle': {
        width: '100%',
        height: '44px',
        top: '547px',
        left: '302px',
        borderRadius: '0px 0px 20px 20px',
        boxShadow: '0px 8px 8px 0px #0000000D',
        "@media(max-width: 959px)": {
            borderRadius: '0px 0px 0px 0px',
            boxShadow: 'none'
        },
    },


    '& .units': {
        height:'12px',
        top: '565px',
        left: '367px',
        fontFamily: 'Nunito',
        fontSize: '18px',
        fontWeight: '800',
        lineHeight: '13px',
        textAlign: 'center',
        backgroundColor: '#00AEFF',
        padding: '20px',
        color: 'var(--primary-blue-blue-primary-light, #C9EEFF)',
        fontStyle: 'normal',
        letterSpacing: '0.144px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow:'ellipsis',

    },


    "& .premium-free-text":{
        display: 'flex',
        justifyContent: 'space-between',
        maxWidth: '340px',
        width:"100%",
        margin: '-13px auto 0px'
    },

    '& .iconContainer': {
        backgroundImage: `url(${rectangle})`,
        width: '261px',
        height: '65px',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    '& .imageTracker': {
        width: '100%',
        "@media(max-width: 959px)": {
            width: '100%',
            margin: '0px auto',
            display: 'block',
        }
    },

    '& .multiLevel': {
        width: '22px',
        height: '16px',
        top: '505px',
        left: '328px',
        cursor:'pointer'
    },

    '& .subjectName': {
        color: 'var(--primary-blue-white, #FFF)',
        textAlign: 'left',
        fontFamily: 'Nunito',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: '900',
        lineHeight: '18.5px',
        letterSpacing: '0.176px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow:'ellipsis',
        height:'20px', 
        paddingLeft:'12px'
    },
    '& .languageButton': {
        display: 'flex',
        gap: '10px',
        margin: '10px',
        marginTop: '20px'
    },
    '& .iconContainer1': {
        width: '170px',
        display: 'flex !important',
        justifyContent: 'space-between'

    },
    "& .trophiWrapper": {
        display: 'flex',
        alignItems: 'center'
    },
    '& .groupAvtar': {
        margin: '7px 13px 7px 7px'
    },
    "& .Trophies, .Goals, .Median": {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "21px",
        margin: '0 0 0 6px'
    },
    [theme.breakpoints.up('md')]: {

        '& .contentContainer': {
            paddingTop: '20px',
            overflowX: 'auto',

        },

        '& .nomargin': {
            margin: 'auto !important',
            '& .mainWhiteBox': {
                margin: '20px !important',
                width: 'calc(100% - 40px) !important',
                borderRadius: '20px 20px 0px 0px !important'

            }
        },
        '& .rightcard': {
            width: '90% !important',
        },

    },

    [theme.breakpoints.up('lg')]: {


        '& .accountWrapper': {
            maxWidth: 600,
        },

        '& .rightcard': {
            width: '60% !important',
        }
    },

    [theme.breakpoints.up('sm')]: {

        '& .rightcard': {
            width: '60%',
        },
        '& .nomargin': {
            width: '100% !important',
            margin: 0,
            '& .mainWhiteBox': {
                width: '100%',
                margin: '0'
            },
            '& .rectangleBlock': {
                width: '100% !important',
            },

        }
    }
}))

// Customizable Area End