// Customizable Area Start
import React from "react";
import {
    Box,
    Container,
    Grid,
    Typography
} from '@material-ui/core'
import { styled } from "@material-ui/styles"
import { withTranslation } from "react-i18next";
import ParentSmsLayoutCotroller from "./parentSmsLayoutController.web";
import Header from "../../../components/src/Header";
import { pandaIcon, pandaIcon2 } from "./assets";
import SelectLanguage from "../../../components/src/SelectLanguage";
// Customizable Area End

class ParentSmsLayout extends ParentSmsLayoutCotroller {
    // Customizable Area Start

    getClassName=()=>{
        return {
            line:this.props.showGoBackBtn?"lineWrapper":"light_lineWrapper",
            secondTab: this.props.showGoBackBtn?"numberWrapper":"light_numberWrapper"
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t } = this.props
        const className = this.getClassName()
        // Customizable Area End
        return (
            // Customizable Area Start
            <LayoutContainer>
                <Header data-testid="go-back-btn" showGoBackBtn={this.props.showGoBackBtn} onGoBack={this.handleGoBack} />
                <Container className="contentWrapper" maxWidth="md">
                    <Box className="content">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                className="numberWrapper"
                                style={{
                                    marginRight: '-2px',
                                }}
                            >
                                1
                            </div>
                            <div className={className.line} />
                            <div
                                className={className.secondTab}
                                style={{
                                    marginLeft: '-2px',
                                }}
                            >
                                2
                            </div>
                        </div>
                        <Grid container className="imageContainer" spacing={0} style={{ width: '520px' }}>
                            <Grid xs >
                                <div></div>
                            </Grid>
                            <Grid xs style={{ display: 'flex', justifyContent: 'end' }}>
                                <img src={pandaIcon} className="pandaIcon" alt='panda-icon' />
                                <img src={pandaIcon2} className="pandaIcon2" alt='panda-icon' />
                            </Grid>
                        </Grid>
                        <Box className="Text_Container">
                            <Typography className="Account_verification_text">{t("Account verification")}</Typography>
                            <Typography className="child_safety_text">{t("Your child's safety is our priority")}</Typography>
                        </Box>
    
                        {this.props.children}
                    </Box>
                    <Box style={{textAlign:'center'}}>
                    <SelectLanguage style={{ marginBottom: '48px', marginTop: '24px' }} />
                    </Box>
                </Container>
            </LayoutContainer>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(ParentSmsLayout)
const LayoutContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    '& .contentWrapper': {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    '& .content': {
        marginTop: '24px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },

    '& .lineWrapper': {
        backgroundColor: '#62CDFF',
        height: '6px',
        width: '44px',
    },

    '& .numberWrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 22,
        width: 22,
        borderRadius: '50%',
        fontFamily: 'Nunito',
        fontSize: '12px',
        fontWeight: '700',
        backgroundColor: '#62CDFF',
        color: '#fff',
        zIndex: 9,
    },

    "& .light_lineWrapper":{
        backgroundColor: '#C9EEFF',
        height: '6px',
        width: '44px',
    },
    "& .light_numberWrapper":{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 22,
        width: 22,
        borderRadius: '50%',
        fontFamily: 'Nunito',
        fontSize: '12px',
        fontWeight: '700',
        backgroundColor: '#C9EEFF',
        color: '#fff',
        zIndex: 9,
    },
    "& .Account_verification_text":{
        color: "var(--Primary-Blue-Dark-Gray, #363636)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px", 
    },
    "& .child_safety_text":{
        color: "var(--Primary-Neutrals-Warm-gray-600, #666)",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px"
    },
    "& .Text_Container":{
       marginBottom:"46px"
    },

    "@media (max-width:520px)": {
        "& .imageContainer": {
            width: '100% !important',
        },
        "& .contentWrapper":{
            alignItems:"unset !important" 
        }
    },

    "@media (max-width:450px)": {
        "& .pandaIcon": {
            display: 'none'
        },
        "& .pandaIcon2": {
            display: 'flex',
            justifyContent: 'end'

        },
    },
  
    "@media (min-width:450px)": {
        "& .pandaIcon2": {
            display: 'none'
        },
        "& .pandaIcon": {
            display: 'block'
        },
    },

}))
// Customizable Area End