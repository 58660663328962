// Customizable Area Start
import React,{Suspense} from 'react'
import LeaderboardsController, { IPlacement } from './LeaderboardsController.web';
import { withTranslation } from 'react-i18next';
import { Box, Button, Typography, styled } from '@material-ui/core';
import {  ChallengeBackImage, LegendBackImage, bolt, bornProtectorBackImage, countdownClock, loaderBlue, defenderBackImage,  starBg } from './assets';
import NavigateLayout from '../../../components/src/NavigateLayout';
import { backButton } from '../../cftableforlessons/src/assets';

class Leaderboards extends LeaderboardsController {

    renderTimeCounter = () => {
        return (
            <div>
           {this.state.timeCounter&& 
           <Box className='time-display'>
                <Typography component="span" className='number'>
                    {this.state.timeCounter.dayLeft.split('')[0]}
                </Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.dayLeft.split("")[1]}
                </Typography>

                <Typography component="span" className='number no-bg'>:</Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.hourLeft.split('')[0]}
                </Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.hourLeft.split("")[1]}
                </Typography>

                <Typography component="span" className='number no-bg'>:</Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.minuteLeft.split('')[0]}
                </Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.minuteLeft.split("")[1]}
                </Typography>

                <Typography component="span" className='number no-bg'>:</Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.secondLeft.split('')[0]}
                </Typography>

                <Typography component="span" className='number'>
                    {this.state.timeCounter.secondLeft.split("")[1]}
                </Typography>
            </Box>}
            </div>
        )
    }


    renderHeadText=()=>{
        return this.state.leaderbordThemeData?.notInRange?this.props.t("Keep aiming for the"): this.props.t("Get ready for the")
    }

    renderButtonText=()=>{
        return this.state.leaderbordThemeData?.notInRange?this.props.t("Start earning XP"): this.props.t("Keep earning XP")
    }

   renderLeaderBoard=()=>{
    return (
        <Box>
            {this.state.showPlacement
                    ?
                    <LeaderboardPlacementContainer data-testid="LeaderBoardPlacement">
                        <Box className='container'>
                        <Box className="left-content-wrapper">
                        <Box className='center_Leaderbord_text' style={{height:'100vh'}}>
                            <Typography className='Leaderbord_text'>{this.props.t("Leaderboard")}</Typography>
                        </Box>
                        </Box>
                        <Box className='main-content-wrapper '>
                            <Box className='current-league'>
                            <Box className={`${this.state.leaderbordThemeData?.groupDivisionClass}`}>
                                <img src={this.state.leaderbordThemeData?.groupDivision} alt="Group division" height={55} />
                            </Box>
                            </Box>
                            <Box className="title">
                                <Typography component="span" className='title-text'>{this.props.t(this.state.leaderbordThemeData?.label)}</Typography>

                                <Typography component="span" className='title-countdown'>
                                    <img src={countdownClock} alt="clock" width={11} />

                                    {this.state.timeCounter&&this.formatDurationConditionalFromString(this.state.timeCounter?.dayLeft,this.state.timeCounter?.hourLeft,this.state.timeCounter?.minuteLeft)}
                                </Typography>
                            </Box>

                            <Box  className='backgroundImage'>
                                <img src= {this.state.leaderbordThemeData?.backgroundImg} style={{width:"100%"}} alt="back_image"/>
                            </Box>
                                 
                            <Box className='rank-placement'>
                                <Box className='top-rank-placement'>
                                    <Box className='top-place'>
                                        <img src={this.updateHappyTeddy(this.state.RankPlacementList[1].favorite_color)} alt="Second place" width={70} />
                                        <Box className='second xp-point'>
                                            <Typography component="span" className='place'>
                                                2<sup>nd</sup>
                                            </Typography>

                                            <Typography component="span" className='user-name'>{this.state.RankPlacementList[1].display_name}</Typography>

                                            <Typography component="span" className='xp-point-count'>{this.state.RankPlacementList[1].total_xp} XP</Typography>
                                        </Box>
                                    </Box>

                                    <Box className='top-place'>
                                        <img src={this.updateHappyTeddy(this.state.RankPlacementList[0].favorite_color)} alt="Second place" width={70} />

                                        <Box className='first xp-point'>
                                            <Typography component="span" className='place'>
                                                1<sup>st</sup>
                                            </Typography>

                                            <Typography component="span" className='user-name'>{this.state.RankPlacementList[0].display_name}</Typography>

                                            <Typography component="span" className='xp-point-count'>{this.state.RankPlacementList[0].total_xp} XP</Typography>
                                        </Box>
                                    </Box>

                                    <Box className='top-place'>
                                        <img src={this.updateHappyTeddy(this.state.RankPlacementList[2].favorite_color)} alt="Second place" width={70} />

                                        <Box className='third xp-point'>
                                            <Typography component="span" className='place'>
                                                3<sup>rd</sup>
                                            </Typography>

                                            <Typography component="span" className='user-name'>{this.state.RankPlacementList[2].display_name}</Typography>

                                            <Typography component="span" className='xp-point-count'>{this.state.RankPlacementList[2].total_xp} XP</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className={`placement-list ${this.state.leaderbordThemeData?.themePlacementClass}`}>
                                <Suspense fallback={<Typography>Loading...</Typography>}>
                                    {this.state.placementList.length>0&&this.state.placementList.map((item:IPlacement) =>{ 
                                        const checkUserId= this.state.UserInfo?.account_id===item.account_id;
                                        const UserBorder =checkUserId?this.state.leaderbordThemeData?.placementBorder:"";
                                        const UserFontColor= checkUserId?"greenFont":"";
                                    return(
                                        <Box className={`participant ${UserBorder}`} key={item.ranking} >
                                            <Box className='left-row'>
                                                <Typography component="span" className={`ranking ${UserFontColor}`}>{item.ranking}</Typography>

                                                <img src={this.updateHealthyTeddy(item.favorite_color)} alt="avatar" width={32} />

                                                <Typography component="span" className={`user-name ${UserFontColor}`}>{item.display_name}</Typography>
                                            </Box>

                                            <Box className='right-row'>
                                                <img src={bolt} alt="bolt" width={10} />
                                                <Typography component="span" className={`xp-point-count ${UserFontColor}`}>{item.total_xp} XP</Typography>
                                            </Box>
                                        </Box>
                                    )

                                })}
                                </Suspense>
                                </Box>
                            </Box>
                        </Box>
                        </Box>
                    </LeaderboardPlacementContainer>
                    :
                    <LeaderboardContainer data-testid="LeaderboardContainer">
                        <Box className='container'>
                        <Box className='left-content-wrapper'>
                           <Box className='center_Leaderbord_text' style={{height:'100vh'}}>
                             <Typography className='Leaderbord_text'>{this.props.t("Leaderboard")}</Typography>
                           </Box>
                        </Box>

                        <Box className={`main-content-wrapper ${this.state.leaderbordThemeData?.backImage}`}>
                            <Box className='main-content-title'>
                                <img src={backButton} alt="Back" width={16} className='Back-btn' />

                                <Typography component='span' className='title-text'>
                                    {this.renderHeadText()}
                                </Typography>
                            </Box>

                            <img src={this.state.leaderbordThemeData?.shieldImg} alt="Badge" width={157} className='badge' />

                            <Box className='main-content-time-counter'>
                                <Typography component="span" className='time-counter-title'>
                                    {this.props.t("Next leage starts in:")}
                                </Typography>

                                <Box className='time-counter'>
                                    {this.renderTimeCounter()}

                                    <Box className='time-indicator'>
                                        <Typography component="span" className='indicator'>
                                            {this.props.t("days")}
                                        </Typography>

                                        <Typography component="span" className='indicator'>
                                            {this.props.t("hours")}
                                        </Typography>

                                        <Typography component="span" className='indicator'>
                                            {this.props.t("min")}
                                        </Typography>

                                        <Typography component="span" className='indicator'>
                                            {this.props.t("sec")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography component='span' className='time-counter-bottom-text'>
                                    {this.props.t("Compete against fellow learners in our weekly leagues. Earn XP to secure your place in the league.")}
                                </Typography>
                            </Box>
                            <Box className='continue-btn-container'><Button className='continue-btn' onClick={this.handleNavigation} data-test-id="daily-goal-continue-btn">{this.renderButtonText()}</Button></Box>
                        </Box>
                        </Box>
                    </LeaderboardContainer>}
        </Box>
    )
   }

    render() {
        return (
            <NavigateLayout menuName='Leaderboard' isShowBottomMenu={true}>

        {this.state.loading ? (
         <LoadingContainer className="loaderShown">
        <img src={loaderBlue} alt="Loading" />
      </LoadingContainer>):
                this.renderLeaderBoard()

        }
            </NavigateLayout>

        )
    }
}

export default withTranslation()(Leaderboards)


export const LoadingContainer=styled(Box)(({theme})=>({
    
        display: 'flex', 
        background:"#FFC300",
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '100%', 
        width: "calc(100% - 240px)",
         marginLeft: "240px" , 
         "@media(max-width: 959px)": {
          width: '100%', 
          marginLeft:0
         }
      
}))


export const LeaderboardPlacementContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundColor: 'white',
    maxHeight: '100vh',
    height: '100vh',
    overflow: 'hidden',

    "& .left-content-wrapper": {
        display: 'none'
    },

    "& .center_Leaderbord_text":{
      justifyContent:"center",
      alignItems:'center',
      display:'flex',
      margin:"auto"
    },

     "& .Leaderbord_text":{
        color: "var(--Primary-Blue-White, var(--0, #FFF))",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px", /* 86.667% */
        letterSpacing:"0.24px"
     },

    "& .container":{
        display:'flex',
        flexDirection:'row',
      },

    "& .backgroundImage":{
        width:"446px",
        minHeight:460,
        position:"absolute",
        left: 0,
        top: 115,
        bottom: 0,
        right: 5,
        margin: 'auto',

        "@media(max-width: 450px)":{
            width:"100%"
        },
        "@media(max-width: 390px)":{
            width:"100%",
            top:154
        },
        "@media(max-width: 360px)":{
            width:"100%",
            top:170
        },
        "@media(max-width: 340px)":{
            width:"100%",
            top:195
        }

    },

    "& .group_division_apprentice":{
        display: 'flex',
        flexDirection: 'row',
        width:301,
        marginRight:70,
        justifyContent: 'end !important',
    },
    "& .group_division_challenge":{
        display: 'flex',
        flexDirection: 'row',
        width:301,
        marginRight:70,
        justifyContent: 'end !important',
    },
    "& .group_division_defender":{
        justifyContent: 'center !important',
        display: 'flex',
        flexDirection: 'row',
        width:301,
    },
    "& .group_division_born_protector":{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start !important',
        marginLeft:70,
        width:301,
    }, 
    "& .group_division_legends":{
        display: 'flex',
        flexDirection: 'row',
        width:301,
        marginLeft:70,
        justifyContent: 'start !important',
    },

    "& .Apprentice_border":{
        border: "5px solid rgba(169, 94, 219, 0.80) !important ",

    },

    "& .Challenge_border":{
        border: "5px solid rgba(249, 104, 85, 0.80) !important",
    },

    "& .Defender_border":{
        border: "4px solid rgba(43, 206, 140, 0.80) !important",
    },

    "& .BornProtector_border":{
        border: "5px solid rgba(98, 205, 255, 0.80) !important",
    },

    "& .Legends_border":{
        border: "5px solid rgba(253, 206, 54, 0.80) !important",
    },

    "& .greenFont":{
        color: "var(--Status-Green-600, #059669) !important"
    },

    "& .main-content-wrapper": {
        textAlign: 'center',
        width: '100%',
        backgroundImage: `URL(${starBg})`,
        backgroundSize: 'contain',
        paddingTop: '73px',
        overflow: 'hidden',
        height: '100vh',
        position: 'relative',

        "& .current-league": {
            width:"100%",
            textAlign:'center',
            display:"flex",
            alignItems:'center',
            justifyContent:'center'
        },

        "& .title": {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '38px',
            gap: '15px',
            justifyContent: 'center',

            "& .title-text": {
                fontFamily: "Nunito",
                fontSize: "22px",
                fontWeight: "900",
                lineHeight: "26px",
                letterSpacing: "0.008em",
                color: '#363636'
            },

            "& .title-countdown": {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '8px',
                fontFamily: "Nunito",
                fontSize: "16px",
                fontWeight: "800",
                lineHeight: "14px",
                letterSpacing: "0.008em",
                color: '#f59e0b'
            }
        },

        "& .rank-placement": {
            top: '250px',
            bottom: '0',
            height: 'auto',
            position: 'absolute',
            width: '100%',
            overflowY: 'scroll',
            borderRadius: '20px 20px 0 0',
            "& .top-rank-placement": {
                padding: "41px 46px 0",
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '16px',

                "& .top-place": {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'end',
                    gap: '20px',
                    color: 'white',
                    alignItems: 'center',

                    "& .xp-point": {
                        width: '90px',
                        borderRadius: '10px 10px 0 0',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        gap: '5px',
                        paddingBottom: '8px',
                        minHeight: 'fit-content',

                        "&.second": {
                            backgroundColor: '#b3b3b3',
                            paddingTop: '20px',
                            height: '90px',
                        },

                        "&.first": {
                            height: '125px',
                            backgroundColor: '#FFC300',
                            paddingTop: '23px',
                        },

                        "&.third": {
                            height: '70px',
                            backgroundColor: '#E4A051',
                            paddingTop: '8px',
                        },

                        "& .place": {
                            fontFamily: "Quicksand",
                            fontSize: "20px",
                            fontWeight: "700",
                            lineHeight: "15px",
                            letterSpacing: "0px",

                            "& sup": {
                                fontFamily: "Quicksand",
                                fontSize: "12px",
                                fontWeight: "700",
                                lineHeight: "5px",
                                letterSpacing: "0px",
                            }
                        },

                        "& .user-name": {
                            fontFamily: "Quicksand",
                            fontSize: "17px",
                            fontWeight: "700",
                            lineHeight: "13px",
                            letterSpacing: "0px",
                            whiteSpace:'nowrap',
                            textOverflow:"ellipsis",
                            overflow:'hidden',
                        },



                        "& .xp-point-count": {
                            fontFamily: "Quicksand",
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "13px",
                            letterSpacing: "0px",
                        }
                    }
                },
            },
            
            "& .ApprenticeBg":{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #A95EDB !important", 
            },
            "& .ChallengersBg":{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #F96855 !important", 
            },

            "& .DefenderBg":{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #2BCE8C !important",
            },

            "& .ProtectorsBg":{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #62CDFF !important",
            },
            "& .LegendsBg":{
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FFC300 !important",
            },

            "& .placement-list": {
                borderRadius: '20px 20px 0 0',
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #A95EDB", 
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                padding: '24px 32px 72px',

                "& .participant": {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    padding: '10px 12px',
                    borderRadius: '10px',

                    "& .left-row": {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',

                        "& .ranking": {
                            fontFamily: "Quicksand",
                            fontSize: "16px",
                            color: '#999999',
                            fontWeight: "600",
                            lineHeight: "13px",
                            letterSpacing: "0px",
                            width: '20px'
                        },

                        "& img": {
                            marginLeft: '20px'
                        },

                        "& .user-name": {
                            color: '#7F7F7F',
                            marginLeft: '10px',
                            fontFamily: "Quicksand",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "13px",
                            letterSpacing: "0px",
                        }
                    },

                    "& .right-row": {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '7px',
                        alignItems: 'center',

                        "& .xp-point-count": {
                            fontFamily: "Quicksand",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: '#999999',
                            lineHeight: "13px",
                            letterSpacing: "0px",
                        }
                    }
                }
            }
        }
    },

    [theme.breakpoints.up("md")]: {
        width: "calc(100% - 240px)",
        marginLeft: '240px',


        "& .left-content-wrapper": {
            width:'50%',
            height:"100%",
            display:'flex',
            background:'#FFC300'
        },

        "& .backgroundImage":{
            top:90
        },

        "& .main-content-wrapper": {
            width:"50%",
             height:"100vh",
            "& .current-league": {
            },

            "& .rank-placement": {
                "& .placement-list": {
                    paddingBottom: '10px !important',
                }
            }
        }
    }
}))

export const LeaderboardContainer = styled(Box)(({ theme }): any => ({
    width: '100%',
    backgroundColor: 'white',
    minHeight: '100vh',

    "& .container":{
      display:'flex',
      flexDirection:'row',
    },
    
    "& .Back-btn":{
        display:'block'
    },

    "& .center_Leaderbord_text":{
        display:'flex',
        justifyContent:"center",
        alignItems:'center',
        margin:"auto"
      },


    "& .left-content-wrapper": {
      display:"none"
    },

    "& .ApprenticeBackImage":{
        backgroundImage: `URL(${starBg}) !important`,
    },
    "& .Leaderbord_text":{
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        color: "var(--Primary-Blue-White, var(--0, #FFF))",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "30px",
        letterSpacing:"0.24px"
     },

    "& .ChallengersBackImage":{
        backgroundImage: `URL(${ChallengeBackImage}) !important`,
    },

    "& .DefenderBackImage":{
        backgroundImage: `URL(${defenderBackImage}) !important`,
    },

    "& .BornProtectorsBackImage":{
        backgroundImage: `URL(${bornProtectorBackImage}) !important`,
    },

    "& .LegendsBackImage":{
        backgroundImage: `URL(${LegendBackImage}) !important`,
    },
    "& .main-content-wrapper": {
        textAlign: 'center',
        width: '100%',
        backgroundImage: `URL(${starBg})`,
        backgroundSize: 'contain',
        padding: '70px 24px 63px',

        "& .main-content-title": {
            textAlign: 'center',
            width: '100%',
            position: 'relative',


            "& img": {
                position: 'absolute',
                left: "7px",
                top: '4px',
            },

            "& .title-text": {
                fontFamily: "Nunito",
                fontWeight: '900',
                fontSize: '22px',
                lineHeight: '26px',
                letterSpacing: '0.8%',
            }
        },

        "& .badge": {
            marginTop: '50px',
        },

        "& .main-content-time-counter": {
            padding: '0 11px',
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px',

            "& .time-counter-title": {
                fontFamily: 'Nunito',
                fontWeight: '900',
                fontSize: '18px',
                lineHeight: '26px',
                color: '#363636'
            },

            "& .time-counter": {
                display: 'flex',
                flexDirection: 'column',
                gap: '13px',
                marginTop: '31px',
                alignItems: 'center',

                "& .time-display": {
                    borderRadius: '8px',
                    border: 'solid 2px #999999',
                    backgroundColor: '#363636',
                    padding: '4px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '2px',
                    width: 'fit-content',

                    "& .number": {
                        fontFamily: 'Nunito',
                        fontWeight: '800',
                        fontSize: '30px',
                        lineHeight: '24px',
                        color: '#F59E0B',
                        backgroundColor: '#4A4A4A',
                        borderRadius: '4px',
                        padding: '3px',

                        "&.no-bg": {
                            backgroundColor: 'initial'
                        }
                    }
                },

                "& .time-indicator": {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    padding: '0 46px',

                    "& .indicator": {
                        width: '25%',
                        textAlign: 'center',
                        fontFamily: 'Nunito',
                        fontWeight: '500',
                        fontSize: '18px',
                        lineHeight: '13.5px',
                    }
                }
            },

            "& .time-counter-bottom-text": {
                fontFamily: "Quicksand",
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '22px',
                marginTop: '60px',
                color: '#666666'
            }
        }
    },

    "& .continue-btn-container": {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    "& .continue-btn": {
        width: '100%',
        maxWidth: '260px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.8px',
        backgroundColor: '#36BFFF',
        boxShadow: '0px 3px #00AEFF',
        borderRadius: '10px',
        textTransform: 'none',
        position: 'relative',
        marginTop: '68px',

        '& :before': {
            left: '50%',
            content: '""',
            display: 'block',
            bottom: 0,
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'white',
            top: '45px'
        },
        '&:hover': {
            backgroundColor: '#00AEFF',
            boxShadow: '0px 3px #36BFFF',
            '&.Mui-disabled': {
                backgroundColor: '#f7f7f7',
            }
        },

        '& .MuiButton-label': {
            fontFamily: 'Nunito',
        },


        '&.Mui-disabled': {
            color: '#ccc',
            boxShadow: '0px 3px #ccc',
            backgroundColor: '#f7f7f7',
        },
    },

    [theme.breakpoints.up("md")]: {
        width: "calc(100% - 240px)",
        marginLeft: '240px',

        "& .left-content-wrapper":{
            width:'50%',
            height:"100%",
            display:'flex',
            background:'#FFC300'
        },

        "& .Back-btn":{
            display:'none'
        },

        "& .main-content-wrapper": {
            overflow:'auto',
            height:"100vh",
            width:"50%",

            "& .main-content-time-counter": {

                "& .time-counter": {

                    "& .time-indicator": {
                        maxWidth: '365px'
                    }
                }
            }
        }
    }
}))
// Customizable Area End
