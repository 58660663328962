import React from "react";
// Customizable Area Start

import { styled } from "@material-ui/core/styles";
import { Box, Typography, Button, Modal } from '@material-ui/core'
import { skip_mask, } from "./assets";
import LessonMaterialModalController from "./LessonMaterialModalController.web";
import { withTranslation } from "react-i18next";
// Customizable Area End
 class LessonMaterialModal extends LessonMaterialModalController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {t} = this.props
        // Customizable Area End
        return (
            // Customizable Area Start

            <SettingContainer
                open={this.props.open}
                onClose={this.props.onClose}
                style={{ zIndex: 2000 }}
                keepMounted

            >
                <Box className="Modal_style">
                    <Box className="left_top_image">
                        <img src={skip_mask} />
                        <div className="index_positioning">{this.props.Material.index}</div>
                    </Box>
                    <Box className="MenuContainer">
                        <Box className="Image_text_Container">
                            <Box><img src={this.props.Material.image} height='70px' width='70px' /></Box>
                            <Box className="text_padding">
                                <Typography className="Text_name"> {this.props.Material.label} </Typography>
                            </Box>
                        </Box>
                        <Box><Button variant="outlined" className="action_btn"
                            onClick={this.props.handleCheetSheetDownload}
                        >{t("Cheatsheet.pdf")}</Button></Box>
                        <Box><Button variant="outlined" className="action_btn"
                            onClick={this.props.handleSummaryDownload}
                        >{t("Summary.pdf")}</Button></Box>
                        <Box><Button variant="outlined" className="action_btn"
                            onClick={this.props.handleVideoDownload}
                        >{t("Summary.mp4")}</Button></Box>
                    </Box>
                </Box>
            </SettingContainer>

            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation() (LessonMaterialModal)
export const SettingContainer = styled(Modal)(({ theme }) => ({

    '& .Modal_style': {
        width: 300,
        background: '#FEEBAF',
        height: '400px',
        border: '5px solid #fff',
        borderRadius: '47px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },

    '& .left_top_image': {
        position: 'relative',
        top: 0,
        left: 0,
        marginLeft: '-5px',
        marginTop: '-4px'
    },

    '& .index_positioning': {
        position: "absolute",
        top: "24px",
        left: "24px",
        color: 'var(--basic-white, #FFF)',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: '18.5px', /* 92.5% */
        letterSpacing: '0.16px',
    },

    '& .MenuContainer': {
        width: "100%",
        padding: '0px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    '& .Image_text_Container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: "center"
    },

    "& .Text_name": {
        color: "var(--primary-blue-dark-gray, #363636)",
        textAlign: "center",
        fontSize: "16px",
        fontStyle: "normal",
        fontFamily: "Nunito",
        fontWeight: 900,
        lineHeight: "22px", /* 137.5% */
        letterSpacing: "0.128px"
    },

    '& .action_btn': {
        width: '220px',
        margin: '6px',
        background: '#fff',
        textAlign: "center",
        fontWeight: 600,
        textTransform: 'none',
        lineHeight: "12px", /* 75% */
        height: 44,
        borderRadius: '12px',
        border: "2px solid var(--primary-neutrals-warm-gray-100, #E6E6E6)",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        letterSpacing: "0.8px",
        color: '#FF8400'
    },

    '& .action_btn > .MuiButton-label': {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "12px", /* 75% */
        letterSpacing: "0.8px",
    },
    '& .text_padding': {
        margin: '20px 0px'
    },


}));
// Customizable Area End