import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    gift_icon,
    arrow_pick,
    box_icon,
    groupTeddy,
    skip_close1,
    teddy_expired
} from "./assets";
import { Props } from './StripePayments';
import { useTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End
import { ViewProps } from "./StripePayments";

// Customizable Area Start
const sections = [
    {
        text: 'Premium lessons',
        text2: 'You re currently experiencing + 1000 extra full, live and premium lessons.',
        text3: 'Available',
        img_src: arrow_pick,
    },
    {
        text: 'Customize Avatar',
        text2: 'A splash of fun with vibrant colors to make it yours!',
        text3: 'Available',
        img_src: arrow_pick,
    },
    {
        text: 'Unlimited lessons',
        text2: 'You’re learning anything you want with no limits!',
        text3: 'Available',
        img_src: box_icon,
    },
    {
        text: 'Win cool prizes',
        text2: 'Compete with others and win exciting rewards.',
        text3: 'Available',
        img_src: gift_icon,
    },

];


// Customizable Area End
const ExpiredPremium: React.FC<Props> = (props) => {
    const { t } = useTranslation();

    const handleClickLater = () => {
        props.navigation.navigate("Settings2");
    }

    const handleClickSkipClose = () => {
        props.navigation.navigate("Settings2");
    }

    return (
        // Customizable Area Start

        <SettingContainer>
            <SideBar />
            <Box className="mainContentExpiredWrapper">
                <Box className="settingExpiredBackground">
                <CenterPortion navigation={undefined} id={""} />
                </Box>
                <Box className="contentContainerPremiu">
                    <Box>
                        <Box style={{ display: 'flex' }}>
                            <Box style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                width: '100%',
                                justifyContent: 'center',
                                position: 'relative',
                            }}>
                                <img src={skip_close1} className="" alt="skip Setting" style={{ marginRight: '10px', position: 'absolute', left: '0px', top: '0px', }} onClick={handleClickSkipClose} data-test-id="skip-close-btn" />
                                <Typography style={webStyle.subscTextTitle}>
                                    {t("Subscription Expired")}
                                </Typography>
                            </Box>

                        </Box>
                        <Box style={{ marginTop: '50px' }}>
                            <Typography style={{
                                fontFamily: 'Nunito',
                                fontSize: '37px',
                                background: 'linear-gradient(90deg, #A95EDB 22.26%, #62CDFF 77.24%)',
                                WebkitBackgroundClip: 'text',
                                backgroundClip: 'text',
                                color: 'transparent',
                                textAlign: 'center',
                                fontWeight: 900,
                                lineHeight: '36px',
                                letterSpacing: '0em',
                            }}>
                                {t("Your Premium")}
                            </Typography>
                            <Typography style={{
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #A95EDB 22.26%, #62CDFF 77.24%)',
                                WebkitBackgroundClip: 'text',
                                fontFamily: 'Nunito',
                                fontSize: '37px',
                                fontWeight: 900,
                                lineHeight: '36px',
                                letterSpacing: '0em',
                                backgroundClip: 'text',
                                color: 'transparent',
                            }}>
                                {t("journey has expired!")}
                            </Typography>
                        </Box>
                    </Box>

                    <Box className="">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography style={{ verticalAlign: 'bottom', lineHeight: '1' }}>
                                <span style={{
                                    display: 'block',
                                    fontFamily: 'Quicksand',
                                    fontSize: '17px',
                                    fontWeight: 700,
                                    lineHeight: '25px',
                                    letterSpacing: '0px',
                                    textAlign: 'center',
                                    color: '#363636',
                                }}>{t("Extend your")} <span style={{ background: 'rgba(0, 0, 0, 0) linear-gradient(90deg, rgb(169, 94, 219) 22.26%, rgb(98, 205, 255) 77.24%) repeat scroll 0% 0% padding-box text', color: ' transparent' }}>{t("Premium")}</span> {t("membership by renewing your subscription!")}</span>
                            </Typography>
                        </div>
                        <div className="btnSectionBottom" >
                            <Button data-testid="btnRenewal" style={{ ...webStyle.btnTextTitle, textTransform: 'none' }} onClick={() => props.navigation.navigate('StripePaymentsView')}>
                                {t("Renew now!")}
                            </Button>
                        </div>
                        <div style={{ marginTop: '10px' }} onClick={handleClickLater} data-test-id="later-btn">
                            <Typography
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '13px',
                                    letterSpacing: '0px',
                                    textAlign: 'center',
                                    color: '#00AEFF',
                                }}
                            >
                                {t("Later")}
                            </Typography>
                        </div>
                    </Box>

                </Box>
                <BottomMenu />
            </Box>
        </SettingContainer>

        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',


    '& .settingExpiredBackground': {
        backgroundColor: 'background: linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
    },

    '& .mainContentExpiredWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

    },


    '& .ImageExpiredWrapper': {
        height: '100%',
        display: 'flex',
        position: 'relative',
        paddingBottom: '30px',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        flexDirection: 'row',
        justifyContent: 'center',
        padding: '0'
    },

    '& .btnSectionBottom': {
        display: 'flex',
        justifyContent: 'center',
        padding: '120px 0px 0px 30px',
        // marginBottom: '100px'
    },

    '& .settingTitleDas': {
        position: 'relative',
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        width: '100%',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
    },
    '& .arrowImageExpired': {
        position: 'absolute',
        left: '33px',
        bottom: '20%',
    },

    '& .teddysImagePremiumExpired': {

        padding: '0',
        position: 'absolute',
        bottom: '0px',
        maxWidth: '240px',
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },
    '& .contentContainerPremiu': {
        flex: '6',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 24px 100px',
        backgroundImage: `url(${teddy_expired})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        justifyContent: 'space-between',
        backgroundSize: '655px auto',

    },


    [theme.breakpoints.up('md')]: {

        '& .settingExpiredBackground': {
            flex: 5,
            position: "fixed",
            width: "calc(50% - 120px)",
            height: "100vh",
        },

        '& .settingTitleDas': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .ImageExpiredWrapper': {
            paddingTop: 'unset',
        },
        '& .mainContentExpiredWrapper': {
            marginLeft: '240px',
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            minHeight: '910px'
        },

        '& .contentContainerPremiu': {
            paddingTop: '90px',
            left: "50%",
            position: "relative",
            maxWidth: "50%",
    },



},

    [theme.breakpoints.up('lg')]: {
    "& .teddysImagePremiumExpired": {
        maxWidth: 'unset'
    },
    '& .mainContentExpiredWrapper': {
        width: `calc(100% - 282px)`,
        marginLeft: '282px',
    },

},

    [theme.breakpoints.between("sm", "md")]: {
    "& .contentContainerPremiu": {
        minHeight: '910px'
    }
},

    [theme.breakpoints.down('sm')]: {

    '& .arrowImageExpired': {
        top: '71px',
        position: 'absolute',
        left: '18%',

    },
    '& .ImageExpiredWrapper': {
        display: 'none',
    },

    '& .btnSectionBottom': {
        display: 'flex',
        justifyContent: 'center',
        padding: '44px 0px 0px 0px',
    },
    '& .contentContainerPremiu': {
        flex: '6',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 24px 100px',
        backgroundImage: `url(${groupTeddy})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        justifyContent: 'space-between',
        backgroundSize: '700px auto',

    },

},


    [theme.breakpoints.down('xs')]: {

    '& .teddysImagePremiumExpired': {
        marginLeft: '0',
        padding: '0',
        maxWidth: '245px',
        left: '37px',
        marginRight: '10px',
        position: 'absolute',
        bottom: '0px',
    },



    '& .ImageExpiredWrapper': {
        display: 'none',
    },

    '& .arrowImageExpired': {
        left: '10%',
        position: 'absolute',
        top: '74px',

    },

    '& .contentContainerPremiu': {
        flex: '6',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 24px 100px',
        backgroundImage: `url(${groupTeddy})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        justifyContent: 'space-between',
        backgroundSize: '434px auto',

    },

    '& .btnSectionBottom': {
        display: 'flex',
        justifyContent: 'center',
        padding: '44px 0px 0px 0px',
    },



},


}))

const webStyle = {

    btnTextTitle: {
        width: '260px',
        letterSpacing: '0.800000011920929px',
        fontFamily: 'Nunito',
        fontSize: '16px',
        height: '40px',
        borderRadius: '12px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        backgroundColor: '#36BFFF',
        fontWeight: 700,
        lineHeight: '16px',

    },
    subscTextTitle: {
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#CCCCCC',
        fontFamily: 'Nunito',
        fontSize: '15px',
        fontWeight: 900,
        textTransform: 'uppercase' as 'uppercase',
    },
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },



};
// Customizable Area End

export default ExpiredPremium;
