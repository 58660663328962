import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { TFunction } from "i18next";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  open:HTMLElement | null | undefined;
  onClose:()=>void;
  Material:IMaterial;
  t:TFunction
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  ToastMessage:string;
  ToastOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface IMaterial{
  id: number|string;
  index?:number;
  show?:boolean;
  image: string,
  label: string,
  summaryPdf: string|null,
  summaryMp4:string|null,
  cheetsheetPdf:string|null
}

// Customizable Area End



export default class MaterialModalController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
          ToastMessage:'',
          ToastOpen:false
            
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    handleDownload=async(url:string,name:string)=>{
    await fetch(url).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `${this.props.Material?.label.replace(" ","_")+'_'+name}`;
          document.body.appendChild(a)
          a.click();
          a.remove()
          a.parentNode?.removeChild(a)
        })
      });
      
    }

    handleCloseToast=()=>{
      this.setState({
        ToastOpen:false,
        ToastMessage:''
      })
    }


  handleCheetsheetPdfDownload = ()=>{
    if(this.props.Material.cheetsheetPdf!==null){
      const cheetsheetUrl =this.props.Material.cheetsheetPdf
    this.handleDownload(cheetsheetUrl,"cheetsheet")
    }else{
      this.setState({
        ToastMessage:this.props.t("Cheetsheet is unavailable"),
        ToastOpen:true
      })
    }
  }

  handleSummaryPdfDownload = ()=>{
    if(this.props.Material?.summaryPdf!==null){
      const summaryPdfUrl = this.props.Material.summaryPdf
    this.handleDownload(summaryPdfUrl,"summary")
    }else{
      this.setState({
        ToastMessage:this.props.t("Summary Pdf is unavailable"),
        ToastOpen:true
      })
    }
  }

  handleSummaryVideoDownload = ()=>{
    if(this.props.Material?.summaryMp4!==null){
      const summaryMp4Url = this.props.Material.summaryMp4
    this.handleDownload(summaryMp4Url,"summary")
    }else{
      this.setState({
        ToastMessage:this.props.t("Summary video is unavailable"),
        ToastOpen:true
      })
    }
  }
    // Customizable Area End
}