Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.subCategories='bx_block_categories/sub_categories';
exports.subCategoryType = "sub_category";
exports.course = "course";
exports.main_course="main";
exports.unit = "unit";
exports.lesson="lesson"
exports.lessonAPI = 'bx_block_profile/list_lessons';
exports.searchAPI = 'bx_block_search/search_learning';
exports.medianAPI = 'bx_block_educational_gamification/median_grades'
exports.LessonDetailEndPoint="bx_block_educational_gamification/median_grades/lessons_detail"
exports.medianGradeDetailAPI = 'bx_block_educational_gamification/median_grades/detail'
exports.gradesByCountry =  'bx_block_categories/categories/get_grades_by_country_id'
exports.getXpTrackerEndPoint ="bx_block_educational_gamification/accounts_event_points"
// Customizable Area End