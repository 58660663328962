import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    teddyBear: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SubscriptionPremiumHighlightsController extends BlockComponent<Props, S, SS> {
   // Customizable Area Start
   // Customizable Area End
   constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
        // Customizable Area Start
        // Customizable Area End
    ];

    this.state = {
        // Customizable Area Start
        teddyBear:''
        // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

async componentDidMount() { 
    this.updateTeddyBear()
   }
   
handleGoBack = () => {
    this.props.navigation.navigate("SubscriptionCancelledPremium")
}

updateTeddyBear = () => {
    const color = localStorage.getItem('avatarColor') || '#62cdff';
    const colorToUpdateTeddy:any = {
        '#ff8400': teddyOrange,
        '#62cdff': teddyBlue,
        '#b7f0ad': teddyLightgreen,
        '#a95edb': teddyPurple,
        '#f96855': teddyRed,
        '#884a39': teddyBrown,
        '#fdce36': teddyYellow,
        '#2bce8c': teddyGreen
    };
  
    const partyTeddy = colorToUpdateTeddy[color] || teddyBlue;
        this.setState({  teddyBear: partyTeddy });
  } 
 // Customizable Area End
}