// Customizable Area Start
export const backgroundColors = {
  bgc1: "#93a7fe",
  bgc2: "#a9e874",
  bgc3: "#ff7a9a",
  bgc4: "#b378f7",
  bgc5: "#ff6674",
  bgc6: "#89e6e5",
  bgc7: "#ffcc64",
  bgc8: "#f8fbff",
};
// Customizable Area End
