import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { Message } from "framework/src/Message";
import { circle , readyOrange,readyBlue,readyBrown,readyGreen,readyLightgreen,readyPurple,readyRed,readyYellow} from "./assets";
import { sendAPIRequest } from '../../../components/src/utils'
import { debounce } from "../../../components/src/utils";
import { TFunction } from "i18next";


export type ListAttributes={
    id: number,
    name: string,
    description: string,
    content: string,
    course_id: number,
    course_name:string,
    created_at: string,
    updated_at: string,
    category_id: number,
    sub_category_id: number,
    unit_id: number,
    unit_number:string,
    unit_name:string,
    main_id: number,
    image?:string;
    message?:string
}
export interface IList{
  image:string,
  name:string,
  section:string,
  data:{
    id:string,
    type:string,
    attributes:ListAttributes
  }|undefined
}

export interface ISectionData{
    id:string,
    type:string,
    attributes:ListAttributes
}

export interface ISection{
  data: Array<ISectionData> |[]
}

export interface IChecked{
  [key:string]:boolean
};

export interface ISearchData{
  searchList:Array<IList>,
  searchTerm:string,
  historyList?:Array<string>
}
export interface ISendSearchActive{
  isActive:boolean
}

export interface ISearchResponseData{
  [key:string]:Array<ISectionData>
}
export interface ISearchResponse{
  data:ISearchResponseData
}

export interface ICurrentProfileResponse{
  data:{
      attributes:ICurrentResponseAttributes
  }
}
export interface ICurrentResponseAttributes{
  avatar_health:string,
  favorite_color:string,
  app_country:{
      id:number
  },
  payment_premium:boolean
}

// Customizable Area End


export interface Props {
    
    // Customizable Area Start
    t:TFunction
    navigation:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    setOpen: boolean;
    searchTerm: string;
    listFilters: Array<string>;
     checked: IChecked;
     filterWith: string;
     newList: Array<IList>;
     searchHistory: Array<string>;
     isSearchFocused: boolean;
     filterList:Array<string>
     searchLoading:boolean;
     searchDataArray:Array<IList>;
     searchMaterialData:Array<IList>;
     searchExerciseData:Array<IList>
     searchData:Array<IList>
     showAllMaterial:boolean;
     showAllExercise:boolean;
     showAll:boolean;
     term: string;
     showHistory:boolean,
     historySearchTerm:string;
     readyTeddy:string;
     profileLoading:boolean;
     country_id:string;
     grade_id:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SearchBarController extends BlockComponent<
    Props,
    S,
    SS
> {
 
  
    // Customizable Area Start
    updatePending: boolean;
    getProfileId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SearchPagePropsMessage),
        ];
        this.state = {
            setOpen: false,
            searchTerm: '', 
            searchLoading:false,
            listFilters:['All','Master','Main','Unit','Lesson','Material','Exercise'], 
            checked: {
              All:true,
              Master:false,
              Main:false,
              Unit:false,
              Material:false,
              Exercise:false,
              Lesson:false,
            },
            filterWith: '', 
            newList: [], 
            searchHistory: [], 
            filterList:[],
            isSearchFocused: false,
            searchDataArray:[],
            searchMaterialData:[],
            searchExerciseData:[],
            searchData:[],
            showAllMaterial:false,
            showAllExercise:false,
            showAll:false,
            term:'',
            showHistory:false,
            historySearchTerm:"",
            readyTeddy:"",
            country_id:"",
            profileLoading:false,
            grade_id:'',
        };
        this.updatePending = false;
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    getSearchList: string = ""

     async componentDidMount() {
      window.addEventListener('beforeunload', this.handleBeforeUnload);
      const storedHistory = localStorage.getItem('searchHistory');
      if (storedHistory) {
        this.setState({ searchHistory: JSON.parse(storedHistory) });
      } 
      this.getProfile()

     }

     componentDidUpdate(prevProps:Props, prevState:S) {
      if(prevState.searchHistory!==this.state.searchHistory){
          localStorage.setItem('searchHistory', JSON.stringify(this.state.searchHistory));
      }
      if (this.state.isSearchFocused !== prevState.isSearchFocused) {
        this.setState({
          showHistory:true
        })
      }
    }
   
    debouceLog = debounce(()=>this.state.searchTerm.length>0&&this.getSearchData(),800)
    handleBeforeUnload=()=>{
      localStorage.removeItem('searchTerm')
    }
     handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {  
        const value = event?.target?.value;
        if(value.trim().length == 0) {
          this.clearSearchData()
        }
        this.setState({ searchTerm: value })
        this.debouceLog()
     };

      clearInput = () => {  
        const { searchTerm, searchHistory } = this.state;
        if (!searchHistory.includes(searchTerm)) {
         const  SearchArr = searchHistory;
          SearchArr.unshift(searchTerm); 
          const updatedHistory = SearchArr;
          this.setState({searchHistory: updatedHistory,})
        }
        this.setState({  
          searchTerm: '',
        });
        this.receiveDataFromSearch({
          searchList: [],
          searchTerm: ""
      });
        localStorage.removeItem('searchTerm')
      };

    async receive(from: string, message: Message) {
      if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
        const gradeId = await message.getData(getName(MessageEnum.SearchPagePropsMessage));
         this.setState({
          grade_id:gradeId||""
         })
       }
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
          this.getSearchRequest(apiRequestCallId, responseJson)
        }
      }

      handleSeeAllMaterialClick = () => {
        this.setState({ showAllMaterial: true });
      };
    
      handleSeeAllExerciseClick = () => {
        this.setState({ showAllExercise: true });
      };

      handleSeeAllClick = () => {
        this.setState({ showAll: true });
      };
    
      getSearchData = () => {
        this.setState({
          searchLoading:true
        })
        let nameParam =  this.state.searchTerm
        const filter = this.state.filterWith
        const token = localStorage.getItem('token')
        const headers = {
          "Content-Type": configJSON.searchApiContentType,
          token
        };
        const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchList = getValidationsMsg.messageId;
    
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.searchAPI}?name=${nameParam}&country_id=${this.state.country_id}&category_id=${this.state.grade_id}&${filter.length>0? filter:'filter=All'}`
        );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetMethod
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

    handleSectionData=(list:Array<ISectionData>,section:string)=>{
        return list.map((item:ISectionData)=>({ 
            name: item.attributes.name||item.attributes.course_name, 
            data:item,
            section:section,
            image: item.attributes.image || circle }))
       }

       
      handleSearchList = (responseJson:ISearchResponse) => {
      if(responseJson?.data){
        const sectionObject =responseJson?.data
        const array:Array<IList> = Object.keys(sectionObject).flatMap((section:string) => {
          const sectionValue  = sectionObject[section]
          return sectionValue[0]?.attributes?this.handleSectionData(sectionValue,section) : []
        });
          
        if (array.length === 0) {
            array.push({ name: 'No Record Found', image: '',data:undefined, section:'' });
        }
        
        let updatedHistory = this.updateHistory()
        this.setState({ newList: this.state.searchTerm === '' ? [] : array });
        localStorage.setItem('searchTerm',this.state.searchTerm);
        this.receiveDataFromSearch({
            searchTerm: this.state.searchTerm,
            historyList: updatedHistory,
            searchList: this.state.newList,
        });
        this.setState({
          searchLoading:false
        })
        };
      }


       updateHistory=()=>{
        const {searchHistory,searchTerm}=this.state
        let updatedHistory: string[]=searchHistory
            if (!searchHistory.includes(searchTerm)) {
               const newSearch = searchTerm!==''
               if(newSearch){
               const SearchArr = searchHistory
               SearchArr.unshift(searchTerm)
               updatedHistory = SearchArr
               this.setState({
                searchHistory:updatedHistory
               })
              }else{
                this.setState({
                  searchHistory:updatedHistory
                 })
              }
            }

            return updatedHistory
       }


    updateReadyAvatar = (avatarColor:string) => {
      const colorToReadyTeddyMap = {
          '#ff8400': readyOrange,
          '#62cdff': readyBlue,
          '#b7f0ad': readyLightgreen,
          '#a95edb': readyPurple,
          '#f96855': readyRed,
          '#884a39': readyBrown,
          '#fdce36': readyYellow,
          '#2bce8c': readyGreen
      };
    
      const readyTeddy = (colorToReadyTeddyMap as {[key:string]:string})[avatarColor] || readyBlue;
          this.setState({ readyTeddy: readyTeddy, });
    }

      getSearchRequest = (apiRequestCallId: string, responseJson: ISearchResponse & ICurrentProfileResponse)  => {    
        if (apiRequestCallId === this.getSearchList) {
            this.handleSearchList(responseJson);
        } 

        if(apiRequestCallId===this.getProfileId){
            this.handleCurrentProfile(responseJson)
        }
    }

    handleCurrentProfile=(responseJson:ICurrentProfileResponse)=>{
      if(responseJson?.data){
        const favorite_color :string = responseJson.data.attributes?.favorite_color
        const searchTerm = localStorage.getItem('searchTerm')
       this.setState({
        profileLoading:false,
        country_id:responseJson.data.attributes?.app_country.id.toString() 
       })
       this.updateReadyAvatar(favorite_color)
       if(searchTerm){
        this.setState({
          searchTerm:searchTerm
        },this.debouceLog)
       }
      }
    }
    
    // Customizable Area End
     
    // Customizable Area Start
    receiveDataFromSearch = (data: ISearchData) => {
      if(data.historyList){
         this.setState({
          searchHistory: data.historyList.slice(0,5)
         })
      }
        const searchList =  data.searchList.filter(item=>item.section!=="materials"&&item.section!=="exercises")
        const searchMaterialList =  data.searchList.filter(item=>item.section==="materials")
        const searchExercise =  data.searchList.filter(item=>item.section==="exercises")
        this.setState({searchData: searchList, searchDataArray :data.searchList, term: data.searchTerm, searchExerciseData:searchExercise,searchMaterialData:searchMaterialList})   
    }
       openDialog = () => {
        this.setState({setOpen: true})
       }

       handleClose = () => {
        this.setState({setOpen: false})
        const checked = this.state.checked

       const result=   Object.values(checked).some(value => value === true);

       if(!result){
        this.setState({
          checked: {All:true, Master:false,Main:false,Unit:false,Lesson:false,},
        })  
       }
       }

       handleSearchTerm=(searchTerm:string)=>{
        this.setState({
         searchTerm
        })
        this.debouceLog()
     }

     clearSearchData = () => {
      this.setState({searchData: [],searchDataArray:[]})
    }

       handleChange = (name: string) => {
        this.setState((prevState) => ({
           checked: {
            ...prevState.checked,
            [name]: !prevState.checked[name],
           },
          }));
        };

        
        handleKeyValue=(filterKey:string)=>{
        return filterKey==='Master'?'course':filterKey
        }
        

      handleSetFilter = () => {
        let CheckedData = this.state.checked
         let keys = Object.keys(CheckedData).filter((keyItem)=>{
          if(CheckedData[keyItem]){
             return  keyItem
          }
         });
          const formatfilter= keys.length&&keys.map((filter:string)=>("filter[]=" + this.handleKeyValue(filter))).join('&')
          this.setState({filterWith: formatfilter || '', filterList:keys},()=>this.getSearchData())
          this.handleClose()
      }

      handleSearchFocus = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if(event) {
          this.setState({ isSearchFocused: true });
        }
        
      };

      handleDeleteHistory = (historyToDelete: string) => {
   
        this.setState((prevState) => {
          const updatedHistoryList = prevState.searchHistory?.filter((history:string) => history !== historyToDelete);
          return { searchHistory: updatedHistoryList };
        });
      };


      getProfile = () => {
        this.setState({
          profileLoading:true
       })
        const token = localStorage.getItem('token')
        this.getProfileId = sendAPIRequest('bx_block_profile/profiles/current_user_profile',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token,
            },
          }
        )
      }

      handleSearchExerciseNavigation=(item:IList)=>{
        const addMessage: Message = new Message(getName(MessageEnum.NavigationChallengeMessage));
        addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        addMessage.addData(getName(MessageEnum.ChallengeSearchedMessage),item);
        this.send(addMessage);
      }
  
      handleSearchMaterialNavigation=(item:IList)=>{
        const addMessage: Message = new Message(getName(MessageEnum.NavigationMaterialMessage));
        addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        addMessage.addData(getName(MessageEnum.MaterialSearchedMessage),item);
        this.send(addMessage);
      }

      handleLandingPage=(item:IList)=>{
        const addMessage: Message = new Message(getName(MessageEnum.NavigationLandingPageMessage));
        addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        addMessage.addData(getName(MessageEnum.LandingPageSearchedMessage),item);
        this.send(addMessage);
      }
  

      handleSearchResult=(item:IList)=>{ 
        if(item.section==='exercises'){
           return this.handleSearchExerciseNavigation(item)
        }else if(item.section==='materials'){
           return  this.handleSearchMaterialNavigation(item)
        }
        else{
           this.handleLandingPage(item)
        }
      }

      closeSearch = () => {
        this.props.navigation.goBack()
      }
    // Customizable Area End
}