import React from 'react'
import SideBar from './Sidebar'
import { Box, styled } from '@material-ui/core'
import BottomMenu from './BottomMenu'

interface NavigateLayoutProps {
  menuName: 'Home' | 'Menu' | 'Leaderboard' | 'Profile' | 'Settings'
  children: any
  isShowBottomMenu?: boolean
}

const NavigateLayout = ({
  menuName,
  children,
  isShowBottomMenu = true,
}: NavigateLayoutProps) => {
  return (
    <NavigateLayoutContainer>
      <SideBar menuName={menuName} />
      {children}
      {isShowBottomMenu && <BottomMenu menuName={menuName} />}
    </NavigateLayoutContainer>
  )
}

/**
  Please style children component like this:
  const ChildrenContainer = styled(Box)(({theme}) => ({
    backgroundColor: 'What color you want',
    height: '100%',

    [theme.breakpoints.up('md')]: {
        paddingLeft: '240px'
    },

    [theme.breakpoints.up('lg')]: {
        paddingLeft: '282px'
    },
  }))
*/

const NavigateLayoutContainer = styled(Box)(() => ({
  height: '100%',
  width: '100%',
}))

export default NavigateLayout
