// Customizable Area Start
import React from 'react'
import ChangePasswordController, { Props } from './ChangePasswordController'
import NavigateLayout from '../../../components/src/NavigateLayout'
import {
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
  styled,
  Modal,
} from '@material-ui/core'
import SettingBackground from './SettingBackground'
import SettingHeader from './SettingHeader'
import { imgChangePassword, imgChangePasswordSuccessBg, loaderBlue } from './assets'
import { TextInputCustom } from '../../../components/src/MaterialTheme'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { imgButtonClose } from '../../contactus/src/assets'
import { withTranslation } from 'react-i18next'

class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props)
  }

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    ) : (
      <Visibility
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    )
  }

  handleClickGoBack = () => {
    this.props.navigation?.goBack()
  }

  handleClose = () => {
    this.setState({ showAlert: false })
  }

  changePasswordSchema = Yup.object({
    currentPassword: Yup.string()
      .trim()
      .required(this.props.t('Current password is required')),
    newPassword: Yup.string().trim()
      .required(this.props.t('New password is required'))
      .notOneOf([Yup.ref('currentPassword')], this.props.t('Your new password is same as current password. Please use another')),
    confirmPassword: Yup.string()
      .trim()
      .required(this.props.t('Confirm password is required'))
      .oneOf([Yup.ref('newPassword'), null], this.props.t('New password must match')),
  })

  handleShowCurrentPassword = () => this.setState({showCurrentPassword: !this.state.showCurrentPassword})
  handleShowNewPassword = () => this.setState({showNewPassword: !this.state.showNewPassword})
  handleShowConfirmPassword = () => this.setState({showConfirmPassword: !this.state.showConfirmPassword})
  handleCloseModal = () => this.setState({showModalSuccess: false})

  renderContainer = () => {
      return(
        <>
          <SettingBackground />
          <Box className="contentContainer">
            <Formik
              data-testId='changeForm'
              initialValues={{
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={this.changePasswordSchema}
              onSubmit={(values, {resetForm}) => {
                this.submitCreateNewPassword(values.currentPassword, values.newPassword)
                resetForm()
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form
                  className='changePasswordForm'
                  onSubmit={handleSubmit}
                >
                  <SettingHeader
                    onGoBack={this.handleClickGoBack}
                    title={this.props.t("Password")}
                    headerImage={imgChangePassword}
                    typeButton='submit'
                    actionButtonText={this.state.saveButtonText}
                    disableButton={values.currentPassword === '' || values.newPassword === '' || values.confirmPassword === ''}
                  />
                  <Box className="changePasswordContainer">
                    <Box className="changePasswordWrapper">
                      <Box className="textFieldWrapper">
                        <Typography className="labelText">
                          {this.props.t("Current Password")}
                        </Typography>
                        <TextInputCustom
                          variant="outlined"
                          value={values.currentPassword}
                          onChange={handleChange}
                          error={Boolean(touched.currentPassword && errors.currentPassword)}
                          helperText={touched.currentPassword && errors.currentPassword}
                          autoComplete="off"
                          name="currentPassword"
                          type={
                            this.state.showCurrentPassword ? 'password' : 'text'
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-testId='currentPassword'
                                  onClick={this.handleShowCurrentPassword}
                                  edge="end"
                                >
                                  {this.renderIconShowPassword(
                                    this.state.showCurrentPassword
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="textFieldWrapper">
                        <Typography className="labelText">
                          {this.props.t("New Password")}
                        </Typography>
                        <TextInputCustom
                          variant="outlined"
                          value={values.newPassword}
                          onChange={handleChange}
                          error={Boolean(touched.newPassword && errors.newPassword)}
                          helperText={touched.newPassword && errors.newPassword}
                          autoComplete="off"
                          name="newPassword"
                          type={
                            this.state.showNewPassword ? 'password' : 'text'
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleShowNewPassword}
                                  edge="end"
                                >
                                  {this.renderIconShowPassword(
                                    this.state.showNewPassword
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box className="textFieldWrapper">
                        <Typography className="labelText">
                          {this.props.t("Confirm New Password")}
                        </Typography>
                        <TextInputCustom
                          variant="outlined"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                          helperText={touched.confirmPassword && errors.confirmPassword}
                          autoComplete="off"
                          name="confirmPassword"
                          type={
                            this.state.showConfirmPassword ? 'password' : 'text'
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleShowConfirmPassword}
                                  edge="end"
                                >
                                  {this.renderIconShowPassword(
                                    this.state.showConfirmPassword
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <Snackbar
            open={this.state.showAlert}
            onClose={this.handleClose}
            className="snackBar"
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            autoHideDuration={2000}
          >
            <Box
              className="snackBarWrapper"
              style={{
                backgroundColor:
                  this.state.popupData.type === 'error' ? '#F96855' : '#62CDFF',
              }}
            >
              <Typography className="snackBarText">
                {this.state.popupData.text}
              </Typography>
            </Box>
          </Snackbar>
          <ModalStyled
            onClose={this.handleCloseModal}
            open={this.state.showModalSuccess}
          >
            <Box className="container">
              <div className="closeBtn" onClick={this.handleCloseModal}>
                <img src={imgButtonClose} width={10} height={10} />
              </div>
              <div className='imageBg'>
                <img src={this.state.successTeddy} className="teddyImg" />
              </div>
              <Typography className="title">{this.props.t("Hooray!")}</Typography>
              <Typography className="description">
                {this.props.t("Your changes are saved.")}
              </Typography>
            </Box>
          </ModalStyled>
          </>
      )
  }

  render() {
    return (
      <NavigateLayout menuName="Settings">
        <ChangePasswordContainer> 
        {this.state.setLoader ? (
        <Box className="loaderShown">
        <img src={loaderBlue} alt="Loading" />
      </Box>
    ) :  
    <>
    {this.renderContainer()}
    </>
        }
        </ChangePasswordContainer>
      </NavigateLayout>
    )
  }
}
export default withTranslation()(ChangePassword)
const ChangePasswordContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#A95EDB',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: '6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(80% - 240px)",
     marginLeft: "240px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },

  '& .changePasswordForm': {
    flex: 1, 
    width: '100%', 
    overflow: 'scroll', 
    scrollbarWidth: 'none'
  },

  '& .changePasswordForm::-webkit-scrollbar': {
    display: 'none'
  },

  '& .changePasswordContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#A95EDB',
    width: '100%',
    overflow: 'scroll',
    scrollbarWidth: 'none'
  },

  '& .changePasswordContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .changePasswordWrapper': {
    flex: 1,
    backgroundColor: '#fff',
    overflow: 'scroll',
    paddingTop: '51px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px',
    scrollbarWidth: 'none'
  },

  '& .changePasswordWrapper::-webkit-scrollbar': {
    display: 'none'
  },

  '& .textFieldWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '15px',
  },

  '& .labelText': {
    fontFamily: 'Quicksand',
    fontWeight: '700',
    fontSize: '12px',
    color: '#7F7F7F',
    textTransform:'uppercase',
    marginBottom: '10px',
  },

  '& .snackBarWrapper': {
    width: '300px',
    borderRadius: '4px',
    color: '#fff',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
  },

  '& .snackBarText': {
    marginLeft: '12px',
    fontWeight: '500',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    wordWrap: 'break-word',
    overflow: 'hidden',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .changePasswordWrapper': {
      borderRadius: '24px 0 0 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px'
  },
}))

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '50%',
    display: 'flex',
    width: 292,
    transform: 'translate(-50%, -50%)',
    padding: 26,
    backgroundColor: '#fff',
    borderRadius: 12,
    border: '2px solid #e6e6e6',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .closeBtn': {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  '& .imageBg': {
    width: '239px', 
    height: '135px', 
    backgroundImage: `url(${imgChangePasswordSuccessBg})`, 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },

  '& .teddyImg': {
    width: '98.98px',
    height: '100px',
  },

  '& .title': {
    fontSize: '18px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#363636'
  },

  '& .description': {
    textAlign: 'center',
    fontSize: '15px',
    fontFamily: 'Quicksand', 
    fontWeight: '500',
    color: '#666666',
    marginTop: '10px'
  }
}))

// Customizable Area End
