// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'

export const configJSON = require('./config')

interface S {
}

interface SS {
  id: any
}

export interface Props {
  navigation: any
  id: string
  t?:any
}

export default class GuestKeepTrackProgressController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }
}
// Customizable Area End
