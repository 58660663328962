import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import { greenViewRectangle, yellowViewRectangle } from '../../../blocks/cftableforlessons/src/assets'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import  {useTranslation} from "react-i18next"
import { mistakeBlueTeddy, mistakeBrownTeddy, mistakeGreenTeddy, mistakeMintTeddy, mistakeOrangeTeddy, mistakePurpleTeddy, mistakeRedTeddy, mistakeYellowTeddy } from '../assets'
interface MistakePageProps {
  onClickContinue: ({
    isClickFromMistake,
  }: {
    isClickFromMistake?: boolean
  }) => void
}

const MistakePage: React.FC<MistakePageProps> = ({ onClickContinue }) => {
  const  { t } = useTranslation()
  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': mistakeOrangeTeddy,
    '#62cdff': mistakeBlueTeddy,
    '#b7f0ad': mistakeMintTeddy,
    '#a95edb': mistakePurpleTeddy,
    '#f96855': mistakeRedTeddy,
    '#884a39': mistakeBrownTeddy,
    '#fdce36': mistakeYellowTeddy,
    '#2bce8c': mistakeGreenTeddy
  };

  const mistakeTeddy = colorToUpdateTeddy[avatarColor] || mistakeBlueTeddy;
  return (
    <MistakePageContainer>
      <Box className="upContainer">
        <Box style={{ position: 'relative' }}>
          <img src={greenViewRectangle} width={240} height="auto" />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#2BCE8C' }}>
             {t("Don’t give up! Keep practicing")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="titleWrapper">
        <Typography className="titleText" style={{ fontSize: '20px' }}>
          {t("MISTAKES ARE PART OF")}
        </Typography>
        <Typography className="titleText" style={{ fontSize: '30px' }}>
          {t("LEARNING")}!
        </Typography>
      </Box>
      <Box className="imageWrapper">
        <img src={mistakeTeddy} width={180} height="auto" />
      </Box>

      <Box className="downContainer">
        <Box style={{ position: 'relative' }}>
          <img src={yellowViewRectangle} width={200} height="auto" />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#EBBB21' }}>
              {t("You'll get it next time!")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        className="buttonContainer"
        onClick={() => onClickContinue({ isClickFromMistake: true })}
      >
        <StyledButton>{t('Continue')}</StyledButton>
      </Box>
    </MistakePageContainer>
  )
}

const MistakePageContainer = styled(Box)(({ theme }) => ({
  marginTop: '55px',
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  '& .upContainer': {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '60px',
  },

  '& .textWrapper': {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#363636',
  },

  '& .styledText': {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },

  '& .titleWrapper': {
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .imageWrapper': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '26px',
  },

  '& .downContainer': {
    display: 'flex',
    marginTop: '64px',
    marginLeft: '60px',
  },

  '& .buttonContainer': {
    flex: 1,
    margin: '32px 26px 40px',
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  [theme.breakpoints.up('md')]: {
    '& .buttonContainer': {
      margin: '32px 60px 40px',
    },
  }
}))

export default MistakePage
