import React from "react";
// Customizable Area Start
import InAppNotificationHeader from "./InAppNotificationHeader.web";
import InAppDeleteNotificationController, {
  Props,
  configJSON
} from "./InAppDeleteNotificationController.web";
import {
  MenuIcon,
  DeleteIcon2,
  DeleteIconDetail,
  NotificationIcon1,
  RecoverNotification,
  CancelIcon,
  RecoverIconDeleteScreen,
  DeleteDisable,
  RecoverDisable,
} from "./assets";
import { Checkbox, Modal } from "@material-ui/core";
import InAppNotificationMainLayout from "./InAppNotificationLayout.web";
import { withTranslation } from "react-i18next";
export interface ResDataItem {
  id: number,
  type: string,
  attributes:{
      id: number,
      status: string,
      subject: string,
      content: string,
      content_as_plain_text: string,
      user_name: string,
      image: string,
      is_button: boolean,
      button_url: string,
      button_content: string,
      time_ago: string
  }
}
// Customizable Area End

interface CardData {
  // Customizable Area Start
  id: number;
  image: string;
  title: string;
  desc: string;
  timeAgo:string;
  // Customizable Area End
}

class InAppDeleteNotification extends InAppDeleteNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDeleteNotificationMenu = () => {
    return (
      <ul className="notificationTopMenuListDelete">
        <li
          className="notificationTopMenuListDeleteItem"
          onClick={this.handleselectNotification}
          data-testid="selectNotificationTestId"
        >
          <img
            src={NotificationIcon1}
            className="notificationTopMenuListDeleteItemImage"
          />
          <p className="notificationTopMenuListDeleteItemTitle">
            {this.props.t(configJSON.deleteSelectNotificationText)}
          </p>
        </li>
        <li className="notificationTopMenuListDeleteItem" data-testid={"recoverAllTestId"} onClick={this.inAppRecoverAllNotification}>
          <img
            src={RecoverNotification}
            className="notificationTopMenuListDeleteItemImag"
          />
          <p className="notificationTopMenuListDeleteItemTitle">{this.props.t(configJSON.deleteRecoverAllText)}</p>
        </li>
        <li className="notificationTopMenuListDeleteItem" data-testid={"deleteAllTestId"} onClick={()=> this.handleOpenDeleteModal("deleteAll")}>
          <img
            src={DeleteIconDetail}
            className="notificationTopMenuListDeleteItemImage"
          />
          <p className="notificationTopMenuListDeleteItemTitle">{this.props.t(configJSON.deleteDeleteAllText)}</p>
        </li>
      </ul>
    );
  };

  renderDeleteMenu = () => {
    return (
      <div className="notificationCenter">
        <div className="notificationTopMenu" data-testid="deleteMenuIcon">
          <div
            className={
              this.state.openNotificationMenu
                ? "notificationTopMenuContainer notificationTopMenuContainerActive"
                : "notificationTopMenuContainer "
            }
            onClick={this.handleOpenNotificationMenu}
            data-testid="openNotificationTestId"
          >
            <img src={MenuIcon} className="notificationTopMenuIcon" />
            {this.state.openNotificationMenu && this.renderDeleteNotificationMenu()}
          </div>
        </div>
      </div>
    );
  };

  renderNotificationSubHeading = () => {
    return (
      <div className="NotificationSubHeading">
        {this.props.t(configJSON.deleteNotificationSubHeading)}
      </div>
    );
  };

  renderDeleteMarkAsReadTopMenu = () => {
    return (
      <div className="renderMarkAsReadTop">
        <div className="renderMarkAsReadTopContainer" data-testid="recoverBtn" onClick={ this.inAppRecoverSelectedNotification }>
          <img src={this.state.selectedNotifications.length === 0 ? RecoverDisable : RecoverIconDeleteScreen} className="renderRecoverIcon" />
          <p className={this.state.selectedNotifications.length === 0 ? "renderMarkAsReadTextDisable" : "renderMarkAsReadText"}>{this.props.t(configJSON.deleteRecoverText)}</p>
        </div>
        <div
          className="renderMarkAsReadDeleteContainer"
          onClick={()=> this.handleOpenDeleteModal("selectedNotification") }
          data-testid="deleteBtnTestID"
        > 
          <img src={this.state.selectedNotifications.length === 0 ? DeleteDisable : DeleteIcon2} className="renderMarkAsReadIcon2" />
          <p className={this.state.selectedNotifications.length === 0 ? "renderMarkAsReadDeleteTextDisable" :"renderMarkAsReadDeleteText"}>{this.props.t(configJSON.deleteDeleteText)}</p>
        </div>
        <div
          className="renderMarkAsReadCancelContainer"
          onClick={this.handleCancelSelectNotification}
          data-testid="cancelBtn"
        >
          <img src={CancelIcon} className="renderMarkAsReadIcon3" />
          <p className="renderMarkAsReadCancelText">{this.props.t(configJSON.deleteCancelText)}</p>
        </div>
      </div>
    );
  };

  renderAllDeletedNotification = () => {
    return (
      <div className="allNotificationContainer">
        {this.state.notificationData.map((item: ResDataItem, index: number) => {
          return (
            <div className="deleteNotificationCard" key={index}>
              <div >
                {!this.state.selectNotification && <img src={item.attributes.image} className="deleteNotificationCardImage"/>}
                {this.state.selectNotification && (
                  <Checkbox value={item.id} className={"cardCheckbox"} data-testid={`deletedNotificationCheckbox_${index}`} onChange={()=>this.handleCheckboxChange(item.id)}/>
                )}
              </div>
              <div className="deleteNotificationCardDetails">
                <div className="deleteNotificationHeading">{item.attributes.subject}</div>
                <p className="deleteNotificationPara">{ item.attributes.content_as_plain_text}</p>
                <div className="deleteNotificationTime">{item.attributes.time_ago}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderDeletedNotificationDeletedModal = () => {
    return (
      <Modal open={this.state.openDeleteModal}>
        <div className="deleteModalBox">
          <div className="deleteModalInnerBox">
            <img src={DeleteIcon2} className="deleteModalImage" />
          </div>
          <p className="deleteModalTitle">{configJSON.deleteModalTitleText}</p>
          <p className="deleteModalWarningText">
            {this.props.t(configJSON.deleteModalWarningText)}
          </p>
          <div className="deleteModalBtnContainer">
            <button
              onClick={this.handleModalCancel}
              data-testid="deleteModalCancelBtn"
              className="deleteModalCancelBtn"
            >
              {this.props.t(configJSON.deleteCancelText)}
            </button>
            <button
              className="modalDeleteBtn"
              onClick={this.handleDeleteModalApiCall}
              data-testid="deleteModalDeleteButtton"
            >
              {this.props.t(configJSON.deleteDeleteText)}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  renderDeleteNotification = () => {
    return (
      <div className="deleteNotification">
        <InAppNotificationHeader
          heading={this.props.t("Deleted Notifications")}
          navigation={{
            navigate: function (screenName: string): void {},
          }}
        />
        {!this.state.selectNotification && this.renderDeleteMenu()}
        {this.state.selectNotification && this.renderDeleteMarkAsReadTopMenu()}
        {this.renderNotificationSubHeading()}
        {this.renderAllDeletedNotification()}
        {this.renderDeletedNotificationDeletedModal()}
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div className="InappDeleteNotification">
        <InAppNotificationMainLayout children={this.renderDeleteNotification()} topicName={this.state.topicName} unitName={this.state.unitName} openDrawer={this.state.openDrawer} trophiesOpen={this.state.trophiesOpen} goalsOpen={this.state.goalsOpen} handleOpenDrawer={(name:string)=>this.handleOpenDrawer(name)} navigation={this.props.navigation} data-testid={'centerCardTestId'}/>
      </div>
    );
    // Customizable Area End
  }
}

export default withTranslation()(InAppDeleteNotification);