import React, { useEffect, useState } from 'react'
import { Box, ListItemAvatar, InputAdornment, Popover, TextField, Typography, styled } from '@material-ui/core'
import { CheckCircle, Search } from '@material-ui/icons'
import { useTranslation } from "react-i18next"
import { parsePhoneNumberFromString as parsePhoneNumber } from "libphonenumber-js"

export interface ICountry {
    id: string
    type: string
    attributes: {
        name: string
        country_code: string
        alpha2: string
        alpha3: string
    }
    update?:boolean
}

interface CustomCountrySelectProps {
    list: Array<ICountry>
    value?: string
    setValue: (item: ICountry) => void
    showSearchInput?: boolean
    showCategoriesIcon?: boolean
    isError?: boolean
    style?: any
    flag?:ICountry
    disableSelect?: boolean
}

const CustomCountrySelect = ({
    list,
    value,
    setValue,
    flag,
    showSearchInput,
    showCategoriesIcon,
    isError,
    style,
    disableSelect
}: CustomCountrySelectProps) => {

    const { t } = useTranslation()
    const [listData, setListData] = useState(list)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClose = () => {
        setAnchorEl(null)
    }
    
    useEffect(() => {
        if (value) {
            const phoneNumber = parsePhoneNumber(value);
            const countryName: any = phoneNumber?.countryCallingCode.toString()
            const defualtValue = list.find((item: any) => item.attributes.country_code === countryName)
            if (defualtValue) {
                setValue({...defualtValue,update:false})
            }
        } else {
            const defualtValue = list.find((item: any) => item.attributes.name === "Romania")
            if (defualtValue) {
                setValue({...defualtValue,update:true})
            }
        }
    }, [list,value])

    const handleSearch = (searchTerm: string) => {
        const filtered = list.filter((item) =>
          item.attributes.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
        setListData(filtered)
    }

    const handleClick = (event: any) => {
        !disableSelect && setAnchorEl(event.currentTarget)
      }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    useEffect(() => {
        if (!open) {
            setListData(list)
        }
    }, [open, list])
    

    return (
        <Box>
            <Box onClick={handleClick}>
              <img
                className='flagIcon'
                width={20}
                height="auto"
                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${flag?.attributes.alpha2}.svg`}
                alt="flag"/>
            </Box>
            <PopoverStyled
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <CustomSelectWrapper>
                    {showSearchInput && (
                        <Typography
                            style={{ padding: '15px 15px 0px 15px', marginBottom: '6px' }}
                        >
                            <TextField
                                className="search-field"
                                name="Search"
                                id="search"
                                placeholder="Search"
                                fullWidth
                                onChange={(e) => handleSearch(e.target.value)}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" disablePointerEvents>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Typography>
                    )}
                    {listData.length === 0 ? (
                        <ul className="itemWrapper">
                            <li className="item">{t('No result found!')}</li>
                        </ul>
                    ) : (
                        <ul className="itemWrapper">
                            {listData?.map((item: any, index: number) => {
                                return (

                                    <li
                                        className="item"
                                        key={index}
                                        onClick={() => {
                                            setValue({...item,update:true})
                                            setListData(list)
                                            handleClose()
                                        }}
                                    >
                                        <Box style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                                        <ListItemAvatar>
                                        <img className='flagIcon' src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${item?.attributes.alpha2}.svg`}alt="flag"/>
                                        </ListItemAvatar>  
                                           <Typography>{item.attributes.name}</Typography> 
                                        </Box>
                                        {value === item && (
                                            <CheckCircle
                                                style={{
                                                    width: '18px',
                                                    height: '18px',
                                                    color: '#62CDFF',
                                                }}
                                            />
                                        )}
                                    </li>

                                )
                            })}
                        </ul>
                    )}
                </CustomSelectWrapper>
            </PopoverStyled>
        </Box>
    )



}

const PopoverStyled = styled(Popover)({
    marginTop: '15px',

    '& .MuiPopover-paper': {
        borderRadius: '10px',
    },
})

const CustomSelectWrapper = styled(Box)(({ theme }) => ({
    width: '400px',
    [theme.breakpoints.down('xs')]: {
        width: '300px',
    },
    background: '#F7F7F7',
    borderRadius: '10px',
    border: '2px solid #999',

    '& .search-field': {
        '& fieldset': {
            borderWidth: '0',
        },
        '& .Mui-focused fieldset': {
            borderWidth: '0px',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#e6e6e6',
            borderRadius: '8px',
            fontSize: '16px',
            fontFamily: 'Quicksand',
            fontWeight: '500',
            color: '#4D4D4D',
        },
        '& .MuiOutlinedInput-input': {
            paddingTop: '6.5px',
            paddingBottom: '6.5px',
            cursor: 'text',
        },
        '& .MuiInputAdornment-positionStart': {
            marginRight: '6px',
        },
    },

    '& .item': {
        listStyleType: 'none',
        borderBottom: '1px solid #E6E6E6',
        color: '#363636',
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '500',
        padding: '8px 0',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '& .itemWrapper': {
        padding: '0 15px 15px 15px',
        maxHeight: '146px',
        overflow: 'auto',
    },

    '& .flagIcon': {
        height:20,
        width:24,
        marginRight: "2rem"
    },

}))

export default CustomCountrySelect