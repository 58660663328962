import React from 'react'
import { Box, Typography, styled } from '@material-ui/core'
import {
  challengeRouletteBg,
  closeBtn,
  rouletteResult,
} from '../../../blocks/assessmenttest/src/assets'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import { useTranslation } from 'react-i18next'

interface ChallengeRouletteResultProps {
  onClickContinue: ({
    isClickFromChallengeRouletteResult,
  }: {
    isClickFromChallengeRouletteResult?: boolean
  }) => void
  nextLessonName: string
  onGoBack: () => void
}

const ChallengeRouletteResult: React.FC<ChallengeRouletteResultProps> = ({ 
  onClickContinue,
  nextLessonName,
  onGoBack,
}) => {
  const { t } = useTranslation()
  
  return (
    <ChallengeRouletteResultContainer>
      <Box className="childContentContainer">
        <Box className="topWrapper">
          <img src={closeBtn} className="cancelButton" onClick={onGoBack} />
          <Typography className="titleText" style={{ fontSize: '21px' }}>
            {t('YOUR CHALLENGE')}
          </Typography>
          <Typography className="titleText" style={{ fontSize: '32px' }}>
            {t('IS READY!')}
          </Typography>
        </Box>
        <Box className='rouletteAnimation'>
          <Box className='nextLessonWrapper'>
            <Typography className='nextLessonText'>{nextLessonName}</Typography>
          </Box>
        </Box>
        <Box className="bottomWrapper">
          <Typography className="descText">
            {t('Time to test your knowledge and skills. Are you ready?')}
          </Typography>
          <StyledButton
            onClick={() =>
              onClickContinue({ isClickFromChallengeRouletteResult: true })
            }
          >
            {t("Let's go")}!
          </StyledButton>
        </Box>
      </Box>
    </ChallengeRouletteResultContainer>
  )
}

const ChallengeRouletteResultContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  flex: 1,
  display: 'flex',
  backgroundSize: '100% 100%',
  backgroundImage: `url(${challengeRouletteBg})`,
  flexDirection: 'column',
  height: '100%',

  '& .rouletteAnimation': {
    height: '341px',
    width: '318px',
    backgroundImage: `url(${rouletteResult})`,
    margin: '20px 0',
  },

  '& .nextLessonText': {
    position: 'absolute',
    width: '60%',
    left: '50%',
    transform: 'translatex(-50%)',
    top: '240px',
    fontFamily: 'Nunito',
    fontSize: '18px',
    fontWeight: 700,
    color: '#29ce8c',
    textAlign: 'center',
    lineHeight: '1'
  },

  '& .nextLessonWrapper': {
    width: '100%',
    height: '100%',
    position: 'relative'
  },

  '& .childContentContainer': {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .topWrapper': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '& .cancelButton': {
    margin: '26px 0 42px 26px',
    width: '20px',
    alignSelf: 'flex-start',
    height: '20px',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    color: '#363636',
    fontWeight: 900,
  },

  '& .bottomWrapper': {
    width: '100%',
    marginBottom: '40px',
    padding: '0 26px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .descText': {
    fontWeight: 700,
    fontFamily: 'Quicksand',
    fontSize: '16px',
    marginBottom: '64px',
    color: '#363636',
    textAlign: 'center',
  },

  [theme.breakpoints.up('md')]: {
    '& .cancelButton': {
      margin: '60px 0 42px 60px',
    },

    '& .bottomWrapper': {
      padding: '0 60px',
    },
  },
}))

export default ChallengeRouletteResult
