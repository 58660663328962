// Customizable Area Start
import * as React from "react";
import BottomMenu from "../../../components/src/BottomMenu";
import {
  skip_freemiumIcon,
  btn_back,
  img_chk,
  view_rectangle,
  skip_vector,
  skip_wrench,
  skip_bolt,
  skip_arrow,
} from "./assets";
import {
  styled,
  Box,
  ListSubheader,
  Typography,
  Grid,
  List,
  Button,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import NavigateLayout from "../../../components/src/NavigateLayout";
import { Props } from './StripePayments';
import { useTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'

const premiumText = [{ id: 0, text: "Unlock full, live and premium lessons" }, { id: 1, text: "Personalize your avatar" }, { id: 2, text: "Unlimited usage" }, { id: 3, text: "Win cool prizes" }]

const StripePaymentsView: React.FC<Props> = ({
  navigation
}) => {
  const { t } = useTranslation();
  const handleClickGoBack = () => {
    navigation.navigate("Settings2");
  }

  return (
    <NavigateLayout menuName="Settings">
      <SettingContainer>
        <Box className="mainContentWrapper">
          <Box className="settingpremiumBackground">
          <CenterPortion navigation={undefined} id={""} />
          </Box>

          <Box className="contentContainerView">
            <Box className="settingBackground">
              <Box className="settingImgWrapper">
                <img src={skip_wrench} className="teddyImage" alt="Teddy Setting" />
                <Typography className="settingTitle">
                  <img src={btn_back} className="arrowImage" alt="arrow btn" style={{ marginRight: '5px' }} onClick={handleClickGoBack} />
                  <Typography className="settingTitleText">{t("Subscription")}</Typography>
                </Typography>
              </Box>
            </Box>

            <List
              component="nav"
              className="accountWrapper"
              subheader={
                <ListSubheader disableSticky component="div" className="settingListTitle">
                  {t("Current Plan")}
                </ListSubheader>
              }
            >
              <Box style={webStyle.freeParent} >
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <div style={webStyle.freeimg}>
                      <img src={skip_freemiumIcon} alt="skip_freemiumIcon" />
                    </div>
                  </Grid>
                  <Grid item xs>
                    <Typography style={webStyle.freefirstTextView}>
                      {t("Freemium")}
                    </Typography>
                    <Typography style={webStyle.freesecondText} >
                      {t("Basic plan with limited benefits")}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </List>
            <div style={{ paddingTop: '25px', width: '100%' }}>
              <Box style={webStyle.premiumparent} >
                <Grid container wrap="nowrap" spacing={2} style={{ padding: '50px 50px 0px 50px' }}>
                  <div style={webStyle.premiumMain}>
                    <img src={skip_vector} alt="skip_vector" style={{ maxWidth: '54px' }} />
                  </div>
                  <Grid item xs>
                    <Typography style={webStyle.premiumText}>
                      {t("Premium")}
                    </Typography>
                    <Typography style={webStyle.premiumTextFirst} >
                      {t("Elevate your app experience: Become a VIP member!")}
                    </Typography>
                  </Grid>
                </Grid>
                {premiumText.map((item) => (
                  <Grid key={item.id} container wrap="nowrap" alignItems="center" spacing={2} style={{ paddingLeft: '50px' }}>
                    <Grid item>
                      <img src={img_chk} alt={`img_chk${item.id}`} />
                    </Grid>
                    <Grid item xs>
                      <Typography style={webStyle.textTypograpy} >
                        {t(item.text)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}

                <div style={webStyle.benefitMain}>
                  <Link to="/SubscriptionPremium" style={webStyle.ExploreText}>{t("Explore more benefits")}</Link>
                  <img src={skip_arrow} alt="test" style={{ marginLeft: '5px', alignSelf: 'center' }} />
                </div>
                <Grid container wrap="nowrap" spacing={2} alignItems="center" style={webStyle.xpMain}>
                  <Box style={webStyle.parentSectionImage}>
                    <Grid item >
                      <img src={skip_bolt} alt="skip_bolt" style={webStyle.imgSkip} />
                    </Grid>
                    <Grid item >
                      <Typography style={webStyle.textTtile}>
                        1000 XP
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </div>
            <Box style={webStyle.boxparent}>
              <Box style={webStyle.btnBoxMain}>
                <Link to="/PremiumSubscription"> <Button style={webStyle.btnTitle}>
                  {t("Cant wait? Get Premium now!")}
                </Button></Link>
              </Box>
            </Box>
            <Box style={webStyle.footerTextMain} >
              <Typography style={webStyle.getFirstTitle}>
                {t("By selecting 'Get Premium now!', you'll be opting for our paid subscription.")}
              </Typography>
            </Box>
          </Box>
          <BottomMenu />
        </Box>
      </SettingContainer>
    </NavigateLayout>
  );
};

const SettingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#A95EDB',
  height: '100%',
  width: '100%',

  "& .arrowimage": {
    position: 'absolute',
    top: '55%',
    left: '0'
  },

  '& .settingpremiumBackground': {
    backgroundColor: '#fff',
  },
  '& .settingImgWrapper': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#A95EDB',
    borderBottomRightRadius: '20px',
    paddingTop: '45px',
    paddingLeft: '33px',
    paddingBottom: '30px',
    position: 'relative',
  },

  '& .teddyImage': {
    Width: '245px',
    marginRight: '10px',
    marginLeft: '0',
    padding: '0',
    position: 'absolute',
    bottom: '0px',
    display: 'block',
    left: '34px'
  },
  '& .teddySettingImage': {
    Width: '245px',
    marginRight: '10px',
    marginLeft: '0',
    padding: '0',
    position: 'absolute',
    bottom: '0px',
    display: 'block',
    left: '34px'
  },

  '& .BenefitsWrapper .MuiListItem-root': {
    display: 'inline-block',
    marginRight: '20px',
  },
  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },


  '& .settingBackground': {
    backgroundColor: '#fff',
    width: 'calc(100% + 48px)',
    height: '111px'
  },

  '& .settingImgWrapperView': {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    borderBottomRightRadius: '20px',
    paddingTop: '45px',
    paddingLeft: '33px',
    paddingBottom: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#A95EDB',
    position: 'relative'
  },

  '& .settingTitle': {
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#fff',
    marginBottom: '12px',
    paddingTop: '22px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative'
  },
  '& .settingTitleText': {
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#fff',
  },

  '& .teddyImageView': {
    maxWidth: '245px',
    position: 'absolute',
    bottom: '0px',
    left: '243px',
    marginRight: '10px',
    marginLeft: '0',
    padding: '0',

  },
  '&.arrowImageView': {
    width: "30%",
    height: "auto",
    position: 'absolute',
    left: '25%',
    top: '29px',
  },


  '& .contentContainerView': {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    overflowX: "hidden",
    padding: '32px 24px 0px',
    alignItems: 'center',
    paddingTop: '0',
    flex: '6'
  },


  '& .accountWrapper': {
    width: '100%',
    border: '2px solid #e6e6e6',
    borderRadius: '12px',
    marginTop: '31px',
  },


  '& .settingListTitle': {
    color: '#999999',
    fontSize: '12px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    backgroundColor: '#e6e6e6',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    textTransform: 'uppercase'
  },

  '& .benefitsListTitle': {
    color: '#fff',
    fontSize: '12px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    backgroundColor: '#e6e6e6',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
  },

  [theme.breakpoints.up('md')]: {
    '& .settingpremiumBackground': {
      flex: 5,
    },


    '& .settingImgWrapper': {
      paddingTop: 'unset',
    },
    '& .teddyImage': {
      maxWidth: 'unset',
      display: 'none'
    },
    '& .teddySettingImage': {
      maxWidth: 'unset',
      width: '240px',
      left: "calc(45% - 120px)"
    },

    '& .contentContainerView': {
      paddingTop: '0',
      overflowX: 'hidden'
    },

    '& .accountWrapper': {
      maxWidth: 450,
    },

    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },

    '& .teddyImageView': {
      width: "30%",
      maxWidth: 'unset',
      marginLeft: '0',
      padding: '0',
      left: '160px',
      position: 'absolute',
      bottom: '0px',
      marginRight: '10px',


    },
    '& .settingImgWrapperView': {
      paddingTop: 'unset',
    },

    '& .settingTitle': {
      fontSize: '30px',
      marginBottom: 'unset',
    },

    '& .arrowImageView': {
      position: 'absolute',
      left: '22%',
      top: '31px',
    },

    '& .settingBackground': {
      width: 'calc(100% + 48px)',
      height: '111px'
    },



  },

  [theme.breakpoints.up('lg')]: {

    '& .teddySettingImage': {
      maxWidth: 'unset',
      width: 'auto',
      left: 'calc(45% - 207px)'
    },
    '& .accountWrapper': {
      maxWidth: '100%',
    },
    '& .mainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },


  },

  [theme.breakpoints.down('sm')]: {
    '& .settingpremiumBackground': {
      display: 'none',
    },
    '& .settingTitle img': {
      position: 'absolute',
      bottom: '10px',
      left: 0
    },
    '& .teddyImageView': {
      maxWidth: '245px',
      padding: '0',
      position: 'absolute',
      marginRight: '10px',
      marginLeft: '0',
      bottom: '0px',
      left: '160px',
    },
    '& .arrowImageView': {
      position: 'absolute',
      left: '1%',
      top: '31px',
    },
    '& .contentContainerView': {
      flex: '6',
      display: 'flex',
      backgroundColor: '#fff',
      overflow: 'scroll',
      padding: '32px 24px 65px',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: '0',
      overflowX: 'hidden'
    },
  },
  [theme.breakpoints.down('xs')]: {
    '& .settingpremiumBackground': {
      display: 'none',
    },

    '& .arrowImageView': {
      position: 'absolute',
      top: '31px',
      left: '1%',
    },
    '& .contentContainerView': {

      flexDirection: 'column',
      backgroundColor: '#fff',
      overflow: 'scroll',
      overflowX: 'hidden',
      alignItems: 'center',
      flex: '6',
      display: 'flex',
      padding: '32px 24px 10px',
      paddingTop: '0'

    },
    '& .teddyImageView': {
      marginRight: '10px',
      position: 'absolute',
      bottom: '0px',
      left: '35px',
      marginLeft: '0',
      maxWidth: '245px',
      padding: '0',
    },
  },

}))

const webStyle = {
  premiumtextTitle: {

    color: '#FFFFFF',
    fontWeight: 900,
    lineHeight: '26px',
    letterSpacing: '0.008em',
    textAlign: 'center' as 'center',
    fontFamily: 'Nunito',
    fontSize: '22px',
    paddingLeft: '20px',
    width: '200px',
  },
  freeimg: {
    display: 'flex',
    alignItems: 'center',
    background: '#F2F2F2',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    justifyContent: 'center',
  },
  container: {
    display: "flex",
    flexDirection: "column" as "column",
    minHeight: "30vh",
  },
  freesecondText: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 700,
    color: '#363636'
  },
  freeParent: {
    width: '100%',
    height: '143px',
    top: 'auto',
    bottom: '0',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
  },
  freefirstTextView: {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Quicksand',
    color: '#363636',
  },
  freethirdText: {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    fontWeight: 700,
    color: '#00AEFF'
  },
  ExploreText: {
    lineHeight: '8.5px',
    padding: '12px 1px 12px 12px',
    textAlign: 'right' as 'right',
    color: '#00AEFF',
    fontSize: '14px',
    fontFamily: 'Quicksand',
    textDecoration: 'none',
    fontWeight: 700,
  },

  premiumparent: {
    width: '100%',
    height: 'auto',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
  },
  premiumText: {
    fontFamily: 'Quicksand',
    fontSize: '22px',
    fontWeight: 700,
    color: '#fff'
  },
  premiumMain: {
    background: '#F7F7F7',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  premiumTextFirst: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 700,
    color: '#fff'
  },
  benefitsText: {
    fontFamily: 'Quicksand',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'left' as 'left',
    color: '#363636'
  },
  textTypograpy: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 500,
    color: '#fff'
  },
  benefitsTextFirst: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 600,
    color: '#363636',
    lineHeight: '16px',
    marginTop: '5px',
  },
  footerTextMain: {
    textAlign: 'center' as 'center',
    padding: '20px',
    paddingBottom: '84px',
  },
  imgSkip: {
    maxWidth: '24px',
    marginRight: '10px',
    paddingTop: '2px',
  },
  xpMain: {
    marginTop: '10px',
    padding: '20px 30px 50px 50px',
  },
  benefitMain: {
    backgroundColor: '#fff',
    marginTop: '30px',
    height: 'auto',
    width: '240px',
    textAlign: 'right' as 'right',
    display: 'flex',
    borderRadius: '0 12px 12px 0',
    justifyContent: 'right',
    padding: '0 15px'
  },
  getbenefitFirstTitle: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left' as 'left',
    color: '#363636',
    marginTop: '10px'
  },
  benefitxpMain: {
    marginTop: '10px',
    padding: '10px 31px 40px 0px',
  },
  parentpremium: {
    paddingLeft: '20px'
  },
  getbenefitSecondTitle: {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0px',
    textAlign: 'left' as 'left',
    color: '#363636',
    marginTop: '5px'
  },
  getSecondtitle: {
    fontFamily: 'Quicksand',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0px',
    color: '#999999'
  },
  getFirstTitle: {
    fontFamily: 'Quicksand',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '15px',
    letterSpacing: '0px',
    color: '#999999',
  },
  textTtile: {
    fontFamily: 'Quicksand',
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '29px',
    letterSpacing: '0px',
    color: '#FFFFFF',
    width: '708px',
    height: '12px',
    paddingLeft: '35px',
  },
  parentSectionImage: {
    backgroundImage: `url(${view_rectangle})`,
    backgroundSize: 'auto 10px',
    backgroundRepeat: 'no-repeat',
    width: '708px',
    display: 'flex',
    backgroundPosition: 'left center',
  },
  boxparent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  textbenefitsTtile: {
    fontFamily: 'Quicksand',
    fontSize: '27px',
    fontWeight: 700,
    lineHeight: '29px',
    letterSpacing: '0px',
    textAlign: 'left' as 'left',
    color: '#999999'
  },
  btnBoxMain: {
    height: 'auto',
    borderRadius: '12px',
    background: '#36BFFF',
    width: '100%',
    margin: '15px 50px 0px 50px',
    textAlign: 'center' as 'center',
    boxSizing: 'border-box' as 'border-box',
  },
  btnTitle: {
    width: '350px',
    height: '40px',
    borderRadius: '12px',
    fontFamily: 'Nunito !important',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.800000011920929px',
    textAlign: 'center' as 'center',
    color: '#FFFFFF',
    textTransform: 'none' as 'none',
  }

};

export default StripePaymentsView;
// Customizable Area End
