import React, { Component } from "react"
// Customizable Area Start
import {
    Box,
    Drawer,
    styled
} from "@material-ui/core";
import { groupconfetti, buttonDown, trophies, free } from "./assets";
interface SS {
    stateOpen: boolean;
    drawerOpen: boolean;
    closeDrawer: () => void;
}
// Customizable Area End

export default class Trophies extends Component<SS> {
    // constructor(props: Props) {
    //     // Customizable Area Start
    //     super(props);
    //     // Customizable Area End
    // }
    // Customizable Area Start

    // Customizable Area End
    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <TrophieDrawer
                    id='trophiesId'
                    open={this.props.stateOpen}
                    anchor={this.props.drawerOpen ? 'right' : 'bottom'}
                    onClose={this.props.closeDrawer}

                >
                    <DrawerStyle>
                        {!this.props.drawerOpen && <img src={buttonDown} />}
                        <div className='trophieBox'>
                            <img src={trophies} width={41} height={41} />
                            <div className="trophieNumber">4</div>
                        </div>
                        <div className="trophieWrapper">
                            <div className="smallTrophe">
                                <img src={trophies} />
                            </div>
                            <div>
                                <div className="firstHader">Algebra Master</div>
                                <div className="secondHader"><img className="free" src={free} width={10} height={14} />230 XP earned</div>
                            </div>
                        </div>
                        <div className="trophieWrapper">
                            <div className="smallTrophe">
                                <img src={trophies} />
                            </div>
                            <div>
                                <div className="firstHader">Real Number Explorer</div>
                                <div className="secondHader"><img className="free" src={free} width={10} height={14} />120 XP earned</div>
                            </div>
                        </div>
                        <div className="trophieWrapper">
                            <div className="smallTrophe">
                                <img src={trophies} />
                            </div>
                            <div>
                                <div className="firstHader">Word Problem Wizard</div>
                                <div className="secondHader"><img className="free" src={free} width={10} height={14} />80 XP earned</div>
                            </div>
                        </div>
                        <div className="trophieWrapper">
                            <div className="smallTrophe">
                                <img src={trophies} />
                            </div>
                            <div>
                                <div className="firstHader">Fractional Share Artist</div>
                                <div className="secondHader"><img className="free" src={free} width={10} height={14} />340 XP earned</div>
                            </div>
                        </div>
                    </DrawerStyle>
                </TrophieDrawer>
            </Box>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const DrawerStyle = styled('div')(({ theme }) => ({
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundImage: `url(${groupconfetti})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'none',
    height: 'auto',
    "& .trophieWrapper": {
        width: '100%',
        borderRadius: "10px",
        padding: '15px',
        maxWidth: "329px",
        height: "80px",
        background: "var(--primary-neutrals-warm-gray-50, #F2F2F2)",
        display: 'flex',
        alignItems: 'center',
        margin: '7px auto'
    },
    '& .secondWrapper': {
        boxShadow: 'none',
        padding: '15px',
        width: '100%',
        background: "var(--primary-neutrals-warm-gray-50, #F2F2F2)",
        margin: '15px auto',
        maxWidth: "329px",
        borderRadius: "10px",

    },
    '& .trophieBox': {
        width: "134px",
        height: "64px",
        borderRadius: "10px",
        opacity: 0.9,
        background: "var(--primary-blue-blue-primary-light, #C9EEFF)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px auto'
    },
    '& .trophieNumber': {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Nunito",
        fontSize: "45px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "21px",
        margin: '0 0 0 10px'
    },
    '& .firstHader': {
        color: "var(--primary-blue-dark-gray, #363636)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "13px",
        margin: '0 0 9px 0'
    },
    '& .secondHader': {
        color: "var(--primary-neutrals-warm-gray-400, #999)",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "13px"
    },
    '& .smallTrophe': {
        margin: '0 20px 0 0'
    },
    '& .free': {
        margin: '0 6px 0 0'
    },
    [theme.breakpoints.up('md')]: {
        height: '100vh',
    }

}))
const TrophieDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paperAnchorBottom': {
        borderRadius: '20px 20px 0 0',
    },
    '& .MuiDrawer-paperAnchorBottom,.MuiDrawer-paperAnchorRight': {
        width: '100%',
        display: 'flex',
        position: 'fixed',
        maxWidth: 'auto',
        bottom: 0,
        right: 0,
        left: 'auto',
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDrawer-paperAnchorBottom,.MuiDrawer-paperAnchorRight': {
            maxWidth: '450px',
        }
    },

}));
// Customizable Area End