// Customizable Area Start
import { BlockComponent } from 'framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from 'framework/src/Messages/MessageEnum'
import { runEngine } from 'framework/src/RunEngine'
import { IBlock } from 'framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
import { orangebear, bluebear, mintbear, purplebear, redbear, brownbear, yellowbear, greenbear } from './assets'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string;
  t?:any
}

interface S {
  isLoading: boolean
  showCurrentPassword: boolean
  showNewPassword: boolean
  showConfirmPassword: boolean
  showAlert: boolean
  popupData: {
    text: string
    type: 'success' | 'error'
  }
  token: string
  showModalSuccess: boolean
  saveButtonText: string
  avatarColor:any
  successTeddy:any
  setLoader:boolean
}

interface SS {
  id: any
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendChangePasswordId: string = ''
  getCurrentProfileId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      isLoading: false,
      showCurrentPassword: true,
      showNewPassword: true,
      showConfirmPassword: true,
      showAlert: false,
      popupData: {
        text: '',
        type: 'success',
      },
      token: '',
      showModalSuccess: false,
      saveButtonText: 'Save',
      avatarColor:'',
      successTeddy:'', 
      setLoader:true
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.setState({ token: localStorage.getItem('token') || ''})
    this.getCurrentUserProfile()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.sendChangePasswordId
    ) {
      if (responseJson?.errors) {
        this.setState({
          isLoading: false,
          showAlert: true,
          popupData: {
            text:
              responseJson?.errors?.[0]?.account,
            type: 'error',
          },
        })
        return
      }
      this.setState({
        isLoading: false,
        showModalSuccess: true,
        saveButtonText: 'Saved'
      }) 
    }
    if (apiRequestCallId === this.getCurrentProfileId) {
      this.setState({
        avatarColor: responseJson?.data?.attributes?.favorite_color,
       })
       this.updateTeddy();
    }
  }

  updateTeddy = () => {
    const colorToUpdateTeddy = {
        '#ff8400': orangebear,
        '#62cdff': bluebear,
        '#b7f0ad': mintbear,
        '#a95edb': purplebear,
        '#f96855': redbear,
        '#884a39': brownbear,
        '#fdce36': yellowbear,
        '#2bce8c': greenbear
    };
  
    const updateTeddy = (colorToUpdateTeddy as any)[this.state.avatarColor] || bluebear;
        this.setState({successTeddy: updateTeddy, setLoader: false });
  }

  submitCreateNewPassword = (currentPassword: string, newPassword: string) => {
    const body = {
      current_password: currentPassword,
      new_password: newPassword
    }
    this.setState({ isLoading: true })
    this.sendChangePasswordId = sendAPIRequest(
      'account_block/accounts/change_password',
      {
        method: 'PUT',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        },
      }
    )
  }

  getCurrentUserProfile = () => {
    const token = localStorage.getItem("token") || ""
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  
}
// Customizable Area End
