import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  loading: boolean;
  teddySetting: any;
  avatarColor:any;
  isLoading: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSpecificAccountId: string = ''
  getSubscriptionDetailId: string = ''
  getCurrentProfileId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      loading: false,
      teddySetting: '',
      avatarColor:'',
      isLoading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.getSpecificAccountId) {
      const callback = () => {
        const { current_subscription_id, last_expired_subscription_id } = responseJson?.data?.attributes;
        if (!last_expired_subscription_id && !current_subscription_id) {
          return this.props.navigation.navigate("StripePaymentsView");
        }

        if (last_expired_subscription_id && !current_subscription_id) {
          return this.props.navigation.navigate("ExpiredPremium");
        }

        if (current_subscription_id) {
          this.getSubscriptionDetail(current_subscription_id)
        }
      }
      this.handleValidNavigation(responseJson?.data, 'EmailLogin', callback)
    }

    if (apiRequestCallId === this.getSubscriptionDetailId) {
      const { end_date } = responseJson?.data?.attributes;

      this.handleNavigateWithSubscriptionDetail(end_date);
    }
    if (apiRequestCallId === this.getCurrentProfileId) {
      this.setState({
        avatarColor: responseJson?.data?.attributes?.favorite_color,
       })
       this.updateTeddy();
    }

    // Customizable Area End
  }

  handleValidNavigation(responseData: any, urlNavigate: string, callback: any) {
    if (responseData)
      callback();
    else
      this.props.navigation.navigate(urlNavigate)
  }
  handleNavigateWithSubscriptionDetail(subscriptionEnd: string) {
    const today = new Date().getTime();
    const endDate = new Date(subscriptionEnd).getTime();

    const differenceDays = Math.abs(endDate - today) / (1000 * 3600 * 24);

    console.log('differenceDays', differenceDays, Math.floor(differenceDays))

    if (differenceDays <= 7) {
      localStorage.setItem("subscriptionEndsIn", String(Math.floor(differenceDays) || 1));

      this.props.navigation.navigate("SubscriptionExpering");
    } else {
      this.props.navigation.navigate("PremiumDashboard");
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;


  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };


  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  navigateToPayment = () => {
    this.setState({ loading: true })

    this.getSpecificAccountId = sendAPIRequest(
      configJSON.getSpecificAccountEndPoint,
      {
        method: configJSON.getSpecificAccountMethod,
        headers: {
          "Content-Type": configJSON.getSpecificAccountContentType,
          "token": this.state.token,
        }
      }
    )
  };

  getSubscriptionDetail = (currentSubscriptionId: string) => {
    this.getSubscriptionDetailId = sendAPIRequest(
      `${configJSON.getSubscriptionDetailEndPoint}/${currentSubscriptionId}`,
      {
        method: configJSON.getSubscriptionDetailMethod,
        headers: {
          "Content-Type": configJSON.getSubscriptionDetailContentType,
          token: this.state.token
        }
      }
    )
  }

  onNavigate(navigation: string) {
    if (navigation === "StripePaymentsView") {
      this.navigateToPayment();
    } else {
      this.props.navigation?.navigate(navigation);
    }
  }

  getSpecificAccount = (token: string) => {
    this.getSpecificAccountId = sendAPIRequest(
      configJSON.specificAccountEndPoint,
      {
        method: configJSON.specificAccountMethod,
        headers: {
          "Content-Type": configJSON.specificAccountContentType,
          token
        }
      }
    )
  }

  getCurrentUserProfile = () => {
    const token = localStorage.getItem("token") || ""
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  async componentDidMount() {
    const token = localStorage.getItem("token") || ""
    this.setState({ token })
    this.getCurrentUserProfile()
  }

  updateTeddy = () => {
    const colorToUpdateTeddy = {
        '#ff8400': teddyOrange,
        '#62cdff': teddyBlue,
        '#b7f0ad': teddyLightgreen,
        '#a95edb': teddyPurple,
        '#f96855': teddyRed,
        '#884a39': teddyBrown,
        '#fdce36': teddyYellow,
        '#2bce8c': teddyGreen
    };
  
    const updateTeddy = (colorToUpdateTeddy as any)[this.state.avatarColor] || teddyBlue;
        this.setState({teddySetting: updateTeddy, isLoading: false });
  }
  // Customizable Area End
}
