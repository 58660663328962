import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { Message } from "../../../framework/src/Message";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: (screenName: string) => void;
  };
  t?:any;
  heading: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectNotification: boolean;
  openNotificationMenu: boolean;
  openDeleteModal: boolean;
  notificationData: Array<ResDataItem>;
  selectedNotifications: Array<number>;
  allNotifications:Array<CardData>;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  openDrawer: boolean;
  topicName: string;
  unitName: string;
  apiErrorMessage: string;
  deleteModalMessage:string;
  // Customizable Area End
}

// Customizable Area Start
interface CardData {
  id: number;
  image: string;
  title: string;
  desc: string;
  timeAgo: string;
  markAsRead:boolean;
}

export interface GetAllDeletedNotificationResponse {
  data: Array<ResDataItem>;
  meta: {
      message: string;
  }
}

export interface ErrorNotificationsResponse{
  message:string;
}

export interface ResDataItem {
  id: number,
  type: string,
  attributes:{
      id: number,
      status: string,
      subject: string,
      content: string,
      content_as_plain_text: string,
      user_name: string,
      image: string,
      is_button: boolean,
      button_url: string,
      button_content: string,
      time_ago: string
  }
}

export interface ApidataResponseMock{
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string
}
// Customizable Area End

interface SS {
  id: string;
}

export default class InAppDeleteNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inAppGetAllDeletedNotificationsApiCallId : string = "";
  inAppRecoverSelectedNotificationApiCallId : string = "";
  inAppDeleteSelectedNotificationApiCallId : string = "";
  inAppRecoverAllNotificationApiCallId : string = "";
  inAppDeleteAllNotificationApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      selectNotification: false,
      openNotificationMenu: false,
      openDeleteModal: false,
      notificationData: [],
      allNotifications:[],
      selectedNotifications: [],
      trophiesOpen: false,
      goalsOpen: false,
      openDrawer: false,
      topicName: "Welcome to Tedix",
      unitName: "Let's explore the world of knowledge",
      apiErrorMessage: "",
      deleteModalMessage: ""
      // Customizable Area End

      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  // Customizable Area Start

  async componentDidMount() {

    let updatedNameUnit = localStorage.getItem('updatedName');
    if (updatedNameUnit !== null) {
    let updatedName = JSON.parse(updatedNameUnit);
    this.setState({ unitName: updatedName.unitName, topicName: updatedName.topicName });
    }
    this.inAppGetAllDeletedNotifications();
  }

  
  async receive(from: string, message: Message) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && responseJson.errors) {
        this.deletedNotificationResponseFailureCall(apiRequestCallId, responseJson);
      }
      else if (responseJson && !responseJson.errors) {
        this.deletedNotificationResponseSucessCell(apiRequestCallId, responseJson);
      }
    }
  }
  mockApiCall = async (data: ApidataResponseMock) => {
    const { contentType, method, endPoint, body, type } = data;
    let token=localStorage.getItem('token')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": contentType,
        token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  deletedNotificationResponseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetAllDeletedNotificationsApiCallId) {
      this.inAppGetAllDeletedNotificationsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppRecoverSelectedNotificationApiCallId) {
      this.inAppRecoverSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSelectedNotificationApiCallId) {
      this.inAppDeleteSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppRecoverAllNotificationApiCallId) {
      this.inAppRecoverAllNotificationSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteAllNotificationApiCallId) {
      this.inAppDeleteAllNotificationSucessCallBack(responseJson);
    }
  };

  deletedNotificationResponseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetAllDeletedNotificationsApiCallId) {
      this.inAppGetAllDeletedNotificationsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppRecoverSelectedNotificationApiCallId) {
      this.inAppRecoverFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSelectedNotificationApiCallId) {
      this.inAppDeleteFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppRecoverAllNotificationApiCallId) {
      this.inAppRecoverAllNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteAllNotificationApiCallId) {
      this.inAppDeleteAllNotificationFailureCallBack(responseJson);
    }
  };

  inAppGetAllDeletedNotifications = async () => {
    this.inAppGetAllDeletedNotificationsApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getAllDeleteNotificationEndPoint,
    });
  };

  inAppGetAllDeletedNotificationsSucessCallBack = (res: GetAllDeletedNotificationResponse) => {
    this.setState({notificationData:res.data})
  };

  inAppGetAllDeletedNotificationsFailureCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:res.message,notificationData:[]});
  };

  inAppRecoverSelectedNotification = async () => {
    if(this.state.selectedNotifications.length > 0){
    let payload ={
      notifications:this.state.selectedNotifications,
      status:configJSON.statusRead
    }
    this.inAppRecoverSelectedNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body:payload
    });
  }
  };

  inAppRecoverSucessCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({selectNotification:false,selectedNotifications:[]},()=>{
       this.inAppGetAllDeletedNotifications();
    })
  };

  inAppRecoverFailureCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:res.message},()=>{
      this.inAppGetAllDeletedNotifications();
    });
  };

  inAppDeleteSelectedNotification = async () => {
    let payload ={
      notifications:this.state.selectedNotifications,
      status:configJSON.statusPermanentlyDeleted
    }
    this.inAppDeleteSelectedNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body:payload
    });
  };

  inAppDeleteSucessCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({selectNotification:false,selectedNotifications:[],openDeleteModal:false,deleteModalMessage:""},()=>{
      this.inAppGetAllDeletedNotifications();
    })
  };

  inAppDeleteFailureCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppRecoverAllNotification = async () => {
    let allIds=this.state.notificationData.map((item:ResDataItem)=>{
      return item.id
   })
  this.setState({selectedNotifications:allIds},async()=>{
    let payload ={
      notifications:this.state.selectedNotifications,
      status:configJSON.statusRead
    }
    this.inAppRecoverAllNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body:payload
    });
  })
  };

  inAppRecoverAllNotificationSucessCallBack = async (res: ErrorNotificationsResponse) => {
    this.setState({openNotificationMenu: false,selectedNotifications:[]},()=>{
      this.openDeletedNotification()
    })
  };
  
  inAppRecoverAllNotificationFailureCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppDeleteAllNotification = async () => {
    let allIds=this.state.notificationData.map((item:ResDataItem)=>{
      return item.id
   })
  this.setState({selectedNotifications:allIds},async()=>{
    let payload ={
      notifications:this.state.selectedNotifications,
      status:configJSON.statusPermanentlyDeleted
    }
    this.inAppDeleteAllNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body:payload
    });
  })
  };

  inAppDeleteAllNotificationSucessCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({ openNotificationMenu: false,notificationData:[],openDeleteModal:false,deleteModalMessage:"" },()=>{
      this.openDeletedNotification()
    })
  };
  
  inAppDeleteAllNotificationFailureCallBack = (res: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:res.message});
  };

  handleselectNotification = () => {
    this.setState({
      selectNotification: !this.state.selectNotification,
      openNotificationMenu: !this.state.openNotificationMenu,
    });
  };

  handleOpenNotificationMenu = () => {
    this.setState({ openNotificationMenu: !this.state.openNotificationMenu });
  };

  handleOpenDeleteModal = (message:string) => {
    if(message === "selectedNotification"){
    this.setState({ openDeleteModal: this.state.selectedNotifications.length > 0 ? true : false, deleteModalMessage:message });
    }
    else{
    this.setState({ openDeleteModal: true , deleteModalMessage:message });
    }
  };

  handleDeleteModalApiCall = async () => {
    if(this.state.deleteModalMessage === "selectedNotification"){
      await this.inAppDeleteSelectedNotification()
    }else if(this.state.deleteModalMessage === "deleteAll"){
      await this.inAppDeleteAllNotification()
    }
  }

  handleCancelSelectNotification = () => {
    this.setState({ selectNotification: false });
  };
  
  handleModalCancel = () => {
    this.setState({ openDeleteModal: false });
  };
 
  handleOpenDrawer = (type: string) => {
    if (type === "trophi") {
      this.setState({ trophiesOpen: true, openDrawer: true });
    }
    else if (type === "goal") {
      this.setState({ goalsOpen: true, openDrawer: true });
    }
  };

  handleCheckboxChange = (id:number) => {
    if (this.state.selectedNotifications.includes(id)) {
      this.setState({
        selectedNotifications: this.state.selectedNotifications.filter((checkedId) => checkedId !== id),
      });
    } else {
      this.setState({
        selectedNotifications: [...this.state.selectedNotifications, id],
      });
    }
  };
  
  openDeletedNotification = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage),"InAppNotification");
    to.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(to);
  };
  // Customizable Area End
}
