import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { amexCardLogo, dinerCardLogo, discoverCardLogo, jcbCardLogo, masterCardLogo, unionCardLogo, visa_img, teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}
const subcriptionStatusActive = "active"
interface S {
    // Customizable Area Start
    isModalOpen: boolean,
    isModalPopOpen: boolean,
    token: string,
    defaultPaymentCard: string,
    planName: string,
    planPrice: number,
    subscriptionStartDate: string,
    subscriptionRenewalDate: string,
    subscriptionStatus: string,
    autoRenewal: boolean,
    lastDigitCard: string,
    brandCard: string,
    isLoading: boolean
    subscriptionId: string,
    listCard: {
        id: string
    }[],
    loader: boolean;
    teddyBear: string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class PlanDetailsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSubscriptionId = ''
    getSpecificAccountId = ''
    getCardInformationId = ''
    updateSubscriptionId = ''
    getPlanInformationId = ''
    getCardItemsId = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isLoading: false,
            isModalOpen: false,
            isModalPopOpen: false,
            token: "",
            defaultPaymentCard: "",
            subscriptionStartDate: "",
            subscriptionRenewalDate: "",
            planName: "",
            planPrice: 0,
            autoRenewal: true,
            subscriptionStatus: subcriptionStatusActive,
            lastDigitCard: "",
            brandCard: "",
            subscriptionId: "",
            listCard: [],
            loader: true,
            teddyBear:''
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.getSpecificAccountId) {
            const handleCallback = (jsonData: any) => {
                const subscriptionId = jsonData.attributes.current_subscription_id
                const defaultPaymentCard = jsonData.attributes.default_payment_method_id
                this.setState({ subscriptionId, defaultPaymentCard })
                this.getSubscription(this.state.token, subscriptionId)
            }
            this.handleNavigationCondition(responseJson, 'EmailLogin',
                () => this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
            )
        }

        if (apiRequestCallId === this.getCardItemsId) {
            const handleCallback = (jsonData: any) => {
                this.setState({ listCard: jsonData })
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
        if (apiRequestCallId === this.getSubscriptionId) {
            const handleCallback = (jsonData: any) => {
                const { auto_renewal, end_date, start_date, status, payment_method_id, plan_id } = jsonData.attributes
                if (status !== subcriptionStatusActive) {
                    this.props.navigation.navigate('Settings2')
                }
                else {
                    const idPayment = this.state.defaultPaymentCard || this.state.listCard?.[0]?.id || payment_method_id
                    this.setState({ autoRenewal: auto_renewal, subscriptionStartDate: start_date, subscriptionRenewalDate: end_date, subscriptionStatus: status })
                    this.getCardInformation(this.state.token, idPayment)
                    this.getPlanInformation(this.state.token, plan_id)
                }
            }
            this.handleNavigationCondition(responseJson, 'Settings2',
                () => this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
            )
        }
        if (apiRequestCallId === this.getCardInformationId) {
            const handleCallback = (jsonData: any) => {
                const { last4, brand } = jsonData.attributes.card
                this.setState({ lastDigitCard: last4, brandCard: brand })
                this.setState({ isLoading: false })
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
        if (apiRequestCallId === this.getPlanInformationId) {
            this.setState({ loader: false })

            const handleCallback = (jsonData: any) => {
                const { name, price_cents } = jsonData.attributes
                this.setState({
                    planName: name,
                    planPrice: price_cents,
                })
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
        if (apiRequestCallId === this.updateSubscriptionId) {
            const handleCallback = (jsonData: any) => {
                const { auto_renewal, status } = jsonData.attributes
                if (status === subcriptionStatusActive) {
                    this.setState({ autoRenewal: auto_renewal })
                    this.handleCloseModal();
                }
                else
                    this.props.navigation.navigate('PremiumCancel')
            }
            this.handleRecieveRestApi(responseJson.data, () => handleCallback(responseJson.data))
        }
    }
    async componentDidMount() {
        const token = localStorage.getItem("token") || ""
        this.setState({ token, isLoading: true })
        this.getSpecificAccount(token)
        this.getListCard(token)
        this.updateTeddyBear()
    }
    getBrandLogo() {
        const brand = this.state.brandCard.toLowerCase();
        let imgUrl;

        switch (brand) {
            case "visa":
                imgUrl = visa_img;
                break;
            case "amex":
                imgUrl = amexCardLogo;
                break;
            case "diners club":
                imgUrl = dinerCardLogo;
                break;
            case "discover":
                imgUrl = discoverCardLogo;
                break;
            case "jcb":
                imgUrl = jcbCardLogo;
                break;
            case "mastercard":
                imgUrl = masterCardLogo;
                break;
            case "unionpay":
                imgUrl = unionCardLogo;
                break;
            default:
                imgUrl = visa_img
                break;
        }

        return imgUrl;
    }
    handleNavigationCondition(response: any, urlNavigate: string, callback: any) {
        if (response.data && callback)
            callback()
        else this.props.navigation.navigate(urlNavigate)
    }
    handleRecieveRestApi(jsonData: any, callback: () => void) {
        const isDataValid = jsonData?.attributes?.account_id !== null
        if (isDataValid) {
            callback();
        } else {
            this.setState({ isLoading: false })
        }
    }
    handleAutoRenewal() {
        const httpBody = {
            auto_renewal: !this.state.autoRenewal
        };
        this.updateSubscription(this.state.token, httpBody)
    }
    convertDate(date: string, option?: string) {
        if (option) {
            const parts = date.split("-");
            const formattedDate = `${parts[2]}/${parts[1]}/${parts[0]}`;
            return formattedDate
        }
        return new Date(date).toLocaleString("en-US", {
            year: 'numeric',
            month: 'long',
            day: "numeric"
        });
    }
    updateSubscription(token: string, httpBody: any) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateSubscriptionId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateSubscriptionEndpoint}/${this.state.subscriptionId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.updateSubscriptionMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getSpecificAccount(token: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSpecificAccountId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSpecificAccountEndpoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSpecificAccountMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getSubscription(token: string, subscriptionId: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSubscriptionId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getSubscriptionEndpoint}/${subscriptionId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getSubscriptionMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getCardInformation(token: string, paymentMethodId: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCardInformationId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getCardInformationEndpoint}/${paymentMethodId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getCardInformationMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getPlanInformation(token: string, planId: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getPlanInformationId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getPlanEndpoint}/${planId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getPlanMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getListCard(token: string) {
        const header = {
            "Content-Type": configJSON.apiContentType,
            token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCardItemsId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getListCardEndpoit}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getListCardMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleSwitchModal() {
        if (this.state.autoRenewal)
            this.handleOpenModal()
        else {
            const httpBody = {
                auto_renewal: !this.state.autoRenewal
            };
            this.updateSubscription(this.state.token, httpBody)
        }
    }
    handleCancelSubscription() {
        const httpBody = {
            status: "canceled"
        }
        this.updateSubscription(this.state.token, httpBody)
    }
    updateTeddyBear = () => {
        const color = localStorage.getItem('avatarColor') || '#62cdff';
        const colorToUpdateTeddy:any = {
            '#ff8400': teddyOrange,
            '#62cdff': teddyBlue,
            '#b7f0ad': teddyLightgreen,
            '#a95edb': teddyPurple,
            '#f96855': teddyRed,
            '#884a39': teddyBrown,
            '#fdce36': teddyYellow,
            '#2bce8c': teddyGreen
        };
      
        const partyTeddy = colorToUpdateTeddy[color] || teddyBlue;
            this.setState({  teddyBear: partyTeddy });
      } 
    handleOpenModal() {
        this.setState({ isModalOpen: true })
    }
    handleCloseModal() {
        this.setState({ isModalOpen: false })
    }
    handleOpenPopModal() {
        this.setState({ isModalPopOpen: true })
    }
    handleClosePopModal() {
        this.setState({ isModalPopOpen: false })
    }
    // Customizable Area End
}
