// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'

export const configJSON = require('./config')

interface S {
  subCategory: string
  subCategoryId: string
  lessonId: string
}

interface SS {
  id: any
}

export interface Props {
  navigation: any
  id: string
  t?:any
}

export default class KeepTrackQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      subCategory: '',
      subCategoryId: '',
      lessonId: ''
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const subCategory = this.props.navigation.getParam('subCategory')
    const subCategoryId = this.props.navigation.getParam('subCategoryId')
    const lessonId = this.props.navigation.getParam('lessonId')
    this.setState({ 
      subCategory: decodeURIComponent(subCategory),
      subCategoryId,
      lessonId, 
    })
  }
}
// Customizable Area End
