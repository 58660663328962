import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
    IconButton
} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    skip_wrench,
    btn_back,
    skip_value,
    skip_medal,
    skip_onebook,
    skip_trophy,
    skip_changes,
    group_lesson,
    calendar_img,
    skip_stats,
    vectorLine,
    group_teddy1,

} from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End
import PremiumHighlightsController from "./PremiumHighlightsController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";

// Customizable Area Start

const data = [
    { number: "+800", text: "Study hours", image: skip_value, },
    { number: "+500", text: "Lessons completed", image: vectorLine, },
    { number: "+100", text: "Units completed", image: skip_onebook, },
    { number: "+300", text: "Trophies collected", image: skip_trophy, },
    { number: "+300", text: "Challenges completed", image: skip_changes, },
    { number: "+200", text: "Medals earned", image: skip_medal, },
    { number: "+50", text: "Avatar changes", image: group_lesson, },
];



// Customizable Area End
class PremiumHighlights extends PremiumHighlightsController {

    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings">
                <SettingContainer>
                    <Box className="mainContentHigWrapper">
                        <Box className="settingHigBackground">
                            <Box className="settingHigImgWrapper">
                                <img src={skip_wrench} className="teddysImageHigh" alt="Teddy Setting" />
                                <img src={group_teddy1} className="teddysImageBottom" alt="Teddy Setting" />
                                <Typography component="div" className="settingTitleHig">
                                    <IconButton data-testid='btn-back' onClick={() => this.props.navigation.goBack()}>
                                        <img src={btn_back} className="arrowImage" alt="arrow btn" width={16} height={16} style={{ marginRight: '5px' }} />
                                    </IconButton>
                                    <Typography className="settingTitleHig mobile">{this.props.t("Subscription")}</Typography>
                                    <Typography className="settingTitleHig desktop">{this.props.t("Settings")}</Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="contentContainerPremiu">
                            <Box className="contentContainerPremium__block">
                                <Box style={{ display: "flex", paddingTop: "16px" }} >
                                    <Box style={{
                                        width: '54px',
                                        height: '54px',
                                        borderRadius: '50%',
                                        background: '#F2F2F2',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img src={skip_stats} alt="Left Side Image" />
                                    </Box>
                                    <Box ml={2}>
                                        <Typography style={webStyle.firstTitle}>
                                            {this.props.t("Premium Journey Rewind")}
                                        </Typography>
                                        <Typography style={webStyle.secondTitle}>
                                            {this.props.t("Rekindle your highlights!")}
                                        </Typography>
                                        <Box display="flex" alignItems="center" marginTop="10px">
                                            <img src={calendar_img} alt="Left Side Image" style={{ marginRight: '5px' }} />
                                            <Typography style={webStyle.dateText}>
                                                {this.formatDate(this.state.subscriptionStartDate)} - {this.formatDate(this.state.subscriptionRenewalDate)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box style={{ display: 'flex', flexDirection: "column", gap: "15px", margin: "32px 0" }}>
                                    {data.map((item, index) => (
                                        <Box key={index} className="mainParent">
                                            <Box className="mainSection">
                                                <Box style={{ margin: '0 24px' }} >
                                                    <img src={item.image} alt="Your Image" width={25} height={25} />
                                                </Box>
                                                <Typography>
                                                    <span className="mainHeadingNumber">{item.number}</span>{" "}
                                                    <span className="mainHeadingText">{this.props.t(item.text)}</span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                <Box style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Button data-testid='btnUnlockPremium' className='btnUnlockPremium' onClick={() => this.props.navigation.navigate('PremiumSubscription')} style={{
                                        borderRadius: '12px',
                                        height: '40px',
                                        background: '#36BFFF',
                                        width: "300px",
                                    }}>
                                        {this.props.t("Unlock Premium now!")}
                                    </Button>
                                </Box>
                                <Box style={{ margin: '20px 0' }}>
                                    <Typography style={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '12px',
                                        fontWeight: 600,
                                        lineHeight: '20px',
                                        letterSpacing: '0px',
                                        textAlign: 'center',
                                        color: '#999999',
                                    }}>
                                        {this.props.t("By selecting 'Unlock Premium now!', you'll be opting for our paid subscription.")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <BottomMenu />
                    </Box>
                </SettingContainer>
            </NavigateLayout>
            // Customizable Area End
        );
    }
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .btnUnlockPremium .MuiButton-label": {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: 'none',
    },
    "& .contentContainerPremium__block": {
        height: "100%",
    },
    '& .mainContentHigWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    '& .mainParent': {
        backgroundColor: '#F7F7F7',
        borderRadius: "12px"
    },
    '& .mainSection': {
        height: '50px',
        borderRadius: '12px',
        border: '2px solid #F7F7F7',
        boxShadow: '0px 2px 2px 0px #0000001A',
        display: 'flex',
        alignItems: 'center' as 'center',
        color: '#F7F7F7',
    },
    '& .mainHeadingText': {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '11px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'left',
        paddingLeft: '6px',
        color: '#4D4D4D',
    },
    '& .mainHeadingNumber': {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 900,
        lineHeight: '11px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'left',
        paddingLeft: '18px',
        color: '#4D4D4D',
    },

    '& .settingHigBackground': {
        backgroundColor: '#A95EDB',
        marginBottom: "-12px"
    },

    '& .settingParent': {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
    },

    '& .settingTitleHig': {
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        width: '100%',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
        position: "relative"
    },

    "& .settingTitleHig.mobile": {
        display: 'block'
    },

    "& .settingTitleHig.desktop": {
        display: 'none'
    },

    "& .settingTitleHig button": {
        position: "absolute",
        left: 0,
        top: "50%",
        marginLeft: "-8px",
        padding: "4px"
    },

    '&.arrowImage': {
        width: "30%",
        height: "auto",
    },

    '& .contentContainerPremiu': {
        flex: '6',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '32px 24px 100px',

    },

    "& .teddysImageBottom": {
        display: 'none',
        width: 'auto'
    },

    '& .teddysImageHigh': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "20%",
        display: 'initial'
    },

    '& .settingHigImgWrapper': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },





    [theme.breakpoints.up('md')]: {
        "& .settingTitleHig button": {
            left: '33px',
            top: '25%'
        },
        "& .settingTitleHig.mobile": {
            display: 'none'
        },

        "& .settingTitleHig.desktop": {
            display: 'block',
        },

        '& .settingHigBackground': {
            flex: 5,
            // height: "100%",
            height: '100vh'
        },

        '& .settingTitleHig': {
            fontSize: '30px',
            marginBottom: 'unset',
            padding: 0
        },


        '& .settingHigImgWrapper': {
            padding: 0,
            width: '100%',
            textAlign: 'center'
        },
        '& .mainContentHigWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },

        '& .contentContainerPremiu': {
            paddingTop: '90px',
            overflowY: "auto"
        },

        '& .teddysImageHigh': {
            maxWidth: 'unset',
            width: "30%",
            display: 'none'
        },

        '& .teddysImageBottom': {
            maxWidth: '240px',
            padding: '0',
            position: 'absolute',
            bottom: '0px',
            display: 'initial'
        },

    },

    [theme.breakpoints.up('lg')]: {
        '& .mainContentHigWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

        "& .teddysImageBottom": {
            maxWidth: 'unset'
        },

    },

    [theme.breakpoints.down('sm')]: {

    },


    [theme.breakpoints.down('xs')]: {
    },


}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",
    },
    firstTitle: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
    },
    secondTitle: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '10px'
    },
    dateText: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '11px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#B3B3B3',
    },


};

export default withTranslation()(PremiumHighlights)
// Customizable Area End
