import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { sendAPIRequest, CommonApiCall } from '../../../components/src/utils'
import { algebra,orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried } from "./assets";
import { setStorageData } from "framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?:any
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  graph?: {};
  // events: { select: ({ nodes, edges }: any) => void; };
  translate: { x: number, y: number };
  algebra: boolean;
  subcategoryNode: any;
  mainNode: any;
  courseNode: ICourseNode | undefined;
  UnitNode:any;
  topic: string | undefined;
  gradeCountryId:number|null,
  unitId:any;
  selectedGrade:any
  gradeList:any;
  summaryPdf:string;
  summaryMp4:string;
  cheetsheetPdf:string;
  description: string;
  CurrentNode: ICurrentMaterialNode |undefined;
  topicName:string,
  UnitName:string,
  showGraph:boolean,
  avatarIcon:any, 
  avatarHealth: any,
  avatarColor: any,
  materialModal: HTMLElement | null;
  drawerSide: boolean;
  nodesData: any;
  categoryData: any;
  lessonData:any;
  unitNumber:any;
  unitName:any
  // countryId:number|null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


interface TreeNode {
  // Customizable Area Start
  name: string;
  children?: TreeNode[]; // Make children optional
  img?: string;
  // Customizable Area End
}


// Customizable Area Start

function flattenData(data: any) {
  const flattenedData: any = [];

  function flatten(item: any) {
    flattenedData.push({
      id: item.id,
      label: item.label,
      font:item.font,
      image: item.image,
      size: item.size,
      type: item.type,
      shape: item.shape,
    });

    if (item.child && Array.isArray(item.child)) {
      item.child.forEach(flatten);
    }

  }
  data.forEach(flatten);
  return flattenedData;
}

function wrapLabel(text: any, maxLength: any) {
  const temp = text.replace(/\n/g, ' ')
  const words = temp.split(' ');
  let currentLine = words[0];
  let lines = [];
  for (let i = 1; i < words.length; i++) {
    if (currentLine.length + words[i].length <= maxLength) {
      currentLine += ' ' + words[i];
    } else {
      lines.push(currentLine);
      currentLine = words[i];
    }
  }
  lines.push(currentLine);
  return lines.join('\n');
}


function createEdges(node: any): any {
  const edges = [];
  if (node?.child && node?.child.length > 0) {
    for (const childNode of node.child) {
      edges.push({ from: node.id, to: childNode.id });
      edges.push(...createEdges(childNode));
    }
  }
  return edges;
}

interface  ICurrentMaterialNode{
  id: string|number;
  index?: number;
  show?: boolean;
  image: string;
  label: string;
  summaryPdf: string;
  summaryMp4: string;
  cheetsheetPdf: string
}

interface INode{
  id: string;
  image: string;
  label: string;
  shape: string;
  type: string;
  size: number;
  child?: any
}

interface ICourseNode{
  id: string;
    image: string;
    label: string;
    shape: string;
    type: string;
    size: number;
}
// Customizable Area End

export default class LessonController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getSubCategories: string = "";
  getGradesByCountryId:string="";
  getProfileId:string="";
  changeunitIdAPICallId:any=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.LessonPagePropsMessage),
      getName(MessageEnum.NavigationLessonMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ]; 

    this.state = {
      drawerSide: false, // Provide a default value
      algebra: false, // Provide a default value
      graph: {
        nodes: [],
        edges: [],
      },
      topic: this.props.t("Current Grade"),
      description: "",
      categoryData: undefined,
      subcategoryNode: undefined,
      courseNode: undefined,
      UnitNode:undefined,
      mainNode: undefined,
      showGraph:false,
      lessonData:[],
      materialModal: null,
      translate: { x: 0, y: 0 }, // Provide default values
      nodesData: [],
      selectedGrade:undefined,
      gradeList:[],
      avatarIcon:'',
      avatarHealth: 'healthy', 
      avatarColor: '',
      topicName:'',
      UnitName:'',
      CurrentNode: undefined,
      summaryPdf:"",
      summaryMp4:"",
      cheetsheetPdf:'',
      gradeCountryId:null,
      unitId: null,
      unitNumber:'', 
      unitName:''
      // countryId:null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.handleWindowSizeChange();
    this.getProfile();
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>,): void {
     if(this.state.gradeCountryId !== null && prevState.gradeCountryId!=this.state.gradeCountryId){
      this.getGradeByCountry()
     }
  }


  getCourseData = (data: any) => {
    const result = data.attributes.courses.data.map((course: any,index:number) => {
      return {
        id: JSON.stringify({...course,index:index+1}),
        image: course.attributes.image || algebra,
        label: wrapLabel(course.attributes.course_name, 15),
        shape: "circularImage",
        type: course.type,
        size: 25
      }
    })
    return result
  }


  getMainData = (data: any) => {
    const result = data.attributes.main.data.map((main: any,index:number) => {
      return {
        id: JSON.stringify({...main,index:index+1}),
        image: main.attributes.image || algebra,
        label: wrapLabel(main.attributes.name, 15),
        shape: "circularImage",
        type: main.type,
        size: 25
      }
    })

    const newData = [{
      id: JSON.stringify(data),
      image: data.attributes.image || algebra,
      label: wrapLabel(data.attributes.course_name, 15),
      shape: "circularImage",
      type: data.type,
      size: 25,
      child: result
    }]

    this.setState({
      description: data.attributes.course_name.replace(/\n/g, ' '),
      topicName:data.attributes.course_name.replace(/\n/g, ' '),
    })

    return newData

  }
  getLessonData = (data: any) => {
    const result = data.attributes.lesson.data.map((lesson: any,index:number) => {
      return {
        id: JSON.stringify({...lesson,index:index+1}),
        image: lesson.attributes.image || algebra,
        label: wrapLabel(lesson.attributes.name, 15),
        shape: "circularImage",
        type: lesson.type,
        size: 25
      }
    })
   this.setState({unitId: data.id, unitName: data.attributes.name, unitNumber: data.attributes.unit_number})
   this.updateUnitId()
   localStorage.setItem('updatedName', JSON.stringify({topicName: this.state.topicName, unitName:this.state.unitName, unitNumber: this.state.unitNumber}))
   this.props.navigation.navigate('LessonList', {unitId: data.id})
    return result
  }


  getUnitData = (data: any) => {
    const result = data.attributes.unit.data.map((unit: any,index:number) => {
      return {
        id: JSON.stringify({...unit,index:index+1}),
        image: unit.attributes.image || algebra,
        label: wrapLabel(unit.attributes.name, 15),
        shape: "circularImage",
        type: unit.type,
        size: 25
      }
    })
    const newData = [{
      id: JSON.stringify(data),
      image: data.attributes.image || algebra,
      label: wrapLabel(data.attributes.name, 15),
      shape: "circularImage",
      type: data.type,
      size: 25,
      child: result
    }]
    this.setState({
      description: data.attributes.name.replace(/\n/g, ' ')
    })
    return newData
  }

  
  updateUnitId= async () => {
    let profile={
      unitid:this.state.unitId,
      unit_name: this.state.unitName, 
      topic_name: this.state.topicName
    }
    this.changeunitIdAPICallId = await CommonApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: 'bx_block_profile/profiles/update_user_profile',
      body:profile
    });
  };

  getCategories = (grade_id:number) => {
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getMsgValidations = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategories = getMsgValidations.messageId;

    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getMsgValidations.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategories}?grade_id=${grade_id}`
    );
    getMsgValidations.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getMsgValidations.id, getMsgValidations);
  }
  NodeFunction = ({ nodes, edges }: any) => {
    const nodeData = JSON.parse(nodes)
    if(nodeData.type == "maingrade") {
      this.setState({
        showGraph:false,
        selectedGrade:undefined,
        description:'',
        graph:{
          nodes:[],
          edges:[]
        }
      })
    }
    this.setState({
      CurrentNode: {
        id: nodeData.id,
        index:nodeData.index,
        show:nodeData.type!==configJSON.subCategoryType,
        image: nodeData.attributes.image||algebra,
        label: nodeData.attributes.name || nodeData.attributes.course_name,
        summaryPdf:nodeData.attributes?.summary_pdf,
        summaryMp4:nodeData.attributes?.summary_video ,
        cheetsheetPdf:nodeData.attributes?.cheatsheet_pdf,
        
      }
    })
    const generateNewNodesAndEdges = (newNodesData: any) => {
      const newNodes = newNodesData;
      const newEdges = createEdges(newNodes[0]);
      const nodesNew = flattenData(newNodes);

      this.setState((prevState: any) => ({
        graph: {
          edges: newEdges,
          nodes: nodesNew,
        },
      }));
    };

    if (nodeData.type === configJSON.subCategoryType) {
      const createNode :INode = this.getCourseData(nodeData)
      const data = this.state.categoryData.map((item: any) => {
   
        if (JSON.parse(item.id).id === nodeData.id) {
          const prev = JSON.parse(item.id)
          this.setState({
            courseNode: {
              id: JSON.stringify(prev),
              image: prev.attributes.image||algebra,
              label: wrapLabel(prev.attributes.name, 15),
              shape: "circularImage",
              type: prev.type,
              size: 25,
            },
            description: prev.attributes.name.replace(/\n/g, ' '),
            topic: this.state.subcategoryNode[0].label.replace(/\n/g, ' ')
          })
          return { ...item, child: createNode,font:{size:18} }

        } else {
          return item
        }
      })

      const subData = [{ ...this.state.subcategoryNode[0], child: data }]
      generateNewNodesAndEdges(subData)

    } else if (nodeData.type === configJSON.course) {
      const createNode:INode[] = this.getMainData(nodeData)
      this.setState({
        mainNode: createNode,
        topic: this.state.courseNode?.label.replace(/\n/g, ' ')
      })
      const subData = [{ ...this.state.courseNode, child:  [{...createNode[0],font:{size:18}}]   }]
      generateNewNodesAndEdges(subData)

    } else if (nodeData.type === configJSON.main_course) {
      const createNode:INode[] = this.getUnitData(nodeData)
      const UnitObject = [{ ...this.state.mainNode[0], child: createNode }]
       
      this.setState({
        UnitNode:UnitObject,
        topic: this.state.mainNode[0].label.replace(/\n/g, ' ')
      })
      const subData = [{ ...this.state.courseNode, child:[{...this.state.mainNode[0], child: [{...createNode[0],font:{size:18} }] }] }]
      generateNewNodesAndEdges(subData)
    } else if (nodeData.type === configJSON.unit) {
      this.getLessonData(nodeData)
     this.props.navigation.navigate('LessonList', {unitId: this.state.unitId})
    }

  }

  handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 960) {
      this.setState({ drawerSide: false });
    } else {
      this.setState({ drawerSide: true });
    }
  };

  handleSubCategory = (data: any) => {
    const result = data.map((item: any) => {
      return {
        id: JSON.stringify(item),
        label: wrapLabel(item.attributes.name, 15),
        image: item.attributes.image || algebra,
        shape: "circularImage",
        type: 'grade',
        size: 25
      }
    })
    this.setState({ categoryData: result })
    return result
  }


  getChild = (data: any) => {

    if (data?.length> 0 && data[0]?.type === configJSON.subCategoryType) {
      const result = this.handleSubCategory(data)
      return result
    }
  }

  getGradeByCountry = async() => {
      this.getGradesByCountryId = sendAPIRequest(`bx_block_categories/categories/get_grades_by_country_id?country_id=${this.state.gradeCountryId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
  }

  handleGraphData = (responseJson: any) => {
    let newNodes = [
      {
        id: JSON.stringify({id: "ddsgdg", type:'maingrade'}),
        size: 25,
        label: responseJson.grade,
        shape: "circularImage",
        child: this.getChild(responseJson.child?.data),
        image: responseJson.category_image_url || algebra,
      },
    ]
    this.setState({
      subcategoryNode: newNodes,
      CurrentNode: {
        label: responseJson.grade,
        summaryPdf:responseJson?.summary_pdf_url,
        summaryMp4:responseJson?.summary_video_url,
        cheetsheetPdf:responseJson?.cheatsheet_pdf_url,
        id: "adsjaklsdja",
        image: responseJson.category_image_url || algebra,
      }
    })
    const newEdges = createEdges(newNodes[0]);
    const nodesNew = flattenData(newNodes)

    this.setState((prevState: any) => ({
      graph: {
        nodes: nodesNew,
        edges: newEdges
      }
    }));
  }

  getProfile = () => {
    const token = localStorage.getItem('token')
    this.getProfileId = sendAPIRequest('bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const countryId = await message.getData(getName(MessageEnum.LessonPagePropsMessage));
      this.setState({
        gradeCountryId:countryId
      })
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getSubCategories) {
        
        if(!responseJson.errors){
        this.handleGraphData(responseJson)
        }else{
          console.log(responseJson)
        }
      }

      if(apiRequestCallId === this.getGradesByCountryId){
         this.setState({
          gradeList:responseJson.data
         })
         console.log(responseJson,"response")
      }
      if(apiRequestCallId === this.getProfileId) {
        this.setState({
          avatarColor: responseJson?.data?.attributes?.favorite_color,
          avatarHealth: responseJson?.data?.attributes?.avatar_health,  
          gradeCountryId:responseJson?.data?.attributes?.country_id,
          unitId:responseJson?.data?.attributes?.unitid
           })
          this.iconAvatar();
       }
    }
  }

  getColorMap = () => {
    return {
        '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
        '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
        '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
        '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar},
        '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
        '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
        '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
        '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
    };
};

iconAvatar() {
  const { avatarColor, avatarHealth } = this.state;
  
  const colorMap = this.getColorMap();

        const avatar = (avatarColor !== undefined && avatarHealth !== undefined) 
    ? ((colorMap as any)[avatarColor]?.[avatarHealth] || null) 
    : blueHealthy;

        this.setState({ avatarIcon: avatar });
}


  // Customizable Area End

}

// Customizable Area Start
// Customizable Area End