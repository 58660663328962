export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const group_edit = require("../assets/group_edit.svg");
export const skip_closedbook = require("../assets/skip_closedbook.svg");
export const skip_challengesIcon = require("../assets/skip_challengesIcon.svg");
export const grade9 =require('../assets/grade9.svg')
export const mathalg =require('../assets/button_subject.svg')
export const algebra =require('../assets/algebra1.svg')
export const physics =require('../assets/physics.svg')
export const language =require('../assets/language.svg')
export const algebramain =require('../assets/button_master.svg')
export const math =require('../assets/math.svg')
export const typeFunction =require('../assets/group_function.png')
export const Search =require('../assets/skip_search.svg')
export const Back =require('../assets/skip_arrow.svg')
export const groupscroll = require("../assets/scrollBox.svg")
export const skip_mask = require("../assets/skip_mask.svg")
export const view_rectangle = require("../assets/view_rectangle.png")
export const lesson1 = require("../assets/group_lesson1.png")
export const lesson2 = require("../assets/group_lesson2.png")
export const lesson3 = require("../assets/group_lesson3.png")
export const lesson4 = require("../assets/group_lesson4.png")
export const horizontal = require("../assets/horizontal.svg")
export const downnside = require("../assets/downSide.svg")
export const upside = require("../assets/upSide.svg")
export const background = require("../assets/background.png")
export const  maskMath = require("../assets/group_maskMath.svg")
export const skipGrade = require("../assets/skip_grade.svg");
export const  closedBook = require("../assets/skip_closedbook_icon.svg")
export const  multilevelIcon = require("../assets/skip_multiLevel.svg")
export const groupMaskMath = require("../assets/group_maskMathFull.png");
export const gradeIcon = require("../assets/grade_icon.svg")
export const listIcon = require("../assets/skip_list.png")
export const brownWorried =  require('../assets/brown_worriedavatar.svg')
export const ghostlyAvatar = require('../assets/ghostly.svg')
export const yellowHealthy =  require('../assets/yellow_healthyavatar.svg')
export const brownHealthy =  require('../assets/brown_healthyavatar.svg')
export const brownHappy = require('../assets/brown_happyavatar.svg')
export const brownWeak = require('../assets/brown_weakavatar.svg')
export const yellowHappy = require('../assets/yellow_happyavatar.svg')
export const yellowWeak = require('../assets/yellow_weakavatar.svg')
export const yellowWorried =  require('../assets/yellow_worriedavatar.svg')
export const orangeHealthy =  require('../assets/orange_healthyavatar.svg')
export const orangeHappy = require('../assets/orange_happyavatar.svg')
export const orangeWeak = require('../assets/orange_weakavatar.svg')
export const orangeWorried =  require('../assets/orange_worriedavatar.svg')
export const purpleHealthy =  require('../assets/purple_healthyavatar.svg')
export const purpleHappy = require('../assets/purple_happyavatar.svg')
export const purpleWeak = require('../assets/purple_weakavatar.svg')
export const purpleWorried =  require('../assets/purple_worriedavatar.svg')
export const redWorried =  require('../assets/red_worriedavatar.svg')
export const greenHealthy =  require('../assets/green_healthyavatar.svg')
export const lightgreenHappy = require('../assets/lightgreen_happyavatar.svg')
export const lightgreenWeak = require('../assets/lightgreen_weakavatar.svg')
export const lightgreenWorried =  require('../assets/lightgreen_worriedavatar.svg')
export const blueHealthy =  require('../assets/blue_healthyavatar.svg')
export const greenHappy = require('../assets/green_happyavatar.svg')
export const greenWeak = require('../assets/green_weakavatar.svg')
export const greenWorried =  require('../assets/green_worriedavatar.svg')
export const redHealthy =  require('../assets/red_healthyavatar.svg')
export const redHappy = require('../assets/red_happyavatar.svg')
export const redWeak = require('../assets/red_weakavatar.svg')
export const lightgreenHealthy =  require('../assets/lightgreen_healthyavatar.svg')
export const blueHappy = require('../assets/blue_happyavatar.svg')
export const blueWeak = require('../assets/blue_weakavatar.svg')
export const blueWorried =  require('../assets/blue_worriedavatar.svg')
export const close =  require('../assets/close.png')
export const Classified =  require('../assets/skip_classified.svg')
export const TeddyArm =  require('../assets/skip_arm.png')
export const TeddyLine =  require('../assets/view_line.png')
export const TeddyRectangle =  require('../assets/view_line_rectangle.png')
export const readyBlue =  require('../assets/blue_ready.svg')
export const readyBrown =  require('../assets/brown_ready.svg')
export const readyGreen =  require('../assets/green_ready.svg')
export const readyLightgreen =  require('../assets/lightgreen_ready.svg')
export const readyOrange =  require('../assets/orange_ready.svg')
export const readyPurple =  require('../assets/purple_ready.svg')
export const readyRed =  require('../assets/red_ready.svg')
export const readyYellow =  require('../assets/yellow_ready.svg')
export const loader = require('../assets/loader.svg');
export const greyImage = require('../assets/greyImage.svg');