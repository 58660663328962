import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { circle, grayCircle} from "./assets";
//@ts-ignore
import { SelectEvent } from 'react-graph-vis';
import { Message } from "framework/src/Message";

export interface IChildData{
  data:Array<INodeInfo>
}

export interface IAttributes{
  id: number,
  name: string,
  description: string,
  content: string,
  course_id: number,
  created_at: string,
  updated_at: string,
  category_id: number,
  sub_category_id: number,
  unit_id: number,
  unit_name:string,
  unit_number:string,
  main_id: number,
  image?:string;
  message?:string,
  course_name?:string,
  unit?:IChildData ,
  courses?:IChildData,
  main?:IChildData ,
  lesson?:IChildData
}

export interface INodeData{
  id:string,
  type?:string,
  label?:string,
  image:string,
  size:number,
  shape:string,
  child?: Array<INodeData> 
}

export interface ISubCategoryResponse{
  id:string,
  is_default_Grade:boolean,
  category_image_url:string,
  child:IChildData,
  grade:string
}

export interface INode{
  id:string
}
type EventHandler = (event: SelectEvent) => void;
export interface INodeInfo{
  id:string,
  type:string,
  attributes:IAttributes
}

export interface ISendDataToParent{
  main:string,
  subMain:string,
  centerShown?:boolean
  unitNumber?:number|string
  lessonId?:string
}

export interface ISendToLoading{openGradeList:boolean}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  gradeOpen: boolean;
  closeDrawer: () => void;
  sendDataToParent: (data:ISendDataToParent)=>void;
  sendDataToLanding:(data:ISendToLoading)=>void;
  searchedGraph?:{data :INodeInfo} 
  grade_id:number
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  graph?: {};
  loading:boolean;
  drawerShown:boolean,
  drawerSide: boolean;
  categoryData: Array<INodeData>;
  subcategoryNode: Array<INodeData>;
  currentGrade: string,
  subCategory: string,
  unitName: string,
  unitNumber:number|string, 
  storeSubCategory: Object
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


interface TreeNode {
  // Customizable Area Start
  name: string;
  children?: TreeNode[]; // Make children optional
  img?: string;
  // Customizable Area End
}


// Customizable Area Start

// Customizable Area End

export default class GradeController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getSubCategories: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        drawerShown: false,
      drawerSide: false,
      graph: {
        nodes: [],
        edges: [],
      },
      categoryData: [],
      subcategoryNode: [],
      currentGrade: '', 
      subCategory: '', 
      unitName: '', 
      unitNumber:'', 
      loading:false,
      storeSubCategory:{}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    if(this.props.searchedGraph===undefined){
      this.getCategoriesData(this.props.grade_id);
    }else{
      const category_id  = this.props.searchedGraph.data.attributes.category_id
      this.getCategoriesData(category_id);
    }
    this.handleWindowSizeChange();
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }


  getCategoriesData = (grade_id:number) => {
    this.setState({
      loading:true
    })
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCategories = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.subCategories}?grade_id=${grade_id}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg); 
  }

  handleDisableImage=(data:IChildData,image:string)=>{
    return data.data.length > 0 ? image : grayCircle;
   }

 getLessonData = (data: INodeInfo) => {
  const getUnitLessonData = data.attributes.lesson?.data[0]
  if(getUnitLessonData){
  this.setState({unitName: data.attributes.name, unitNumber: data.attributes.unit_number})
  const customUnitNumber = Number( data.attributes.unit_number)
    this.props.sendDataToParent({ 
      main: this.state.subCategory,
      subMain: data.attributes.name, 
      unitNumber: isNaN(customUnitNumber) ? '' : customUnitNumber,
      centerShown: true,
      lessonId: data.id
    })
  }
}

generateNewNodesAndEdges = (newNodesData: Array<INodeData>) => {
  const newNodes = newNodesData;
  const newEdges = this.createEdges(newNodes[0]);
  const nodesNew = this.flattenData(newNodes);

  this.setState({
    graph: {
      edges: newEdges,
      nodes: nodesNew,
    },
  });
};

  NodeFunction:EventHandler = ({ nodes, edges }) => {
    const nodeData = JSON.parse(nodes)
    if(nodeData.type == "maingrade") {
       this.props.sendDataToLanding({
        openGradeList: true
       })
       this.setState({
        graph: {
          edges: [],
          nodes: [],
        },
      });
    }
      this.handleSearchedGraph(nodeData)   
  }

  handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 960) {
      this.setState({ drawerShown : true,drawerSide: false });
    } else {
      this.setState({ drawerSide: true });
    }
  };

  handleSubCategory = (data: Array<INodeInfo>) => {
    const defaultData = {data:[]}
    const result = data.map((item: INodeInfo) => {
      return {
        id: JSON.stringify(item),
        image: this.handleDisableImage(item.attributes?.courses||defaultData , item.attributes.image  || circle),
        label: this.wrapLabel(item.attributes.name, 15),
        shape: "circularImage",
        type: 'grade',
        size: 25,
      }
    })

    this.setState({ categoryData: result })
    return result
  }

  getChild = (data: Array<INodeInfo>) => {
    if (data[0].type === configJSON.subCategoryType) {
      const result = this.handleSubCategory(data);
      return result;
    }
  }


  getType=(nodeType:INodeInfo)=>{
    switch(nodeType.type){
       
      case configJSON.subCategoryType:  
      return nodeType.attributes.courses

      case configJSON.course:  
      return nodeType.attributes.main

      case configJSON.main_course:
        return nodeType.attributes.unit
       
      case configJSON.unit:  
        return nodeType.attributes.lesson
    }
  }

  getMapping=(nodeArray:IChildData,)=>{
    const defaultData = {data:[]}
    const result = nodeArray.data.map((item: INodeInfo) => {
      const name = item.attributes.course_name || item.attributes.name
      const getChildLength = this.getType(item) 
      return {
        id: JSON.stringify(item),
        image: this.handleDisableImage(getChildLength||defaultData , item.attributes.image  || circle),
        label: this.wrapLabel(name, 15),
        shape: "circularImage",
        type: 'grade',
        size: 25,
        fixed:false
      }
    })

    return result.sort((item1,item2)=>JSON.parse(item1.id).id - JSON.parse(item2.id).id)
  }


 handleTypeSubCategory=(searchedGraph:INodeInfo)=>{
  const subCategory = searchedGraph.attributes.id
  const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
  const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) 
  if(subCategoryNode){
  const subCategoryNodeData= this.handleCustomdata(subCategoryNode)
   
   const subCategoryChildNodeData = {...subCategoryNodeData,child:this.getMapping(subCategoryNode.attributes.courses as IChildData)}
   const childata =this.getChild(storeSubCategory.child.data)
   const GradeNodeChild =childata?.map((item:INode)=>{
    if(JSON.parse(item.id).id==subCategory){
      return subCategoryChildNodeData
    }else{
    return  item
    }
    
  })
 

  const GradeNode = [{
    id: JSON.stringify({id: "ddsgdg", type:'maingrade'}),
    image: storeSubCategory.category_image_url || circle,
    label: storeSubCategory.grade,
    shape: "circularImage",
    size: 25,
    child: GradeNodeChild
    }] as INodeData[]
      this.props.sendDataToParent({
        main:storeSubCategory.grade,
        subMain:searchedGraph.attributes.name
   }) 
    this.generateNewNodesAndEdges(GradeNode)
  }
   
 } 

 
  handleCourseTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>courseItem.id==searchedGraph.id) as INodeInfo

    const courseNodeData= this.handleCustomdata(courseNode)
    const courseChildNode={...courseNodeData,child:this.getMapping(courseNode.attributes.main as IChildData)}

    const subCategoryNodeData = this.handleCustomdata(subCategoryNode)
    const subCategoryChildNode=[ { ...subCategoryNodeData ,child:[{...courseChildNode}]} ]
      this.props.sendDataToParent({
        main: subCategoryNode.attributes.name,
        subMain:searchedGraph.attributes.course_name??''
      })
    this.generateNewNodesAndEdges(subCategoryChildNode)
  }


  handleMainTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const course = searchedGraph.attributes.course_id  
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course)as INodeInfo
    const mainNode = courseNode.attributes.main?.data.find((mainItem:INodeInfo)=>mainItem.id==searchedGraph.id)as INodeInfo

    const mainNodeData = this.handleCustomdata(mainNode)
    const mainChildNode={...mainNodeData, child: this.getMapping(mainNode.attributes.unit as IChildData)}
  
  const courseNodeData= this.handleCustomdata(courseNode)
  const courseChildNode={...courseNodeData,child:[{...mainChildNode}]}

  const subCategoryNodeData = this.handleCustomdata(subCategoryNode)

  const subCategoryChildNode=[{ ...subCategoryNodeData ,child:[{...courseChildNode}]}]

    this.props.sendDataToParent({
      main: courseNode.attributes.course_name as string, 
      subMain:searchedGraph.attributes.name
    })
  this.generateNewNodesAndEdges(subCategoryChildNode)

  }

  handleUnitTypeGraphData=(searchedGraph:INodeInfo)=>{
    const subCategory = searchedGraph.attributes.sub_category_id
    const course = searchedGraph.attributes.course_id 
    const storeSubCategory = this.state.storeSubCategory as ISubCategoryResponse
    const subCategoryNode = storeSubCategory.child.data.find((subCate:INodeInfo)=>subCate.attributes.id==subCategory) as INodeInfo
    const courseNode = subCategoryNode.attributes.courses?.data.find((courseItem:INodeInfo)=>parseInt(courseItem.id)==course) as INodeInfo
    this.setState({subCategory: courseNode.attributes.course_name as string},()=>this.getLessonData(searchedGraph))
  }


  handleCustomdata=(nodeData:INodeInfo)=>{
    const defaultData = {data:[]}
    const name = nodeData.attributes.course_name || nodeData.attributes.name
    const getType= this.getType(nodeData)
   return { 
    id:JSON.stringify(nodeData),
    image:  this.handleDisableImage(getType||defaultData, nodeData.attributes.image || circle),
    label: this.wrapLabel(name,15),
    shape: "circularImage",
    size: 25,
    }
  }

  handleGraphData = (responseJson: ISubCategoryResponse) => {
    this.setState({
      storeSubCategory:responseJson
    })
    let newNodes = [
      {
        id: JSON.stringify({id: "ddsgdg", type:'maingrade'}),
        image: responseJson.category_image_url || circle,
        label: responseJson.grade,
        shape: "circularImage",
        size: 25,
        child: this.getChild(responseJson.child.data)
      },
    ]

    this.setState({
      subcategoryNode: newNodes
    })

    if(this.props.searchedGraph===undefined){
      this.generateNewNodesAndEdges(newNodes)
  }else{
    this.handleSearchedGraph(this.props.searchedGraph.data)
   } 
  }


  handleSearchedGraph=(searchedGraph:INodeInfo)=>{
      switch(searchedGraph.type){

        case configJSON.subCategoryType:
          return this.handleTypeSubCategory(searchedGraph)
         
        case configJSON.course:
          return this.handleCourseTypeGraphData(searchedGraph)

        case configJSON.main_course:
          return this.handleMainTypeGraphData(searchedGraph)  

        case configJSON.unit:
          return this.handleUnitTypeGraphData(searchedGraph)  
      }
  }


  getApiRequests = (apiRequestCallId: string, responseJson: ISubCategoryResponse) => {
    if (apiRequestCallId === this.getSubCategories) {
      const grade = responseJson && responseJson?.grade
      this.setState({currentGrade: grade , loading:false})
      this.handleGraphData(responseJson);
     
    } 
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if(!responseJson.errors){
      this.getApiRequests(apiRequestCallId, responseJson)
      }
    }
  }

 wrapLabel = (text: string, maxLength: number) => {
    let temp= text && text.replace(/\n/g,' ')
    let words = temp && temp.split(' ');
    if(words && words.length > 0 ) {
      let currentLine = words[0];
      let lines = [];
      for (let textItem = 1; textItem < words.length; textItem++) {
        if (currentLine.length + words[textItem].length <= maxLength) {
          currentLine += ' ' + words[textItem];
        } else {
          lines.push(currentLine);
          currentLine = words[textItem];
        }
      }
      lines.push(currentLine);
      return lines.join('\n');
    }
  
  }
  
    flattenData =(data: Array<INodeData>) =>{
    const flattenedData: { id: string; label: string | undefined; image: string; size: number; type: string; shape: string; }[] = [];
  
    const flatten = (item: INodeData) => {
      flattenedData.push({
        id: item.id,
        label: this.wrapLabel(item.label as string,15),
        image: item.image,
        size: item.size,
        type: item.type as string,
        shape: item.shape,
      });
  
      if (item.child && Array.isArray(item.child)) {
        item.child.forEach(flatten);
      }
  
    }
    data.forEach(flatten);
    return flattenedData;
  }
  
  createEdges = (node: INodeData): Array<Object> => {
    const edges = [];
    if (node?.child && node?.child.length > 0) {
      for (const childNode of node.child) {
        edges.push({ from: node.id, to: childNode.id });
        edges.push(...this.createEdges(childNode));
      }
    }
    return edges;
  }
  // Customizable Area End

}

// Customizable Area Start
// Customizable Area End