import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  LinearProgress,
  withStyles,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BottomMenu from "../../../components/src/BottomMenu";
import { brain, maskMath } from "./assets";
import Cftableforlessonsvideo from "./cftableforlessonsvideo.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

import CftableforlessonsController, {
  Props,
  configJSON,
} from "./CftableforlessonsController";
import ProgressBar from "./ProgressBar";
import NavigateLayout from "../../../components/src/NavigateLayout";
import MainContentCard from "./MainContentCard";
import { loader } from "../../stripepayments/src/assets";

class Cftableforlessons extends CftableforlessonsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <CftableforlessonsContainer>
        <NavigateLayout menuName="Home">
          <Box className="mainContentWrapper">
            <MainContentCard
              closeDrawer={this.closeDrawer}
              drawerSide={this.state.drawerSide}
              handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
              trophiesOpen={this.state.trophiesOpen}
              goalsOpen={this.state.goalsOpen}
              medianOpen={this.state.medianOpen}
              lessonName={this.state.lessonName}
              onClickBackLandingPage={this.handleClickBackLandingPage}
              navigation={this.props.navigation}
              topicName={this.state.topicName}
              openDrawer={this.state.openDrawer}
              unitName={this.state.unitName}
              lessonNumber={this.state?.VideoList?.attributes?.lesson_number}
              unitNumber={this.state.unitNumber}
            />
            <Box className="contentContainer fullScreenContent">
              {this.state.loading ? (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <img src={loader} alt="Loading" />
                </Box>
              ) : (
                <>
                  <Box className="centerPortionMain">
                    <ProgressBar
                      currentLessonNumber={this.state.lessonNumber}
                      totalLessonCount={this.state.totalLessonCount}
                      number={this.state.totalXP}
                      navigation={this.props.navigation}
                    />
                  </Box>
                  <Box className="topicNameMain">
                    <Box style={{ display: "flex" }}>
                      <div className="BrainImgMain">
                        <img className="BrainImgIcon" src={brain} alt=" " />
                      </div>
                      <Typography className="learnTitle">
                        {this.props.t("Learn")}
                      </Typography>
                    </Box>
                    <Typography className="microlessonTitle">
                      {this.state?.VideoList?.attributes?.name}
                    </Typography>
                  </Box>
                  <Cftableforlessonsvideo
                    lessonName={this.state.lessonName}
                    lessonNumber={this.state.lessonNumber}
                    parentId={this.state.parentId}
                    navigation={this.props.navigation}
                    id={""}
                    videoUrl={this.state?.VideoList}
                    lessonId={this.state?.lessonId}
                    totalLessonCount={this.state.totalLessonCount}
                    totalXP={this.state.totalXP}
                    onGetAccountInfo = {this.getSpecificUserAccount}
                  />
                </>
              )}
            </Box>
            <BottomMenu />
          </Box>
        </NavigateLayout>
      </CftableforlessonsContainer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withTranslation()(Cftableforlessons);
export const CftableforlessonsContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",

  "& .mainContentWrapper": {
    overflowX: "hidden !important",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  "& .summaryContainer": {
    display: "none",
    flexDirection: "column",
    gap: "15px",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#00AEFF",
    backgroundImage: `url(${maskMath})`,
  },
  "& .summaryCard": {
    marginTop: "170px",
  },

  "& .unitNameRectangle": {
    height: "78px",
    top: "720px",
    left: "303px",
    borderRadius: "20px",
    boxShadow: "0px 0px 8px 7px #0000000D",
    margin: "auto",
    Width: "100%",
    alignSelf: "flex-end",
  },
  "& :fullscreen .topicNameMain, :fullscreen .centerPortionMain, :fullscreen , :fullscreen .actionBtnMain":
    {
      display: "none",
    },
  "& .unitName": {
    height: "12px",
    top: "565px",
    left: "367px",
    fontFamily: "Nunito",
    fontSize: "17px",
    fontWeight: "800",
    lineHeight: "13px",
    textAlign: "center",
    backgroundColor: "#00AEFF",
    padding: "20px",
    color: "var(--primary-blue-blue-primary-light, #C9EEFF)",
    fontStyle: "normal",
    letterSpacing: "0.144px",
  },
  "& .settingImgWrapper": {
    marginTop: "170px",
  },
  "& .centerPortionMain": {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    width: "100%",
    padding: '32px 26px 0',
  },
  "& .closeIcon": {
    height: "15px",
  },
  "& .progressBar": {
    width: "100%",
    height: "100px",
  },
  "& .boltCountMain": {
    borderRadius: "10px",
    display: "flex",
    background: "#F2F2F2",
    justifyContent: "space-between",
    padding: "8px",
  },
  " & .topicNameMain": {
    marginTop: "30px",
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    padding: '0 26px',
  },
  "& .contentContainer": {
    flex: "6",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    scrollbarWidth: "none",
  },

  "& .contentContainer::-webkit-scrollbar": {
    display: "none",
  },
  "& .microlessonText": {
    padding: "20px",
  },
  " & .learnTitle": {
    color: "#00AEFF",
    marginLeft: "10px",
    fontWeight: "bold",
    textTransform:"uppercase !important"
  },
  " & .microlessonTitle": {
    color: "#CCCCCC",
    fontWeight: "bold",
    marginRight: "0px",
  },
  "& .BrainImgMain": {
    backgroundColor: "#00AEFF",
    height: "20px",
    width: "20px",
    top: "122px",
    left: "26px",
    borderRadius: "20px",
  },
  "& .BrainImgIcon": {
    width: "16px",
    height: "16px",
    top: "126px",
    left: "30px",
    marginLeft: "2px",
    marginTop: "2px",
  },
  [theme.breakpoints.up("md")]: {
    "& .mainContentWrapper": {
      width: `calc(100% - 240px)`,
      marginLeft: "240px",
      flexDirection: "row",
    },
    "& .summaryContainer": {
      flex: 5,
      display: "flex",
    },
    "& .unitNameRectangle": {
      Width: "393px",
      alignSelf: "flex-end",
    },
    "& .unitName": {
      fontWeight: "17px",
    },
    "& .centerPortionMain": {
      padding: '32px 60px 0',
    },
    " & .topicNameMain": {
      padding: '0 60px',
    },
  },

  [theme.breakpoints.up("lg")]: {
    "& .mainContentWrapper": {
      width: `calc(100% - 282px)`,
      marginLeft: "282px",
    },
    "& .accountWrapper": {
      maxWidth: 600,
    },
  },
}));

// Customizable Area End
