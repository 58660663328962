import React, { Component } from "react";
// Customizable Area Start
import { Box, Card, Typography, styled } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import ReportController, {Props} from "./ReportController.web";

// Customizable Area End
// Customizable Area Start
class Report extends ReportController {
// Customizable Area End

// Customizable Area Start
  constructor(props: Props) {
// Customizable Area End
    
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <ReportBoxStyle>
        <ReportHeader>
          <Typography className="reportDate">{this.props.dateTime}</Typography>
          <Box
            className="reportDetailLink"
            data-testid="btnViewDetail"
            onClick={() => {
              this.props.handleViewDetail({
                dataId:"1",
                startDate:this.props.startDate, 
                endDate:this.props.endDate,
                type:this.props.type,
                dateTime:this.props.dateTime 
              });
            }}
          >
            {this.props.t('Details')}
          </Box>
        </ReportHeader>
        <ReportInformation>
          <InformationBox>
            <NumberBox>
              <CircleNumber border="2px solid #62CDFF">
                <NumberTyography>
                  <Typography className="numberValue">
                    {this.props.lessonLearned}
                  </Typography>
                </NumberTyography>
              </CircleNumber>
            </NumberBox>
            <Typography>{this.props.t('Lessons')}</Typography>
          </InformationBox>
          <InformationBox>
            <NumberBox>
              <CircleNumber border="2px solid #018FF5">
                <NumberTyography>
                  <Typography className="numberValue">
                    {this.props.lessonAvgGrade}
                  </Typography>
                </NumberTyography>
              </CircleNumber>
            </NumberBox>
            <Typography>{this.props.t('Avg. Grade')}</Typography>
          </InformationBox>
          <InformationBox>
            <NumberBox>
              <CircleNumber border="2px solid #A95EDB">
                <NumberTyography>
                  <Typography className="numberValue">
                    {this.props.lessonMinute}
                  </Typography>
                </NumberTyography>
              </CircleNumber>
            </NumberBox>
            <Typography>{this.props.t('Minutes')}</Typography>
          </InformationBox>
          <InformationBox>
            <NumberBox>
              <CircleNumber border="2px solid #FDCE36">
                <NumberTyography>
                  <Typography className="numberValue">
                    {this.props.xpEarned}
                  </Typography>
                </NumberTyography>
              </CircleNumber>
            </NumberBox>
            <Typography>{this.props.t('XP Earned')}</Typography>
          </InformationBox>
        </ReportInformation>
      </ReportBoxStyle>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withTranslation()(Report)
const ReportBoxStyle = styled(Card)({
  padding: "15px",
  backgroundColor: "#C9EEFF99",
  boxShadow: "none",
  borderRadius: "16px",
  width: "289px"
  // [theme.breakpoints.up("md")]: {
  //     height: "100vh"
  // }
});

const ReportHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "& .reportDate": {
    color: "#62CDFF",
    fontStyle: "normal",
    fontFamily: "Quicksand",
    fontSize: "12px",
    lineHeight: "9px",
    fontWeight: 700
  },
  "& .reportDetailLink": {
    color: "#36BFFF",
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Quicksand",
    lineHeight: "9px",
    textDecoration: "underline",
    cursor: "pointer"
  }
});

const ReportInformation = styled(Box)({
  display: "flex",
  marginLeft: "-12px",
  alignItems: "center"
});

const InformationBox = styled(Box)({
  display: "flex",
  flex: "0 0 25%",
  maxWidth: "25%",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& .MuiTypography-body1": {
    fontWeight: 700,
    fontSize: "12px",
    fontFamily: "Quicksand",
    lineHeight: "9px",
    color: "#363636",
    textAlign:'center',
  }
});

const NumberBox = styled(Box)({
  marginTop: "15px",
  marginBottom: "12px"
});

const CircleNumber = styled(Box)({
  borderRadius: "40px",
  width: "40px",
  height: "40px",
  padding: "4px",
  borderWidth: "1px"
});
const NumberTyography = styled(Box)({
  backgroundColor: "#FFFFFF",
  borderStyle: "none",
  borderRadius: "40px",
  display: "flex",
  justifyItems: "center",
  alignItems: "center",
  height: "100%",
  "& .numberValue": {
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Quicksand",
    lineHeight: "12px",
    color: "#363636"
  }
});
// Customizable Area End
