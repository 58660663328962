// Customizable Area Start

import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import {
    lightgreenHealthy,redHealthy,greenHealthy,purpleHealthy,brownHealthy,blueHealthy,yellowHealthy,orangeHealthy,
    lightgreenHappy,redHappy,greenHappy,purpleHappy,brownHappy,blueHappy,yellowHappy,orangeHappy
     } from "../../../components/src/assets"
import {  Legends, apprenticeLeagueBadge, apprenticeLeagueListBg,  bornProtector,  bornProtectorbg, challenge, challengeresbg,  defender,  defenderbg,  groupDivisionApprentice,  groupDivisionChallenger,  groupDivisionLegends,  groupDivisionbornProtector,  groupDivisiondefender,  legendsbg,  } from './assets';
import { sendAPIRequest } from "../../../components/src/utils";

export interface IThemeObject{
    backgroundImg:string,
    themePlacement:string,
    themePlacementClass:string,
    label:string,
    groupDivisionClass:string,
    placementBorder:string,
    groupDivision:string,
    notInRange:boolean
    backImage:string,
    shieldImg:string,
}

export interface IThemeLayout{
  [key:string]: IThemeObject
}
export interface Props {
    navigation: any;
    id: string;
    t?: any;
}

export interface ITimeCounter{
    dayLeft: string;
    hourLeft: string;
    minuteLeft: string;
    secondLeft: string;
}


export interface ILeaderBoardTimer{
  message:string,
  ranking:number,
  next_league:null|string,
  main_leaderboard:boolean
}

export interface ILeaderBoardPlacementData {
  id: string,
  type: string,
  attributes:IUser
}

export interface ILeaderBoardPlacement{
  main_leaderboard:boolean
  data: ILeaderBoardPlacementData,
  league_details:{
    league_id:number,
    league_name:string,
    remaining_time:null|string,
    accounts_in_league:Array<IPlacement>
  }
}


export interface IUser{   
         account_id: number,
         ranking: number,
         username: string,
         total_points: number,
         league_type: string
}
export interface IUserDetails{
    attributes: IUser
}

export interface IPlacement{
        id?: null,
        account_id: number,
        ranking: number,
        first_name: null,
        last_name: null,
        display_name:string,
        total_xp: number,
        favorite_color: string
    }

interface S {
    timeCounter:ITimeCounter | undefined
    loading:boolean;
    showPlacement: boolean;
    scoreRank:number;
    placementList:Array<IPlacement>;
    UserInfo : IUser | undefined;
    RankPlacementList:Array<IPlacement>;
    leaderbordThemeData:IThemeObject|undefined;
}

interface SS {
}



export default class LeaderboardsController extends BlockComponent<Props, S, SS> {
    timer: any;
    getLeaderboard:string=""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]

        this.state = {
            timeCounter:  undefined,
            scoreRank:78,
            placementList:[],
            loading:false,
            UserInfo:undefined,
            RankPlacementList:[],
            leaderbordThemeData:undefined,
            showPlacement: false,
            
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
   

    themeLayout:IThemeLayout={
        "unKnown":{    
            backgroundImg:apprenticeLeagueListBg,
            themePlacementClass:"ApprenticeBg",
            shieldImg:apprenticeLeagueBadge,
            groupDivision:groupDivisionApprentice,
            backImage:"ApprenticeBackImage",
            groupDivisionClass:'group_division_apprentice',
            label:"Apprentice League",
            placementBorder:"Apprentice_border",
            notInRange:true,
            themePlacement: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #A95EDB",
            },

        "Apprentice League":{    
            backgroundImg:apprenticeLeagueListBg,
            themePlacementClass:"ApprenticeBg",
            shieldImg:apprenticeLeagueBadge,
            placementBorder:"Apprentice_border",
            groupDivision:groupDivisionApprentice,
            notInRange:false,
            backImage:"ApprenticeBackImage",
            groupDivisionClass:'group_division_apprentice',
            label:"Apprentice League",
            themePlacement: "linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #A95EDB",
            },
          "Challengers League":{
            themePlacementClass:"ChallengersBg",
            backImage:"ChallengersBackImage",
            label:"Challengers League",
            placementBorder:"Challenge_border",
            notInRange:false,
            groupDivisionClass:'group_division_challenge',
            groupDivision:groupDivisionChallenger,
            shieldImg:challenge,
            themePlacement:"linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #F96855",
            backgroundImg:challengeresbg,
          },
          "Defender League":{
            themePlacementClass:"DefenderBg",
            placementBorder:"Defender_border",
            backgroundImg:defenderbg,
            groupDivision:groupDivisiondefender,
            notInRange:false,
            groupDivisionClass:'group_division_defender',
            backImage:"DefenderBackImage",
            shieldImg:defender,
            label:"Defender League",
            themePlacement:"linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #2BCE8C",
          },
          "Born Protectors League":{
            themePlacementClass:"ProtectorsBg",
            backgroundImg:bornProtectorbg,
            groupDivision:groupDivisionbornProtector,
            shieldImg:bornProtector,
            placementBorder:"BornProtector_border",
            notInRange:false,
            groupDivisionClass:'group_division_born_protector',
            backImage:"BornProtectorsBackImage",
            label:"Born Protectors League",
            themePlacement:"linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #62CDFF"
          },
          "Legends League":{
            themePlacementClass:"LegendsBg",
            groupDivisionClass:'group_division_legends',
            placementBorder:"Legends_border",
            backgroundImg:legendsbg,
            shieldImg:Legends,
            notInRange:false,
            groupDivision:groupDivisionLegends,
            backImage:"LegendsBackImage",
            label:"Legends League",
            themePlacement:"linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FDCE36"
          }
        }

         formatDurationConditionalFromString(daysStr:string, hoursStr:string, minutesStr:string) {
          let parts = [];
          
          let days = parseInt(daysStr.trim(), 10);
          let hours = parseInt(hoursStr.trim(), 10);
          let minutes = parseInt(minutesStr.trim(), 10);
          
          if (days > 0) {
            parts.push(`${days}d`);
          }
          
          if (hours > 0) {
            parts.push(`${hours}h`);
          }
          
          parts.push(`${minutes}min`);
          
          return parts.join(' ');
        }


        updateHealthyTeddy = (color:string) => {
            const colorToUpdateTeddy:{[key:string]:string} = {
                '#ff8400': orangeHealthy,
                '#62cdff': blueHealthy,
                '#b7f0ad': lightgreenHealthy,
                '#a95edb': purpleHealthy,
                '#f96855': redHealthy,
                '#884a39': brownHealthy,
                '#fdce36': yellowHealthy,
                '#2bce8c': greenHealthy
            };
            const updateTeddy = (colorToUpdateTeddy as {[key:string]:string})[color] || blueHealthy;
               return updateTeddy
          }

          updateHappyTeddy = (color:string) => {
            const colorToUpdateTeddy :{[key:string]:string}= {
                '#ff8400': orangeHappy,
                '#62cdff': blueHappy,
                '#b7f0ad': lightgreenHappy,
                '#a95edb': purpleHappy,
                '#f96855': redHappy,
                '#884a39': brownHappy,
                '#fdce36': yellowHappy,
                '#2bce8c': greenHappy
            };
            const updateTeddy = (colorToUpdateTeddy as {[key:string]:string})[color] || blueHappy;
               return updateTeddy
          }

    calculateTimeLeft = (etDate: Date)=> {
        const current = new Date();

        let difference = +etDate - +current;

        if (difference < 0) {
          this.getLeaderBoardScoreRank()
            return this.setState({
                timeCounter:{ dayLeft: '00',
                hourLeft: '00',
                minuteLeft: '00',
                secondLeft: '00'}
            })
        }
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        difference -= days * (1000 * 60 * 60 * 24);

        const hours = Math.floor(difference / (1000 * 60 * 60));
        difference -= hours * (1000 * 60 * 60);

        const minutes = Math.floor(difference / (1000 * 60));
        difference -= minutes * (1000 * 60);

        const seconds = Math.floor(difference / 1000);

        this.setState({
            timeCounter: {
             dayLeft: days < 10 ? '0' + String(days) : String(days),
            hourLeft: hours < 10 ? '0' + String(hours) : String(hours),
            minuteLeft: minutes < 10 ? '0' + String(minutes) : String(minutes),
            secondLeft: seconds < 10 ? '0' + String(seconds) : String(seconds)
          }
        })
    }

    getEndDate=()=>{
        const current = new Date();
         const dayOfWeek = current.getDay();
        const daysUntilSunday = (7 - dayOfWeek) % 7;
        const nextSundayMidnight = new Date(current);
        nextSundayMidnight.setDate(current.getDate() + daysUntilSunday);
        nextSundayMidnight.setHours(24, 0, 0, 0); 
        
         return nextSundayMidnight
        }

    intervalCallback = () => {
        let etDate: Date =  this.getEndDate();
        this.calculateTimeLeft(etDate)
    }

    async componentDidMount() {

        this.getLeaderBoardScoreRank()
    }

    getLeaderBoardScoreRank=()=>{
        this.setState({
            loading:true
        })
        const token = localStorage.getItem('token')

        this.getLeaderboard = sendAPIRequest('bx_block_educational_gamification/rankings',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token,
            },
          }
        )
    }

    async componentWillUnmount() {
        clearInterval(this.timer);
    }



    handleLeaderboardContainer=(responseJson:ILeaderBoardTimer)=>{
        this.timer = setInterval(()=>this.intervalCallback(), 1000);
         this.setState({
           scoreRank:responseJson.ranking,
           showPlacement: false,
         })
         this.rateNumber(responseJson.ranking)
    }

    handleNavigation=()=>{
      this.props.navigation.navigate("LandingPageWeb")
    }


    getLeagueType=(league_type:string)=>{
      const ranges = [
        { league_type:"apprentice_league",label: "Apprentice League" },
        { league_type:"challengers_league",label: "Challengers League" },
        { league_type:"defenders_league", label: "Defender League" },
        { league_type:"born_protectors_league", label: "Born Protectors League" },
        { league_type:"legends_league", label: "Legends League" },
    ];

    const result = ranges.find(range => range.league_type===league_type);
     const value =result ? result.label : "unKnown";
     this.setState({
         leaderbordThemeData: this.themeLayout[value]
     })
    }

   
    handleLeaderboardPlacements=(responseJson:ILeaderBoardPlacement)=>{
        const accountsInLeague =[...responseJson.league_details.accounts_in_league]
         accountsInLeague.sort((a: IPlacement, b: IPlacement) => a.ranking - b.ranking);
        const LeagueDataArray  = accountsInLeague
        this.intervalCallback()
       this.setState({
        placementList:LeagueDataArray.slice(3),
        RankPlacementList:LeagueDataArray.slice(0,3),
        showPlacement:true,
        UserInfo:responseJson.data.attributes,
       })
       this.getLeagueType(responseJson.data.attributes.league_type)
    }


    handleLeaderboard=(responseJson:ILeaderBoardPlacement & ILeaderBoardTimer)=>{
      if(responseJson.main_leaderboard){
          this.handleLeaderboardContainer(responseJson)
      }else{
          this.handleLeaderboardPlacements(responseJson)
      }
      this.setState({
        loading:false
      }) 
    }


     rateNumber=(num:number)=> {
        const ranges = [
            { min: 121, max: 150, label: "Apprentice League" },
            { min: 91, max: 120, label: "Challengers League" },
            { min: 61, max: 90, label: "Defender League" },
            { min: 31, max: 60, label: "Born Protectors League" },
            { min: 1, max: 30, label: "Legends League" },
        ];
    
        const result = ranges.find(range => num >= range.min && num <= range.max);
         const value =result ? result.label : "unKnown";
         this.setState({
             leaderbordThemeData: this.themeLayout[value]
         })
    }
    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getLeaderboard) {
            if(!responseJson.errors){
                  this.handleLeaderboard(responseJson)
            }else{
              console.log(responseJson)
            }
          }
        }
    }

}

// Customizable Area End