import { Box, Typography, styled, useTheme, useMediaQuery } from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import {
  blueFlag,
  greenFlag,
  greenShare,
  groupConfetti,
  redFlag,
  redShare,
} from '../../../blocks/cftableforlessons/src/assets'
import {
  Option,
  QuestionBodyItem,
  QuestionProps,
  StyledButton,
  reportOptions,
} from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import GroupActionsExercise from './GroupActionsExercise'
import {
  CorrectAnswerButton,
  InCorrectAnswerButton,
} from './SingleChoiceExercise'
import ActionAlert from './ActionAlert'
import ReportModal from '../../../blocks/cftableforlessons/src/ReportModal'
import WellDoneShare from '../../../blocks/cftableforlessons/src/WellDoneShare'
import ThankReportModal from '../../../blocks/cftableforlessons/src/ThankReportModal'
import _ from 'lodash'
import { convertLatex } from '../utils'
import { HintText, MathFormula, QuestionText } from './ExerciseComponents'
import { useTranslation } from 'react-i18next'
import SolutionPage from './SolutionPage'
import ShareModal from './ShareModal.web'
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../assets'

interface CustomQuestionBodyItem extends QuestionBodyItem {
  id: number
  selectedAnswerId: number | null
}

const SequenceExercise: React.FC<QuestionProps> = ({
  onClickContinue,
  assessmentId,
  attributes,
  onSubmitExerciseAnswer,
  exerciseAnswer,
  isLastQuestion,
  onSelectReportOption,
  onSubmitReport,
  isGuest,
  onUpdateXP,
  lessonId,
  microLessonId,
  lessonAttemptId,
  shareRecordType,
}) => {
  const { hint, options, question, questionBody, solution, questionType, id } = attributes
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [showInCorrectAnswer, setShowInCorrectAnswer] = useState(false)
  const [showHint, setShowHint] = useState(false)
  const [showSolutionAlert, setShowSolutionAlert] = useState(false)
  const [showSkipAlert, setShowSkipAlert] = useState(false)
  const [showHintAlert, setShowHintAlert] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [showWellDoneShareModal, setShowWellDoneShareModal] = useState(false)
  const [showThankReportModal, setShowThankReportModal] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)

  const { t } = useTranslation()
  const boxRef = useRef<HTMLDivElement>(null)
  const selectedItem = React.useRef<any>(null)
  const [width, setWidth] = useState(0)
  const [solutionFromValue, setSolutionFromValue] = useState<
    'SOLUTION' | 'INCORRECT' | null
  >(null)
  const [startTime, setStartTime] = useState(0)

  const [blanks, setBlanks] = useState<Array<CustomQuestionBodyItem>>([])
  const [answerOptions, setAnswerOptions] = useState<Array<Option>>([])
  const [loadUI, setLoadUI] = useState(false)

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    setTimeout(() => {
      setLoadUI(true)
    }, 300);
    const updateWidth = () => {
      if (boxRef.current) {
        setWidth(boxRef.current.offsetWidth)
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': orangePartyTeddy,
    '#62cdff': bluePartyTeddy,
    '#b7f0ad': mintPartyTeddy,
    '#a95edb': purplePartyTeddy,
    '#f96855': redPartyTeddy,
    '#884a39': brownPartyTeddy,
    '#fdce36': yellowPartyTeddy,
    '#2bce8c': greenPartyTeddy
  };

  const partyTeddy = colorToUpdateTeddy[avatarColor] || bluePartyTeddy;

  const customQuestionBody = useMemo(() => {
    const questionBodyTransfrom = typeof questionBody === 'string' ? [] : _.cloneDeep(questionBody)

    const customData: CustomQuestionBodyItem[] =
      questionBodyTransfrom.map((item, index) => {
        const customText = item.isBlank ? '' : item.text
        return {
          ...item,
          text: customText,
          id: index,
          selectedAnswerId: null,
        }
      })

    return customData
  }, [questionBody])

  useEffect(() => {
    const questionBodyTransfrom =
      typeof questionBody === 'string' ? [] : _.cloneDeep(questionBody)

    const customQuestionData: CustomQuestionBodyItem[] =
      questionBodyTransfrom.map((item, index) => {
        const customText = item.isBlank ? '' : item.text
        return {
          ...item,
          text: customText,
          id: index,
          selectedAnswerId: null,
        }
      })

    if (exerciseAnswer) {
      const userAnswerString = exerciseAnswer?.attributes?.userAnswer
      const userAnswerArray = userAnswerString?.split('(split-space)')
      const userAnswer = userAnswerArray?.map((item) => {
        return JSON.parse(item)
      })
      let blankIndex = 0
      const answerBlanks: CustomQuestionBodyItem[] = _.cloneDeep(customQuestionData).map((item) => {
        if (item.isBlank) {
          const answerItem = userAnswer?.[blankIndex]
          const answerOption = _.cloneDeep(options).find(
            (item) => item.attributes.id === answerItem?.id
          )
          blankIndex += 1
          return {
            ...item,
            text: answerOption?.attributes?.option || '',
            selectedAnswerId: answerOption?.attributes.id || 0,
          }
        } else {
          return { ...item }
        }
      })
      setBlanks(answerBlanks)

      const answerOptions = _.cloneDeep(options).map(item => {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            option: '',
            id: null
          }
        }
      })
      setAnswerOptions(answerOptions)
      const isInCorrect = answerBlanks.some(
        (item) => item.correctAnswerId !== item.selectedAnswerId
      )
      if (isInCorrect) {
        setShowInCorrectAnswer(true)
      } else {
        setShowCorrectAnswer(true)
      }
    } else {
      const coppyOptions = _.cloneDeep(options)

      setStartTime(Date.now())
      setBlanks(customQuestionData)
      setAnswerOptions(coppyOptions)
    }
  }, [questionBody, options, exerciseAnswer])

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    index: number,
    isBlank: boolean
  ) => {
    event?.preventDefault()
    const updatedBlanks = _.cloneDeep(blanks)
    const updatedOptions = _.cloneDeep(answerOptions)
    let selectedIndex

    if (isBlank) {
      if (selectedItem.current?.isBlank) {
        selectedIndex = updatedBlanks.findIndex(
          (item) => item.id === selectedItem.current.id
        )
        const tempItem = { ...updatedBlanks[selectedIndex] }
        updatedBlanks[selectedIndex].text = updatedBlanks[index].text
        updatedBlanks[selectedIndex].selectedAnswerId = updatedBlanks[index].selectedAnswerId
        updatedBlanks[index].text = tempItem.text
        updatedBlanks[index].selectedAnswerId = tempItem.selectedAnswerId
      } else {
        selectedIndex = updatedOptions.findIndex(
          (item) => item.id === selectedItem.current?.id
        )
        const dragData = {
          text: updatedOptions[selectedIndex].attributes.option,
          id: updatedOptions[selectedIndex].attributes.id,
        }
        updatedOptions[selectedIndex].attributes.id =
          updatedBlanks[index].selectedAnswerId
        updatedOptions[selectedIndex].attributes.option =
          updatedBlanks[index].text

        updatedBlanks[index].text = dragData.text
        updatedBlanks[index].selectedAnswerId = dragData.id
      }
    } else {
      if (selectedItem.current?.isBlank) {
        selectedIndex = updatedBlanks.findIndex(
          (item) => item.id === selectedItem.current.id
        )
        const dragData = {
          text: updatedBlanks[selectedIndex].text,
          id: updatedBlanks[selectedIndex].selectedAnswerId,
        }
        updatedBlanks[selectedIndex].text =
          updatedOptions[index].attributes.option
        updatedBlanks[selectedIndex].selectedAnswerId =
          updatedOptions[index].attributes.id

        updatedOptions[index].attributes.id = dragData.id
        updatedOptions[index].attributes.option = dragData.text
      } else {
        selectedIndex = updatedOptions.findIndex(
          (item) => item.id === selectedItem.current.id
        )
        const tempOptionItem = { ...updatedOptions[selectedIndex] }
        updatedOptions[selectedIndex] = { ...updatedOptions[index] }
        updatedOptions[index] = tempOptionItem
      }
    }
    setAnswerOptions(updatedOptions)
    setBlanks(updatedBlanks)
  }

  const handleDragOver = (event: React.DragEvent) => {
    event?.preventDefault()
  }

  const handleShowSolution = () => {
    const questionBodySolution = _.cloneDeep(customQuestionBody).map((item) => {
      const matchOption = _.cloneDeep(options).find(
        (option) => option.attributes.id === item.correctAnswerId
      )
      const textBlank = item.isBlank
        ? matchOption?.attributes.option || ''
        : item.text
      return {
        ...item,
        text: textBlank,
      }
    })

    const optionsSolution = _.cloneDeep(options).map((option) => {
      return {
        ...option,
        attributes: {
          ...option.attributes,
          option: '',
        },
      }
    })

    setAnswerOptions(optionsSolution)
    setBlanks(questionBodySolution)
  }

  const handleTouchMove = (
    e: React.TouchEvent<HTMLDivElement>,
    isBlank: boolean
  ) => {
    const touchLocation = e.targetTouches[0]
    if (selectedItem.current) {
      // Update the style to visually move the dragged div
      const element = document.getElementById(
        `${isBlank ? 'blank' : 'option'}-${selectedItem.current.id}`
      )
      if (element) {
        element.style.position = 'fixed'
        element.style.left = `${touchLocation.clientX - 26}px`
        element.style.top = `${touchLocation.clientY - 22}px`
      }
      e.preventDefault() // Prevent scrolling during the drag
    }
  }

  const getDropZoneFromTarget = (
    target: HTMLElement | null
  ): HTMLElement | null => {
    while (
      target &&
      !(target.id.startsWith('blank-') || target.id.startsWith('option-'))
    ) {
      target = target.parentElement as HTMLElement
    }
    return target
  }

  const getItemId = (idStr: string, isDropBlank: boolean) => {
    const match = isDropBlank
      ? idStr.match(/blank-(.*)/)
      : idStr.match(/option-(.*)/)
    return match ? match[1] : null
  }

  const handleTouchEnd = (
    e: React.TouchEvent<HTMLDivElement>,
    isDragBlank: boolean
  ) => {
    document.querySelector('html')?.classList?.remove('disableScroll')

    if (selectedItem.current) {
      const touchLocation = e.changedTouches[0]
      // Temporarily remove the dragged item from the flow to check the element below
      const element = document.getElementById(
        `${isDragBlank ? 'blank' : 'option'}-${selectedItem.current.id}`
      )
      if (element) {
        element.style.display = 'none'
      }

      let targetElement: HTMLElement | null = document.elementFromPoint(
        touchLocation.clientX,
        touchLocation.clientY
      ) as HTMLElement
      targetElement = getDropZoneFromTarget(targetElement)
      const isDropBlank = targetElement?.id.startsWith('blank-')
      const dropItemId = getItemId(targetElement?.id || '', !!isDropBlank)
      const updatedBlanks = _.cloneDeep(blanks).filter(
        (item) => item.id !== -1000
      )
      const updatedOptions = _.cloneDeep(answerOptions).filter(
        (item) => item.id !== '-1000'
      )

      if (targetElement && dropItemId !== '-1000') {
        let dropItemIndex
        let selectedIndex

        if (isDropBlank) {
          dropItemIndex = updatedBlanks.findIndex(
            (item) => item.id.toString() === dropItemId
          )
          if (selectedItem.current?.isBlank) {
            selectedIndex = updatedBlanks.findIndex(
              (item) => item.id === selectedItem.current.id
            )
            const tempItem = { ...updatedBlanks[selectedIndex] }
            updatedBlanks[selectedIndex].text = updatedBlanks[dropItemIndex].text
            updatedBlanks[selectedIndex].selectedAnswerId = updatedBlanks[dropItemIndex].selectedAnswerId
            updatedBlanks[dropItemIndex].text = tempItem.text
            updatedBlanks[dropItemIndex].selectedAnswerId = tempItem.selectedAnswerId
          } else {
            selectedIndex = updatedOptions.findIndex(
              (item) => item.id === selectedItem.current?.id
            )
            const dragData = {
              text: updatedOptions[selectedIndex].attributes.option,
              id: updatedOptions[selectedIndex].attributes.id,
            }
            updatedOptions[selectedIndex].attributes.id =
              updatedBlanks[dropItemIndex].selectedAnswerId
            updatedOptions[selectedIndex].attributes.option =
              updatedBlanks[dropItemIndex].text

            updatedBlanks[dropItemIndex].text = dragData.text
            updatedBlanks[dropItemIndex].selectedAnswerId = dragData.id
          }
        } else {
          dropItemIndex = updatedOptions.findIndex(
            (item) => item.id === dropItemId
          )
          if (selectedItem.current?.isBlank) {
            selectedIndex = updatedBlanks.findIndex(
              (item) => item.id === selectedItem.current.id
            )
            const dragData = {
              text: updatedBlanks[selectedIndex].text,
              id: updatedBlanks[selectedIndex].selectedAnswerId,
            }
            updatedBlanks[selectedIndex].text =
              updatedOptions[dropItemIndex].attributes.option
            updatedBlanks[selectedIndex].selectedAnswerId =
              updatedOptions[dropItemIndex].attributes.id

            updatedOptions[dropItemIndex].attributes.id = dragData.id
            updatedOptions[dropItemIndex].attributes.option = dragData.text
          } else {
            selectedIndex = updatedOptions.findIndex(
              (item) => item.id === selectedItem.current.id
            )
            const tempOptionItem = { ...updatedOptions[selectedIndex] }
            updatedOptions[selectedIndex] = { ...updatedOptions[dropItemIndex] }
            updatedOptions[dropItemIndex] = tempOptionItem
          }
        }
      }
      setAnswerOptions(updatedOptions)
      setBlanks(updatedBlanks)

      // Restore the style of the dragged div
      if (element) {
        element.style.removeProperty('position')
        element.style.removeProperty('top')
        element.style.removeProperty('left')
        element.style.display = 'flex'
      }

      // setDraggedItem(null); // Clear the dragged item state
      e.preventDefault() // Prevent default action on touch end
    }
  }

  return (
    <SingleChoiceExerciseContainer {...{ ref: boxRef }}>
      {!loadUI ? (
        <p></p>
      ) : solutionFromValue ? (
        <SolutionPage
          solutionContent={solution}
          onContinue={() => {
            const param =
              solutionFromValue === 'SOLUTION'
                ? { isUseSolution: true }
                : { isAnswerWrong: true }
            onClickContinue(param)
          }}
          width={isMediumScreen ? width - 120 : width - 52}
          onClickReport={() => setShowReportModal(true)}
          isGuest={isGuest}
        />
      ) : (
        <Box className="mainContainer">
          <Box className="questionWrapper">
            <QuestionText question={question} width={isMediumScreen ? width - 120 : width - 52} />
            <Box className="questionBodyContainer">
              <div
                style={{
                  display: 'flex',
                  marginBottom: '96px',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  rowGap: '24px',
                }}
              >
                {blanks.map((blank, index) => {
                  return blank.isBlank ? (
                    <div
                      key={blank.id}
                      id={`blank-${blank.id}`}
                      className={
                        blank.text.length > 0 ? 'filledBlank' : 'emptyBlank'
                      }
                      draggable={blank.text.length > 0 && !exerciseAnswer}
                      onDragStart={() => (selectedItem.current = blank)}
                      onDrop={(e) => handleDrop(e, index, blank.isBlank)}
                      onDragOver={handleDragOver}
                      onTouchStart={(e) => {
                        if (blank.text.length > 0) {
                          document.querySelector('html')?.classList?.add('disableScroll')

                          const cloneBlanks = _.cloneDeep(blanks)
                          cloneBlanks.splice(index, 0, {
                            text: '',
                            isBlank: true,
                            correctAnswerId: null,
                            id: -1000,
                            selectedAnswerId: null,
                          })
                          setBlanks(cloneBlanks)
                          selectedItem.current = blank
                        }
                      }}
                      onTouchMove={(e) => {
                        if (blank.text.length > 0) {
                          handleTouchMove(e, true)
                        }
                      }}
                      onTouchEnd={(e) => {
                        if (blank.text.length > 0) {
                          handleTouchEnd(e, true)
                        }
                      }}
                    >
                      <MathFormula
                        formula={convertLatex(blank.text)}
                        color='#62CDFF'
                        fontFamily='Quicksand'
                        fontSize={16}
                        lineHeight='8px'
                        fontWeight={700}
                      />
                    </div>
                  ) : (
                    <div>
                      <MathFormula
                        formula={convertLatex(blank.text)}
                        color='#363636'
                        fontFamily='Quicksand'
                        fontSize={20}
                        lineHeight='16px'
                        fontWeight={700}
                      />
                    </div>
                  )
                })}
              </div>
              <div style={{ display: 'flex', gap: '12px', flexDirection: 'row', flexWrap: 'wrap' }}>
                {answerOptions.map((option, index) => (
                  <div
                    key={option.id}
                    id={`option-${option.id}`}
                    className={
                      option.attributes.option.length > 0
                        ? 'filledOption'
                        : 'emptyOption'
                    }
                    draggable={
                      option.attributes.option.length > 0 && !exerciseAnswer
                    }
                    onDragStart={() => (selectedItem.current = option)}
                    onDrop={(e) => handleDrop(e, index, false)}
                    onDragOver={handleDragOver}
                    onTouchStart={(e) => {
                      if (option.attributes.option.length > 0) {
                        document.querySelector('html')?.classList?.add('disableScroll')

                        const cloneAnswerOptions = _.cloneDeep(answerOptions)
                        cloneAnswerOptions.splice(index, 0, {
                          id: '-1000',
                          type: 'string',
                          attributes: {
                            id: 1000,
                            option: '',
                            isCorrect: false,
                            sequenceNumber: 1000,
                            description: '',
                          },
                        })
                        setAnswerOptions(cloneAnswerOptions)
                        selectedItem.current = option
                      }
                    }}
                    onTouchMove={(e) => {
                      if (option.attributes.option.length > 0) {
                        handleTouchMove(e, false)
                      }
                    }}
                    onTouchEnd={(e) => {
                      if (option.attributes.option.length > 0) {
                        handleTouchEnd(e, false)
                      }
                    }}
                  >
                    <MathFormula
                      formula={convertLatex(option.attributes.option)}
                      color='#7F7F7F'
                      fontFamily='Quicksand'
                      fontSize={16}
                      lineHeight='8px'
                      fontWeight={700}
                    />
                  </div>
                ))}
              </div>
            </Box>
          </Box>
          {!showCorrectAnswer &&
            !showInCorrectAnswer &&
            !showHint && (
              <GroupActionsExercise
                isDisableButton={blanks.some(
                  (item) => item.isBlank && item.text === ''
                )}
                onCheckAnswer={() => {
                  const isInCorrect = blanks.some(
                    (item) => item.correctAnswerId !== item.selectedAnswerId
                  )
                  if (isInCorrect) {
                    setShowInCorrectAnswer(true)
                  } else {
                    setShowCorrectAnswer(true)
                  }

                  const endTime = Date.now();
                  const elapsedTimeInSeconds = (endTime - startTime) / 1000;

                  const questionBodyText = customQuestionBody.map(item => item.text)
                  const questionBodyStr = questionBodyText.join('(split-space)')

                  const questionBodyTransfrom = typeof questionBody === 'string' ? [] : _.cloneDeep(questionBody)
                  const answerOptionString = _.cloneDeep(questionBodyTransfrom)
                    .filter((item) => item.isBlank)
                    .map((item) => {
                      const correctText = _.cloneDeep(options).find(
                        (option) => option.attributes.id === item.correctAnswerId
                      )?.attributes.option
                      return correctText
                    })
                  const correctAnswerStr = answerOptionString.join('(split-space)')

                  const questionBlanks = blanks.filter(item => item.isBlank)
                  const questionBlanksText = questionBlanks.map(item => {
                    return JSON.stringify({
                      text: item.text,
                      id: item.selectedAnswerId
                    })
                  })
                  const questionBlanksStr = questionBlanksText.join('(split-space)')

                  const submitData = {
                    assessmentId: assessmentId,
                    questionBankId: attributes?.id,
                    questionType: questionType,
                    questionBody: questionBodyStr,
                    correctAnswer: correctAnswerStr,
                    userAnswer: questionBlanksStr,
                    correct: !isInCorrect,
                    grade: isInCorrect ? 0 : 10,
                    timeCompleteInSeconds: Math.floor(elapsedTimeInSeconds),
                    lessonCompleted: isLastQuestion,
                  }
                  onSubmitExerciseAnswer && onSubmitExerciseAnswer(submitData)
                }}
                onShowSolutionAlert={() => {
                  const isSkipShowSolutionAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSolutionAlertMicroLesson')
                  )
                  if (isSkipShowSolutionAlertMicroLesson) {
                    handleShowSolution()
                    setSolutionFromValue('SOLUTION')
                  } else {
                    setShowSolutionAlert(true)
                  }
                }}
                onShowSkipAlert={() => {
                  const isSkipShowSkipAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSkipAlertMicroLesson')
                  )
                  if (isSkipShowSkipAlertMicroLesson) {
                    onClickContinue({ isSkip: true })
                  } else {
                    setShowSkipAlert(true)
                  }
                }}
                onShowHintAlert={() => {
                  const isSkipShowHintAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowHintAlertMicroLesson')
                  )
                  if (isSkipShowHintAlertMicroLesson) {
                    setShowHint(true)
                  } else {
                    setShowHintAlert(true)
                  }
                }}
              />
            )}
        </Box>
      )}
      {showHint && (
        <Box className="solutionContainer">
          <Box className="solutionContentContainer">
            <HintText hint={hint} width={isMediumScreen ? width - 120 : width - 52} />
            {isGuest ? (<Box />) : (
              <img
                src={blueFlag}
                width={15}
                onClick={() => setShowReportModal(true)}
                height={15}
              />
            )}
          </Box>
          <StyledButton
            onClick={() => {
              setShowHint(false)
            }}
          >
            {t('Continue')}
          </StyledButton>
        </Box>
      )}
      {showInCorrectAnswer && (
        <Box className="incorrectContainer">
          <Box className="incorrectContentContainer">
            <Box>
              <Typography className="incorrectTitle">
                {t(" Oh oh! Correct answer:")}
              </Typography>
              <Typography className="incorrectAnswerDesc">
                {t("Continue to see the solution...")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  width={15}
                  src={redFlag}
                  onClick={() => setShowReportModal(true)}
                  height={15}
                />
                <img
                  width={15}
                  src={redShare}
                  onClick={() => setShareModalOpen(true)}
                  height={15}
                />
              </Box>
            )}
          </Box>
          <InCorrectAnswerButton
            onClick={() => {
              setShowInCorrectAnswer(false)
              if (exerciseAnswer) {
                setSolutionFromValue('SOLUTION')
              } else {
                setSolutionFromValue('INCORRECT')
              }
            }}
          >
            {t('Continue')}
          </InCorrectAnswerButton>
        </Box>
      )}
      {showCorrectAnswer && (
        <Box className="correctWrapper">
          <Box className="correctContentWrapper">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img src={partyTeddy} className="partyTeddy" />
              <Typography className="correctText">
                {t('Awesome work!')}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  height={15}
                  src={greenFlag}
                  width={15}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  width={15}
                  src={greenShare}
                  height={15}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <CorrectAnswerButton
            onClick={() => {
              const param = exerciseAnswer ? {} : { isAnswerCorrect: true }
              onClickContinue(param)
            }}
          >
            {t('Continue')}
          </CorrectAnswerButton>
        </Box>
      )}

      <ShareModal 
        id="share-modal" 
        open={shareModalOpen} 
        lessonId={lessonId}
        microLessonId={microLessonId}
        handleClickClose={() => setShareModalOpen(false)}
        assessmentId={assessmentId}
        questionBankId={id}
        lessonAttemptId={lessonAttemptId}
        shareRecordType={shareRecordType}
        onAfterShare={() => {
          setShareModalOpen(false)
          setShowWellDoneShareModal(true)
        }}
        onUpdateXp={onUpdateXP}
      >
        <Box style={{width: '100%', padding: '24px 16px', textAlign: 'left'}}>
          <QuestionText question={question} width={308} isSharing />
          <div
            style={{
              display: 'flex',
              marginBottom: '96px',
              alignItems: 'center',
              flexWrap: 'wrap',
              rowGap: '24px',
            }}
          >
            {blanks.map((blank) => {
              return blank.isBlank ? (
                <div
                  key={blank.id}
                  style={{
                    marginLeft: '8px',
                    marginRight: '8px',
                    minHeight: '36px',
                    minWidth: '55px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    border: '2px solid #62CDFF',
                    borderBottomWidth: '6px',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <MathFormula
                    formula={convertLatex(blank.text)}
                    color='#62CDFF'
                    fontFamily='Quicksand'
                    fontSize={14}
                    lineHeight='6px'
                    fontWeight={700}
                  />
                </div>
              ) : (
                <div>
                  <MathFormula
                    formula={convertLatex(blank.text)}
                    color='#363636'
                    fontFamily='Quicksand'
                    fontSize={14}
                    lineHeight='6px'
                    fontWeight={700}
                  />
                </div>
              )
            })}
          </div>
        </Box>
      </ShareModal>

      <ActionAlert
        actionText={t('Solution')}
        open={showSolutionAlert}
        onClose={() => setShowSolutionAlert(false)}
        description={t(
          'Please note that choosing to view the solution will not earn you any XP.'
        )}
        title={t('Solution Alert')}
        onClickAction={() => {
          setSolutionFromValue('SOLUTION')
          setShowSolutionAlert(false)
        }}
        actionType="SOLUTION"
      />
      <ActionAlert
        actionType="HINT"
        isHint
        open={showHintAlert}
        onClose={() => setShowHintAlert(false)}
        title={t('Hint Alert')}
        actionText={t('Show hint')}
        description={t("If you choose to access a hint, you won't earn any XP.")}
        onClickAction={() => {
          setShowHint(true)
          setShowHintAlert(false)
        }}
      />
      <ActionAlert
        open={showSkipAlert}
        actionText={t('Skip')}
        onClose={() => setShowSkipAlert(false)}
        title={t('Skip Alert')}
        description={t(`skipDescription`)}
        actionType="SKIP"
        onClickAction={() => onClickContinue({ isSkip: true })}
      />
      <ReportModal
        open={showReportModal}
        title={t('Report a problem')}
        reportOptions={reportOptions}
        onClose={() => setShowReportModal(false)}
        onSubmitReport={() => {
          onSubmitReport && onSubmitReport()
          setShowThankReportModal(true)
        }}
        handleOption={(value: string) => {
          onSelectReportOption && onSelectReportOption(value)
        }}
      />
      <WellDoneShare
        open={showWellDoneShareModal}
        onClose={() => setShowWellDoneShareModal(false)}
        isExerciseShare
      />
      <ThankReportModal
        open={showThankReportModal}
        onClose={() => setShowThankReportModal(false)}
      />
    </SingleChoiceExerciseContainer>
  )
}

const SingleChoiceExerciseContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  height: '100%',

  '& .mainContainer': {
    padding: '32px 26px 0',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .questionWrapper': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .questionBodyContainer': {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .emptyBlank': {
    height: '44px',
    border: '2px solid #f0f0f0',
    width: '55px',
    marginRight: '12px',
    borderRadius: '12px',
    marginLeft: '12px',
  },

  '& .filledBlank': {
    marginLeft: '12px',
    marginRight: '12px',
    minHeight: '44px',
    minWidth: '55px',
    paddingLeft: '12px',
    paddingRight: '12px',
    border: '2px solid #62CDFF',
    borderBottomWidth: '6px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 99,
    background: 'white',
    opacity: 0.999,
  },

  '& .emptyOption': {
    height: '44px',
    width: '55px',
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
  },

  '& .filledOption': {
    cursor: 'pointer',
    minHeight: '44px',
    minWidth: '55px',
    paddingLeft: '12px',
    paddingRight: '12px',
    border: '2px solid #e6e6e6',
    borderBottomWidth: '6px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'white',
    opacity: 0.999,
  },

  '& .solutionContainer': {
    backgroundColor: '#C9EEFF',
    width: '100%',
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
  },

  '& .solutionContentContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '28px',
    alignItems: 'center',
  },

  '& .solutionContent': {
    color: '#00AEFF',
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '20px',
  },

  '& .correctWrapper': {
    padding: '36px 26px 40px',
    width: '100%',
    backgroundColor: '#d5f5e8',
    borderRadius: '20px 20px 0 0',
    backgroundImage: `url(${groupConfetti})`,
  },

  '& .correctContentWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .partyTeddy': {
    height: '84px',
    marginBottom: '-28px',
    marginLeft: '12px',
    width: '84px',
  },

  '& .correctText': {
    color: '#2BCE8C',
    fontSize: '20px',
    marginLeft: '10px',
    fontWeight: '900',
    fontFamily: 'Nunito',
  },

  '& .incorrectContainer': {
    padding: '36px 26px 40px',
    width: '100%',
    backgroundColor: '#fee1dd',
    borderRadius: '20px 20px 0 0',
  },

  '& .incorrectContentContainer': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    alignItems: 'center',
  },

  '& .incorrectTitle': {
    color: '#F96855',
    fontSize: '20px',
    fontWeight: '900',
    fontFamily: 'Nunito',
  },

  '& .incorrectDesc': {
    color: '#F96855',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontWeight: '600',
  },

  '& .incorrectAnswerDesc': {
    fontSize: '18px',
    fontFamily: 'Quicksand',
    fontWeight: '600',
    color: '#F96855',
  },

  [theme.breakpoints.up('md')]: {
    '& .mainContainer': {
      padding: '32px 60px 0',
    },
  },
}))

export default SequenceExercise
