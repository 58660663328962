
import React from "react";
  // Customizable Area Start
import {
  searchIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ClockIcon,
  CalenderIcon,
  ClockIconActive,
  CalenderIconActive,
} from "./assets";
import {
  Box,
  FormControl,
  Typography,
} from "@material-ui/core";
import FirstLoginController, { IList } from "./FirstLoginController.web";
import { withTranslation } from "react-i18next";
import SelectLanguage from "../../../components/src/SelectLanguage";
import Header from "../../../components/src/Header";
 // Customizable Area End
 class FirstLogin extends FirstLoginController {

  renderSteakgoalDropDown = ()=> {
    // Customizable Area Start
    return (
      <FormControl style={{ width: "100%", position: "relative",marginTop:"34px", display: 'flex', alignItems: 'center' }}>
       
        <div className={this.state.streakGoalFlag?"firstLoginDropDownActive firstLoginDropDown":"firstLoginDropDown"}onClick={this.handleSteakOpenDropDown} data-testid="streakgoalDropDown" >
            <img src={this.state.streakGoal.length>0 ? CalenderIconActive:CalenderIcon} alt=""  className="firstLoginDropStartIcon"/>
            <p data-testid="streakGoalValue"className={this.state.streakGoal.length>0 ? "firstLoginDropDownTitleActive":"firstLoginDropDownTitle"}>{this.state.streakGoal!==""?this.props.t(this.state.streakGoal):this.props.t("Select your streak goal")}</p>
           {this.state.streakGoalFlag ? (<img src={ArrowUpIcon} className="firstLoginDropEndIcon"/>):(<img src={ArrowDownIcon} className="firstLoginDropEndIcon"/>)}
        </div>
        
        {this.state.streakGoalFlag && (
          <div  data-testid = "streakGoalFlag"className="firstLoginOptionContainer">
            <div className="firstLoginSearchContainer">
              <img src={searchIcon} alt="" className="searchIcon" />
              <input
                type="text"
                placeholder={this.props.t("Search")}
                className="firstLoinsearch"
                onChange={this.handleSearchSteakGoal}
                data-testid={"searchStreakField"}
              />
            </div>
            <div className="firstLoginList">
              {this.state.streakGoalData.length>0?this.state.streakGoalData.map((item: IList, index: number) => {
                return (
                  <div className="firstLoginListItem" onClick={()=>this.handleStreakGoalSelectOption(item)} key={index} data-testid={'streakGoalDropDownOption'}>
                    <p className="firstLoginListItemOption" >{this.props.t(item?.option)}</p>
                    <p className="firstLoginListItemTime">{item?.time}</p>
                  </div>
                );
              }): <div className="firstLoginListItem"><p  className="firstLoginListItemOption" >{this.props.t("No result found!")}</p></div>}
            </div>

          </div>
        )}

      </FormControl>
    );
    // Customizable Area End

  };
  renderDailygoalDropDown=()=>{
        // Customizable Area Start

    return  <FormControl style={{ width: "100%", position: "relative",marginTop:"34px", display: 'flex', alignItems: 'center' }}>
       
    <div className={this.state.dailyGoalFlag?"firstLoginDropDownActive firstLoginDropDown":"firstLoginDropDown"}onClick={this.handleDailyOpenDropDown} data-testid={"dailyGoaldropDown"} >
        <img src={this.state.dailyGoal.length>0 ? ClockIconActive:ClockIcon} alt=""  className="firstLoginDropStartIcon"/>
        <p data-testid="dailyGoalValue" className={this.state.dailyGoal.length>0 ? "firstLoginDropDownTitleActive":"firstLoginDropDownTitle"}>{this.state.dailyGoal!==""?this.props.t(this.state.dailyGoal):this.props.t("Select your daily goal")}</p>
       {this.state.dailyGoalFlag ? (<img src={ArrowUpIcon} className="firstLoginDropEndIcon"/>):(<img src={ArrowDownIcon} className="firstLoginDropEndIcon"/>)}
    </div>
    
    {this.state.dailyGoalFlag && (
      <div className="firstLoginOptionContainer" data-testid="dailGoalFlag">
        <div className="firstLoginSearchContainer">
          <img src={searchIcon} alt="" className="searchIcon" />
          <input
            type="text"
            onChange={this.handleSearchDailyGoal}
            placeholder={this.props.t("Search")}
            className="firstLoinsearch"
            data-testid={"dailyGoalsearchField"}   
          />
        </div>
        <div className="firstLoginList">
          {this.state.dailyGoalData.length>0? this.state.dailyGoalData.map((item: IList, index: number) => {
            return (
              <div data-testid="dailyGoaldropDownOption" className="firstLoginListItem" onClick={()=>this.handleDailyGoalSelectOption(item)}  key={index}>
                <p  className="firstLoginListItemOption" >{this.props.t(item?.option)}</p>
                <p className="firstLoginListItemTime">{item?.time}</p>
              </div>
            );
          }): <div className="firstLoginListItem"><p  className="firstLoginListItemOption" >{this.props.t("No result found!")}</p></div>}
        </div>
      </div>
    )}

  </FormControl>
      // Customizable Area End

  }
 
  renderFirstLoginBottom=()=>{
    // Customizable Area Start

    return <div className="firstLoginBottom" style={{marginBottom: '40px'}}>
        <Typography style={{ textAlign: 'center', margin: '25px 0 0 0' }}>
          <SelectLanguage />
      </Typography>
    </div>
    // Customizable Area End

  }

  renderfirstloginMain=()=>{
        // Customizable Area Start

    return (
      <Box style={{display: 'flex', flexDirection: 'column', height: '100%',}}>
        <Header showGoBackBtn onGoBack={this.handleBackFirstlogin} />
        <div className="firstLogintitleContainer">
          <h1 className="firstLogintitle">{this.props.t("Ready, Set, Goal!")}</h1>
          <p className="firstLoginSubtitle">{this.props.t("Set your sights")}</p>
        </div>
        <div className="firstLoginForm">
          {this.renderDailygoalDropDown()}
          {this.renderSteakgoalDropDown()}
        </div>
        <button className="firstLoginDropDownBtnContaine" disabled={this.state.dailyGoal === "" || this.state.streakGoal === ""} onClick={this.handleSubmit} data-testid="firstLoginContinueBtn">{this.props.t("Continue")}</button>
        {this.renderFirstLoginBottom()}
      </Box>
    )
  }
   // Customizable Area End
  
   render() {
    // Customizable Area Start
    return (
      <>
        {this.renderfirstloginMain()}
      </>
    // Customizable Area End
    );
  }
}

export default withTranslation()(FirstLogin);
export  {FirstLogin};
