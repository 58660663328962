import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  styled,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { bolt, buttonClose, thankReportBg, reportBlue, reportBrown, reportPurple, reportMint, reportGreen, reportOrange, reportRed, reportYellow } from './assets';
import  {useTranslation} from "react-i18next"
// Customizable Area End

interface FeedbackReportModalProps {
  onClose: () => void
  open: any
}

// Customizable Area Start
const FeedbackReportModal = ({ onClose, open }: FeedbackReportModalProps) => {
  const  { t } = useTranslation()
  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
        '#ff8400': reportOrange,
        '#62cdff': reportBlue,
        '#b7f0ad': reportMint,
        '#a95edb': reportPurple,
        '#f96855': reportRed,
        '#884a39': reportBrown,
        '#fdce36': reportYellow,
        '#2bce8c': reportGreen
  };

  const thankFeedbackTeddy = colorToUpdateTeddy[avatarColor] || reportBlue;
  return (
    <ModalStyle open={open} onClose={onClose}>
      <Box className="feedbackContainer">
        <div className="feedbackCloseBtn" onClick={onClose}>
          <img src={buttonClose} width={10} height={10}  data-testid="btnClose"/>
        </div>
        <img src={thankFeedbackTeddy} className="thankTeddy" />
        <Typography className="feedbackCtitle">{t("Valuable feedback!")}</Typography>
        <Box className="feedbackCDescription">
          <Typography className="descriptions">{t("You've earned")}</Typography>
          <img src={bolt} className="boltIcon" />
          <Typography className="descriptionXP">{t("10 XP")}.</Typography>
        </Box>
        <Typography className="descriptions">
        {t("Your input fuels our mission to improve learning!")}
        </Typography>
      </Box>
    </ModalStyle>
  )
}
// Customizable Area End

// Customizable Area Start
const ModalStyle = styled(Modal)(() => ({
  '& .feedbackContainer': {
    top: '50%',
    position: 'absolute',
    left: '50%',
    width: 296,
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#e3cff4',
    backgroundImage: `url(${thankReportBg})`,
    borderRadius: 12,
    padding: 26,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .feedbackCloseBtn': {
    justifyContent: 'flex-end',
    width: '100%',
    display: 'flex',
  },

  '& .feedbackCtitle': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    color: '#363636',
    fontSize: '18px',
  },

  '& .feedbackCDescription': {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
  },

  '& .boltIcon': {
    marginRight: '4px',
    width: '10px',
    marginLeft: '4px',
    height: '13px',
  },

  '& .descriptions': {
    color: '#666666',
    fontFamily: 'Quicksand',
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: '500',
  },

  '& .descriptionXP': {
    color: '#666666',
    fontFamily: 'Quicksand',
    fontSize: '15px',
    fontWeight: '700',
  },

  '& .thankTeddy': {
    marginBottom: '13px',
    width: '96px',
    height: '100px',
    //marginRight: '20px',
  },
}))

export default FeedbackReportModal

// Customizable Area End