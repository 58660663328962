// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    IconButton,
    InputAdornment,
    styled
} from '@material-ui/core'

import {
    ButtonCustom,
    TextInputCustom,
} from '../../../components/src/MaterialTheme'
import { withTranslation } from "react-i18next";
import ParentSmsController from "./ParentSmsController.web";
import ParentSmsLayoutWeb from "./parentSmsLayout.web";
import { Formik, FormikTouched, FormikErrors  } from 'formik'
import * as Yup from 'yup'
import { warningIcon } from "./assets";
import {parsePhoneNumberFromString,isValidPhoneNumber, CountryCode} from "libphonenumber-js"
import CustomCountrySelector from "../../../components/src/CustomCountrySelector";
import { ICountry } from "../../accountgroups/src/EditAccountController";
import { StringChain } from "lodash";
// Customizable Area End

class ParentSms extends ParentSmsController {
    // Customizable Area Start
    phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

    accountProfileSchema=Yup.object({
     parentPhone: Yup.string()
     .required(this.props.t('Parent phone name is required'))
      .test(
        'is-valid-phone',
        'Phone number is not valid',
        value => value ? isValidPhoneNumber((`${value}`),this.state.parentPhoneCountryCode?.attributes?.alpha2 as CountryCode) : false
      ),
    })

    showError = (touched: FormikTouched<{ parentPhone:string }>, errors: FormikErrors<{ parentPhone:string }>, value: string) => {
        return errors[value as keyof typeof errors] && touched[value as keyof typeof touched] && <div className="required-error"><Typography className="text-danger">{errors[value as keyof typeof errors]}</Typography></div>
    }


    checkTouchedAndErrorInput = (name: boolean | undefined, error: string | undefined) => {
        return name && Boolean(error)
    }

    handleChildPhoneNumber=(value:ICountry,setFieldValue:(field: string, value: string, shouldValidate?: boolean | undefined) => void)=>{
        this.setState({
            parentPhoneCountryCode:value
        })
        if(value?.update){
        setFieldValue("parentPhone",`+${value.attributes.country_code}`)
        }
      }


      handlePhoneNumberChange=(e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,handleChange:(e:{target:{name:string,value:string}})=>void,name:string,phoneCountrycode:ICountry)=>{
        if(e.target.value.length > phoneCountrycode?.attributes?.country_code?.length+1){
          const numericValue = e.target.value.replace(/[^\d+]/g, ''); 
          const phoneNumber = parsePhoneNumberFromString(numericValue,phoneCountrycode?.attributes?.alpha2 as CountryCode)
          if(phoneNumber){
            const formattedNumber = phoneNumber.formatInternational()
            handleChange({ target: { name: name, value: formattedNumber} });
          }else{
            handleChange({ target: { name: name, value: numericValue } });
          }
        }
      }
    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { t } = this.props
        const queryParams = new URLSearchParams(window.location.search);
        const parentPhone = queryParams.get('parent_phone');
        // Customizable Area End
        return (
            // Customizable Area Start
            <BoxContainer>
                <ParentSmsLayoutWeb showGoBackBtn={false} navigation={this.props.navigation}>
                    <Box className="description_container">
                        <Typography className="contact_info">{t("Please review your contact information to stay updated on your child's progress!")}</Typography>
                    </Box>
                    <Box>
                        <Formik
                            data-testid="formik"
                            enableReinitialize
                            initialValues={{
                                parentPhone: this.state.parentPhone ??""
                            }}
                            onSubmit={this.handleFormSubmit}
                             validationSchema={this.accountProfileSchema}
                        >
                            {({ errors, touched, handleSubmit, handleChange, values, setFieldValue }) => (
                                <form className="formContainer" onSubmit={handleSubmit}>
                                    <TextInputCustom
                                        value={values.parentPhone}
                                        onChange={(e) => {
                                            this.handlePhoneNumberChange(
                                                e,
                                                handleChange,
                                                'parentPhone',
                                                this.state.parentPhoneCountryCode as ICountry)
                                        }}
                                        error={this.checkTouchedAndErrorInput(touched.parentPhone, errors.parentPhone)}
                                        autoComplete="off"
                                        name="parentPhone"
                                        data-testid="parentPhone"
                                        placeholder={this.props.t("Parent Phone")}
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" style={{marginRight:10}}>
                                                <CustomCountrySelector 
                                                 showSearchInput
                                                 flag={this.state.parentPhoneCountryCode}
                                                 list={this.state.countriesData}
                                                 data-testid="parentPhoneCountryCode"
                                                 value={this.state.parentPhone}
                                                 setValue={ (value:ICountry)=>{
                                                  this.handleChildPhoneNumber(value,setFieldValue)
                                                 }}
                                                />
                                                </InputAdornment>
                                              ),
                                            endAdornment: this.checkTouchedAndErrorInput(
                                                touched.parentPhone, errors.parentPhone
                                            ) ? (
                                                <InputAdornment position="end">
                                                    <IconButton disabled edge="end">
                                                        <img src={warningIcon} width={18} height={18} />
                                                    </IconButton>
                                                </InputAdornment>
                                            ) : null,
                                        }}
                                        style={{ marginBottom: '16px' }}
                                    />
                                    {this.showError(touched, errors, 'parentPhone')}
                                    <ButtonCustom
                                        variant="contained"
                                        type="submit"
                                        style={{ marginTop: '36px' }}
                                        data-test-id="continue"
                                    >
                                        {this.props.t("Confirm and continue")}
                                    </ButtonCustom>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </ParentSmsLayoutWeb>
            </BoxContainer>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(ParentSms)
const BoxContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: '100%',
    flexDirection: 'column',

    "& .contact_info": {
        color: "var(--Primary-Neutrals-Warm-gray-400, #999)",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
    },
    "& .formContainer":{
        display:'flex',
        flexDirection:'column',
        alignItems:"center",
        justifyContent:'center',
    }, 

    '& .errorAlert': {
        width: '300px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        backgroundColor: '#FEE2DF',
        color: '#F96855',
        fontSize: '14px',
        fontFamily: 'Quicksand',
        fontWeight: '500',
        marginBottom: '16px',
      },
      [theme.breakpoints.up('md')]: {
        '& .errorAlert': {
          width: '400px',
        },
      },

      "& .text-danger": {
        color: "#F96855",
        fontStyle: "normal",
        textAlign: 'start',
        fontWeight: 500,
        fontFamily: "Quicksand",
        fontSize: "13px",
        lineHeight: "12px",
        "@media (max-width:600px)": {
            width: '300px'
        }
    },
      "& .required-error": {
        textAlign: "start",
        width: "395px",
        marginTop: "0px",
        marginBottom: "10px",
        "@media (max-width:600px)": {
            display: "flex",
            justifyContent: "center",
        }
    },

    "& .description_container": {
        marginBottom: 24
    }
}))
// Customizable Area End