import React, {Component} from "react";
// Customizable Area Start
import {Box, Typography, styled} from "@material-ui/core"
import LinearProgress from '@material-ui/core/LinearProgress';
import {blankBolt,blankBolt1, blankBolt2, blankBolt3} from './assets'
import { withTranslation } from "react-i18next";
interface Props {
    premium: boolean;
    xp_detail:any;
    t?:any
  }

class UserProfileDetail extends Component<Props> {

  detailData = [
    {name: "Achiever", points:  `${this.props.xp_detail?.archiver_xp} XP`, completeProgress: this.props.xp_detail?.archiver_xp, image: blankBolt},
    {name: "Socializer", points: `${this.props.xp_detail?.socializer_xp} XP`, completeProgress: this.props.xp_detail?.socializer_xp, image: blankBolt1},
    {name: "Explorer", points: `${this.props.xp_detail?.explorer_xp} XP`, completeProgress: this.props.xp_detail?.explorer_xp, image: blankBolt2},
    {name: "Philanthropist", points: `${this.props.xp_detail?.philantropist_xp} XP`, completeProgress: this.props.xp_detail?.philantropist_xp, image: blankBolt3}
  ]

 getBoxColor = (index:number) => {
    if(index === 1) {
        return "detailbox1"
    } else if(index === 2) {
        return "detailBox2"
    } else {
        return "detailbox3"
    }

 }

 getProgressColor = (index:number) => {
    if(index === 1) {
        return "progress1"
    } else if(index === 2) {
        return "progress2"
    } else {
        return "progress3"
    }
 }
 
 getLinerProgress=(xp:number)=>{
  const total_XP=this.props.xp_detail?.total_xp;
  return  total_XP===0? 0: Math.floor(xp*100/total_XP)
 }

  render() {

    return (
        <DetailContainer>
        {this.detailData.map((item: any, index: number) => (
          index === 0 ? (
            <Box key={index} className="detailbox">
              <Typography className="detailName">{this.props.t(item.name)}</Typography>
              <Box className="progressBar">
              <img src={item.image} className="boltImage"/>  
              <LinearProgress variant="determinate" value={this.getLinerProgress(item.completeProgress)} className="progress"/>
              </Box>
              <Typography className="pointShown">{item.points}</Typography>
            </Box>
          ) : (
            <Box key={index} className={this.getBoxColor(index)}>
              <Typography className="detailName">{this.props.t(item.name)}</Typography>
              <Box className="progressBar">
              <img src={item.image} className="boltImage"/>  
              <LinearProgress variant="determinate" value={this.getLinerProgress(item.completeProgress)} className={this.getProgressColor(index)}/>
              </Box>
              <Typography className="pointShown">{item.points}</Typography>
            </Box>
          )
        ))}
      </DetailContainer>
    );
    
  }
}
export default withTranslation()(UserProfileDetail)
const DetailContainer = styled(Box)(({theme}) => ({ 
   '& .detailbox': {
       
        borderRadius: "10px",
        background: 'rgba(0, 174, 255, 0.10)',
        display:'flex',
        padding: "20px",
        marginTop:'20px',
        minHeight:'43px'
   },
   '& .detailbox1': {
       
    borderRadius: "10px",
    background: 'rgba(255, 132, 0, 0.10)',
    display:'flex',
    padding: "20px",
    marginTop:'20px',
    minHeight:'43px'
},
'& .detailbox2': {
       
    borderRadius: "10px",
    background: 'rgba(43, 206, 140, 0.15)',
    display:'flex',
    padding: " 20px",
    marginTop:'20px',
    minHeight:'43px'
},
'& .detailbox3': {
       
    borderRadius: "10px",
    background: 'rgba(169, 94, 219, 0.10)',
    display:'flex',
    padding: "20px",
    marginTop:'20px',
    minHeight:'43px'
},
        '& .pointShown': {
         marginLeft:'auto', 
         color: "var(--Primary-Neutrals-Warm-gray-500, #7F7F7F)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Quicksand",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "8px"
        },

'& .detailName': {
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "11.5px", 
    width:126,

    '@media(max-width:400px)':{
      width:'108px',
     
    },

    '@media(max-width:360px)':{
      width:'90px',
    }

}, 

'& .progressBar': {
    width: '40%',
    position: 'relative',
    marginLeft:'15px',
}, 

'& .boltImage': {
    cssFloat: "left",
    position: "absolute",
    zIndex: 9,
    left: "-5px"
},

'& .progress': {
    height: "10px",
    borderRadius: "15px",
    backgroundColor: "unset", 
    marginTop:'5px',
    
    '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#00AEFF',
    borderRadius:'15px'
    }
}, 
'& .progress1': {
    height: "10px",
    borderRadius: "15px",
    backgroundColor: "unset", 
    marginTop:'5px',
    
    '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#FF8400',
    borderRadius:'15px'
    }
},
'& .progress2': {
    height: "10px",
    borderRadius: "15px",
    backgroundColor: "unset", 
    marginTop:'5px',
    
    '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#2BCE8C',
    borderRadius:'15px'
    }
},
'& .progress3': {
    height: "10px",
    borderRadius: "15px",
    backgroundColor: "unset", 
    marginTop:'5px',
    
    '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#A95EDB',
    borderRadius:'15px'
    }
}

}))
// Customizable Area End
