import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ReactPlayer from "react-player";
import { RefObject, createRef } from "react";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?:any;
  // Customizable Area Start
  videoUrl: any;
  lessonId: any;
  parentId: string
  lessonNumber: number
  lessonName: string
  totalLessonCount: number
  totalXP: number
  onGetAccountInfo: (token: string) => void
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isVideoReady: boolean
  isPlaying: boolean;
  videoProgress: number;
  showVideoControls: boolean;
  showLinearProgressBar: boolean;
  ended: boolean;
  currentTime: number;
  duration: number;
  slider: number | string | Array<number | string>
  isExpanded: boolean;
  handleOpenReportModel: boolean;
  handleVolumnBtn: boolean;
  volumeLevel: number;
  isMuted: boolean;
  isScrollDown:boolean;
  isScrollingDown: boolean,
  isShowPlayer:boolean;
  isReportModalOpen: boolean;
  isThankreportModelOpen: boolean;
  iswellDoneShareModelOpen: boolean;
  reportproblem: any;
  savename: string;
  prevScrollTop:number;
  isScrollable:boolean;
  lessonAttemptId: string;
  showMicroSummary:boolean;
  carouselDataImages : Array<{
    src: string,
    alt: string
  }>
  componentWidth: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CftableforlessonsvideoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public player: ReactPlayer | null = null;
  reportproblemApiId: string = "";
  public divRef: any;
  public typographyRef: any;
  microlessonApiId: any = '';
  getLatestLessonAttempsId: string = ''
  getMicroSummaryDataID: string = ''
  createNewLessonAttemptId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.divRef = createRef()
    this.typographyRef = createRef()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isVideoReady: false,
      isPlaying: false,
      videoProgress: 0,
      showVideoControls: true,
      showLinearProgressBar: false,
      ended: false,
      currentTime: 0,
      duration: 0,
      slider: 0,
      isExpanded: false,
      handleOpenReportModel: false,
      handleVolumnBtn: false,
      volumeLevel: 100,
      isMuted: false,
      isScrollDown: false,
      isScrollingDown: true,
      isShowPlayer:false,
      isReportModalOpen: false,
      isThankreportModelOpen: false,
      iswellDoneShareModelOpen: false,
      reportproblem: {},
      savename: "",
      prevScrollTop: 0,
      isScrollable: false,
      lessonAttemptId: '',
      showMicroSummary: false,
      carouselDataImages:[],
      componentWidth: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.reportproblemApiId) {
        this.receivePostProblemStatus(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.getLatestLessonAttemps) {
        this.receivePostProblemStatus(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.getMicroSummaryData) {
        const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleGetMicroSummaryResponse(responseJSON);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any
  ) => {
    const successCallbackMap = {
      [this.getLatestLessonAttempsId]: this.handleGetLatestLessonAttempResponse,
      [this.createNewLessonAttemptId]: this.handleCreateNewLessonAttemptResponse,
      [this.getMicroSummaryDataID] : this.handleGetMicroSummaryResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON)
    }
  }
  async componentDidMount() { 
    const token = localStorage.getItem('token') || ''
    const lessonId = this.props.navigation.getParam('lessonId')
    if(this.props.videoUrl.attributes?.summary_video.startsWith('http')){
      this.setState({
        isScrollDown:false
      })
    }
    else {
      console.log("props",this.props.videoUrl)
    setTimeout(() =>{
      const divElement = document.getElementById("textBox");
      if(divElement?.clientHeight == divElement?.scrollHeight){
        this.setState({
          isScrollDown:true
        }) 
      }
    },3000)}
    this.getLatestLessonAttemps(token, lessonId)
    this.getMicroSummaryData(this.props.videoUrl.id)
    this.updateComponentWidth()
    window.addEventListener('resize', this.updateComponentWidth)
    window.addEventListener('fullscreenchange', this.handlePressESC)
  }

  handlePressESC = () => {
    const isFullscreen = Boolean(document.fullscreenElement)
    if (isFullscreen) {
        this.setState({ isExpanded: true });
    } else {
      this.setState({ isExpanded: false });
    }
  };
  
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(prevProps?.videoUrl?.attributes?.summary_video!==this.props.videoUrl?.attributes?.summary_video){
      if(this.props.videoUrl.attributes?.summary_video.startsWith('http')){
        this.setState({
          isScrollDown:false
        })
      }
      else {
    setTimeout(() =>{
      const divElement = document.getElementById("textBox");
      if(divElement?.clientHeight == divElement?.scrollHeight){
        this.setState({
          isScrollDown:true
        }) 
      }
    },4500)}
}
}

async componentWillUnmount() {
  window.removeEventListener('resize', this.updateComponentWidth)
  window.removeEventListener('fullscreenchange', this.handlePressESC);
}

updateComponentWidth = () => {
  const { current } = this.typographyRef
  if (current) {
    const width = current.getBoundingClientRect().width
    this.setState({ componentWidth: width })
  }
}

handleGetMicroSummaryResponse = (responseJson: any) => {
  const microSummaryData = responseJson?.data?.[0]?.attributes?.content_images;
  if (microSummaryData) {
    const carouselDataImages = microSummaryData.micro_summary_images.map((item: any) => ({
      src: item.image_url,
      alt: "Image Alt Text",
    }));
    this.setState({
      carouselDataImages,
    });
  }
}

  handleOption = (option: string) => {
    this.setState({ savename: option })
  }

  handleSetVideoReady = () => {
    this.setState({isVideoReady: true})
  }

  togglePlay = () => {
    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
      showVideoControls: true,
    }));
  };
  toggleControls = () => {
    this.setState((prevState) => ({
      showVideoControls: !prevState.showVideoControls,
    }));
  };
  onMouseEvent = (event: string) => {
    event === "enter" ?
      this.setState({
        showVideoControls: false,
      }) : this.setState({
        showVideoControls: true,
      })
  }

  handlePauseClick = () => {
    this.setState({
      isPlaying: false,
    });
  };

  handleReplayClick = () => {
    if (this.player) {
      this.player.seekTo(0);
      this.setState({
        isPlaying: true,
        ended: false,
      });
      if (this.state.videoProgress !== 0) {
        this.setState({ videoProgress: 0 });
      }
    }
  };

  handleProgress(progress: any) {
    this.setState({ videoProgress: progress?.played * 100, currentTime: progress?.playedSeconds });
  }

  handleDuration(duration: number) {
    this.setState({ duration });
  }

  handleSliderChange = (event: any, newValue: number | number[]) => {

    if (this.player) {
      const timeToSeek = (newValue as number) / 100 * this.state.duration;
      this.player.seekTo(timeToSeek);
      this.setState({ currentTime: timeToSeek });
    }
  };

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  handleVolumeChange = (newValue: any) => {
    this.setState({ volumeLevel: newValue, isMuted: newValue === 0 });
  };

  handleVolumnBtn = () => {
    this.setState((prevState) => ({
      handleVolumnBtn: !prevState.handleVolumnBtn,
    }));
  };

  handleOpenReportModal = () => {
    this.setState({
      isReportModalOpen: true,
    });
  }
  handleOpenThankReportModal = () => {
    this.setState({
      isThankreportModelOpen: true,
    });
  }
  
  handleMicroSummary = () =>{
    this.setState((prevState) => ({
      showMicroSummary: !prevState.showMicroSummary,
      isPlaying: false,
    }))
  }

  handleRef = (ref: any) => {
    this.player = ref
  }

  handleExpandClick = () => {
    const contentContainer = document.querySelector('.fullScreenContent');
    if (contentContainer) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        contentContainer.requestFullscreen();
      }
    }
  }

  ReportProblem = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("token")
    };
    const data = {
      "lesson_id": this.props.parentId,
      "micro_lesson_id": this.props.lessonId,
      "lesson_attempt_id":this.state.lessonAttemptId,
      "problem": this.state.savename
    };

    const httpBody = {
      data: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportproblemApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportproblemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  receivePostProblemStatus = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.success) {
      this.props.onGetAccountInfo(localStorage.getItem("token") || '')
      this.handleOpenThankReportModal();
    }
    else {
      console.log("Error------", errorReponse);
    }
  }

  handleGetLatestLessonAttempResponse = (responseJson: any) => {
    const attemptId = responseJson?.data?.id
    const lessonCompleted = responseJson?.data?.attributes?.lesson_completed
    
    if (attemptId && !lessonCompleted) {
      this.setState({
        lessonAttemptId: attemptId
      })
    } else {
      this.createNewLessonAttempt()
    }
  }

  handleCreateNewLessonAttemptResponse = (responseJson: any) => {
    const attemptId = responseJson?.data?.id
    
    this.setState({
      lessonAttemptId: attemptId
    })
  }

  getLatestLessonAttemps = (token: string, lessonId: number) => {
    this.getLatestLessonAttempsId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts/latest?lesson_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getMicroSummaryData = (microLessonId: number) => {
    let token =  localStorage.getItem("token");
    this.getMicroSummaryDataID = sendAPIRequest(
      `bx_block_profile/get_micro_summary?micro_lesson_id=${microLessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  lessonProgress = () => {
    let token =  localStorage.getItem("token");
    let payload = {
      "lesson_progress": {
        "lesson_id": this.props.parentId ,
        "micro_lesson_id": this.props.lessonId,
        "status": "start" 
    }
    };
    this.microlessonApiId = sendAPIRequest(
      configJSON.lessonProgressAPIEndPoint,
      {
        method: configJSON.postApiMethod,
        headers: {
          'Content-Type': configJSON.validationApiContentType,
          "token": token
        },
        body: payload,
      }
    )
  }

  handleNavigateMicrolessonExercise = ()=> {
    if (this.props.lessonNumber === 3) {
      this.props.navigation.navigate('MotivationalPage', {
        lessonId: this.props.parentId, 
        lessonNumber: this.props.lessonNumber, 
        lessonName: this.props.lessonName,
        totalLessonCount: this.props.totalLessonCount,
        totalXP: this.props.totalXP,
      })
    } else {
      this.props.navigation.navigate('MicroLessonExercise', {lessonId: this.props.parentId, lessonNumber: this.props.lessonNumber, lessonName: this.props.lessonName})
    }
  }

  handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const result = (event?.currentTarget?.scrollTop + event?.currentTarget?.clientHeight) - event?.currentTarget?.scrollHeight
    const isScrollableBox = (result > -5) && (result < 5);
    const isScrollingUp = event?.currentTarget?.scrollTop < this.state.prevScrollTop;
    
    this.setState({
      isScrollingDown: !isScrollingUp,
      isScrollDown: isScrollableBox,
      prevScrollTop: event?.currentTarget?.scrollTop,
    });
  };

  createNewLessonAttempt = () => {
    const body = {
      lesson_id: this.props.navigation.getParam('lessonId')
    }
    console.log('bodyyy', body);
    
    this.createNewLessonAttemptId = sendAPIRequest(
      `bx_block_educational_gamification/lesson_attempts`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem("token"),
        },
        body,
      }
    )
  }
  // Customizable Area End 
}
