import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  Motivational,
  blueViewRectangle,
  blueViewRectangleLeft,
} from '../../../blocks/educationalgamification/src/assets'
import  {useTranslation} from "react-i18next"

interface MotivationalPageProps {
  onClickContinue: ({
    isClickFromMotivation,
  }: {
    isClickFromMotivation?: boolean
  }) => void
}

const MotivationalPage: React.FC<MotivationalPageProps> = ({
  onClickContinue,
}) => {
  const  { t } = useTranslation()
  return (
    <MotivationalPageContainer>
      <Box className="upContainer">
        <Box style={{ position: 'relative' }}>
          <img src={blueViewRectangle} width={171} height={55} />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#00AEFF' }}>
              {t("You’ve got this")}!
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="imageWrapper">
        <img src={Motivational} width={180} height="auto" />
      </Box>
      <Box className="titleWrapper">
        <Typography className="titleText" style={{ fontSize: '30px' }}>
          {t("CHARGE AHEAD!")}
        </Typography>
        <Typography className="titleText" style={{ fontSize: '20px' }}>
          {t("XP IS JUST A QUESTION AWAY")}
        </Typography>
      </Box>

      <Box className="downContainer">
        <Box style={{ position: 'relative' }}>
          <img src={blueViewRectangleLeft} width={200} height="auto" />
          <Box className="textWrapper">
            <Typography className="styledText" style={{ color: '#00AEFF' }}>
              {t("Game on!")}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        className="buttonContainer"
        onClick={() => onClickContinue({ isClickFromMotivation: true })}
      >
        <StyledButton>{t("Continue")}</StyledButton>
      </Box>
    </MotivationalPageContainer>
  )
}

const MotivationalPageContainer = styled(Box)(({ theme }) => ({
  marginTop: '55px',
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  '& .upContainer': {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: '26px',
  },

  '& .textWrapper': {
    position: 'absolute',
    top: '10px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#363636',
    textTransform:'capitalize'
  },

  '& .styledText': {
    fontFamily: 'Quicksand',
    fontSize: '14px',
    fontWeight: 700,
    textAlign: 'center',
  },

  '& .titleWrapper': {
    marginTop: '56px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .imageWrapper': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '42px',
  },

  '& .downContainer': {
    display: 'flex',
    marginTop: '64px',
  },

  '& .buttonContainer': {
    flex: 1,
    margin: '32px 26px 40px',
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  [theme.breakpoints.up('md')]: {
    '& .buttonContainer': {
      margin: '32px 60px 40px',
    },

    '& .upContainer': {
      marginRight: '60px',
    },
  },
}))

export default MotivationalPage
