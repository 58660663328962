import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import AutomaticRenewalsController, {
  Props,
  configJSON,
} from "./AutomaticRenewalsController";

export default class AutomaticRenewals extends AutomaticRenewalsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Box>
              <Box style={webStyle.headingWrapper}>
                <Typography variant="h6" style={{ flex: 1 }}>
                  {configJSON.automaticRenewalList}
                </Typography>
                <Button
                  data-test-id="btnAdd"
                  style={webStyle.editButton}
                  variant="contained"
                  color="primary"
                  onClick={() => this.setState({ isFormOpen: true })}
                >
                  {configJSON.addNewText}
                </Button>
              </Box>
              <TableContainer style={webStyle.tableContainer} component={Paper}>
                <Table style={webStyle.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: 10 }}>
                        {configJSON.idText}
                      </TableCell>
                      <TableCell>{configJSON.subscriptionType}</TableCell>
                      <TableCell>{configJSON.autoRenewal}</TableCell>
                      <TableCell>{configJSON.actionText}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data
                      .sort(
                        (a: any, b: any) => a.attributes.id - b.attributes.id
                      )
                      .map((item: any) => {
                        return (
                          <TableRow key={item.attributes.id}>
                            <TableCell>{item.attributes.id}</TableCell>
                            <TableCell>
                              {item.attributes.subscription_type}
                            </TableCell>
                            <TableCell>
                              {item.attributes.is_auto_renewal
                                ? "Active"
                                : "Inactive"}
                            </TableCell>
                            <TableCell>
                              <Button
                                data-test-id="btnAction"
                                style={webStyle.editButton}
                                variant="contained"
                                color="primary"
                                onClick={() => this.onUpdateHandler(item)}
                              >
                                {configJSON.editText}
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Container>
        <Dialog
          maxWidth={"md"}
          open={this.state.isFormOpen}
          onClose={() => this.onCancelHandler()}
        >
          <DialogTitle>
            {this.state.updateId
              ? configJSON.updateAutomaticRenewal
              : configJSON.createAutomaticRenewal}
          </DialogTitle>
          <DialogContent>
            <TextField
              id="standard-basic"
              label={configJSON.subscriptionType}
              fullWidth
              value={this.state.subscriptionType}
              onChange={(e) =>
                this.setState({ subscriptionType: e.target.value })
              }
            />
            <FormControlLabel
              value={this.state.isAutoRenewal}
              control={
                <Checkbox
                  onChange={() =>
                    this.setState({ isAutoRenewal: !this.state.isAutoRenewal })
                  }
                  checked={this.state.isAutoRenewal}
                  color="primary"
                />
              }
              label={configJSON.autoRenewal}
              labelPlacement="start"
              style={{ marginLeft: 0, marginTop: 16 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onCancelHandler()} color="primary">
              {configJSON.cancelText}
            </Button>
            <Button
              onClick={() =>
                this.state.updateId
                  ? this.updateAutoRenewal()
                  : this.createAutoRenewal()
              }
              variant="contained"
              color="primary"
            >
              {this.state.updateId
                ? configJSON.updateText
                : configJSON.saveText}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(98, 0, 238)",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
    button: {
      textTransform: "none",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  headingWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    paddingTop: 20,
  },
  tableContainer: {
    marginTop: 20,
  },
  table: {
    minWidth: 650,
  },
  editButton: {
    padding: 0,
    fontSize: 12,
  },
};
// Customizable Area End
