// Customizable Area Start
import React from "react"
import { withTranslation } from "react-i18next"
import PageNotFoundController from "./PageNotFoundController.web"
import {
    Box,
    Typography,
    Button,
    styled
} from '@material-ui/core'
import { TedixHeadingText, TextableFour, sadTeddy } from "./assets"
// Customizable Area End

class PageNotFound extends PageNotFoundController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={{background:"#62CDFF",height:"100%", overflow:'auto'}}>
            <PageNotFoundContainer>
                <Box className="Header_image">
                    <img src={TedixHeadingText} alt="tedix_image" />
                </Box>
                <Box className="center_Content">
                    <Box>
                        <img src={TextableFour} alt="text_4" className="image_Height_Width" />
                        <img src={sadTeddy} alt="teddy" className="image_Height_Width" />
                        <img src={TextableFour} alt="text_4" className="image_Height_Width" />
                    </Box>
                    <Box className="text_container">
                    <Box className="title_textContainer">
                        <Typography className="title_text">{this.props.t("Oops! Page not found")}</Typography>
                        <Box className="title_description_container">
                            <Typography className="title_description">{this.props.t("Seems like you're in uncharted territory!")}</Typography>
                            <Box className="title_description_content">
                                <Typography className="title_description">{this.props.t("Don't worry; we'll guide you back to the main learning path.")}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    </Box>
                </Box>
                <Box className="footer">
                  <Button data-testid="BackToHomeBtn" onClick={this.handleBacktoHome} className="back_to_home_button">{
                  this.props.t("Back to Home")}
                  </Button>
                </Box>
            </PageNotFoundContainer>
            </Box>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default withTranslation()(PageNotFound)
const PageNotFoundContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    paddingTop: 65,
    paddingBottom: 65,
    background: "var(--Primary-Blue-Blue-Primary, #62CDFF)",

    "& .Header_image": {
        width: "100%",
        textAlign: 'center',
    },

    "& .image_Height_Width": {
        "@media(max-width:480px)":{
            width: "110px",
        },

        "@media(max-width:350px)":{
            width: "85px",
        },

    },

    "& .center_Content": {
        padding: "120px 0px",
        textAlign: 'center',
    },

    "& .title_textContainer": {
        textAlign: "center",
        width:"360px"
    },

    "& .title_text": {
        color: "var(--Basic-White, var(--0, #FFF))",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",

        "@media(max-width:480px)":{
            fontSize: "22px",
        }
    },

    "& .title_description_content": {
      marginTop:8,
      padding:"0px 30px"
    },

    "& .title_description": {
        color: "var(--Basic-White, var(--0, #FFF))",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",

        "@media(max-width:480px)":{
            fontSize: "16px",
        }
    },

    "& .text_container":{
        display:'flex',
        alignItems:'center',
        marginTop:'28px',
        justifyContent:'center'
    },

    "& .title_description_container":{
        marginTop:16
    },

    "& .footer":{
        textAlign:"center"
    },

    "& .back_to_home_button":{
        width:400,
        height:'42px',
        borderRadius: "12px",
        border: "2px solid var(--Primary-Neutrals-Warm-gray-400, #999)",
        background: "var(--0, #FFF)",

        "@media(max-width:480px)":{
            width:260,
        }
    },

    "& .back_to_home_button > .MuiButton-label":{
        color: "var(--Primary-Neutrals-Warm-gray-500, #7F7F7F)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform:"none",
        lineHeight: "12px", /* 60% */
        letterSpacing: "0.8px",

        "@media(max-width:480px)":{
            fontSize: "16px",
        }
    },


}))
// Customizable Area End