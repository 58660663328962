import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import {orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried} from './assets';
import { sendAPIRequest } from '../../../components/src/utils'

export interface  ItransformedData {
  title: string;
  value: number;
  label: string;
  image: string;
}

export interface  Ilabels {
  weekly: string[];
  monthly: string[];
  yearly: string[];
}

export interface IMonthData{
  startDate:string,
  endDate:string,
  month:string
}

export type CalendarWeeks = {
  [key: string]: string[]; // Represents dynamic week keys with an array of "DD-MM-YYYY" formatted strings
};

export type DayIndexType={
  [key: string]: string;
}

export type RangeDataType={
  stringDate: string;
  startDate:string;
  endDate:string;
  type:string;
}

export type LessonTypeData={
    lesson_id: number,
    name: string,
    content: string,
    description: string
}
export interface LessonDataArray{
   lessons: Array<LessonTypeData> 
   earned_xp: number,
   minutes: number,
   grade: number,
   startDate?:string,
   label ?:string,
   endDate?:string
}

export interface ILearningReport{
  grade: number,
  lessons: number,
  minutes: number,
  earned_xp: number
}


export interface IMedianResponseData{
  average_grade:number,
  statistics_report: {
      total_xp: number,
      minutes_spent: number,
      completed_lessons: number,
      challenge_completed: number
  },
  avg_grade_info_report: {[key:string]: IAverageGrade} ,
  learning_report: {[key:string]: ILearningReport},
  errors?:{}
   
}

export interface ILearningData{
  dateTime: string,
    startDate: string,
    endDate: string,
    type: string,
    Lessons: number,
    Grade: number,
    Minutes: number,
    XPEarned: number,
}

export interface IAverageGrade{
  grade:number
}

export interface DataEntry {
  lessons: Array<LessonDataArray>;
  earned_xp: number;
  minutes: number;
  grade: number;
}

export interface MainObject {
  [key: string]: DataEntry;
}

export interface IError{
  errors?:{}
}


export interface MainLessonDetailObject  {
  name: string;
  content: string;
  description: string;
  lesson_id: number;
  earned_xp: number;
  grade: number;
  lesson_attempt_id:string|null
  minutes: number;
}

export interface ILessontDetailData{
 type:"weekly" | "daily" | "monthly",
 startDate:string,
 endDate:string,
 dataId:string,
 dateTime:string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  stateOpen?: boolean;
  drawerOpen?: boolean;
  closeDrawer?: () => void;
  // Customizable Area Start
  t?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number;
  detailId: string;
  drawerOpen: boolean;
  medianType: string;
  medianDataList: IMedianResponseData;
  startDate: string;
  endDate: string;
  dataChart: Array<number>;
  average: number;
  learningData: Array<ILearningData >
  ReportDetailData: Array<LessonDataArray | undefined > | Array<MainLessonDetailObject>
  loading: boolean;
  dateTime: string;
  reportCategory: "weekly" | "daily" | "monthly",
  listObject: boolean;
  previousAverage:number;
  typeObject: string;
  ReportDetailStartDate:string;

  ReportDetailEndDate:string
  MedianGradeLoading:boolean
  previousEndDate:string
  PreviousStartDate:string
  avatarHealth: any;
  avatarColor: any;
  avatarHealthIcon:any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MedianController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      value: 0,
      detailId: "",
      reportCategory: "weekly",
      drawerOpen: true,
      medianType: 'weekly',
      medianDataList: {
        average_grade:0,
        avg_grade_info_report:{},
        learning_report:{},
        statistics_report:{
          challenge_completed:0,
          completed_lessons:0,
          minutes_spent:0,
          total_xp:0
        }
      },
      startDate: '',
      previousAverage:0,
      endDate: '',
      dataChart: [],
      average: 0,
      learningData: [],
      ReportDetailData: [],
      loading: false,
      dateTime: "",
      listObject: false,
      ReportDetailEndDate:'',
      ReportDetailStartDate:"",
      typeObject: '',
      PreviousStartDate:'',
      previousEndDate:'',
      MedianGradeLoading:false,
      avatarHealth: 'healthy', 
      avatarColor: '',
      avatarHealthIcon: '',
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  medianData: string = "";
  getPreviousAverageEndPointId:string="";
  medianGradeDetailApiCall: string = "";
  getCurrentProfileId: string = ''

  async componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize);
    this.getFormattedWeekDates();
    this.getCurrentUserProfile()
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)

    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.getApiRequests(apiRequestCallId, responseJson)
    }
    if (apiRequestCallId === this.getCurrentProfileId) {
      this.setState({
        avatarHealth: responseJson?.data?.attributes?.avatar_health,
        avatarColor: responseJson?.data?.attributes?.favorite_color,
       })
       this.updateAvatarIcon();
    }
  }

  getFormattedWeekDates = () => {
    const currentPreviousWeek=new Date();
    const currentDate = new Date();

    currentPreviousWeek.setDate((currentDate.getDate()+1)-(currentDate.getDay()+1))
    currentDate.setDate((currentDate.getDate()+1) - currentDate.getDay());

    const startDate = currentDate.toLocaleDateString('en-GB').replace(/\//g, '-');

    const previousEndDate = currentPreviousWeek.toLocaleDateString('en-GB').replace(/\//g, '-');
  
    currentPreviousWeek.setDate(currentPreviousWeek.getDate() - 6);
    const PreviousStartDate=currentPreviousWeek.toLocaleDateString('en-GB').replace(/\//g, '-');
    currentDate.setDate(currentDate.getDate() + 6);

    const endDate = currentDate.toLocaleDateString('en-GB').replace(/\//g, '-');

    this.setState({
       startDate: startDate, 
       endDate: endDate, 
       previousEndDate:previousEndDate,
       PreviousStartDate:PreviousStartDate
      })
     
    setTimeout(() => {
      this.getMedianData(this.state.medianType)
      this.getPreviousAverage()
    }, 600)

  }

  getMonthDates = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formatDate = (date: Date) => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
  
    const getCurrentMonthStartDate = new Date(currentYear, currentMonth - 1, 1);
    const getCurrentMonthEndDate = new Date(currentYear, currentMonth, 0);

    const startDate = formatDate(getCurrentMonthStartDate)
    const endDate = formatDate(getCurrentMonthEndDate)

    getCurrentMonthStartDate.setMonth(getCurrentMonthStartDate.getMonth() - 1);
    getCurrentMonthEndDate.setMonth(getCurrentMonthEndDate.getMonth())
    getCurrentMonthEndDate.setDate(0);
    const PreviousStartDate = this.formatDate(getCurrentMonthStartDate)
    const previousEndDate = this.formatDate(getCurrentMonthEndDate)

    this.setState({
       startDate: startDate, 
       endDate: endDate,
       PreviousStartDate:PreviousStartDate,
       previousEndDate:previousEndDate
      
      })
    setTimeout(() => {
      this.getMedianData(this.state.medianType)
      this.getPreviousAverage()
    },600)
  }

  getCurrentYearDates() {
    const currentYear = new Date().getFullYear();

    const startDate = `01-01-${currentYear}`;
    const endDate = `31-12-${currentYear}`;

    const PreviousStartDate = `01-01-${currentYear-1}`
    const PreviousEndDate=`31-12-${currentYear-1}`


    this.setState({ 
       startDate: startDate,
       endDate: endDate ,
       PreviousStartDate:PreviousStartDate,
       previousEndDate:PreviousEndDate
      })
    setTimeout(() => {
      this.getMedianData(this.state.medianType)
    },600)
  }

  formatDateForType(date: Date) {
      return {
        stringDate: date.toLocaleDateString('en-US', { weekday: 'long', month: 'short', day: 'numeric' }),
        startDate: date.toLocaleDateString('en-GB').replace(/\//g, '-'),
        endDate: date.toLocaleDateString('en-GB').replace(/\//g, '-'),
        type: 'daily'
      }
  }

  generateCalendarWeeks = (startDateStr: string, endDateStr: string) => {
    const startDate = new Date(startDateStr.split("-").reverse().join("-"));
    const endDate = new Date(endDateStr.split("-").reverse().join("-"));
    const oneDay = 24 * 60 * 60 * 1000; // Milliseconds in one day
    let current = new Date(startDate);
    let weekNumber = 1;
    let calendarWeeks: CalendarWeeks = {};

    while (current <= endDate) {
      const weekKey:string = `Week${weekNumber}`;
      if (!calendarWeeks[weekKey]) {
        calendarWeeks[weekKey] = [];
      }

      calendarWeeks[weekKey].push(current.toISOString().slice(0, 10));

      const nextDay = new Date(current.getTime() + oneDay);
      if (current.getDay()=== 0) { // Saturday, considering weeks start on Sunday
        weekNumber++;
      }
      current = nextDay;
    }
    // Adjusting the format to DD-MM-YYYY for output
    for (let week in calendarWeeks) {
      calendarWeeks[week as keyof CalendarWeeks] = calendarWeeks[week as keyof CalendarWeeks].map((dateStr: string) => {
        const [yyyy, mm, dd] = dateStr.split("-");
        return `${dd}-${mm}-${yyyy}`;
      });
    }

    return calendarWeeks;
  }

  formDateForMonth = (startDate: Date, endDate: Date, weekNumber: number) => {
    return {
      stringDate: `Week ${weekNumber}, ${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}`,
      startDate: startDate.toLocaleDateString('en-GB').replace(/\//g, '-'),
      endDate: endDate.toLocaleDateString('en-GB').replace(/\//g, '-'),
      type: 'weekly'
    }
  }

  setAllData = (responseData: IMedianResponseData) => {

    let avgGradeInfoReport = responseData?.avg_grade_info_report;
    if (avgGradeInfoReport) {
     
      let dataChart = Object.values(avgGradeInfoReport).map((item: IAverageGrade) => item.grade);
      this.setState({
        dataChart:dataChart,
        average: responseData.average_grade,
      })

      let type = this.state.medianType;

      if (type === "weekly") {
        const chartData:number = dataChart.shift() as number
        dataChart.push(chartData)
        this.setState({
          dataChart:dataChart,
        })
        let currentDateStr = this.state.startDate.split('-');
        let currentDate = new Date(parseInt(currentDateStr[2]), parseInt(currentDateStr[1]) - 1, parseInt(currentDateStr[0]));
       
        const weekDataArray = Object.values(responseData?.learning_report as {[key: string]: ILearningReport})
        const temp = weekDataArray.shift() as ILearningReport
        weekDataArray.push(temp)
        const learningData = weekDataArray.map((value: ILearningReport) => {
          const formattedDate = this.formatDateForType(currentDate);
          const result = {
            dateTime: formattedDate?.stringDate,
            startDate: formattedDate?.startDate,
            endDate: formattedDate?.endDate,
            type: formattedDate?.type,
            Lessons: value.lessons,
            Grade: value.grade,
            Minutes: value.minutes,
            XPEarned: value.earned_xp,  
          };
          currentDate.setDate(currentDate.getDate() + 1)
          return result;
        });
        this.setState({   learningData: learningData });
      } else if (type === "monthly") {
        const calendarWeeks = this.generateCalendarWeeks(this.state.startDate, this.state.endDate)
        const learningData = Object.values(responseData?.learning_report as { [key: string]: ILearningReport }).map((value: ILearningReport , index: number) => {
          let date:string[] = calendarWeeks[`Week${index + 1}`]
          if(date){
          let startDateStr = date[0].split('-');
          let startDate = new Date(parseInt(startDateStr[2]), parseInt(startDateStr[1]) - 1, parseInt(startDateStr[0]));
          let endDateStr = date[date.length - 1].split('-');
          let endDate = new Date(parseInt(endDateStr[2]), parseInt(endDateStr[1]) - 1, parseInt(endDateStr[0]));
          const formattedDate: RangeDataType = this.formDateForMonth(startDate, endDate, index + 1);
          const result = {
            dateTime: formattedDate?.stringDate,
            startDate: formattedDate?.startDate,
            endDate: formattedDate?.endDate,
            type: formattedDate?.type,
            Lessons: value.lessons,
            Grade: value.grade,
            Minutes: value.minutes,
            XPEarned: value.earned_xp,
          };
          return result ; 
        }
        });
        const data : Array<ILearningData>=[]
         learningData.forEach(item=>{ 
          if(item!==undefined){
            data.push(item)
          }
         })

        this.setState({  learningData: data});

      }else if(type === "yearly"){
        let startDateStr =this.state.startDate.split('-');
          let startDate = new Date(parseInt(startDateStr[2]), parseInt(startDateStr[1]) - 1, parseInt(startDateStr[0]));
          let endDateStr = this.state.endDate.split('-');
          let endDate = new Date(parseInt(endDateStr[2]), parseInt(endDateStr[1]) - 1, parseInt(endDateStr[0]));
         const monthlydata:Array<IMonthData> = this.generateMonthlyData(startDate,endDate)
         const learningData = Object.values(responseData?.learning_report as { [key: string]: ILearningReport }).map((value: ILearningReport, index: number) => {
          const result = {
            dateTime: monthlydata[index]?.month,
            startDate: monthlydata[index]?.startDate,
            endDate: monthlydata[index]?.endDate,
            type: 'monthly',
            Lessons: value.lessons,
            Grade: value.grade,
            Minutes: value.minutes,
            XPEarned: value.earned_xp,
            YearData:monthlydata[index]
          };
          return result;
         })
         this.setState({   learningData: learningData });
      }

    } else {
      console.log("avg_grade_info_report is undefined or null");
    }
  };


   generateMonthlyData=(startDate:Date, endDate:Date)=>{
    const start = new Date(startDate);
    const end = new Date(endDate);
    let currentDate = start;
    const monthlyData = [];
  
    while (currentDate <= end) {
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
        // Format dates as "DD-MM-YYYY"
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        const formattedStart = `${startOfMonth.getDate().toString().padStart(2, '0')}-${(startOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${startOfMonth.getFullYear()}`;
        const formattedEnd = `${endOfMonth.getDate().toString().padStart(2, '0')}-${(endOfMonth.getMonth() + 1).toString().padStart(2, '0')}-${endOfMonth.getFullYear()}`;
        monthlyData.push({ startDate: formattedStart, endDate: formattedEnd , month:currentMonth });
        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return monthlyData;
}

  getPreviousAverage=()=>{
    const token = localStorage.getItem('token')
    const start_date = this.state.PreviousStartDate
    const end_date = this.state.previousEndDate
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getAvgGradeMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPreviousAverageEndPointId = getAvgGradeMsg.messageId;

    getAvgGradeMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAvgGradeMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.medianAPI}/average_grade?&start_date=${start_date}&end_date=${end_date}`

    );
    getAvgGradeMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAvgGradeMsg.id, getAvgGradeMsg);
  }

  getMedianData = (type: string) => {

    const token = localStorage.getItem('token')
    this.setState({
      MedianGradeLoading: true
    })
    const start_date = this.state.startDate
    const end_date = this.state.endDate
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.medianData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.medianAPI}?filter_type=${type}&start_date=${start_date}&end_date=${end_date}`

    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getApiRequests = (apiRequestCallId: string, responseJson: IMedianResponseData) => {
    if (apiRequestCallId === this.medianData) {
      if(!responseJson?.errors){
      this.setState({ medianDataList: responseJson, MedianGradeLoading:false})
      this.setAllData(responseJson)
      }else {
        console.log(responseJson)
    } 
  }

    if (apiRequestCallId === this.medianGradeDetailApiCall) {
      this.handleMedianGradeDetail(responseJson)
    }
    if(apiRequestCallId===this.getPreviousAverageEndPointId){
      this.handleAverageGrade(responseJson)
    }
  }


  handleAverageGrade=(responseJson:any)=>{
    if(!responseJson?.errors){
      this.setState({
       previousAverage:responseJson.average_grade
      })
    }
  }

  handleMedianGradeDetail = (responseJson: any) => {

    this.handleWeeklyAndMonthlyDetail(responseJson)
  }

   formatDate(date:Date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
}

   getDatesAndDays=()=> {
    let currentDateStr = this.state.ReportDetailStartDate.split('-');
    let currentDate = new Date(parseInt(currentDateStr[2]), parseInt(currentDateStr[1]) - 1, parseInt(currentDateStr[0]));
    let endDateStr = this.state.ReportDetailEndDate.split('-');
    const  endDate = new Date(parseInt(endDateStr[2]), parseInt(endDateStr[1]) - 1, parseInt(endDateStr[0]));
   
    let datesAndDays = [];
    while (currentDate <= endDate) {
        const formattedDate = this.formatDate(currentDate);
        const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
        datesAndDays.push( {startDate:formattedDate, endDate:formattedDate ,dayOfWeek: dayOfWeek.toUpperCase()});
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesAndDays;
}


  handleWeeklyAndMonthlyDetail = (responseJson: any) => {

    switch (this.state.typeObject) {
      case "daily":
        this.setState({
          ReportDetailData: responseJson,
          loading: false
        })

        break;

      case "weekly": {
        const label: DayIndexType = {
          "0": "SUNDAY",
          "1": "MONDAY",
          "2": "TUESDAY",
          "3": "WEDNESDAY",
          "4": "THURSDAY",
          "5": "FRIDAY",
          "6": "SATURDAY"
        }  

        const date =this.getDatesAndDays()

        const dataArray = Object.keys(responseJson).map(key => {
          const day = date.find(item=>item.dayOfWeek===label[key])
          if(day?.dayOfWeek===label[key]){
          return { id: key, ...responseJson[key], label: label[key],startDate:day.startDate,endDate:day.endDate};
          }else{
            return { id: key, ...responseJson[key], label: label[key]}
          }
        });
        const data = dataArray.shift()
         dataArray.push(data)
        this.setState({
          ReportDetailData: dataArray,
          loading: false
        })
      }
        break;

      case "monthly": {
        const weekDate = this.generateCalendarWeeks(this.state.ReportDetailStartDate, this.state.ReportDetailEndDate)
        
        const dataArray : Array<LessonDataArray> = Object.keys(responseJson as MainObject).map((key,index) => {
          const date = weekDate[`Week${index + 1}`]
          if(date){
          return { id: key, ...responseJson[key], label: `WEEK ${[key]}`,startDate:date[0] ,endDate:date[date.length-1]};
          }
        })

        this.setState({
          ReportDetailData: dataArray,
          loading: false
        })
      }
        break;

      default: {
        this.setState({
          loading: false
        })
      }

    }


  }




  handleResize = () => {
    this.setState({ drawerOpen: window.innerWidth > 960 });
  };
  handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      MedianGradeLoading:true
    })
    let type=""
    if (newValue === 0) {
      type = "weekly";
      this.getFormattedWeekDates();
    } else if (newValue === 1) {
      type = "monthly";
      this.getMonthDates();
    } else if (newValue === 2) {
      type = "yearly";
      this.getCurrentYearDates();
    }
    this.setState({ value: newValue, medianType: type });
  };

  handleViewDetail = (detailId: ILessontDetailData) => {

    this.getDetailsOfMedianGrade(detailId.type, detailId.startDate, detailId.endDate)
    this.setState({
      detailId: detailId.dataId,
      ReportDetailStartDate:detailId.startDate,
      ReportDetailEndDate:detailId.endDate,
      reportCategory: detailId.type,
      dateTime: detailId.dateTime
    });
  };
  handleBack = () => {
    this.setState({ detailId: "" });
  };

  moveOneWeekBack = (): void => {
    
    const parseDate = (dateStr: string): Date => new Date(dateStr.split('-').reverse().join('-'));
    const isValidDate = (date: Date): boolean => !isNaN(date.getTime());
    const subtractOneWeek = (date: Date): Date => new Date(date.setDate(date.getDate() - 7));
    const formatDate = (date: Date): string => new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');

    const isValid = (date1: Date, date2: Date): boolean => isValidDate(date1) && isValidDate(date2);
    this.setState({
      startDate:this.state.PreviousStartDate,
      endDate:this.state.previousEndDate
    })   
    const startDate: Date = parseDate(this.state.PreviousStartDate);
    const endDate: Date = parseDate(this.state.previousEndDate);

    if (isValid(startDate, endDate)) {
      const newStartDate = formatDate(subtractOneWeek(startDate));
      const newEndDate = formatDate(subtractOneWeek(endDate));
      this.setState({ 
          previousEndDate:newEndDate,
          PreviousStartDate:newStartDate,})
      setTimeout(() => {
        this.getMedianData(this.state.medianType)
        this.getPreviousAverage()
      }, 600)
    } else {
      console.log('Invalid input dates');
    }
  };

  moveOneWeekForward = (startDateStr: string, endDateStr: string): void => {
    this.setState({
      PreviousStartDate:startDateStr,
      previousEndDate:endDateStr
    })
    const parseDate = (dateStr: string): Date => new Date(dateStr.split('-').reverse().join('-'));
    const isValidDate = (date: Date): boolean => !isNaN(date.getTime());
    const addOneWeek = (date: Date): Date => new Date(date.setDate(date.getDate() + 7));
    const formatDate = (date: Date): string => new Intl.DateTimeFormat('en-GB').format(date).replace(/\//g, '-');

    const isValid = (date1: Date, date2: Date): boolean => isValidDate(date1) && isValidDate(date2);

    const startDate: Date = parseDate(startDateStr);
    const endDate: Date = parseDate(endDateStr);

    if (isValid(startDate, endDate)) {
      const newStartDate = formatDate(addOneWeek(startDate));
      const newEndDate = formatDate(addOneWeek(endDate));
      this.setState({ startDate: newStartDate, endDate: newEndDate })
      setTimeout(() => {
        this.getMedianData(this.state.medianType)
        this.getPreviousAverage()
      }, 1000)
    } else {
      console.log('Invalid input dates');
    }
  };


  getDetailsOfMedianGrade = (type: string, startDate: string, endDate: string) => {
    this.setState({
      typeObject: type,
      ReportDetailData:[],
      listObject: true
    })

    const token = localStorage.getItem('token')
    const start_date = startDate
    const end_date = endDate
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.medianGradeDetailApiCall = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.medianGradeDetailAPI}?filter_type=${type}&start_date=${start_date}&end_date=${end_date}`

    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getCurrentUserProfile = () => {
    const token = localStorage.getItem('token')
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  handleClickBack = (type:string) => {
    this.setState({
      MedianGradeLoading:true
    })
  if(type === "weekly"){
    this.moveOneWeekBack()
  }else if(type === "monthly"){
    this.setState({
      startDate:this.state.PreviousStartDate,
      endDate:this.state.previousEndDate
    })
    let startDateStr =this.state.PreviousStartDate.split('-');
    let startDate = new Date(parseInt(startDateStr[2]), parseInt(startDateStr[1]) - 1, parseInt(startDateStr[0]));
    let endDateStr = this.state.previousEndDate.split('-');
    let endDate = new Date(parseInt(endDateStr[2]), parseInt(endDateStr[1]) - 1, parseInt(endDateStr[0]));
    startDate.setMonth(startDate.getMonth() - 1);
    endDate.setDate(0);
    const startdata = this.formatDate(startDate)
    const endData = this.formatDate(endDate)
    this.setState({
      previousEndDate:endData,
      PreviousStartDate:startdata
    })
    setTimeout(()=>{
      this.getMedianData(type)
      this.getPreviousAverage()
    },800)

  }else if(type==="yearly"){
    this.setState({
      startDate:this.state.PreviousStartDate,
      endDate:this.state.previousEndDate
    })
    let startDateStr =this.state.PreviousStartDate.split('-');
    const startDate= `${parseInt(startDateStr[0])}-${parseInt(startDateStr[1])}-${parseInt(startDateStr[2])-1}`
    let endDateStr = this.state.previousEndDate.split('-');
    const endDate = `${parseInt(endDateStr[0])}-${parseInt(endDateStr[1])}-${parseInt(endDateStr[2])-1}`
    this.setState({
      PreviousStartDate:startDate,
      previousEndDate:endDate
    })
    setTimeout(()=>{
      this.getMedianData(type)
      this.getPreviousAverage()
    },800)
  }
  }

  handleClickNext = (type:string) => {
    this.setState({
      MedianGradeLoading:true
    })
   if(type==="weekly"){
    this.moveOneWeekForward(this.state.startDate, this.state.endDate)
   }else if(type === "monthly"){
    this.setState({
      PreviousStartDate:this.state.startDate,
      previousEndDate:this.state.endDate
    })
    let startDateStr =this.state.startDate.split('-');
    let startDate = new Date(parseInt(startDateStr[2]), parseInt(startDateStr[1]) - 1, parseInt(startDateStr[0]));
    let endDateStr = this.state.endDate.split('-');
    let endDate = new Date(parseInt(endDateStr[2]), parseInt(endDateStr[1]) - 1, parseInt(endDateStr[0]));
    startDate.setMonth(startDate.getMonth() + 1);
    endDate.setMonth(endDate.getMonth()+2)
    endDate.setDate(0);
    const startdata = this.formatDate(startDate)
    const endData = this.formatDate(endDate)
    this.setState({
      startDate:startdata,
      endDate:endData
    })
    setTimeout(()=>{
      this.getMedianData(type)
      this.getPreviousAverage()
    },800)

   }else if(type==="yearly"){
    this.setState({
      PreviousStartDate:this.state.startDate,
      previousEndDate:this.state.endDate
    })
    let startDateStr =this.state.startDate.split('-');
    const startDate= `${parseInt(startDateStr[0])}-${parseInt(startDateStr[1])}-${parseInt(startDateStr[2])+1}`
    let endDateStr = this.state.endDate.split('-');
    const endDate = `${parseInt(endDateStr[0])}-${parseInt(endDateStr[1])}-${parseInt(endDateStr[2])+1}`
    this.setState({
      startDate,endDate
    })
    setTimeout(()=>{
      this.getMedianData(type)
      this.getPreviousAverage()
    },800)
  }
   
  }

  getColorMoodMap = () => {
    return {
        '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },
        '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
        '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
        '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
        '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
        '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
        '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
        '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar}
    };
};

updateAvatarIcon() {
  const { avatarColor, avatarHealth } = this.state;

  const colorMoodMap = this.getColorMoodMap();

  const avatarIcon = (avatarColor !== undefined && avatarHealth !== undefined) 
  ? ((colorMoodMap as any)[avatarColor]?.[avatarHealth] || null) 
  : blueHealthy;

      this.setState({ avatarHealthIcon: avatarIcon });
}

  // Customizable Area End
}
