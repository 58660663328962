import React from "react";
import {
    // Customizable Area Start
    Box, Typography, Button, Container, Grid, Card, Dialog,
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import EmailRegistrationSuccessControllerWeb, {
    Props,
} from "./EmailRegistrationController.web";
import { tedixIcon, pandaSuccess, pandaTedix, sucessBackgroung } from './assets';
import CloseIcon from '@material-ui/icons/Close';
import SelectLanguage from "../../../components/src/SelectLanguage";
import { withTranslation } from "react-i18next";
import Header from "../../../components/src/Header";
// Customizable Area End

 class EmailRegistrationSuccess extends EmailRegistrationSuccessControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <EmailSuccessStyle>
                    <Header />
                    <Container maxWidth="xs">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                            }}
                        >
                            <div>
                                <Grid container spacing={0} style={{ alignItems: 'center' }}>
                                    <Grid xs >
                                        <div className="welcomeAmy">{this.props.t("Welcome")}, {this.state.emailSignUpAddress.userName}!</div>
                                    </Grid>
                                </Grid>
                            </div>
                            <Typography className="signupSuccessful">{this.props.t("Your sign-up was successful")}</Typography>
                            <div style={{ margin: '35px 0' }}>
                                <Grid container spacing={0} style={{ alignItems: 'center' }}>
                                    <Grid xs className="plus">
                                        +
                                    </Grid>
                                    <Grid xs className="mines">
                                        -
                                    </Grid>
                                    <Grid xs className="cros">
                                        +
                                    </Grid>
                                    <Grid xs className="division">
                                        ÷
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ margin: '0 0 136px 0' }}>
                                <img src={this.state.partyTeddyBear} width="163px" height="165px" alt="pandaSuccess" />
                            </div>
                            <div >
                                <Button variant="contained" className="start-learning" onClick={() => this.handleOpen()}>{this.props.t("Start learning!")}</Button>
                             </div>
                        </Box>
                        <div style={{display:'flex',justifyContent:'center',marginTop:'30px'}}><SelectLanguage/></div>
                        <div>
                            <Dialog
                                open={this.state.open}
                                onClose={() => this.handleClose()}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                PaperProps={{ style: { boxShadow: 'none', borderRadius: '12px' } }}
                            >
                                <CardStyle>
                                    <Card className="installCard">
                                        <div className="handleClose" onClick={() => this.handleClose()}>
                                            <CloseIcon className='closeIcon' />
                                        </div>
                                        <div className="iconInstll">
                                            <img src={pandaTedix} alt="pandaTedix" />
                                            <div className='app-challenges'>
                                                <div className='installApp'>
                                                    {this.props.t("Install the app")}
                                                </div>
                                                <div className="challenges-premium">
                                                    {this.props.t("Embrace challenges and unlock the PREMIUM experience")}!
                                                </div>
                                            </div>
                                        </div>
                                        <div className='btn'>
                                            <Button variant="contained" className="cancel-btn" onClick={() => this.handleClose()}>{this.props.t("Cancel")}</Button>
                                            <Button variant="contained" className="install-btn" onClick={()=>this.installApp()}>{this.props.t("Install")}</Button>
                                        </div>
                                    </Card>
                                </CardStyle>
                            </Dialog>
                        </div>
                    </Container>
                </EmailSuccessStyle>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
export default withTranslation()(EmailRegistrationSuccess)

const EmailSuccessStyle = styled('div')({
    backgroundImage: `url(${sucessBackgroung})`,
    
    "& .welcomeAmy": {
        color: "#363636",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        padding: '20px 50px 0px 50px',
        "@media (max-width:600px)": {
            fontSize: "22px",
        }
    },
    "& .start-learning": {
        borderRadius: "12px",
        background: "#36BFFF",
        textTransform: 'none',
        color: "#FFF",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.8px",
        height: '44px',
        boxShadow: '0px 4px #00AEFF',
        width:'400px',
        "& span": {
            fontFamily: "Nunito",
        },
        "@media (max-width:600px)": {
            fontSize: "16px",
            width:'260px',
        }
    },
    "& .plus": {
        color: "#FF8400",
        lineHeight: "17px",
        fontWeight: 700,
        fontSize: "40px",
        fontFamily: "Nunito",
        fontStyle: "normal",
        textAlign: "center",
        margin: '90px 0 0 0'
    },
    "& .mines": {
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        textAlign: "center",
        color: "#2BCE8C",
        fontFamily: "Nunito",
        lineHeight: "17px"
    },
    "& .cros": {
        color: "#A95EDB",
        fontFamily: "Nunito",
        fontWeight: 700,
        fontStyle: "normal",
        textAlign: "center",
        transform: "rotate(-45deg)",
        fontSize: "40px",
        lineHeight: "17px",
    },
    "& .division": {
        lineHeight: "17px",
        fontSize: "40px",
        fontStyle: "normal",
        color: "#F96855",
        textAlign: "center",
        fontFamily: "Nunito",
        fontWeight: 700,
        margin: '90px 0 0 0'
    },
    "& .signupSuccessful":{
        color: "#666",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        "@media (max-width:600px)": {
        fontSize: "16px",
        }
    }
    
})

const CardStyle = styled('div')({

    "& .challenges-premium": {
        color: "#666",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "22.5px",
        width: '266px',
        "@media (max-width:600px)": {
            width: '240px',
            fontSize:'15px'
        }
    },
    "& .installApp": {
        color: "#363636",
        fontFamily: "Nunito",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "20.5px",
        margin:'0px 0 10px 0'
    },
    "&  .install-btn": {
        borderRadius: '12px',
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "16px",
        letterSpacing: "0.8px",
        fontWeight: 700,
        fontStyle: "normal",
        color: "white",
        backgroundColor: '#36BFFF',
        width: '110px',
        boxShadow: '0px 4px #00AEFF',
        textTransform: 'none',
    },
    "& .cancel-btn": {
        letterSpacing: "0.8px",
        borderRadius: "12px",
        background: "#FFF",
        fontWeight: 700,
        width: '110px',
        boxShadow: '0px 2px #999999',
        color: "#7F7F7F",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        border: "2px solid #999",
        textTransform: 'none',
        padding: '6px 0',
    },
    "& .installCard":{
        backgroundColor: "#F7F7F7",
        borderRadius: "12px",
        border: "2px solid  #E6E6E6",
        background: "#F7F7F7",
        boxShadow: "4px 4px 6px 0px rgba(0, 0, 0, 0.10)",
        width:'467px',
        height:'212px',
        "@media (max-width:600px)": {
            width:'292px',
            height:'275px',
        },
        "@media (max-width:360px)": {
            width:'auto',
        }
    },
    "& .closeIcon":{
        position: 'absolute', 
        right: "25px", 
        top: '22px', 
        color: '#CCCCCC', 
        cursor: 'pointer',
        "@media (max-width:600px)": {
            display:'none'
        }
    },
    "& .btn":{
        display: 'flex', 
        justifyContent: 'space-around', 
        margin: '0px 0px 0px 75px',
        width:'280px',
        "@media (max-width:600px)": {
            width:'100%',
            justifyContent:'space-evenly',
            margin:'10px 0 0 0'
        }
    },
    "& .iconInstll":{
        display: 'flex', 
        alignItems: 'center',
        margin:'20px 0 0px 0',
        "@media (max-width:600px)": {
            display:'block',
            textAlign:'center',
            margin:'0px 0 0px 0',
        }
    },
    "& .app-challenges":{
        "@media (max-width:600px)": {
            display:'flex',
            flexDirection:'column',
            alignItems:'center'
        }
    },
    


})
  // Customizable Area End