import React from "react";
// Customizable Area Start
import { Box, Typography, styled, IconButton, Card } from "@material-ui/core";
import {
  buttonShow,
  buttonHide,
  free,
  skipTime,
  skipGrade,
  loader
} from "./assets";

import { withTranslation } from "react-i18next";
import ReportLessonPageController,{ILessonDetails,LessonAttributes} from "./ReportLessonPageController.web";
import { MathFormula } from "../../../components/src/MicrolessonExercise/ExerciseComponents";
import { convertLatex } from "../../../components/src/utils";

// Customizable Area End

class ReportLessonPage extends ReportLessonPageController {
  // Customizable Area Start

  QuestionType = (item:LessonAttributes ) => {
    switch (item.question_type) {
      case 'sequence':
        return this.handleSequenceType(item)

      case 'fill_in_the_blank':
        return this.handleFillInTheBlankType(item)

      case  "true_false":
        return this.handleTrueFlase(item)
      
      case  "match_the_column" :
        return this.handleMatchingType(item) 

      case "mcq":
        return this.handleMCQType(item)
    }

 

  }

   isIndexValid=(index:number,getCorrectAnswerArray:any)=> {
    return index >= 0 && index < getCorrectAnswerArray.length;
  }

  handleSequenceType = (answer: LessonAttributes) => {
    const correctAnswer = answer.correct_answer
    const userAnswer =answer.user_answer
    const getCorrectAnswerArray = correctAnswer.split("(split-space)")
    const getUserAnswerArray = userAnswer.split("(split-space)").map((item:string) => {
      return JSON.parse(item).text
    })
    return (
      <Box className="report_Lesson_Answer__Page__title">
        <Box className="report_Page_Lesson_Answer--first align-left">{answer.index}</Box>
        <Box className="report_Page_Lesson_Answer--second">
          <Box style={{ display: 'flex', justifyContent: 'center',flexWrap:"wrap",alignItems:'center' }}>
            {
              getCorrectAnswerArray.map((item: string, index: number) => {
                const isLastItem = index === getCorrectAnswerArray.length - 1
                const separator = isLastItem ? " and " : ", ";
                return (   
                  <Typography key={index}>
                    {index>0 && <Typography style={{margin:"0px 4px 0px 0px"}}>{separator}</Typography>}
                    <MathFormula 
                    formula={convertLatex(item)} 
                    color={"#999999"} 
                    fontSize={14} 
                    fontWeight={600}
                    fontFamily={"Quicksand"} />
                  </Typography>
                )
              })
            }
          </Box>

        </Box>
        <Box className="report_Page_Lesson_Answer--third">
          <Box style={{ display: 'flex', justifyContent: 'center',flexWrap:"wrap",alignItems:'center'}} className="main">
            {
              getUserAnswerArray.map((item: string, index: number) => {
                const isLastItem = index === getUserAnswerArray.length - 1
                const separator = isLastItem ? " and " : ", ";
                return (
                  
                  <Typography key={index} className={this.isIndexValid(index,getCorrectAnswerArray)&&item === getCorrectAnswerArray[index] ? "answer_True" : "answer_Wrong"}>
                   {index>0 && <Typography style={{margin:"0px 4px 0px 0px"}}>{separator}</Typography>}
                    <MathFormula 
                    formula={convertLatex(item)} 
                    color={item === getCorrectAnswerArray[index] ? "#2BCE8C" : "#F96855"} 
                    fontSize={14} 
                    fontWeight={600} 
                    fontFamily={"Quicksand"} />
                  </Typography>
                )
              })
            }
          </Box>
        </Box>
      </Box>
    )
  }

  handleMatchingType = (answer: LessonAttributes) => {
    const correctAnswer = answer.correct_answer
    const userAnswer =answer.user_answer
    const getCorrectAnswerArray = correctAnswer.split("(split-space)")
    const getUserAnswerArray = userAnswer.split("(split-space)")
    return (
      <Box className="report_Lesson_Answer__Page__title">
        <Box className="report_Page_Lesson_Answer--first align-left">{answer.index}</Box>
        <Box className="report_Page_Lesson_Answer--second">
          {getCorrectAnswerArray.map((item: string, index: number) => {
            const name = JSON.parse(item)
            return (
              <Typography key={index}>{name[`Op ${index + 1}`]}</Typography>
            )
          })}
        </Box>
        <Box className="report_Page_Lesson_Answer--third">
          {getUserAnswerArray.map((item: string, index: number) => {
            const name = JSON.parse(item)
            return (
              <Typography key={index} className={item === getCorrectAnswerArray[index] ? "answer_True" : "answer_Wrong"}>{name[`Op ${index + 1}`]}</Typography>
            )
          })}
        </Box>
      </Box>
    )
  }

  handleFillInTheBlankType = (answer: LessonAttributes) => {
    const correctAnswer = answer.correct_answer
    const userAnswer =answer.user_answer
   const getUserAnswer = JSON.parse(userAnswer).text
   return(
         <Box className="report_Lesson_Answer__Page__title">
        <Box className="report_Page_Lesson_Answer--first">{answer.index}</Box>
        <Box className="report_Page_Lesson_Answer--second">
          <Typography>{correctAnswer}</Typography>
        </Box>
        <Box className="report_Page_Lesson_Answer--third">
          <Typography className={correctAnswer === getUserAnswer ? "answer_True" : "answer_Wrong"}>{getUserAnswer}</Typography>
        </Box>
      </Box>
   )
  }

  handleTrueFlase = (answer:LessonAttributes) => {
    const correctAnswer = answer.correct_answer
    const userAnswer =answer.user_answer
    const getCorrectAnswer = JSON.parse(correctAnswer).text
    const getUserAnswer = JSON.parse(userAnswer).text
    return (
      <Box className="report_Lesson_Answer__Page__title">
        <Box className="report_Page_Lesson_Answer--first">{answer.index}</Box>
        <Box className="report_Page_Lesson_Answer--second">
          <Typography>{getCorrectAnswer}</Typography>
        </Box>
        <Box className="report_Page_Lesson_Answer--third">
          <Typography className={getCorrectAnswer === getUserAnswer ? "answer_True" : "answer_Wrong"}>{getUserAnswer}</Typography>
        </Box>
      </Box>
    )

  }


  handleMCQType=(answer:LessonAttributes)=>{
    const correctAnswer = answer.correct_answer
    const userAnswer =answer.user_answer
    const getCorrectAnswer = correctAnswer.split("(split-space)")
    const getUserAnswer=userAnswer.split("(split-space)")
    const getCorrectAnswerArray = getCorrectAnswer.map((item:string)=>JSON.parse(item).text)
    const getUserAnswerArray = getUserAnswer.map((item:string)=>JSON.parse(item).text)

    return (
      <Box className="report_Lesson_Answer__Page__title">
        <Box className="report_Page_Lesson_Answer--first align-left">
          {answer.index}
          </Box>
        <Box className="report_Page_Lesson_Answer--second">
          <Box style={{  justifyContent: 'center',display: 'flex',flexWrap:"wrap",alignItems:"center" }}>
            {
              getCorrectAnswerArray.map((item: string, index: number) => {
                const isLastItem = index === getCorrectAnswerArray.length - 1
                const separator = isLastItem ? "and" : ",";
                return (
                  <Typography key={index}>
                    {index>0 && <Typography style={{margin:"0px 4px 0px 0px"}}className="seprator">{separator}</Typography>}
                    <MathFormula formula={convertLatex(item)}fontWeight={600}color={"#999999"} fontSize={14} fontFamily={"Quicksand"} />
                  </Typography>
                )
              })
            }
          </Box>

        </Box>
        <Box className="report_Page_Lesson_Answer--third">
          <Box style={{justifyContent: 'center', display: 'flex', flexWrap:"wrap",alignItems:"center" }} className="main">
            {
              getUserAnswerArray.map((item: string, index: number) => {
                const isLastItem = index === getUserAnswerArray.length - 1
                const separator = isLastItem ? " and " : ", ";
                return (
                  <Typography className={this.isIndexValid(index,getCorrectAnswerArray)&&item === getCorrectAnswerArray[index] ? "answer_True" : "answer_Wrong"} key={index} >
                    {index>0 && <Typography style={{margin:"0px 4px 0px 0px"}}>{separator}</Typography>}
                    <MathFormula formula={convertLatex(item)} fontSize={14} fontWeight={600} color={item === getCorrectAnswerArray[index] ? "#2BCE8C" : "#F96855"}  fontFamily={"Quicksand"} />
                  </Typography>
                )
              })
            }
          </Box>
        </Box>
      </Box>
    )

  }

  getClass=()=>{
    return this.props.isOpen === `show${this.props.index}`? "toogleShow" : ""
  }

  renderReportLesson = () => {
    if (this.state.loading) {
      return (
        <Box className="loaderBox" >
          <img src={loader} alt="Loading" />
        </Box>
      )
    }
    if (this.state.reportLesson.length === 0) {
      return null
    }
    return (
      <Box className="reportLesson__Page__description">
        <Typography>{this.props.t("Description:")}</Typography>
        <Typography className="reportLesson__Page__description-text">
          {this.props.Lessondata?.description}
        </Typography>

        <Box className="reportLesson__Page__answer">
          <Box className="report_Lesson_Answer__Page__title">
            <Box className="report_Page_Lesson_Answer--first" />
            <Typography
              component="div"
              className="report_Page_Lesson_Answer--second fontTitleAnswer"
            >
              {this.props.t("Correct Answer")}
            </Typography>
            <Typography
              component="div"
              className="report_Page_Lesson_Answer--third fontTitleAnswer"
            >
              {this.props.t("Your answer")}
            </Typography>
          </Box>
          {this.state.reportLesson.map((item:ILessonDetails,index:number)=>{
            return this.QuestionType({...item.attributes,index:index+1})
          })}
          <Box className="report_Page_Lesson_Answer__border" />
        </Box>
      </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log(this.props.Lessondata,"this.props.LessonData")
    // Customizable Area End
    return (
      // Customizable Area Start
      <CardStyle>
        <Box className="reportLesson_Page__header">
          <Typography>{this.props.Lessondata?.name}</Typography>
          <IconButton
            data-testid="btnToogle"
            onClick={()=>this.handleShowToggle()}
          >
            <img
              src={this.props.isOpen === `show${this.props.index}` ? buttonHide : buttonShow}
              width={18}
              height={18}
            />
          </IconButton>
        </Box>
        <Box className="reportLesson_Page__title">
          <Typography>{this.props.Lessondata?.content}</Typography>
        </Box>
      
        {this.props.isOpen === `show${this.props.index}` && (
          this.renderReportLesson()
        )}
        <Box className={`reportLesson__footer ${this.getClass}`}>
          <Box className="reportLesson__footer__item">
            <img src={free} height={18} />
            <Typography>{this.props.Lessondata.earned_xp} XP</Typography>
          </Box>
          <Box className="reportLesson__footer__item">
            <img src={skipTime} height={18} />
            <Typography>{this.props.Lessondata.minutes} MIN</Typography>
          </Box>
          <Box className="reportLesson__footer__item">
            <img src={skipGrade} height={18} />
            <Typography>{this.props.Lessondata.grade}</Typography>
          </Box>
        </Box>
      </CardStyle>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export default withTranslation()(ReportLessonPage)

const CardStyle = styled(Card)(() => ({
  padding: "20px 20px 0 20px",
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  backgroundColor: "#FFFFFF",
  border: "2px solid #F2F2F2",
  borderRadius: "16px",
  width: "100%",
  "& .reportLesson_Page__header button": {
    marginRight: "-4px",
    padding: "4px",
  },
  "& .reportLesson_Page__header": {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between"
  },
  "& .reportLesson_Page__header, .MuiTypography-body1": {
    fontFamily: "Nunito",
    color: "#00AEFF",
    fontWeight: 900,
    fontSize: "16px",
  },
  "& .report_Lesson_Answer__Page__title": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginBottom: "18px"
  },
  "& .report_Lesson_Answer__Page__title .fontTitleAnswer.MuiTypography-body1": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#CCC"
  },
  "& .reportLesson_Page__title .MuiTypography-body1": {
    fontSize: "14px",
    fontFamily: "Quicksand",
    fontWeight: 700,
    color: "#999999"
  },
  "& .report_Lesson_Answer__Page__title .report_Page_Lesson_Answer--first": {
    fontSize: "16px",
    fontWeight: 700,
    color: "#CCCCCC"
  },
  "& .reportLesson__Page__description .reportLesson__Page__description-text": {
    fontWeight: 500,
    lineHeight: "15px"
  },
  "& .reportLesson__Page__description .MuiTypography-body1": {
    fontFamily: "Quicksand",
    color: "#999999",
    fontWeight: 600,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    margin: "0px 4px",
    alignItems:'center'
  },
  "& .seprator":{
   margin:"0px 4px 0px 0px"
  },
  "& .report_Page_Lesson_Answer__border": {
    borderWidth: "1px 0px 0px 1px",
    borderRadius: "16px 0 0 0",
    height: "calc(80% + 24px)",
    borderStyle: "solid",
    top: "20%",
    position: "absolute",
    borderColor: "#F2F2F2",
    width: "90%",
    left: "10%"
  },
  "& .report_Page_Lesson_Answer--first": {
    flex: "0 0 10%",
    fontFamily: "Nunito",
    fontWeight: 700,
    maxWidth: "10%",
    fontSize: "16px",
    color: "#CCCCCC"
  },
  "& .report_Page_Lesson_Answer__title": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    textAlign: "center",
    marginBottom: "18px"
  },
  "& .report_Page_Lesson_Answer--third > .main > .answer_True": {
    color: "#2BCE8C !important"
  },

  "& .report_Page_Lesson_Answer--third .answer_True": {
    color: "#2BCE8C !important"
  },
  "& .loaderBox":{
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%'
  },
  "& .report_Page_Lesson_Answer__title .MuiTypography-body1": {
    fontSize: "16px",
    fontWeight: 600,
    color: "#999999"
  },
  "& .reportLesson__Page__answer": {
    margin: "24px 0px",
    fontWeight: 700,
    fontSize: "14px",
    position: "relative",
    fontFamily: "Quicksand",
  },
  "& .report_Page_Lesson_Answer--third > .main > .answer_Wrong": {
    color: "#F96855 !important"
  },
  "& .report_Page_Lesson_Answer--third .answer_Wrong": {
    color: "#F96855 !important"
  },
  "& .report_Page_Lesson_Answer--third": {
    maxWidth: "45%",
    flex: "0 0 45%",
  },
  "& .reportLesson__footer": {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0"
  },
  "& .report_Page_Lesson_Answer--second": {
    flex: "0 0 45%",
    maxWidth: "45%"
  },

  "& .reportLesson__footer__item": {
    display: "flex",
    alignItems: "center"
  },
  "& .reportLesson__footer__item img": {
    margin: "0 12px"
  },
  "& .reportLesson__footer.toogleShow": {
    color: "#F2F2F2",
    padding: "12px",
    background: "#36BFFF",
    margin: "0 -20px"
  },
  "& .toogleShow > .reportLesson__footer__item .MuiTypography-body1": {
    fontWeight: 900,
    fontSize: "16px",
    fontFamily: "Nunito",
    color: "#fff"
  },
  "& .reportLesson__footer__item .MuiTypography-body1": {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 900,
    color: "#363636"
  },
}));
// Customizable Area End