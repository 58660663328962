// Customizable Area Start
import React from 'react'
import { Box, styled, Typography } from '@material-ui/core'
import GuestScoreController, { Props } from './GuestScoreController'
import Header from '../../../components/src/Header'
import { celebrateTeddy, guestPanner, guestScoreBg } from './assets'
import { ButtonCustom } from '../../../components/src/MaterialTheme'
import { withTranslation } from 'react-i18next'
import { bolt } from '../../assessmenttest/src/assets'

class GuestScore extends GuestScoreController {
    constructor(props: Props) {
        super(props)
    }

    handleGoBack = () => {
        this.props.navigation.goBack()
    }

    handleClickContinue = () => {
        this.props.navigation.navigate('GuestKeepTrackProgress')
    }

    render() {
        return (
            <GuestScoreContainer>
                <Header showGoBackBtn onGoBack={this.handleGoBack} />
                <Box className='mainContainer'>
                    <Box className='titleContainer'>
                        <img src={celebrateTeddy} alt='Celebrate Teddy' className='celebrateTeddy' />
                        <Box className='titleWrapper'>
                            <Typography className='titleText'>{this.props.t('Lesson')}</Typography>
                            <Typography className='titleText bigTitlteText'>{this.props.t('Completed')}</Typography>
                        </Box>
                    </Box>
                    <SummaryCard title="Total XP" value={`${this.state.guestXP} XP`} />
                    <ButtonCustom
                        variant="contained"
                        className='continueButton'
                        onClick={this.handleClickContinue}
                    >
                        {this.props.t('Continue')}
                    </ButtonCustom>
                </Box>
            </GuestScoreContainer>
        )
    }
}

export default withTranslation()(GuestScore)

const SummaryCard = ({
    value,
    title,
}: {
    value: string
    title: string
}) => {
    return (
        <SummaryCardContainer>
            <Typography className="summaryTitle">
                {title}
            </Typography>
            <Box className="summaryExpWrapper">
                <img
                    src={bolt}
                    width={18}
                    height={23}
                />
                <Typography className="summaryExpText">
                    {value}
                </Typography>
            </Box>
        </SummaryCardContainer>
    )
}

const GuestScoreContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .mainContainer': {
        width: '100%',
        height: '100%',
        maxWidth: '960px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${guestScoreBg})`,
        backgroundSize: '100% 100%',
        marginBottom: '40px',
        padding: '100px 26px 0',
        justifyContent: 'space-between',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            padding: '100px 60px 0',
        }
    },

    '& .titleContainer': {
        position: 'relative',
    },

    '& .celebrateTeddy': {
        position: 'absolute',
        width: '130px',
        height: 'auto',
        top: 0,
        left: '50%',
        transform: 'translate(-50%, -65%)',
        zIndex: -1
    },

    '& .titleWrapper': {
        backgroundImage: `url(${guestPanner})`,
        backgroundSize: '100% 100%',
        width: '300px',
        height: '112px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    '& .titleText': {
        fontFamily: 'Nunito',
        fontWeight: 900,
        fontSize: '20px',
        color: '#fff',
        lineHeight: '30px',
        textTransform: 'uppercase',
    },

    '& .bigTitlteText': {
        fontSize: '30px'
    }
}))

const SummaryCardContainer = styled(Box)(({ theme }) => ({
    borderRadius: '12px',
    backgroundColor: '#00AEFF',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    '& .summaryTitle': {
        fontSize: '16px',
        fontFamily: 'Nunito',
        fontWeight: 900,
        marginBottom: '2px',
        marginTop: '6px',
        color: '#fff',
    },

    '& .summaryExpWrapper': {
        padding: '12px 36px',
        backgroundColor: '#fff',
        borderWidth: '2px',
        borderRadius: '12px',
        display: 'flex',
        borderStyle: 'solid',
        alignItems: 'center',
        gap: '8px',
        borderColor: '#00AEFF',
    },

    '& .summaryExpText': {
        fontFamily: 'Nunito',
        fontSize: '20px',
        color: '#363636',
        fontWeight: 900,
    },
}))
// Customizable Area End
