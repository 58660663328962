// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { Props, configJSON } from "./StripePayments";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";

interface S {
    selectedPlan: any;
    planList: Array<any>
    loading: boolean;
}

interface SS {
    id: string;
    getListPlanCallId: string;
}

export default class PremiumCheckoutController extends BlockComponent<Props, S, SS> {
    getListPlanCallId: string = ''

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        this.getRenewDate = this.getRenewDate.bind(this);
        this.handleClickCancel = this.handleClickCancel.bind(this);
        this.handleClickTerms = this.handleClickTerms.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            selectedPlan: {},
            planList: [],
            loading: true,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleClickCancel() {
        this.props.navigation.navigate("StripePaymentsView")
    }

    getListPlan(token: string) {
        this.getListPlanCallId = sendAPIRequest(
            configJSON.getListPlanEndPoint,
            {
                method: configJSON.getListPlanMethod,
                headers: {
                    "Content-Type": configJSON.apiContentType,
                    token
                }
            }
        )
    }

    getRenewDate() {
        if (this.state.selectedPlan && this.state.selectedPlan.attributes) {
            const today = new Date();
            const { interval, interval_count } = this.state.selectedPlan.attributes;

            if (interval === "year") {
                today.setFullYear(today.getFullYear() + 1);
                return `${this.props.t("Your subscription is set to renew on")} ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
            }

            today.setMonth(today.getMonth() + interval_count);
            return `${this.props.t("Your subscription is set to renew on")} ${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
        }

        return ''
    }

    getPriceDetail(plan: any) {
        const perMonth = plan.attributes.interval === 'year'
            ? plan.attributes.price_cents / 12
            : plan.attributes.price_cents / plan.attributes.interval_count;

        const formattedPerMonth = perMonth.toFixed(2).replace('.', ',');

        const perMonthString = formattedPerMonth.endsWith('00') ? formattedPerMonth.slice(0, -3) : formattedPerMonth;

        return {
            total: `${plan.attributes.interval_count} ${plan.attributes.interval} • ${plan.attributes.price_cents} €`,
            perMonth: `${perMonthString} € / month`
        };
    }

    handleClickTerms() {
        this.props.navigation.navigate("TermsConditions")
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        )

        if (apiRequestCallId === this.getListPlanCallId) {
            this.setState({
                planList: responseJson?.data,
                selectedPlan: responseJson?.data[0]
            })

            this.setState({ loading: false })

            localStorage.setItem("planId", responseJson?.data[0]?.id || 0)
            localStorage.setItem("amountToPay", responseJson.data[0].attributes.price_cents)
        }
    }

    async componentDidMount() {
        const token = localStorage.getItem("token") || ""
        this.getListPlan(token);
    }
}

// Customizable Area End