import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start

import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../components/src/utils";
import {parsePhoneNumberFromString} from "libphonenumber-js"
import { Message } from "framework/src/Message";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { TFunction } from "i18next";
import { ICountry } from "../../accountgroups/src/EditAccountController";

// Customizable Area End

export const configJSON = require("./config");

export interface Props{
  // Customizable Area Start
  navigation:any
  t:TFunction
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  parentPhone:string
  countriesData: Array<Country>
  parentPhoneCountryCode:ICountry|undefined
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface Country {
  id: string
  type: string
  attributes: {
    name: string
    country_code: string
    alpha2: string
    alpha3: string
  }
}

interface ICoutryResponse{
  data:Array<Country>,
  errors?:{}
}
// Customizable Area End



export default class ParentSmsController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    getCountriesId:string=""
    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      // Customizable Area Start
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      this.state = {
        parentPhone:'',
        countriesData:[],
        parentPhoneCountryCode:undefined
      };
      // Customizable Area End
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

   // Customizable Area Start

   componentDidMount=async()=> {
    this.getCountries()
    const queryParams = new URLSearchParams(window.location.search);
    const parentPhone = queryParams.get('parent_phone');  
    if(parentPhone){
      this.setState({
        parentPhone:this.getFormatedPhonenNumber(`+${parentPhone}`)
      })
      setStorageData("parentPhone",JSON.stringify({parentPhone:"+"+parentPhone.toString()}))
    }else{
      const getparentPhone = await getStorageData("parentPhone",true)
      if(getparentPhone?.parentPhone){
        this.setState({
          parentPhone:this.getFormatedPhonenNumber(getparentPhone?.parentPhone)
        })
      }
    }

   }


   getFormatedPhonenNumber=(number:string)=>{
    const phoneNumber = parsePhoneNumberFromString(number)
    if(phoneNumber){
      const formattedNumber = phoneNumber.formatInternational() 
      return formattedNumber
    }else{
      return number
    }
  }

   handleFormSubmit=(value:{parentPhone:string})=>{
    setStorageData("parentPhone",JSON.stringify({parentPhone:value.parentPhone}))
    this.props.navigation.navigate("ChildEmailVerification")
   }

  getCountries=()=>{
    this.getCountriesId = sendAPIRequest('account_block/countries', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  }

  handleCountries=(responseJson:ICoutryResponse)=>{
    if(!responseJson?.errors){
      this.setState({
        countriesData: responseJson?.data,
      })
    }
  }

  async receive(from: string, message: Message) {

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      if(apiRequestCallId===this.getCountriesId){
        this.handleCountries(responseJson)
      }
    }

  }

   // Customizable Area End

}    