Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.postAPiMethod = "POST";
exports.putApiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.getUserAvatarApiEndPoint = "user_avatars/user_avatars"; 
exports.addUserAvatarApiEndPoint = "user_avatars/user_avatars";
exports.updateUserAvatarApiEndPoint = "user_avatars/user_avatars";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Avatar";
exports.labelBodyText = "Avatar Body";
exports.labelEdit = "Edit";
exports.labelAdd = "Add";

exports.btnExampleTitle = "CLICK ME";

exports.selectSkinTitle = "Select Skin";
exports.selectHairTitle = "Select hair and color";
exports.selectFacialHairTitle = "Select facial hair and color";
exports.selectBodyTitle = "Select body and color";
exports.selectEyesTitle = "Select eyes";
exports.selectMouthTitle = "Select Mouth";
exports.selectNoseTitle = "Select nose";
exports.selectBgcTitle = "Select background color";
exports.randomBtnTitle = "Random Character";
exports.saveBtnTitle = "Save";
exports.somethingWentWrongTitle = "Something went wrong";
// Customizable Area End
