// Customizable Area Start

import React from 'react'
import { Container, Typography, Box, styled, } from '@material-ui/core'

import { imgGroupMail } from '../src/assets'
import EmailRegistrationController from './EmailRegistrationController.web'
import Header from '../../../components/src/Header'
import SelectLanguage from '../../../components/src/SelectLanguage'

export default class ActiveEmail extends EmailRegistrationController {
// Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

// Customizable Area End

  render() {
     // Customizable Area Start
    // Customizable Area End
    return (
    // Customizable Area Start
    
    <AccountStyle>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <Box className="contentWrapper">
            <img alt="mail" src={imgGroupMail} className="emailImage" />
            <Typography className="checkEmailTitle">Activate your Email</Typography>
            <Typography className="checkEmailDesc">
             We have sent email for activation on {' '}
              <span style={{ color: '#363636' }}>
                {this.state.emailName}
              </span>
            </Typography>
            <SelectLanguage style={{ marginTop: '36px' }} />
          </Box>
         
        </Container>
       
      </AccountStyle>
    // Customizable Area End
    )
  }
}
// Customizable Area Start
const AccountStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  
  '& .emailImage': {
    marginBottom: '68px',
    width: '100px',
    height: 'auto',
  },

  '& .contentContainer': {
    display: 'flex',
    marginTop: '128px',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },

  '& .contentWrapper': {
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex',
  },

  '& .checkEmailDesc': {
    fontSize: '16px',
    color: '#666666',
    marginTop: '10px',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    fontWeight: '700',
  },

  '& .checkEmailTitle': {
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '22px',
    fontWeight: '900',
  },

}))
// Customizable Area End
// Customizable Area End
