// Customizable Area Start
export const skinColors = {
  sc1: "#FFCC22",
  sc2: "#FBD2C7",
  sc3: "#F2AD9B",
  sc4: "#e58f7b",
  sc5: "#e4a06f",
  sc6: "#b1695a",
  sc7: "#92584b",
  sc8: "#613d36",
  sc9: "#c9e6dc",
};
// Customizable Area End
