import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
    IconButton
} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    btn_back,
    gift_icon,
    arrow_pick,
    box_icon,
    groupTeddy,
} from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End
import NavigateLayout from "../../../components/src/NavigateLayout";
import PremiumCancelController from "./PremiumCancelController.web";

// Customizable Area Start
const sections = [
    {
        text: 'Premium lessons',
        text2: 'You re currently experiencing + 1000 extra full, live and premium lessons.',
        text3: 'Available',
        img_src: arrow_pick,
    },
    {
        text: 'Customize Avatar',
        text2: 'A splash of fun with vibrant colors to make it yours!',
        text3: 'Available',
        img_src: arrow_pick,
    },
    {
        text: 'Unlimited lessons',
        text2: 'You’re learning anything you want with no limits!',
        text3: 'Available',
        img_src: box_icon,
    },
    {
        text: 'Win cool prizes',
        text2: 'Compete with others and win exciting rewards.',
        text3: 'Available',
        img_src: gift_icon,
    },

];


// Customizable Area End
class PremiumCancel extends PremiumCancelController {
    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings" isShowBottomMenu={false}>
                <SettingContainer>
                    <Box className="mainContentDasWrapper">
                        <Box className="settingDasBackground">
                            <Box className="settingDasImgWrapper">
                                <img src={this.state.teddyBear} className="teddysImagePremium" alt="Teddy Setting" />
                                <Typography className="settingTitleDas">
                                    <IconButton data-testid='btn-back' style={{ padding: "0", flex: "0 0 20%", maxWidth: "45px" }} onClick={() => this.props.navigation.navigate('Settings2')}>
                                        <img src={btn_back} className="arrowImage" alt="arrow btn" />
                                    </IconButton>
                                    <Typography className="settingTitleDas__text" style={{ flex: "0 0 85%" }} >
                                        {this.props.t("Settings")}
                                    </Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="contentContainerPremium">
                            <Box className="contentContainerPremium__block">
                                <Box>
                                    <Typography className='contentContainerPremium__title'>
                                        {this.props.t("Subscription cancelled")}
                                    </Typography>
                                </Box>
                                <Box className="contentContainerPremium__subTitle">
                                    <Typography style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '37px',
                                        fontWeight: 900,
                                        lineHeight: '36px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        background: 'linear-gradient(90deg, #A95EDB 22.26%, #62CDFF 77.24%)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }}>
                                        {this.props.t("We will")}
                                    </Typography>
                                    <Typography style={{
                                        fontFamily: 'Nunito',
                                        fontSize: '37px',
                                        fontWeight: 900,
                                        lineHeight: '36px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        background: 'linear-gradient(90deg, #A95EDB 22.26%, #62CDFF 77.24%)',
                                        WebkitBackgroundClip: 'text',
                                        backgroundClip: 'text',
                                        color: 'transparent',
                                    }}>
                                        {this.props.t("miss you!")}
                                    </Typography>
                                </Box>
                            </Box>


                            <Box className="contentContainerPremium__content" >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography style={{ verticalAlign: 'bottom', lineHeight: '1' }}>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'Quicksand',
                                            fontSize: '17px',
                                            fontWeight: 700,
                                            lineHeight: '25px',
                                            letterSpacing: '0px',
                                            textAlign: 'center',
                                            color: '#363636',
                                        }}>{this.props.t("It's not a goodbye.")}</span>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'Quicksand',
                                            fontSize: '17px',
                                            fontWeight: 700,
                                            lineHeight: '25px',
                                            letterSpacing: '0px',
                                            textAlign: 'center',
                                            color: '#363636',
                                        }}>{this.props.t("Don't forget you can always return to")}</span>
                                        <span style={{
                                            display: 'block',
                                            fontFamily: 'Quicksand',
                                            fontSize: '17px',
                                            fontWeight: 700,
                                            lineHeight: '25px',
                                            letterSpacing: '0px',
                                            textAlign: 'center',
                                            background: 'linear-gradient(270deg, #62CDFF 4.09%, #A95EDB 36.57%)',
                                            WebkitBackgroundClip: 'text',
                                            backgroundClip: 'text',
                                            color: 'transparent',
                                        }}>
                                            {this.props.t("Premium")}<span style={{ fontFamily: 'Quicksand', color: '#363636' }}>!</span>
                                        </span>
                                    </Typography>
                                </div>
                                <div className="btnSectionBottom">
                                    <Button data-testid='btn-continue' style={webStyle.btnTextTitle} onClick={() => this.props.navigation.navigate('SubscriptionCancelledPremium')}>
                                        {this.props.t("Continue")}
                                    </Button>
                                </div>
                            </Box>

                        </Box>
                    </Box>
                </SettingContainer>
            </NavigateLayout>

            // Customizable Area End
        );
    }
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    '& .mainContentDasWrapper': {
        flexDirection: 'column',
        height: '100%',
        display: 'flex',
    },
    '& .btnSectionBottom': {
        display: 'flex',
        justifyContent: 'center',
        padding: '120px 0px 0px 30px',
        // marginBottom: '100px'
    },
    '& .settingDasBackground': {
        backgroundColor: 'background: linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
    },

    '& .settingDasImgWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
        paddingBottom: '30px',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',

    },

    '& .settingTitleDas': {
        display: "flex",
        width: "100%",
        paddingLeft: '20px'
    },
    "& .settingTitleDas__text": {
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        textAlign: 'center',
        width: '100%',
        fontWeight: '900',
        fontFamily: 'Nunito',
    },
    '&.arrowImage': {
        width: "30%",
        height: "auto",
    },

    '& .teddysImagePremium': {
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px'
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },
    '& .contentContainerPremium': {
        flex: '6',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // overflowY: 'auto',
        padding: '32px 24px 100px',
        backgroundImage: `url(${groupTeddy})`,
        backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        justifyContent: 'space-between',
        backgroundSize: '655px auto',
    },
    "& .contentContainerPremium__block": {
        height: "180px"
    },
    "& .contentContainerPremium__title": {
        marginTop: "40px",
        fontFamily: 'Nunito',
        fontSize: '15px',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#CCCCCC',
        textTransform: 'uppercase' as 'uppercase',
    },
    "& .contentContainerPremium__subTitle": {
        margin: "20px 0px"
    },
    '& .imageParentSection': {
        padding: '319px 0px 0px 20px',
        marginTop: '20px',
    },

    '& .settingListTitle': {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#F2F2F2',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        width: '100%',
        height: '39px',
        padding: '15px',
    },
    '& .settingParent': {
        height: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px',
    },


    [theme.breakpoints.up('md')]: {

        '& .settingDasBackground': {
            flex: 5,
            width: "calc(50% - 120px)",
            position: 'fixed',
            height: '100vh'
        },

        '& .settingTitleDas__text': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .settingDasImgWrapper': {
            paddingTop: 'unset',
        },
        '& .mainContentDasWrapper': {
            marginLeft: '240px',
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            minHeight: '900px'
        },

        '& .contentContainerPremium': {
            backgroundSize: "600px",
            paddingTop: '120px',
            maxWidth: '50%',
            position: 'relative',
            left: '50%'
        },
        '& .teddysImagePremium': {
            width: "360px",
            //maxWidth: '240px',
        },

    },

    [theme.breakpoints.up("lg")]: {
        "& .mainContentDasWrapper": {
            width: 'calc(100% - 282px)',
            marginLeft: '282px'
        },

        "& .teddysImagePremium": {
            maxWidth: 'unset'
        }
    },

    [theme.breakpoints.between('md', 'lg')]: {
        '& .contentContainerPremium': {
            paddingTop: 0,
            '& .contentContainerPremium__block': {
                height: 'auto'
            },
        },

        '& .contentContainerPremium__block .contentContainerPremium__subTitle': {
            marginTop: "100px"
        },
        '& .mainContentDasWrapper': {
        },

    },

    [theme.breakpoints.down('sm')]: {

        '&  .arrowsImage': {
            left: '15%', top: '75px',
            position: 'absolute',

        },
        '& .teddysImagePremium': {
            maxWidth: '287px',
            padding: '0',
            position: 'absolute',
            marginRight: '10px',
            marginLeft: '0',
            bottom: '0px',
            left: '199px',
        },

        '& .arrowImage': {
            position: 'absolute',
            left: '18%',
            top: '71px',
        },
        '& .settingDasImgWrapper': {
            display: 'none',
        },
        '& .imageParentSection': {
            padding: '193px 0px 0px 20px',
            marginTop: '20px',
        },

        '& .contentContainerPremium': {
            backgroundImage: `url(${groupTeddy})`,
            backgroundRepeat: 'no-repeat',
            flex: '6',
            backgroundColor: '#fff',
            display: 'flex',
            backgroundPosition: 'center bottom',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 24px 100px',
            justifyContent: 'space-between',
            backgroundSize: '450px auto',
        },

        "& .contentContainerPremium__block": {
            height: "200px"
        },
        "& .contentContainerPremium__title": {
            margin: "0"
        },
        "& .contentContainerPremium__content": {
            height: "350px",
            paddingTop: "200px"
        },

        "& .contentContainerPremium__subTitle": {
            marginTop: "50px"
        },
        '& .btnSectionBottom': {
            display: 'flex',
            padding: '44px 0px 0px 0px',
            justifyContent: 'center',
        },

    },


    [theme.breakpoints.down('xs')]: {

        '& .teddysImagePremium': {
            marginLeft: '0',
            padding: '0',
            maxWidth: '245px',
            left: '37px',
            marginRight: '10px',
            position: 'absolute',
            bottom: '0px',
        },

        '& .arrowImage': {
            position: 'absolute',
            top: '74px',
            left: '10%',
        },

        '&  .arrowsImage': {
            top: '75px',
            left: '15%',
            position: 'absolute',

        },

        '& .settingDasImgWrapper': {
            display: 'none',
        },
        '& .imageParentSection': {
            padding: '193px 0px 0px 20px',
            marginTop: '20px',
        },

        '& .contentContainerPremium': {
            flex: '6',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '32px 24px 100px',
            backgroundImage: `url(${groupTeddy})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            justifyContent: 'space-between',
            backgroundSize: '450px auto',

        },
        "& .contentContainerPremium__subTitle": {
            marginTop: "50px",
            marginBottom: "0"
        },

        '& .btnSectionBottom': {
            display: 'flex',
            justifyContent: 'center',
            padding: '30px 0px 0px 0px',
        },



    },


}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },
    btnTextTitle: {
        width: '260px',
        height: '40px',
        borderRadius: '12px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        backgroundColor: '#36BFFF',
        fontFamily: 'Nunito',
        fontSize: '16px',
        textTransform: "capitalize" as 'capitalize'
    },
    titleTextMid: {
        fontFamily: 'Nunito',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#363636',
        fontSize: '15px',
        fontWeight: 900,
        lineHeight: '26px',
    },


};

export default withTranslation()(PremiumCancel)
// Customizable Area End