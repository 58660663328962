import React from 'react'
import {
  Box,
  IconButton,
  LinearProgress,
  Typography,
  styled,
  withStyles,
} from '@material-ui/core'
import { bolt, close } from './assets'

interface ProgressBarProps {
  number: number
  navigation?: any;
  currentLessonNumber : number;
  totalLessonCount: number;
  isHideCloseBtn?: boolean
}

const ProgressBar = ({ currentLessonNumber, totalLessonCount, number , navigation, isHideCloseBtn }: ProgressBarProps) => {
  const percentage = (currentLessonNumber / totalLessonCount) * 100;
  return (
    <Wrapper>
      {!isHideCloseBtn && (
      <IconButton>
      <img height={15} width={15} src={close} onClick={() => navigation.navigate('LandingPageWeb')} />
      </IconButton>
      )}
      <BorderLinearProgress variant="determinate" value={percentage} />
      <Box className="boltCountMain">
        <img src={bolt} width={14.12} height={19.16} />
        <Typography className="number">{number}</Typography>
      </Box>
    </Wrapper>
  )
}

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '15px',
  alignItems: 'center',
  width: '100%',

  '& .boltCountMain': {
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    background: '#F2F2F2',
    padding: '8px 18px 8px 8px',
    gap: '12px',
  },

  '& .number': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '18px',
    color: '#666666',
  },
}))

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 24,
    borderRadius: 20,
    width: '100%',
    border: '3px solid #f5f5f5',
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 100 : 50],
  },
  bar: {
    borderRadius: 20,
    backgroundColor: '#62CDFF',
  },
}))(LinearProgress)

export default ProgressBar
