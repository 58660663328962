// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export interface Props {
    id?: string
    navigation?: {
        navigate: (screenName: string) => void,
    }
}
export interface S {
    showPassword: boolean;
    check: boolean;
    selectValue: string;
}
export interface SS {
    id: string;
}
export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS> {
    accountLoginCallId:string=''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        ];
        this.state = {
            // Customizable Area Start
            showPassword: false,
            check: false,
            selectValue: ''
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.accountLoginCallId) {
                this.accountLoginData(responseJson)
            }
        }
    }
  
    accountLoginData = (responseJson: any) => {
        if (!responseJson.errors) {
            localStorage.setItem("token",responseJson.meta.token)
        }
        else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }

    }
    // Customizable Area Start
    handleClickShowPassword = () => { this.setState({ showPassword: !this.state.showPassword }) };
    submitFormData = (values: { email: string, password: string }) => {
        const data = { email: values.email, password: values.password }
        let body: object = {
            data: {
                type: "email_account",
                attributes: data
            }
        }
        this.accountLoginCallId = sendAPIRequest(configJSON.accountLoginApi, {
            method: "POST",
            body: body,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    handleChecked = () => {
        this.setState({ check: !this.state.check });
        const checked = document.getElementById('check-id')
        if (checked) {
            checked.click()
        }
    }
    selectHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ selectValue: event.target.value as string });
    }
    // Customizable Area End
}
// Customizable Area End
