export const imgEnglishFlag = require("./assets/englishFlag.png");
export const imgGermanFlag = require("./assets/germanFlag.png");
export const imgHungarianFlag = require("./assets/hungarianFlag.png");
export const imgRomanianFlag = require("./assets/romanianFlag.png");
export const imgLogoTedix = require("./assets/logoTedix.png");
export const ArrowUpIcon =require('./assets/ArrowUp-icon.svg')
export const ArrowDownIcon =require('./assets/ArrowDown-icon.svg')
export const checkIcon =require('./assets/check-icon.svg')
export const searchIcon =require('./assets/search-icon.svg')
export const skipCategory=require("./assets/skip-category.svg");
export const Loader =require('./assets/Loader.svg')
export const imgArrowDown = require("./assets/ic_arrowdown.svg");
export const imgDropDown = require("./assets/ic_dropdown.svg");
export const imgHomeMenu = require("./assets/homeMenu.svg");
export const imgHomeMenuActive = require("./assets/homeMenuActive.svg");
export const imgChallengeMenu = require("./assets/challengeMenu.svg");
export const imgChallengeMenuActive = require("./assets/challengeMenuActive.svg");
export const imgLeaderBoardMenu = require("./assets/leaderBoardMenu.svg");
export const imgLeaderBoardMenuActive = require("./assets/leaderBoardMenuActive.svg");
export const imgProfileMenu = require("./assets/profileMenu.svg");
export const imgProfileMenuActive = require("./assets/profileMenuActive.svg");
export const imgSettingMenu = require("./assets/settingMenu.svg");
export const imgSettingMenuActive = require("./assets/settingMenuActive.svg");
export const imgMenuMenuActive = require("./assets/menuMenuActive.svg");
export const imgMenuMenu = require("./assets/menuMenu.svg");
export const groupAvtar = require("./assets/group_avatar.svg");
export const buttonTrophies = require("./assets/button_trophies.svg")
export const buttonGoals = require("./assets/button_goals.svg")
export const buttonGrade = require("./assets/button_grade.svg")
export const buttonLangCountry = require("./assets/button_languagecountry.svg")
export const buttonpxMonth = require("./assets/button_pxMonth.svg")
export const buttonMultilevel = require('./assets/button_multiLevel.svg')
export const buttonNotification = require("./assets/button_notifications.svg")
export const rectangle = require("./assets/view_rectangle.svg")
export const trophies =require('./assets/skip_trophies.svg')
export const goalMedal =require('./assets/goalMedal.svg')
export const maskMath = require("./assets/maskMath.svg")
export const grades =require('./assets/skip_grade.svg')
export const unreadNotifications =require('./assets/button_unreadNotifications.svg')
export const bolt =  require('./assets/skip_bolt(1).svg')
export const diamond =  require('./assets/skip_vector.svg')
export const listIcon =  require('./assets/skip_list.png')
export const ghostlyAvatar = require('./assets/ghostly.svg')
export const orangeHealthy =  require('./assets/orange_healthyavatar.svg')
export const orangeHappy = require('./assets/orange_happyavatar.svg')
export const orangeWeak = require('./assets/orange_weakavatar.svg')
export const orangeWorried =  require('./assets/orange_worriedavatar.svg')
export const blueHealthy =  require('./assets/blue_healthyavatar.svg')
export const blueHappy = require('./assets/blue_happyavatar.svg')
export const blueWeak = require('./assets/blue_weakavatar.svg')
export const blueWorried =  require('./assets/blue_worriedavatar.svg')
export const lightgreenHealthy =  require('./assets/lightgreen_healthyavatar.svg')
export const lightgreenHappy = require('./assets/lightgreen_happyavatar.svg')
export const lightgreenWeak = require('./assets/lightgreen_weakavatar.svg')
export const lightgreenWorried =  require('./assets/lightgreen_worriedavatar.svg')
export const purpleHealthy =  require('./assets/purple_healthyavatar.svg')
export const purpleHappy = require('./assets/purple_happyavatar.svg')
export const purpleWeak = require('./assets/purple_weakavatar.svg')
export const purpleWorried =  require('./assets/purple_worriedavatar.svg')
export const redHealthy =  require('./assets/red_healthyavatar.svg')
export const redHappy = require('./assets/red_happyavatar.svg')
export const redWeak = require('./assets/red_weakavatar.svg')
export const redWorried =  require('./assets/red_worriedavatar.svg')
export const brownHealthy =  require('./assets/brown_healthyavatar.svg')
export const brownHappy = require('./assets/brown_happyavatar.svg')
export const brownWeak = require('./assets/brown_weakavatar.svg')
export const brownWorried =  require('./assets/brown_worriedavatar.svg')
export const yellowHealthy =  require('./assets/yellow_healthyavatar.svg')
export const yellowHappy = require('./assets/yellow_happyavatar.svg')
export const yellowWeak = require('./assets/yellow_weakavatar.svg')
export const yellowWorried =  require('./assets/yellow_worriedavatar.svg')
export const greenHealthy =  require('./assets/green_healthyavatar.svg')
export const greenHappy = require('./assets/green_happyavatar.svg')
export const greenWeak = require('./assets/green_weakavatar.svg')
export const greenWorried =  require('./assets/green_worriedavatar.svg')
export const medianGrade =  require('./assets/grade.svg')
export const goals = require('./assets/skip_goals.svg')
export const trophy = require('./assets/trophy.svg')
export const runningTeddyBlue = require('./assets/blue_runningTeddy.png')
export const runningTeddyRed = require('./assets/red_runningTeddy.png')
export const runningTeddyOrange = require('./assets/orange_runningTeddy.png')
export const runningTeddyPurple = require('./assets/purple_runningTeddy.png')
export const runningTeddyGreen = require('./assets/green_runningTeddy.png')
export const runningTeddyMint = require('./assets/mint_runningTeddy.png')
export const runningTeddyYellow = require('./assets/yellow_runningTeddy.png')
export const runningTeddyBrown = require('./assets/brown_runningTeddy.png')
export const bluePartyTeddy = require('./assets/blue_partyTeddy.svg')
export const orangePartyTeddy = require('./assets/orange_partyTeddy.svg')
export const redPartyTeddy = require('./assets/red_partyTeddy.svg')
export const mintPartyTeddy = require('./assets/mint_partyTeddy.svg')
export const greenPartyTeddy = require('./assets/green_partyTeddy.svg')
export const purplePartyTeddy = require('./assets/purple_partyTeddy.svg')
export const yellowPartyTeddy = require('./assets/yellow_partyTeddy.svg')
export const brownPartyTeddy = require('./assets/brown_partyTeddy.svg')
export const mistakeBlueTeddy =  require('./assets/mistakeBlue.svg')
export const mistakeBrownTeddy =  require('./assets/mistakeBrown.svg')
export const mistakeRedTeddy =  require('./assets/mistakeRed.svg')
export const mistakeOrangeTeddy =  require('./assets/mistakeOrange.svg')
export const mistakeMintTeddy =  require('./assets/mistakeMint.svg')
export const mistakeGreenTeddy =  require('./assets/mistakeGreen.svg')
export const mistakePurpleTeddy =  require('./assets/mistakePurple.svg')
export const mistakeYellowTeddy =  require('./assets/mistakeYellow.svg')
export const yourtext =  require('./assets/yourtext.svg')
export const premimumtext =  require('./assets/premiumtext.svg')
export const PremiumShadow = require('./assets/union_background.svg')