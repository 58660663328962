import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ReactPlayer from "react-player";
import { RefObject, createRef } from "react";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?:any;
  // Customizable Area Start
  isPremiumUser: boolean;
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  loading: boolean;
  isPlaying: boolean;
  videoProgress: number;
  showVideoControls: boolean;
  showLinearProgressBar: boolean;
  ended: boolean;
  currentTime: number;
  duration: number;
  slider: number | string | Array<number | string>
  isExpanded: boolean;
  handleOpenReportModel: boolean;
  isFullscreen: boolean;
  handleVolumnBtn: boolean;
  volumeLevel: number;
  isMuted: boolean;
  isScrollDown: boolean;
  isScrollingDown: boolean,
  isShowPlayer: boolean;
  isReportModalOpen: boolean;
  miroLessonId: number;
  isThankreportModelOpen: boolean;
  isMaterialModelOpen: boolean;
  isVideoFaded: boolean,
  savename: string;
  timeElapsed: number;
  showFreeUpgradePremium: boolean;
  premiumUser: boolean;
  showModalSuccess: boolean;
  lessonId: any;
  parentId: string
  lessonNumber: number,
  topicsName: string;
  videoUrl: string;
  summaryVideoName: string;
  unitsName: string;
  trophieOpen: boolean;
  goalOpen: boolean;
  mathOpen: boolean;
  drawer: boolean;
  openDrawers: boolean;
  medianGradeOpen: boolean;
  showGrade: boolean;
  lessonsName: string;
  token: string;
  summaryPDF: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LessonSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitReportId: string = "";
  public divRef: any;
  public typographyRef: any;
  public player: RefObject<ReactPlayer>;
  getlessonSummaryVideoId: string = "";
  currentUserProfileId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.typographyRef = createRef()
    this.divRef = createRef()
    this.player = createRef<ReactPlayer>()
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "A",
      // Customizable Area Start
      loading: false,
      isPlaying: false,
      showVideoControls: false,
      videoProgress: 0,
      ended: false,
      showLinearProgressBar: false,
      duration: 0,
      currentTime: 0,
      isExpanded: false,
      slider: 0,
      isFullscreen: false,
      handleOpenReportModel: false,
      volumeLevel: 100,
      handleVolumnBtn: false,
      isScrollDown: false,
      isMuted: false,
      videoUrl: '',
      summaryVideoName: '',
      miroLessonId: 0,
      isShowPlayer: false,
      isScrollingDown: true,
      isReportModalOpen: false,
      isMaterialModelOpen: false,
      isThankreportModelOpen: false,
      isVideoFaded: false,
      savename: '',
      timeElapsed: 0,
      showFreeUpgradePremium: false,
      premiumUser: false,
      showModalSuccess: true,
      lessonId: "",
      parentId: '',
      lessonNumber: 0,
      topicsName: '',
      unitsName: '',
      mathOpen: false,
      trophieOpen: false,
      goalOpen: false,
      drawer: false,
      openDrawers: false,
      medianGradeOpen: false,
      showGrade: false,
      lessonsName: '',
      token: '',
      summaryPDF: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.submitReportId) {
        this.receivePostProblem(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.getlessonSummaryVideoId) {
        this.receiveGetVideoStatus(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.currentUserProfileId) {
        this.handleGetCurrentUserProfileResponse(responseJson, errorReponse)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = localStorage.getItem('token') || ""
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
    const lessonName = this.props.navigation.getParam('lessonName');
    const lessonId = this.props.navigation.getParam('lessonId');
    this.getCurrentUserDetail(token)
    this.getlessonSummaryVideo(token, lessonId)
    this.setState({
      topicsName: updatedName.topicName,
      unitsName: updatedName.unitName,
      lessonsName: lessonName,
      token,
      lessonId
    })
  }

  closeTrophieDrawer = () => {
    this.setState({ goalOpen: false, trophieOpen: false, openDrawers: false, mathOpen: false, medianGradeOpen: false });
  };

  handleCenterDrawer = (type: string) => {
    if (type === 'goal') {
      this.setState({ goalOpen: true, openDrawers: true })
    }
    else if (type === 'trophi') {
      this.setState({ trophieOpen: true, openDrawers: true })
    }
    else if (type === 'median') {
      this.setState({ medianGradeOpen: true, openDrawers: true })
    }
  }

  handleBackLandingPage = () => {
    this.props.navigation.navigate('LandingPageWeb')
    localStorage.setItem('isBackFromMicrolessonVideo', 'true')
  }

  togglePlay = () => {
    this.setState((prevState) => ({
      isPlaying: !prevState.isPlaying,
      showVideoControls: false,
    }));
  };

  handlePlay = () => {
    this.setState({
      isPlaying: true,
      isMaterialModelOpen: false
    })
  }

  toggleControl = () => {
    this.setState((prevState) => ({
      showVideoControls: !prevState.showVideoControls,
    }));
  };

  onMouseEvent = (event: string) => {
    event === "enter" ?
      this.setState({
        showVideoControls: true,
      }) : this.setState({
        showVideoControls: false,
      })
  }

  handlePauseBtnClick = () => {
    this.setState({
      isPlaying: false,
    });
  };

  handleReplayClick = () => {
    if (this.player.current) {
      this.player.current.seekTo(0);
      this.setState({
        isPlaying: true,
        ended: false,
        showFreeUpgradePremium: false,
      });
      if (this.state.videoProgress !== 0) {
        this.setState({ videoProgress: 0 });
      }
    }
  };

  handleProgress(progress: any) {
    this.setState({ videoProgress: progress?.played * 100, currentTime: progress?.playedSeconds });
    const timeElapsed = Math.floor(progress?.playedSeconds);
    this.setState({ timeElapsed });
    if (timeElapsed === 20 && !this.state.premiumUser) {
      this.setState({ showFreeUpgradePremium: true, isPlaying: false });
    }
  }

  handleDuration(duration: number) {
    this.setState({ duration });
  }

  handleSlideChange = (event: any, newValue: number | number[]) => {
    if (this.player.current) {
      const timeToSeek = (newValue as number) / 100 * this.state.duration;
      this.player.current.seekTo(timeToSeek);
      this.setState({ currentTime: timeToSeek });
    }
  };

  formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  handleVolume = (newValue: any) => {
    this.setState({ volumeLevel: newValue, isMuted: newValue === 0 });
  };

  handleVolumnBotton = () => {
    this.setState((prevState) => ({
      handleVolumnBtn: !prevState.handleVolumnBtn,
    }));
  };

  handleOpenReportModal = () => {
    this.setState({
      isReportModalOpen: true,
    });
  }

  handleOption = (option: string) => {
    this.setState({ savename: option })
  }

  handleMaterial = () => {
    this.setState({
      isMaterialModelOpen: true,
      isPlaying: false,
    });
  }

  handleExpandBtnClick = () => {
    const mainContentContainer = document.querySelector('.fullScreenContent');
    if (mainContentContainer) {
      if (document.fullscreenElement === mainContentContainer) {
        document.exitFullscreen();
        this.setState({ isFullscreen: false });
      } else {
        mainContentContainer.requestFullscreen();
        this.setState({ isFullscreen: true });
      }
    }
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }))
  };

  handleOpenThankReportModal = () => {
    this.setState({
      isThankreportModelOpen: true,
    });
  }

  submitReportProblem = () => {
    const body = {
      data: {
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        problem: this.state.savename
      }
    }
    this.submitReportId = sendAPIRequest(
      `bx_block_request_management/report_problem`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  receivePostProblem = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.success) {
      this.handleOpenThankReportModal();
    }
    else {
      console.log("Error------", errorReponse);
    }
  }

  getCurrentUserDetail = (token: string) => {
    this.currentUserProfileId = sendAPIRequest(
      `bx_block_profile/profiles/current_user_profile`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  handleGetCurrentUserProfileResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      this.setState({
        premiumUser: responseJson?.data.attributes?.payment_premium
      })
    } else {
      console.log("Error getting current user profile", errorReponse);
    }
  }

  getlessonSummaryVideo = (token: string, lessonId: number) => {
    this.setState({ loading: true })
    this.getlessonSummaryVideoId = sendAPIRequest(
      `bx_block_profile/get_summary_video?lesson_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  receiveGetVideoStatus = (responseJson: any, errorReponse: any) => {
    this.setState({ loading: false })
    if (responseJson && responseJson.data) {
      this.setState({
        videoUrl: responseJson.data[0].attributes.summary_video,
        summaryVideoName: responseJson.data[0].attributes.name,
        summaryPDF: responseJson.data[0].attributes.summary_pdf,
      });
    } else {
      console.log("Error getting video list", errorReponse);
    }
  }

  getVideoClarityStyle() {
    const clarityStyle = this.state.premiumUser ? {} : { filter: 'blur(1px)' };
    return { ...clarityStyle };
  }

  handleDownloadPDF = () => {
    if (this.state.summaryPDF) {
      fetch(this.state.summaryPDF).then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `Summary Material`;
          document.body.appendChild(a)
          a.click();
          a.remove()
        });
      }).catch(err => console.log('Download PDF failed', err))
    }
  }
  // Customizable Area End 
}
