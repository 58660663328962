export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const UserIcon = require("../assets/Vector.svg");
export const emailIcon = require("../assets/Email.svg");
export const passwordInvisible = require("../assets/password_invisible.svg");
export const lockIcon =require('../assets/lock-icon.svg')
export const pandaIcon =require('../assets/panda-Icon.svg')
export const pandaIcon2 =require('../assets/pandaIcon2.svg')
export const tedixIcon =require('../assets/tedix-icon.svg')
export const checkIcon =require('../assets/check-icon.svg')
export const EnglishFlag =require('../assets/English_Flag.png')
export const GermanFlag =require('../assets/German_Flag.png')
export const HungarianFlag =require('../assets/Hungarian_Flag.png')
export const RomanianFlag =require('../assets/Romanian_Flag.png')
export const checkedIcon =require('../assets/check.svg')
export const uncheckIcon =require('../assets/uncheck.svg')
export const Tedixlogo =require('../assets/skip_logo.png')
export const LeftArrow =require('../assets/skip_arrow.png')
export const searchIcon =require('../assets/search-icon.svg')
export const ArrowUpIcon =require('../assets/ArrowUp-icon.svg')
export const ArrowDownIcon =require('../assets/ArrowDown-icon.svg')
export const ClockIcon =require('../assets/skip_clock.svg')
export const CalenderIcon =require('../assets/skip_calendar.svg')
export const ClockIconActive =require('../assets/skip_clock_active.svg')
export const CalenderIconActive =require('../assets/skip_calendar_active.svg')

export const healthyTeddy1 =require('../assets/skip_healthyTeddy__1_.svg')
export const healthyTeddy2 =require('../assets/skip_healthyTeddy__2_.svg')
export const healthyTeddy3 =require('../assets/skip_healthyTeddy__3_.svg')
export const healthyTeddy4 =require('../assets/skip_healthyTeddy__4_.svg')
export const healthyTeddy5 =require('../assets/skip_healthyTeddy__5_.svg')
export const healthyTeddy6 =require('../assets/skip_healthyTeddy__6_.svg')
export const healthyTeddy7 =require('../assets/skip_healthyTeddy__7_.svg')
export const healthyTeddy8 =require('../assets/skip_healthyTeddy__8_.svg')


export const textlabel1 =require('../assets/textlabel_decoration__1_.svg')
export const textlabel2 =require('../assets/textlabel_decoration__2_.svg')
export const textlabel3 =require('../assets/textlabel_decoration__3_.svg')
export const textlabel4 =require('../assets/textlabel_decoration__4_.svg')
export const textlabel5 =require('../assets/textlabel_decoration__5_.svg')
export const textlabel6 =require('../assets/textlabel_decoration__6_.svg')
export const textlabel7 =require('../assets/textlabel_decoration__7_.svg')
export const textlabel8 =require('../assets/textlabel_decoration__8_.svg')





