import * as React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
    IconButton,
} from "@material-ui/core";

import BottomMenu from "../../../components/src/BottomMenu";
import {
    skip_wrench,
    btn_back,
    skip_vector,
    skip_bolt,
    group_chk,
    button_showone,
    button_info,
    skip_stats,
} from "./assets";
import { withTranslation } from "react-i18next";


const benefits = [
    {
        imgSrc: group_chk,
        text: 'Unlock Premium Lessons',
        btnSrc: button_showone,
    },
    {
        imgSrc: group_chk,
        text: 'Personalize Your Avatar',
        btnSrc: button_showone,
    },
    {
        imgSrc: group_chk,
        text: 'Explore All Lessons',
        btnSrc: button_showone,
    },
    {
        imgSrc: group_chk,
        text: 'Win Cool Prizes',
        btnSrc: button_showone,
    },
];

// Customizable Area End
import SubscriptionPremiumHighlightsController from "./SubscriptionPremiumHighlightsController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";

class SubscriptionPremiumHighlights extends SubscriptionPremiumHighlightsController {
    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings">
                <SettingContainer>
                    <Box className="mainContentSubscripWrapper">
                        <Box className="settingBackground">
                            <Box className="settingSubscripWrapper">
                                <img src={skip_wrench} className="teddyImageHigh" alt="Teddy Setting" />

                                <img src={this.state.teddyBear} className="teddy-setting-image" alt="Teddy Setting" style={{ alignSelf: 'center' }} />

                                <Typography component='div' className="settingHighTitlePre">
                                    <IconButton data-testid='btn-back' onClick={this.handleGoBack}>
                                        <img src={btn_back} className="arrowImage" alt="arrow btn" width={16} height={16} style={{ marginRight: '5px' }} />
                                    </IconButton>
                                    <span className="title-desktop">{this.props.t("Settings")}</span>
                                    <span className="title-mobile">{this.props.t("Subscription")}</span>
                                </Typography>
                            </Box>
                        </Box>

                        <Box className="contentbenContainerHigh">
                            <Box className="title-background-container">
                                <Box className="title-background-wrapper">
                                    <Typography className="title-background-text-wrapper">
                                        <img src={btn_back} className="title-background-arrow" alt="arrow btn" style={{ marginRight: '5px' }} onClick={() => this.props.navigation.navigate("SubscriptionCancelledPremium")} data-test-id="title-btn-back" />

                                        <Typography className="title-background-text">{this.props.t("Subscription")}</Typography>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className='premiumContent'>
                                <div style={{ marginTop: '10px' }}>
                                    <Grid container wrap="nowrap" spacing={2} style={{ padding: '0px 51px 0px 0px' }}>
                                        <div style={webStyle.premiumMainSub}>
                                            <img src={skip_vector} alt="skip_vector" style={{ maxWidth: '54px' }} />
                                        </div>
                                        <Grid item xs style={webStyle.parentpremium}>
                                            <Typography style={webStyle.benefitsTextSub}>
                                                {this.props.t("Premium")}
                                            </Typography>
                                            <Typography style={webStyle.getbenefitSubTitle} >
                                                {this.props.t("Elevate your app experience:")}
                                                <br />
                                                <span style={webStyle.getbenefitSecTitle}>
                                                    {this.props.t("Become a VIP member!")}
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <Grid container wrap="nowrap" spacing={2} alignItems="center" style={webStyle.benefitxpMain}>
                                        <Grid item>
                                            <img src={skip_bolt} alt="skip_bolt" style={webStyle.imgSkip} />
                                        </Grid>
                                        <Grid item>
                                            <Typography style={webStyle.textbenefitsTtile}>
                                                Premium@1000 XP
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <img src={button_info} alt="button_info" style={webStyle.imgSkip} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Box className="BenefitsFirstWrapperHigh">
                                    <Box className="benefitsFirstTitle">
                                        <Typography>{this.props.t("BENEFITS")}</Typography>
                                    </Box>
                                    <Box className="benefitsSection">
                                        {benefits.map((benefit, index) => (
                                            <div key={index} style={{ display: 'flex', marginBottom: '10px', position: 'relative', width: '100%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <img src={benefit.imgSrc} alt={`benefit_${index}`} />
                                                </div>
                                                <div style={{ marginLeft: '10px' }}>
                                                    <Typography style={webStyle.freefirstText}>
                                                        {this.props.t(benefit.text)}
                                                    </Typography>
                                                </div>
                                                <div style={{
                                                    display: 'flex', paddingLeft: '10px',
                                                    position: 'absolute', right: '0px'
                                                }}>
                                                    <img src={benefit.btnSrc} alt={`button_${index}`} />
                                                </div>
                                            </div>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="middleTextParentHigh">
                                <Box className="insideTextSection" >
                                    <Box style={{ paddingTop: '5px' }}>
                                        <img src={skip_stats} alt="skip starts" />
                                    </Box>
                                    <Box>
                                        <Typography data-testid='btnRekindlePremium' className="textTitleMiddleHigh" onClick={() => this.props.navigation.navigate('PremiumHighlights')}>
                                            {this.props.t("Rekindle your previous Premium highlights!")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={webStyle.boxparentHigh}>
                                <Box style={webStyle.btnBoxMainHigh}>
                                    <Button data-testid='btnRejoinPremium' className='btnRejoinPremium' onClick={() => this.props.navigation.navigate('PremiumSubscription')}>
                                        {this.props.t("Rejoin Premium!")}
                                    </Button>
                                </Box>
                            </Box>

                            <Box style={webStyle.footerTextHighMain} >
                                <Typography style={webStyle.getFirstHighTitle}>
                                    By selecting 'Rejoin Premium!', you'll be
                                    <br />
                                    <span style={webStyle.getSecondHightitle}>
                                        opting for our paid subscription.
                                    </span>
                                </Typography>
                            </Box>
                        </Box>
                        <BottomMenu />
                    </Box>
                </SettingContainer>
            </NavigateLayout>
            // Customizable Area End
        );
    }
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .title-background-text": {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
    },

    '& .title-background-arrow': {
        position: 'absolute',
        top: '55%',
        left: '0'
    },

    "& .title-background-text-wrapper": {
        fontWeight: '900',
        paddingTop: '22px',
        fontFamily: 'Nunito',
        justifyContent: 'center',
        marginBottom: '12px',
        fontSize: '22px',
        display: 'flex',
        width: '100%',
        color: '#fff',
        position: 'relative'
    },

    "& .title-background-container": {
        width: 'calc(100% + 48px)',
        backgroundColor: '#fff',
        height: '111px',
        display: 'none'
    },

    "& .title-background-wrapper": {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    "& .arrowImage": {
        display: 'initial'
    },

    '& .teddy-setting-image': {
        display: 'none'
    },

    "& .title-desktop": {
        display: 'none'
    },

    "& .title-mobile": {
        display: 'block'
    },

    '& .BenefitsWrapperHigh .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },
    '& .textTitleMiddleHigh': {
        fontFamily: 'Nunito',
        fontSize: '16px',
        color: '#62CDFF',
        paddingLeft: '20px',
        fontWeight: 700,
        lineHeight: '18px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'left',
        textTransform: 'uppercase',
        cursor: 'pointer'
    },
    '& .middleTextParentHigh': {
        width: '100%',
        borderRadius: '12px',
        padding: '1px 1px 5px 1px',
        marginTop: '20px',
        // border: '2px solid', 
        // borderColor: 'linear-gradient(180deg, , #62CDFF)', 
        background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',

    },
    '& .insideTextSection': {
        display: 'flex',
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '15px',
    },
    '& .BenefitsWrapperHigh': {
        border: '2px solid',
        borderImageSource: 'linear-gradient(90deg, #A95EDB 0%, #62CDFF 100%)',
        borderImageSlice: 1,
        borderRadius: '12px !important',
    },
    '& .BenefitsFirstWrapperHigh': {
        padding: '2px 2px 2px 2px',
        marginTop: '20px',
        background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
        borderRadius: '12px',
        overflow: 'hidden',
    },
    '& .BenefitsWrapperHigh,.BenefitsFirstWrapperHigh,.middleTextParentHigh,.premiumContent': {
        maxWidth: "450px",
        width: "450px"
    },
    '& .contentbenContainerHigh': {
        flex: '6',
        padding: '32px 24px 100px',
        display: 'flex',
        backgroundColor: '#fff',
        overflow: 'scroll',
        flexDirection: 'column',
        alignItems: 'center',
    },


    '& .mainContentSubscripWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    '& .settingHighTitlePre': {
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        width: '100%',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
        position: "relative"
    },

    "& .settingHighTitlePre button": {
        position: "absolute",
        left: 0,
        top: "48%",
        marginLeft: "10px",
        padding: "4px"
    },
    '& .settingBackground': {
        backgroundColor: '#A95EDB',
        marginBottom: "-12px"
    },

    '& .settingSubscripWrapper': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },

    '& .benefitsListTitle': {
        color: '#fff',
        borderTopLeftRadius: '8px',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
        backgroundColor: '#e6e6e6',

    },
    '& .benefitsFirstTitle': {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        padding: '15px 20px',
    },
    "& .benefitsFirstTitle .MuiTypography-body1": {
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
    },

    '& .benefitsSection': {
        width: '100%',
        height: 'auto',
        top: 'auto',
        bottom: '0',
        backgroundColor: '#FFFFFF',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '0px 0px 12px 12px',

    },

    '& .teddyImageHigh': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "10%"

    },
    '&.arrowHighImage': {
        width: "30%",
        left: '25%',
        top: '29px',
        height: "auto",
        position: 'absolute',

    },

    "& .btnRejoinPremium": {
        width: '350px',
        height: '40px',
        borderRadius: '12px',
    },

    "& .btnRejoinPremium .MuiButton-label": {
        fontFamily: 'Nunito',
        fontSize: '16px',
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textTransform: 'capitalize'
    },

    [theme.breakpoints.up('md')]: {
        "& .contentbenContainerHigh": {
            paddingTop: '0'
        },
        "& .arrowImage": {
            display: 'none'
        },

        "& .title-background-container": {
            display: 'initial',
            width: 'calc(100% + 48px)',
            height: '111px'
        },

        '& .teddy-setting-image': {
            display: 'initial',
            position: 'absolute',
            bottom: '0',
            maxWidth: 'unset',
            width: '360px',
            left: 'calc(30% - 120px)'
        },
        "& .title-desktop": {
            display: 'block'
        },

        "& .title-mobile": {
            display: 'none'
        },

        '& .mainContentSubscripWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },

        '& .teddyImageHigh': {
            display: 'none'
        },

        '& .settingHighTitlePre': {
            fontSize: '30px',
            marginBottom: 'unset',
        },


        '& .BenefitsWrapperHigh,.BenefitsFirstWrapperHigh,.middleTextParentHigh,.premiumContent': {
            maxWidth: "350px",
            width: "350px",
            marginTop: '31px'
        },

        '& .settingBackground': {
            flex: 5,
            height: "100%"
        },

        '& .settingSubscripWrapper': {
            paddingTop: 'unset',
        },

    },

    [theme.breakpoints.up('lg')]: {
        '& .teddy-setting-image': {
            width: '360px',
            left: 'calc(50% - 207px)'
        },
        '& .BenefitsWrapperHigh,.BenefitsFirstWrapperHigh,.middleTextParentHigh,.premiumContent': {
            maxWidth: "450px",
            width: "450px"
        },

        '& .mainContentSubscripWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


    },

    [theme.breakpoints.down('sm')]: {
        "& .settingHighTitlePre button": {
            marginLeft: "-8px"
        },
        '& .arrowHighImage': {
            position: 'absolute',
            left: '0%',
            top: '31px',
        },

        '& .middleTextParentHigh': {
            width: "100%",
            borderRadius: '12px',
            padding: '2px 2px 5px 2px',
            marginTop: '20px',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
        },
        '& .benefitsSection': {
            width: '100%',
            height: 'auto',
            top: 'auto',
            bottom: '0',
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '0px 0px 12px 12px',

        },
    },
    [theme.breakpoints.down('xs')]: {

        '& .arrowHighImage': {
            left: '0%',
            position: 'absolute',
            top: '31px',
        },
        '& .middleTextParentHigh': {
            borderRadius: '12px',
            padding: '2px 2px 7px 2px',
            marginTop: '20px',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
        },
        "& .premiumContent": {
            width: "100%"
        },
        '& .BenefitsFirstWrapperHigh': {
            width: '100%',
            padding: '2px 2px 2px 2px',
            marginTop: '20px',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
            borderRadius: '12px',
            overflow: 'hidden', /* Clip content that goes beyond the border-radius */
            position: 'relative',
        },

        '& .BenefitsFirstWrapperHigh::before': {
            content: '',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
            borderRadius: '12px',
            zIndex: '-1', /* Place this behind the content */
        },
        '& .benefitsSection': {
            width: '100%',
            height: 'auto',
            top: 'auto',
            bottom: '0',
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            borderRadius: '0px 0px 12px 12px',

        },

    },

}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",
    },
    freefirstText: {
        fontWeight: 700,
        color: '#363636',
        fontFamily: 'Quicksand',
        fontSize: '20px',
    },
    benefitsParent: {
        width: '100%',
        height: 'auto',
        top: 'auto',
        bottom: '0',
        backgroundColor: '#FFFFFF',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
    },
    premiumMainSub: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F2F2F2',
        borderRadius: '50%',
        width: '60px',
    },

    benefitsTextSub: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '12px',

    },

    imgSkip: {
        maxWidth: '24px',
        marginRight: '10px'
    },
    footerTextHighMain: {
        textAlign: 'center' as 'center',
        padding: '20px 20px'
    },
    benefitxpMain: {
        marginTop: '10px',
        padding: '10px 23px 40px 0px',
    },
    getbenefitSubTitle: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '10px',
    },
    getbenefitSecTitle: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '5px',

    },
    parentpremium: {
        paddingLeft: '16px'
    },
    getFirstHighTitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999',
    },
    getSecondHightitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999'
    },

    textbenefitsTtile: {
        fontFamily: 'Quicksand',
        fontSize: '27px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999',
        fontWeight: 700,
        lineHeight: '29px',

    },
    boxparentHigh: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        maxWidth: '100%'
    },
    btnBoxMainHigh: {
        height: 'auto',
        width: '100%',
        borderRadius: '12px',
        margin: '45px 50px 0px 50px',
        textAlign: 'center' as 'center',
        boxSizing: 'border-box' as 'border-box',
        background: '#36BFFF',
    },




};

export default withTranslation()(SubscriptionPremiumHighlights)
// Customizable Area End
