// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled
} from '@material-ui/core'
import { withTranslation } from "react-i18next";
import { skipConfetti, skipBanner, teddyParty, supportText, successText } from "./assets"
import Header from "../../../components/src/Header";

import ParentVerificationSuccesssController from "./ParentVerificationSuccessController.web";
import SelectLanguage from "../../../components/src/SelectLanguage";
// Customizable Area End

class ParentVerificationSuccess extends ParentVerificationSuccesssController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Container>
                <Box className="Header_container">
                    <Header showGoBackBtn={false} />
                </Box>
                <Box>
                    <Typography className="account_verified_text">{this.props.t("Account Verified")}</Typography>
                </Box>
                <Box className="Banner_Image" >
                    <Box className="Banneer_Image_text_container">
                        <img src={skipBanner} alt="banner_image" />
                        <Box className="support_text_container">
                           <Typography className="support_text">
                            <img src = {supportText} alt="support_text"/>
                            <img style={{marginTop:-16}}src={successText} alt="success_text" />
                           </Typography>
                        </Box>
                    </Box>
                    <Box className="teddy_image">
                        <img src={teddyParty} alt="teddy_party" />
                    </Box>
                </Box>
                <Box style={{ width: "100%", justifyContent: 'center', display: 'flex' }}>
                    <Box className="bottom-text">
                        <Typography className="textStyle">{this.props.t("An active parent = A thriving learner")}</Typography>
                        <Box className="bottom-text-container">
                            <Typography className="textStyle">{this.props.t("Track their progress,")} </Typography>
                            <Typography className="textStyle">{this.props.t("celebrate their accomplishments,")} </Typography>
                            <Typography className="textStyle">{this.props.t("and encourage their bright future.")}</Typography>
                        </Box>
                    </Box>
                </Box>
               <Box className="selectLanguage">
                <SelectLanguage />
                </Box>
            </Container>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withTranslation()(ParentVerificationSuccess)

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${skipConfetti})`,


    "& .Header_container > .MuiBox-root  > .imageWrapper": {
        justifyContent: 'center',
        marginLeft: 0,
    },

    "& .account_verified_text": {
        textAlign: 'center',
        fontFamily: "Nunito",
        fontSize: "13px",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "0em",
        textTransform: 'uppercase',
        color: '#62CDFF'
    },

    "& .Banner_image": {
        textAlign: 'center',
        position: 'relative',
        marginTop: '96px',
        marginBottom: '80px'
    },

    "& .teddy_image": {
        marginTop: '-56px',
        position: "absolute",
        textAlign: 'center',
        width: "100%",
        zIndex: -1
    },

    "& .support_text_container": {
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
    },

    "& .support_text": {
        width: "200px",
        position: "absolute",
        top: "8px",
    },

    "& .Banneer_Image_text_container": {
        position: "relative"
    },

    "& .bottom-text": {
        textAlign: 'center',
        width: "306px",
        marginTop: '80px'
    },

    "& .bottom-text-container": {
        marginTop: '20px'
    },

    "& .textStyle": {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        letterSpacing: "0px",
        textAlign: "center",
       color:"#666666"
    },

    "& .selectLanguage":{
        width:"100%",
        textAlign:"center",
        marginTop:'60px'
    }
}))

// Customizable Area End


