import React from "react";
// Customizable Area Start
import { ArrowIcon, BgImage } from "./assets";
import { withTranslation } from "react-i18next";
import InAppNotificationHeaderController, {
  Props,
} from "./InAppNotificationHeaderController.web";
// Customizable Area End

 class InAppNotificationHeader extends InAppNotificationHeaderController {
  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderHeader = () => {
    return (
      <div className="notificationTop">
        <div className="notificationTopContent">
          <img
            src={ArrowIcon}
            className="notificationArrowIcon"
            onClick={this.handleGoBack}
            data-testid="HeaderBackArrow"
          />
          <p className="notificationTitle">{this.props.heading}</p>
        </div>
        <img src={BgImage} className="notificationTopBgImage" />
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <div data-testid="HeaderMain" className="HeaderMain">{this.renderHeader()}</div>;
    // Customizable Area End
  }
}
export default withTranslation()(InAppNotificationHeader);
