import React, { Component } from "react";
// Customizable Area Start
import { Box, styled, Card, Typography, CardContent } from "@material-ui/core";
import { withTranslation } from "react-i18next";

interface CardComponentProps {
  data: {
    title?: string;
    value?: number;
    label?: string;
    image?: string;
  }[];
  t?:any
}
// Customizable Area End

class Statistics extends Component<CardComponentProps> {
  constructor(props: CardComponentProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { data, t } = this.props;

    return (
      /* Customizable Area Start */
  
        <Statistic>
          <Card className="statisticCard">
            <Typography className="statisticCardHeader">{this.props?.t("Statistic")}</Typography>

            <CardContent className="gridSection">
              {data.map((box, index) => (
                <Box key={index} className="statisticCardBox">
                  <div>
                    <img
                      src={box.image}
                      alt={`star-${index}`}
                      className="statisticImg"
                    />
                  </div>
                  <div className="statisticCardBox__information">
                    <Typography className="statisticValue">
                      {box.value}
                    </Typography>
                    <Typography className="statisticTxtValue">
                    {t(box.label)}
                    </Typography>
                  </div>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Statistic>
   
      /* Customizable Area End */
    );
  }
}

// Customizable Area Start
export default withTranslation()(Statistics)
const Statistic = styled(Box)(({ theme }) => ({
  margin: "15px 0px",
  "& .statisticImg": {
    marginRight: "10px",
    marginLeft: "10px"
  },
  "& .gridSection": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px"
  },
  "& .statisticCard": {
    minWidth: "329px",
    borderRadius: "10px",
    border: "2px solid #F2F2F2",
    background: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    boxShadow: "none",
    '@media(max-width:599px)':{
      width:"329px"
    }
  },
  "& .statisticCardHeader": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.2",
    margin: "14px 0px 0px 16px"
  },
  "& .statisticCardBox": {
    height: "68px",
    border: "2px solid #E6E6E6",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px"
  },
  "& .statisticCardBox__information": {
    display: "column"
  },
  "& .statisticValue": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "1.2"
  },
  "& .statisticTxtValue": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "12px",
    textTransform: "uppercase",
    width: "80%",
    height: 26,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "14px"
  },

  [theme.breakpoints.up("md")]: {
    // height: "100vh"
  }
}));
// Customizable Area End
