import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { circle, gradeIcon } from "./assets";
//@ts-ignore
import { SelectEvent } from 'react-graph-vis';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
 t?: any;
 countryId: number;
 selectedGrade:string;
 sendDataToCategories:(id:number)=>void
 selectGraphClose:()=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  graph:{}
  loading:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


interface TreeNode {
  // Customizable Area Start
  
  // Customizable Area End
}


// Customizable Area Start

export interface IGradeAttributes{
  name:string,
  image:string,
  id:number
}
export interface IGrade{
  id:string,
  type:string,
  attributes:IGradeAttributes
}
export  interface INodeDataInfo{
  font?:number
  id:string,
  type?:string,
  label?:string,
  image:string,
  size:number,
  shape:string,
  child?:Array<INodeDataInfo>
}

type EventHandler = (event: SelectEvent) => void;

function flatData(data: Array<INodeDataInfo>) {
  const dataFlattened: { id: string; label: string | undefined; type:string|undefined, image: string; size: number; shape: string;font:number|undefined }[] =[] ;

  function flat(item: INodeDataInfo) {
    dataFlattened.push({
      id: item.id,
      label: item.label,
      type: item.type,
      shape: item.shape,
      font:item.font,
      image: item.image,
      size: item.size,
    });

    if (item.child && Array.isArray(item.child)) {
      item.child.forEach(flat);
    }

  }
  data.forEach(flat);
  return dataFlattened;
}


function edgesCreate(node: INodeDataInfo): Array<Object> {
  const edgesNew = [];
  if (node?.child && node?.child.length > 0) {
    for (const childNode of node.child) {
      edgesNew.push({ from: node.id, to: childNode.id });
      edgesNew.push(...edgesCreate(childNode));
    }
  }
  return edgesNew;
}
// Customizable Area End

export default class GradeListController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
        graph: {
          nodes: [],
          edges: [],
        }, 
        loading:false   
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  getGradesList: string = ""

  async componentDidMount() {
   this.getAllGrades()
   this.props.selectGraphClose()

  }

  handleGrade=(gradesData:Array<IGrade>)=>{

   const gradeData={
     label:this.props.t("Grade"),
     id:'1233445',
     image:gradeIcon,
     type:'grade',
     shape: "circularImage",
     size: 25,
   }
   const gradListNode= gradesData.map((item:IGrade)=>{
    return{
     label:item?.attributes?.name,
     image:item?.attributes?.image || circle,
     type:item.type,
     shape: "circularImage",
     size: 25,
     id:JSON.stringify(item)
   }
   })
   const subData = [{...gradeData, child: gradListNode }]
   this.generateNewNodesAndEdges(subData)
  }
 

 generateNewNodesAndEdges = (newNodesData: Array<INodeDataInfo>) => {
   const newNodes = newNodesData;
   const newEdges = edgesCreate(newNodes[0]);
   const nodesNew = flatData(newNodes);

   this.setState({
     graph: {
       edges: newEdges,
       nodes: nodesNew,
     },
   });
 };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.getGradesList) {
      this.handleGrade(responseJson?.data)
      this.setState({ loading:false})
     } 
  }

 getAllGrades = ()=> {
  this.setState({
    loading:true
  })
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGradesList = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.gradesByCountry}?country_id=${this.props.countryId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
 }

 openCategories:EventHandler =({ nodes, edges })=>{
  const dataId = JSON.parse(nodes ?? "{}")?.id ?? null;
  if (dataId !== null) {
    this.props.sendDataToCategories(dataId);
  }
 }

  // Customizable Area End

}

// Customizable Area Start
// Customizable Area End


