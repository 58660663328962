// Customizable Area Start
import React from 'react'
import MicroLessonExerciseController, {
  Props, QuestionAnswerItem,
} from './MicroLessonExerciseController'
import NavigateLayout from '../../../components/src/NavigateLayout';
import {
  Box,
  Typography,
  styled,
  Button,
  CircularProgress,
} from '@material-ui/core'
import { exerciseImg } from '../../cftableforlessons/src/assets'
import SingleChoiceExercise from '../../../components/src/MicrolessonExercise/SingleChoiceExercise'
import MultipleChoiceExercise from '../../../components/src/MicrolessonExercise/MultipleChoiceExercise'
import MatchingExercise from '../../../components/src/MicrolessonExercise/MatchingExercise'
import SequenceExercise from '../../../components/src/MicrolessonExercise/SequenceExercise'
import SelebrationPage from '../../../components/src/MicrolessonExercise/SelebrationPage'
import MistakePage from '../../../components/src/MicrolessonExercise/MistakePage'
import FillBlanksExercise from '../../../components/src/MicrolessonExercise/FillBlanksExercise'
import ProgressBar from '../../cftableforlessons/src/ProgressBar'
import MainContentCard from '../../cftableforlessons/src/MainContentCard'
import { withTranslation } from "react-i18next"; 

export type ShareRecordType = 'EXERCISE' | 'CHALLENGE' | 'SCORECARD'

export interface QuestionBodyItem {
  text: string
  isBlank: boolean
  correctAnswerId: number | null
}

export interface Option {
  id: string
  type: string
  attributes: {
    id: number | null
    option: string
    isCorrect: boolean
    sequenceNumber: number | null
    description: string | null
  }
}

export interface QuestionData {
  id: string
  type: string
  assessmentId: number
  attributes: {
    id: number
    question: string
    questionBody: Array<QuestionBodyItem> | string
    solution: string
    hint: string
    questionType: string
    options: Array<Option>
  }
}

export interface QuestionProps extends QuestionData {
  onClickContinue: ({
    isAnswerCorrect,
    isAnswerWrong,
    isUseSolution,
    isSkip,
    isClickFromCelebrate,
    isClickFromMistake,
  }: {
    isAnswerCorrect?: boolean
    isAnswerWrong?: boolean
    isUseSolution?: boolean
    isSkip?: boolean
    isClickFromCelebrate?: boolean
    isClickFromMistake?: boolean
  }) => void
  onSubmitExerciseAnswer?: any
  exerciseAnswer?: QuestionAnswerItem
  isLastQuestion?: boolean
  onSelectReportOption?: (options: string) => void
  onSubmitReport?: () => void
  isGuest?: boolean
  onUpdateXP: (token: string) => void
  lessonId: string
  microLessonId: number
  lessonAttemptId?: string | null
  shareRecordType: ShareRecordType
}

export const reportOptions = [
  {
    id: 1,
    value: 'The question is difficult to understand.',
  },
  {
    id: 2,
    value: 'The correct answer is missing.',
  },
  {
    id: 3,
    value: 'The solution is not clear.',
  },
  {
    id: 4,
    value: 'The hint wasn’t clear.',
  },
  {
    id: 5,
    value: 'The hint provided is confusing.',
  },
  {
    id: 6,
    value: 'Other',
  },
]

class MicroLessonExercise extends MicroLessonExerciseController {
  constructor(props: Props) {
    super(props)
  }

  handleNextQuestion = () => {
    if (this.state.questionIndex === this.state.exerciseData.length - 1) {
      this.handleCheckToNavigate()
      return
    }
    this.setState({ questionIndex: this.state.questionIndex + 1 })
  }

  handleClickContinue = ({
    isAnswerCorrect,
    isAnswerWrong,
    isUseSolution,
    isSkip,
    isClickFromCelebrate,
    isClickFromMistake,
  }: {
    isAnswerCorrect?: boolean
    isAnswerWrong?: boolean
    isUseSolution?: boolean
    isSkip?: boolean
    isClickFromCelebrate?: boolean
    isClickFromMistake?: boolean
  }) => {
    if (isAnswerCorrect && !this.state.hasCorrectAnswerInLesson && !this.state.isAlreadyShowCelebra) {
      this.setState({ showCelebrate: true, isAlreadyShowCelebra: true })
      return
    }
    if (isClickFromCelebrate) {
      this.setState({ showCelebrate: false })
      this.handleNextQuestion()
      return
    }
    if (isAnswerWrong) {
      this.setState({ isAnswerWrong: true })
      return
    }
    if (isClickFromMistake) {
      this.setState({ isAnswerWrong: false })
      this.handleNextQuestion()
      return
    }

    this.handleNextQuestion()
  }

  renderCurrentQuestion = () => {
    const QuestionList = {
      TRUE_FALSE: SingleChoiceExercise,
      MULTIPLE_CHOICE: MultipleChoiceExercise,
      MATCHING: MatchingExercise,
      FILL_IN_BLANK: FillBlanksExercise,
      SEQUENCE: SequenceExercise,
    }

    const currentQuestion = this.state.exerciseData?.[this.state.questionIndex]
    const currentQuestionAnswer = this.getExerciseAnswer(currentQuestion?.attributes?.id)
    const Component =
      QuestionList[
        currentQuestion?.attributes?.questionType as keyof typeof QuestionList
      ]

    if (this.state.isLoading) {
      return <CircularProgress style={{ color: '#36BFFF' }} />
    }

    if (this.state.exerciseData.length === 0) {
      return <Typography>{this.props.t("There is no exercise.")}</Typography>
    }

    if (this.state.showCelebrate) {
      return <SelebrationPage onClickContinue={this.handleClickContinue} />
    }

    if (this.state.isAnswerWrong) {
      return <MistakePage onClickContinue={this.handleClickContinue} />
    }

  return (
    <Component
      key={this.state.questionIndex}
      {...currentQuestion}
      onClickContinue={this.handleClickContinue}
      onSubmitExerciseAnswer={this.submitExerciseAnswer}
      exerciseAnswer={currentQuestionAnswer}
      isLastQuestion={this.state.questionIndex === this.state.exerciseData.length - 1 && this.state.lessonNumber === this.state.numberOfLesson - 1}
      onSelectReportOption={this.handleSelectReportOption}
      onSubmitReport={this.submitReport}
      onUpdateXP={this.getSpecificAccount}
      lessonId={this.state.lessonId}
      microLessonId={this.state.miroLessonId}
      lessonAttemptId={this.state.latestAttemptLessonId}
      shareRecordType='EXERCISE'
    />
  )
  }

  handleClickBackLandingPage = () => {
    localStorage.setItem('isBackFromMicrolessonVideo', 'true')
    this.props.navigation.navigate('LandingPageWeb')
  }

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    } else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    } else if (type === 'median') {
      this.setState({ medianOpen: true, openDrawer: true })
    }
  }

  closeDrawer = () => {
    this.setState({
      goalsOpen: false,
      trophiesOpen: false,
      openDrawer: false,
      mathOpen: false,
      medianOpen: false,
    })
  }

  render() {
    return (
      <NavigateLayout menuName="Home" isShowBottomMenu={false}>
        <MicroLessonExerciseContainer>
          <MainContentCard
            drawerSide={this.state.drawerSide}
            closeDrawer={this.closeDrawer}
            handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
            goalsOpen={this.state.goalsOpen}
            medianOpen={this.state.medianOpen}
            lessonName={this.state.lessonName}
            onClickBackLandingPage={this.handleClickBackLandingPage}
            navigation={this.props.navigation}
            topicName={this.state.topicName}
            openDrawer={this.state.openDrawer}
            unitName={this.state.unitName}
            trophiesOpen={this.state.trophiesOpen}
            lessonNumber={localStorage.getItem('lessonNumber')}
            unitNumber={localStorage.getItem('unitNumber')}
          />
          <Box className="contentContainer">
            <Box className="mainContentWrapper">
              <Box className="contentHeaderWrapper">
                <ProgressBar
                  currentLessonNumber={this.state.lessonNumber}
                  totalLessonCount={this.state.numberOfLesson}
                  number={this.state.totalXP}
                  navigation={this.props.navigation}
                />
                {!(this.state.showCelebrate || this.state.isAnswerWrong) && (
                  <Box className="topicNameMain">
                    <Box style={{ display: 'flex' }}>
                      <div className="brainImgMain">
                        <img src={exerciseImg} width={16.14} height={16.14} />
                      </div>
                      <Typography className="learnTitle">{this.props.t("Exercise")}</Typography>
                    </Box>
                    <Typography className="microLessonTitle">
                      {this.state.microLessonName || ''}
                    </Typography>
                  </Box>
                )}
              </Box>
              {this.renderCurrentQuestion()}
            </Box>
          </Box>
        </MicroLessonExerciseContainer>
      </NavigateLayout>
    )
  }
}

export default withTranslation()(MicroLessonExercise)
const MicroLessonExerciseContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#00AEFF',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: '6',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
    overflow: 'auto',
    scrollbarWidth: 'none',
  },

  '& .contentContainer::-webkit-scrollbar': {
    display: 'none',
  },

  '& .mainContentWrapper': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .contentHeaderWrapper': {
    padding: '32px 26px 0',
    width: '100%',
  },

  '& .topicNameMain': {
    marginTop: '30px',
    marginBottom: '25px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  '& .brainImgMain': {
    backgroundColor: '#00AEFF',
    height: '20.17px',
    width: '20.17px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },

  '& .learnTitle': {
    color: '#00AEFF',
    marginLeft: '10px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    fontSize: '14px',
    textTransform:"uppercase !important"
  },

  '& .microLessonTitle': {
    color: '#CCCCCC',
    fontWeight: '900',
    fontFamily: 'Nunito',
    fontSize: '14px',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .contentHeaderWrapper': {
      padding: '32px 60px 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontFamily: 'Nunito',
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: 700,
  fontStyle: 'normal',
  letterSpacing: '0.8px',
  boxShadow: '0px 4px #00AEFF',
  backgroundColor: '#36BFFF',
  textTransform: 'none',
  borderRadius: '12px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#36BFFF',
    boxShadow: '0px 4px #00AEFF',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
  '&.Mui-disabled': {
    boxShadow: '0px 4px #F2F2F2',
    color: '#B3B3B3',
    backgroundColor: '#E6E6E6',
  },
}))
// Customizable Area End
