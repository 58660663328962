// Customizable Area Start
import React from "react";

import {
  Modal,
  Container,
  Box,
  Button,
  Input,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props, configJSON } from "./ContactusController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class AddContactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
            <Typography variant="h4" component="h2">
              Add New/Query
            </Typography>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area End

