import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
const crypto  = require("crypto")
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  data: any;
  unitName?:string;
  Topic?:string;
  navigation:any;
  handleNonPremium?:()=>void
  isPremiumUser?:boolean
  unitId?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  challengeModal: boolean,
  challengeShuffleModal: boolean,
  Challenge:{
    id: string | number;
    name: string;
    image: string;
    unit_name?:string,
    unit_id: number
  }|undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End



export default class ChallengeUnitTopicsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      challengeModal: false,
      challengeShuffleModal: false,
      Challenge:undefined
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start

  getChallenge = () => {
    if(this.props.isPremiumUser){
    const randomBytes = crypto.randomBytes(4); // 4 bytes to cover most array lengths
    const randomIndex = randomBytes.readUInt32BE(0) % this.props.data.length;
    const item = JSON.parse(this.props.data[randomIndex].id).attributes
  this.setState({
    challengeShuffleModal:true,
    challengeModal:false,
    Challenge:{
      id:item.id,
      image:item.image,
      name:item.name,
      unit_name:this.props.unitName?.split(": ")[1],
      unit_id: parseInt(this.props.unitId || '0')
    }
  })
  this.handleChallengeShuffleClose()
  }else{
    this.props.handleNonPremium&& this.props.handleNonPremium()
  }
  }

  handleChallengeShuffleClose=()=>{
    setTimeout(()=>{
       if(this.state.challengeShuffleModal){
         this.setState({
            challengeShuffleModal:false,
            challengeModal:true
         })
       }
    },3500)
}

  handleClose=() => {
    this.setState({challengeModal:false})
    this.handleChallengeShuffleClose()
    }


  // Customizable Area End
}