// Customizable Area Start

import React from 'react'
import { Container, Typography, Box, styled, Snackbar } from '@material-ui/core'

import { imgGroupMail } from '../src/assets'
import CheckEmailForgotPasswordController from './CheckEmailForgotPasswordController.web'
import SelectLanguage from '../../../components/src/SelectLanguage'
import Header from '../../../components/src/Header'
import { Error } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

 class CheckEmailForgotPassword extends CheckEmailForgotPasswordController {
  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  handleClose = () => {
    this.setState({ showAlert: false })
  }

  render() {
    return (
      <CheckEmailForgotPasswordContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <div />
          <Box className="contentWrapper">
            <img alt="mail" src={imgGroupMail} className="emailImage" />
            <Typography className="checkEmailTitle">{this.props.t("Check your mail")}</Typography>
            <Typography className="checkEmailDesc">
              {this.props.t("We have sent a password recovery link to")}{' '}
              <span style={{ color: '#363636' }}>
                {this.state.emailResetPassword}
              </span>
            </Typography>
          </Box>
          <Box className="footerWrapper">
            <Typography className="footerText">
              {this.props.t("Did not receive the email? Check your spam filter, or try")}{' '}
              <span
                className="sendAgainButton"
                onClick={() => this.reSendEmail()}
              >
               {this.props.t("sending it again.")}
              </span>
            </Typography>
            <SelectLanguage style={{ marginTop: '36px' }} />
          </Box>
        </Container>
        <Snackbar
          open={this.state.showAlert}
          onClose={this.handleClose}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={2000}
        >
          <Box
            className="snackBarContainer"
            style={{
              backgroundColor:
                this.state.popupData.type === 'error' ? '#F96855' : '#4BB543',
            }}
          >
            <Error />
            <Typography className="alertText">
              {this.state.popupData.text}
            </Typography>
          </Box>
        </Snackbar>
      </CheckEmailForgotPasswordContainer>
    )
  }
}

export default withTranslation()(CheckEmailForgotPassword)
const CheckEmailForgotPasswordContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .contentWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .emailImage': {
    width: '100px',
    height: 'auto',
    marginBottom: '68px',
  },

  '& .checkEmailTitle': {
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'Nunito',
  },

  '& .checkEmailDesc': {
    fontSize: '16px',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    color: '#666666',
    marginTop: '10px',
    fontWeight: '700',
  },

  '& .footerWrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '72px',
  },

  '& .footerText': {
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    color: '#999999',

    '& span': {
      color: '#36BFFF',
      cursor: 'pointer',
    },
  },

  '& .snackBarContainer': {
    maxWidth: '300px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '6px 16px',
    color: '#fff',
  },

  '& .alertText': {
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
    overflow: 'hidden',
    wordWrap: 'break-word',
    marginLeft: '12px',
  },
}))
