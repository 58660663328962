import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//@ts-ignore
import Cookies from "js-cookie"
// Importing translation files

 const  translationEN =  require("./Languages/en.json");
 const translationRO = require("./Languages/ro.json");  


//Creating object with the variables of imported translation files
  const JsonData = Cookies.get('webAppLanguage') || "{}"
  const LanguageCookie = JSON.parse(JsonData)
  const language = LanguageCookie?.name || 'Romanian'

const resources = {
  English: {
    translation: translationEN,
  },
  Romanian: {
    translation: translationRO,
  },
};

//i18N Initialization

 i18n.use(initReactI18next).init({
  resources,
  lng: language , //default language
  fallbackLng: "Romanian",
  // compatibilityJSON: "v2",
  // keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  // react: {
  //   useSuspense: true,
  // },
});

export default i18n;