// Customizable Area Start
export const circle = require("../assets/circle.svg");
export const divider = require("../assets/view_divide.svg");
export const filterButton = require("../assets/button_filter.svg");
export const graySearch = require("../assets/gray_search.svg");
export const Back = require("../assets/skip_arrow.svg");
export const crossButton = require("../assets/close.svg");
export const loader = require("../assets/loader.svg");
export const skipHistory = require("../assets/skip_history.svg");
export const readyBlue =  require('../assets/blue_ready.svg')
export const readyBrown =  require('../assets/brown_ready.svg')
export const readyGreen =  require('../assets/green_ready.svg')
export const readyLightgreen =  require('../assets/lightgreen_ready.svg')
export const readyOrange =  require('../assets/orange_ready.svg')
export const readyPurple =  require('../assets/purple_ready.svg')
export const readyRed =  require('../assets/red_ready.svg')
export const readyYellow =  require('../assets/yellow_ready.svg')
export const groupMaskMathBack =  require('../assets/group_maskMathBack.svg')
// Customizable Area End