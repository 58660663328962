// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from 'framework/src/Message'

export interface Props {
  navigation: any
  id: string
  t?:any
}

interface S {
  isLoading: boolean
  descriptions: string
}

interface SS {
  id: any
}

export default class TermsConditionsRegisterController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTermsAndConditionsId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      isLoading: true,
      descriptions: '',
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getTermsAndConditions()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )

    this.setState({ isLoading: false })

    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse)
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson)
    

    if (apiRequestCallId && responseJson && !responseJson?.errors) {
      if (apiRequestCallId === this.getTermsAndConditionsId) {
        this.setState({
          descriptions: responseJson?.data?.[0]?.attributes?.description,
        })
      }
    }
  }

  getTermsAndConditions = () => {
    this.getTermsAndConditionsId = sendAPIRequest(
      'terms_and_conditions/terms_and_conditions',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}
// Customizable Area End
