// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string
  t?:any
}

interface S {
  isLoading: boolean
  showPassword: boolean
  showConfirmPassword: boolean
  isOTPValidate: boolean
  showAlert: boolean
  validateToken: string
  popupData: {
    text: string
    type: 'success' | 'error'
  }
}

interface SS {
  id: any
}

export default class CreateNewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendCreateNewPasswordId: string = ''
  checkValidateOTPId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      isLoading: false,
      showPassword: true,
      showConfirmPassword: true,
      isOTPValidate: false,
      showAlert: false,
      validateToken: '',
      popupData: {
        text: '',
        type: 'success',
      },
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const token = urlSearchParams.get('token') || ''
    const otp_code = urlSearchParams.get('otp_code') || ''
    this.setState({ validateToken: token })
    this.checkValidateOTP(token, otp_code)
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.sendCreateNewPasswordId
    ) {
      if (responseJson?.errors) {
        this.setState({
          isLoading: false,
          showAlert: true,
          popupData: {
            text:
              responseJson?.errors?.[0]?.password ||
              responseJson?.errors?.[0]?.otp,
            type: 'error',
          },
        })
        return
      }
      this.setState({
        isLoading: false,
        showAlert: true,
        popupData: {
          text: 'The password is successfully changed!',
          type: 'success',
        },
      })
    }

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.checkValidateOTPId
    ) {
      if (responseJson?.errors) {
        this.setState({
          popupData: {
            text:
              responseJson?.errors?.[0]?.token ||
              responseJson?.errors?.[0]?.otp ||
              responseJson?.errors?.[0]?.pin,
            type: 'error',
          },
          showAlert: true,
        })
        return
      }
      this.setState({ isOTPValidate: true })
    }
  }

  checkValidateOTP = (token: string, otpCode: string) => {
    const data = { token, otp_code: otpCode }
    let body: object = { data }
    this.checkValidateOTPId = sendAPIRequest(
      'bx_block_forgot_password/otp_confirmations',
      {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  submitCreateNewPassword = (newPassword: string) => {
    const data = { token: this.state.validateToken, new_password: newPassword }
    let body: object = { data }
    this.setState({ isLoading: true })
    this.sendCreateNewPasswordId = sendAPIRequest(
      'bx_block_forgot_password/passwords',
      {
        method: 'POST',
        body: body,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
}
// Customizable Area End
