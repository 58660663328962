import React from 'react'
import { Box, IconButton, Typography, styled } from '@material-ui/core'
import { KeyboardArrowLeft } from '@material-ui/icons'
import { SmallButtonCustom } from '../../../components/src/MaterialTheme'
import { useTranslation } from 'react-i18next'

interface SettingHeaderProps {
  onGoBack: () => void
  title: string
  showAvatar?: boolean
  headerImage?: any
  disableButton?: boolean
  onClickButton?: () => void
  typeButton?: 'button' | 'submit' | 'reset'
  isShowButton?: boolean
  actionButtonText?: string
  isLanguage?: boolean
  avatarIcon?:any
}

const SettingHeader = ({
  onGoBack,
  title,
  showAvatar,
  headerImage,
  disableButton,
  onClickButton,
  typeButton,
  avatarIcon,
  isShowButton = true,
  actionButtonText = 'Save',
  isLanguage = false
}: SettingHeaderProps) => {
  const {t}= useTranslation()
  return (
    <SettingHeaderContainer data-testid="home-shows">
      <Box style={{ position: 'relative' }}>
        <Box className="navigateHeaderWrapper">
          <IconButton onClick={onGoBack}>
            <KeyboardArrowLeft style={{ color: 'white' }} />
          </IconButton>
          <Typography className="headerText">{title}</Typography>
          {isShowButton ? (
            <SmallButtonCustom
              disabled={disableButton}
              onClick={onClickButton}
              type={typeButton}
            >
              {t(actionButtonText)}
            </SmallButtonCustom>
          ) : (
            <div className="fakeItem" />
          )}
        </Box>
        <img className="headerImg" src={headerImage} style={{marginTop: isLanguage ? '-42px' : '-12px'}} />
        {showAvatar && <img src={avatarIcon} className="avatarImg" />}
      </Box>
    </SettingHeaderContainer>
  )
}

const SettingHeaderContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'sticky',
  top: '0',
  padding: '72px 24px 0',
  backgroundColor: '#A95EDB',
  borderRadius: '0 0 24px 0',
  zIndex: 2,

  '& .navigateHeaderWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .headerText': {
    textAlign: 'center',
    color: '#fff',
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
  },

  '& .headerImg': {
    width: '88px',
    height: 'auto',
    marginLeft: '32px',
  },

  '& .avatarImg': {
    position: 'absolute',
    bottom: '-68px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '94px',
    zIndex: 3,
    backgroundColor: "rgb(201, 238, 255)",
      border: "2px none",
      padding: "15px",
      borderRadius: "20px"
  },

  '& .fakeItem': {
    width: '48px',
    height: '48px',
  },

  [theme.breakpoints.up('md')]: {
    padding: '72px 32px 0',

    '& .headerText': {
      fontSize: '25px',
    },

    '& .avatarImg': {
      width: "113px",
      bottom: "-86px",
      backgroundColor: "rgb(201, 238, 255)",
      border: "2px none",
      padding: "15px",
      borderRadius: "20px"
    },
  },
}))

export default SettingHeader