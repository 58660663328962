import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box, styled, useTheme, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import  {useTranslation} from "react-i18next"

import {
  Option,
  QuestionProps,
  StyledButton,
  reportOptions,
} from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import { blueFlag, matchingImg } from '../../../blocks/cftableforlessons/src/assets'
import GroupActionsExercise from './GroupActionsExercise'
import ActionAlert from './ActionAlert'
import ReportModal from '../../../blocks/cftableforlessons/src/ReportModal'
import ThankReportModal from '../../../blocks/cftableforlessons/src/ThankReportModal'
import { convertLatex } from '../utils'
import { HintText, MathFormula, QuestionText } from './ExerciseComponents'
import SolutionPage from './SolutionPage'

interface MatchingOption extends Option {
  side: 'LEFT' | 'RIGHT',
  initialIndex: number
}

const MatchingExercise: React.FC<QuestionProps> = ({
  onClickContinue,
  assessmentId,
  attributes,
  onSubmitExerciseAnswer,
  exerciseAnswer,
  isLastQuestion,
  onSelectReportOption,
  onSubmitReport,
  isGuest,
}) => {
  const {question, options, hint, solution, questionBody, questionType} = attributes
  const  { t } = useTranslation()
  const [showHintAlert, setShowHintAlert] = useState(false)
  const [showSolutionAlert, setShowSolutionAlert] = useState(false)
  const [showSkipAlert, setShowSkipAlert] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [showSolution, setShowSolution] = useState(false)
  const [isDisableSelectOption, setIsDisableSelectOption] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState<MatchingOption | null>(null)
  const [selectedWrongPairOptions, setSelectedWrongPairOptions] = useState<Array<MatchingOption>>([])
  const [matchedList, setMatchedList] = useState<Array<MatchingOption>>([])
  const [showThankReportModal, setShowThankReportModal] = useState(false)
  const [showHint, setShowHint] = useState(false)

  const classes = useStyles()
  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [startTime, setStartTime] = useState(0)

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  const [customFirstList, setCustomFirstList] = useState<MatchingOption[]>([])
  const [customLastList, setCustomLastList] = useState<MatchingOption[]>([])

  useEffect(() => {
    if (exerciseAnswer) {
      setIsDisableSelectOption(true)
    } else {
      setStartTime(Date.now())
    }
  }, [exerciseAnswer])

  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setWidth(boxRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const customOptions = _.cloneDeep(options).map((item, index) => ({...item, initialIndex: index + 1}))

    const firstList = customOptions.slice(0, customOptions.length / 2)
    const transformFirstList: Array<MatchingOption> = firstList.map((item) => ({
      ...item,
      side: 'LEFT',
    }))
    setCustomFirstList(transformFirstList)

    const lastList = customOptions.slice(customOptions.length / 2, customOptions.length)
    const transformLastList: Array<MatchingOption> = lastList.map((item) => ({
      ...item,
      side: 'RIGHT',
    }))
    setCustomLastList(transformLastList)
  }, [options])

  const numberOfMatching = useMemo(() => {
    return customFirstList.filter(item => {
      return customLastList.find(i => item.initialIndex === i.attributes.sequenceNumber)
    }).length
  }, [customFirstList, customLastList])

  const checkStatusClass = (option: MatchingOption) => {
    const isOneOfWrongPairOption =
      selectedWrongPairOptions.length === 2 &&
      selectedWrongPairOptions.findIndex((item) => item.id === option.id) !== -1

    const isSelectedMatchedList =
      matchedList.findIndex((selectedItem) => selectedItem.id === option.id) !==
      -1

    if (isSelectedMatchedList) {
      return {
        wrapper: classes.matchedOption,
        text: classes.matchedText,
      }
    } else if (isOneOfWrongPairOption) {
      return {
        wrapper: classes.incorrectOption,
        text: classes.incorrectText,
      }
    } else if (selectedAnswer?.id === option.id) {
      return {
        wrapper: classes.selectedOption,
        text: classes.selectedText,
      }
    } else {
      return {
        wrapper: classes.unselectedOption,
        text: classes.unselectedText,
      }
    }
  }

  const handleChooseAnswer = (item: MatchingOption) => {
    if (selectedAnswer === null || selectedAnswer?.side === item.side) {
      setSelectedAnswer(item)
      setSelectedWrongPairOptions([])
    } else {
      const isMatch = selectedAnswer.attributes.sequenceNumber === item.initialIndex
      if (isMatch) {
        setMatchedList((prev) => [...prev, selectedAnswer, item])
      } else {
        setSelectedWrongPairOptions([selectedAnswer, item])
      }
      setSelectedAnswer(null)
    }
  }

  const renderOptionList = (list: MatchingOption[]) => (
    <Box
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {list.map((item) => {
        const statusClass = checkStatusClass(item)
        const isSelectedMatchedList =
          matchedList.findIndex(
            (selectedItem) => selectedItem.id === item.id
          ) !== -1
        return (
          <Box
            key={item.id}
            onClick={() => {
              if (!isSelectedMatchedList && !isDisableSelectOption && ((matchedList.length / 2) !== numberOfMatching)) {
                handleChooseAnswer(item)
              }
            }}
            className={`${classes.matchingOption} ${statusClass.wrapper}`}
          >
            <MathFormula 
              formula={convertLatex(item.attributes.option)}
              color='unset'
              fontFamily='Quicksand'
              fontSize={16}
              fontWeight={700}
              lineHeight='8px'
              className={statusClass.text}
            />
          </Box>
        )
      })}
    </Box>
  )

  return (
    <MatchingExerciseContainer {...{ ref: boxRef }}>
      {showSolution ? (
        <SolutionPage
          solutionContent={solution} 
          onContinue={() => {
            onClickContinue({isUseSolution: true})
          }}
          width={isMediumScreen ? width - 120 : width - 52}
          onClickReport={() => setShowReportModal(true)}
          isGuest={isGuest}
        />
      ) : (
        <Box className="mainContainer">
          <Box className="questionWrapper">
            <QuestionText question={question} width={isMediumScreen ? width - 120 : width - 52} />
            <img src={matchingImg} className='matchingImg' />
            <Box style={{ display: 'flex', width: '100%', gap: '36px' }}>
              {renderOptionList(customFirstList)}
              {renderOptionList(customLastList)}
            </Box>
          </Box>
          {!showSolution && !showHint && (
            <GroupActionsExercise
              isDisableButton={((matchedList.length / 2) !== numberOfMatching) && !Boolean(exerciseAnswer)}
              buttonText={t("Continue")}
              onCheckAnswer={() => {
                if (!Boolean(exerciseAnswer)) {
                  const endTime = Date.now();
                  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
                  
                  const answerList: any = []
                  customFirstList.forEach(item => {
                    const matchedWithItem = customLastList.find(i => i.initialIndex === item.attributes.sequenceNumber)
                    if (matchedWithItem) {
                      const matchedIndex = customLastList.findIndex(op => op.id === matchedWithItem.id)
                      const pairOption = {
                        [`Op ${item.initialIndex}`]: `Op ${matchedIndex + 1}`
                      }
                      answerList.push(pairOption)
                    } else {
                      const unmatchedOption = {
                        [`Op ${item.initialIndex}`]: `N/A`
                      }
                      answerList.push(unmatchedOption)
                    }
                  })

                  const answerListStr = answerList?.map((item: any) => JSON.stringify(item))
                  const correctAnswerString = answerListStr?.join('(split-space)')
                  
                  const submitData = {
                    assessmentId: assessmentId,
                    questionBankId: attributes?.id,
                    questionType: questionType,
                    questionBody: questionBody,
                    correctAnswer: correctAnswerString,
                    userAnswer:  correctAnswerString,
                    correct: true,
                    grade: 10,
                    timeCompleteInSeconds: Math.floor(elapsedTimeInSeconds),
                    lessonCompleted: isLastQuestion,
                  }
                  onSubmitExerciseAnswer && onSubmitExerciseAnswer(submitData)
                }
                const param = exerciseAnswer ? {} : {isAnswerCorrect: true}
                onClickContinue(param)
              }}
              onShowSolutionAlert={() => {
                const isSkipShowSolutionAlert = Boolean(
                  localStorage.getItem('isSkipShowSolutionAlertMicroLesson')
                )
                if (isSkipShowSolutionAlert) {
                  setShowSolution(true)
                  setIsDisableSelectOption(true)
                } else {
                  setShowSolutionAlert(true)
                }
              }}
              onShowSkipAlert={() => {
                const isSkipShowSkipAlert = Boolean(
                  localStorage.getItem('isSkipShowSkipAlertMicroLesson')
                )
                if (isSkipShowSkipAlert) {
                  onClickContinue({isSkip: true})
                } else {
                  setShowSkipAlert(true)
                }
              }}
              onShowHintAlert={() => {
                const isSkipShowHintAlert = Boolean(
                  localStorage.getItem('isSkipShowHintAlertMicroLesson')
                )
                if (isSkipShowHintAlert) {
                  setShowHint(true)
                } else {
                  setShowHintAlert(true)
                }
              }}
            />
          )}
        </Box>
      )}
      {showHint && (
        <Box className="solutionWrapper">
          <Box className="solutionContentWrapper">
            <HintText hint={hint} width={isMediumScreen ? width - 120 : width - 52} />
            {isGuest ? (<Box />) : (
              <img
                src={blueFlag}
                onClick={() => setShowReportModal(true)}
                width={15}
                height={15}
              />
            )}
          </Box>
          <StyledButton
            onClick={() => {
              setShowHint(false)
            }}
          >
            {t("Continue")}
          </StyledButton>
        </Box>
      )}
      <ActionAlert
        open={showSolutionAlert}
        onClose={() => setShowSolutionAlert(false)}
        title={t("Solution Alert")}
        description={t("Please note that choosing to view the solution will not earn you any XP.")}
        actionText={t("Solution")}
        onClickAction={() => {
          setShowSolutionAlert(false)
          setIsDisableSelectOption(true)
          setShowSolution(true)
        }}
        actionType="SOLUTION"
      />
      <ActionAlert
        open={showHintAlert}
        onClose={() => setShowHintAlert(false)}
        title={t("Hint Alert")}
        description={t("If you choose to access a hint, you won't earn any XP.")}
        actionText={t("Show hint")}
        actionType="HINT"
        isHint
        onClickAction={() => {
          setShowHintAlert(false)
          setShowHint(true)
        }}
      />
      <ActionAlert
        open={showSkipAlert}
        onClose={() => setShowSkipAlert(false)}
        title={t("Skip Alert")}
        description={t(`skipDescription`)}
        actionText={t("Skip")}
        actionType="SKIP"
        onClickAction={() => onClickContinue({isSkip: true})}
      />
      <ReportModal
        open={showReportModal}
        reportOptions={reportOptions}
        title={t("Report a problem")}
        handleOption={(value: string) => {
          onSelectReportOption && onSelectReportOption(value)
        }}
        onClose={() => setShowReportModal(false)}
        onSubmitReport={() => {
          onSubmitReport && onSubmitReport()
          setShowThankReportModal(true)
        }}
      />
      <ThankReportModal
        onClose={() => setShowThankReportModal(false)}
        open={showThankReportModal}
      />
    </MatchingExerciseContainer>
  )
}

const MatchingExerciseContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '& .mainContainer': {
    flex: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 26px 0',
  },

  '& .questionWrapper': {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .matchingImg': {
    width: '200px',
    height: 'auto',
    marginBottom: '64px'
  },

  '& .solutionWrapper': {
    width: '100%',
    padding: '36px 26px 40px',
    backgroundColor: '#C9EEFF',
    borderRadius: '20px 20px 0 0',
  },

  '& .solutionContentWrapper': {
    marginBottom: '28px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .solutionText': {
    fontFamily: 'Nunito',
    color: '#00AEFF',
    fontSize: '20px',
    fontWeight: '900',
  },

  [theme.breakpoints.up('md')]: {
    '& .mainContainer': {
      padding: '32px 60px 0',
    },
  },
}))

const useStyles = makeStyles(() => ({
  matchingOption: {
    minHeight: '42px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    marginBottom: '15px',
    padding: '4px 20px',
    cursor: 'pointer',
  },

  matchedOption: {
    animation: '$changeBorderColor 0.5s forwards',
  },

  incorrectOption: {
    boxShadow: `0px 2px #F96855`,
    border: '2px solid #F96855',
  },

  selectedOption: {
    boxShadow: `0px 2px #62CDFF`,
    border: '2px solid #62CDFF',
  },

  unselectedOption: {
    boxShadow: '0px 2px #E6E6E6',
    border: '2px solid #E6E6E6',
  },

  '@keyframes changeBorderColor': {
    '0%': {
      boxShadow: `0px 2px #2BCE8C`,
      border: '2px solid #2BCE8C',
    },
    '96%': {
      boxShadow: `0px 2px #2BCE8C`,
      border: '2px solid #2BCE8C',
    },
    '100%': {
      boxShadow: `0px 2px #f2f2f2`,
      border: '2px solid #f2f2f2',
    },
  },

  matchedText: {
    animation: '$changeTextColor 0.5s forwards',
  },

  incorrectText: {
    color: '#F96855',
  },

  selectedText: {
    color: '#62CDFF',
  },

  unselectedText: {
    color: '#7F7F7F',
  },

  '@keyframes changeTextColor': {
    '0%': {
      color: '#2BCE8C',
    },
    '96%': {
      color: '#2BCE8C',
    },
    '100%': {
      color: '#e6e6e6',
    },
  },
}))

export default MatchingExercise
