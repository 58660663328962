import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import  {useTranslation} from "react-i18next"

interface GroupActionsExerciseProps {
  onShowSolutionAlert: () => void
  onShowHintAlert: () => void
  onShowSkipAlert: () => void
  isDisableButton: boolean
  onCheckAnswer: () => void
  buttonText?: string
}

const GroupActionsExercise = ({
  onShowSolutionAlert,
  onShowHintAlert,
  onShowSkipAlert,
  isDisableButton,
  onCheckAnswer,
  buttonText = 'Check'
}: GroupActionsExerciseProps) => {
  const  { t } = useTranslation()
  return (
    <GroupActionsExerciseContainer>
      <Box className="groupActionButton">
        <div className="actionWrapper" onClick={onShowSolutionAlert}>
          <Typography className="actionText">{t("Solution")}</Typography>
        </div>
        <div className="actionWrapper" onClick={onShowHintAlert}>
          <Typography className="actionText">{t("Hint")}</Typography>
        </div>
        <div className="actionWrapper" onClick={onShowSkipAlert}>
          <Typography className="actionText">{t("Skip")}</Typography>
        </div>
      </Box>
      <StyledButton disabled={isDisableButton} onClick={onCheckAnswer}>
        {t(buttonText)}
      </StyledButton>
    </GroupActionsExerciseContainer>
  )
}

const GroupActionsExerciseContainer = styled(Box)(() => ({
  width: '100%',
  marginTop: '24px',
  marginBottom: '40px',

  '& .groupActionButton': {
    display: 'flex',
    width: '100%',
    gap: '14px',
    marginBottom: '15px',
  },

  '& .actionWrapper': {
    flex: 1,
    display: 'flex',
    height: '44px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    borderBottomWidth: '4px',
    cursor: 'pointer',
  },

  '& .actionText': {
    color: '#62CDFF',
    fontFamily: 'Nunito',
    fontWeight: '700',
    fontSize: '16px',
  },
}))

export default GroupActionsExercise
