// Customizable Area Start
export const algebra = require("../assets/algebra1.svg");
export const  maskMath = require("../assets/group_maskMath.svg")
export const skip_closedbook = require("../assets/skip_challengesIcon.svg");
export const Search =require('../assets/skip_search.svg')
export const Back = require('../assets/skip_arrow.svg')
export const cross_icon = require('../assets/button_Close.svg')
export const groupscroll = require("../assets/scrollBox.svg")
export const roulette = require("../assets/skip_roulette.svg")
export const roulette1 = require("../assets/skip_roulette1.svg")
export const downArrow = require('../assets/skip_arrow_down.svg')
export const upArrow = require('../assets/skip_arrow_up.svg')
export const leftTop = require('../assets/leftTop.svg')
export const leftMid = require('../assets/leftMid.svg')
export const leftBottom = require('../assets/leftBottom.svg')
export const rightTop = require('../assets/rightTop.svg')
export const rightMid = require('../assets/rightMid.svg')
export const rightBottom = require('../assets/rightBottom.svg')
export const groupLesson = require('../assets/group_lesson.svg')
export const multilevelIcon = require('../assets/skip_multiLevel.svg')
export const centerCardIcon = require('../assets/center_card.svg')
export const upside = require('../assets/downSide.svg')
export const downnside = require('../assets/upSide.svg')
export const horizontal = require('../assets/horizontal.svg')
export const groupMaskMath = require("../assets/group_maskMathFull.png");
export const challengeMountain = require('../assets/challenge_mountain.png')
export const teddyBlueCelebrate = require('../assets/teddy_blue_celebrate.svg')
export const teddyOrangeCelebrate = require('../assets/teddy_orange_celebrate.svg')
export const teddyLightGreenCelebrate = require('../assets/teddy_light_green_celebrate.svg')
export const teddyPurpleCelebrate = require('../assets/teddy_purple_celebrate.svg')
export const teddyRedCelebrate = require('../assets/teddy_red_celebrate.svg')
export const teddyBrownCelebrate = require('../assets/teddy_brown_celebrate.svg')
export const teddyYellowCelebrate = require('../assets/teddy_yellow_celebrate.svg')
export const teddyGreenCelebrate = require('../assets/teddy_green_celebrate.svg')
export const purpleRectangle = require('../assets/purple_rectangle.png')
export const orangeRectangle = require('../assets/orange_rectangle.png')
export const challengeCelebration = require('../assets/challenge-celebration.png')
export const challengeFinishBg = require('../assets/challenge_finish_bg.png')
export const teddyBlueFinishChallenge = require('../assets/teddy_blue_finish_challenge.svg')
export const teddyOrangeFinishChallenge = require('../assets/teddy_orange_finish_challenge.svg')
export const teddyLightGreenFinishChallenge = require('../assets/teddy_light_green_finish_challenge.svg')
export const teddyPurpleFinishChallenge = require('../assets/teddy_purple_finish_challenge.svg')
export const teddyRedFinishChallenge = require('../assets/teddy_red_finish_challenge.svg')
export const teddyBrownFinishChallenge = require('../assets/teddy_brown_finish_challenge.svg')
export const teddyYellowFinishChallenge = require('../assets/teddy_yellow_finish_challenge.svg')
export const teddyGreenFinishChallenge = require('../assets/teddy_green_finish_challenge.svg')
export const bolt = require('../assets/bolt.svg')
export const time = require('../assets/time.svg')
export const goHome = require('../assets/go_home.svg')
export const share = require('../assets/share.svg')
export const challengeRouletteBg = require('../assets/challenge_roulette_bg.png')
export const closeBtn = require('../assets/close_btn.svg')
export const rouletteHand = require('../assets/roulette_hand.svg')
export const rouletteResult = require('../assets/roulette_result.svg')
export const flagHeader = require('../assets/flag_header.svg')
export const cloundLeft = require('../assets/clound_left.svg')
export const cloundRight = require('../assets/clound_right.svg')
export const math = require('../assets/math.svg')
export const gradeIcon = require("../assets/grade_icon.svg")
export const listIcon = require("../assets/skip_list.png")
export const challengeShareTeddy = require("../assets/challenge_share_teddy.svg")
export const brownHealthy =  require('../assets/brown_healthyavatar.svg')
export const brownHappy = require('../assets/brown_happyavatar.svg')
export const brownWeak = require('../assets/brown_weakavatar.svg')
export const brownWorried =  require('../assets/brown_worriedavatar.svg')
export const ghostlyAvatar = require('../assets/ghostly.svg')
export const yellowHealthy =  require('../assets/yellow_healthyavatar.svg')
export const orangeHealthy =  require('../assets/orange_healthyavatar.svg')
export const orangeHappy = require('../assets/orange_happyavatar.svg')
export const lightgreenHappy = require('../assets/lightgreen_happyavatar.svg')
export const lightgreenWeak = require('../assets/lightgreen_weakavatar.svg')
export const lightgreenWorried =  require('../assets/lightgreen_worriedavatar.svg')
export const blueHealthy =  require('../assets/blue_healthyavatar.svg')
export const orangeWeak = require('../assets/orange_weakavatar.svg')
export const orangeWorried =  require('../assets/orange_worriedavatar.svg')
export const purpleHealthy =  require('../assets/purple_healthyavatar.svg')
export const yellowHappy = require('../assets/yellow_happyavatar.svg')
export const yellowWeak = require('../assets/yellow_weakavatar.svg')
export const purpleWorried =  require('../assets/purple_worriedavatar.svg')
export const redHealthy =  require('../assets/red_healthyavatar.svg')
export const redHappy = require('../assets/red_happyavatar.svg')
export const redWeak = require('../assets/red_weakavatar.svg')
export const redWorried =  require('../assets/red_worriedavatar.svg')
export const greenHealthy =  require('../assets/green_healthyavatar.svg')
export const greenHappy = require('../assets/green_happyavatar.svg')
export const greenWeak = require('../assets/green_weakavatar.svg')
export const greenWorried =  require('../assets/green_worriedavatar.svg')
export const lightgreenHealthy =  require('../assets/lightgreen_healthyavatar.svg')
export const blueHappy = require('../assets/blue_happyavatar.svg')
export const blueWeak = require('../assets/blue_weakavatar.svg')
export const yellowWorried =  require('../assets/yellow_worriedavatar.svg')
export const purpleHappy = require('../assets/purple_happyavatar.svg')
export const purpleWeak = require('../assets/purple_weakavatar.svg')
export const blueWorried =  require('../assets/blue_worriedavatar.svg')
export const greyImage =  require('../assets/greyImage.svg')
export const loader =  require('../assets/loader.svg')
// Customizable Area End