// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'

interface S {
    guestXP: number
}

interface SS {
    id: any
}

export interface Props {
    navigation: any
    id: string
    t?: any
}

export default class GuestScoreController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props)

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            guestXP: 0,
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async componentDidMount() {
        const guestXP = this.props.navigation.getParam('guestXP')
        this.setState({ guestXP: guestXP })
    }
}
// Customizable Area End
