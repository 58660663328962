import { Button, TextField, styled } from '@material-ui/core'

export const ButtonCustom = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#36BFFF',
  boxShadow: '0px 4px #00AEFF',
  borderRadius: '12px',
  textTransform: 'none',
  width: '400px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  [theme.breakpoints.down('xs')]: {
    width: '260px',
    fontSize: '16px',
  },
  '&:hover': {
    backgroundColor: '#36BFFF',
    boxShadow: '0px 4px #00AEFF',
  },
  '&.Mui-disabled': {
    color: '#B3B3B3',
    backgroundColor: '#E6E6E6',
    boxShadow: 'unset',
  },
}))

export const SecondaryButtonCustom = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  color: '#7F7F7F',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#fff',
  boxShadow: '0px 4px #999999',
  borderRadius: '12px',
  border: '2px solid #999999',
  textTransform: 'none',
  width: '400px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  [theme.breakpoints.down('xs')]: {
    width: '260px',
    fontSize: '16px',
  },
  '&:hover': {
    backgroundColor: '#fff',
    borderColor: '#999999',
    boxShadow: '0px 4px #999999',
  },
  '&.Mui-disabled': {
    color: '#B3B3B3',
    backgroundColor: '#E6E6E6',
    boxShadow: 'unset',
    borderWidth: '0',
  },
}))

export const SmallButtonCustom = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '33px',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#36BFFF',
  boxShadow: '0px 3px #00AEFF',
  borderRadius: '10px',
  textTransform: 'none',
  minWidth: '70px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#00AEFF',
    boxShadow: '0px 3px #36BFFF',
    '&.Mui-disabled': {
      backgroundColor: '#f7f7f7',
    }
  },
  '&.Mui-disabled': {
    color: '#ccc',
    backgroundColor: '#f7f7f7',
    boxShadow: '0px 3px #ccc',
  },
}))

export const TextInputCustom = styled(TextField)(({ theme }) => ({
  width: '400px',
  [theme.breakpoints.down('xs')]: {
    width: '300px',
  },
  '& fieldset': {
    borderColor: '#e6e6e6',
    borderWidth: '2px',
  },
  '&:hover fieldset': {
    borderWidth: '2px',
    borderColor: '#363636!important',
  },
  '& .Mui-focused fieldset': {
    borderColor: '#363636!important',
  },
  '& .Mui-error fieldset': {
    borderColor: '#F96855!important',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '500',
  },
  '& .MuiOutlinedInput-input': {
    paddingTop: '10.5px',
    paddingBottom: '10.5px',
  },
  '& .MuiFormHelperText-root': {
    margin: '10px 0 0 0',
    fontSize: '14px',
    color: '#999999',
    fontFamily: "Quicksand",
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#F96855',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: '6px',
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: '6px',
  },
  '& .Mui-disabled fieldset': {
    borderColor: '#e6e6e6',
    borderWidth: '2px',
  },
}))
