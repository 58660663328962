Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.notifications = "Notifications";
exports.deleteText = "DELETE";
exports.okText = "OK";
exports.deleteMessage = "Notifications deleted!";
exports.deleteSelectNotificationText = "Select notifications";
exports.deleteRecoverAllText = "Recover all";
exports.deleteDeleteAllText = "Delete all";
exports.deleteNotificationSubHeading = "Notifications indicate the remaining days before deletion. After that time, notifications will be permanently removed. This process may take up to 40 days.";
exports.deleteRecoverText = "Recover";
exports.deleteDeleteText = "Delete";
exports.deleteCancelText = "Cancel";
exports.deleteModalTitleText = "Delete notification";
exports.deleteModalWarningText = "Are you sure you want to delete this notification?";
exports.detailsMarkAsReadText = "Mark as unread";
exports.detailsDeleteText = "Delete";
exports.detailsHeading = "Hi, Amy!";
exports.detailsParaSecondText = "access activated, continue earning XP by accessing new lessons and challenges. The more you learn, the longer you'll enjoy the benefits of";
exports.mainSelectNotifications = "Select notifications";
exports.mainMarkAsAllRead = "Mark all as read";
exports.mainDeleteAllText = "Delete all";
exports.mainShowRecentlyDeleted = "Show recently deleted";
exports.mainMarkAsRead = "Mark as read";
exports.mainDelete = "Delete";
exports.mainCancel = "Cancel";
exports.mainModaltitle = "Delete notification";
exports.mainModalSubTitle = "Are you sure you want to delete this notification?";
exports.topicName = "Algebra";
exports.unitName = "Unit 1: Set of Real Numbers";
exports.postMethod = "POST";
exports.contentType = "application/json";
exports.getMethod ="GET";
exports.statusRead = "read";
exports.statusUnread = "unread"
exports.statusDeleted = "deleted";
exports.statusPermanentlyDeleted = "permenently_deleted";
exports.getAllDeleteNotificationEndPoint = "bx_block_notifications/notifications?status=deleted";
exports.updateNoticationEndPoint = "bx_block_notifications/notifications/update_notifications";
exports.getAllNotificationsEndPoint = "bx_block_notifications/notifications";
exports.getNotificationDetailsEndPoint = "bx_block_notifications/notifications"
// Customizable Area End