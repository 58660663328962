import React from "react"
// Customizable Area Start
import {Box,styled} from "@material-ui/core";
import {loader} from "./assets"
import GradeController, { Props } from "./GradeController.web";
//@ts-ignore
import Graph from "react-graph-vis";
// Customizable Area End

export default class Grade extends GradeController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
   options = {
    autoResize: true,
    physics: {
      enabled: true,
      solver: "forceAtlas2Based",
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 25,
      },
      forceAtlas2Based: {
        gravitationalConstant: -50,
        centralGravity: 0.004,
        springLength: 200,
        springConstant: 0.09,
        damping: 0.4,
        avoidOverlap: 1,
      },
      repulsion: {
        nodeDistance: 300,
        centralGravity: 0.1,
        springLength: 300,
        springConstant: 0.05,
        damping: 0.09,
      },
    },
    nodes: {
      borderWidth:0,
      widthConstraint:false,
      shapeProperties: {
        interpolation:false,
        useImageSize: false,
        borderRadius:0,
        useBorderWithImage:true
      },
      font: {
        multi: true,
        size: 14,
         face: 'Quicksand',
        color:'#666',
        bold: {
          color: "#666"
        }
      },
      
    },

    layout: {
      randomSeed: undefined,
      improvedLayout: true,
    },
    edges: {
      arrows: {
        to: {
          enabled: false,
          imageHeight: undefined,
          imageWidth: undefined,
          scaleFactor: 1,
          src: undefined,
          type: "arrow"
        },
      },
    
      color: {
        color:'#848484',
        border:'unset',
        highlight:'#848484',
        background:'unset',
        hover: '#848484',
        inherit: 'from',
        opacity:1.0
      },
       length: 200,
    },
    interaction: {
      dragNodes: true,
        zoomView: false,
        dragView: false,
    },
  };
  
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
        // Customizable Area Start
        <Box style={{ backgroundColor: '#fff', width: '100%', height: '100%' }}>
          {this.state.loading?<LoadingContainer >
      <img src={loader} alt="Loading" />
      </LoadingContainer>:
        <Graph
        graph={this.state.graph}
        options={this.options}
        events={{ select: this.NodeFunction }}
        style={{ height: "100vh", width: '100%', overflow: 'auto' }}
        data-testid="graph"
      />
          }
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const LoadingContainer=styled(Box)(({theme})=>({
  alignItems: 'center', 
  justifyContent: 'center', 
  height: '100%', 
  display: 'flex', 
  width: "100%",
   "@media(max-width: 959px)": {
    width: '100%', 
   }

}))
// Customizable Area End
