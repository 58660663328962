// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
    getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
import { QuestionData } from '../../educationalgamification/src/MicroLessonExercise'
import { handleCustomExerciseData } from '../../educationalgamification/src/MicroLessonExerciseController'

export const configJSON = require('./config')

interface S {
    isLoading: boolean
    lessonId: string
    exerciseData: QuestionData[]
    questionIndex: number
    guestXP: number
}

interface SS {
    id: any
}

export interface Props {
    navigation: any
    id: string
    t?: any
}

export default class GuestExerciseController extends BlockComponent<
    Props,
    S,
    SS
> {
    getLessonsBySubCategoryId: string = ''

    constructor(props: Props) {
        super(props)

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ]

        this.state = {
            isLoading: false,
            lessonId: '',
            exerciseData: [],
            questionIndex: 0,
            guestXP: 0,
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async componentDidMount() {
        const subCategoryId = this.props.navigation.getParam('subCategoryId')
        const lessonId = this.props.navigation.getParam('lessonId')
        this.getLessonsBySubCategory(subCategoryId)
        this.setState({ lessonId })
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        )
        const httpStatus = message.getData(
            getName(MessageEnum.RestAPIResponceHttpStatus)
        )

        this.apiSuccessCallBackController(apiRequestCallId, responseJson, httpStatus)
    }

    apiSuccessCallBackController = (
        apiRequestCallId: string,
        responseJSON: any,
        httpStatus?: number
    ) => {
        const successCallbackMap = {
            [this.getLessonsBySubCategoryId]: this.handleGetLessonsBySubCategorResponse,
        }

        if (apiRequestCallId) {
            const successCallback: (responseJSON: any, httpStatus?: number) => void = successCallbackMap[apiRequestCallId]
            !!successCallback && successCallback(responseJSON, httpStatus)
        }
    }

    handleGetLessonsBySubCategorResponse = (responseJson: any) => {
        const exercises = responseJson?.data?.find((item: any) => item?.id === this.state.lessonId)
        this.setState({ isLoading: false })
        if (exercises) {
            const assessments = exercises?.attributes?.assessments?.data
            let responseData: any = []
            assessments.forEach((assessment: any) => {
                const questions = assessment?.attributes?.questions?.data
                const customQuestion = questions?.map((question: any) => {
                    return {
                        ...question,
                        assessmentId: assessment?.attributes?.id
                    }
                })
                responseData = [...responseData, ...customQuestion]
            })
            const customResponseData: QuestionData[] = handleCustomExerciseData(responseData)

            return customResponseData?.length > 0 && this.setState({ exerciseData: customResponseData })
        }
    }

    getLessonsBySubCategory = (subCategoryId: string) => {
        this.setState({ isLoading: true })
        this.getLessonsBySubCategoryId = sendAPIRequest(
            `bx_block_profile/lessons/by_sub_category?sub_category_id=${subCategoryId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
    }
}
// Customizable Area End
