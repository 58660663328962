import React from "react";
// Customizable Area Start
import InAppNotificationHeader from "./InAppNotificationHeader.web";
import {
  MenuIcon,
  MarkAsReadDetail,
  DeleteIconDetail,
} from "./assets";
import InAppNotificationDetailsController, {
  Props,
  configJSON,
} from "./InAppNotificationDetailsController.web";
import InAppNotificationMainLayout from "./InAppNotificationLayout.web";
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";

// Customizable Area End

 class InAppNotificationDetails extends InAppNotificationDetailsController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetailNotificationMenu = () => {
    return (
      <ul className="notificationTopMenuListDetail">
        <li
          className="notificationTopMenuListDetailItem"
          onClick={this.inAppUnreadNotificationNotifications}
          data-testid="detailMarkAsUnRead"
        >
          <img
            src={MarkAsReadDetail}
            className="notificationTopMenuListDetailItemImage"
          />
          <p className="notificationTopMenuListDetailItemTitle">
            {this.props.t(configJSON.detailsMarkAsReadText)}
          </p>
        </li>
        <li className="notificationTopMenuListDetailItem" data-testid="deleteNotificationTestID" onClick={this.inAppDeleteNotifications}>
          <img
            src={DeleteIconDetail}
            className="notificationTopMenuListDetailItemImage"
            data-testid="detailDelete"
          />
          <p className="notificationTopMenuListDetailItemTitle">
            {this.props.t(configJSON.detailsDeleteText)}
          </p>
        </li>
      </ul>
    );
  };

  renderDetailMenu = () => {
    return (
      <div className="notificationCenter">
        <div className="notificationTopMenu">
          <div
            className={
              this.state.openNotificationMenu
                ? "notificationTopMenuContainer notificationTopMenuContainerActive"
                : "notificationTopMenuContainer "
            }
            data-testid="detailsMenu"
            onClick={this.handleOpenNotificationMenu}
          >
            <img src={MenuIcon} className="notificationTopMenuIcon" />
            {this.state.openNotificationMenu && this.renderDetailNotificationMenu()}
          </div>
        </div>
      </div>
    );
  };

  renderNotificationDetails = () => {
    return (
      <div className="notificationDetailWrapper">
        <div className="notificationDetailContainer">
          <img src={this.state.notificationDetails.attributes.image} alt="" className="notificationDetailTopIcon" />
          <p className="notificationDetailContainerHeading">
            {this.state.notificationDetails.attributes.subject}
          </p>
          <div className="detailsContent" dangerouslySetInnerHTML={{ __html: this.state.notificationDetails.attributes.content }}></div>
          {this.state.notificationDetails.attributes.is_button === true && 
          <Link to={this.state.notificationDetails.attributes.button_url} className="detailContentButton">
            {this.state.notificationDetails.attributes.button_content}
          </Link>
          }
          <p className="notificationDetailsTime">{this.state.notificationDetails.attributes.time_ago}</p>
        </div>
      </div>
    );
  };

  renderNotificationDetailsMain = () => {
    return (
      <div className="NotificationDetails">
        <InAppNotificationHeader
          heading={this.props.t("Notifications")}
          navigation={{
            navigate: function (screenName: string): void {},
          }}
        />
        {this.renderDetailMenu()}
        {this.renderNotificationDetails()}
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <div className="NotificationDetailsmain">
        <InAppNotificationMainLayout children={this.renderNotificationDetailsMain()} topicName={this.state.topicName} unitName={this.state.unitName} openDrawer={this.state.openDrawer} trophiesOpen={this.state.trophiesOpen} goalsOpen={this.state.goalsOpen} handleOpenDrawer={(name:string)=>this.handleOpenDrawer(name)} navigation={this.props.navigation} data-testid={'centerCardTestId'}/>
        </div>
      </>
    );
    // Customizable Area End
  }
}
export default withTranslation()(InAppNotificationDetails);
