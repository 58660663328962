import React from "react";

// Customizable Area Start
import { Container, Box, Button } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PersonasAvatar from "./personas-avatar/PersonasAvatar";
import SelectOptionsAvatar from "./SelectOptionsAvatar";
import { skinColors } from "./personas-avatar/constants/skin";
import { hairColors, hairs } from "./personas-avatar/constants/hair";
import {
  facialHairs,
  facialHairColors,
} from "./personas-avatar/constants/facial-hair";
import { bodies, bodyColors } from "./personas-avatar/constants/body";
import { eyeses } from "./personas-avatar/constants/eyes";
import mouths from "./personas-avatar/constants/mouth";
import noses from "./personas-avatar/constants/nose";
import { backgroundColors } from "./personas-avatar/constants/background";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import EditAvatarController, {
  Props,
  configJSON,
} from "./EditAvatarController";

export default class EditAvatar extends EditAvatarController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.avatar = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box style={webStyle.avatarIconWrapper}>
            <Box id="avatar" style={webStyle.avatarPng}>
              <PersonasAvatar
                ref={this.avatar}
                characters={this.getCharacter()}
                onNewCharacters={this.randomCharacter}
                style={webStyle.personalAvatar}
              />
            </Box>
          </Box>
          <SelectOptionsAvatar
            data-test-id={"skin-color"}
            type={"skinColors"}
            title={configJSON.selectSkinTitle}
            selectedColor={this.state.skinColor}
            colors={skinColors}
            onColorPress={(value: string) =>
              this.setFacialAttribute("skinColor", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"hair-color"}
            type={"hairs"}
            title={configJSON.selectHairTitle}
            selectedIcon={this.state.hair}
            selectedColor={this.state.hairColor}
            icons={hairs}
            colors={hairColors}
            onIconPress={(value: string) =>
              this.setFacialAttribute("hair", value)
            }
            onColorPress={(value: string) =>
              this.setFacialAttribute("hairColor", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"facial-hair"}
            type={"facialHairs"}
            title={configJSON.selectFacialHairTitle}
            selectedIcon={this.state.facialHair}
            selectedColor={this.state.facialHairColor}
            icons={facialHairs}
            colors={facialHairColors}
            onIconPress={(value: string) =>
              this.setFacialAttribute("facialHair", value)
            }
            onColorPress={(value: string) =>
              this.setFacialAttribute("facialHairColor", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"body-color"}
            type={"body"}
            title={configJSON.selectBodyTitle}
            selectedIcon={this.state.body}
            selectedColor={this.state.bodyColor}
            icons={bodies}
            colors={bodyColors}
            onIconPress={(value: string) =>
              this.setFacialAttribute("body", value)
            }
            onColorPress={(value: string) =>
              this.setFacialAttribute("bodyColor", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"eyes"}
            type={"eyes"}
            title={configJSON.selectEyesTitle}
            selectedIcon={this.state.eyes}
            icons={eyeses}
            onIconPress={(value: string) =>
              this.setFacialAttribute("eyes", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"mouth"}
            type={"mouths"}
            title={configJSON.selectMouthTitle}
            selectedIcon={this.state.mouth}
            icons={mouths}
            onIconPress={(value: string) =>
              this.setFacialAttribute("mouth", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"nose"}
            type={"noses"}
            title={configJSON.selectNoseTitle}
            selectedIcon={this.state.nose}
            selectedColor={skinColors[this.state.skinColor]}
            icons={noses}
            onIconPress={(value: string) =>
              this.setFacialAttribute("nose", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <SelectOptionsAvatar
            data-test-id={"background-color"}
            type={"backgroundColors"}
            title={configJSON.selectBgcTitle}
            colors={backgroundColors}
            selectedColor={skinColors[this.state.skinColor]}
            onColorPress={(value: string) =>
              this.setFacialAttribute("bgColor", value)
            }
            open={this.state.open}
            openToggle={this.openOption}
          />

          <Box style={webStyle.btnWrapper}>
            <Button
              data-test-id={"randomAvatar"}
              onClick={() =>
                this.avatar.current && this.avatar.current.randomize()
              }
              variant="contained"
              color="primary"
              style={webStyle.btn}
            >
              {configJSON.randomBtnTitle}
            </Button>
            <Button
              data-test-id={"createAvatar"}
              onClick={this.onCapture}
              variant="contained"
              color="primary"
              style={webStyle.btn}
            >
              {configJSON.saveBtnTitle}
            </Button>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  btnWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row" as "row",
    paddingTop: 15,
  },
  btn: {
    marginLeft: 10,
  },
  avatarIconWrapper: {
    width: 180,
    margin: "0 auto",
    marginTop: 20,
  },
  avatarPng: {
    backgroundColor: "transparent",
    width: 180,
  },
  personalAvatar: {
    alignSelf: "center" as "center",
    height: 180,
    width: 180,
  },
};
// Customizable Area End
