// Customizable Area Start
import React from 'react'

import { Container, Typography, Box, styled } from '@material-ui/core'
import { Country } from '../../accountgroups/src/EditAccountController'
import CategoriessubcategoriesController, {
  Props,
} from './CategoriessubcategoriesController.web'
import { ButtonCustom } from '../../../components/src/MaterialTheme'
import SelectLanguage from '../../../components/src/SelectLanguage'
import Header from '../../../components/src/Header'
import { withTranslation } from 'react-i18next'
import CustomSelect from '../../../components/src/CustomSelect'
import { loader } from '../../stripepayments/src/assets'
 class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props)
  }
  
  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  handleSelectCountry = (item: string) => {
    this.setState({ selectedCountry: item })
    const selectedCountryData: Country | undefined = this.state.countriesData.find((items:any) => items.attributes.name == item)
    this.getGradesByCountry(selectedCountryData?.id || 138)
  }

  handleClickContinue = () => {
    const selectedGradeId = this.state.listGrades.find(item => item.attributes.name === this.state.selectedGrade)?.id
    this.props.navigation.navigate('ExploreLesson', {gradeId: selectedGradeId})
  }

  renderMainContent = () => {
    if (this.state.isLoading) {
      return (
        <Box className='mainContainWrapper' style={{justifyContent: 'center'}}>
          <img src={loader} alt="Loading" />
        </Box>
      )
    }

    return (
      <Box className='mainContainWrapper'>
        <div className="gridContainer">
          {this.state.listGrades.map((grade) => (
            <div
              key={grade.id}
              className={
                grade.attributes.name === this.state.selectedGrade
                  ? 'gradeWrapper gradeSelected'
                  : 'gradeWrapper'
              }
              onClick={() => this.setState({ selectedGrade: grade.attributes.name })}
            >
              <Typography className="gradeText">{grade.attributes.name}</Typography>
            </div>
          ))}
        </div>
        <ButtonCustom
          variant="contained"
          disabled={this.state.selectedGrade === ''}
          className="continueBtn"
          onClick={this.handleClickContinue}
        >
          {this.props.t("Continue")}
        </ButtonCustom>
      </Box>
    )
  }

  render() {
    return (
      <CategoriesContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentWrapper" maxWidth="md">
          <Box className="selectGradeContainer">
            <Typography className="categoryTitle">{this.props.t("Pick your academy journey")}</Typography>
            <Typography className="categoryDesc">{this.props.t("Select your country and grade")}</Typography>
            <CustomSelect
              data-testId='selectCountry'
              list={this.state.countriesDisplayList}
              value={this.state.selectedCountry}
              showSearchInput
              setValue={this.handleSelectCountry}
            />
            {this.renderMainContent()}
          </Box>
          <SelectLanguage style={{ marginBottom: '72px', marginTop: '24px' }} />
        </Container>
      </CategoriesContainer>
    )
  }
}
export default withTranslation()(Categoriessubcategories)
const CategoriesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '& .contentWrapper': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .categoryTitle': {
    fontSize: '22px',
    fontWeight: '900',
    textAlign: 'center',
    fontFamily: 'Nunito',
    color: '#363636',
  },

  '& .categoryDesc': {
    color: '#666666',
    marginTop: '15px',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    marginBottom: '85px',
  },

  '& .mainContainWrapper': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  '& .selectGradeContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '52px',
  },

  '& .gridContainer': {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    rowGap: '15px',
    columnGap: '15px',
    marginTop: '48px',
  },

  '& .gradeWrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '96px',
    width: '140px',
    borderRadius: '12px',
    border: '2px solid grey',
    boxShadow: '0px 4px 2px #999999',
    cursor: 'pointer',
    color: '#7f7f7f',

    '&:hover': {
      color: '#06aeff',
      borderColor: '#36BFFF',
      boxShadow: '0px 5px 2px #36BFFF',
    },
  },

  '& .gradeSelected': {
    color: '#06aeff',
    borderColor: '#36BFFF',
    boxShadow: '0px 5px 2px #36BFFF',
  },

  '& .gradeText': {
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
  },

  '& .continueBtn': {
    marginTop: '73px'
  },

  [theme.breakpoints.up('md')]: {
    '& .categoryTitle': {
      fontSize: '24px',
    },

    '& .categoryDesc': {
      fontSize: '20px',
      marginTop: '10px',
      marginBottom: '69px',
    },

    '& .gradeText': {
      fontSize: '18px',
    },

    '& .gridContainer': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      rowGap: '50px',
      columnGap: '20px',
    },

    '& .continueBtn': {
      marginTop: '87px'
    }
  },
}))
// Customizable Area End
