Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cftableforlessons";
exports.labelBodyText = "cftableforlessons Body";
exports.lessonProgressAPIEndPoint = "bx_block_educational_gamification/start_end_learning"
exports.btnExampleTitle = "CLICK ME";
exports.getApiMethod = "GET";
exports.VideoDetailAPiEndPoint ="bx_block_profile/list_micro_lessons"
exports.postApiMethod = "POST";
exports.reportproblemApiEndPoint ="bx_block_request_management/report_problem"

// Customizable Area End