import React from "react";
// Customizable Area Start
import PremiumSkipUserController from "./PremiumSkipUserController.web";
import { withTranslation } from "react-i18next";
import { Box, IconButton, Typography, styled } from "@material-ui/core";
import { Classified, TeddyArm, TeddyLine, TeddyRectangle, close } from "./assets";
import { ButtonCustom } from "../../../components/src/MaterialTheme";
// Customizable Area End

class PremiumSkipUser extends PremiumSkipUserController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t } = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <SettingContainer>
        <Box className="main_container">
          <Box className="closeIcons">
            <IconButton data-testid="close_icon" onClick={()=>this.props.navigation.goBack()}>
              <img src={close} alt="close_Icon" />
            </IconButton>
          </Box>
          <Box className="container">
            <Box className="image_position">
              <img src={Classified} alt="classified_icon" />

              <Box className="Text_container">
                <Typography className="bold_text">{t("Classified")}:</Typography>
                <Typography className="bold_text">{t("Premium Adventure Awaits")}</Typography>
              </Box>
              <Box className="description_text_box">
                <Typography className="description_text_style">{("Did you know? Premium opens a world of fun and learning opportunities. Ascend this journey to a whole new level!")}</Typography>
              </Box>
            </Box>
          </Box>

          <Box className="footer_container">
            <Typography className="footer_text">{t("*Cancel anytime – no strings attached!")}</Typography>
          </Box>

        </Box>
        <Box className="action_container">
          <Box>
            <ButtonCustom data-testid="upgrade_btn" onClick={this.handlePremiumScreen} className="button_style">{t("Upgrade now!")}</ButtonCustom>
          </Box>
          <Box className="later_text_box">
            <Typography data-testid="later_btn" onClick={()=>this.props.navigation.goBack()} className="Later_text"> {t("Later")}</Typography>
          </Box>
          <Box className="teddy_image_container">
              <img src={TeddyArm}  alt="TeddyArm"/>
          </Box>
          <Box className="teddy_image_container1">
              <img src={TeddyRectangle} alt="taddy_ractangle"/>
          </Box>
          <Box className="teddy_image_container2">
              <img src={TeddyLine}  alt="TeddyLine"/>
          </Box>
        </Box>
      </SettingContainer>
      // Customizable Area End
    )
  }

}
// Customizable Area Start
export default withTranslation()(PremiumSkipUser)
export const SettingContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: "100%",
  position:'relative',
  '& .main_container': {
    background: "linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)",
    overflowX: "hidden",
    position: 'relative',
    margin: 0,
    height: "100%",
    maxHeight:"690px",
    padding: 20,
    '&::after': {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "calc(100% - 0px)",
      pointerEvents: "none",
      backgroundImage: `repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 36px,
      rgba(0, 0, 0, 0.06) 36px,
      rgba(0, 0, 0, 0.08) 40px
  )`
    },
  },

  "& .container": {
    display: 'flex',
    position: 'relative',
    height: '80%',
    width: "100%",
    justifyContent: 'center',
  },

  "& .image_position": {
    position: 'absolute',
    alignSelf: 'center',
    zIndex: 9,
    textAlign: 'center'
  },

  "& .closeIcons": {
    left: 0,
    marginTop: 20
  },

  "& .Text_container": {
    width: "100%",
    marginTop: 20,
    textAlign: "center"
  },

  "& .bold_text": {
    fontFamily: "Nunito",
    color: "#FFFFFF",
    fontSize: "22px",
    fontWeight: 900,
    lineHeight: "26px",
    letterSpacing: "0.008em",
    textAlign: "center",
  },

  "& .description_text_box": {
    marginTop: 32,
    marginBottom: 32,
    width:"312px"
  },

  "& .description_text_style": {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "center",
    color: "#F7F7F7"
  },

  "& .footer_text": {
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "20px",
    color: "#F7F7F79E",
    letterSpacing: "0px",
    textAlign: "center",
  },

  "& .action_container": {
    height: "180px",
    zIndex: 10,
    position:'relative',
    display: "flex",
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: "center"
  },

  "& .button_style": {
    width: "260px"
  },

  "& .Later_text": {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "13px",
    color:"#00AEFF",
    letterSpacing: "0px",
    textAlign: "center",
  },
  "& .later_text_box":{
    marginTop:28
  },
"& .teddy_image_container":{
  position:'absolute',
  display:'flex',
  alignSelf:'flex-end',
  bottom:0,
  marginRight:38
},
"& .teddy_image_container2":{
  position:'absolute',
  display:'flex',
  alignSelf:'flex-end',
  bottom:62,
  zIndex:-2,
  marginRight:39
},
"& .teddy_image_container1":{
  position:'absolute',
  display:'flex',
  alignSelf:'flex-end',
  bottom:52,
  zIndex:-1,
  marginRight:35
}
}))
// Customizable Area End