// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    subscriptionDaysLeft: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
export default class SubscriptionExperingController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);

        this.handleClickLater = this.handleClickLater.bind(this);
        this.handleClickSkipClose = this.handleClickSkipClose.bind(this);

        this.state = {
            subscriptionDaysLeft: ""
        }
    }

    handleClickLater() {
        this.props.navigation.navigate("PremiumDashboard")
    }

    handleClickSkipClose() {
        this.props.navigation.goBack();
    }

    async componentDidMount() {
        const subscriptionEndsIn = localStorage.getItem('subscriptionEndsIn') || '';

        this.setState({ subscriptionDaysLeft: subscriptionEndsIn });
    }
}

// Customizable Area End