import React, { useRef, useEffect, useState } from 'react'
import { Box, Button, Typography, styled, useTheme, useMediaQuery } from '@material-ui/core'

import {
  Option,
  QuestionProps,
  StyledButton,
  reportOptions,
} from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  blueFlag,
  greenFlag,
  greenShare,
  groupConfetti,
  noteBookLines,
  redFlag,
  redShare,
} from '../../../blocks/cftableforlessons/src/assets'
import GroupActionsExercise from './GroupActionsExercise'
import ActionAlert from './ActionAlert'
import ReportModal from '../../../blocks/cftableforlessons/src/ReportModal'
import WellDoneShare from '../../../blocks/cftableforlessons/src/WellDoneShare'
import ThankReportModal from '../../../blocks/cftableforlessons/src/ThankReportModal'
import { convertLatex } from '../utils'
import { QuestionText, HintText, MathFormula } from './ExerciseComponents'
import { useTranslation } from 'react-i18next'
import { teddyWrite } from '../../../blocks/educationalgamification/src/assets'
import SolutionPage from './SolutionPage'
import ShareModal from './ShareModal.web'
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../assets'

const SingleChoiceExercise: React.FC<QuestionProps> = ({
  onClickContinue,
  assessmentId,
  attributes,
  onSubmitExerciseAnswer,
  exerciseAnswer,
  isLastQuestion,
  onSelectReportOption,
  onSubmitReport,
  isGuest,
  onUpdateXP,
  lessonId,
  microLessonId,
  lessonAttemptId,
  shareRecordType,
}) => {
  const { hint, options, question, solution, questionBody, questionType, id } = attributes
  const [showInCorrectAnswer, setShowInCorrectAnswer] = useState(false)
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const [showHintAlert, setShowHintAlert] = useState(false)
  const [showSolutionAlert, setShowSolutionAlert] = useState(false)
  const [showSkipAlert, setShowSkipAlert] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)
  const [isDisableSelectOption, setIsDisableSelectOption] = useState(false)
  const [showWellDoneShareModal, setShowWellDoneShareModal] = useState(false)
  const [selectedAnswer, setSelectedAnswer] = useState<Option | null>(null)
  const [isCheckAnswer, setIsCheckAnswer] = useState(false)
  const [showThankReportModal, setShowThankReportModal] = useState(false)
  const [showHint, setShowHint] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const { t } = useTranslation()

  const boxRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [solutionFromValue, setSolutionFromValue] = useState<'SOLUTION' | 'INCORRECT' | null>(null)
  const [startTime, setStartTime] = useState(0)

  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))

  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': orangePartyTeddy,
    '#62cdff': bluePartyTeddy,
    '#b7f0ad': mintPartyTeddy,
    '#a95edb': purplePartyTeddy,
    '#f96855': redPartyTeddy,
    '#884a39': brownPartyTeddy,
    '#fdce36': yellowPartyTeddy,
    '#2bce8c': greenPartyTeddy
  };

  const partyTeddy = colorToUpdateTeddy[avatarColor] || bluePartyTeddy;

  useEffect(() => {
    if (exerciseAnswer) {
      const answeOptionId = JSON.parse(exerciseAnswer?.attributes?.userAnswer)?.id
      const answeOption = options.find(item => item.attributes.id === answeOptionId)
      if (answeOption) {
        setSelectedAnswer(answeOption)

        setIsDisableSelectOption(true)
        setIsCheckAnswer(true)

        if (answeOption?.attributes.isCorrect) {
          setShowCorrectAnswer(true)
        } else {
          setShowInCorrectAnswer(true)
        }
      }
    } else {
      setStartTime(Date.now())
    }
  }, [exerciseAnswer, options])


  useEffect(() => {
    const updateWidth = () => {
      if (boxRef.current) {
        setWidth(boxRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const checkOptionStatus = (option: Option) => {
    if (option.id === selectedAnswer?.id) {
      if (isCheckAnswer) {
        if (option.attributes.isCorrect) {
          return {
            borderColor: '#2BCE8C',
            color: '#2BCE8C',
          }
        } else {
          return {
            borderColor: '#F96855',
            color: '#F96855',
          }
        }
      } else {
        return { borderColor: '#62CDFF', color: '#62CDFF' }
      }
    } else {
      return { borderColor: '#E6E6E6', color: '#7F7F7F' }
    }
  }

  return (
    <SingleChoiceExerciseContainer {...{ ref: boxRef }}>
      {solutionFromValue ? (
        <SolutionPage
          solutionContent={solution}
          onContinue={() => {
            const param = solutionFromValue === 'INCORRECT' ? { isAnswerWrong: true } : { isUseSolution: true }
            onClickContinue(param)
          }}
          width={isMediumScreen ? width - 120 : width - 52}
          onClickReport={() => setShowReportModal(true)}
          isGuest={isGuest}
        />
      ) : (
        <Box className="mainContainer">
          <Box className="questionWrapper">
            <QuestionText question={question} width={isMediumScreen ? width - 120 : width - 52} />
            <Box className="imageBg">
              <img src={teddyWrite} style={{ width: '94px', height: '107px', top: '50%', right: '-60px', transform: 'translate(0, -50%)', position: 'absolute' }} />
              <MathFormula
                formula={convertLatex(typeof questionBody === 'string' ? questionBody : '')}
                color='#363636'
                fontFamily='Quicksand'
                fontSize={20}
                fontWeight={800}
                lineHeight='16px'
                inline={false}
                width={isMediumScreen ? width - 200 : width - 140}
              />
            </Box>
            <Box>
              {options?.map((option) => {
                const optionColor = checkOptionStatus(option)
                const lowerCaseOptionText = option.attributes.option.toLowerCase()
                return (
                  <QuestionOption
                    key={option.id}
                    onClick={() => {
                      if (!isDisableSelectOption) {
                        setSelectedAnswer(option)
                      }
                    }}
                    style={{
                      boxShadow: `0px 2px ${optionColor.borderColor}`,
                      borderColor: optionColor.borderColor,
                    }}
                  >
                    <Typography
                      className="optionText"
                      style={{
                        color: optionColor.color,
                      }}
                    >
                      {`${lowerCaseOptionText
                        .charAt(0)
                        .toUpperCase()}${lowerCaseOptionText.slice(1)}`}
                    </Typography>
                  </QuestionOption>
                )
              })}
            </Box>
          </Box>
          {!showCorrectAnswer &&
            !showInCorrectAnswer &&
            !showHint && (
              <GroupActionsExercise
                isDisableButton={selectedAnswer === null}
                onCheckAnswer={() => {
                  setIsDisableSelectOption(true)
                  setIsCheckAnswer(true)
                  if (selectedAnswer?.attributes.isCorrect) {
                    setShowCorrectAnswer(true)
                  } else {
                    setShowInCorrectAnswer(true)
                  }

                  const endTime = Date.now();
                  const elapsedTimeInSeconds = (endTime - startTime) / 1000;
                  const correctAnswerOption = options?.find(item => item?.attributes?.isCorrect)
                  const userAnswer = JSON.stringify({
                    text: selectedAnswer?.attributes.option,
                    id: selectedAnswer?.attributes.id
                  })
                  const submitData = {
                    assessmentId: assessmentId,
                    questionBankId: attributes?.id,
                    questionType: questionType,
                    questionBody: questionBody,
                    correctAnswer: JSON.stringify({
                      text: correctAnswerOption?.attributes.option,
                      id: correctAnswerOption?.attributes.id
                    }),
                    userAnswer: userAnswer,
                    correct: selectedAnswer?.attributes.isCorrect,
                    grade: selectedAnswer?.attributes.isCorrect ? 10 : 0,
                    timeCompleteInSeconds: Math.floor(elapsedTimeInSeconds),
                    lessonCompleted: isLastQuestion,
                  }
                  onSubmitExerciseAnswer && onSubmitExerciseAnswer(submitData)
                }}
                onShowSolutionAlert={() => {
                  const isSkipShowSolutionAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSolutionAlertMicroLesson')
                  )
                  if (isSkipShowSolutionAlertMicroLesson) {
                    setSolutionFromValue('SOLUTION')
                    setIsDisableSelectOption(true)
                  } else {
                    setShowSolutionAlert(true)
                  }
                }}
                onShowSkipAlert={() => {
                  const isSkipShowSkipAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowSkipAlertMicroLesson')
                  )
                  if (isSkipShowSkipAlertMicroLesson) {
                    onClickContinue({ isSkip: true })
                  } else {
                    setShowSkipAlert(true)
                  }
                }}
                onShowHintAlert={() => {
                  const isSkipShowHintAlertMicroLesson = Boolean(
                    localStorage.getItem('isSkipShowHintAlertMicroLesson')
                  )
                  if (isSkipShowHintAlertMicroLesson) {
                    setShowHint(true)
                  } else {
                    setShowHintAlert(true)
                  }
                }}
              />
            )}
        </Box>
      )}
      {showHint && (
        <Box className="solutionWrapper">
          <Box className="solutionContentWrapper">
            <HintText hint={hint} width={isMediumScreen ? width - 120 : width - 52}  />
            {isGuest ? (<Box />) : (
              <img
                src={blueFlag}
                onClick={() => setShowReportModal(true)}
                width={15}
                height={15}
              />
            )}
          </Box>
          <StyledButton
            onClick={() => {
              setShowHint(false)
            }}
          >
            {t("Continue")}
          </StyledButton>
        </Box>
      )}
      {showInCorrectAnswer && (
        <Box className="incorrectAnswerWrapper">
          <Box className="incorrectAnswerContentWrapper">
            <Box>
              <Typography className="incorrectAnswerTitle">
                {t("Oh oh! Correct answer:")}
              </Typography>
              <Typography className="incorrectAnswerDesc">
                {t("Continue to see the solution...")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={redFlag}
                  width={15}
                  onClick={() => setShowReportModal(true)}
                  height={15}
                />
                <img
                  src={redShare}
                  width={15}
                  onClick={() => setShareModalOpen(true)}
                  height={15}
                />
              </Box>
            )}
          </Box>
          <InCorrectAnswerButton
            onClick={() => {
              setShowInCorrectAnswer(false)
              if (exerciseAnswer) {
                setSolutionFromValue('SOLUTION')
              } else {
                setSolutionFromValue('INCORRECT')
              }
            }}
          >
            {t("Continue")}
          </InCorrectAnswerButton>
        </Box>
      )}
      {showCorrectAnswer && (
        <Box className="correctAnswerWrapper">
          <Box className="correctAnswerContentWrapper">
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <img src={partyTeddy} className="partyTeddy" />
              <Typography className="correctAnswerText">
                {t("Awesome work!")}
              </Typography>
            </Box>
            {isGuest ? (<Box />) : (
              <Box style={{ display: 'flex', gap: '10px' }}>
                <img
                  src={greenFlag}
                  height={15}
                  width={15}
                  onClick={() => setShowReportModal(true)}
                />
                <img
                  src={greenShare}
                  height={15}
                  width={15}
                  onClick={() => setShareModalOpen(true)}
                />
              </Box>
            )}
          </Box>
          <CorrectAnswerButton
            onClick={() => {
              const param = exerciseAnswer ? {} : { isAnswerCorrect: true }
              onClickContinue(param)
            }}
          >
            {t("Continue")}
          </CorrectAnswerButton>
        </Box>
      )}

      <ShareModal id="share-modal" 
        open={shareModalOpen} 
        handleClickClose={() => setShareModalOpen(false)}
        assessmentId={assessmentId}
        questionBankId={id}
        lessonId={lessonId}
        microLessonId={microLessonId}
        shareRecordType={shareRecordType}
        lessonAttemptId={lessonAttemptId}
        onAfterShare={() => {
          setShareModalOpen(false)
          setShowWellDoneShareModal(true)
        }}
        onUpdateXp={onUpdateXP}
      >
        <Box style={{width: '100%', padding: '24px 16px', textAlign: 'left'}}>
          <QuestionText question={question} width={308} isSharing />
          <Box style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${noteBookLines})`,
            padding: '16px',
            border: '2px solid #E6E6E6',
            borderRadius: '8px',
            position: 'relative',
            marginTop: '-32px',
            marginBottom: '24px'
          }}>
            <img src={teddyWrite} style={{ width: '36px', height: 'auto', top: '50%', right: '-16px', transform: 'translate(0, -50%)', position: 'absolute' }} />
            <MathFormula
              formula={convertLatex(typeof questionBody === 'string' ? questionBody : '')}
              color='#363636'
              fontFamily='Quicksand'
              fontSize={14}
              fontWeight={800}
              lineHeight='6px'
              inline={false}
              width={276}
            />
          </Box>
          <Box style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            {options?.map((option) => {
              const optionColor = checkOptionStatus(option)
              const lowerCaseOptionText = option.attributes.option.toLowerCase()
              return (
                <QuestionOption
                  key={option.id}
                  style={{
                    boxShadow: `0px 2px ${optionColor.borderColor}`,
                    borderColor: optionColor.borderColor,
                    width: '260px',
                    marginBottom: '12px',
                    minHeight: '36px'
                  }}
                >
                  <Typography
                    className="optionText"
                    style={{
                      color: optionColor.color,
                      fontSize: '12px'
                    }}
                  >
                    {`${lowerCaseOptionText
                      .charAt(0)
                      .toUpperCase()}${lowerCaseOptionText.slice(1)}`}
                  </Typography>
                </QuestionOption>
              )
            })}
          </Box>
        </Box>
      </ShareModal>

      <ActionAlert
        open={showSolutionAlert}
        onClose={() => setShowSolutionAlert(false)}
        title={t("Solution Alert")}
        description={t("Please note that choosing to view the solution will not earn you any XP.")}
        actionText={t("Solution")}
        onClickAction={() => {
          setShowSolutionAlert(false)
          setIsDisableSelectOption(true)
          setSolutionFromValue('SOLUTION')
        }}
        actionType="SOLUTION"
      />
      <ActionAlert
        open={showHintAlert}
        onClose={() => setShowHintAlert(false)}
        title={t("Hint Alert")}
        description={t("If you choose to access a hint, you won't earn any XP.")}
        actionText={t("Show hint")}
        actionType="HINT"
        isHint
        onClickAction={() => {
          setShowHintAlert(false)
          setShowHint(true)
        }}
      />
      <ActionAlert
        open={showSkipAlert}
        onClose={() => setShowSkipAlert(false)}
        title={t("Skip Alert")}
        description={t(`skipDescription`)}
        actionText={t("Skip")}
        actionType="SKIP"
        onClickAction={() => onClickContinue({ isSkip: true })}
      />
      <ReportModal
        reportOptions={reportOptions}
        title={t("Report a problem")}
        onClose={() => setShowReportModal(false)}
        open={showReportModal}
        handleOption={(value: string) => {
          onSelectReportOption && onSelectReportOption(value)
        }}
        onSubmitReport={() => {
          onSubmitReport && onSubmitReport()
          setShowThankReportModal(true)
        }}
      />
      <WellDoneShare
        isExerciseShare
        open={showWellDoneShareModal}
        onClose={() => setShowWellDoneShareModal(false)}
      />
      <ThankReportModal
        open={showThankReportModal}
        onClose={() => setShowThankReportModal(false)}
      />
    </SingleChoiceExerciseContainer>
  )
}

const SingleChoiceExerciseContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',

  '& .mainContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '32px 26px 0',
  },

  '& .questionWrapper': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },

  '& .imageBg': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${noteBookLines})`,
    padding: '36px',
    border: '2px solid #E6E6E6',
    borderRadius: '8px',
    marginBottom: '52px',
    position: 'relative',
    minWidth: '80%',
  },

  '& .solutionWrapper': {
    width: '100%',
    backgroundColor: '#C9EEFF',
    borderRadius: '20px 20px 0 0',
    padding: '36px 26px 40px',
  },

  '& .solutionContentWrapper': {
    marginBottom: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .solutionText': {
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '900',
    color: '#00AEFF',
  },

  '& .correctAnswerWrapper': {
    width: '100%',
    backgroundImage: `url(${groupConfetti})`,
    backgroundColor: '#d5f5e8',
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
  },

  '& .correctAnswerContentWrapper': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .partyTeddy': {
    marginLeft: '12px',
    marginBottom: '-28px',
    height: '84px',
    width: '84px',
  },

  '& .correctAnswerText': {
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '900',
    marginLeft: '10px',
    color: '#2BCE8C',
  },

  '& .incorrectAnswerWrapper': {
    width: '100%',
    padding: '36px 26px 40px',
    borderRadius: '20px 20px 0 0',
    backgroundColor: '#fee1dd',
  },

  '& .incorrectAnswerContentWrapper': {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .incorrectAnswerTitle': {
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontWeight: '900',
    color: '#F96855',
  },

  '& .incorrectAnswerDesc': {
    fontFamily: 'Quicksand',
    color: '#F96855',
    fontWeight: '600',
    fontSize: '18px',
  },

  [theme.breakpoints.up('md')]: {
    '& .mainContainer': {
      padding: '32px 60px 0',
    },
  },
}))

export const QuestionOption = styled(Box)(() => ({
  minHeight: '42px',
  width: '300px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '12px',
  marginBottom: '15px',
  padding: '4px 20px',
  boxShadow: '0px 2px #E6E6E6',
  border: '2px solid #E6E6E6',
  cursor: 'pointer',

  '& .optionText': {
    fontFamily: 'Quicksand',
    color: '#7F7F7F',
    fontSize: '16px',
    fontWeight: '700',
  },
}))

export const CorrectAnswerButton = styled(Button)(({ theme }) => ({
  height: '40px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontStyle: 'normal',
  fontSize: '20px',
  fontWeight: 700,
  letterSpacing: '0.8px',
  boxShadow: '0px 4px #22A06D',
  backgroundColor: '#2BCE8C',
  textTransform: 'none',
  borderRadius: '12px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    boxShadow: '0px 4px #22A06D',
    backgroundColor: '#2BCE8C',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
  '&.Mui-disabled': {
    backgroundColor: '#E6E6E6',
    color: '#B3B3B3',
    boxShadow: '0px 4px #F2F2F2',
  },
}))

export const InCorrectAnswerButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  height: '40px',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Nunito',
  textAlign: 'center',
  fontSize: '20px',
  fontWeight: 700,
  color: 'white',
  fontStyle: 'normal',
  letterSpacing: '0.8px',
  textTransform: 'none',
  boxShadow: '0px 4px #ea5948',
  backgroundColor: '#f96855',
  borderRadius: '12px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#f96855',
    boxShadow: '0px 4px #ea5948',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
  '&.Mui-disabled': {
    boxShadow: '0px 4px #F2F2F2',
    color: '#B3B3B3',
    backgroundColor: '#E6E6E6',
  },
}))

export default SingleChoiceExercise
