import React from "react"
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
} from "@material-ui/core";
import {
    downnside,
    horizontal,
    upside
} from "./assets";
import ChallengeModal from "./ChallengeModal.web";
import ChallengeShuffleModal from "./ChallengeShuffleModal.web";
import ChallengeUnitTopicsController from "./ChallengeUnitTopicsController.web";

const side = [
    { side: upside },
    { side: horizontal },
    { side: downnside }
]
// Customizable Area End

export default class ChallengeUnitTopic extends ChallengeUnitTopicsController {

    // Customizable Area Start


    PatternIndex = (index: number) => {
        const num = index % 3
        return num
    }

    getItemContainer = (indexNumber: number) => {
        return (<Box className={this.props.data.length - 1 !== indexNumber ? "nodeBoxContainer" : "nodeBoxContainer1"}>
            {this.props.data.slice(indexNumber, indexNumber + 2).map((item: any, index: number) => {
                return (
                    <Box key={index} className="nodeBox" >
                        <Box className="ImageTextContainer">
                            <Box style={{ textAlign: 'center' }} >
                                <Box>
                                    <Box data-testid={`getMaterialIcons${indexNumber + index}`} style={{ cursor: 'pointer' }} onClick={() => this.getChallenge()}>
                                        <img src={item.image} width={88} height={88} style={{ borderRadius: '50%', border: '4px solid rgba(43, 206, 140, 0.23)', padding: 4 }} />
                                    </Box>
                                </Box>
                                <Box style={{ width: '100%', maxWidth: "150px", padding: "0 10px" }}>
                                    <Typography className="lesson-name">
                                        {item.label.replace("\n", " ")}
                                    </Typography>
                                </Box>
                            </Box>
                            {(index === 0 && this.props.data.length - 1 !== indexNumber) && <Box style={{ marginTop: '-50px', padding: "0px 24px" }}>
                                <img src={side[1].side} />
                            </Box>}
                        </Box>
                    </Box>
                )
            })}
        </Box>
        )
    }

    UpsideclassName = (index: number) => {
        return index === 0 ? "upsideImage" : "upsideImage2"
    }

    TextContainer = (index: number) => {
        return index === 0 ? "textContainer" : "textContainer2"
    }

    BoxImageContainer = (index: number) => {
        return index === 0 ? "BoxImageContainer" : "BoxImageContainer2"
    }

    DownSideImage = (index: number) => {
        return index === 0 ? "downsideImage" : "downsideImage2"
    }

    getImageColor = (item: any) => {
        return 'withoutMicroLesson'
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <SettingContainer>
                <>
                    {this.props.data?.map((item: any, index: number) => {
                        const patternIndex = this.PatternIndex(index)
                        if (patternIndex === 0) {
                            return (
                                <Box key={index} className={this.BoxImageContainer(index)} >
                                    <Box className="BoxContainer" > {this.props.data.length - 1 !== index ? <Box className={this.DownSideImage(index)}> <img src={side[2].side} />
                                    </Box> :
                                        <Box style={{ marginRight: 60 }}></Box>}
                                        <Box data-testid={`getMaterialIcons${index}`} style={{ cursor: 'pointer', }} onClick={() => this.getChallenge()}>
                                            <img src={item.image}
                                                className={`${this.getImageColor(item)}`}
                                                width={88} height={88} />
                                        </Box>{index !== 0 && <Box className={this.UpsideclassName(index)}><img src={side[0].side} />
                                        </Box>}
                                    </Box>
                                    <Box className={this.TextContainer(index)}>
                                        <Typography className="lesson-name" >{item.label.replace("\n", " ")}</Typography>
                                    </Box>
                                </Box>
                            )
                        }
                        else if (index === 1 || patternIndex === 1) {
                            return this.getItemContainer(index)
                        }
                    })}

                    {this.state.Challenge &&
                        <ChallengeModal
                            data-testid="ChallengeModal"
                            open={this.state.challengeModal}
                            onClose={() => this.handleClose()}
                            navigation={this.props.navigation}
                            Challenge={this.state.Challenge}
                        />}
                    <ChallengeShuffleModal
                        data-testid="ChallengeShuffleModal"
                        open={this.state.challengeShuffleModal}
                        onClose={() => this.setState({ challengeShuffleModal: false })}
                    />

                </>
            </SettingContainer>
            // Customizable Area End
        )

    }

}

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    padding: '10px',
    width: "100%",
    background: '#DCFFD6',
    paddingTop: 20,
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    '@media(max-width:960px)': {
        marginBottom: 60
    },

    '& .BoxContainer': {
        display: 'flex',
        alignItems: 'center'
    },

    '& .lesson-name': {
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "15px", /* 93.75% */
        letterSpacing: "0.32px",
        textAlign: 'center',
        color: "var(--secondary-green, #2BCE8C)",
        fontFamily: "Nunito",
    },

    '& .nodeBox': {
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        marginTop: 0
    },

    '& .nodeBoxContainer': {
        display: 'flex',
        width: "100%",
        marginTop: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },

    '& .nodeBoxContainer1': {
        display: 'flex',
        width: "50%",
        marginLeft: '7%',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
        '@media(max-width:570px)': {
            marginLeft: 0
        }
    },

    '& .mainWhiteBox': {
        backgroundColor: '#FF8400'
    },
    '& .withoutMicroLesson': {
        borderRadius: '50%',
        padding: 4,
        border: '4px solid rgba(43, 206, 140, 0.23)'
    },

    '& .BoxImageContainer2': {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        flexDirection: 'column',
        marginLeft: "",
        marginBotton: ""
    },
    '& .BoxImageContainer': {
        display: 'flex',
        marginLeft: 90,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
    },


    '& .upsideImage2': {
        marginTop: '-32px',
        marginLeft: '40px'
    },

    '& .upsideImage': {
        marginTop: '-32px',
        marginLeft: '40px'
    },


    '& .downsideImage2': {
        marginTop: '60px',
        marginRight: '40px',
    },

    '& .downsideImage': {
        marginTop: '60px',
        marginRight: '40px',
        '@media(max-width:375px)': {
            marginLeft: "-24px",
            marginRight: "16px",
        }
    },

    '& .textContainer': {
        maxWidth: "150px",
        width: '100%',
        padding: "0 10px"
    },

    '& .ImageTextContainer': {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
    },

    '& .textContainer2': {
        width: '100%',
        maxWidth: "184px",
        padding: "0 10px"
    },

}))
// Customizable Area End
