import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { Loader as LoaderIcon } from "./assets";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return props.loading ? (
    <Backdrop style={{ zIndex: 99999 }} open={props.loading}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img src={LoaderIcon} alt="LoaderIcon" />
      </div>
    </Backdrop>
  ) : null;
}
