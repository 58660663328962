import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  data: any
  isPremium?:boolean
  handleGoBack?:()=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  materialModal: boolean
  MaterialData: any
  summaryPdf: any;
  video: any;
  material: any;
  ToastOpen:boolean;
  ToastMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End



export default class UnitTopiController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMaterialsApi = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationNonPremiumUser),
      getName(MessageEnum.NonPremiumUserMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.MaterialPagePropsMessage),
      getName(MessageEnum.NavigationMaterialMessage),
    ];
    this.state = {
      materialModal: false,
      MaterialData: undefined,
      summaryPdf: undefined,
      video: undefined,
      material: undefined,
      ToastOpen:false,
      ToastMessage:'',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

    // Customizable Area Start
  handleMaterialResponse = (response: any) => {   
      this.setState({
        materialModal: true,
        summaryPdf: response.summarypdf,
        video: response.summaryVideo,
        material:response.cheetsheetpdf ,
      })
  }

  handleCloseToast=()=>{
    this.setState({
      ToastOpen:false,
      ToastMessage:''
    })
  }

  handleDownlaodSummaryPdf = () => {
    if(this.state.summaryPdf!==null){
    const URL = `${this.state.summaryPdf}`
    this.handleDownload(URL,'summary.pdf')
    }else{
      this.setState({
        ToastMessage:"summary pdf is unavailable",
        ToastOpen:true
      })
    }
  }

  handleDownloadCheetSheetPdf=()=>{
    if(this.state.material!==null){
    const URL = `${this.state.material}`
    this.handleDownload(URL,'Cheetsheet.pdf')
  }else{
    this.setState({
      ToastMessage:"cheetsheet pdf is unavailable",
      ToastOpen:true
    })
  }
  }

  handleDownloadVideo=()=>{
    if(this.state.video!==null){
    const URL = `${this.state.video}`
    this.handleDownload(URL,'mp4')
  }else{
    this.setState({
      ToastMessage:"Summary video is unavailable",
      ToastOpen:true
    })
   
  }

  }


  handleDownload=(url:string,name:string)=>{
    
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `${this.state.MaterialData.label.replace(" ","_")+'_'+name}`;
        document.body.appendChild(a)
        a.click();
        a.remove()
      });
    });
    
  }

  getMaterials = (item: any ) => {
    if(this.props.isPremium){
    const index =  this.props.data.findIndex((num:any)=>{
   return  JSON.parse(num.id).id===JSON.parse(item.id).id
  })
    this.setState({
      MaterialData: {...item,index:index+1}
    })
     this.handleMaterialResponse({...item,index:index+1})
  }else{
   this.props.handleGoBack&&this.props.handleGoBack()
  }
}
  // Customizable Area End
}