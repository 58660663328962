// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import { initializeApp} from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connect } from 'react-firebase';
import './App.css'
import "react-swipeable-list/dist/styles.css";
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Cflessonbuildercoursebuilderinbackend from '../../blocks/cflessonbuildercoursebuilderinbackend/src/Cflessonbuildercoursebuilderinbackend';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import AutomaticRenewals from '../../blocks/automaticrenewals/src/AutomaticRenewals';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import Cftableforlessons from '../../blocks/cftableforlessons/src/Cftableforlessons';
import Eolscorecard from '../../blocks/cftableforlessons/src/Eolscorecard.web';
import ProgressUpdate from '../../blocks/cftableforlessons/src/ProgressUpdate.web'
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import AssessmentTest from '../../blocks/assessmenttest/src/AssessmentTest';
import LanguageSupport from '../../blocks/languagesupport/src/LanguageSupport';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Notifications from '../../blocks/notifications/src/Notifications';
import Leaderboards from "../../blocks/leaderboard/src/Leaderboards";
import Reviewprompt2 from '../../blocks/reviewprompt2/src/Reviewprompt2';
import Apiintegrationadobecaptivate from '../../blocks/apiintegrationadobecaptivate/src/Apiintegrationadobecaptivate';
import Subscriptionbilling2 from '../../blocks/subscriptionbilling2/src/Subscriptionbilling2';
import Avatar from '../../blocks/avatar/src/Avatar';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Share from '../../blocks/share/src/Share';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Gamification from '../../blocks/educationalgamification/src/Gamification';
import Search from '../../blocks/search/src/SearchBar.web';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import StripePayments from '../../blocks/stripepayments/src/StripePayments';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Gamescore2 from '../../blocks/gamescore2/src/Gamescore2';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import MainMenuWeb from '../../blocks/cflessonbuildercoursebuilderinbackend/src/MainMenu.web';
import MaterialPage from '../../blocks/cflessonbuildercoursebuilderinbackend/src/MaterialPage.web';
import LessonPage from '../../blocks/cflessonbuildercoursebuilderinbackend/src/LessonPage.web';
import PremiumSkipUser from '../../blocks/cflessonbuildercoursebuilderinbackend/src/PremiumSkipUser.web';
import ChallengeLessons from  '../../blocks/assessmenttest/src/ChallengeLessons.web';
import ChallengePage from  '../../blocks/assessmenttest/src/Challenge.web';
import LessonMaterial from '../../blocks/cflessonbuildercoursebuilderinbackend/src/LessonMaterial.web';
import LessonList from '../../blocks/cflessonbuildercoursebuilderinbackend/src/LessonsList.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Analytics from '../../blocks/analytics/src/Analytics';
import LanguageOptions from '../../blocks/languageoptions/src/LanguageOptions.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import ProjectReporting from '../../blocks/projectreporting/src/ProjectReporting.web';
// import EmailRegistrationsWeb from '../../blocks/email-account-registration/src/EmailRegistrations.web';
import EmailLogin from '../../blocks/email-account-login/src/EmailLogin.web';
import LandingPageWeb from '../../blocks/landingpage/src/LandingPage.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import NotificationWeb from '../../blocks/notifications/src/Notification.web';
import EmailRegistrationSuccess from '../../blocks/email-account-registration/src/EmailRegistrationSuccess.web';
import CheckEmailForgotPassword from '../../blocks/forgot-password/src/CheckEmailForgotPassword.web';
import Loader from '../../components/src/pandaLoader.web';
import DesktopScreen from '../../blocks/email-account-registration/src/DesktopScreen.web';
import CreateNewPassword from '../../blocks/forgot-password/src/CreateNewPassword';
import FirstLogin from '../../blocks/email-account-login/src/FirstLogin.web'
import FirstLoginSelectAvatar from '../../blocks/email-account-login/src/FirstLoginSelectAvatar.web'
import ExploreLesson from "../../blocks/categoriessubcategories/src/ExploreLesson"
import KeepTrackQuestion from "../../blocks/categoriessubcategories/src/KeepTrackQuestion"
import ActiveEmail from "../../blocks/email-account-registration/src/ActiveEmail.web"
import TermsConditionsRegister from "../../blocks/forgot-password/src/TermsConditionsRegister"
import NetworkStatusChecker from "../../components/src/NetworkError"
import SignUp from "../../blocks/email-account-registration/src/SignUp"
import CompleteProfile from "../../blocks/email-account-registration/src/CompleteProfile"
import SignUpActivation from "../../blocks/email-account-registration/src/SignUpActivation"
import PremiumSubscription from '../../blocks/stripepayments/src/PremiumSubscription';
import PremiumCheckouts from '../../blocks/stripepayments/src/PremiumCheckouts.web';
import StripePaymentsMethod from '../../blocks/stripepayments/src/StripePaymentsMethod.web';
import ProcessingPayment from '../../blocks/stripepayments/src/ProcessingPayments.web';
import PaymentError from '../../blocks/stripepayments/src/PaymentError.web';
import PaymentPending from '../../blocks/stripepayments/src/PaymentPending.web';
import PaymentSuccessful from '../../blocks/stripepayments/src/PaymentSuccessful.web';
import PaymentSuccessfulEmail from '../../blocks/stripepayments/src/PaymentSuccessfulEmail.web';
import PremiumDashboard  from '../../blocks/stripepayments/src/PremiumDashboard.web';
import PremiumCancel from '../../blocks/stripepayments/src/PremiumCancel.web';
import PlanDetails from '../../blocks/stripepayments/src/PlanDetails.web';
import PlanDetailsMethod from '../../blocks/stripepayments/src/PlanDetailsMethod.web';
import SubscriptionExpering from '../../blocks/stripepayments/src/SubscriptionExpering.web';
import SubscriptionPremium from '../../blocks/stripepayments/src/SubscriptionPremium.web';
import SubscriptionCancelledPremium from '../../blocks/stripepayments/src/SubscriptionCancelledPremium.web';
import SubscriptionPremiumHighlights from '../../blocks/stripepayments/src/SubscriptionPremiumHighlights.web';
import ExpiredPremium from '../../blocks/stripepayments/src/ExpiredPremium.web';
import PremiumHighlights from '../../blocks/stripepayments/src/PremiumHighlights.web';
import EditAccount from "../../blocks/accountgroups/src/EditAccount"
import ChangePassword from "../../blocks/settings2/src/ChangePassword"
import LanguagePage from "../../blocks/landingpage/src/LanguagePage.web"
import XPTracker from "../../blocks/landingpage/src/XPTracker.web"
import InAppNotification from '../../blocks/notifications/src/InAppNotifiocationMain.web'
import InAppNotificationDetails from '../../blocks/notifications/src/InAppNotificationDetails.web';
import InAppDeleteNotification from '../../blocks/notifications/src/InAppDeleteNotification.web';
import MicroLessonExercise from "../../blocks/educationalgamification/src/MicroLessonExercise"
import ParentSms from  '../../blocks/mobile-account-login/src/ParentSms.web'
import ParentVerificationFailure from  '../../blocks/mobile-account-login/src/ParentVerificationFailure.web'
import ParentVerificationSuccess from  '../../blocks/mobile-account-login/src/ParentVerificationSuccess.web'
import ChildEmailVerification from  '../../blocks/mobile-account-login/src/ChildEmailVerification.web'
import ChallengeExercise from '../../blocks/assessmenttest/src/ChallengeExercise';
import StripePaymentsView from '../../blocks/stripepayments/src/StripePaymentsViews';
import MotivationalPage from '../../blocks/cftableforlessons/src/MotivationalPage';
import "../../components/src/I18nextConfig/i18n"
import CustomisableUserProfiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web'
import lessonSummary from '../../blocks/cftableforlessons/src/lessonSummary.web'
import PageNotFound from '../../blocks/landingpage/src/PageNotFound.web';
import GuestExercise from '../../blocks/categoriessubcategories/src/GuestExercise';
import GuestScore from '../../blocks/categoriessubcategories/src/GuestScore';
import GuestKeepTrackProgress from '../../blocks/categoriessubcategories/src/GuestKeepTrackProgress';

const routeMap = {
  FirstLogin: {
    component: FirstLogin,
    path: '/firstLogin'
  },
  FirstLoginSelectAvatar: {
    component: FirstLoginSelectAvatar,
    path: '/FirstLoginSelectAvatar/:dailyGoal/:streakGoal'
  },
  InAppNotification:{
    component:InAppNotification,
    path:"/InAppNotification"
  },
  InAppNotificationDetails:{
    component:InAppNotificationDetails,
    path:"/InAppNotificationDetails"
  },
  InAppDeleteNotification:{
    component:InAppDeleteNotification,
    path:"/InAppDeleteNotification"
  },
  Cflessonbuildercoursebuilderinbackend: {
    component: Cflessonbuildercoursebuilderinbackend,
    path: '/Cflessonbuildercoursebuilderinbackend'
  },
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task'
  },
  AutomaticRenewals: {
    component: AutomaticRenewals,
    path: '/AutomaticRenewals'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path:'/CustomisableUserProfiles'
  },
  Cftableforlessons: {
    component: Cftableforlessons,
    path: '/Cftableforlessons/:lessonId/:lessonNumber/:lessonName'
  },
  lessonSummary: {
    component: lessonSummary,
    path: '/lessonSummary/:lessonName/:lessonId'
  },
  Eolscorecard: {
    component: Eolscorecard,
    path: '/Eolscorecard/:lessonName/:lessonId'
  },
  ProgressUpdate: {
    component:ProgressUpdate,
    path: '/ProgressUpdate'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: '/AssessmentTest'
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: '/LanguageSupport'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Leaderboard:{
   component:Leaderboards,
  path:"/Leaderboard"},
  Reviewprompt2: {
    component: Reviewprompt2,
    path: '/Reviewprompt2'
  },
  Apiintegrationadobecaptivate: {
    component: Apiintegrationadobecaptivate,
    path: '/Apiintegrationadobecaptivate'
  },
  Subscriptionbilling2: {
    component: Subscriptionbilling2,
    path: '/Subscriptionbilling2'
  },
  StripePaymentsView: {
    component: StripePaymentsView,
    path: "/StripePaymentsView",
  },
  Avatar: {
    component: Avatar,
    path: '/Avatar'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ParentSms: {
    component: ParentSms,
    path: '/parent_verify'
  },
  ParentVerificationFailure: {
    component: ParentVerificationFailure,
    path: '/ParentVerificationFailure'
  },
  ParentVerificationSuccess: {
    component: ParentVerificationSuccess,
    path: '/ParentVerificationSuccess'
  },
  ChildEmailVerification: {
    component: ChildEmailVerification,
    path: '/ChildEmailVerification'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  ProjectReporting: {
    component: ProjectReporting,
    path: '/ProjectReporting'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  StripePayments: {
    component: StripePayments,
    path: '/StripePayments'
  },
  PremiumSubscription:{
    component: PremiumSubscription,
    path: '/PremiumSubscription'
  },
  PremiumCheckout:{
    component: PremiumCheckouts,
    path: '/PremiumCheckout'
  },
  StripePaymentsMethod:{
   component:StripePaymentsMethod,
   path:'/StripePaymentsMethod'
  },
  ProcessingPayment:{
    component:ProcessingPayment,
    path:'/ProcessingPayment'
  },
  PaymentError:{
    component:PaymentError,
    path:'/PaymentError'
  },
  PaymentPending:{
    component:PaymentPending,
    path:'/PaymentPending'
  },
  PaymentSuccessful:{
    component:PaymentSuccessful,
    path:'/PaymentSuccessful'
  },
  PaymentSuccessfulEmail:{
    component:PaymentSuccessfulEmail,
    path:'/PaymentSuccessfulEmail'
  },
  PremiumDashboard:{
    component:PremiumDashboard,
    path:'/PremiumDashboard' 
  },
  PremiumCancel:{
   component:PremiumCancel,
   path:'/PremiumCancel'
  },
  PlanDetails:{
    component:PlanDetails,
    path:'/PlanDetails'
   },
  PlanDetailsMethod:{
  component:PlanDetailsMethod,
  path:'/PlanDetailsMethod'   
  },
  SubscriptionExpering:{
  component:SubscriptionExpering,
  path:'/SubscriptionExpering'
  },
  SubscriptionPremium:{
    component:SubscriptionPremium,
    path:'/SubscriptionPremium' 
  },
  SubscriptionCancelledPremium:{
   component:SubscriptionCancelledPremium,
   path:'/SubscriptionCancelledPremium'
  },
  SubscriptionPremiumHighlights:{
    component:SubscriptionPremiumHighlights,
    path:'/SubscriptionPremiumHighlights'
  },
  ExpiredPremium:{
    component:ExpiredPremium,
    path:'/ExpiredPremium'
  },
  PremiumHighlights:{
    component:PremiumHighlights,
    path:'/PremiumHighlights' 
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  Gamescore2: {
    component: Gamescore2,
    path: '/Gamescore2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: '/LanguageOptions'
  },
  EmailLogin: {
    component: EmailLogin,
    path: '/EmailLogin'
  },
  LandingPageWeb: {
    component: LandingPageWeb,
    path: '/LandingPageWeb'
  },
  MainMenuWeb: {
    component: MainMenuWeb,
    path: '/mainMenuWebPage'
  },
  MaterialPage: {
    component: MaterialPage,
    path: '/materialPageWeb'
  },

  PageNotFound:{
    component:PageNotFound,
    path:"/pageNotFound"
  },

  LessonPage: {
    component: LessonPage,
    path: '/lessonPageWeb'
  },

  PremiumSkipUser:{
    component: PremiumSkipUser,
    path: '/NonPremiumScreen'
  },

  ChallengePage: {
    component: ChallengePage,
    path: '/ChallengePage'
  },
  ChallengeLessons: {
    component: ChallengeLessons,
    path: '/ChallengeLessonsPage'
  },
  LessonMaterial: {
    component: LessonMaterial,
    path: '/Lesson-material'
  },
  LessonList: {
    component: LessonList,
    path: '/Lesson-list/:unitId'
  },
  CheckEmailForgotPassword: {
    component: CheckEmailForgotPassword,
    path: '/CheckEmailForgotPassword'
  },
  CreateNewPassword: {
    component: CreateNewPassword,
    path: '/CreateNewPassword'
  },
  ExploreLesson:{
    component: ExploreLesson,
    path:"/ExploreLesson/:gradeId"
  },
   KeepTrackQuestion:{
    component: KeepTrackQuestion,
    path:"/KeepTrackQuestion/:subCategory/:subCategoryId/:lessonId",
  },
  GuestExercise:{
    component: GuestExercise,
    path:"/GuestExercise/:subCategoryId/:lessonId",
  },
  GuestScore:{
    component: GuestScore,
    path:"/GuestScore/:guestXP",
  },
  GuestKeepTrackProgress:{
    component: GuestKeepTrackProgress,
    path:"/GuestKeepTrackProgress",
  },
   TermsConditionsRegister:{
    component: TermsConditionsRegister,
    path:"/TermsConditionsRegister",
  },
  CompleteProfile:{
   component: CompleteProfile,
   path:"/CompleteProfile"},
  EditAccount:{
   component: EditAccount,
   path:"/EditAccount"},
  ChangePassword:{
   component: ChangePassword,
   path:"/ChangePassword"},
  MicroLessonExercise:{
   component: MicroLessonExercise,
   path:"/MicroLessonExercise/:lessonId/:lessonNumber/:lessonName"},
  ChallengeExercise: {
    component: ChallengeExercise,
    path: '/ChallengeExercise/:unitId/:unitName/:lessonId/:lessonName'
  },
  MotivationalPage: {
    component: MotivationalPage,
    path: '/MotivationalPage/:lessonId/:lessonNumber/:lessonName/:totalLessonCount/:totalXP'
  },

  // Home: {
  //   component: StripePayments,
  //   path: '/',
  //   exact: true
  // },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  EmailRegistrationWeb: {
    component: SignUp,
    path: '/EmailRegistrationWeb'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/ForgotPasswordWeb'
  },
  NotificationWeb: {
    component: NotificationWeb,
    path: '/NotificationWeb'
  },
  EmailRegistrationSuccess: {
    component: EmailRegistrationSuccess,
    path: '/EmailRegistrationSuccess'
  },
  Loader: {
    component: Loader,
    path: '/Loader'
  },
  DesktopScreen: {
    component: DesktopScreen,
    path: '/',
    exact: true
  },
  ActiveEmail:{
    component: SignUpActivation,
   path:"/ActiveEmail"},
   LanguagePage:{
    component: LanguagePage,
   path:"/LanguagePage"},
   XPTracker:{
    component: XPTracker,
    path:"/XPTracker"
   }
};

const firebaseConfig = {
  apiKey: "AIzaSyAaAFVPd2BOLgYAKNL0t5zWei2BtIyUtaI",
  authDomain: "tedix-400192.firebaseapp.com",
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: "tedix-400192",
  storageBucket: "tedix-400192.appspot.com",
  messagingSenderId: "494702856114",
  appId: "1:494702856114:web:ad89db2b2b3bc90d80c6ab",
  measurementId: "G-EYH7F0W5KN"
};

class App extends Component {
  componentDidMount = () => {
    window.addEventListener(
      "beforeinstallprompt",
      (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        window.deferredPrompt = e;
       
        // Update UI notify the user they can install the PWA
        // showInstallPromotion();
      }
    );
    const loginToken =  localStorage.getItem('token')
    const checkToken = this.isTokenExpired(loginToken);
    const allowedUrls = [
      '/',
       '/EmailLogin',
       '/CreateNewPassword',
       '/ForgotPassword',
       '/EmailRegistrationWeb',
       '/Categoriessubcategories',
       '/ExploreLesson',
       '/KeepTrackQuestion',
       '/CompleteProfile',
       '/ActiveEmail',
       '/pageNotFound',
       '/GuestExercise',
       '/GuestScore',
       '/GuestKeepTrackProgress',
    ];
    const isAllowed = allowedUrls.some(item => window.location.pathname === item)
    if (checkToken && !isAllowed) {
      window.location.href = "/";
    }
  };

isTokenExpired(token) {
    try {
        const [, payloadBase64] = token.split('.');
        const payload = JSON.parse(Buffer.from(payloadBase64, 'base64').toString('utf-8'));
        const expirationTime = payload.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (expirationTime < currentTime) {
            return true; 
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error decoding token:', error.message);
        return true;
    }
}
  render() {
    // const existingApps = getApps();
    const app = initializeApp(firebaseConfig);

    const defaultAnalytics = getAnalytics(app);

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav />Quicksand */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
