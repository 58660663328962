import React, { useEffect, useRef, useState } from 'react'
import {  InputAdornment, OutlinedInputProps, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import  {useTranslation} from "react-i18next"
import { CustomSelectWrapper, PopoverStyled, StyledContainer, TextInputCustomSelect } from './CustomSelect'

interface IItem {
    option: string
    optionData: string
    data: number
}

interface CustomSelectProps {
    list: Array<IItem>
    value: IItem
    setValue: (item: IItem) => void
    setId?:(item:number)=>void
    placeholder?: string
    showSearchInput?: boolean
    isError?: boolean
    isDailyGoal?:boolean
    style?: any
    InputProps ?:Partial<OutlinedInputProps>
    disableSelect?: boolean
}


const CustomGoalSelect = ({
    list,
    value,
    setValue,
    placeholder,
    showSearchInput,
    isError,
    isDailyGoal,
    InputProps,
    style,
    disableSelect 
  } : CustomSelectProps) => {
    const  { t } = useTranslation()
    const [listData, setListData] = useState(list)
    
    const handleSearch = (searchTerm: string) => {
      const filtered = listData.filter((item) =>
        item.option.toLowerCase().includes(searchTerm.trim().toLowerCase())
      )
      setListData(filtered)
    }
  
    const [anchorEl, setAnchorEl] = useState(null)
    const [highlightIndex, setHighlightIndex] = useState<number>(0)
    const customSelectRef = useRef<HTMLDivElement>(null)
    const listRef = useRef<HTMLUListElement>(null)
  
    const handleClick = (event: any) => {
      !disableSelect && setAnchorEl(event.currentTarget)
    }
  
    const handleClose = () => {
      setAnchorEl(null)
    }
  
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
  
    useEffect(() => {
      if (!open) {
        setListData(list)
      }
    }, [open, list])
  
    const handleOptionClick = (option: IItem) => {
      setHighlightIndex(list.indexOf(option));
      setListData(list)
      setValue(option);
      handleClose();
    }
  
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      e.persist()
      if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
        e.preventDefault();
        setHighlightIndex((prevIndex) => {
          const newIndex = e.key === 'ArrowDown'
            ? (prevIndex + 1) % listData.length
            : (prevIndex - 1 + listData.length) % listData.length;
          
          if (listRef.current) {
            const itemHeight = listRef.current.scrollHeight / listData.length;
            const scrollTop = itemHeight * newIndex;
            listRef.current.scrollTop = scrollTop - itemHeight * 3;
          }
  
          return newIndex;
        });
      } else if (e.key === 'Enter' && highlightIndex !== -1) {
        const selectedValue = listData[highlightIndex];
        handleOptionClick(selectedValue);
      }
    }
  
    const refFunc = () => {
      if (listRef.current) {
        const itemHeight = listRef.current.scrollHeight / listData.length;
        const scrollTop = itemHeight * highlightIndex;
        listRef.current.scrollTop = scrollTop - itemHeight * 2;
      }
    }

    const getDailyGoalOptions=(item:IItem)=>{

      if(item.data===1){
        return `${item.data} ${t("lesson")}`
      }else{
        return `${item.data} ${t("lessons")}`
      }
    }

   const getListOption=(item:IItem)=>{
      if(isDailyGoal){
         return  getDailyGoalOptions(item)
      }else{
        return `${item.data} ${t("days")}`
      }
    }
  
    useEffect(() => {
      setHighlightIndex(list.indexOf(value))
      if (open && value && list.length > 0) {
        setTimeout(() => {
          refFunc()
        }, 0)
      }
    }, [open, value, list.length])
  
    return (
      <StyledContainer {...{ ref: customSelectRef }} className='styled_container' tabIndex={0} onKeyDown={handleKeyDown}>
        <TextInputCustomSelect
          aria-describedby={id}
          value={value.option}
          autoComplete="off"
          onClick={handleClick}
          placeholder={placeholder}
          variant="outlined"
          error={isError}
          style={style}
          className={disableSelect ? 'disableSelect' : ''}
          InputProps={InputProps}
        />
  
        <PopoverStyled
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <CustomSelectWrapper>
            {showSearchInput && (
              <Typography
                style={{ padding: '15px 15px 0px 15px', marginBottom: '6px' }}
              >
                <TextField
                  className="search-field"
                  name="Search"
                  id="search"
                  placeholder="Search"
                  fullWidth
                  onChange={(e) => handleSearch(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" disablePointerEvents>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Typography>
            )}
            <ul className="itemWrapper" ref={listRef}>
              {listData.length === 0 ? (
                <li className="item">{t('No result found!')}</li>
              ) : (
                listData.map((item, index) => {
                  return (
                    <li
                      key={item.option}
                      className={`item ${index === highlightIndex ? 'highlight' : ''}`}
                      onClick={() => handleOptionClick(item)}
                    >
                      <p>{t(item.option)}</p>
                      <p style={{color: '#999999'}}>{getListOption(item)}</p>
                    </li>
                  )
                })
              )}
            </ul>
          </CustomSelectWrapper>
        </PopoverStyled>
      </StyledContainer>
    )
  }
  
  export default CustomGoalSelect