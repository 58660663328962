import React, { Component } from "react";
// Customizable Area Start
import { Box, Card, CardContent, Typography, styled } from "@material-ui/core";
import {ILearningData} from "./MedianController.web"
// Core modules imports are same as usual
import { Pagination } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";

// Styles must use direct files imports
import "swiper/swiper.min.css"; // core Swiper
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import Report from "./Report.web";
import { withTranslation } from "react-i18next";
interface SS {
  data?: {
    dateTime: string;
    data: ILessonData[];
  }[];
  learningData:Array<ILearningData>;
  handleViewDetail: (detailId: any) => void;
  t?:any
}
interface ILessonData {
  lessonTitle: string;
  lessonName: string;
  lessonDescription: string;
  correctAnswer: string[];
  yourAnswer: string[];
  lessonMinute: number;
  lessonGrade: number;
  xpEarned: number;
  weekData?:any,
  YearData?:any
}

// Customizable Area End

class LearningReport extends Component<SS> {
  constructor(props: SS) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <LearningReportBoxStyle>
        <Card className="learningReportCard">
          <Typography className="learningReportCardHeader">
            {this.props.t('Learning Report')}
          </Typography>
          <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
            {this.props.learningData.length>0&&this.props.learningData.map((report:any) => {
              return(           
              <SwiperSlide key={report.dateTime}>
                  <CardContent className="gridSection" >
                    <Box
                      className="learningReportCardBox"
                    >
                      <Report
                        handleViewDetail={this.props.handleViewDetail}
                        dateTime={report.dateTime}
                        lessonLearned={Math.floor(report.Lessons)}
                        lessonAvgGrade={Math.floor(report.Grade)}
                        lessonMinute={Math.floor(report.Minutes)}
                        xpEarned={Math.floor(report.XPEarned)}
                        startDate={report.startDate}
                        endDate={report.endDate}
                        type={report.type}
                    /> 
                    </Box>
                  </CardContent>
              </SwiperSlide>
              )
            })}
          </Swiper>
        </Card>
      </LearningReportBoxStyle>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withTranslation()(LearningReport)
const LearningReportBoxStyle = styled(Box)(({ theme }) => ({
  margin: "15px 0px",
  "& .gridSection": {
    display: "grid",
    gap: "10px",
    justifyContent:'center',
    '@media(max-width:599px)':{
      justifyContent:'unset',
    }
  },
  "& .mySwiper":{
    width:'433px !important',
    '@media(max-width:599px)':{
      width:"100% !important"
    }
  },
  "& .learningReportCard": {
    minWidth: "329px",
    borderRadius: "10px",
    border: "2px solid #F2F2F2",
    background: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    boxShadow: "none",
    '@media(max-width:599px)':{
      width:"329px"
    }
  },
  "& .learningReportCardHeader": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "1.2",
    margin: "14px 0px 0px 16px"
  },
  "& .learningReportCardBox": {
    minHeight: "68px",
    width:'289px',
    border: "2px solid #FFFFFF",
    background: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "20px",
    padding: "2px"
  },

  [theme.breakpoints.up("md")]: {
    // height: "100vh"
  },
  "& .swiper": {
    width: "100%",
    height: "100%",
    paddingBottom: "16px"
  },
  "& .swiper-pagination-bullet-active": {
    background: "#363636"
  },
  "& .swiper-slide img": {
    display: "block",
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
}));

// Customizable Area End
