// Customizable Area Start
import React from 'react'

import {
  Container,
  Typography,
  Box,
  styled,
  Tabs,
  Tab,
} from '@material-ui/core'

import ExploreLessonController, { Props } from './ExploreLessonController'
import {
  ButtonCustom,
  SecondaryButtonCustom,
} from '../../../components/src/MaterialTheme'
import SelectLanguage from '../../../components/src/SelectLanguage'
import Header from '../../../components/src/Header'
import { withTranslation } from 'react-i18next'
import { loader } from '../../stripepayments/src/assets'

 class ExploreLesson extends ExploreLessonController {
  constructor(props: Props) {
    super(props)
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  handleChangeSubject = (event: any, newValue: number) => {
    this.setState({ selectedSubject: newValue, selectedLesson: null })
    this.getLessonsBySubCategory(this.state.listSubCategories[newValue].id)
  }

  handleClickContinue = () => {
    this.props.navigation.navigate('KeepTrackQuestion', {
      subCategory: this.state.listSubCategories[this.state.selectedSubject].name,
      subCategoryId: this.state.listSubCategories[this.state.selectedSubject].id,
      lessonId: this.state.selectedLesson?.id
    })
  }

  renderLessons = () => {
    if (this.state.isLoading) {
      return (
        <img src={loader} alt="Loading" />
      )
    }

    if (this.state.listLessons.length === 0) {
      return (
        <Typography className='loadingText'>{this.props.t("There is no lesson")}</Typography>
      )
    }

    return (
      <Box>
        {this.state.listLessons.map((lesson) => (
          <SecondaryButtonCustom
            key={lesson.id}
            className={
              lesson.id === this.state.selectedLesson?.id
                ? 'lessonItem selectedLesson'
                : 'lessonItem'
            }
            onClick={() => this.setState({ selectedLesson: lesson })}
          >
            {lesson.name}
          </SecondaryButtonCustom>
        ))}
      </Box>
    )
  }



  render() {
    return (
      <ExploreLessonContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentContainer" maxWidth="md">
          <div />
          <Box className="selectLessonContainer">
            <Typography className="exploreLessonTitle">
              {this.props.t("I want to learn...")}
            </Typography>
            <Typography className="exploreLessonDesc">
              {this.props.t("Select a lesson")}
            </Typography>
            <Tabs
              value={this.state.selectedSubject}
              onChange={this.handleChangeSubject}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#C9EEFF',
                  height: '3px',
                  borderRadius: '3px',
                },
              }}
              style={{ marginBottom: '116px' }}
              variant='scrollable'
              scrollButtons='auto'
            >
              {this.state.listSubCategories.map((item) => (
                <Tab key={item.id} label={item.name} disableRipple />
              ))}
            </Tabs>
            {this.renderLessons()}
            <ButtonCustom
              variant="contained"
              className="continueButton"
              disabled={this.state.selectedLesson === null}
              onClick={this.handleClickContinue}
              style={{ marginTop: '92px' }}
            >
              {this.props.t("Continue")}
            </ButtonCustom>
          </Box>
          <SelectLanguage style={{ marginBottom: '72px', marginTop: '24px' }} />
        </Container>
      </ExploreLessonContainer>
    )
  }
}
export default withTranslation()(ExploreLesson)

const ExploreLessonContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '& .loadingText': {
    color: '#666666',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    textAlign: 'center',
  },

  '& .exploreLessonTitle': {
    fontSize: '22px',
    fontFamily: 'Nunito',
    textAlign: 'center',
    fontWeight: '900',
    color: '#363636',
  },

  '& .exploreLessonDesc': {
    color: '#666666',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
    marginTop: '16px',
    textAlign: 'center',
    marginBottom: '82px',
  },

  '& .selectLessonContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '24px',
  },

  '& .MuiTab-root': {
    fontSize: '16px',
    fontFamily: 'Nunito',
    fontWeight: '500',
    color: '#CCCCCC',
    textTransform: 'none',
  },

  '& .Mui-selected': {
    color: '#62CDFF',
  },

  '& .lessonItem': {
    marginBottom: '24px',
  },

  '& .selectedLesson': {
    color: '#06aeff',
    borderColor: '#36BFFF',
    boxShadow: '0px 4px #36BFFF',
  },

  [theme.breakpoints.up('sm')]: {
    '& .MuiTab-root': {
      minWidth: 'unset',
      fontSize: '20px',
      fontWeight: '600',
    },
  },

  [theme.breakpoints.up('md')]: {
    '& .exploreLessonTitle': {
      fontSize: '24px',
    },

    '& .exploreLessonDesc': {
      fontSize: '20px',
    },
  },
}))
// Customizable Area End
