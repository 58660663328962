import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {  Box, Typography } from "@material-ui/core";
import { imgSkipWrench,loader } from './assets'
import NavigateLayout from "../../../components/src/NavigateLayout";
import SettingBackground from "../../settings2/src/SettingBackground";
import SettingHeader from "../../settings2/src/SettingHeader";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";
import { withTranslation } from "react-i18next";

 class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClickGoBack = () => {
    this.props.navigation?.goBack()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <NavigateLayout menuName="Settings">
        <TermsCondsContainer>
          <SettingBackground />
          <Box className="contentContainer">
            <SettingHeader
              data-testId='settingHeader'
              onGoBack={this.handleClickGoBack}
              title={this.props.t("Terms and Conditions")}
              headerImage={imgSkipWrench}
              isShowButton={false}
            />
            <Box className="termsCondsContainer">
              <Box className="termsCondsWrapper">
                <Typography className="termsCondsTitle">{this.props.t("General provisions")}</Typography>
                {this.state.isLoading ? (
                  <Box component='div' className="loadingWrapper">
                     <img src={loader} alt="Loading" />
                  </Box>
                  ) : (
                    <div className='termsCondsDesc' dangerouslySetInnerHTML={{ __html: this.state.termsConds?.description || '' }}></div>
                  )
                }
              </Box>
            </Box>
          </Box>
        </TermsCondsContainer>
      </NavigateLayout>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(TermsConditions)
const TermsCondsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#A95EDB',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .termsCondsContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    backgroundColor: '#A95EDB',
    scrollbarWidth: 'none'
  },

  '& .termsCondsContainer::-webkit-scrollbar': {
    display: 'none'
  },

  '& .termsCondsWrapper': {
    overflow: 'scroll',
    flex: 1,
    display: 'flex',
    backgroundColor: '#fff',
    paddingTop: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 46px 88px',
    scrollbarWidth: 'none'
  },

  '& .termsCondsWrapper::-webkit-scrollbar': {
    display: 'none',
  },

  '& .termsCondsTitle': {
    width: '100%',
    color: '#363636',
    fontSize: '18px',
    fontWeight: '700',
    fontFamily: 'Nunito',
    marginBottom: '12px',
    textAlign: 'left',
  },

  '& .loadingWrapper': {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .termsCondsDesc': {
    color: '#666666',
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Quicksand',
    textAlign: 'justify',
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .termsCondsWrapper': {
      borderRadius: '24px 0 0 0',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))

// Customizable Area End
