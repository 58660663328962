import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '300px',
        },
        customAlert: {
            backgroundColor: '#FEE2DF',
            color: '#F96855',
            textAlign: 'right',
            fontSize: '14px',
            padding: '2px 8px',
        },
        '@media (min-width: 600px)': {
            root: {
                width: '400px',
            },
        },
    }),
);

interface ToasterProps {
    label: string;
    color?: any;
}

export function Toaster(props: ToasterProps) {
    const classes = useStyles();
    const { label, color } = props;

    return (
        <div className={classes.root}>
            {label.length ? (
                <Alert color={color} className={classes.customAlert} icon={false} style={{textAlign: 'left'}}>
                    {label}
                </Alert>
            ) : (
                <></>
            )}
        </div>
    );
}

Toaster.defaultProps = {
    label: 'text',
    color: 'info',
};
