import * as React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import {
    skip_wrench,
    btn_back,
    img_chk,
    skip_freemiumIcon,
    skip_vector,
    skip_bolt,
    skip_arrow,
    view_rectangle,
    group_warning,
} from "./assets";
import { withTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End
import SubscriptionCancelledPremiumController from "./SubscriptionCancelledPremiumController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";

class SubscriptionCancelledPremium extends SubscriptionCancelledPremiumController {
    render() {
        return (
            // Customizable Area Start
            <NavigateLayout menuName="Settings">
                <SettingContainer>
                    <Box className="mainContentWrapperCancel">
                        <Box className="settingpremiumBackground">
                        <CenterPortion navigation={undefined} id={""} />
                        </Box>


                        <Box className="contentContainerCancel">
                            <Box className="settingBackground">
                                <Box className="settingImgWrapper">
                                    <img src={skip_wrench} className="teddyImage" alt="Teddy Setting" />
                                    <Typography className="settingTitle">
                                        <img src={btn_back} className="arrowImage" alt="arrow btn" style={{ marginRight: '5px' }} onClick={() => this.props.navigation.navigate("SubscriptionPremiumHighlights")} data-test-id="btn-back" />
                                        <Typography className="settingTitleText">{this.props.t("Subscription")}</Typography>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box className="accountWrapper">
                                <Typography component='div' className="settingCancelListTitle" style={{ textTransform: 'uppercase' }}>
                                    {this.props.t("Current Plan")}
                                </Typography>
                                <Box style={webStyle.freeParentCancel}>
                                    <Box style={webStyle.freeimg}>
                                        <img src={skip_freemiumIcon} alt="diamond" />
                                    </Box>
                                    <Box className="settingCancelCurrentPlan">
                                        <Typography style={webStyle.freefirstText}>
                                            {this.props.t("Freemium")}
                                        </Typography>
                                        <Typography style={webStyle.freesecondTextCancel} >
                                            {this.props.t("Basic plan with limited benefits")}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <div style={{ paddingTop: '25px', width: '100%' }}>
                                <Box style={webStyle.premiumparentCancel} >
                                    <Grid container wrap="nowrap" spacing={2} style={{ padding: '50px 50px 0px 50px' }}>
                                        <div style={webStyle.premiumMainCancel}>
                                            <img src={skip_vector} alt="skip_vector" style={{ maxWidth: '54px' }} />
                                        </div>
                                        <Grid item xs>
                                            <Typography style={webStyle.firstTextTitleCancel}>
                                                {this.props.t("Come back and rekindle Premium!")}
                                            </Typography>
                                            <Typography style={webStyle.premiumTextFirst} >
                                                {this.props.t("Elevate your app experience:")}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{ paddingLeft: '50px' }}>
                                        <Grid item>
                                            <img src={img_chk} alt={`img_chk`} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography style={webStyle.textTypograpyCancel} >
                                                {this.props.t("Unlock full, live and premium lessons")}
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                    <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{ paddingLeft: '50px' }}>
                                        <Grid item>
                                            <img src={img_chk} alt={`img_chk`} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography style={webStyle.textTypograpyCancel} >
                                                {this.props.t("Personalize your avatar")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{ paddingLeft: '50px' }}>
                                        <Grid item>
                                            <img src={img_chk} alt={`img_chk`} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography style={webStyle.textTypograpyCancel} >
                                                {this.props.t("Unlimited usage")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container wrap="nowrap" alignItems="center" spacing={2} style={{ paddingLeft: '50px' }}>
                                        <Grid item>
                                            <img src={img_chk} alt={`img_chk`} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography style={webStyle.textTypograpyCancel} >
                                                {this.props.t("Win cool prizes")}
                                            </Typography>
                                        </Grid>
                                    </Grid>


                                    <div style={webStyle.benefitMainCancel}>
                                        <Typography data-testid='btnExploreBenefit' style={webStyle.ExploreTextCancel} onClick={() => this.props.navigation.navigate('SubscriptionPremiumHighlights')}>
                                            {this.props.t("Explore more benefits")}
                                        </Typography>
                                        <img src={skip_arrow} alt="test" style={{ margin: '0 10px' }} />
                                    </div>
                                    <Grid container wrap="nowrap" spacing={2} alignItems="center" style={webStyle.xpMainCancel}>
                                        <Box style={webStyle.parentCancelSectionImage}>
                                            <Grid item >
                                                <img src={skip_bolt} alt="skip_bolt" style={webStyle.imgSkip} />
                                            </Grid>
                                            <Grid item >
                                                <Typography style={webStyle.textTtileCancel}>
                                                    1000 XP
                                                </Typography>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Box>
                            </div>
                            <Box className="parentSectionMiddle">
                                <Box style={{ display: 'flex' }}>
                                    <Box>
                                        <img src={group_warning} />
                                    </Box>
                                    <Box style={{ paddingLeft: '10px' }}>
                                        <Typography style={{
                                            fontFamily: 'Quicksand',
                                            fontSize: '16px',
                                            fontWeight: 700,
                                            lineHeight: '12px',
                                            letterSpacing: '0px',
                                            textAlign: 'left',
                                            color: '#B3B3B3',
                                        }}>
                                            {this.props.t("Cancelled Premium!")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography style={{
                                        fontFamily: 'Quicksand',
                                        fontSize: '13px',
                                        fontWeight: 600,
                                        lineHeight: '18px',
                                        letterSpacing: '0px',
                                        textAlign: 'left',
                                        color: '#B3B3B3',
                                    }}>
                                        {this.props.t("Your subscription was cancelled on")} {this.getCurrentDate()}. {this.props.t("We'll always have a spot for you in Premium whenever you decide to return!")}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webStyle.boxparentCancel}>
                                <Box style={webStyle.btnBoxMainCancel}>
                                    <Button data-testid='btnRejoinPremium' className='btnTitleCancel' onClick={() => this.props.navigation.navigate('PremiumSubscription')}>
                                        {this.props.t("Cant wait? Rejoin Premium!")}
                                    </Button>
                                </Box>
                            </Box>
                            <Box style={webStyle.footerTextMain} >
                                <Typography style={webStyle.getFirstTitle}>
                                    {this.props.t("By selecting 'Rejoin Premium!', you'll be opting for our paid subscription.")}
                                </Typography>
                            </Box>
                        </Box>
                        <BottomMenu />
                    </Box>
                </SettingContainer>
            </NavigateLayout>
            // Customizable Area End
        );
    }

};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',
    '& .settingTitleText': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
    },

    '& .teddyImage': {
        Width: '245px',
        bottom: '0px',
        marginRight: '10px',
        marginLeft: '0',
        position: 'absolute',
        display: 'block',
        padding: '0',
        left: '34px'
    },

    '& .settingTitle': {
        fontWeight: '900',
        fontFamily: 'Nunito',
        justifyContent: 'center',
        color: '#fff',
        marginBottom: '12px',
        fontSize: '22px',
        paddingTop: '22px',
        display: 'flex',
        width: '100%',
        position: 'relative'
    },
    '& .teddySettingImage': {
        bottom: '0px',
        Width: '245px',
        marginLeft: '0',
        position: 'absolute',
        padding: '0',
        display: 'block',
        marginRight: '10px',
        left: 'calc(45% - 207px)',
    },


    '& .settingBackground': {
        backgroundColor: '#fff',
        width: 'calc(100% + 48px)',
        height: '111px'
    },
    '& .settingpremiumBackground': {
        backgroundColor: '#fff',
    },

    '& .arrowImage': {
        position: 'absolute',
        top: '55%',
        left: '0'
    },
    '& .settingImgWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },
    '& .parentSectionMiddle': {
        width: '100%',
        borderRadius: '12px',
        border: '2px solid #E6E6E6',
        padding: '20px',
        marginTop: '15px',
    },
    '& .mainContentWrapperCancel': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .settingImgCancelWrapper': {
        height: '100%',
        display: 'flex',
        paddingLeft: '33px',
        paddingBottom: '40px',
        alignItems: 'center',
        background: 'rgb(169, 94, 219) none repeat scroll 0% 0%',
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.10)",
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },

    '& .settingBackgroundCancel': {
        marginBottom: "-12px",
        backgroundColor: '#A95EDB',
    },
    '& .settingTitleCancel': {
        fontSize: '22px',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
        width: '100%',
        position: "relative"
    },

    "& .settingTitleCancel button": {
        position: "absolute",
        top: "48%",
        left: 0,
        marginLeft: "10px",
        padding: "4px"
    },
    '& .teddyImagecancel': {
        maxWidth: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        left: "10%"
    },
    '&.arrowCancelImage': {
        width: "30%",
        height: "auto",
        position: 'absolute',
        left: '25%',
        top: '29px',
    },


    '& .contentContainerCancel': {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        overflowX: "hidden",
        padding: '32px 24px 0px',
        alignItems: 'center',
        paddingTop: '0',
        flex: '6'
    },


    '& .accountWrapper': {
        width: '100%',
        border: '2px solid #e6e6e6',
        borderRadius: '12px',
        marginTop: '31px'
    },


    '& .settingCancelListTitle': {
        color: '#999999',
        fontSize: '12px',
        padding: "15px 20px",
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
    },

    "& .settingCancelCurrentPlan": {
        flex: "0 0 85%",
        maxWidth: "85%",
        display: "flex",
        flexDirection: "column"
    },

    '& .benefitsListTitle': {
        color: '#fff',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
    },
    "& .btnTitleCancel": {
        width: '350px',
        height: '40px',
        borderRadius: '12px',
    },
    "& .btnTitleCancel .Muibutton-label": {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center',
        color: '#FFFFFF',
        textTransform: "none"
    },
    [theme.breakpoints.up('md')]: {

        '& .settingTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
        },
        '& .teddySettingImage': {
            maxWidth: 'unset',
            width: '240px',
            left: 'calc(45% - 120px)'
        },
        '& .teddyImage': {
            maxWidth: 'unset',
            display: 'none'
        },

        '& .settingBackground': {
            width: 'calc(100% + 48px)',
            height: '111px'
        },
        '& .settingpremiumBackground': {
            flex: 5,
        },
        '& .settingImgWrapper': {
            paddingTop: 'unset',
        },


        '& .contentContainerCancel': {
            paddingTop: '0',
        },

        '& .accountWrapper': {
            maxWidth: 450,
        },

        '& .mainContentWrapperCancel': {
            width: `calc(100% - 240px)`,
            flexDirection: 'row',
            marginLeft: '240px',

        },

        '& .teddyImagecancel': {
            maxWidth: 'unset',
            width: "30%",
        },

        '& .settingImgCancelWrapper': {
            paddingTop: 'unset',
        },

        '& .arrowCancelImage': {
            position: 'absolute',
            left: '22%',
            top: '31px',
        },

        '& .settingBackgroundCancel': {
            flex: 5,
            height: "100%"
        },


        '& .settingTitleCancel': {
            marginBottom: 'unset',
            fontSize: '30px',
        },

    },

    [theme.breakpoints.up('lg')]: {
        '& .teddySettingImage': {
            width: 'auto',
            left: 'calc(45% - 207px)'
        },
        '& .mainContentWrapperCancel': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

        '& .accountWrapper': {
            maxWidth: '100%',
        },

    },

    [theme.breakpoints.down('sm')]: {
        '& .settingTitle img': {
            position: 'absolute',
            bottom: '10px',
            left: 0
        },
        '& .settingpremiumBackground': {
            display: 'none',
        },
        "& .settingTitleCancel button": {
            marginLeft: "-8px"
        },
        '& .contentContainerCancel': {
            flex: '6',
            overflow: 'scroll',
            padding: '32px 24px 65px',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: '#fff',
            flexDirection: 'column',
            paddingTop: '0',
        },

        '& .arrowCancelImage': {
            position: 'absolute',
            left: '1%',
            top: '31px',
        },

    },
    [theme.breakpoints.down('xs')]: {
        '& .settingpremiumBackground': {
            display: 'none',
        },


        '& .arrowCancelImage': {
            position: 'absolute',
            top: '31px',
            left: '1%',
        },

        '& .contentContainerCancel': {
            flex: '6',
            display: 'flex',
            alignItems: 'center',
            padding: '32px 24px 10px',
            flexDirection: 'column',
            backgroundColor: '#fff',
            overflow: 'scroll',
            overflowX: 'hidden',
            paddingTop: '0'
        },

    },

}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },

    freeimg: {
        background: '#F2F2F2',
        borderRadius: '50%',
        width: '37px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    freefirstText: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        color: '#363636'
    },
    freesecondTextCancel: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 700,
        color: '#363636'
    },
    freethirdTextCancel: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 700,
        color: '#00AEFF'
    },
    freeParentCancel: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 20px',
        backgroundColor: '#FFFFFF',
        borderRadius: "12px"
    },

    premiumparentCancel: {
        width: '100%',
        height: 'auto',
        borderRadius: '12px',
        border: '2px solid #E6E6E6',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
    },
    ExploreTextCancel: {
        lineHeight: '8.5px',
        padding: '12px 1px 12px 12px',
        textAlign: 'right' as 'right',
        color: '#00AEFF',
        fontSize: '14px',
        fontFamily: 'Quicksand',
        fontWeight: 700,
        textDecoration: "none",
        cursor: 'pointer'
    },
    premiumMainCancel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F7F7F7',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
    },
    firstTextTitleCancel: {
        fontFamily: 'Quicksand',
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '25px',
        color: '#fff'
    },

    premiumTextFirst: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 700,
        color: '#fff',
    },

    textTypograpyCancel: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 500,
        color: '#fff'
    },
    imgSkip: {
        maxWidth: '24px',
        marginRight: '10px',
        paddingTop: '2px',
    },
    footerTextMain: {
        textAlign: 'center' as 'center',
        padding: '20px',
        paddingBottom: '84px',
    },
    benefitMainCancel: {
        backgroundColor: '#fff',
        marginTop: '30px',
        height: 'auto',
        display: 'flex',
        borderRadius: '0 12px 12px 0',
        justifyContent: 'right',
        width: '240px',
        textAlign: 'right' as 'right',

    },
    xpMainCancel: {
        marginTop: '10px',
        padding: '20px 30px 50px 50px',
    },


    getFirstTitle: {
        fontFamily: 'Quicksand',
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999',
        fontSize: '12px',
        fontWeight: 600,

    },

    parentCancelSectionImage: {
        backgroundImage: `url(${view_rectangle})`,
        backgroundSize: 'auto 20px',
        backgroundRepeat: 'no-repeat',
        width: '708px',
        display: 'flex',
        backgroundPosition: 'left center',
    },
    textTtileCancel: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        color: '#FFFFFF',
        width: '708px',
        height: '12px',
        fontSize: '35px',
        fontWeight: 700,
        lineHeight: '29px',
        paddingLeft: '35px',
    },

    boxparentCancel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    btnBoxMainCancel: {
        height: 'auto',
        borderRadius: '12px',
        background: '#36BFFF',
        width: '100%',
        margin: '15px 50px 0px 50px',
        textAlign: 'center' as 'center',
        boxSizing: 'border-box' as 'border-box',
    },

};

export default withTranslation()(SubscriptionCancelledPremium)
// Customizable Area End

