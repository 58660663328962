export const skip_wrench = require("../assets/skip_wrench.svg");
export const btn_back = require("../assets/btn_back.svg");
export const img_chk = require("../assets/img_chk.svg");
export const skip_freemiumIcon = require("../assets/skip_freemiumIcon.svg");
export const skip_vector = require("../assets/skip_vector.svg");
export const skip_arrow = require("../assets/skip_arrow.svg");
export const skip_bolt = require("../assets/skip_bolt.svg");
export const view_rectangle = require("../assets/view_rectangle.svg");
export const skip_diamond = require("../assets/skip_diamond.svg");
export const group_chk = require("../assets/group_chk.svg");
export const button_showone = require("../assets/button_showone.svg");
export const button_info = require("../assets/button_info.svg");
export const group_teddy = require("../assets/group_teddy.svg");
export const group_state = require("../assets/group_state.svg");
export const group_diamond = require("../assets/group_diamond.svg");
export const skip_check1 = require("../assets/skip_check1.svg");
export const skip_check2 = require("../assets/skip_check2.svg");
export const skip_check3 = require("../assets/skip_check3.svg");
export const skip_check4 = require("../assets/skip_check4.svg");
export const skip_close = require("../assets/skip_close.svg");
export const button_back = require("../assets/button_back.svg");
export const group_checkout = require("../assets/group_checkout.svg");
export const skip_star = require("../assets/skip_star.svg");
export const group_state2 = require("../assets/group_state2.svg");
export const group_notification = require("../assets/group_notification.svg");
export const skip_men = require("../assets/skip_men.svg");
export const skip_calendar = require("../assets/skip_calendar.svg");
export const skip_card = require("../assets/skip_card.svg");
export const skip_password = require("../assets/skip_password.svg");
export const group_teddy1 = require("../assets/group_teddy1.svg");
export const button_addcard = require("../assets/button_addcard.svg");
export const group_mail = require("../assets/group_mail.svg");
export const teddyPage = require("../assets/teddyPage.svg");
export const button_loading = require("../assets/button_loading.svg");
export const group_divine = require("../assets/group_divine.svg");
export const page_decline = require("../assets/page_decline.svg");
export const pending_teddy = require("../assets/pending_teddy.svg");
export const group_pending = require("../assets/group_pending.svg");
export const premium_teddy = require("../assets/premium_teddy.svg");
export const group_success = require("../assets/group_success.svg");
export const vextor_tedix = require("../assets/vextor_tedix.svg");
export const group_title = require("../assets/group_title.svg");
export const group_illustration = require("../assets/group_illustration.svg");
export const skip_insta = require("../assets/skip_insta.svg");
export const skip_twitter = require("../assets/skip_twitter.svg");
export const skip_tiktok = require("../assets/skip_tiktok.svg");
export const gift_icon = require("../assets/gift_icon.svg");
export const diamond = require("../assets/diamond.svg");
export const box_icon = require("../assets/box_icon.svg");
export const arrow_pick = require("../assets/arrow_pick.svg");
export const view_rect = require("../assets/view_rect.svg");
export const calendar_latest = require("../assets/calendar_latest.svg");
export const first_calendar = require("../assets/first_calendar.svg");
export const button_switch = require("../assets/button_switch.svg");
export const icon_msg = require("../assets/icon_msg.svg");
export const visa_img = require("../assets/visa_img.svg");
export const view_teddy = require("../assets/view_teddy.svg");
export const view_bg = require("../assets/view_bg.svg");
export const skip_vectors = require("../assets/skip_vectors.svg");
export const skip_value = require("../assets/skip_value.svg");
export const skip_medal = require("../assets/skip_medal.svg");
export const skip_trophy = require("../assets/skip_trophy.svg");
export const skip_onebook = require("../assets/skip_onebook.svg");
export const skip_changes = require("../assets/skip_changes.svg");
export const skip_union = require("../assets/skip_union.svg");
export const group_lesson = require("../assets/group_lesson.png");
export const calendar_img = require("../assets/calendar_img.svg");
export const skip_stats = require("../assets/skip_stats.svg");
export const skip_close1 = require("../assets/skip_close1.svg");
export const bigstats = require("../assets/bigstats.svg");
export const groupTeddy = require("../assets/groupTeddy.svg");
export const group1_mail = require("../assets/group1_mail.svg");
export const card_delete = require("../assets/card_delete.svg");
export const add_card = require("../assets/add_card.svg");
export const group_noti = require("../assets/group_noti.svg");
export const button_next = require("../assets/button_next.svg");
export const group_warning = require("../assets/group_warning.svg");
export const group_popup = require("../assets/group_popup.svg");
export const skip_delete = require("../assets/skip_delete.svg");
export const imageMastercard = require('../assets/image_mastercard.svg')
export const skipAdd = require('../assets/skip_add.svg')
export const skipDelete = require('../assets/skipDelete.svg')
export const loader = require('../assets/loader.svg');
export const card_bg = require('../assets/card-bg.svg')
export const arrowDetail = require('../assets/arrowDetail.svg')
export const backgroundCard = require('../assets/background_card.svg')
export const imageVisa = require('../assets/image_VISA.svg')
export const premiumDashboard1 = require('../assets/PremiumDashboard1.svg')
export const premiumDashboard2 = require('../assets/PremiumDashboard2.png')
export const premiumDashboard3 = require('../assets/PremiumDashboard3.svg')
export const premiumDashboard4 = require('../assets/PremiumDashboard4.svg')
export const vectorLine = require('../assets/vectorline.svg')
export const groupBear = require('../assets/group_bear.svg')
export const masterCardLogo = require('../assets/Mastercard-logo.png')
export const teddy_expired = require("../assets/teddy_expired.svg")
export const white_loader = require('../assets/white_loader.svg')
export const amexCardLogo = require('../assets/amex_card_logo.png')
export const dinerCardLogo = require("../assets/diner_card_logo.png")
export const discoverCardLogo = require('../assets/discover_card_logo.png')
export const jcbCardLogo = require('../assets/jcb_card_logo.png')
export const unionCardLogo = require('../assets/union_card_logo.png')
export const teddyBlue =  require('../assets/bluebear.svg')
export const teddyBrown =  require('../assets/brownbear.svg')
export const teddyGreen =  require('../assets/greenbear.svg')
export const teddyRed =  require('../assets/redbear.svg')
export const teddyYellow =  require('../assets/yellow bear.svg')
export const teddyLightgreen =  require('../assets/mintbear.svg')
export const teddyOrange =  require('../assets/orangebear.svg')
export const teddyPurple =  require('../assets/purplebear.svg')
export const sadBlue =  require('../assets/sad_blue.svg')
export const sadBrown =  require('../assets/sad_brown.svg')
export const sadGreen =  require('../assets/sad_green.svg')
export const sadRed =  require('../assets/sad_red.svg')
export const sadYellow =  require('../assets/sad_yellow.svg')
export const sadmint =  require('../assets/sad_mint.svg')
export const sadOrange =  require('../assets/sad_orange.svg')
export const sadPurple =  require('../assets/sad_purple.svg')
export const blueSuccess =  require('../assets/blueSuccess.svg')
export const brownSuccess =  require('../assets/brownSuccess.svg')
export const greenSuccess =  require('../assets/greenSuccess.svg')
export const redSuccess =  require('../assets/redSuccess.svg')
export const yellowSuccess =  require('../assets/yellowSuccess.svg')
export const mintSuccess =  require('../assets/mintSuccess.svg')
export const orangeSuccess =  require('../assets/orangeSuccess.svg')
export const purpleSuccess =  require('../assets/purpleSuccess.svg')
export const progress =  require('../assets/progress.svg')

