import React, { Component } from "react";
// Customizable Area Start
import { Box, Typography, styled, IconButton } from "@material-ui/core";
import {
  loader,
  vectorArrowLeft,
  buttonGrade,
  skipUp,
  skipDown
} from "./assets";
import MedianWeeklyData from "./MedianGradeWeeklyData.web";
import {LessonDataArray,MainLessonDetailObject} from  "./MedianController.web"
import ReportLessonPageWeb from "./ReportLessonPage.web";
interface SS {
  handleBack: () => void;
  tabCategory: "weekly"|"daily"|"monthly";
  loading:boolean;
  data: Array<LessonDataArray | undefined > | Array<MainLessonDetailObject>
  average:number;
  dateTime:string;
  startDate:string;
  previousAverage:number;
  endDate:string;
  t?:any;
  TeddyIcon:string;
}
// Customizable Area End

const mockWeekData = [

  {
    day: "MONDAY",
    data: []
  },
  {
    day: "TUESDAY",
    data: []
  },
  {
    day: "WEDNESDAY",
    data: [
      {
        lesson: 'LESSON 1',
        title: "Algebraic Operations with Real Numbers",
        xp_points: "80",
        minute: 20,
      },

      {
        lesson: 'LESSON 2',
        title: "Ordering Real Numbers",
        xp_points: "90",
        minute: 20,
      },

      {
        lesson: 'LESSON 3',
        title: "Module of a Real Number",
        xp_points: "90",
        minute: 20,
      }
    ]
  },
  {
    day: "THURSDAY",
    data: [
      {
        lesson: 'LESSON 1',
        title: "Algebraic Operations with Real Numbers",
        xp_points: "80",
        minute: 20,
      },

      {
        lesson: 'LESSON 2',
        title: "Ordering Real Numbers",
        xp_points: "90",
        minute: 20,
      },

      {
        lesson: 'LESSON 3',
        title: "Module of a Real Number",
        xp_points: "90",
        minute: 20,
      }
    ]
  },
  {
    day: "FRIDAY",
    data: []
  },
  {
    day: "SATURDAY",
    data: []
  },
  {
    day: "SUNDAY",
    data: []
  },

]


const mockYearData=[

  {
    label: "WEEK 1",
    data: []
  },
  {
    label: "WEEK 2",
    data: []
  },
  {
    label: "WEEK 3",
    data: [
      {
        lesson: 'LESSON 1',
        title: "Algebraic Operations with Real Numbers",
        xp_points: "80",
        minute: 20,
      },

      {
        lesson: 'LESSON 2',
        title: "Ordering Real Numbers",
        xp_points: "90",
        minute: 20,
      },

      {
        lesson: 'LESSON 3',
        title: "Module of a Real Number",
        xp_points: "90",
        minute: 20,
      }
    ]
  },
  {
    label: "WEEK 4",
    data: []
  },
]

export default class ReportDetail extends Component<SS> {
  constructor(props: SS) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start

  state = {
    toogleShowMultipleLesson: false,
    lessonOpen:""
  };


  handleToggle=(index:number)=>{
    if(this.state.lessonOpen === `show${index}`){
      this.setState({
        lessonOpen:""
      })
    }else{
      this.setState({
        lessonOpen:`show${index}`
      })
    }
    
  }
  getData=()=>{
    const LessonDetail = this.props.data as Array<MainLessonDetailObject>
    return(
      <>
     { LessonDetail.length > 0 ?
          LessonDetail?.map((item : MainLessonDetailObject,index:number)=>{
            const lessonDetail  = item
           return (
            <ReportLessonPageWeb 
              key={index}
              data-testid={`showReportLessonPageWeb${index}`}
              Lessondata={{ ...lessonDetail, startDate: this.props.startDate, endDate: this.props.endDate }}
              toggleShow={()=>this.handleToggle(index)} 
              isOpen={this.state.lessonOpen}
              index={index}/>   
           )
          })
          
          :
          <Box className="empty_lesson_box">
          <Typography className="reportMultipleDate_empty_lesson">
          { this.props.t("No lessons completed")}
          </Typography>
        </Box>
        
        }
        </>
    )
  }
 
  renderListLearningReport() {
    return (
      <Box className="reportDetail__reportLessons">
        {

      this.props.loading?
      <Box className="loaderBox" >
      <img src={loader} alt="Loading" />
      </Box>: 
      this.getData()
        }
      </Box>
    );
  }

  renderAverageGrade=()=>{
    if(this.props.average>this.props.previousAverage){
      return <img
      className="median_gradeStat_gradeStatIcon"
      src={skipUp}
      alt="Avg. grade stat"
    />
    }else if(this.props.average<this.props.previousAverage){
      return <img
      className="median_gradeStat_gradeStatIcon"
      src={skipDown}
      alt="Avg. grade stat"
    />
    }
   }

  // Customizable Area End
  render() {
    // Customizable Area Start
      const {average} = this.props
      console.log("this.props.data",this.props.data)
    // Customizable Area End
    return (
      // Customizable Area Start
      <ReportDetailStyle>
        <Box className="reportDetail_nav">
          <IconButton
            data-testid="btnBack"
            style={{marginRight:16}}
            onClick={() => {
              this.props.handleBack();
            }}
          >
            <img src={vectorArrowLeft} width={16} height={16} />
          </IconButton>
          <Box className="reportDetail_nav_title">
            <Box className="reportDetail_nav_title_icon">
              <img src={this.props.TeddyIcon} width={40} height={40} className="teddy_image"/>
            </Box>
            <Box className="reportDetail_nav_title_text">
              <img src={buttonGrade} width={20} height={20}/>
              <Typography>{this.props.t("Median Grade")}</Typography>
            </Box>
          </Box>
          <Box className="reportDetail_gradeStat">
            <div className="reportDetail_gradeStat_medianBox">
              <div className="reportDetail_gradeStat_medianNumber">{average}</div>
              {this.renderAverageGrade()}
            </div>
          </Box>
        </Box>
        <></>
        {this.props.tabCategory === "daily"&& (
          <>
            <Box className="reportDetail__timeline">
              <Typography className="reportDetail__timeline-text" variant="h6">
                {this.props.dateTime}
              </Typography>
            </Box>
            {this.renderListLearningReport()}
          </>
        )}

        {this.props.tabCategory === "weekly" && (
          <>
            <Box className="reportDetail__timeline">
              <Typography className="reportDetail__timeline-text" variant="h6">
              {this.props.dateTime}
              </Typography>
            </Box>
            {this.props.loading ?
              <Box className="loaderBox" >
              <img src={loader} alt="Loading" />
              </Box>:
            <Box style={{width:"100%"}}> 
             <MedianWeeklyData 
               mockWeekYearData ={this.props.data as Array<LessonDataArray | undefined>}
             />
             </Box>
            }
          </>
        )}

        {this.props.tabCategory === "monthly" && (
          <>
            <Box className="reportDetail__timeline">
              <Typography className="reportDetail__timeline-text" variant="h6">
              {this.props.dateTime}
              </Typography>
            </Box>
            <Box style={{width:"100%"}}> 
            {this.props.loading ?
              <Box className="loaderBox" >
              <img src={loader} alt="Loading" />
              </Box>:
             <MedianWeeklyData 
               mockWeekYearData ={this.props.data as Array<LessonDataArray | undefined>}
             />
            }
             </Box>
          </>
        )}
      </ReportDetailStyle>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ReportDetailStyle: React.ComponentType = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  "& .reportDetail_nav": {
    display: "flex",
    width: "100%",
    height: "50px",
    marginTop: 24,
    justifyContent: "center"
  },
  "& .reportDetail_nav button": {
    padding: "4px"
  },
  "& .reportDetail_nav_title": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    background: "#E6E6E6",
    padding: "8px",
    borderRadius: "40px 10px 10px 40px"
  },
  "& .teddy_image":{
    borderRadius:'50%',
    marginTop:"-3.5px",
    marginLeft:'-2px'
  },
  "& .reportDetail_nav_title_text": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "0 8px"
  },
  "& .reportDetail_nav_title_text .MuiTypography-body1": {
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: "18px",
    marginLeft:'4px',
    marginTop:'1px',
  },
  "& .reportDetail_gradeStat_medianBox": {
    display: "flex",
    alignItems: "center",
    background: "#A9D6F6",
    height: "100%",
    borderRadius: "10px",
    opacity: 0.9,
    marginLeft: "5px",
    padding: "20px 16px"
  },
  "& .loaderBox":{
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%'
  },
  "& .reportDetail_gradeStat_medianNumber": {
    color: "#0084E2",
    fontFamily: "Nunito",
    fontWeight: 900,
    fontSize: "18px"
  },
  "& .reportDetail__timeline": {
    padding: '12px',
    margin: "30px 0"
  },
  "& .median_gradeStat_gradeStatIcon":{
    marginLeft:"8px"
  },
  "& .reportDetail__timeline .reportDetail__timeline-text": {
    fontFamily: "Nunito",
    color: "#62CDFF",
    fontSize: "16px",
    fontWeight: 500,
    textDecoration: "underline",
    textUnderlineOffset: "8px",
    textDecorationThickness: "3px",
    textDecorationColor: "#C9EEFF"
  },
  "& .reportDetail__reportLessons": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "15px"
  },
  "& .empty_lesson_box": {
    padding: "10px 20px 20px",
    borderBottom: "1px solid  var(--Primary-Neutrals-Warm-gray-50, #F2F2F2)"
  },
  "& .reportMultipleDate_empty_lesson": {
    color: "var(--Primary-Neutrals-Warm-gray-200, #CCC)",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px" /* 92.857% */
  },
}));
// Customizable Area End
