import React, { useState } from "react";

import {
  // Customizable Area Start
  styled,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { skipStar, skipRatingStar } from "./assets";
import { Theme } from "@material-ui/core/styles/createTheme";
// Customizable Area End

interface CustomRatingProps {
  value: number | null;
  onChange: (value: number | null) => void;
}

// Customizable Area Start
const CustomStar = styled("div")(({ theme, isClicked, rated  }: { theme: Theme; isClicked: boolean, rated: boolean}) => ({
  position: 'relative',
  display: 'inline-block',
  cursor: rated ? '' : 'pointer',
  width: '1.5em', 
  height: '1.5em', 
  marginRight: '0.5em', 
  backgroundSize: 'cover',
  padding: '2px', 
  backgroundImage: `url(${isClicked  ? skipStar : skipRatingStar})`,

  '& .inner-star': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${skipStar})`,
    backgroundSize: 'cover',
    opacity: 0,
    pointerEvents: 'none', 
  },
  '&.empty-star:hover .inner-star': {
    opacity: rated ? 0 : 1,
  },
}));

const CustomizedRating: React.FC<CustomRatingProps> = ({ value, onChange }) => {
  const [hoverValue, setHoverValue] = useState<number | null>(null);
  const [clickedStars, setClickedStars] = useState<boolean[]>(Array.from({ length: 5 }, () => false));

  const handleHoverChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    newHover: number | null
  ) => {
    setHoverValue(newHover);
  };
  const handleStarClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    if (clickedStars.every(item => !item)) {
      const newClickedStars = clickedStars.map((value, i) => i === index ? !value : value);
      setClickedStars(newClickedStars);
  
      // Skip stars if clicked on 2nd, 3rd, 4th, or 5th
      for (let i = 0; i < index; i++) {
        newClickedStars[i] = true;
      }
  
      if (newClickedStars[index]) {
        for (let i = index + 1; i < newClickedStars.length; i++) {
          newClickedStars[i] = false;
        }
      }
  
      const newValue = newClickedStars.findIndex(value => !value) + 1;
      onChange(newValue);
    }
  };
  // Customizable Area end

  // Customizable Area Start
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <CustomStar
        data-testid="ratingStar"
          key={index}
          theme={{} as Theme}
          isClicked={clickedStars[index]}
          className={index < (hoverValue ?? value ?? 0) ? "empty-star" : ""}
          onClick={(event) => handleStarClick(event, index)}
          onMouseEnter={(event) => handleHoverChange(event, index + 1)}
          rated={!clickedStars.every(item => !item)}
        >
          <div className="inner-star" />
        </CustomStar>
      ))}
    </>
  );
};

export default CustomizedRating;
// Customizable Area Start
