// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled
} from '@material-ui/core'
import Header from "../../../components/src/Header";
import {skipTeddy} from "./assets"
import { withTranslation } from "react-i18next";


import ParentVerificationFailureController from "./ParentVerificationFailureController.web";
import { ButtonCustom } from "../../../components/src/MaterialTheme";
import SelectLanguage from "../../../components/src/SelectLanguage";
// Customizable Area End

class ParentVerificationFailure extends ParentVerificationFailureController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Container>
              <Box className="Header_container">
                    <Header showGoBackBtn={false} />
                </Box>  
              <Box className="verification_fail">  
             <Box className="verification_container">
                <Typography className="verification_text">Oops!</Typography>
                <Typography className="verification_text"> {this.props.t("Verification Failed")}</Typography>
             </Box>
             </Box>

             <Box className="skip_teddy">
                <img src={skipTeddy} alt="teddy_image" height={165}/>
             </Box>
            <Box className="bottom_text">
             <Box className="text_message_contaier">
                <Box>
                  <Typography className="verification_message">{this.props.t("The verification didn't go as planned.")}</Typography>
                </Box>
                <Box className="top_margin">
                   <Typography className="verification_message">{this.props.t("No worries! You can double-check the information and try again to ensure a successful account verification.")}</Typography>
                </Box>
                </Box>
             </Box>

             <Box className="button_style">
                <ButtonCustom 
                 data-testid="try_again_btn"
                 onClick={()=>{
                    this.props.navigation.navigate("ParentSms")
                }} 
                className="btn">{this.props.t("Try again")}</ButtonCustom>
             </Box>

             <Box className="Language">
                <SelectLanguage />
             </Box>
            </Container>
            // Customizable Area End
        )
    }
}

// Customizable Area Start

export default withTranslation()(ParentVerificationFailure)

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    "& .Header_container > .MuiBox-root  > .imageWrapper": {
        justifyContent: 'center',
        marginLeft: 0,
    },

    "& .verification_text":{
        fontFamily: "Nunito",
        fontSize: "22px",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "0em",
        textAlign: "center",
        color:"#363636",
    },

    "& .verification_container":{
        width:"260px",
        textAlign:'center'
    },

    "& .verification_fail":{
        width:"100%",
        display:'flex',
        marginTop: '80px',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
   "& .skip_teddy":{
    marginTop:'64px',
    textAlign:'center'
   },

   "& .text_message_contaier":{
    marginTop:56,
    alignItems:'center',
    textAlign:'center',
    width:"330px",
   },

   "& .bottom_text":{
    alignItems:'center',
    width:"100%",
    display:'flex',
   justifyContent:"center"
   },

   "& .top_margin":{
   marginTop:"8px"
   },

   "& .verification_message":{
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "center",
    color:"#666666"
   },

   "& .button_style":{
    marginTop:"48px",
     width:'100%',
     alignItems:'center',
     justifyContent:"center",
     display:'flex',

   },
   "& .btn":{
    maxWidth:"310px"
   },

   "& .Language":{
    marginTop:'48px',
    width:"100%",
    textAlign:'center'
   }
}))   

// Customizable Area End


