export const bookIcon = require("../assets/book-icon.svg");
export const cupIcon = require("../assets/cup-icon.svg");
export const flashIcon = require("../assets/flash-icon.svg");
export const medalIcon = require("../assets/medal-icon.svg");
export const notificationIcon = require("../assets/notification-icon.svg");
export const superIcon = require("../assets/super-icon.svg");
export const teacherIcon = require("../assets/teacher-icon.svg");
export const avtarIcon = require("../assets/avtar-icon.svg");
export const homeIcon = require("../assets/home-icon.svg");
export const crownIcon = require("../assets/crown-icon.svg");
export const shopIcon = require("../assets/shop-icon.svg");
export const settingIcon = require("../assets/setting-icon.svg");
export const userIcon = require("../assets/user-icon.svg");
export const buttonNotification = require("../assets/button_notifications.svg");
export const groupAvtar = require("../assets/group_avatar.svg");
export const skipBolt = require("../assets/skip_bolt.svg");
export const skipGoals = require("../assets/skip_goals.svg");
export const skipGrade = require("../assets/skip_grade.svg");
export const skipTrophy = require("../assets/skip_trophy.svg");
export const skipVector = require("../assets/skip_vector.svg");
export const imageFlag = require("../assets/button_languagecountry.svg");
export const buttonMenu = require("../assets/button_menu.svg");
export const groupLogo = require("../assets/group_logo.svg");
export const pandaFlage = require("../assets/pandaFlag.svg");
export const skipComunications = require("../assets/skip_comunications.svg");
export const searchIcon = require("../assets/search-icon.svg");
export const EnglishFlag = require("../assets/English_Flag.png");
export const GermanFlag = require("../assets/German_Flag.png");
export const HungarianFlag = require("../assets/Hungarian_Flag.png");
export const RomanianFlag = require("../assets/Romanian_Flag.png");
export const skipwrench = require("../assets/skip_wrench.svg");
export const skipglobe = require("../assets/skip_globe.svg");
export const checkedIcon = require("../assets/check.svg");
export const uncheckIcon = require("../assets/uncheck.svg");
export const xpskipwrench = require("../assets/xp_skip_wrench.svg");
export const skipachiever = require("../assets/skip_achiever.svg");
export const skipsocialiser = require("../assets/skip_socialiser.svg");
export const skipchallengesIcon = require("../assets/skip_challengesIcon.svg");
export const skipfeedback = require("../assets/skip_feedback.svg");
export const free = require("../assets/free.svg");
export const premimum = require("../assets/premimum.svg");
export const medal = require("../assets/medal.svg");
export const groupconfetti = require("../assets/group_confetti.svg");
export const buttonDown = require("../assets/buttonDown.svg");
export const goalMedal = require("../assets/goalMedal.svg");
export const calender = require("../assets/calender.svg");
export const lessonGoal = require("../assets/Untitled.svg");
export const trophies = require("../assets/skip_trophies.svg");
export const buttonTrophies = require("../assets/button_trophies.svg");
export const buttonGoals = require("../assets/button_goals.svg");
export const buttonGrade = require("../assets/group_grade.svg");
export const buttonLangCountry = require("../assets/button_languagecountry.svg");
export const buttonpxMonth = require("../assets/button_pxMonth.svg");
export const buttonMultilevel = require("../assets/button_multiLevel.svg");
export const groupDecoration = require("../assets/group_decoration.svg");
export const skipTeddy = require("../assets/skip_Teddy.svg");
export const groupMaskMath = require("../assets/group_maskMathFull.png");
export const math = require("../assets/math.svg");
export const language = require("../assets/language.svg");
export const algebra = require("../assets/algebra.svg");
export const physics = require("../assets/physics.svg");
export const mathTwoline = require("../assets/mathTwoline.svg");
export const grade9 = require("../assets/grade9.svg");
export const grade9Twoline = require("../assets/grade9Twoline.svg");
export const mathOneline = require("../assets/mathOneline.svg");
export const backgroundImage = require("../assets/backgroundImage.svg");
export const ellipse = require("../assets/Ellipse 228.svg");
export const centerLine = require("../assets/center_view_line.svg");
export const rightLine = require("../assets/right_view_line.svg");
export const leftLine = require("../assets/left_view_line.svg");
export const groupLesson = require("../assets/group_lesson.svg");
export const groupLesson1 = require("../assets/group_lesson1.svg");
export const groupLesson2 = require("../assets/group_lesson2.svg");
export const groupLesson3 = require("../assets/group_lesson3.svg");
export const groupLesson4 = require("../assets/group_lesson4.svg");
export const groupLesson5 = require("../assets/group_lesson5.svg");
export const groupLesson6 = require("../assets/group_lesson6.svg");
export const rectangle = require("../assets/view_rectangle.svg");
export const groupMultilevel = require("../assets/group_multiLevel.svg");
export const groupscroll = require("../assets/Group_19250.svg");
export const mathalg = require("../assets/button_subject.svg");
export const algebramain = require("../assets/button_master.svg");
export const typeFunction = require("../assets/group_function.png");
export const skipTime = require("../assets/skip_time.svg");
export const file = require("../assets/file.svg");
export const circle = require("../assets/circle.svg");
export const Search = require("../assets/skip_search.svg");
export const Back = require("../assets/homeMenu.svg");
export const backImage = require("../assets/group_maskMathBack.svg");
export const buttonHide = require("../assets/button_hide.svg");
export const buttonShow = require("../assets/button_show.svg");
export const skipShowLeft = require("../assets/skip_show_left.svg");
export const skipShowRight = require("../assets/skip_show_right.svg");
export const skipCircle = require("../assets/skip_circle.svg");
export const skipUp = require("../assets/skip_up-stat.svg");
export const skipDown = require("../assets/skip_down-stat.svg");
export const viewRectangleChart = require("../assets/view_rectangle_chart.svg");
export const vectorArrowLeft = require("../assets/vector_arrow.svg");
export const divider = require("../assets/view_divide.svg");
export const filterButton = require("../assets/button_filter.svg");
export const graySearch = require("../assets/gray_search.svg");
export const searchTeddy = require("../assets/search_Teddy.svg");
export const crossButton = require("../assets/close.svg");
export const disableicon = require("../assets/skip_show.svg");
export const skipHistory = require("../assets/skip_history.svg");
export const messageIcon = require("../assets/skip_union.svg")
export const gradeIcon = require("../assets/grade_icon.svg")
export const listIcon =  require('../assets/skip_list.png')
export const readyBlue =  require('../assets/blue_ready.svg')
export const readyBrown =  require('../assets/brown_ready.svg')
export const readyGreen =  require('../assets/green_ready.svg')
export const readyLightgreen =  require('../assets/lightgreen_ready.svg')
export const readyOrange =  require('../assets/orange_ready.svg')
export const readyPurple =  require('../assets/purple_ready.svg')
export const readyRed =  require('../assets/red_ready.svg')
export const readyYellow =  require('../assets/yellow_ready.svg')
export const brownHealthy =  require('../assets/brown_healthyavatar.svg')
export const brownHappy = require('../assets/brown_happyavatar.svg')
export const brownWeak = require('../assets/brown_weakavatar.svg')
export const brownWorried =  require('../assets/brown_worriedavatar.svg')
export const ghostlyAvatar = require('../assets/ghostly.svg')
export const yellowHealthy =  require('../assets/yellow_healthyavatar.svg')
export const yellowHappy = require('../assets/yellow_happyavatar.svg')
export const yellowWeak = require('../assets/yellow_weakavatar.svg')
export const yellowWorried =  require('../assets/yellow_worriedavatar.svg')
export const orangeHealthy =  require('../assets/orange_healthyavatar.svg')
export const orangeHappy = require('../assets/orange_happyavatar.svg')
export const orangeWeak = require('../assets/orange_weakavatar.svg')
export const orangeWorried =  require('../assets/orange_worriedavatar.svg')
export const purpleHealthy =  require('../assets/purple_healthyavatar.svg')
export const purpleHappy = require('../assets/purple_happyavatar.svg')
export const purpleWeak = require('../assets/purple_weakavatar.svg')
export const purpleWorried =  require('../assets/purple_worriedavatar.svg')
export const redHealthy =  require('../assets/red_healthyavatar.svg')
export const redHappy = require('../assets/red_happyavatar.svg')
export const redWeak = require('../assets/red_weakavatar.svg')
export const redWorried =  require('../assets/red_worriedavatar.svg')
export const greenHealthy =  require('../assets/green_healthyavatar.svg')
export const greenHappy = require('../assets/green_happyavatar.svg')
export const greenWeak = require('../assets/green_weakavatar.svg')
export const greenWorried =  require('../assets/green_worriedavatar.svg')
export const lightgreenHealthy =  require('../assets/lightgreen_healthyavatar.svg')
export const lightgreenHappy = require('../assets/lightgreen_happyavatar.svg')
export const lightgreenWeak = require('../assets/lightgreen_weakavatar.svg')
export const lightgreenWorried =  require('../assets/lightgreen_worriedavatar.svg')
export const blueHealthy =  require('../assets/blue_healthyavatar.svg')
export const blueHappy = require('../assets/blue_happyavatar.svg')
export const blueWeak = require('../assets/blue_weakavatar.svg')
export const loader = require('../assets/loader.svg');
export const blueWorried =  require('../assets/blue_worriedavatar.svg')
export const TedixHeadingText =  require('../assets/skip_logo.svg')
export const TextableFour =  require('../assets/textlabel_title.svg')
export const sadTeddy =  require('../assets/skip_sadTeddy.svg')
export const EditPencil = require('../assets/group_pencil.svg')
export const skipCalender = require('../assets/skip_calendar.svg')
export const clock = require('../assets/skip_clock.svg')
export const dropDown = require('../assets/skip_dropdown.svg')
export const grayCircle = require('../assets/greyImage.svg')
export const doubleXp = require('../assets/doubleXp.svg')

export const TeddyBrown = require('../assets/TeddyBrown.png')
export const TeddyBlue = require('../assets/TeddyBlue.png')
export const TeddyGreen = require('../assets/TeddyGreen.png')
export const TeddyLightGreen = require('../assets/TeddyLightGreen.png')
export const TeddyOrange = require('../assets/TeddyOrange.png')
export const TeddyPurple = require('../assets/TeddyPurple.png')
export const TeddyRed = require('../assets/TeddyRed.png')
export const TeddyYellow = require('../assets/TeddyYellow.png')