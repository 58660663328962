// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
//@ts-ignore
import  Cookies from "js-cookie"
import { Message } from '../../../framework/src/Message'
import i18next from "i18next";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?:any
}

export interface LanguageType {
  id: string
  type: string
  attributes: {
    id: number
    name: 'English' | 'German' | 'Hungarian' | 'Romanian'
    language_code: string
    is_content_language: string
    is_app_language: string
    emoji_flag: string
    created_at: string
    updated_at: string
  }
}

interface S {
  isLoading: boolean
  selectLanguage:string|null
  accountId: string
  appLanguageId: number | null
  selectedLanguageId: number | null
  currentLanguage: LanguageType | null
  languageOptionsList: Array<LanguageType>
  appLanguageList: Array<LanguageType>
  token: string
  isSubmitLanguageError?: boolean
  saveButtonText: string
}

interface SS {
  id: string;
}

export default class LanguageOptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getSpecificAccountId: string = ""
  getAppLanguageListId: string = ""
  changeAppLanguageId: string = ""

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      isLoading: false,
      accountId: '',
      appLanguageId: null,
      selectedLanguageId: null,
      selectLanguage:null,
      currentLanguage: null,
      languageOptionsList: [],
      appLanguageList: [],
      token: '',
      saveButtonText: 'Save'
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = localStorage.getItem('token') || ''
    this.getSpecificAccount(token)
    this.getAppLanguageList()
    this.setState({ token: token})
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.getSpecificAccountId
    ) {
      this.setState({
        isLoading: false,
        accountId: responseJson?.data?.id,
        selectLanguage:responseJson?.data?.attributes?.app_language_name,
        appLanguageId: responseJson?.data?.attributes?.app_language_id
      })
      if (this.state.appLanguageList?.length !== 0) {
        this.handleLanguageListData(this.state.appLanguageList)
      }
    }

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.getAppLanguageListId
    ) {
      this.setState({
        appLanguageList: responseJson?.data
      })
      this.handleLanguageListData(responseJson?.data)
    }

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.changeAppLanguageId
    ) {
      if (responseJson?.errors) {
        this.setState({isSubmitLanguageError: true})
        return
      }
      if(this.state.selectLanguage!==null){
        Cookies.set("webAppLanguage",JSON.stringify({name:this.state.selectLanguage,id:this.state.selectedLanguageId}))
        i18next.changeLanguage(this.state.selectLanguage)
       }
      this.setState({
        selectedLanguageId: null,
        saveButtonText: 'Saved'
      })
      this.getSpecificAccount(this.state.token)
    }
  }

  handleLanguageListData = (dataList: any) => {
    const currentAppLanguage = dataList?.find((item: any) => item?.attributes?.id === this.state.appLanguageId)
    const appLanguageOptions = dataList?.filter((item: any) => item?.attributes?.id !== this.state.appLanguageId)
      this.setState({
        currentLanguage: currentAppLanguage,
        languageOptionsList: appLanguageOptions
      })
  }

  getSpecificAccount = (token: string) => {
    this.setState({ isLoading: true })
    this.getSpecificAccountId = sendAPIRequest('account_block/accounts/specific_account', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      },
    })
  }

  getAppLanguageList = () => {
    this.getAppLanguageListId = sendAPIRequest('bx_block_language_options/languages', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  changeAppLanguage = () => {
    const body = {
      data: {
        attributes: {
          app_language_id: this.state.selectedLanguageId
        }
      }
    }
    this.changeAppLanguageId = sendAPIRequest(
      `account_block/accounts/${this.state.accountId}`,
      {
        method: 'PUT',
        body: body,
        headers: {
          'Content-Type': 'application/json',
          'token': this.state.token
        },
      }
    )
  }
}
// Customizable Area End
