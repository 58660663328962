import { Box, Typography, styled } from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  challengeRouletteBg,
  closeBtn,
  roulette,
  rouletteHand,
} from '../../../blocks/assessmenttest/src/assets'
import { useTranslation } from 'react-i18next'

interface ChallengeRouletteProps {
  onGoBack: () => void
}

const ChallengeRoulette: React.FC<ChallengeRouletteProps> = ({onGoBack}) => {
  const { t } = useTranslation()

  return (
    <ChallengeRouletteContainer>
      <Box className="childContentContainer">
        <Box className="topContainer">
          <img src={closeBtn} className="cancelButton" onClick={onGoBack} />
          <Typography className="titleText" style={{ fontSize: '21px' }}>
            {t('CHALLENGE')}
          </Typography>
          <Typography className="titleText" style={{ fontSize: '32px' }}>
            {t('SHUFFLER')}
          </Typography>
        </Box>
        <img src={roulette} className="rouletteAnimation" />
        <Box className="bottomContainer">
          <Typography className="descText">
            {t('You never know what you might discover next!')}
          </Typography>
          <img src={rouletteHand} width={152} height="auto" />
        </Box>
      </Box>
    </ChallengeRouletteContainer>
  )
}

const ChallengeRouletteContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundSize: '100% 100%',
  backgroundImage: `url(${challengeRouletteBg})`,
  height: '100%',

  '& .rouletteAnimation': {
    width: '318px',
    height: '341px',
    margin: '20px 0',
  },

  '& .childContentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  '& .topContainer': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '& .cancelButton': {
    alignSelf: 'flex-start',
    margin: '26px 0 42px 26px',
    width: '20px',
    height: '20px',
  },

  '& .titleText': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#363636',
    textTransform: 'capitalize',
  },

  '& .bottomContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 26px',
  },

  '& .descText': {
    fontFamily: 'Quicksand',
    fontWeight: 700,
    fontSize: '16px',
    color: '#363636',
    marginBottom: '36px',
    textAlign: 'center',
  },

  [theme.breakpoints.up('md')]: {
    '& .cancelButton': {
      margin: '60px 0 42px 60px',
    },

    '& .bottomContainer': {
      padding: '0 60px',
    },
  },
}))

export default ChallengeRoulette
