// Customizable Area Start
import React from "react";
import {
    Typography,
    Box,
    styled,
} from "@material-ui/core";

import {
    skip_wrench,
    loader
} from "./assets";
import { Props } from "./StripePayments";
import NavigateLayout from "../../../components/src/NavigateLayout";
import ProcessingPaymentController from "./ProcessingPaymentsController";
import { withTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'


class ProcessingPayments extends ProcessingPaymentController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        return (
            <NavigateLayout menuName="Settings" isShowBottomMenu={false}>
                <SettingContainer>
                    <Box className="mainPremiumWrSubWrapper" >
                        <Box className="settingCheckBackground">
                        <CenterPortion navigation={undefined} id={""} />
                        </Box>
                        <Box className="ProcessPayContainer" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <Box className="skipImgesProcWrapper" >
                                <Box>
                                    <img src={loader} alt="Loading" />
                                </Box>
                                <Box>
                                    <Typography style={webStyle.titleText}>
                                        {this.props.t("Processing your payment")}...
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography style={webStyle.checkboxProcessText}>
                                        {this.props.t("Stay with us for a sec while we ensure your payment is super secure!")}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box className="parentImage" style={{ flex: '1' }}>
                                <div className="lastImage">
                                    <img src={skip_wrench} className="teddyesImageproce" alt="Skip Wrench" style={{ width: '300px' }} />
                                </div>
                            </Box>
                        </Box>
                    </Box>
                </SettingContainer>
            </NavigateLayout>

        );
    }
}

const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    '& .parentImage': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        '& .lastImage': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', // Adjust the width as needed
        },
    },

    '& .mainPremiumWrSubWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .settingCheckBackground': {
        backgroundColor: '#fff',
    },

    '& .settingImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .skipImgesProcWrapper': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
    },


    '& .settingesTitleProc': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .ProcessPayContainer': {
        flex: '6',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
        overflow: 'auto',
        justifyContent: 'center',
        padding: '32px 24px 100px',
        flexDirection: 'column',

    },



    '& .teddyesImageproce': {
        bottom: '0px',
        Width: '245px',
        padding: '0',
        marginRight: '10px',
        marginLeft: '0',
        position: 'absolute',
    },



    [theme.breakpoints.up('md')]: {

        '& .teddyesImageproce': {
            maxWidth: 'unset',
        },

        '& .ProcessPayContainer': {
            paddingTop: '90px',
        },

        '& .mainPremiumWrSubWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },

        '& .skipImgesProcWrapper': {
            paddingTop: 'unset',
        },

        '& .settingesTitleProc': {
            fontSize: '30px',
            marginBottom: 'unset',
        },


        '& .settingCheckBackground': {
            flex: 5,
        },
        '& .settingImgesWrapper': {
            paddingTop: 'unset',
        },


    },

    [theme.breakpoints.up('lg')]: {


        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


    },
    [theme.breakpoints.down('sm')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
        '& .ProcessPayContainer': {
            flex: '6',
            alignItems: 'center',
            background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            padding: '254px 24px 100px',

        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },
    },
    [theme.breakpoints.down('xs')]: {
        '& .settingCheckBackground': {
            display: 'none',
        },
        '& .ProcessPayContainer': {
            flex: '6',
            display: 'flex',
            padding: '254px 24px 100px',
            flexDirection: 'column',
            alignItems: 'center',
            background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },
    },

}))

const webStyle = {
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },


    checkboxProcessText: {
        textAlign: "center" as "center",
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: '27px',
        letterSpacing: '0px',
        color: '#FFFFFF',
        marginTop: '25px'
    },
    titleText: {
        textAlign: "center" as "center",
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#FFFFFF',
        marginTop: '50px'
    },



};

export default withTranslation()(ProcessingPayments)
// Customizable Area End
