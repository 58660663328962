// Customizable Area Start
import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { sendAPIRequest } from "../../../components/src/utils"
import { BlockComponent } from "../../../framework/src/BlockComponent"
import { IBlock } from "../../../framework/src/IBlock"
import { Message } from "../../../framework/src/Message"
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../framework/src/RunEngine"
import { FormikTouched, FormikErrors } from 'formik'
import * as Yup from 'yup'
import Google from "../../../components/src/GoogleLogin"
import { checkIcon } from "./assets"
import { initializeApp,getApps } from 'firebase/app'
//@ts-ignore
import Cookies from "js-cookie"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
export const configJSON = require("./config")

export interface Props {
    id?: string
    t?:any
    navigation: {
        goBack(): void;
        navigate: (screenName: string) => void,
    }
}

export interface S {
    // Customizable Area Start
    showPassword: boolean
    check: boolean
    selectValue: string
    errorObj: {
        capital: boolean
        lower: boolean
        length: boolean
        specialChar: boolean
        digit: boolean
    }
    loginResponse: { email: string, googleId: string }
    checkFlag: boolean
    errorMessage:string
    email:string,
    password:string,
    firebaseToken:string,
    loader:boolean,
    // Customizable Area End
}

export interface SS {
    id: string;
}

export default class EmailLoginController extends BlockComponent<
    Props,
    S,
    SS> {
    accountLoginCallId: string = ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            showPassword: false,
            check: false,
            selectValue: '',
            errorObj: {
                capital: false,
                lower: false,
                length: false,
                specialChar: false,
                digit: false,
            },
            loginResponse: { email: '', googleId: '' },
            checkFlag: false,
            email:"",
            password:"",
            errorMessage:"",
            loader:false,
            firebaseToken:""
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    accountActivationCallId: string = ''
    validationApiCallId: string = ''
    changeAppLanguageId:string=''
    firebaseTokenApiCallId:string=''
    async componentDidMount() {
        super.componentDidMount()
        const urlSearchParams = new URLSearchParams(window.location.search);
        const token = urlSearchParams.get('token');
        if(Cookies.get("password")&&Cookies.get("email")){
            const password = Cookies.get("password")
            this.setState({
                checkFlag:Cookies.get("checked") as boolean
            })
            this.validatePasswordStrength(password)
        }
        if (token) {
            this.accountActivationCallId = sendAPIRequest(`${configJSON.accountActivationApi}${token}`, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', },
            });
        }
        this.requestFirebasePermission()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevState.loginResponse != this.state.loginResponse) {
            const data = { email: this.state.loginResponse.email, password: this.state.loginResponse.email, unique_auth_id: this.state.loginResponse.googleId }
            let body1: object = {
                data: {
                    type: "social_account",
                    attributes: data
                }
            }
            this.accountLoginCallId = sendAPIRequest(configJSON.accountLoginApi, {
                method: "POST",
                body: body1,
                headers: { 'Content-Type': 'application/json', },
            });

        }
    }

    async receive(from: string, message: Message) {
        console.log('this receive is rnnfslflsdz=');
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.accountLoginCallId) {
                this.accountLoginData(responseJson)
            }
            if (apiRequestCallId === this.validationApiCallId) {
                this.LoginCheckData(responseJson)
            }
            if (apiRequestCallId === this.firebaseTokenApiCallId) {
                this.firebaseCheckData(responseJson)
            }
        }
    }
    handleClickShowPassword = () => { this.setState({ showPassword: !this.state.showPassword }) };
    submitFormData = (values: { email: string, password: string }) => {
        const data = { email: values.email, password: values.password }
        if(this.state.checkFlag){
            Cookies.set("email",values.email)
            Cookies.set("password",values.password)
            Cookies.set("checked",this.state.checkFlag)
        }else{
            Cookies.set("email","")
            Cookies.set("password","")
            Cookies.set("checked",false)
        }

        this.setState({
            loader:true
        })
        let body: object = {
            data: {
                type: "email_account",
                attributes: data
            }
        }
        this.accountLoginCallId = sendAPIRequest(configJSON.accountLoginApi, {
            method: "POST",
            body: body,
            headers: { 'Content-Type': 'application/json' },
        });
    }
 firebaseCheckData = async (responseJson: any) => {
       console.log('Firebase Check Response:');
    }
    
    accountLoginData = (responseJson: any) => {
        if (!responseJson.errors) {
            localStorage.setItem("token", responseJson.meta.token)
             this.callGetValidationApi()
            this.firebaseClientToken(this.state.firebaseToken) 
            this.changeAppLanguage(responseJson.meta.token,responseJson.meta.id)
            localStorage.setItem("userDetail",JSON.stringify(
                {loginCount:responseJson?.meta?.login_count,
                userId:responseJson.meta.id,
                })
                )      
                this.setState({
                loader:false
                })         
        }
        else {
            const errorMessage = responseJson.errors[0].failed_login;
            this.setState({ errorMessage,loader:false});
        }
    }

    changeAppLanguage = (token:string, accountId:number) => {
        const JsonData = Cookies.get('webAppLanguage') || "{}"
        const LanguageCookie = JSON.parse(JsonData)
        const body = {
          data: {
            attributes: {
              app_language_id: LanguageCookie?.id
            }
          }
        }
        this.changeAppLanguageId = sendAPIRequest(`account_block/accounts/${accountId}`,
          {
            method: 'PUT',
            body: body,
            headers: {
              'Content-Type': 'application/json',
              'token': token
            },
          }
        )
      }



    LoginCheckData = async (responseJson: any) => {
        localStorage.setItem('displayName', responseJson?.data?.attributes?.display_name)
        const dailyGoal = responseJson?.data?.attributes;
        if (dailyGoal?.daily_goal === null || dailyGoal?.streak_goal === null) {
            this.props.navigation.navigate('FirstLogin');
        }
        else {
            this.props.navigation.navigate('LandingPageWeb');
        }
    }

    handleChecked = () => {
        this.setState({ check: !this.state.check });
        const checked = document.getElementById('check-id')
        if (checked) {
            checked.click()
        }
    }
    validatePasswordStrength = (value: string) => {
        const errorValues = {
            capital: configJSON.capital,
            lower: configJSON.lower,
            length: configJSON.length,
            specialChar: configJSON.specialChar,
            digit: configJSON.digit,
        };
        this.setState({
            errorObj: {
                capital: errorValues.capital.test(value),
                lower: errorValues.lower.test(value),
                length: errorValues.length.test(value),
                specialChar: errorValues.specialChar.test(value),
                digit: errorValues.digit.test(value),
            }
        })
    }
    selectHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ selectValue: event.target.value as string });
    }
    loginSchema = () => {
        return Yup.object().shape({
            email: Yup.string().email(this.props.t("Uh-oh! Something's not right with your email.")).required(this.props.t("Uh-oh! Something's not right with your email.")),
            password: Yup.string()
                .required(this.props.t('Oops! It appears the password is incorrect.')),
            // checked: Yup.boolean()
        })
    };
    showError = (touched: FormikTouched<{ email: string; password: string; checked: boolean }>, errors: FormikErrors<{ email: string; password: string; checked: boolean }>, value: string) => {
        return errors[value as keyof typeof errors] && touched[value as keyof typeof touched] && <div className="required-error"><Typography className="text-danger">{errors[value as keyof typeof errors]}</Typography></div>
    }
    checkError = (name: boolean | undefined, error: string | undefined) => {
        return name && Boolean(error)
    }
    googleButton = () => {
        return <Google 
        token={(response) => {
             this.setState({ loginResponse: { 
                email: response.profileObj.email, googleId: response.googleId } }) 
            }} 
            handleGoogleLogin={function (res?: string | undefined): void {
            throw new Error('Function not implemented.');
        }} />
    }
    requestFirebasePermission = () => {
      const firebaseConfig = {
      apiKey: "AIzaSyAaAFVPd2BOLgYAKNL0t5zWei2BtIyUtaI",
      authDomain: "tedix-400192.firebaseapp.com",
      projectId: "tedix-400192",
      storageBucket: "tedix-400192.appspot.com",
      messagingSenderId: "494702856114",
      appId: "1:494702856114:web:ad89db2b2b3bc90d80c6ab",
      measurementId: "G-EYH7F0W5KN"
    };  
    const existingApps = getApps();
    const firebaseApp = existingApps.length ? existingApps[0] : initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, { vapidKey: configJSON.firebasevapidKey })
          .then((firebaseToken:string) => {
            if (firebaseToken) {
              console.log('Client Token: ', firebaseToken);
              this.setState({firebaseToken})
              onMessage(messaging, (payload: any) => {
              });
            } 
          })
          .catch((err:any) => {
            console.error('Error', err);
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }
    handleCheckClick = (values: any, setValue: (key: string, value: boolean) => void) => {
        const check = document.getElementById('checkbox')
        if (check) {
            this.setState({ checkFlag: !this.state.checkFlag })
            setValue('checked', !this.state.checkFlag)
        }
    }
    passwordError = () => {
        const {t} = this.props
        return <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid container spacing={0} style={{ alignItems: 'center' }} className="errorComponent">
                <Grid className="error" style={{ backgroundColor: this.state.errorObj.lower ? '#E3F9F0' : '#F2F2F2' }}>
                    <div className={this.state.errorObj.lower ? 'no-error' : 'with-error'}>{this.state.errorObj.lower && <img src={checkIcon} alt="checkIcon" className="check-icon" />}{t("One lowercase letter")}</div>
                </Grid>
                <Grid className="error" style={{ backgroundColor: this.state.errorObj.digit ? '#E3F9F0' : '#F2F2F2' }}>
                    <div className={this.state.errorObj.digit ? 'no-error' : 'with-error'}>{this.state.errorObj.digit && <img src={checkIcon} alt="checkIcon" className="check-icon" />}{t("One number")}</div>
                </Grid>
                <Grid className="error" style={{ backgroundColor: this.state.errorObj.length ? '#E3F9F0' : '#F2F2F2' }}>
                    <div className={this.state.errorObj.length ? 'no-error' : 'with-error'}>{this.state.errorObj.length && <img src={checkIcon} alt="checkIcon" className="check-icon" />}{t("8 characters minimum")}</div>
                </Grid>
            </Grid>
        </div>
    }
    callGetValidationApi() {
        const token = localStorage.getItem('token')
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validationApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.accountCheckApi
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    firebaseClientToken = (firebaseToken: string) => {
    const token = localStorage.getItem("token"); 
    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": token, 
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.firebaseTokenApiCallId = requestMessage.messageId;

    const apiUrl = `${configJSON.firebaseCheckApi}?device_id=${firebaseToken}`

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       apiUrl
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};
    // Customizable Area End
}
// Customizable Area End