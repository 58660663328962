export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgQuestion = require("../assets/ic_question.png");
export const imgGlobal = require("../assets/ic_global.png");
export const imgNotepad = require("../assets/ic_notepad.png");
export const imgSupport = require("../assets/ic_24support.png");
export const imgAward = require("../assets/ic_award.png");
export const imgTeddySetting = require("../assets/ic_teddy_setting.svg");
export const imgEditAccount = require("../assets/ic_editAccount.png");
export const imgPassword = require("../assets/ic_password.png");
export const imgLogout = require("../assets/ic_logout.png");
export const imgEditAccountAvatar = require("../assets/editAccountAvatar.svg");
export const imgEditAccountHeader = require("../assets/edit_account.svg");
export const imgChangePassword = require("../assets/change_password.svg");
export const imgEmailConfirm = require("../assets/mail_confirm.svg");
export const imgDeleteConfirm = require("../assets/delete_confirm.svg");
export const imgChangePasswordSuccessBg = require("../assets/change_password_success_bg.svg");
export const imgChangePasswordSuccessTeddy = require("../assets/change_password_success_teddy.svg");
export const imgTeddySettingMobile = require("../assets/ic_teddy_setting_mobile.svg");
export const loader = require("../assets/loader.svg")
export const teddyBlue =  require('../assets/bluebear.svg')
export const teddyBrown =  require('../assets/brownbear.svg')
export const teddyGreen =  require('../assets/greenbear.svg')
export const teddyLightgreen =  require('../assets/mintbear.svg')
export const teddyOrange =  require('../assets/orangebear.svg')
export const teddyPurple =  require('../assets/purplebear.svg')
export const teddyRed =  require('../assets/redbear.svg')
export const teddyYellow =  require("../assets/yellow bear.svg")
export const orangebear = require('../assets/orangebearjoy.svg')
export const bluebear = require('../assets/bluebearjoy.svg')
export const mintbear = require('../assets/mintbearjoy.svg')
export const purplebear = require('../assets/purplebearjoy.svg')
export const redbear = require('../assets/redbearjoy.svg')
export const brownbear = require('../assets/brownbearjoy.svg')
export const yellowbear = require('../assets/yellowbearjoy.svg')
export const greenbear = require('../assets/greenbearjoy.svg')
export const loaderBlue = require('../assets/loaderBlue.svg');
