Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.paymentMethodEndpoint = "stripe_payments/payment_methods";
exports.paymentEndpoint = "stripe_payments/payments";
exports.confirmPaymentEndpoint = "stripe_payments/payments/confirm";
exports.getPaymentMethodsMethod = "GET";
exports.createPaymentMethodMethod = "POST";
exports.createPaymentMethod = "POST";
exports.confirmPaymentMethod = "POST";

exports.orderId = "Order ID";
exports.loading = "Loading...";
exports.noPaymentMethods = "No payment methods found.";
exports.loginToSeePaymentMethods = "Please login to see payment methods.";
exports.addPaymentMethod = "Add new payment method";
exports.completePayment = "Complete Payment";
exports.cardNumberLabel = "Card Number";
exports.cardNumberPlaceholder = "Enter your credit card number";
exports.expiryDateLabel = "Expiry Date";
exports.expiryDatePlaceholder = "MM / YYYY";
exports.cvcLabel = "CVC";
exports.cvcPlaceholder = "XXX";
exports.cancelText = "Cancel";
exports.submitText = "Submit";

exports.stripePayment = "Stripe Payment";
exports.successMessage = "Your payment was successful!";
exports.ok = "OK";
exports.stripePayments = "Stripe Integration";
exports.getSubscriptionMethod = 'GET'
exports.getSubscriptionEndpoint = "bx_block_stripe_integration/subscriptions"
exports.getCardInformationMethod = 'GET'
exports.getCardInformationEndpoint = "bx_block_stripe_integration/payment_methods"
exports.getSpecificAccountMethod = 'GET'
exports.getSpecificAccountEndpoint='account_block/accounts/specific_account'
exports.updateSubscriptionMethod='PUT'
exports.updateSubscriptionEndpoint='bx_block_stripe_integration/subscriptions'

exports.getListPlanEndPoint = "bx_block_stripe_integration/plans";
exports.getListPlanMethod = "GET";

exports.createSubscriptionEndPoint = "bx_block_stripe_integration/subscriptions";
exports.createSubscriptionMethod = "POST";

exports.stripeSecretKey = "pk_test_51NmhSIEHcDhXTyQaBywa47u5PhZGodznJiuFLKLKvl9hSNgLWaNNqI58r4CgIAcUwZ8OVloi0Ux6BWumK8e2LHFi00rUBxYUkB";
exports.createStripeTokenContentType = "application/x-www-form-urlencoded";
exports.createStripeTokenEndPoint = "https://api.stripe.com/v1/tokens";
exports.createStripeTokenMethod = "POST";

exports.getListCardEndPoint = "bx_block_stripe_integration/payment_cards";
exports.getListCardMethod = "GET";

exports.deleteCardEndPoint = "bx_block_stripe_integration/payment_cards";
exports.deleteCardMethod = "DELETE"
exports.getListCardEndpoit='bx_block_stripe_integration/payment_cards'
exports.createStrpieTokenMethod='POST'
exports.addNewCardMethod='POST'
exports.actionCardEndpoint='bx_block_stripe_integration/payment_cards'
exports.updateCardMethod='PUT'
exports.deleteCardMethod='DELETE'
exports.getPlanMethod='GET'
exports.getPlanEndpoint='bx_block_stripe_integration/plans'
// Customizable Area End
