import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";
import {
    skip_close1,
    bigstats,
} from "./assets";
import SubscriptionExperingController, { Props } from "./SubscriptionExperingController";
import { withTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End

// Customizable Area Start

class SubscriptionExpering extends SubscriptionExperingController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            // Customizable Area Start

            <SettingContainer>
                <SideBar />
                <Box className="mainContentExpWrapper">
                    <Box className="settingExpBackground">
                    <CenterPortion navigation={undefined} id={""} />
                    </Box>
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flex: '6'
                    }}>

                        <Box className="ContainerExpering">
                            <Box style={{ position: 'relative' }}>
                                <img src={skip_close1} alt="Back Button" style={{ position: 'fixed', top: '12px' }} data-test-id="skip-close-btn" onClick={this.handleClickSkipClose} />
                            </Box>
                            <Box className="ContainerExperingContent">
                                <Box style={webStyle.mainImage} >
                                    <img src={bigstats} alt="teddy" />
                                </Box>
                                <Box>
                                    <Typography style={webStyle.TextTitle}>
                                        {this.props.t("Subscription expiring soon")}
                                    </Typography>
                                </Box>
                                <Box style={{ marginTop: '15px' }}>
                                    <Typography style={webStyle.TextFirstTitle}>
                                        Your subscription is set to expire in just <strong style={{ fontWeight: 'bold' }}>{this.state.subscriptionDaysLeft} days</strong>.
                                    </Typography>
                                    <Typography style={webStyle.TextSecondTitle} >
                                        {this.props.t("By renewing now, you ensure uninterrupted access to premium content and a seamless learning experience.")}
                                    </Typography>
                                </Box>
                                <Box className="section">
                                    <Button data-testid="btnRenew" style={webStyle.btnText} onClick={() => this.props.navigation.navigate('PremiumCheckout')}>
                                        {this.props.t("Renew today!")}
                                    </Button>
                                </Box>
                                <Box data-test-id="later-btn" onClick={this.handleClickLater}>
                                    <Typography style={webStyle.lastTitle}>
                                        {this.props.t("Later")}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </SettingContainer>

            // Customizable Area End
        );
    }
}
// Customizable Area End

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .ContainerExperingContent": {
        width: '350px',
        maxWidth: '100%'
    },

    '& .mainContentExpWrapper': {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    '& .section': {
        height: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '140px',
        borderRadius: '12px',
        backgroundColor: '#36BFFF',
        display: 'flex',

    },
    '& .settingExpBackground': {
        backgroundColor: 'background: linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
    },

    '& .settingExpImgWrapper': {
        height: '100%',
        display: 'flex',
        paddingBottom: '30px',
        paddingTop: '45px',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        justifyContent: 'center',
        position: 'relative',
    },

    '& .settingTitleExp': {
        position: 'relative',
        fontSize: '22px',
        color: '#fff',
        fontWeight: '900',
        fontFamily: 'Nunito',
        textAlign: 'center',
        marginBottom: '12px',
        paddingTop: '22px',
        width: '100%',

    },
    '& .arrowImageExp': {
        position: 'absolute',
        left: '40px',
        top: '55%'
    },

    '& .teddysImagePremium': {
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        width: 'auto'
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },
    '& .ContainerExpering': {
        flex: '6',
        background: '#FEF3C7',
        display: 'flex',
        alignItems: 'center',
        overflowY: 'auto',
        justifyContent: 'center',
        padding: '32px 24px 100px',
    },

    [theme.breakpoints.up('md')]: {
        "& .ContainerExperingContent": {
            width: '280px'
        },


        '& .settingExpBackground': {
            flex: 5,
        },

        '& .settingTitleExp': {
            marginBottom: 'unset',
            fontSize: '30px',
            position: 'relative'

        },

        '& .settingExpImgWrapper': {
            paddingTop: 'unset',
        },
        '& .mainContentExpWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
        },

        '& .ContainerExpering': {
            paddingTop: '90px',
            paddingLeft: '50px',
            paddingRight: '50px'
        },

        '& .teddysImagePremium': {
            maxWidth: '240px'
        },

    },

    [theme.breakpoints.up('lg')]: {
        '& .mainContentExpWrapper': {
            marginLeft: '282px',
            width: `calc(100% - 282px)`,

        },

        '& .teddysImagePremium': {
            maxWidth: 'unset'
        },

    },

    [theme.breakpoints.down('sm')]: {

        '& .teddysImagePremium': {
            maxWidth: '287px',
            bottom: '0px',
            padding: '0',
            position: 'absolute',
            marginRight: '10px',
            left: '199px',
            marginLeft: '0',
        },

        '& .arrowImageExp': {
            left: '18%',
            position: 'absolute',
            top: '71px',
        },

        '& .ContainerExpering': {
            flex: '6',
            background: '#FEF3C7',
            display: 'flex',
            alignItems: 'center',
            overflowY: 'auto',
            padding: '32px 50px',
            height: '100vh',
        },
        '& .settingExpBackground': {
            display: 'none',
        },

    },


    [theme.breakpoints.down('xs')]: {

        '& .teddysImagePremium': {
            marginLeft: '0',
            position: 'absolute',
            bottom: '0px',
            padding: '0',
            maxWidth: '245px',
            left: '37px',
            marginRight: '10px',

        },
        '& .settingExpBackground': {
            display: 'none',
        },

        '& .arrowImageExp': {
            left: '10%',
            position: 'absolute',
            top: '74px',

        },


        '& .ContainerExpering': {
            flex: '6',
            overflowY: 'auto',
            padding: '32px 24px',
            height: '100vh',
            background: '#FEF3C7',
            display: 'flex',
            alignItems: 'center',

        },

    },


}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",

    },
    mainImage: {
        display: "flex",
        justifyContent: "center" as "center",
        alignItems: "center" as "center",
        marginBottom: '10px',
        paddingRight: '60px',
    },
    TextTitle: {
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: '15px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        color: '#363636',
        marginTop: '30px',
    },
    TextFirstTitle: {
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: '25px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#363636',
        padding: '0px 59px 0px 55px',
    },
    TextSecondTitle: {
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: '25px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#363636',
        marginTop: '15px'
    },
    lastTitle: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '13px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#00AEFF',
        marginTop: '15px'
    },
    btnText: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        textTransform: 'capitalize' as 'capitalize',
    },


};

export default withTranslation()(SubscriptionExpering)
// Customizable Area End
