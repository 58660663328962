import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

interface ILessonMaterial{
  id:string;
  image:string;
  label:string;
  shape:string;
  type:string;
  summaypdf:string;
  summaryVideo:string;
  cheetsheetpdf:string;
}
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
  }
  
  interface S {
    // Customizable Area Start
    selectValue: string;
    firstLogin: boolean;
    topicName: string;
    unitName: string;
    errorMessage: string;
    trophiesOpen: boolean;
    goalsOpen: boolean;
    mathOpen: boolean;
    drawerSide: boolean;
    openDrawer: boolean;
    isScrolled: boolean;
    LessonData:any; 
    isLoading: boolean
    // Customizable Area End
  }
  
  interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
  }

  export default class LessonMaterialController extends BlockComponent<
  Props,
  S,
  SS
> {

   // Customizable Area Start
   getLessonsList:string = ''
   // Customizable Area End

   constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      unitName: "Let's explore the world of knowledge",
      errorMessage: "",
      LessonData:[],
      mathOpen: false,
      trophiesOpen: false,
      goalsOpen: false,
      selectValue: '',
      isScrolled: false,
      firstLogin: false,
      topicName: "Welcome to Tedix",
      drawerSide: false,
      openDrawer: false,
      isLoading:true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    let updatedNameUnit = localStorage.getItem('updatedName');
    if (updatedNameUnit !== null) {
    let updatedName = JSON.parse(updatedNameUnit);
    this.setState({ unitName: updatedName.unitName, topicName: updatedName.topicName });
    }
    window.addEventListener('resize', this.handleWindowSizeChange);
    window.addEventListener('scroll', this.handleScroll);  
    this.handleWindowSizeChange();
    const unitId = this.props.navigation.getParam('unitId');
    this.getLessonsData(unitId)
    
   }

  async componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getLessonsList) {
    
        const result = responseJson?.data?.map((lesson: any) => {
          return {
            id: JSON.stringify(lesson),
            image: lesson.attributes.image,
            label: lesson.attributes.name,
            shape: "image", 
            type: lesson.type,
            size: 15,
            progress: lesson.attributes.current_progress
          }
        })
        result.sort((a:any, b:any) => {
          const idA = JSON.parse(a.id).id;
          const idB = JSON.parse(b.id).id;
          return idA - idB;
        });
        this.setState({LessonData: result, isLoading: false })
      }
  }
}

getLessonsData = (lessonId: any) => {
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLessonsList = getValidationsMsg.messageId;
  
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.lessonAPI}?parent_id=${lessonId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  
  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, mathOpen: false });
  };

 goBackScreen=()=>{
  this.props.navigation.navigate('LessonPage')
}
  
  handleWindowSizeChange = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 960) {
      this.setState({ 
          drawerSide: false 
      });
    } else {
      this.setState({
           drawerSide: true 
          });
    }
  };

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    }
  }
 
// Customizable Area End
}   

// Customizable Area Start
// Customizable Area End