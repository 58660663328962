// Customizable Area Start
const mouths = {
  mouth1: true,
  mouth2: true,
  mouth3: true,
  mouth4: true,
  mouth5: true,
  mouth6: true,
  mouth7: true,
};

export default mouths;
// Customizable Area End
