import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";

import {
    pending_teddy,
    group_pending
} from "./assets";
import { useTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End
import { ViewProps } from "./StripePayments";

// Customizable Area Start


// Customizable Area End
const PaymentPending: React.FC<ViewProps> = () => {
    const { t } = useTranslation();


    return (
        // Customizable Area Start

        <SettingContainer>
            <SideBar />
            <Box className="mainPaymentsPenWrapper" >
                <Box className="PaymentPendBackground">
                <CenterPortion navigation={undefined} id={""} />
                </Box>
                <Box className="ProcessContainerPen" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box className="skipImgesWrapperPen" >
                        <Box style={webStyle.teddyImageparent} >
                            <img src={pending_teddy} alt="Loading" />
                        </Box>
                        <Box>
                            <Typography style={webStyle.titleText}>
                                {t("Payment on the way")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={webStyle.checkboxText}>
                                {t("Your payment is in the works and will be confirmed soon.")}
                                {t("We appreciate your patience and trust!")}
                            </Typography>
                        </Box>
                        <Box
                            style={{
                                width: '260px',
                                height: '41.9px',
                                borderRadius: '12px',
                                background: '#fff',
                                borderColor: '#999999',
                                marginTop: '94px',
                                border: '2px solid #999999',
                                borderBottom: '4px solid #999999',
                            }}
                        >
                            <Button
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '12px',
                                    letterSpacing: '0.800000011920929px',
                                    textAlign: 'center',
                                    color: '#7F7F7F',
                                    width: '100%',
                                    marginTop: '5px'
                                }}
                            >
                                {t("View details")}
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </SettingContainer>

        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',




    '& .mainPaymentsPenWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .PaymentPendBackground': {
        backgroundColor: '#fff',
    },



    '& .paymentPenImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        justifyContent: 'center',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .skipImgesWrapperPen': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomRightRadius: '20px',
        position: 'relative',
    },


    '& .settingesPenTitle': {
        fontSize: '22px',
        fontWeight: '900',
        paddingTop: '22px',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
    },

    '& .ProcessContainerPen': {
        flex: '6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: `url(${group_pending})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        justifyContent: 'center',
        padding: '254px 24px 100px',
        backgroundColor: '#FDCE36',
        overflow: 'auto',
    },

    '& .PenteddyesImage': {
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',

    },




    [theme.breakpoints.up('md')]: {


        '& .paymentPenImgesWrapper': {
            paddingTop: 'unset',
        },


        '& .PenteddyesImage': {
            maxWidth: 'unset',
        },

        '& .skipImgesWrapperPen': {
            paddingTop: 'unset',
        },

        '& .settingesPenTitle': {
            marginBottom: 'unset',
            fontSize: '30px',
        },

        '& .ProcessContainerPen': {
            paddingTop: '90px',
        },


        '& .PaymentPendBackground': {
            flex: 5,
        },


        '& .mainPaymentsPenWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },

    },

    [theme.breakpoints.up('lg')]: {


        '& .mainPaymentsPenWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


    },
    [theme.breakpoints.down('sm')]: {
        '& .PaymentPendBackground': {
            display: 'none',
        },
        '& .ProcessContainerPen': {
            flex: '6',
            alignItems: 'center',
            backgroundImage: `url(${group_pending})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            display: 'flex',
            padding: '254px 24px 100px',
            flexDirection: 'column',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipImgesWrapperPen': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderBottomRightRadius: '20px',
            paddingLeft: '33px',
            paddingBottom: '30px',
            position: 'relative',
        },

    },
    [theme.breakpoints.down('xs')]: {
        '& .PaymentPendBackground': {
            display: 'none',
        },
        '& .ProcessContainerPen': {
            flex: '6',
            alignItems: 'center',
            backgroundImage: `url(${group_pending})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            padding: '254px 24px 100px',
            flexDirection: 'column',
            backgroundPosition: 'center',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipImgesWrapperPen': {
            height: '100%',

            paddingLeft: '33px',
            paddingBottom: '30px',
            position: 'relative',
        },
    },

}))

const webStyle = {
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },


    checkboxText: {
        textAlign: "center" as "center",
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 400,
        lineHeight: '27px',
        letterSpacing: '0px',
        color: '#363636',
        marginTop: '10px'
    },
    titleText: {
        textAlign: "center" as "center",
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#363636',
        marginTop: '20px'
    },
    teddyImageparent: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '232px'
    }



};
// Customizable Area End

export default PaymentPending;
