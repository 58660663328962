import React from "react";
// Customizable Area Start
import {
  healthyTeddy1,
  healthyTeddy2,
  healthyTeddy3,
  healthyTeddy4,
  healthyTeddy5,
  healthyTeddy6,
  healthyTeddy7,
  healthyTeddy8,
  textlabel1,
  textlabel2,
  textlabel3,
  textlabel4,
  textlabel5,
  textlabel6,
  textlabel7,
  textlabel8,
} from "./assets";
import { Typography } from "@material-ui/core";
import FirstLoginSelectAvatarController from "./FirstLoginSelectAvatarController.web";
import { withTranslation } from "react-i18next";
import SelectLanguage from "../../../components/src/SelectLanguage";
import Header from "../../../components/src/Header";
// Customizable Area End
 class FirstLoginSelectAvatar extends FirstLoginSelectAvatarController {
  // Customizable Area Start
  renderHeadingSection = () => {
    return (
      <div className="firstLogintitleContainer">
        <h1 className="firstLogintitle">{this.props.t("Personalize Your Look")}</h1>
        <p className="firstLoginSubtitle">{this.props.t("Select your avatar")}</p>
      </div>
    );
  };
  renderAvatarSelectionContainer = () => {
    return (
      <div>
        <div className="avatarSelection">
          <div className={this.state.centerImage===healthyTeddy5?" circleActive circle":"circle"} onClick={this.handleButtonClick1} data-testid={"selectAvatarButton1"}><img src={textlabel1} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy4?" circleActive circle":"circle"} onClick={this.handleButtonClick2} data-testid={"selectAvatarButton2"}><img src={textlabel2} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy3?" circleActive circle":"circle"} onClick={this.handleButtonClick3} data-testid={"selectAvatarButton3"}><img src={textlabel3} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy6?" circleActive circle":"circle"} onClick={this.handleButtonClick4} data-testid={"selectAvatarButton4"}><img src={textlabel4} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy7?" circleActive circle":"circle"} onClick={this.handleButtonClick5} data-testid={"selectAvatarButton5"}><img src={textlabel5} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy8?" circleActive circle":"circle"} onClick={this.handleButtonClick6} data-testid={"selectAvatarButton6"}><img src={textlabel6} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy1?" circleActive circle":"circle"} onClick={this.handleButtonClick7} data-testid={"selectAvatarButton7"}><img src={textlabel8} alt="text_label"   className="selectAvatarLable"/></div>
          <div className={this.state.centerImage===healthyTeddy2?" circleActive circle":"circle"} onClick={this.handleButtonClick8} data-testid={"selectAvatarButton8"}><img src={textlabel7} alt="text_label"  /></div>
          <img src={this?.state?.centerImage} alt="text_label" className="circle mainImage"  />

        </div>
      </div>
    );
  };
  renderButton = () => {
    return <div className="firstLoginDropDownBtnContainer" ><button className="firstLoginDropDownBtn" onClick={this.finishSelectAvatar} data-testid={"selectAvataFinishBtn"}>{this.props.t("Finish")}</button></div>;
  };
  renderFirstLoginBottom = () => {
    return (
      <div className="firstLoginBottom">
        <Typography style={{ textAlign: "center", margin: "35px 0 0 0" }}>
          <SelectLanguage />
        </Typography>
      </div>
    );
  };
  renderSelectAvatarMain = () => {
    return (
      <>
        <Header showGoBackBtn onGoBack={this.handleBackFirstlogin} />
        {this.renderHeadingSection()}
        {this.renderAvatarSelectionContainer()}
        {this.renderButton()}
        {this.renderFirstLoginBottom()}
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return <div className="selectAvatar">{this.renderSelectAvatarMain()}</div>;
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(FirstLoginSelectAvatar);
export  {FirstLoginSelectAvatar};
// Customizable Area End

