import { Box, Typography, styled } from '@material-ui/core'
import React, { useState } from 'react'
import { StyledButton } from '../../../blocks/educationalgamification/src/MicroLessonExercise'
import {
  bolt,
  challengeFinishBg,
  challengeShareTeddy,
  goHome,
  share,
  teddyBlueFinishChallenge,
  teddyBrownFinishChallenge,
  teddyGreenFinishChallenge,
  teddyLightGreenFinishChallenge,
  teddyOrangeFinishChallenge,
  teddyPurpleFinishChallenge,
  teddyRedFinishChallenge,
  teddyYellowFinishChallenge,
  time,
} from '../../../blocks/assessmenttest/src/assets'
import { useTranslation } from 'react-i18next'
import { tedixLogo } from '../../../blocks/cftableforlessons/src/assets'
import ShareModal from './ShareModal.web'

interface ChallengeFinishedProps {
  onClickContinue: ({
    isClickFromChallengeFinish,
  }: {
    isClickFromChallengeFinish?: boolean
  }) => void
  onClickGoHome: () => void
  earnedXP: number
  totalTime: number
  challengeName: string
  assessmentId: number
  lessonId: string
  onUpdateXP: (token: string) => void
  teddyColor: string
}

const ChallengeFinished: React.FC<ChallengeFinishedProps> = ({
  onClickContinue,
  onClickGoHome,
  earnedXP,
  totalTime,
  challengeName,
  assessmentId,
  lessonId,
  onUpdateXP,
  teddyColor
}) => {
  const { t } = useTranslation()
  const [openShareModal, setOpenShareModal] = useState(false)

  const isMinute = totalTime > 59
  const minute = Math.floor(totalTime / 60)

  const colorToUpdateTeddy: {[key: string]: string} = {
    '#ff8400': teddyOrangeFinishChallenge,
    '#62cdff': teddyBlueFinishChallenge,
    '#b7f0ad': teddyLightGreenFinishChallenge,
    '#a95edb': teddyPurpleFinishChallenge,
    '#f96855': teddyRedFinishChallenge,
    '#884a39': teddyBrownFinishChallenge,
    '#fdce36': teddyYellowFinishChallenge,
    '#2bce8c': teddyGreenFinishChallenge,
  }

  return (
    <ChallengeFinishedContainer>
      <img src={colorToUpdateTeddy[teddyColor] || colorToUpdateTeddy['#62cdff']} className="teddyBear" />
      <Box className="mainContentContainer">
        <Box className="summaryContentContainer">
          <SummaryCard title="Total XP" value={`${earnedXP} XP`} isExp />
          <SummaryCard title={isMinute ? 'Minutes' : 'Seconds'} value={isMinute ? minute.toString() : totalTime.toString()} />
        </Box>
        <Box className="buttonContainer">
          <Box className="moreActionContainer">
            <Box className="moreActionButton" onClick={onClickGoHome}>
              <img src={goHome} width={16} height={16} />
              <Typography className="moreActionText">{t('Go Home')}</Typography>
            </Box>
            <Box
              className="moreActionButton"
              onClick={() => setOpenShareModal(true)}
            >
              <img src={share} width={16} height={16} />
              <Typography className="moreActionText">{t('Share')}</Typography>
            </Box>
          </Box>
          <StyledButton
            onClick={() =>
              onClickContinue({ isClickFromChallengeFinish: true })
            }
          >
            {t('Next Challenge')}
          </StyledButton>
        </Box>
      </Box>

      <ShareModal 
        id='share-modal'
        open={openShareModal}
        handleClickClose={() => setOpenShareModal(false)}
        lessonId={lessonId}
        assessmentId={assessmentId}
        shareRecordType='CHALLENGE'
        onAfterShare={() => setOpenShareModal(false)}
        onUpdateXp={onUpdateXP}
        questionBankId={0}
        microLessonId={0}
      >
        <Box
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundImage: `URL(${challengeShareTeddy})`,
          }}    
        >
          <Box
            style={{
              backgroundColor: '#fff',
              display: 'flex',
              padding: '0',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              width: '340px',
              overflow: 'hidden',
              borderRadius: '20px',
            }}  
          >
            <Box
              style={{
                backgroundColor: '#fff',
                paddingTop: '26px',
                paddingBottom: '34px',
                borderRadius: '20px 20px 0 0',
              }}  
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                  justifyContent: 'center',
                }}
              >
                <SummaryCard
                  title="Total XP"
                  value={`${earnedXP} XP`}
                  isExp
                  isShareModal
                />
                <SummaryCard 
                  title={isMinute ? 'Minutes' : 'Seconds'} 
                  value={isMinute ? minute.toString() : totalTime.toString()} 
                  isShareModal 
                />
              </Box>

              <Box
                style={{
                  textAlign: 'center',
                  marginTop: '24px',
                }}      
              >
                <Typography
                  style={{
                    margin: '0',
                    fontSize: '15px',
                    fontWeight: 500,
                    color: '#666666',
                    fontFamily: 'Quicksand',
                    letterSpacing: 'initial',
                  }}  
                >
                  {t("I've wrapped up the challenge on")}
                </Typography>

                <Typography
                  style={{
                    margin: '0',
                    fontSize: '15px',
                    fontWeight: 700,
                    color: '#666666',
                    fontFamily: 'Quicksand',
                    letterSpacing: 'initial',
                  }}
                >
                  {challengeName}
                </Typography>
              </Box>
              <Box
                style={{
                  textAlign: 'center',
                  marginTop: '24px',
                }}      
              >
                <img src={tedixLogo} alt="Tedix" />
              </Box>
            </Box>
          </Box>
        </Box>
      </ShareModal>
    </ChallengeFinishedContainer>
  )
}

const SummaryCard = ({
  title,
  value,
  isExp,
  isShareModal,
}: {
  title: string
  value: string
  isExp?: boolean
  isShareModal?: boolean
}) => {
  return (
    <SummaryCardContainer>
      <Typography
        className="summaryTitle"
        style={{
          fontSize: isShareModal ? '14px' : '16px',
        }}
      >
        {title}
      </Typography>
      <Box className="summaryExpWrapper">
        <img
          src={isExp ? bolt : time}
          width={isShareModal ? 16 : 18}
          height={isShareModal ? 21 : 23}
        />
        <Typography
          className="summaryExpText"
          style={{
            fontSize: isShareModal ? '18px' : '20px',
          }}
        >
          {value}
        </Typography>
      </Box>
    </SummaryCardContainer>
  )
}

const ChallengeFinishedContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundSize: '100% 100%',
  backgroundImage: `url(${challengeFinishBg})`,
  position: 'relative',

  '& .teddyBear': {
    width: '174px',
    height: '172px',
    position: 'absolute',
    bottom: '40%',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: 10,
  },

  '& .mainContentContainer': {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 11,
  },

  '& .summaryContentContainer': {
    marginBottom: '48px',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },

  '& .buttonContainer': {
    padding: '32px 26px 40px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: '100%',
    borderRadius: '20px 20px 0 0',
  },

  '& .moreActionContainer': {
    display: 'flex',
    marginBottom: '16px',
    gap: '20px',
  },

  '& .moreActionButton': {
    flex: 1,
    borderRadius: '12px',
    border: '2px solid #e6e6e6',
    borderBottomWidth: '4px',
    paddingTop: '12px',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    justifyContent: 'center',
    cursor: 'pointer',
  },

  '& .moreActionText': {
    color: '#62CDFF',
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 700,
  },

  [theme.breakpoints.up('md')]: {
    '& .buttonContainer': {
      padding: '32px 60px 40px',
    },
  },
}))

const SummaryCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#E6E6E6',
  borderRadius: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  '& .summaryTitle': {
    fontFamily: 'Nunito',
    fontWeight: 900,
    color: '#7F7F7F',
    marginTop: '6px',
    marginBottom: '2px',
  },

  '& .summaryExpWrapper': {
    backgroundColor: '#fff',
    borderRadius: '12px',
    border: '2px solid #E6E6E6',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 20px',
  },

  '& .summaryExpText': {
    fontFamily: 'Nunito',
    color: '#363636',
    fontWeight: 900,
  },
}))

export default ChallengeFinished
