import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import {LessonDataArray, MainLessonDetailObject} from "./MedianController.web"
import { Message } from "framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    mockWeekYearData: Array<LessonDataArray | undefined>;
    t?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    toogleShowMultipleLesson: string,
    loading: boolean
    reportLesson:Array<MainLessonDetailObject>
    startDate:string;
    endDate:string
    lessonDetailOpen:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class MedianGradeWeeklyDataController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    lesssonDetailApi = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            toogleShowMultipleLesson: "",
            loading: false,
            reportLesson:[],
            lessonDetailOpen:'',
            startDate:'',
            endDate:'',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start
    getLessonDetailsOfMedianGrade = (startDate:string,endDate:string) => {
        this.setState({
         loading:true,
         startDate,
         endDate
        })

        
       
       const token = localStorage.getItem('token')
       const start_date = startDate
       const end_date = endDate
       const headers = {
         "Content-Type": configJSON.validationApiContentType,
         token
       };
       const getValidationsMsg = new Message(
         getName(MessageEnum.RestAPIRequestMessage)
       );
       this.lesssonDetailApi = getValidationsMsg.messageId;
   
       getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestHeaderMessage),
         JSON.stringify(headers)
       );
       getValidationsMsg.addData(
         getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.LessonDetailEndPoint}?start_date=${start_date}&end_date=${end_date}` 
         
       );
       getValidationsMsg.addData(
         getName(MessageEnum.RestAPIRequestMethodMessage),
         configJSON.validationApiMethodType
       );
       runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
     }

     getLessonDetailResponse=(apiRequestCallId:string,responseJson:Array<MainLessonDetailObject>)=>{

        if(apiRequestCallId===this.lesssonDetailApi){
            this.setState({
                loading:false,
                reportLesson:responseJson,
                startDate:this.state.startDate,
                endDate:this.state.endDate
            })
        }

     }

     async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
          
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
          this.getLessonDetailResponse(apiRequestCallId, responseJson)
        }
      }


    // Customizable Area End
}