export const imgCall = require("../assets/ic_call.png");
export const imgSms = require("../assets/ic_sms.png");
export const imgUser = require("../assets/ic_user.png");
export const imgContactUsHeader = require("../assets/header_contact_us.svg");
export const imgGroupContactUsIcon = require("../assets/group_contact_us_icon.svg");
export const imgContactUsTeddy = require("../assets/contact_us_teddy.svg");
export const imgContactPhone = require("../assets/contact_phone.svg");
export const imgContactSms = require("../assets/contact_sms.svg");
export const imgContactInstagram = require("../assets/contact_instagram.svg");
export const imgContactTwitter = require("../assets/contact_twitter.svg");
export const imgContactTiktok = require("../assets/contact_tiktok.svg");
export const imgButtonClose = require("../assets/button_close.svg");
export const imgMessageTeddy = require("../assets/message_Teddy.svg");
export const contactusBlue =  require('../assets/wink_blue.svg')
export const contactusBrown =  require('../assets/wink_brown.svg')
export const contactusPurple =  require('../assets/wink_purple.svg')
export const contactusRed =  require('../assets/wink_red.svg')
export const contactusYellow =  require('../assets/wink_yellow.svg')
export const contactusGreen =  require('../assets/wink_green.svg')
export const contactusLightgreen =  require('../assets/wink_lightgreen.svg')
export const contactusOrange =  require('../assets/wink_orange.svg')
export const orangebearmsg =  require('../assets/orangebearmsg.svg')
export const bluebearmsg =  require('../assets/bluebearmsg.svg')
export const mintbearmsg =  require('../assets/mintbearmsg.svg')
export const purplebearmsg =  require('../assets/purplebearmsg.svg')
export const redbearmsg =  require('../assets/redbearmsg.svg')
export const brownbearmsg =  require('../assets/brownbearmsg.svg')
export const yellowbearmsg =  require('../assets/yellowbearmsg.svg')
export const greenbearmsg =  require('../assets/greenbearmsg.svg')
