// Customizable Area Start
import { Box, Typography, styled } from '@material-ui/core'
import React from 'react'
import CenterCard from '../../../components/src/CenterCard.web'
import Trophies from '../../landingpage/src/Trophies.web'
import Goal from '../../landingpage/src/Goal.web'
import Median from '../../landingpage/src/Median.web'
import { maskMath } from './assets'
import { useTranslation } from "react-i18next";

interface MainContentCardProps {
  topicName: string
  unitName: string
  openDrawer: boolean
  trophiesOpen: boolean
  goalsOpen: boolean
  medianOpen: boolean
  onClickBackLandingPage: () => void
  handleOpenDrawer: (type: string) => void
  navigation: any
  drawerSide: boolean
  closeDrawer: () => void
  lessonName: string
  isChallenge?: boolean
  unitNumber?: any
  lessonNumber?: any
}

const MainContentCard: React.FC<MainContentCardProps> = ({
  topicName,
  unitName,
  openDrawer,
  trophiesOpen,
  goalsOpen,
  medianOpen,
  onClickBackLandingPage,
  handleOpenDrawer,
  navigation,
  drawerSide,
  closeDrawer,
  lessonName,
  isChallenge,
  unitNumber,
  lessonNumber,
}) => {
  const { t } = useTranslation()
  const decodedLessonName = decodeURIComponent(lessonName);
  return (
    <MainContentCardContainer style={{ backgroundColor: isChallenge ? '#25b178' : '#00AEFF'}}>
      <Box className="summaryCard">
        <Box>
          <>
            <CenterCard
              Topic={topicName}
              Unit={`${t("Unit")} ${unitNumber}: ${unitName}`}
              openDrawer={openDrawer}
              trophiesOpen={trophiesOpen}
              goalsOpen={goalsOpen}
              medianOpen={medianOpen}
              toggleState={onClickBackLandingPage}
              handleOpenDrawer={(name: string) => handleOpenDrawer(name)}
              navigation={navigation}
              isChallenge={isChallenge}
            />
            <Trophies
              stateOpen={trophiesOpen}
              drawerOpen={drawerSide}
              closeDrawer={closeDrawer}
            />
            <Goal
              stateOpen={goalsOpen}
              drawerOpen={drawerSide}
              closeDrawer={closeDrawer}
            />
            <Median
              stateOpen={medianOpen}
              drawerOpen={drawerSide}
              closeDrawer={closeDrawer}
            />
          </>
        </Box>
      </Box>
      <Box className="unitNameRectangle" style={{}}>
        <Typography className="unitName" style={{backgroundColor: isChallenge ? '#25b178' : '#00AEFF'}}>{isChallenge ? `${t("Challenge")} ${lessonNumber}:` :  `${t("Lesson")} ${lessonNumber}:`}</Typography>
        <Typography className="unitName" style={{backgroundColor: isChallenge ? '#25b178' : '#00AEFF'}}>{decodedLessonName}</Typography>
      </Box>
    </MainContentCardContainer>
  )
}

const MainContentCardContainer = styled(Box)(({ theme }) => ({
    width: '100%',
  display: 'none',
  gap: '15px',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundImage: `url(${maskMath})`,

  '& .summaryCard': {
    marginTop: '170px',
  },

  '& .unitNameRectangle': {
    height: '78px',
    left: '303px',
    top: '720px',
    borderRadius: '20px',
    boxShadow: '0px 0px 8px 7px #0000000D',
    Width: '100%',
    margin: 'auto',
    alignSelf: 'flex-end',
  },

  '& .unitName': {
    fontFamily: 'Nunito',
    height: '12px',
    top: '565px',
    left: '367px',
    fontSize: '17px',
    fontWeight: '800',
    lineHeight: '20px',
    maxHeight:'contentFit',
    textAlign: 'center',
    padding: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.144px',
    color: 'var(--primary-blue-blue-primary-light, #C9EEFF)',
  },

  [theme.breakpoints.up('md')]: {
    flex: 5,
    display: 'flex',

    '& .unitNameRectangle': {
      Width: '393px',
      alignSelf: 'flex-end',
    },

    '& .unitName': {
      fontWeight: '17px',
    },
  },
}))

export default MainContentCard
// Customizable Area End
