import * as React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Button,
    styled,
} from "@material-ui/core";
import {
    group_state,
    group_diamond,
    skip_check1,
    skip_check2,
    skip_check3,
    skip_check4,
    skip_close
} from "./assets";
import { Props } from "./StripePayments";
import { Link } from 'react-router-dom';
import NavigateLayout from "../../../components/src/NavigateLayout";
import { useTranslation } from "react-i18next";
import CenterPortion from './CenterPortion.web'
// Customizable Area End

// Customizable Area Start
const data = [
    {
        imgSrc: skip_check1,
        title: 'All Premium Benefits',
        description: 'Unlock exclusive features.',
    },
    {
        imgSrc: skip_check2,
        title: 'Auto-Renew Payment',
        description: 'Enjoy hassle-free, automatic renewals.',
    },
    {
        imgSrc: skip_check3,
        title: 'Cancel Anytime',
        description: 'Customize your subscription settings with ease.',
    },
    {
        imgSrc: skip_check4,
        title: 'Support Our Mission',
        description: 'Join us in delivering exceptional content.',
    },

];
// Customizable Area End
// Customizable Area Start
const PremiumSubscription: React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const handleClickCloseBtn = () => {
        props.navigation.navigate("Settings2")
    }
    // Customizable Area End
    return (
        // Customizable Area Start
        <NavigateLayout menuName="Settings" isShowBottomMenu={false}>
            <SettingContainer>
                <Box className="mainPremiumWrSubWrapper">
                    <Box className="settingpremiumBackground">
                    <CenterPortion navigation={undefined} id={""} />
                    </Box>
                    <Box className="contentPreminumContainer">
                        <Box style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            width: '100%',
                            justifyContent: 'center',
                            position: 'relative',
                        }}>
                            <img src={skip_close} className="" alt="skip Setting" style={{ marginRight: '10px', position: 'absolute', left: '0px', top: '0px', }} onClick={handleClickCloseBtn} />
                            <Typography style={webStyle.premiumtextTitle}>
                                {t("Premium Subscription")}
                            </Typography>
                        </Box>

                        <Box>
                            <img src={group_state} className="group_stateImage" alt="group Setting" />
                        </Box>
                        <Box style={{ marginBottom: '20px' }}>
                            <img src={group_diamond} className="group_stateImage" alt="diamond Setting" />
                        </Box>

                        {data.map((benefit, index) => (
                            <div key={benefit.title} style={{ display: 'flex', marginTop: '10px', width: '100%' }}>
                                <div style={{ marginRight: '10px', marginTop: '10px' }}>
                                    <img src={benefit.imgSrc} alt={`benefit_${index}`} />
                                </div>
                                <div style={webStyle.textMainsection}>
                                    <Typography style={webStyle.textSubFirst} >
                                        {t(benefit.title)}
                                    </Typography>
                                    <Typography style={webStyle.textSubSecond} >
                                        {t(benefit.description)}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                        <Box style={webStyle.boxparentes}>
                            <Box style={webStyle.btnBoxMain}>
                                <Link to="/PremiumCheckout" style={{ position: 'relative' }}>
                                    <Button className="continue-btn" style={webStyle.btnTitle}>
                                        <div className="continue-btn-overlay"></div>
                                        <span className="continue-btn-text">{t("Continue")}</span>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* <BottomMenu /> */}
            </SettingContainer>
        </NavigateLayout>
        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .continue-btn-overlay": {
        position: 'absolute',
        width: '100%',
        height: '40px',
        top: 0,
        left: 0,
        backgroundColor: '#36BFFF',
        borderRadius: '12px',
        zIndex: 2
    },

    "& .continue-btn-text": {
        position: 'relative',
        zIndex: 3,
    },

    "& .MuiButton-label": {
        fontFamily: "Nunito"
    },

    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },


    '& .mainPremiumWrSubWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: '920px'
    },

    '& .contentPreminumContainer': {
        flex: '6',
        background: 'linear-gradient(180deg, #62CDFF 0%, #A95EDB 100%)',
        overflow: 'auto',
        padding: '70px 24px 0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    '& .settingpremiumBackground': {
        backgroundColor: '#fff',
    },
    '& .settingImgWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .group_stateImage': {
        marginTop: '40px'
    },

    '& .settingListTitle': {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },


    '& .settingTitle': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .teddyImage': {
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
    },


    '& .borderBottom': {
        borderBottom: '1px solid #e6e6e6',
    },


    [theme.breakpoints.up('md')]: {

        '& .settingpremiumBackground': {
            flex: 5,
            position: 'fixed',
            width: 'calc(50% - 120px)',
            height: '100vh'
        },

        '& .settingTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

        '& .teddyImage': {
            // width: "50%",
            maxWidth: '240px',
        },

        '& .mainPremiumWrSubWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },

        '& .contentPreminumContainer': {
            paddingTop: '90px',
            position: 'relative',
            maxWidth: '50%',
            left: '50%'
        },


        '& .settingImgWrapper': {
            paddingTop: 'unset',
            padding: 0
        },

    },



    [theme.breakpoints.down('sm')]: {
        '& .settingpremiumBackground': {
            display: 'none',
        },
    },

    [theme.breakpoints.up('lg')]: {


        '& .mainPremiumWrSubWrapper': {
            marginLeft: '282px',
            width: `calc(100% - 282px)`,

        },

        '& .teddyImage': {
            maxWidth: 'unset',
        },

    },

    [theme.breakpoints.down('xs')]: {
        '& .settingpremiumBackground': {
            display: 'none',
        },
    },

}))

const webStyle = {

    premiumtextTitle: {

        color: '#FFFFFF',
        fontWeight: 900,
        lineHeight: '26px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        fontFamily: 'Nunito',
        fontSize: '22px',
        paddingLeft: '20px',
        width: '200px',
    },
    textMainsection: {
        marginTop: '10px',
        marginLeft: '20px',
    },
    textSubFirst: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF',
    },
    textSubSecond: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#FFFFFF',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '24px',
    },




    premiumText: {
        fontFamily: 'Quicksand',
        fontSize: '22px',
        fontWeight: 700,
        color: '#fff',
    },


    boxparentes: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        marginTop: '60px',
    },
    btnTitle: {
        width: '350px',
        borderRadius: '12px',
        fontFamily: 'Nunito !important',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        textTransform: 'none' as 'none',
        backgroundColor: '#00AEFF',
        zIndex: 1,
        height: '44px',
        maxWidth: '100%'
    },
    btnBoxMain: {
        height: 'auto',
        borderRadius: '12px',
        background: '#36BFFF',
        width: '90%',
        margin: '30px 0px 0px 0px',
        textAlign: 'center' as 'center',
        boxSizing: 'border-box' as 'border-box',
    },


};
// Customizable Area End

export default PremiumSubscription;
