import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  styled,
} from '@material-ui/core'
import React, { useState } from 'react'
import { checkBox, checkedBox, redFlag } from './assets'
import  {useTranslation} from "react-i18next"

interface ReportModalProps {
  open: boolean
  onClose: () => void
  title: string
  reportOptions: Array<any>
  onSubmitReport: () => void
  handleOption: (value: string) => void;
}

const ReportModal = ({ open, onClose, title, reportOptions, onSubmitReport, handleOption }: ReportModalProps) => {
  const  { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<number[]>([]);
  const [showTextInput, setShowTextInput] = useState<boolean>(false)

  const handleClose = () => {
    setSelectedOption([]);
    setShowTextInput(false)
    onClose()
  }

  const handleSubmitReport = () => {
    handleClose()
    onSubmitReport()
  }

  return (
    <ModalStyled open={open} onClose={handleClose}>
      <Box className="container">
        <img src={redFlag} width={32} height={43} />
        <Typography className="title">{title}</Typography>
          {reportOptions?.map((item) => (
        <Box className="optionsWrapper">
            <Box key={item.id} className="optionItem">
              <div
                data-testId="reportOption"
                onClick={() => {
                  const updatedOptions = [...selectedOption];
                  if (updatedOptions.includes(item.id)) {
                    updatedOptions.splice(updatedOptions.indexOf(item.id), 1);
                  } else {
                    updatedOptions.push(item.id);
                  }
                  setSelectedOption(updatedOptions);
                  handleOption(updatedOptions.map(id => reportOptions.find(option => option.id === id)?.value || "").join(", "));
                  if (item.id === 6) {
                    setShowTextInput(true);
                  } else {
                    setShowTextInput(false);
                  }
                }}
              >
                <img
                      src={selectedOption.includes(item.id) ? checkedBox : checkBox}
                  width={18}
                  height={18}
                  style={{ marginTop: '4px' }}
                />
              </div>
              <Typography className="optionText">{t(item.value)}</Typography>
            </Box>
        </Box>
          ))}
        {showTextInput && (
          <ReportTextField
            variant="outlined"
            placeholder="Message..."
            multiline
            minRows={2}
            autoComplete="off"
          />
        )}
        <Box className="buttonWrapper">
          <CancelButton data-testId="cancelReport" onClick={handleClose}> 
          {t("Cancel")}</CancelButton>
          <ActionButton data-testId="submitReport" disabled={selectedOption.length === 0} onClick={handleSubmitReport}> {t("Submit")}</ActionButton>
        </Box>
      </Box>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    transform: 'translate(-50%, -50%)',
    width: 296,
    backgroundColor: '#fff',
    padding: 26,
    borderRadius: 12,
    flexDirection: 'column',
    alignItems: 'center',
    border: '2px solid #e6e6e6',
  },

  '& .title': {
    marginTop: '22px',
    marginBottom: '10px',
    fontFamily: 'Nunito',
    fontSize: '18px',
    color: '#363636',
    fontWeight: '900',
  },

  '& .optionsWrapper': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  '& .optionItem': {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '11px',
  },

  '& .optionText': {
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: 'Quicksand',
    color: '#666666',
    marginLeft: '10px',
  },

  '& .buttonWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    marginTop: '24px',
  },
}))

const ActionButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  color: 'white',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#36BFFF',
  boxShadow: '0px 4px #00AEFF',
  borderRadius: '12px',
  textTransform: 'none',
  width: '110px',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
  '&:hover': {
    backgroundColor: '#36BFFF',
    boxShadow: '0px 4px #00AEFF',
  },
  '&.Mui-disabled': {
    color: '#e6e6e6',
    backgroundColor: '#fff',
    border: '2px solid #e6e6e6',
    boxShadow: '0px 4px #e6e6e6',
  },
}))

const CancelButton = styled(Button)(() => ({
  width: '110px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  color: '#ccc',
  textAlign: 'center',
  fontFamily: 'Nunito',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  letterSpacing: '0.8px',
  backgroundColor: '#fff',
  boxShadow: '0px 4px #ccc',
  borderRadius: '12px',
  border: '2px solid #ccc',
  textTransform: 'none',
  '& .MuiButton-label': {
    fontFamily: 'Nunito',
  },
}))

const ReportTextField = styled(TextField)(() => ({
  marginTop: '12px',
  width: '100%',
  '& fieldset': {
    borderColor: '#e6e6e6',
    borderWidth: '2px',
  },
  '&:hover fieldset': {
    borderWidth: '2px',
    borderColor: '#363636!important',
  },
  '& .Mui-focused fieldset': {
    borderColor: '#363636!important',
  },
  '& .Mui-error fieldset': {
    borderColor: '#F96855!important',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#f7f7f7',
    borderRadius: '10px',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    color: '#4D4D4D',
    fontWeight: '500',
  },
  '& .MuiFormHelperText-root': {
    margin: '10px 0 0 0',
    fontSize: '14px',
    color: '#999999',
    fontFamily: 'Quicksand',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#F96855',
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: '6px',
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: '6px',
  },
  '& .Mui-disabled fieldset': {
    borderColor: '#e6e6e6',
    borderWidth: '2px',
  },
  '& .MuiOutlinedInput-multiline': {
    paddingTop: '11px',
    paddingBottom: '11px',
  },
}))

export default ReportModal
