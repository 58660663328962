import { Box, Typography, styled } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
const baseURL = require("../../../framework/src/config.js").baseURL;
import { teddyBlue, teddyBrown, teddyGreen, teddyLightgreen, teddyOrange, teddyPurple, teddyRed, teddyYellow } from "./assets";
const SettingBackground = () => {

  const [color, setColor] = useState('');
  const [teddyCenter, setTeddyCenter] = useState()

  const getCurrentUserDetail = async () => {
    try {
      let token = localStorage.getItem("token");
      const resp = await fetch(
        `${baseURL}/bx_block_profile/profiles/current_user_profile`,
        {
          headers: {
            "Content-Type": "application/json",
            token: token || ''
          }
        }
      );
      const response = await resp.json();
      if (response?.data) {
        const avatarColor = response?.data?.attributes?.favorite_color;
        setColor(avatarColor);
      }
    } catch (error) {
      console.error("Error fetching user detail:", error);
    }
  };

  useEffect(() => {
    getCurrentUserDetail();
  }, []);

  useEffect(() => {
    updateTeddy();
  }, [color]);

  const updateTeddy = () => {
    const colorToUpdateTeddy:any = {
      '#ff8400': teddyOrange,
      '#62cdff': teddyBlue,
      '#b7f0ad': teddyLightgreen,
      '#a95edb': teddyPurple,
      '#f96855': teddyRed,
      '#884a39': teddyBrown,
      '#fdce36': teddyYellow,
      '#2bce8c': teddyGreen
    };

    const updatedTeddy = colorToUpdateTeddy[color];
    setTeddyCenter(updatedTeddy);
  };


  const {t} =useTranslation()
  return (
    <SettingBackgroundContainer>
      <Box className="settingImgWrapper">
        <div />
        <Typography className="settingTitle">{t("Settings")}</Typography>
        <img src={teddyCenter} className="teddyImage" alt="Teddy Setting" />
      </Box>
    </SettingBackgroundContainer>
  )
}

const SettingBackgroundContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'none',

  '& .settingImgWrapper': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomRightRadius: '20px',
    backgroundColor: '#A95EDB',
  },

  '& .settingTitle': {
    fontSize: '30px',
    fontFamily: 'Nunito',
    color: '#fff',
    fontWeight: '900',
  },

  '& .teddyImage': {
    width: '154px',
    height: 'auto',
  },

  [theme.breakpoints.up('md')]: {
    display: 'block',
    flex: 5,


    '& .teddyImage': {
      width: '360px',
    },
  },
}))

export default SettingBackground
