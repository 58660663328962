import React from "react";
// Customizable Area Start
import { Box, styled, } from "@material-ui/core";
import NavigateLayout from "../../../components/src/NavigateLayout";
import CenterCard from "../../../components/src/CenterCard.web";
import Trophies from '../../landingpage/src/Trophies.web';
import Goal from '../../landingpage/src/Goal.web';
import ChallengeLessonController from "./ChallengeLessonController.web";
import { centerCardIcon, listIcon, groupMaskMath } from "./assets";
import ChallengeUnitTopic from "./ChallengeUnitTopics.web";
// import Maths from "./Math.web";
// Customizable Area End

export default class ChallegeLessons extends ChallengeLessonController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Menu">
        <SettingContainer style={{ background: '#DCFFD6', }}>
          <Box className="mainContentWrapper">
            <Box className="settingBackground">

              <Box className="settingImgWrapper"
                style={{ backgroundImage: `url(${groupMaskMath})` }}
              >
                <Box className="nomargin">
                  <Goal data-testid="goal" stateOpen={this.state.goalsOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer} />
                  <CenterCard
                    Topic={this.state.topicName}
                    Unit={this.state.unitName}
                    data-testid="centerCard"
                    topicIcon={centerCardIcon}
                    openDrawer={this.state.openDrawer}
                    trophiesOpen={this.state.trophiesOpen}
                    goalsOpen={this.state.goalsOpen}
                    multilevelIcon={listIcon}
                    toggleState={this.goBackScreen}
                    handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
                    navigation={this.props.navigation} />
                  <Trophies data-testid="trophies" stateOpen={this.state.trophiesOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer} />
                </Box>
              </Box>
            </Box>
            <Box className="mainLanding">
              <Box style={{ position: "relative" }}>
                <DrawerStyle>
                  <ChallengeUnitTopic
                    data-testid="UnitTopics"
                    Topic={this.state.topicName}
                    unitName={this.state.unitName}
                    handleNonPremium={this.handleNonPremiumSceen}
                    data={this.state.LessonData}
                    isPremiumUser={this.state.isPremium}
                    navigation={this.props.navigation}
                    unitId={this.state.unitId}
                  />
                </DrawerStyle>
              </Box>
            </Box>
          </Box>
        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    )
  }
}

// Customizable Area Start

export const SettingContainer = styled(Box)(({ theme }) => ({
  background: '#DCFFD6',
  width: '100%',
  height: '100%',
  "@media(max-width: 959px)": {
    background: '#DCFFD6',
  },

  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 ': {
    backgroundColor: '#2BCE8C !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 > .rectangleBlock': {
    backgroundColor: '#2BCE8C !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox': {
    backgroundColor: '#2BCE8C !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle': {
    backgroundColor: '#25B078 !important',
    height: '100%',
    minHeight: 44
  },

  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle > .units': {
    backgroundColor: '#25B078 !important',
    color: '#fff',
    borderRadius: '0px 0px 20px 20px',
    opacity: 0.9,
    lineHeight: 'normal',
    height: '100%',
    minHeight: 12
  },

  '& .nomargin': {
    width: '100% !important',
  },
  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .settingBackground': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .mainLanding': {
    flex: 6,
    alignItems: 'center',
    margin: 'auto',
    // backgroundImage: `url(${groupDecoration})`,
    '@media(max-width:960px)': {
      margin: 0,
    }
  },

  '& .settingImgWrapper': {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#2BCE8C',
    borderBottomRightRadius: '0px',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: '80%',
    "@media(max-width: 959px)": {
      height: 'auto'
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      margin: 0,
      width: '100% !important',
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .settingBackground': {
      flex: 5,
      height: 'auto'
    },
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      flexDirection: 'row',
      marginLeft: '240px',
    },
    '& .nomargin': {
      margin: 'auto !important',
    },
    '& .settingImgWrapper': {
      height: '100vh',
      borderBottomRightRadius: '20px',
      paddingTop: 'unset',
    },
  },
}))

export const DrawerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  fontFamily: "Quicksand",
  alignItems: 'center',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  }

}))

// Customizable Area End
