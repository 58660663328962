import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Button, IconButton, Snackbar, Portal, } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import NavigateLayout from "../../../components/src/NavigateLayout";
import { rectangle, } from '../../landingpage/src/assets';
import { Back, Search, groupscroll, skip_closedbook, maskMath, groupMaskMath, loader } from "./assets";
//@ts-ignore
import Graph from "react-graph-vis"
import ChallengeController from "./ChallengeController.web";
import ChallengeShuffleModal from "./ChallengeShuffleModal.web";
import ChallengeModal from "./ChallengeModal.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

 class ChallengePage extends ChallengeController {
  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  handleClose = () => {
    this.setState({ challengeModal: false });
  }


  renderSubCatGraph=()=>{
    return(
      <>
      {this.state.subCategoryLoading? 
        <Box style={{height: "100vh", overflow: 'auto', width: '100%'}} className="GraphloaderShown">
        <img src={loader} alt="Loading" />
         </Box>:
      <Graph
      events={{ select: this.NodeFunction }}
      graph={this.state.graph}
      options={this.options}
      style={{ height: "100vh", overflow: 'auto', width: '100%', alignSelf:'center',display:'flex' }}
      data-testid="graph"
    />
      }
    </>
    )
  }


  renderGradeGraph=()=>{
    return(
      <Graph
        events={{ select: this.selectGrade }}
        graph={this.state.gradeList}
        options={this.options}
        style={{ height: "100vh", overflow: 'auto', width: '100%', alignSelf:'center',display:"flex"}}
        data-testid="Gradegraph"
      />
    )
  }

  renderWhiteBoardContent=()=>{
    return (
      <Box style={{ position: "relative" }}>
                {this.state.ChallengeLesson && <ChallengeModal
                  data-testid="ChallengeModal"
                  Challenge={this.state.ChallengeLesson}
                  open={this.state.challengeModal}
                  navigation={this.props.navigation}
                  onClose={this.handleClose}
                />}
                <ChallengeShuffleModal
                  data-testid="ChallengeShuffleModal"
                  open={this.state.challengeShuffleModal}
                  onClose={this.handleChallengeShuffleonClose}
                />
                <DrawerStyle>
                  {!this.state.showCategoryGraph ?         
                    this.renderGradeGraph()
                    :this.renderSubCatGraph()
                  }
                </DrawerStyle>
                <Box className="button_position">
                  {this.state.showCategoryGraph && 
                  <Button
                    className="challenge-btn"
                    data-testid='challenge-btn'
                    variant="outlined"
                    onClick={this.handleChallengeButton}
                    startIcon={<img
                      src={skip_closedbook}
                      style={{ height: 18, width: 16 }}
                    />}
                  >
                    {this.props.t("Challenges")}
                  </Button>
                   }
                </Box>
              </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Menu">
        
        <SettingContainer>
        {this.state.profileLoading? 
        <Box className="loaderShown">
        <img src={loader} alt="Loading" />
         </Box>:
          <Box className="main-Content-Wrapper">
            <Box className="setting-Background">
              <Box className="setting-Image-Wrapper" style={{ backgroundImage: `url(${groupMaskMath})`, objectFit: "cover" }}>
                <Box className="scroll-Box" >
                  <Box className="Back_Image">
                    <IconButton data-testid="back-btn"
                      onClick={this.handleBack}                   
                    >
                      <img src={Back} width="20px" height="22px" />
                    </IconButton>
                  </Box>
                  <Box className="icon-Container-scroll">
                    <img src={this.state.avatarIcon} width="51px" />
                    <Box>
                      <Box className="scroll-box-title-wrapper">
                        <Typography className="fontSet">{this.state.topic}</Typography>
                      </Box>
                      <Typography className="scroll-box-detail">{this.state.description}</Typography>
                    </Box>
                  </Box>
                  <Box className="language-Button">
                    <IconButton >
                      <img src={Search} width="31px" height="22px" />
                    </IconButton>
                  </Box>
                  <img src={maskMath} className="imagePosition" />
                </Box>
                <Box className="nomargin">
                </Box>
              </Box>
            </Box>
            <Box className="GraphLanding">
              {this.renderWhiteBoardContent()}
            </Box>
          </Box>
           }
          <Portal>
            <Snackbar
              data-testid="snackbar"
              open={this.state.ToastOpen}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={2000}
              style={{ zIndex: 9999 }}
              onClose={this.handleCloseToast}>
              <Alert data-testid="alert" onClose={this.handleCloseToast} style={{ position: 'fixed', zIndex: 9999, top: 10, right: 20 }} severity="error">{this.state.ChallengeError}</Alert>
            </Snackbar>
          </Portal>
        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
export default withTranslation()(ChallengePage)

const SettingContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: '#C9EEFF',

  '& .GraphloaderShown': {
    height: '100%',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    width: "calc(100% - 240px)", 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },

  "@media(max-width: 959px)": {
    backgroundColor: '#fff',
  },
  '& .main-Content-Wrapper': {
    display: 'flex',
    flexDirection: 'column',
  },
  '& .nomargin': {
    width: '100% !important',
  },

  '& .setting-Background': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .loaderShown':{
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(100% - 240px)",
    marginLeft: "240px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },
  '& .fontSet': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    fontFamily: 'Nunito',
    letterSpacing: '0.096pxpx',
    fontWeight: '900',
    lineHeight: '10px',
    fontSize: '12px',
    fontStyle: 'normal',
    textTransform: 'uppercase',
  },
  '& .GraphLanding': {
    flex: 6,
    height: 'calc(100% - 136px)',
    margin: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
    backgroundColor: '#fff',
    "@media(max-width: 959px)": {
      width: '100%',
      position: 'absolute',
      zIndex: 100,
      top: 'calc(0% + 136px)',
      flex: 'unset',
      borderRadius: '25px',
      boxShadow: "rgba(0, 0, 0, 0.2) 0px -2px 12px",
    }
  },

  '& .Back_Image': {
    marginRight: '15px',
    marginTop: 42,
    alignSelf: "flex-start",
    zIndex: 1,
    '& .MuiIconButton-root': {
      padding: "2px"
    },
    "@media(max-width: 520px)": {
      marginRight: '0px',
    },
  },

  '& .button_position': {
    position: "absolute",
    textAlign: 'center',
    bottom: "5%",
    width: '100%',

    "@media screen and (max-width: 960px) and (max-height: 1500px)": {
      bottom: '14%',
    },
    "@media screen and (max-width: 960px) and (max-height: 1200px)": {
      bottom: '16%',
    },
    "@media screen and (max-width: 960px) and (max-height: 980px)": {
      bottom: '18%',
    },

    "@media screen and (max-width: 960px) and (max-height: 830px)": {
      bottom: '21%',
    },

    "@media screen and (max-width: 960px) and (max-height: 670px)": {
      bottom: '25%',
    },

    "@media screen and (max-width: 960px) and (max-height: 550px)": {
      bottom: '28%',
    },
    "@media screen and (max-width: 960px) and (max-height: 500px)": {
      bottom: '32%',
    },

    "@media screen and (max-width: 960px) and (max-height: 420px)": {
      bottom: '36%',
    },


  },
  '& .imagePosition': {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  '& .challenge-btn': {
    zIndex: `100`,
    color: "var(--primary-blue-blue-primary, #62CDFF)",
    fontWeight: "700",
    height: "41px",
    textDecoration: "uppercase",
    fontFamily: "Nunito",
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "0.8px",
    borderRadius: "12px",
    fontSize: "16px",
    background: "var(--basic-white, #FFF)",
    border: "2px solid var(--primary-blue-blue-primary-light, #C9EEFF)",
  },

  '& .challenge-btn > .MuiButton-label': {
    fontFamily: "Nunito",
    fontStyle: "normal",
    lineHeight: "12px",
    letterSpacing: "0.8px",
    fontSize: "16px",
  },

  '& .icon-Container-scroll': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    height: '65px',
    zIndex: 1,
    display: 'flex',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    marginTop: "24px",
    alignItems: "center",
    gap: '15px',
    padding: "10px",
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)'
  },
  '& .scroll-Box': {
    display: "flex",
    padding: "10px",
    minHeight: "150px",
    top: 0,
    margin: '50% auto auto',
    alignItems: "baseline",
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundColor: 'var(--secondary-green, #2BCE8C)',
    backgroundPosition: "100%",
    backgroundImage: `url(${groupscroll})`,
    backgroundRepeat: "no-repeat",
    gap: '10px',
    position: 'relative',
    width: '80%',
    '@media(max-width:960px)': {
      margin: '0px !important',
    },
    '@media(max-width:520px)': {
      width: '100%',
      margin: '0px !important',
    }
  },
  '& .scroll-box-title-wrapper': {
    display: 'flex',
    gap: '10px',
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    width: '100%',
    fontFamily: "Nunito",
    lineHeight: "18.5px",
    color: "#00AEFF",
    letterSpacing: "0.128px"
  },
  '& .scroll-box-detail': {
    fontFamily: "Nunito",
    fontSize: "16px",
    color: "#2BCE8C",
    fontStyle: "normal",
    whiteSpace: 'nowrap',
    overflow: "hidden",
    width: '100%',
    maxWidth: '190px',
    textOverflow: "ellipsis",
    fontWeight: 700,
    letterSpacing: "0.128px",
    lineHeight: "14px",
    marginTop: '8px'
  },

  '& .language-Button': {
    zIndex: 1,
    display: 'flex',
    gap: '10px',
    position: "relative", top: "-12px",
    marginLeft: 5,
    '& .MuiIconButton-root': {
      padding: "2px"
    },
    '@media(max-width:520px)': {
      marginLeft: 0
    }
  },
  '& .setting-Image-Wrapper': {
    alignItems: 'center',
    backgroundColor: 'var(--secondary-green, #2BCE8C)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomRightRadius: '0px',
    height: '100vh',
    display: 'flex',
    backgroundSize: '80%',
    "@media(max-width: 959px)": {
      height: 'auto'
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .setting-Image-Wrapper': {
      height: '100vh',
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',

    },
    '& .nomargin': {
      margin: 'auto !important',
    },
    '& .main-Content-Wrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
    '& .setting-Background': {
      flex: 5,
      height: 'auto'
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .main-Content-Wrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      margin: 0,
      width: '100% !important',
    }
  }
}))

const DrawerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  fontFamily: "Quicksand",
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  }

}))

// Customizable Area End
