// Customizable Area Start
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { IBlock } from '../../../framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
import { Message } from '../../../framework/src/Message'
import { v4 as uuidv4 } from 'uuid';
import { getStorageData, removeStorageData } from 'framework/src/Utilities'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string
  t?:any
}

export interface Country {
  id: string
  type: string
  attributes: {
    name: string
    country_code: string
    alpha2: string
    alpha3: string
  }
}

interface City {
  id: string
  type: string
  attributes: {
    name: string
    country_id: number
  }
}

interface School {
  id: string
  type: string
  attributes: {
    name: string
    city_id: number
  }
}

interface Grade {
  id: string
  type: string
  attributes: {
    name: string
  }
}

interface S {
  isLoading: boolean
  firstName:string;
  lastName:string;
  showAlert: boolean
  popupData: {
    text: string
    type: 'success' | 'error'
  }
  signUpData: {
    email: string
    password: string
  }
  googleData:{
    email:string
  }

  countriesData: Array<Country>
  countriesDisplayList: Array<string>
  citiesData: Array<City>
  citiesDisplayList: Array<string>
  schoolsData: Array<School>
  schoolsDisplayList: Array<string>
  gradesData: Array<Grade>
  gradesDisplayList: Array<string>
  selectedCountry: string
  selectedCity: string
  selectedSchool: string
  selectedGrade: string
  isDisableSelectOtherCountries: boolean
  isDisabeGrade:boolean
  isClickSubmitBtn: boolean
  userNameError: string
  userName:string
  profileType: string
  token: string | null;
  parentPhoneCountryCode:any;
  studedntPhoneCountryCode:any;
  countryObject:any
  role_id:number;
}

interface SS {
  id: any
}

export default class CompleteProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  profileAccountId: string = ''
  getCountriesId: string = ''
  getCitiesId: string = ''
  getGradesByCountryId:string=''
  getGradesId: string = ''
  getSchoolsId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      isLoading: false,
      showAlert: false,
      signUpData: {
        email: '',
        password: '',
      },
      popupData: {
        text: '',
        type: 'success',
      },
      countriesData: [],
      countriesDisplayList: [],
      citiesData: [],
      citiesDisplayList: [],
      parentPhoneCountryCode:undefined,
      studedntPhoneCountryCode:undefined,
      schoolsData: [],
      schoolsDisplayList: [],
      gradesData: [],
      gradesDisplayList: [],
      isDisabeGrade:true,
      selectedCountry: '',
      selectedCity: '',
      selectedSchool: '',
      selectedGrade: '',
      countryObject:undefined,
      isDisableSelectOtherCountries: false,
      isClickSubmitBtn: false,
      userNameError: '',
      userName:'',
      googleData:{email:''},
      profileType: 'Student',
      token: null,
      firstName:'',
      lastName:'',
      role_id:6

    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getCountries()
    const ProfieObj = await getStorageData("profileObj",true)
    if(ProfieObj){
      this.setState({
       firstName:ProfieObj.firstName,
       lastName:ProfieObj.lastName
      })
    }
    const registerData = localStorage.getItem('signUpData') || ''
    const googleData = localStorage.getItem('googleSignupData') || ''
    if(googleData){
    this.setState({ signUpData: JSON.parse(googleData) })
    }else{
     this.setState({ signUpData: JSON.parse(registerData) })
  }
}

 componentWillUnmount=async()=> {
   removeStorageData("profileObj")
 }

  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message)

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.profileAccountId
    ) {
      if (responseJson?.errors) {
        this.setState({
          isLoading: false,
          userNameError: responseJson?.errors?.[0]?.['profile.display_name']
            ? `User name ${responseJson?.errors?.[0]?.['profile.display_name']}`
            : '',
        })
        return
      }
     const token = responseJson?.meta?.token || null;

      this.setState({ token });

      localStorage.setItem('emailSignUpAddress', JSON.stringify({
      email: this.state.signUpData.email,
      userName: this.state.userName,
    }));
    localStorage.setItem("token", responseJson.meta.token)

    const destinationScreen = localStorage.getItem('signUpData')
      ? 'ActiveEmail'
      : 'FirstLogin'; 
    this.props.navigation.navigate(destinationScreen);
    }
    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      apiRequestCallId === this.getCountriesId
    ) {
      this.setState({
        countriesData: responseJson?.data,
        countriesDisplayList: this.getNameList(responseJson),
      })
    }

    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      apiRequestCallId === this.getCitiesId
    ) {
      this.setState({
        citiesData: responseJson?.data,
        citiesDisplayList: this.getNameList(responseJson),
      })
    }

    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      apiRequestCallId === this.getSchoolsId
    ) {
      this.setState({
        schoolsData: responseJson?.data,
        schoolsDisplayList: this.getNameList(responseJson),
      })
    }

    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      apiRequestCallId === this.getGradesId
    ) {
      this.setState({
        gradesData: responseJson?.data,
        gradesDisplayList: this.getNameList(responseJson),
      })
    }
    if (
      apiRequestCallId &&
      responseJson &&
      !responseJson?.errors &&
      apiRequestCallId === this.getGradesByCountryId
    ) {
      if(responseJson?.data && responseJson.data.length>0){
      this.setState({
        gradesData: responseJson?.data,
        gradesDisplayList: this.getNameList(responseJson),
        isDisabeGrade: false,
      })
      if(this.state.countryObject?.attributes.name!=='Romania'){
        this.setState({
          isDisableSelectOtherCountries: this.state.countryObject?.attributes.name!='Romania',
          citiesData: [],
          citiesDisplayList: [],
        })
      
      }
    }else{
       this.setState({
        gradesData:[],
        isDisabeGrade: true,
        selectedCity: 'International',
        selectedSchool: '007 School',
        selectedGrade: '007 Grade ',
        gradesDisplayList:[],
        isDisableSelectOtherCountries: true,
      })
    }
    }
  }

  getNameList = (response: any) => {
    return response?.data?.map(
      (item: any) => item?.attributes?.name
    )
  }

  getCountries = () => {
    this.getCountriesId = sendAPIRequest('account_block/countries', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getSchools = (cityId: string) => {
    this.getSchoolsId = sendAPIRequest(
      `account_block/schools?city_id=${cityId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }

  getGrades = () => {
    this.getGradesId = sendAPIRequest('bx_block_categories/categories', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getGradesByCountry = (country:any) => {
    if(country?.attributes?.name === 'Romania'){
      this.getCities()
    }
    this.getGradesByCountryId = sendAPIRequest(`bx_block_categories/categories/get_grades_by_country_id?country_id=${country?.id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  getCities = () => {
    this.getCitiesId = sendAPIRequest('account_block/cities?country_id=138', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  submitProfileAccount = ({
  firstName,
  lastName,
  userName,
  studentPhone,
  parentPhone,
  parentEmail,
  teacherEmail,
}: {
  firstName: string
  lastName: string
  userName: string
  studentPhone: string
  parentPhone: string
  parentEmail: string
  teacherEmail: string
}) => {
  const googleSignupData = localStorage.getItem('googleSignupData');
  const type = googleSignupData ? 'social_account' : 'email_account';
  const uniqueAuthId = googleSignupData ? JSON.parse(googleSignupData).uniqueAuthId  : null;
  const categoryId = this.state.gradesData.map(item=>item.attributes.name===this.state.selectedGrade)
  const data = {
    type: type,
    attributes: {
      email: this.state.signUpData.email,
      password: this.state.signUpData.password,
      password_confirmation: this.state.signUpData.password,
      role_id: 6,
      unique_auth_id: uniqueAuthId,
      profile_attributes: {
        display_name: userName,
        phone: studentPhone,
        parent_phone: parentPhone,
        country: this.state.selectedCountry,
        country_id: this.state.countryObject?.id,
        city: this.state.selectedCity,
        school: this.state.selectedSchool,
        grade: this.state.selectedGrade,
        first_name: firstName,
        last_name: lastName,
        user_name: userName,
        parent_email: parentEmail,
        teacher_email: teacherEmail,
        category_id:categoryId
      },
    },
  };
  
  this.setState({ isLoading: true, userName });
  this.profileAccountId = sendAPIRequest('account_block/accounts', {
    method: 'POST',
    body: { data },
    headers: {
      'Content-Type': 'application/json',
    },
  });
};


  checkTouchedAndErrorInput = (touched: any, errors: any) => {
    return Boolean(touched && errors)
  }
}
// Customizable Area End
