// Customizable Area Start
import { BlockComponent } from 'framework/src/BlockComponent'
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum'
import { runEngine } from 'framework/src/RunEngine'
import { IBlock } from 'framework/src/IBlock'
import { sendAPIRequest } from '../../../components/src/utils'
export const configJSON = require('./config')
import { Message } from '../../../framework/src/Message'
import { QuestionData } from '../../educationalgamification/src/MicroLessonExercise'
import { QuestionTypeKey, handleCustomExerciseData } from '../../educationalgamification/src/MicroLessonExerciseController'

export interface Props {
  navigation: any
  id: string
  t?:any
}

interface S {
  showChallengeCelebrate: boolean
  showChallengeFinish: boolean
  showRoulette: boolean
  showRouletteResult: boolean
  lessonName: string
  topicName: string
  unitName: string
  trophiesOpen: boolean;
  goalsOpen: boolean;
  mathOpen: boolean;
  drawerSide: boolean;
  openDrawer: boolean;
  medianOpen: boolean;
  showGrade:boolean;
  challengeExerciseData: QuestionData[]
  questionIndex: number
  isLoading: boolean
  isFirstTimeCorrectAnswer: boolean
  isDisableShowCelebrate: boolean
  token: string
  lessonId: string
  miroLessonId: number
  totalXP: number
  reportData: string
  initialXPPoint: number
  startTime: number
  totalTime: number
  unitId: string
  nextLessonId: string
  nextLessonName: string
  lessonNumber: number
  unitNumber: number
  assessmentId: number
  teddyColor: string
}

interface SS {
  id: any
}

export default class MicroLessonExerciseController extends BlockComponent<
  Props,
  S,
  SS
> {
  getChallengeExercisesId: string = ''
  submitExerciseAnswerId: string = ''
  getSpecificAccountId: string = ''
  submitReportId: string = ''
  submitShareActionId: string = ''
  getNextChallengeId: string = ''
  getCurrentProfileId: string = ''

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      showChallengeCelebrate: false,
      showChallengeFinish: false,
      showRoulette: false,
      showRouletteResult: false,
      lessonName: '',
      topicName: '',
      unitName: '',
      trophiesOpen: false,
      goalsOpen: false,
      mathOpen: false,
      drawerSide: false,
      openDrawer: false,
      medianOpen: false,
      showGrade:false,
      challengeExerciseData: [],
      questionIndex: 0,
      isLoading: true,
      isFirstTimeCorrectAnswer: false,
      isDisableShowCelebrate: false,
      token: '',
      lessonId: '',
      miroLessonId: 0,
      totalXP: 0,
      reportData: '',
      initialXPPoint: 0,
      startTime: 0,
      totalTime: 0,
      unitId: '',
      nextLessonId: '',
      nextLessonName: '',
      lessonNumber: 1,
      unitNumber: 1,
      assessmentId: 0,
      teddyColor: '#62cdff',
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const token = localStorage.getItem('token') || ''
    const unitId = this.props.navigation.getParam('unitId') || '0'
    const lessonId = this.props.navigation.getParam('lessonId')
    const unitName = this.props.navigation.getParam('unitName')
    const lessonName = this.props.navigation.getParam('lessonName') || ''
    this.setState({
      lessonName,
      unitName,
      token,
      lessonId,
      startTime: Date.now(),
      unitId,
    })
    this.getChallengeExercises(token, lessonId)
    this.getSpecificAccount(token)
    this.getCurrentUserProfile(token)
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    if (responseJson && !responseJson?.errors) {
      this.apiSuccessCallBackController(apiRequestCallId, responseJson)
    }
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getChallengeExercisesId]: this.handleGetChallengeExercisesResponse,
      [this.getSpecificAccountId]: this.handleGetSpecificAccountResponse,
      [this.submitExerciseAnswerId]: this.handleSubmitExerciseAnswerResponse,
      [this.submitShareActionId]: this.handleSubmitShareActionResponse,
      [this.submitReportId]: this.handleSubmitReportResponse,
      [this.getNextChallengeId]: this.handleGetNextChallengeResponse,
      [this.getCurrentProfileId]: this.handleGetCurrentProfileResponse,

    }

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJson)
    }
  }

  handleGetCurrentProfileResponse = (responseJson: any) => {
    const teddyColor = responseJson?.data?.attributes?.favorite_color
    this.setState({ teddyColor })
  }

  handleGetNextChallengeResponse = (responseJson: any) => {
    const nextLessonId = responseJson?.data?.id
    const nextLessonName = responseJson?.data?.attributes?.name
    this.setState({
      nextLessonId,
      nextLessonName,
      showRoulette: false,
      showRouletteResult: true,
    })
  }

  handleSubmitReportResponse = () => {
    this.getSpecificAccount(this.state.token)
  }

  handleSubmitShareActionResponse = () => {
    this.getSpecificAccount(this.state.token)
  }

  handleSubmitExerciseAnswerResponse = () => {
    this.getSpecificAccount(this.state.token)
  }

  handleGetSpecificAccountResponse = (responseJson: any) => {
    const userXP = responseJson?.data?.attributes?.total_xp

    if (userXP) {
      Boolean(this.state.initialXPPoint) 
      ? this.setState({totalXP: userXP}) 
      : this.setState({totalXP: userXP, initialXPPoint: userXP})
    }
  }

  handleGetChallengeExercisesResponse = (responseJson: any) => {
    this.setState({ 
      isLoading: false,
      assessmentId: responseJson?.data?.attributes?.id
    })

    const questions = responseJson?.data?.attributes?.questions?.data
    const customQuestion = questions?.map((question: any) => {
      return {
        ...question,
        assessmentId: responseJson?.data?.attributes?.id
      }
    })
    
    const microLessonId = responseJson?.data?.attributes?.micro_lesson_id
    const lessonNumber = responseJson?.data?.attributes?.lesson_number
    const unitNumber = responseJson?.data?.attributes?.unit_number
    
    const customResponseData: QuestionData[] = handleCustomExerciseData(customQuestion)

    if (customResponseData?.length > 0) {
      this.setState({ 
        challengeExerciseData: customResponseData,
        miroLessonId: microLessonId, 
        isLoading: false,
        lessonNumber,
        unitNumber
      })
    } 
  }

  handleSelectReportOption = (option: string) => {
    this.setState({ reportData: option })
  }

  submitReport = () => {
    const body = {
      data: {
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        problem: this.state.reportData
      }
    }
    this.submitReportId = sendAPIRequest(
      `bx_block_request_management/report_problem`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }

  submitShareAction = ({assessmentId, questionBankId}: {assessmentId: number, questionBankId: number}) => {
    const body = {
      data: {
        share_record_type: "challenge",
        lesson_id: this.state.lessonId,
        micro_lesson_id: this.state.miroLessonId,
        assessment_id: assessmentId,
        question_bank_id: questionBankId
      }
    }
    this.submitShareActionId = sendAPIRequest(
      "bx_block_share/share",
      {
        method: "POST",
        headers: {
          token: this.state.token,
          "Content-Type": "application/json"
        },
        body
      }
    )
  }

  getChallengeExercises = (token: string, lessonId: string) => {
    this.setState({ isLoading: true })
    this.getChallengeExercisesId = sendAPIRequest(
      `bx_block_profile/challenge_assessment?lesson_id=${lessonId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getNextChallenge = () => {
    this.getNextChallengeId = sendAPIRequest(
      `bx_block_profile/return_lesson?type=unit&id=${this.state.unitId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token
        },
      }
    )
  }

  getSpecificAccount = (token: string) => {
    this.getSpecificAccountId = sendAPIRequest(
      'account_block/accounts/specific_account',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getCurrentUserProfile = (token: string) => {
    this.getCurrentProfileId = sendAPIRequest(
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  convertType: { [key in QuestionTypeKey]: string } = {
    'TRUE_FALSE': 'true_false',
    'MULTIPLE_CHOICE': 'mcq',
    'MATCHING': 'match_the_column',
    'SEQUENCE': 'sequence',
    'FILL_IN_BLANK': 'fill_in_the_blank',
  }

  submitExerciseAnswer = (
    {
      questionBankId,
      assessmentId,
      questionType,
      correctAnswer,
      questionBody,
      userAnswer,
      grade,
      correct,
      lessonCompleted,
      timeCompleteInSeconds,
    }: {
      assessmentId: number
      questionType: QuestionTypeKey
      questionBankId: number
      questionBody: string
      userAnswer: string
      correctAnswer: string
      correct: boolean
      timeCompleteInSeconds: number
      grade: number
      lessonCompleted: boolean
    }
  ) => {
    const body = {
      question_and_answer: {
        micro_lesson_id: this.state.miroLessonId,
        lesson_id: +this.state.lessonId,
        question_bank_id: questionBankId,
        assessment_id: assessmentId,
        question_body: questionBody,
        question_type: this.convertType[questionType],
        user_answer: userAnswer,
        correct_answer: correctAnswer,
        grade,
        correct,
        challenge_completed: lessonCompleted,
        time_complete_in_seconds: timeCompleteInSeconds,
      },
    }

    this.submitExerciseAnswerId = sendAPIRequest(
      `bx_block_educational_gamification/question_and_answers`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          token: this.state.token,
        },
        body,
      }
    )
  }
}
// Customizable Area End
