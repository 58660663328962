// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export interface Props {
    id?: string;
    navigation?: {
        navigate: (screenName: string) => void,
        getParam: (param: string, defaultValue?: unknown) => unknown,
        goBack: (routeKey?: string | null) => boolean | null,
    };
}
export interface S {
    // Customizable Area Start
    showPassword: boolean,
    showConfirmPassword: boolean,
    check: boolean,
    isSubmitButtonClicked: boolean,
    // Customizable Area End
}
export interface SS {
    id: string;
}
export default class EmailAccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    accountCreationCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            showPassword: false,
            showConfirmPassword: false,
            check: false,
            isSubmitButtonClicked: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.accountCreationCallId) {
                this.accountRegisterData(responseJson)
            }
        }
    }
  
    accountRegisterData = (responseJson: any) => {
        if (!responseJson.errors) {
            localStorage.setItem("token",responseJson.meta.token)
            this.setState({ isSubmitButtonClicked: true })
        }
        else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }

    }
    handleClickShowPassword = () => { this.setState({ showPassword: !this.state.showPassword }) };
    handleClickShowConfirmPassword = () => { this.setState({ showConfirmPassword: !this.state.showConfirmPassword }) };
    handleChecked = () => {
        this.setState({ check: !this.state.check });
        const checked = document.getElementById('check-id')
        if (checked) {
            checked.click()
        }
    }

    submitFormData = (values: { userName: string, email: string, password: string, confirmPassword: string }) => {
        const data = { user_name: values.userName, email: values.email, password: values.password, password_confirmation: values.confirmPassword }
        let body: object = {
            data: {
                type: "email_account",
                attributes: data
            }
        }
        this.accountCreationCallId = sendAPIRequest(configJSON.accountRegistrationApi, {
            method: "POST",
            body: body,
            headers: { 'Content-Type': 'application/json' },
        });
    }
    // Customizable Area End

}
// Customizable Area End
