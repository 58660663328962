// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { Props, configJSON } from "./StripePayments";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";

interface S {
    avatarColor:any;
}

interface SS {
    id: string;
}

export default class ProcessingPaymentsController extends BlockComponent<Props, S, SS> {
    createSubscriptionCallId: string = ''
    getCurrentProfileId: string = ''
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            avatarColor:''
          };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    createSubscription(cardToken?: string, paymentMethodId?: string, saved: boolean = false) {
        let body;

        if (cardToken) {
            body = {
                "subscription": {
                    "plan_id": Number(localStorage.getItem('planId'))
                },
                "card": {
                    "token": cardToken,
                    "saved": saved
                }
            }
        } else {
            body = {
                "subscription": {
                    "payment_method_id": paymentMethodId,
                    "plan_id": Number(localStorage.getItem('planId'))
                }
            }
        }

        this.createSubscriptionCallId = sendAPIRequest(
            configJSON.createSubscriptionEndPoint,
            {
                method: configJSON.createSubscriptionMethod,
                headers: {
                    "Content-Type": configJSON.apiContentType,
                    token: localStorage.getItem('token') || ''
                },
                body
            }
        )
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        )

        if (apiRequestCallId === this.createSubscriptionCallId) {
            if (responseJson?.data?.id) {
                this.props.navigation.navigate("PaymentSuccessful", {avatarColor : this.state.avatarColor})
            } else {
                this.props.navigation.navigate("PaymentError", {avatarColor : this.state.avatarColor})
            }
        }

        if (apiRequestCallId === this.getCurrentProfileId) {
            this.setState({
              avatarColor: responseJson?.data?.attributes?.favorite_color,
             })
          }
    }

    async componentDidMount() {
        const useSavedCard = localStorage.getItem("useSavedCard");
         this.getCurrentUserProfile()
        if (useSavedCard && useSavedCard === "true") {
            const cardId = localStorage.getItem("cardId") || '';
            this.createSubscription(undefined, cardId);
        } else {
            const newCardToken = localStorage.getItem('newCardToken') || '';
            const isCardSaved = localStorage.getItem('isCardSaved');

            this.createSubscription(newCardToken, undefined, isCardSaved === "true");
        }
    }

    getCurrentUserProfile = () => {
        const token = localStorage.getItem('token')
        this.getCurrentProfileId = sendAPIRequest(
          'bx_block_profile/profiles/current_user_profile',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token,
            },
          }
        )
      }
    
}

// Customizable Area End