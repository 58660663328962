// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const lightgreenHealthy =  require('../assets/lightgreen_healthy.svg')
export const lightgreenHappy = require('../assets/lightgreen_happy.svg')
export const lightgreenWeak = require('../assets/lightgreen_weak.svg')
export const lightgreenWorried =  require('../assets/lightgreen_worried.svg')
export const blueHealthy =  require('../assets/blue_healthy.svg')
export const blueHappy = require('../assets/blue_happy.svg')
export const blueWeak = require('../assets/blue_weak.svg')
export const blueWorried =  require('../assets/blue_worried.svg')
export const purpleHealthy =  require('../assets/purple_healthy.svg')
export const purpleHappy = require('../assets/purple_happy.svg')
export const purpleWeak = require('../assets/purple_weak.svg')
export const purpleWorried =  require('../assets/purple_worried.svg')
export const redHealthy =  require('../assets/red_healthy.svg')
export const redHappy = require('../assets/red_happy.svg')
export const redWeak = require('../assets/red_weak.svg')
export const redWorried =  require('../assets/red_worried.svg')
export const ghostlyAvatar = require('../assets/ghostly.svg')
export const orangeHealthy =  require('../assets/orange_healthy.svg')
export const orangeHappy = require('../assets/orange_happy.svg')
export const orangeWeak = require('../assets/orange_weak.svg')
export const orangeWorried =  require('../assets/orange_worried.svg')
export const greenHealthy =  require('../assets/green_healthy.svg')
export const greenHappy = require('../assets/green_happy.svg')
export const greenWeak = require('../assets/green_weak.svg')
export const greenWorried =  require('../assets/green_worried.svg')
export const yellowHealthy =  require('../assets/yellow_healthy.svg')
export const yellowHappy = require('../assets/yellow_happy.svg')
export const yellowWeak = require('../assets/yellow_weak.svg')
export const yellowWorried =  require('../assets/yellow_worried.svg')
export const brownHealthy =  require('../assets/brown_healthy.svg')
export const brownHappy = require('../assets/brown_happy.svg')
export const brownWeak = require('../assets/brown_weak.svg')
export const brownWorried =  require('../assets/brown_worried.svg')
export const loader = require('../assets/loader.svg');
// Customizable Area End