// Customizable Area Start
import { IBlock } from '../../../framework/src/IBlock'
import { BlockComponent } from '../../../framework/src/BlockComponent'
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum'
import { runEngine } from '../../../framework/src/RunEngine'
import { Country } from '../../accountgroups/src/EditAccountController'
import { Message } from '../../../framework/src/Message'
import { sendAPIRequest } from '../../../components/src/utils'

export const configJSON = require('./config')

const DEFAULT_COUNTRY = {
  id: '138',
  name: 'Romania'
}

interface Grade {
  id: string
  type: string
  attributes: {
    id: number
    name: string
    image: string
  }
}

interface S {
  token: string
  selectedGrade: string
  selectedCountry: string
  countriesDisplayList: Array<string>
  countriesData: Array<Country>
  listGrades: Array<Grade>
  isLoading: boolean
}

interface SS {
  id: any
}

export interface Props {
  navigation: any
  t?:any
  id: string
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCountriesId: string = ''
  getGradesByCountryId: string = ''

  constructor(props: Props) {
    super(props)

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      token: '',
      selectedGrade: '',
      selectedCountry: DEFAULT_COUNTRY.name,
      countriesDisplayList: [],
      countriesData: [],
      listGrades: [],
      isLoading: false
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    this.getCountries()
    this.getGradesByCountry(DEFAULT_COUNTRY.id)
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const httpStatus = message.getData(
      getName(MessageEnum.RestAPIResponceHttpStatus)
    )

    this.apiSuccessCallBackController(apiRequestCallId, responseJson, httpStatus)
  }

  apiSuccessCallBackController = (
    apiRequestCallId: string,
    responseJSON: any,
    httpStatus?: number
  ) => {
    const successCallbackMap = {
      [this.getCountriesId]: this.handleGetCountriesResponse,
      [this.getGradesByCountryId]: this.handleGradesByCountryResponse,
    }

    if (apiRequestCallId) {
      const successCallback: (responseJSON: any, httpStatus?: number) => void = successCallbackMap[apiRequestCallId]
      !!successCallback && successCallback(responseJSON, httpStatus)
    }
  }

  handleGradesByCountryResponse = (responseJson: any) => {
    this.setState({
      listGrades: responseJson?.data,
      isLoading: false
    })
  }

  handleGetCountriesResponse = (responseJson: any) => {
    this.setState({
      countriesData: responseJson?.data,
      countriesDisplayList: responseJson?.data?.map(
        (item: Country) => item?.attributes?.name
      ),
    })
  }

  getCountries = () => {
    this.getCountriesId = sendAPIRequest('account_block/countries', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  }

  getGradesByCountry = (countryId: any) => {
    this.setState({isLoading: true})
    this.getGradesByCountryId = sendAPIRequest(
      `bx_block_categories/categories/get_grades_by_country_id?country_id=${countryId}`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
  }
  // Customizable Area End
}
