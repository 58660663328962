// Customizable Area Start
import { BlockComponent } from 'framework/src/BlockComponent'
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum'
import { runEngine } from 'framework/src/RunEngine'
import { IBlock } from 'framework/src/IBlock'
export const configJSON = require('./config')

export interface Props {
  navigation: any
  id: string
}

interface S {
  lessonId: string
  lessonNumber: number
  lessonName: string
  topicName: string
  unitName: string
  trophiesOpen: boolean
  goalsOpen: boolean
  mathOpen: boolean
  drawerSide: boolean
  openDrawer: boolean
  medianOpen: boolean
  showGrade:boolean
  totalLessonCount: number
  totalXP: number
}

interface SS {
  id: any
}

export default class MotivationalPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ]

    this.state = {
      lessonId: '',
      lessonNumber: 0,
      lessonName: '',
      topicName: '',
      unitName: '',
      trophiesOpen: false,
      goalsOpen: false,
      mathOpen: false,
      drawerSide: false,
      openDrawer: false,
      medianOpen: false,
      showGrade:false,
      totalLessonCount: 0,
      totalXP: 0
    }

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async componentDidMount() {
    const lessonId = this.props.navigation.getParam('lessonId')
    const lessonNumber = this.props.navigation.getParam('lessonNumber')
    const lessonName = this.props.navigation.getParam('lessonName') || ''
    const totalLessonCount = this.props.navigation.getParam('totalLessonCount') || '0'
    const totalXP = this.props.navigation.getParam('totalXP') || '0'
    const customLessonNumber = Number(lessonNumber)
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
    this.setState({
      lessonNumber: isNaN(customLessonNumber) ? 0 : customLessonNumber,
      lessonId: lessonId,
      lessonName: lessonName,
      topicName: updatedName.topicName,
      unitName : updatedName.unitName,
      totalLessonCount,
      totalXP
    })
  }

  
}
// Customizable Area End
