import React from "react";
// Customizable Area Start
import { Box, Drawer, Tab, Tabs, Typography, styled, IconButton } from "@material-ui/core";
import {
  buttonDown,
  buttonGrade,
  skipBolt,
  skipchallengesIcon,
  skipTime,
  file,
  skipUp,
  skipDown,
  vectorArrowLeft,
  loader
} from "./assets";
import Statistics from "./Statistics.web";
import LearningReport from "./LearningReport.web";
import AverageGrade from "./AverageGrade.web";
import MedianController,{ItransformedData,Ilabels} from "./MedianController.web";
import ReportDetail from "./ReportDetail.web";
import { withTranslation } from "react-i18next";
import "./Median.css"
// import ReportDetail from "./ReportDetail.web";
// Customizable Area End

class Median extends MedianController {
  // Customizable Area Start

  handleMonthlyData=(transformedData:Array<ItransformedData>,labels:Ilabels)=>{
    const {  medianType, learningData, medianDataList } = this.state;
             return (
               <Box style={{ padding: "20px 0px" }}>
                    <Typography component="div">
                    {this.state.MedianGradeLoading? 
                    <Box className="loaderBox" >
                      <img src={loader} alt="Loading" />
                    </Box>:
                      <>
                    { medianDataList&& <Statistics data={transformedData} />}
                      <AverageGrade
                        type={medianType}
                        endDate={this.state.endDate}
                        dataChart={this.state.dataChart}
                        onClickBack={()=>this.handleClickBack(medianType)}
                        labels={labels.monthly}
                        data-testid="AverageGrade"
                        startDate={this.state.startDate}
                        onClickNext={()=>this.handleClickNext(medianType)}
                      />
                      <LearningReport
                        data-testid="LearningReport"
                        handleViewDetail={this.handleViewDetail}
                        learningData= {learningData}
                      />
                      </>
                    }
                    </Typography>
                  </Box>
    )
  }

      handleYearlyData=(transformedData:Array<ItransformedData>, labels:Ilabels)=>{
        const {  medianType, medianDataList, learningData } = this.state;
        return (
        <Box style={{ padding: "20px 0px" }}>
        <Typography component="div">
        {this.state.MedianGradeLoading?
         <Box className="loaderBox" >
              <img src={loader} alt="Loading" />
              </Box>:
          <>
        { medianDataList && <Statistics data={transformedData} />}
          <AverageGrade
            data-testid="AverageGrade"
            type={medianType}
            dataChart={this.state.dataChart}
            labels={labels.yearly}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onClickBack={()=>this.handleClickBack(medianType)}
            onClickNext={()=>this.handleClickNext(medianType)}
          />
          <LearningReport
            learningData= {learningData}
            data-testid="LearningReport"
            handleViewDetail={this.handleViewDetail}
          />
          </>
          } 
        </Typography>
      </Box>
      )
      }


  handleWeeklyData=(transformedData:Array<ItransformedData> ,labels:Ilabels)=>{
    const { medianDataList, medianType, learningData } = this.state;
    return (
      <Box style={{ padding: "20px 0px" }}>
      <Typography component="div">
        {this.state.MedianGradeLoading?
        <Box className="loaderBox" >
        <img src={loader} alt="Loading" />
      </Box>:
        <>
       { medianDataList&& <Statistics data={transformedData} />}
        <AverageGrade
          type={medianType}
          data-testid="AverageGrade"
          dataChart={this.state.dataChart}
          onClickNext={()=>this.handleClickNext(medianType)}
          labels={labels.weekly}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onClickBack={()=>this.handleClickBack(medianType)}
          
        />
        <LearningReport
          data-testid="LearningReport"   
          handleViewDetail={this.handleViewDetail}
          learningData= {learningData}
        />
        </>
         }
      </Typography>
    </Box>
    )
  }

   renderAverageGrade=()=>{
    if(this.state.average>this.state.previousAverage){
      return <img
      className="median_gradeStat_gradeStatIcon"
      src={skipUp}
      alt="Avg. grade stat"
    />
    }else if(this.state.average<this.state.previousAverage){
      return <img
      className="median_gradeStat_gradeStatIcon"
      src={skipDown}
      alt="Avg. grade stat"
    />
    }
   }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { value, medianType, medianDataList, average,  } = this.state;

  const transformedData = [
    {
      title: "Box 1",
      value:  medianDataList?.statistics_report?.total_xp,
      label: 'XP Earned',
      image: `${skipBolt}`
    },
    {
      title: "Box 2",
      value:   medianDataList?.statistics_report?.completed_lessons,
      label: 'Lessons Completed',
      image: `${file}`
    },
    {
      title: "Box 3",
      value:  medianDataList?.statistics_report?.minutes_spent,
      label: 'Minutes Spent',
      image: `${skipTime}`
    },
    {
      title: "Box 4",
      value:   medianDataList?.statistics_report?.challenge_completed,
      label: 'Challenges Overcome',
      image: `${skipchallengesIcon}`
    }
  ];   
    const labels = {
      weekly: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      monthly: ["W1", "W2", "W3", "W4", "W5"],
      yearly: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ]
    };
  
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box>
        <MedianDrawer
          className={"fullbottom"}
          id="trophiesId"
          open={this.props.stateOpen}
          draggable={!this.state.drawerOpen}
          anchor={this.state.drawerOpen ? "right" : "bottom"}
          onClose={this.props.closeDrawer}
        >
          <MedianDrawerStyle>
            {!this.state.drawerOpen && <img src={buttonDown} />}
            {this.state.detailId ? (
              <ReportDetail 
              data-testid="ReportDetail"
              average={this.state.average}
              TeddyIcon={this.state.avatarHealthIcon}
              previousAverage={this.state.previousAverage}
              loading={this.state.loading} 
              data={this.state.ReportDetailData} 
              tabCategory={this.state.reportCategory}
              handleBack={this.handleBack} 
              startDate={this.state.ReportDetailStartDate}
              endDate={this.state.ReportDetailEndDate}
              dateTime= {this.state.dateTime}
              t={this.props.t}
              />
            ) : (
              <Box style={{ padding: "20px 0px", width: "100%" }}>
                <Box className="median_nav">
                  <IconButton
                    data-testid="btnBack"
                    style={{ marginRight: 16 }}
                    
                    onClick={this.props.closeDrawer}
                  >
                    <img src={vectorArrowLeft} width={16} height={16} />
                  </IconButton>
                  <Box className="median_nav_title">
                    <Box className="median_nav_title_icon">
                      <img src={this.state.avatarHealthIcon} width={40} height={40} className="teddy_image"/>
                    </Box>
                    <Box className="median_nav_title_text">
                      <img src={buttonGrade} width={20} height={20} />
                      <Typography>{this.props.t('Median Grade')}</Typography>
                    </Box>
                  </Box>
                  <Box className="median_gradeStat">
                    <div className="median_gradeStat_medianBox">
                      <div className="median_gradeStat_medianNumber">{average}</div>
                      {this.renderAverageGrade()}
                    </div>
                  </Box>
                </Box>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                  className="tabs"
                  data-test-id="handleTypes"
                >
                  <Tab className="tab_text" label={this.props.t('Weekly') } />
                  <Tab className="tab_text" label={this.props.t('Monthly')} />
                  <Tab className="tab_text" label={this.props.t('Yearly')} />
                </Tabs>
                <div role="tabpanel" className="tabpanel" hidden={medianType !== "weekly"}>
                  {medianType === "weekly" && this.handleWeeklyData(transformedData,labels)}
                </div>
                <div role="tabpanel" className="tabpanel" hidden={medianType !== "monthly"}>
                  {medianType === "monthly" && this.handleMonthlyData(transformedData,labels)}
                </div>
                <div role="tabpanel" className="tabpanel" hidden={medianType !== "yearly"}>
                  {medianType === "yearly" && this.handleYearlyData(transformedData,labels)}
                </div>
              </Box>
            )}
          </MedianDrawerStyle>
        </MedianDrawer>
      </Box>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export default withTranslation()(Median)
const MedianDrawerStyle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundSize: "cover",
  backgroundRepeat: "none",
  height: "auto",
  "& .teddy_image":{
    borderRadius:'50%',
    marginTop:"-3.5px",
    marginLeft:'-2px'
  },
  "& .gradeStat": {
    display: "flex",
    marginTop: 20
  },

  "& .median_nav": {
    display: "flex",
    height: "50px",
    justifyContent: "center",
    width: "100%",
    marginTop: 16,
    marginBottom: 28,
  },
  "& .median_nav button": {
    padding: "4px"
  },

  "& .median_nav_title": {
    display: "flex",
    alignItems: "center",
    background: "#E6E6E6",
    flexDirection: "row",
    padding: "8px",
    borderRadius: "40px 10px 10px 40px"
  },
  "& .median_nav_title_text": {
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    margin: "0 8px"
  },
  "& .median_nav_title_text .MuiTypography-body1": {
    fontWeight: 900,
    fontFamily: "Nunito",
    fontSize: "18px",
    marginLeft:'4px',
    marginTop:'1px',
  },

  "& .tab_text > .MuiTab-wrapper": {
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform:"none",
    lineHeight: "13px",

  },

  "& .median_gradeStat_gradeStatIcon":{
    marginLeft:'8px'
  },

  "& .tabs > .MuiTabs-scroller > .MuiTabs-flexContainer > .Mui-selected":{
    color:"var(--Primary-Blue-Blue-Primary, #62CDFF) !important"
  },

  "& .tabs > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiTab-textColorPrimary":{
   color: "var(--Primary-Neutrals-Warm-gray-200, #CCC)"
  },

  "& .tabs > .MuiTabs-scroller > .MuiTabs-indicator":{
  background: "var(--Primary-Blue-Blue-Primary-Light, #C9EEFF) !important",
  height:'3px'
  },

  "& .median_gradeStat_medianBox": {
    display: "flex",
    borderRadius: "10px",
    opacity: 0.9,
    marginLeft: "5px",
    alignItems: "center",
    justifyContent:'center',
    background: "#A9D6F6",
    height: "100%",
    padding: "20px 16px"
  },
  "& .median_gradeStat_medianNumber": {
    color: "#0084E2",
    fontWeight: 900,
    fontSize: "18px",
    fontFamily: "Nunito",
  },
  "& .gradeStat .gradeStatIcon": {
    width: "20px"
  },
  "& .medianBox": {
    height: "64px",
    borderRadius: "10px",
    opacity: 0.9,
    background: "var(--primary-blue-blue-primary-light, #A9D6F6)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 16px 20px auto",
    padding: "12px"
  },

  "& .loaderBox":{
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%'},

  "& .tabpanel": {
    display: "flex",
    justifyContent: "center"
  },
  "& .medianNumber": {
    color: "var(--primary-blue-dark-gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Nunito",
    fontSize: "45px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "21px",
    margin: "0 0 0 10px"
  },

  [theme.breakpoints.up("md")]: {
    height: "100vh"
  }
}));

const MedianDrawer = styled(Drawer)(({ theme }) => ({
  inset: 'unset !important'
}));
// Customizable Area End
