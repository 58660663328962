// Customizable Area Start
import React from 'react';
import { Box, Typography, InputAdornment, IconButton, OutlinedInput, Button, Container, } from '@material-ui/core';
import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController.web";
//@ts-ignore
import Google from "../../../components/src/GoogleLogin";
import { styled } from "@material-ui/styles";
import FormControl from '@material-ui/core/FormControl';
import Visibility from "@material-ui/icons/Visibility";
import { Formik, FormikTouched, FormikErrors } from 'formik';
import * as Yup from 'yup';
import CheckIcon from '@material-ui/icons/Check';
import { UserIcon, emailIcon, passwordInvisible } from './assets';
class EmailAccountRegistrationWeb extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    SignupSchema = () => {
        return Yup.object().shape({
            userName: Yup.string()
                .required('User Name Required'),
            email: Yup.string().email('Invalid email').required('Email Address Required'),
            password: Yup.string()
                .required('Password Required')
                .min(8, 'Password must be 8 characters long')
                .matches(/\d/, 'Password requires a number')
                .matches(/[a-z]/, 'Password requires a lowercase letter')
                .matches(/[A-Z]/, 'Password requires an uppercase letter')
                .matches(/[^\w]/, 'Password requires a symbol'),
            confirmPassword: Yup
                .string()
                .oneOf([Yup.ref('password'), null], 'Must match "password" field value')
                .required("Confirm Password Required"),
            checked: Yup.string()
                .required('Required Field'),
        })
    };

    showError = (touched: FormikTouched<{ userName: string; email: string; password: string; confirmPassword: string; checked: string; }>, errors: FormikErrors<{ userName: string; email: string; password: string; confirmPassword: string; checked: string; }>, value: string) => {
        return errors[value as keyof typeof errors] && touched[value as keyof typeof touched] && <Typography className="text-danger">{errors[value as keyof typeof errors]}</Typography>
    }
    createSignUpPage = () => {
        const initialValuesCreate ={
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            checked: ''
        }
        return (
             <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px",
            }}
          >
                <Typography>
                    <Typography>
                        <h1 className='title'>tedix</h1>
                    </Typography>
                    <Typography className='sub-title'>
                        Create an account
                    </Typography>
                    <Typography className='sub-title1'>
                        Get ready to have fun with math
                    </Typography>
                </Typography>
                {!this.state.isSubmitButtonClicked ? 
                <Formik
                    initialValues={initialValuesCreate}
                    validationSchema={this.SignupSchema()}
                    onSubmit={values => {
                        this.submitFormData(values)
                    }}
                >
                    {({ errors, touched, handleSubmit, handleChange }) => (

                        <form
                            onSubmit={handleSubmit}
                            autoComplete="off"
                            style={{minWidth:'65%'}}
                        >
                            <Box className='signUp'>
                                <FormControl>
                                    <OutlinedInput className='input-field' name='userName' placeholder='Enter Username'
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="userIcon"
                                                    edge="end"
                                                >
                                                    <img src={UserIcon} alt='userIcon' />

                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    {this.showError(touched, errors, 'userName')}
                                </FormControl>
                                
                                <FormControl>
                                    <OutlinedInput className='input-field' name='email' placeholder='Enter Email'
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="emailIcon"
                                                    edge="end"
                                                >
                                                    <img src={emailIcon} alt='emailIcon' />
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    {this.showError(touched, errors, 'email')}
                                </FormControl>

                                <FormControl>
                                    <OutlinedInput className='input-field' name='password' placeholder='Password'
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        onChange={handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                    id='password-visibility-button'
                                                    onClick={() => this.handleClickShowPassword()}
                                                >
                                                    {this.state.showPassword ? <img src={passwordInvisible} alt='emailIcon' /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>

                                        } />
                                    {this.showError(touched, errors, 'password')}
                                </FormControl>

                                <FormControl>
                                    <OutlinedInput className='input-field' name='confirmPassword' placeholder='Confirm Password'
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        onChange={handleChange}

                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    id='confirmPassword-visibility-button'
                                                    edge="end"
                                                    onClick={() => this.handleClickShowConfirmPassword()}
                                                >
                                                    {this.state.showConfirmPassword ? <img src={passwordInvisible} alt='emailIcon' /> : <Visibility />}

                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    {this.showError(touched, errors, 'confirmPassword')}
                                </FormControl>
                                <Typography style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography style={{ display: 'flex' }}>
                                        <Typography style={{ padding: '0px 5px' }}>
                                            <input id='check-id' type='checkbox' name='checked' onChange={handleChange} style={{ display: 'none' }} />
                                            <div className='box' onClick={() => this.handleChecked()}>
                                                {this.state.check && <CheckIcon className='check' />}
                                            </div>
                                        </Typography>
                                        <Typography className='term-condition'>
                                            By signing up you are agreeing <br />our <span className='blue-text'>Term and privacy policy</span>
                                        </Typography>
                                    </Typography>
                                    {this.showError(touched, errors, 'checked')}
                                    <Typography>
                                        <Button type='submit' variant='contained' className='account-btn'>Create Account</Button>
                                    </Typography>
                                    <Typography className='continue-text'>
                                        Or Continue With
                                    </Typography>
                                    <Typography className='google-btn'>
                                        <Google handleGoogleLogin={function (res?: string | undefined): void {
                                            throw new Error('Function not implemented.');
                                        }} />
                                    </Typography>
                                </Typography>
                            </Box>
                        </form>
                    )}
                </Formik> :
                    <>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box className='signUp'>
                                <Typography className='great-text'>
                                    Great!
                                </Typography >
                                <Typography className='experience-rewards'>
                                    Now, let’s get you set up for your personal learning experience and rewards!
                                </Typography>
                                
                            </Box>
                        </Box>
                        <Typography style={{ textAlign: 'center' }}>
                                    <Button variant='contained' className='continue-btn' onClick={() => this.setState({ isSubmitButtonClicked: true })}>Continue</Button>
                                </Typography>
                    </>}
                    </Box>
                    </Container>
        );
    }

    render() {
        return (
            <EmailAccountRegistrationStyle>
                {this.createSignUpPage()}
            </EmailAccountRegistrationStyle>
        );
    }
}
export default EmailAccountRegistrationWeb;

const EmailAccountRegistrationStyle = styled("div")({
    width: '100%',

    "& .title": {
        color: '#0E1A4F',
        textAlign: 'center',

    },
    "& .sub-title": {
        color: '#6B5E5E',
        textAlign: 'center',
        fontFamily: 'Quicksand',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px'
    },
    "& .sub-title1": {
        color: '#6B5E5E',
        textAlign: 'center',
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '24px'
    },
    "& .input-field": {
        backgroundColor: '#D9D9D9',
        margin: '10px 0'
    },
    "& .signUp": {
        display: 'flex',
        flexDirection: 'column',
    },
    "& .text-danger": {
        color: 'red'
    },
    "& .account-btn": {
        bordeRadius: '5px',
        background: '#D9D9D9',
        boxShadow: '0px 4px 2px 0px rgba(0, 0, 0, 0.25)',
        color: '#000',
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '16px',
        margin: '25px 0 10px 0',
        width: '171px',
        height: '45px',
        padding: '0px',
        textTransform: 'none'
    },
    "& .term-condition": {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
        color: '#6B5E5E'
    },
    "& .blue-text": {
        color: '#0386D0',
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
    },
    "& .continue-text": {
        fontFamily: 'Quicksand',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '24px',
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0 10px 0',

    },
    "& .google-btn": {
        margin: '10px 0 10px 0',
        borderRadius: '5px',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    },
    "& .box": {
        border: '2px solid black',
        borderRadius: '30%',
        width: '16px',
        height: '16px',
        position: 'relative',
    },
    "& .check": {
        position: 'absolute',
        top: '-6px',
        left: '0px',
        width: '16px',
        height: '28px',
    },
    "& .great-text": {
        color: "#6B5E5E",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        margin: '70px 0 10px 0'
    },
    "& .experience-rewards": {
        color: "#6B5E5E",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0px 0 70px 0'
    },
    "& .continue-btn": {
        bordeRadius: '5px',
        background: '#D9D9D9',
        boxShadow: '0px 4px 2px 0px rgba(0, 0, 0, 0.25)',
        color: '#000',
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '16px',
        margin: '25px 0 10px 0',
        width: '171px',
        height: '45px',
        padding: '0px',
        textTransform: 'none'
    },
})
// Customizable Area End