export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const UserIcon = require("../assets/Vector.svg");
export const emailIcon = require("../assets/Email.svg");
export const passwordInvisible = require("../assets/password_invisible.svg");
export const passwordVisible = require("../assets/password_visible.svg");
export const skipCategory=require("../assets/skip-category.svg");
export const lockIcon =require('../assets/lock-icon.svg')
export const tedixIcon =require('../assets/tedix-icon.svg')
export const viewLine =require('../assets/viewLine.svg')
export const viewLineDark =require('../assets/viewLineDark.svg')
export const searchIcon =require('../assets/search-icon.svg')
export const ArrowUpIcon =require('../assets/ArrowUp-icon.svg')
export const ArrowDownIcon =require('../assets/ArrowDown-icon.svg')
export const checkIcon =require('../assets/check-icon.svg')
export const warningIcon =require('../assets/warning.svg')
export const checkedIcon =require('../assets/checkedIcon.svg')
export const pandaSuccess =require('../assets/pandaSuccess.svg')
export const pandaIcon =require('../assets/pandaIcon.svg')
export const tedixBlackIcon =require('../assets/tedixBlackIcon.svg')
export const pandaTedix =require('../assets/pandaTedix-icon.svg')
export const EnglishFlag =require('../assets/English_Flag.png')
export const GermanFlag =require('../assets/German_Flag.png')
export const HungarianFlag =require('../assets/Hungarian_Flag.png')
export const RomanianFlag =require('../assets/Romanian_Flag.png')
export const Loader =require('../assets/Loader.svg')
export const imgGroupMail = require("../assets/group_mail.png");
export const sucessBackgroung = require("../assets/sucess.svg");
export const GoogleLogo =require('../assets/image_google.svg')
export const imgSignUpActivate =require('../assets/sign_up_activate.svg')