import React from 'react'
import { Box, List, ListItem, ListItemIcon, styled } from '@material-ui/core'
import {
  imgHomeMenu,
  imgProfileMenu,
  imgSettingMenu,
  imgHomeMenuActive,
  imgLeaderBoardMenuActive,
  imgLeaderBoardMenu,
  imgProfileMenuActive,
  imgSettingMenuActive,
  imgMenuMenuActive,
  imgMenuMenu,
} from './assets'
import "./I18nextConfig/i18n"
import { withRouter } from 'react-router-dom'
import  {useTranslation} from "react-i18next"

const BottomMenu = (props: any) => {
  const  { t } = useTranslation()
  const bottomMenu = [
    {
      title: t('Home'),
      icon: (
        <img
          src={props.menuName === 'Home' ? imgHomeMenuActive : imgHomeMenu}
          width={24}
          height="auto"
        />
      ),
      navigate: 'LandingPageWeb',
    },
    {
      title: t('Leaderboard'),
      icon: (
        <img
          src={
            props.menuName === 'Leaderboard'
              ? imgLeaderBoardMenuActive
              : imgLeaderBoardMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'Leaderboard',
    },
    {
      title: t('Menu'),
      icon: (
        <img
          src={
            props.menuName === 'Menu'
              ? imgMenuMenuActive
              : imgMenuMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'mainMenuWebPage',
    },
    {
      title: t('Profile'),
      icon: (
        <img
          src={
            props.menuName === 'Profile' ? imgProfileMenuActive : imgProfileMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'CustomisableUserProfiles',
    },
    {
      title: t('Settings'),
      icon: (
        <img
          src={
            props.menuName === 'Settings'
              ? imgSettingMenuActive
              : imgSettingMenu
          }
          width={24}
          height="auto"
        />
      ),
      navigate: 'Settings2',
    },
  ]

  return (
    <ActionIconsContainerMobile>
      <MyList>
        {bottomMenu.map((item) => (
          <ListItem
            key={item.title}
            button
            className="actionButton"
            onClick={() => {
              item.navigate && props.history.push(`/${item.navigate}`)
            }}
          >
            <ListItemIcon className="actionButton">{item.icon}</ListItemIcon>
          </ListItem>
        ))}
      </MyList>
    </ActionIconsContainerMobile>
  )
}

export const ActionIconsContainerMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  background: 'white',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  alignItems: 'center',
  zIndex: 99,
  borderRadius: '10px 10px 0 0',
  boxShadow: '0px -2px 12px rgba(0, 0, 0, 0.2)',

  '& .actionButton': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .MuiList-root': {
    paddingTop: 0,
    paddingBottom: 0,
  },
  '& .MuiButtonBase-root': {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '0',
    paddingRight: '0',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '16px',
      paddingRight: '16px'
      }
  },
}))

export const MyList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexGrow: 3,
  justifyContent: 'space-evenly',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
  
    }
}))

export default withRouter(BottomMenu)
