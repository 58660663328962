import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
  // Customizable Area End
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  t?:any;
  navigation: {
    navigate: (screenName: string) => void;
  };
  heading: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start

  selectNotification: boolean;
  openNotificationMenu: boolean;
  openDeleteModal: boolean;
  cardData: Array<ResDataItem>;
  selectedNotifications: Array<number>;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  openDrawer: boolean;
  topicName: string;
  unitName: string;
  apiErrorMessage: string;
  deleteModalMessage: string;
  showNotificationDescription: boolean;
  unreadNotifications:boolean
  showNotificationDescriptionId: number;
  token: string | unknown;

  // Customizable Area End
}

  // Customizable Area Start
export interface OnChangeEventType {
  target: {
    value: number;
  };
}

export interface GetAllNotificationResponse {
  data: Array<ResDataItem>;
  meta: {
    message: string;
  }
}

export interface MarkAsReadSelectedNotificationsResponse {
  message: string;
}

export interface ResDataItem {
  id: number;
  type: string;
  attributes: {
    id: number,
    status: string,
    subject: string,
    content: string,
    user_name: string,
    content_as_plain_text: string,
    only_informative: boolean,
    image: string,
    is_button: false,
    button_url: string,
    button_content: string,
    time_ago: string
  };
}

export interface ApidataResponseMock{
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string
}
// Customizable Area End

interface SS {
  id: string;
}
export default class InAppNotifiocationMainController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inAppGetAllNotificationsApiCallId: string = "";
  inAppMarkAsReadSelectedNotificationApiCallId: string = "";
  inAppDeleteSelectedNotificationApiCallId: string = "";
  inAppMarkAsAllReadApiCallId: string = "";
  inAppDeleteAllApiCallId: string = "";
  inAppMarkAsReadSingleNotificationApiCallId: string = "";
  inAppDeleteSingleNotificationApiCallId: string = "";
  inAppUnreadNotificationApiCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      selectNotification: false,
      openNotificationMenu: false,
      openDeleteModal: false,
      cardData: [],
      unreadNotifications:false,
      trophiesOpen: false,
      goalsOpen: false,
      openDrawer: false,
      topicName: "Welcome to Tedix",
      unitName: "Let's explore the world of knowledge",
      selectedNotifications: [],
      apiErrorMessage: "",
      deleteModalMessage: "",
      showNotificationDescription: false,
      showNotificationDescriptionId: 0,
      token: ""
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  // Customizable Area Start
 
  async componentDidMount() {
    let updatedNameUnit = localStorage.getItem('updatedName');
    if (updatedNameUnit !== null) {
    let updatedName = JSON.parse(updatedNameUnit);
    this.setState({ unitName: updatedName.unitName, topicName: updatedName.topicName });
    }
    this.inAppGetAllNotifications();
    
  }

  mockApiCall = async (data: ApidataResponseMock) => {
    const { contentType, method, endPoint, body, type } = data;
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.inAppResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.inAppResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  inAppResponseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetAllNotificationsApiCallId) {
      this.inAppGetAllNotificationsSucessCallBack(responseJson);
    }
    if (
      apiRequestCallId === this.inAppMarkAsReadSelectedNotificationApiCallId
    ) {
      this.inAppMarkAsReadSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSelectedNotificationApiCallId) {
      this.inAppDeleteSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppMarkAsAllReadApiCallId) {
      this.inAppMarkAsAllReadSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteAllApiCallId) {
      this.inAppDeleteAllSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppMarkAsReadSingleNotificationApiCallId) {
      this.inAppMarkAsReadSingleNotificationSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSingleNotificationApiCallId) {
      this.inAppSwipeDeleteSingleNotificationSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppUnreadNotificationApiCallId) {
      this.inAppUnreadNotificationSucessCallBack(responseJson);
    }
  };

  inAppResponseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetAllNotificationsApiCallId) {
      this.inAppGetAllNotificationsFailureCallBack(responseJson);
    }
    if (
      apiRequestCallId === this.inAppMarkAsReadSelectedNotificationApiCallId
    ) {
      this.inAppMarkAsReadFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSelectedNotificationApiCallId) {
      this.inAppDeleteFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppMarkAsAllReadApiCallId) {
      this.inAppMarkAsAllReadFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteAllApiCallId) {
      this.inAppDeleteAllFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppMarkAsReadSingleNotificationApiCallId) {
      this.inAppMarkAsReadSingleNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteSingleNotificationApiCallId) {
      this.inAppSwipeDeleteSingleNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppUnreadNotificationApiCallId) {
      this.inAppUnreadNotificationFailureCallBack(responseJson);
    }
  };

  inAppGetAllNotifications = async () => {
    this.inAppGetAllNotificationsApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: configJSON.getAllNotificationsEndPoint,
    });
  };

  inAppGetAllNotificationsSucessCallBack = (
    res: GetAllNotificationResponse
  ) => {
    const unread = res.data.filter((item:ResDataItem)=> item.attributes.status==="unread")
    this.setState({ 
      cardData: res.data , 
      unreadNotifications: unread.length>0?true:false });
  };

  inAppGetAllNotificationsFailureCallBack = (
    res: GetAllNotificationResponse
  ) => {
    
    this.setState({apiErrorMessage:"Something went wrong"});
  };

  inAppMarkAsReadSelectedNotification = async () => {
    if(this.state.selectedNotifications.length > 0){
    let payload = {
      notifications: this.state.selectedNotifications,
      status: configJSON.statusRead,
    };
    this.inAppMarkAsReadSelectedNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload,
    });
  }
  
  };

  inAppMarkAsReadSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState(
      { selectNotification: false, selectedNotifications: [] },
      () => {
        this.inAppGetAllNotifications();
      }
    );
  };

  inAppMarkAsReadFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppDeleteSelectedNotification = async () => {
    let payload = {
      notifications: this.state.selectedNotifications,
      status: configJSON.statusDeleted,
    };
    this.inAppDeleteSelectedNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload,
    });
  };

  inAppDeleteSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({ openDeleteModal: false, selectNotification: false }, () => {
      this.inAppGetAllNotifications();
    });
  };

  inAppDeleteFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppMarkAsAllRead = async () => {
    let allIds = this.state.cardData.map((item: ResDataItem) => {
      return item.id;
    });
    this.setState({ selectedNotifications: allIds }, async () => {
      let payload = {
        notifications: this.state.selectedNotifications,
        status: configJSON.statusRead,
      };
      this.inAppMarkAsAllReadApiCallId = await this.mockApiCall({
        contentType: configJSON.contentType,
        method: configJSON.postMethod,
        endPoint: configJSON.updateNoticationEndPoint,
        body: payload,
      });
    });
  };

  inAppMarkAsAllReadSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({ openNotificationMenu: false }, () => {
      this.inAppGetAllNotifications();
    });
  };

  inAppMarkAsAllReadFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppDeleteAllRead = async () => {
    let allIds = this.state.cardData.map((item: ResDataItem) => {
      return item.id;
    });
    this.inAppDeleteAllApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body:  {
        notifications: allIds,
        status: configJSON.statusDeleted,
      },
    });
  };

  inAppDeleteAllSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({ openNotificationMenu: false, cardData: [], openDeleteModal: false });
  };

  inAppDeleteAllFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppMarkAsReadSingleNotification = async (id: number) => {
    this.inAppMarkAsReadSingleNotificationApiCallId =
      await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint:configJSON.updateNoticationEndPoint,
      body: {
        notifications: [id],
        status: configJSON.statusRead,
      },
    });
  };

  inAppMarkAsReadSingleNotificationSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({ openNotificationMenu: false, selectedNotifications: [] }, () => {
      this.inAppGetAllNotifications();
    });
  };

  inAppMarkAsReadSingleNotificationFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message})
  };

  inAppSwipeDeleteSingleNotification = async (id: number) => {
    this.state.selectedNotifications.push(id);
    this.setState(
      { selectedNotifications: this.state.selectedNotifications },
      async () => {
        let payload = {
          notifications: this.state.selectedNotifications,
          status: configJSON.statusDeleted,
        };
        this.inAppDeleteSingleNotificationApiCallId = await this.mockApiCall({
          contentType: configJSON.contentType,
          method: configJSON.postMethod,
          endPoint: configJSON.updateNoticationEndPoint,
          body: payload,
        });
      }
    );
  };

  inAppSwipeDeleteSingleNotificationSucessCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({ openNotificationMenu: false,selectedNotifications:[] }, () => {
      this.inAppGetAllNotifications();
    });
  };

  inAppSwipeDeleteSingleNotificationFailureCallBack = (
    res: MarkAsReadSelectedNotificationsResponse
  ) => {
    this.setState({apiErrorMessage:res.message});
  };

  inAppUnreadNotificationNotifications = async (id: number) => {
    let payload = {
      notifications: [id],
      status: configJSON.statusUnread,
    };
    this.inAppUnreadNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload
    });
  };

  inAppUnreadNotificationSucessCallBack = (responseJson:MarkAsReadSelectedNotificationsResponse) => {
    this.setState({ openNotificationMenu: false, selectedNotifications: [] }, () => {
      this.inAppGetAllNotifications();
    });
  };

  inAppUnreadNotificationFailureCallBack = (response: MarkAsReadSelectedNotificationsResponse) => {
    this.setState({apiErrorMessage:response.message});
  };

  openNotification = (id: number) => {
    const to = new Message(getName(MessageEnum.NavigationNotificationsMessage));
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    to.addData(getName(MessageEnum.NotificationsPropsMessage), id);
    this.send(to);
  };

  openDeletedNotification = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage),"InAppDeleteNotification");
    to.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(to);
  };

  

  

  handleOpenDeleteModal = (message:string) => {
    if(message === "selectedNotifications"){
    this.setState({ openDeleteModal: this.state.selectedNotifications.length > 0 ? true : false,deleteModalMessage:message });
    }
    else {
    this.setState({ openDeleteModal: true, deleteModalMessage:message });
    }
  };

  handleDeleteModalApiCall = async() => {
    if(this.state.deleteModalMessage === "deleteAll"){
      await this.inAppDeleteAllRead()
    }else if(this.state.deleteModalMessage === "selectedNotifications"){
      await this.inAppDeleteSelectedNotification()
    }
  }

  handleOpenNotificationMainMenu = () => {
    this.setState({ openNotificationMenu: !this.state.openNotificationMenu });
  };

  handleselectNotifications = () => {
    this.setState({
      openNotificationMenu: !this.state.openNotificationMenu,
      selectNotification: !this.state.selectNotification,
    });
  };

  handleCancelSelectNotification = () => {
    this.setState({ selectNotification: false });
  };

  handleModalDeleteNotificationBtn = () => {
    this.setState({ openDeleteModal: false });
  };

  handleOpenDrawer = (type: string) => {
    if (type === "trophi") {
      this.setState({ trophiesOpen: true, openDrawer: true });
    } else if (type === "goal") {
      this.setState({ goalsOpen: true, openDrawer: true });
    }
  };

  handleCheckboxChange = (id: number) => {
    if (this.state.selectedNotifications.includes(id)) {
      this.setState({
        selectedNotifications: this.state.selectedNotifications.filter(
          (checkedId) => checkedId !== id
        ),
      });
    } else {
      this.setState({
        selectedNotifications: [...this.state.selectedNotifications, id],
      });
    }
  };

  toggleDescriptionMore = (id:number) => {
    this.setState({ showNotificationDescription : true,showNotificationDescriptionId :id })
  };

  toggleDescriptionHide = () => {
    this.setState({ showNotificationDescription : false,showNotificationDescriptionId :0 })
  };
  // Customizable Area End
}
