import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
  
import { Message } from "../../../framework/src/Message";
export interface ApidataResponseMock{
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface GetNotificationDetailsResponse {
  data: {
      id: string,
      type: string,
      attributes: {
          id: number,
          status: string,
          subject: string,
          content: string,
          user_name: string,
          image: string,
          is_button: boolean,
          button_url: string,
          button_content: string,
          time_ago: string
      }
  },
  meta: {
      message: string;
  }
}

export interface NotificationDetailsMock{
            id: string,
            type: string,
            attributes: {
                id: number,
                status: string,
                subject: string,
                content: string,
                user_name: string,
                image: string,
                is_button: boolean,
                button_url: string,
                button_content: string,
                time_ago: string
            }
}

export interface ApiSucessResponseMock {
  message: string;
}

export interface ErrorNotificationsResponse{
  message:string;
}
  // Customizable Area End
export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: (screenName: string) => void;
  };
  t?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openNotificationMenu: boolean;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  openDrawer: boolean;
  topicName: string;
  unitName: string;
  notificationDetails: NotificationDetailsMock;
  apiErrorMessage: ErrorNotificationsResponse;
  apiSuccesMessage: ErrorNotificationsResponse;
  notificationId: number;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class InAppNotificationDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inAppGetNotificationsDetailsApiCallId : string = "";
  inAppReadNotificationApiCallId : string = "";
  inAppUnreadNotificationApiCallId : string = "";
  inAppDeleteNotificationApiCallId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationInfoPageMessage),
      getName(MessageEnum.NotificationsPropsMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      openNotificationMenu: false,
      trophiesOpen: false,
      goalsOpen: false,
      openDrawer: false,
      topicName: "Welcome to Tedix",
      unitName: "Let's explore the world of knowledge",
      notificationDetails: {     
            id: "",
            type: "",
            attributes: {
                id: 0,
                status: "",
                subject: "",
                content: "",
                user_name: "",
                image: "",
                is_button: false,
                button_url: "",
                button_content: "",
                time_ago: ""
            }
      },
      apiErrorMessage: {
        message:""
      },
      apiSuccesMessage:{
        message:""
      },
      notificationId: 0
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    let updatedNameUnit = localStorage.getItem('updatedName');
    if (updatedNameUnit !== null) {
    let updatedName = JSON.parse(updatedNameUnit);
    this.setState({ unitName: updatedName.unitName, topicName: updatedName.topicName });
    }
    this.inAppGetNotificationDetails();
  }

  mockApiCall = async (data: ApidataResponseMock) => {
    const { contentType, method, endPoint, body, type } = data;
    let token=localStorage.getItem('token')
    
    const header = {
      "Content-Type": contentType,
        token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    ); 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    body && type === "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let NavigationInfoPageMessage = message.getData(
      getName(MessageEnum.NavigationInfoPageMessage)
    );

    if(NavigationInfoPageMessage !== undefined){
      this.setState({
        notificationId: NavigationInfoPageMessage
      });
    }

    else if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
     let data = message.getData(getName(MessageEnum.NotificationsPropsMessage));
     this.setState({notificationId:parseInt(data)},()=>{
      this.inAppGetNotificationDetails();
      this.inAppReadNotificationNotifications()
    })
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && responseJson.errors) {
        this.notificationDetailsResponseFailureCall(apiRequestCallId, responseJson);
      }
      else if (responseJson && !responseJson.errors) {
        this.notificationDetailsResponseSucessCell(apiRequestCallId, responseJson);
      }
    }
  }

  notificationDetailsResponseSucessCell = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetNotificationsDetailsApiCallId) {
      this.inAppGetNotificationsDetailsSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppUnreadNotificationApiCallId) {
      this.inAppUnreadNotificationSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteNotificationApiCallId) {
      this.inAppDeleteNotificationSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppReadNotificationApiCallId) {
      this.inAppReadNotificationSucessCallBack(responseJson);
    }
  };

  notificationDetailsResponseFailureCall = async (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.inAppGetNotificationsDetailsApiCallId) {
      this.inAppGetAllDeletedNotificationsFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppUnreadNotificationApiCallId) {
      this.inAppUnreadNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppDeleteNotificationApiCallId) {
      this.inAppDeleteNotificationFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.inAppReadNotificationApiCallId) {
      this.inAppReadNotificationFailureCallBack(responseJson);
    }
  };

  inAppGetNotificationDetails = async () => {
    this.inAppGetNotificationsDetailsApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.getMethod,
      endPoint: `${configJSON.getNotificationDetailsEndPoint}/${this.state.notificationId}`,
    });
  };

  inAppGetNotificationsDetailsSucessCallBack = ( response: GetNotificationDetailsResponse ) => {
    this.setState({notificationDetails: response.data})    
  };

  inAppGetAllDeletedNotificationsFailureCallBack = (response: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:response});    
  };

  inAppReadNotificationNotifications = async () => {
    let payload = {
      notifications: [this.state.notificationId],
      status: configJSON.statusRead,
    };
    this.inAppReadNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload
    });
  };

  inAppReadNotificationSucessCallBack = (responseJson:ApiSucessResponseMock) => {
    this.setState({apiSuccesMessage:responseJson});
  };

  inAppReadNotificationFailureCallBack = (response: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:response});
  };

  inAppUnreadNotificationNotifications = async () => {
    let payload = {
      notifications: [this.state.notificationId],
      status: configJSON.statusUnread,
    };
    this.inAppUnreadNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload
    });
  };

  inAppUnreadNotificationSucessCallBack = (responseJson:ApiSucessResponseMock) => {
    this.openDeletedNotification()
  };

  inAppUnreadNotificationFailureCallBack = (response: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:response});
  };

  inAppDeleteNotifications = async () => {
    let payload = {
      notifications: [this.state.notificationId],
      status: configJSON.statusDeleted,
    };
    this.inAppDeleteNotificationApiCallId = await this.mockApiCall({
      contentType: configJSON.contentType,
      method: configJSON.postMethod,
      endPoint: configJSON.updateNoticationEndPoint,
      body: payload
    });
  };

  inAppDeleteNotificationSucessCallBack = (response: ApiSucessResponseMock) => {
    this.openDeletedNotification()
  };

  inAppDeleteNotificationFailureCallBack = (response: ErrorNotificationsResponse) => {
    this.setState({apiErrorMessage:response});
  };

  handleOpenNotificationMenu = () => {
    this.setState({ openNotificationMenu: !this.state.openNotificationMenu });
  };

  handleOpenDrawer = (type: string) => {
    if (type === "trophi") {
      this.setState({ trophiesOpen: true, openDrawer: true });
    }
    else if (type === "goal") {
      this.setState({ goalsOpen: true, openDrawer: true });
    }
  };

  openDeletedNotification = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage),"InAppNotification");
    to.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(to);
  };

  // Customizable Area End
}
