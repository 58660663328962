import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ReactPlayer from "react-player";
import { sendAPIRequest } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  t?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showVideoControls: boolean;
  VideoList: any;
  lessonId: any;
  parentId: string
  lessonNumber: number,
  topicName : string;
  unitName : string;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  mathOpen: boolean;
  drawerSide: boolean;
  openDrawer: boolean;
  medianOpen: boolean;
  showGrade:boolean;
  lessonName:string;
  totalLessonCount: number;
  progress: number;
  totalXP: number;
  loading: boolean;
  unitNumber: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CftableforlessonsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private player: ReactPlayer | null = null;
  getVideoDetailsId: string = "";
  getSpecificUserAccountId: any = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showVideoControls: false,
      VideoList: {},
      lessonId: "",
      parentId: '',
      lessonNumber: 0,
      topicName:'',
      unitName:'',
      mathOpen: false,
      trophiesOpen: false,
      goalsOpen: false,
      drawerSide: false,
      openDrawer: false,
      medianOpen: false,
      showGrade:false,
      lessonName:'',
      totalLessonCount: 0,
      progress:0,
      totalXP: 0,
      loading: false,
      unitNumber: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getVideoDetailsId) {
       this.receiveGetVideoStatus(responseJson, errorReponse);
      }
       if (apiRequestCallId === this.getSpecificUserAccountId) {
         const userXPPoints = responseJson?.data?.attributes?.total_xp;
        return userXPPoints && this.setState({totalXP: userXPPoints})
       }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    localStorage.getItem('updatedName')
    const token = localStorage.getItem('token') || ''
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
   const lessonId = this.props.navigation.getParam('lessonId');
   const lessonNumber = this.props.navigation.getParam('lessonNumber');
   const lessonName = this.props.navigation.getParam('lessonName');
   const customLessonNumber = Number(lessonNumber)
    this.setState({ lessonNumber: isNaN(customLessonNumber) ? 0 : customLessonNumber, parentId: lessonId , topicName : updatedName.topicName , unitName : updatedName.unitName , lessonName : lessonName, unitNumber: updatedName.unitNumber})
    this.MicrolessonvVideo(lessonId)
    this.getSpecificUserAccount(token)
    localStorage.setItem('unitNumber', updatedName.unitNumber)
  }
  MicrolessonvVideo = async (lessonId: string) => {
    try {
      this.setState({ loading: true });
      let token = await localStorage.getItem("token");
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        "token": token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getVideoDetailsId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.VideoDetailAPiEndPoint}?parent_id=${lessonId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    } catch (error) {
    }
  };
  
  receiveGetVideoStatus = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data) {
      const totalLessonCount = responseJson.data.length || 0;
      const watchedLessonCount = this.state.lessonNumber + 1;
      let progress = 0;
      if (totalLessonCount > 1) {
        progress = (watchedLessonCount / (totalLessonCount - 1)) * 100;
      }
      this.setState({
        VideoList: responseJson.data[this.state.lessonNumber],
        lessonId: responseJson.data[this.state.lessonNumber]?.attributes?.id,
        totalLessonCount: totalLessonCount,
        progress: progress,
      });
      localStorage.setItem('lessonNumber',this.state?.VideoList?.attributes?.lesson_number)
    } else {
      console.log("Error getting video list", errorReponse);
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  getSpecificUserAccount = (token: string) => {
    this.getSpecificUserAccountId = sendAPIRequest(
      'account_block/accounts/specific_account',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }
  
  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'goal') {
      this.setState({ goalsOpen: true, openDrawer: true })
    }
    else if (type === 'median') {
      this.setState({ medianOpen: true, openDrawer: true })
    }
  }

 handleClickBackLandingPage = () => {
  localStorage.setItem('isBackFromMicrolessonVideo', 'true')
  this.props.navigation.navigate('LandingPageWeb')
}
 
  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, mathOpen: false , medianOpen: false });
  };

  toggleVideoControls = () => {
    this.setState((prevState) => ({
      showVideoControls: !prevState.showVideoControls,
    }));
  };
  // Customizable Area End
}
