import React from "react";
// Customizable Area Start

import { styled } from "@material-ui/core/styles";
import { Box, Modal, IconButton, Typography, Button } from '@material-ui/core'
import ChallengeModalController from "./ChallengeModalController.web";
import { algebra, cross_icon, leftBottom, leftMid, leftTop, rightBottom, rightMid, rightTop, roulette1, } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

 class ChallengeModal extends ChallengeModalController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
         const {t} = this.props
        // Customizable Area End
        return (
             // Customizable Area Start
            <SettingContainer
                open={this.props.open}
                onClose={this.props.onClose}
                style={{ zIndex: 2000 }}
                keepMounted
            >
                <Box className="Modal_style">
                    <Box className="Boxcontainer">
                        <Box className="cross-Icon">
                            <IconButton onClick={this.props.onClose}>
                                <img src={cross_icon} />
                            </IconButton>
                        </Box>
                        <Box className="content">
                            <Box className="mid-image">
                                <Box className="leftImage">
                                    <Box><img src={leftTop} className="arrowImage" /></Box>
                                    <Box className="leftMidImage"><img src={leftMid} className="arrowImage" /></Box>
                                    <Box><img src={leftBottom} className="arrowImage" /></Box>
                                </Box>
                                <Box>
                                    <img src={roulette1} height={70} width={69} />
                                </Box>
                                <Box className="rightImage">
                                    <Box><img src={rightTop} className="arrowImage" /></Box>
                                    <Box className="rightMidImage"><img src={rightMid} className="arrowImage" /></Box>
                                    <Box><img src={rightBottom} className="arrowImage" /></Box>
                                </Box>
                            </Box>
                            <Box className="content_title">
                                {t("Your next challenge")}
                            </Box>
                            <Box className="content_description">
                                {t("It's time! The challenge shuffler has chosen your next challenge")}:
                            </Box>
                            <Box>
                                <Box className="topic_Container">
                                    <img src={this.props.Challenge.image||algebra} style={{ zIndex: 1 }} width={40} height={40}/>
                                    <Typography className="topic_title">{this.props.Challenge.name}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="button_postion">
                            <Button variant="contained" data-testid="letsgo-btn" className="btn-action" onClick={()=>this.props.navigation.navigate("ChallengeExercise",{
                                unitId: this.props.Challenge.unit_id,
                                unitName:this.props.Challenge.unit_name,
                                lessonId:this.props.Challenge.id.toString(),
                                lessonName:this.props.Challenge.name,
                                })}>
                                {t("Let’s go!")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </SettingContainer>
            // Customizable Area End
        )
    }

}
// Customizable Area Start
export default withTranslation()(ChallengeModal)
export const SettingContainer = styled(Modal)(({ theme }) => ({

    '& .Modal_style': {
        height: '426px',
        flexShrink: 0,
        top: '50%',
        width: '301px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: '#DCFFD6',
        border: '5px solid #fff',
        borderRadius: '47px',
        position: 'absolute',
    },
   
    '& .cross-Icon': {
        justifyContent: 'flex-end',
        paddingRight: '16px',
        display: 'flex',
        width: '100%',
        paddingTop: '20px',
    },
    '& ,content': {
        textAlign: 'center'
    },

    '& .content_title': {
        marginTop: '24px',
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "17px", /* 94.444% */
        color: ' var(--primary-blue-dark-gray, #363636)',
        textAlign: "center",
        letterSpacing: " 0.144px",
        height: '17px',
        fontFamily: "Nunito",
        fontSize: "18px",
    },
    '& .mid-image': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .arrowImage': {
        padding: 5
    },
    '& .leftMidImage': {
        marginLeft: -13
    },
    '& .rightMidImage': {
        marginRight: -13
    },
    '& .leftImage': {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 1
    },
    '& .content_description': {
        padding: '10px 32px',
        color: 'var(--primary-blue-dark-gray, #363636)',
        textAlign: 'center',
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },
    '& .rightImage': {
        display: 'flex',
        flexDirection: 'column',
    },
    '& .topic_Container': {
        margin: '24px',
        width: '240px',
        borderRadius: "15px",
        display: 'flex',
        padding: '8px 20px 8px 17px',
        alignItems: 'center',
        background: 'rgb(183, 240, 173, 0.32)'
    },
    '& .Boxcontainer': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .topic_title': {
        color: 'var(--secondary-green, #2BCE8C)',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: "normal",
        fontWeight: 800,
        marginLeft: 8,
        zIndex: 1,
        lineHeight: "15px", /* 93.75% */
        letterSpacing: "0.32px",
    },
    '& .button_postion': {
        width: '240px',

    },

    '& .btn-action': {
        width: '100%',
        height: '40px',
        borderRadius: "12px",
        background: "var(--primary-blue-blue-primary-dark, #36BFFF)",
        color: 'var(--primary-blue-white, #FFF)',
        textAlign: "center",
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '16px', /* 100% */
        letterSpacing: '0.8px',
        textTransform:'none',
    },
    '& .btn-action > .MuiButton-label': {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '16px', /* 100% */
        letterSpacing: '0.8px',
    }
}))

// Customizable Area End