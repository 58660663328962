import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils";

export interface ILessonDetails{
    id: string;
    type: string;
    attributes: LessonAttributes;
}

export interface LessonAttributes {
    account_id: number;
    micro_lesson_id: number;
    lesson_id: number;
    assessment_id: number;
    account_event_point_id: number | null;
    question_bank_id: number;
    question_body: string;
    correct_answer: string;
    user_answer: string;
    correct: boolean;
    challenge: boolean;
    time_complete_in_seconds: number;
    grade: number;
    lesson_completed: boolean;
    question_type: string;
    earned_xp: number | null;
    index?:number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    Lessondata : {
        content: string
        description: string
        earned_xp: number
        endDate: string
        grade: number
        lesson_attempt_id: string| null
        lesson_id: number
        minutes: number
        name: string
        startDate: string
    };
    toggleShow:()=>void
    t?: any
    isOpen:string;
    index:number
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    toogleShow: string,
    loading: boolean
    reportLesson:Array<ILessonDetails>
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ReportLessonPageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLessonDetails:string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            toogleShow: "",
            loading: false,
            reportLesson:[]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start


    handleShowToggle=() =>{ 
        if(this.props.isOpen === `show${this.props.index}`){
            this.setState({
                reportLesson:[]
            })
        this.props.toggleShow()
        }else{
            this.setState({
                reportLesson:[],
                loading:true
            })
          this.props.toggleShow()
          this.getLessonDetailAPI()
        }
      }

    getLessonDetailAPI = async() => {
        const start_date = this.props.Lessondata.startDate
       const end_date = this.props.Lessondata.endDate
        this.getLessonDetails = sendAPIRequest(`bx_block_educational_gamification/median_grades/lesson_detail?start_date=${start_date}&end_date=${end_date}&lesson_id=${this.props.Lessondata.lesson_id}&lesson_attempt_id=${this.props.Lessondata.lesson_attempt_id}`, {
          method: 'GET',
          headers: {
            token:localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
          
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId && responseJson) {
           if(responseJson?.data){
            this.setState({
                reportLesson:responseJson.data
            })
           }
           this.setState({
            loading:false
           })
        }
      }


    // Customizable Area End
}