import React from "react";
// Customizable Area Start
import MaterialModalController from "./MaterialModalController.web";
import { styled } from "@material-ui/core/styles";
import { 
  Box, 
  Typography,
  Popover,
  Backdrop, 
  Button, 
  Snackbar,
  Portal,
} from '@material-ui/core'
import { skip_mask } from "./assets";
import {Alert} from "@material-ui/lab"
import { withTranslation } from "react-i18next";
// Customizable Area End
class MaterialModal extends MaterialModalController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const open = Boolean(this.props.open);
    const {t} = this.props
    // Customizable Area End
    return (
      // Customizable Area Start
      <Backdrop
        open={open}
        style={{
          color: "#fff",
          zIndex: 99,
        }}
      >
        <SettingContainer
          id="basic-menu"
          anchorEl={this.props.open}
          open={open}
          onClose={this.props.onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
               position:'relative'
            }
          }}
        >
            {this.props.Material.show&&
            <Box style ={{position:'absolute'}}>
          <Box className="left_top_image">
         <> <img src={skip_mask} />
            <div className="index_positioning">{this.props.Material.index}</div>
            </>
          </Box>
          </Box>}
          <Box className="MenuContainer">
            <Box className="Image_text_Container">
              <Box><img src={this.props.Material.image} height='70px' width='70px'/></Box>
              <Box className="text_padding">
                <Typography className="Text_name"> {this.props.Material.label} </Typography>
              </Box>
            </Box>
            <Box className="Button_position">
            <Box><Button variant="outlined" data-testid="cheetsheet" className="action_btn" onClick={this.handleCheetsheetPdfDownload}>{t("Cheatsheet.pdf")}</Button></Box>
            <Box><Button variant="outlined"data-testid="summary" className="action_btn"  onClick={this.handleSummaryPdfDownload}>{t("Summary.pdf")}</Button></Box>
            <Box><Button variant="outlined" data-testid="summarymp"className="action_btn" onClick={this.handleSummaryVideoDownload}>{t("Summary.mp4")}</Button></Box>
            </Box>
          </Box>
        </SettingContainer>
        <Portal>
                    <Snackbar
                    data-testid="snackbar"
                        open={this.state.ToastOpen}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        autoHideDuration={2000}
                        style={{zIndex:9999}}
                        onClose={this.handleCloseToast}>
                       <Alert  data-testid= "alert"onClose={this.handleCloseToast} style={{position:'fixed',zIndex:9999}}severity="error">{this.state.ToastMessage}</Alert>
                    </Snackbar>
                    </Portal>
      </Backdrop>
      // Customizable Area End
    )
  }
}
// Customizable Area Start
export default withTranslation()(MaterialModal)
export const SettingContainer = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: 300,
    background: '#FEEBAF',
    height: '400px',
    position:'relative',
    overflowX: 'visible',
    overflowY: 'visible',
    border: '5px solid #fff',
    transform: 'translate(0, -20vh) !important',
    borderRadius: '47px',
    
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      bottom: -10,
      borderRadius:'2px',
      transform: "rotate(45deg)",
      left: "calc(50% - 6px)"
    },
    "&::after": {
      backgroundColor: "#FEEBAF",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      bottom: -4,
      transform: "rotate(45deg)",
      left: "calc(50% - 6px)"
    }
  },

  '& .MenuContainer': {
    width: "100%",
    height:'100%',
    justifyContent:'flex-end',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom:20
    
  },
  '& .left_top_image': {
    top: 0,
    position: 'relative',
    left: 0,
    marginLeft: '-5px',
    marginTop: '-4px'
  },
  '& .index_positioning': {
    position: "absolute",
    top: "24px",
    left: "24px",
    color: 'var(--basic-white, #FFF)',
    textAlign: 'center',
    fontFamily: 'Nunito',
    fontSize: '20px',
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: '18.5px', /* 92.5% */
    letterSpacing: '0.16px',
},

   '& .Image_text_Container':{
  display:'flex',
  flexDirection:'column',
  alignItems: "center",
  flex:3,
  width:'100%',
  paddingTop:30
   },

  '& .action_btn': {
    width: '220px',
    margin: '6px',
    background: '#fff',
    textAlign: "center",
    height: 44,
    borderRadius: '12px',
    border: "2px solid var(--primary-neutrals-warm-gray-100, #E6E6E6)",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    textTransform: 'none',
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
    color: '#FF8400'
  },
  '& .text_padding': {
    width:'82%',
    margin: '20px 0px'
    
  },
  '& .action_btn > .MuiButton-label':{
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
},
  "& .Text_name": {
    color: "var(--primary-blue-dark-gray, #363636)",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "22px", /* 137.5% */
    letterSpacing: "0.128px"
  }

}));
// Customizable Area End