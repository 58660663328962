import React from 'react'
import {
  greyCloseButton,
  bolt,
  wellDoneShareBg,
  socialTeddy,
  welldoneBlue, 
  welldoneBrown, 
  welldoneGreen,
  welldoneMint, 
  welldoneOrange, 
  welldonePurple, 
  welldoneRed, 
  welldoneYellow
} from './assets'
import { Box, Modal, Typography, styled } from '@material-ui/core'
import  {useTranslation} from "react-i18next"

interface WellDoneShareProps {
  isExerciseShare?: boolean
  isScoreCardShare?:boolean
  open: any
  onClose: () => void
}

const WellDoneShare = ({ onClose, open, isExerciseShare , isScoreCardShare}: WellDoneShareProps) => {
  const  { t } = useTranslation()
  const avatarColor =  localStorage.getItem('avatarColor') || '#62cdff'
  const colorToUpdateTeddy:any = {
    '#ff8400': welldoneOrange,
    '#62cdff': welldoneBlue,
    '#b7f0ad': welldoneMint,
    '#a95edb': welldonePurple,
    '#f96855': welldoneRed,
    '#884a39': welldoneBrown,
    '#fdce36': welldoneYellow,
    '#2bce8c': welldoneGreen
  };

  const welldoneTeddy = colorToUpdateTeddy[avatarColor] || welldoneBlue;
  return (
    <ModalStyled open={open} onClose={onClose}>
      <Box className="container">
        <div className="closeBtn" onClick={onClose}>
          <img src={greyCloseButton} height={10} width={10} data-testid="btnClose"/>
        </div>
        <img src={welldoneTeddy} className="socialTeddy" />
        <Typography className="title">{t("Well done, Socializer!")}</Typography>
        <Box className="descriptionGroup">
          <Typography className="description">{t("You've earned")}</Typography>
          <img src={bolt} className="boltImg" />
          <Typography className="descriptionBold">
          {t("10 XP")}
          </Typography>
        </Box>
        <Typography className="description">
        {isExerciseShare ? 'for sharing your answer.' : isScoreCardShare ? 'for sharing your scorecard.' : 'for sharing the video.'}
        </Typography>
        <Typography className="description">
        {isExerciseShare ? "You're doing fantastic!" : isScoreCardShare ? "You're unstoppable!" : 'Keep it up!'}
        </Typography>
      </Box>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)(() => ({
  '& .container': {
    width: 296,
    position: 'absolute',
    left: '50%',
    top: '50%',
    backgroundImage: `url(${wellDoneShareBg})`,
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FEF3C7',
    display: 'flex',
    padding: 26,
    flexDirection: 'column',
    borderRadius: 12,
    alignItems: 'center',
  },

  '& .closeBtn': {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },

  '& .title': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    color: '#363636',
    fontSize: '18px',
  },

  '& .descriptionGroup': {
    display: 'flex',
    alignItems: 'center',
  },

  '& .boltImg': {
    marginRight: '4px',
    marginLeft: '4px',
    width: '10px',
    height: '13px',
  },

  '& .description': {
    textAlign: 'center',
    fontFamily: 'Quicksand',
    color: '#666666',
    fontSize: '15px',
    fontWeight: '500',
  },

  '& .descriptionBold': {
    fontFamily: 'Quicksand',
    fontSize: '15px',
    color: '#666666',
    fontWeight: '700',
  },

  '& .socialTeddy': {
    marginBottom: '13px',
    marginRight: '20px',
    height: '100px',
    width: '116px',
  },
}))

export default WellDoneShare
