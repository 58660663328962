import React from "react"
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Snackbar,
    Portal,
} from "@material-ui/core";
import {Alert} from "@material-ui/lab"
import { 
    downnside, 
    horizontal,
    upside 
} from "./assets";
import UnitTopiController from "./UnitTopicController.web";
import LessonMaterialModal from "./LessonMaterialModal.web";

const side = [
    { side: upside },
    { side: horizontal },
    { side: downnside }
]
// Customizable Area End

export default class UnitTopic extends UnitTopiController {

    // Customizable Area Start


    getPatternIndex = (index: number) => {
        const num = index % 3
        return num
    }

    getItemCss = (patternIndex: number) => {
        return (<Box className={this.props.data.length - 1 !== patternIndex?"nodeBoxContainer":"nodeBoxContainer1"}>
            {this.props.data.slice(patternIndex, patternIndex + 2).map((item: any, index: number) => {
                return (
                    <Box key={index} className="nodeBox" >
                        <Box className="ImageTextContainer">
                            <Box style={{ textAlign: 'center' }} >
                                <Box data-testid={`getMaterialIcons${patternIndex+index}`}style={{ cursor: 'pointer' }} onClick={() => this.getMaterials(item)}>
                                    <img src={item.image} width={88} height={88} style={{borderRadius:"50%"}} />
                                </Box>
                                <Box style={{ width: '100%', maxWidth: "150px", padding: "0 10px" }}>
                                    <Typography className="lesson_name">
                                        {item.label}
                                    </Typography>
                                </Box>
                            </Box>
                            {(index === 0 && this.props.data.length - 1 !== patternIndex) && <Box style={{ marginTop: '-50px', padding: "0px 24px" }}>
                                <img src={side[1].side} />
                            </Box>}
                        </Box>
                    </Box>
                )
            })}
        </Box>
        )
    }

    getUpsideclassName = (index: number) => {
        return index === 0 ? "upsideImage" : "upsideImage2"
    }

    getBoxImageContainer = (index: number) => {
        return index === 0 ? "BoxImageContainer" : "BoxImageContainer2"
    }

    getTextContainer = (index: number) => {
        return index === 0 ? "textContainer" : "textContainer2"
    }

    getDownSideImage = (index: number) => {
        return index === 0 ? "downsideImage" : "downsideImage2"
    }
    // Customizable Area End
    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <SettingContainer>
                <>
                    {this.props.data?.map((item: any, index: number) => {
                        const patternIndex = this.getPatternIndex(index)
                        if (patternIndex === 0) {
                            return (
                                <Box key={index} className={this.getBoxImageContainer(index)} >
                                    <Box className="BoxContainer" >
                                        {this.props.data.length - 1 !== index ? <Box className={this.getDownSideImage(index)}>
                                            <img src={side[2].side} />
                                        </Box> : <Box style={{ marginRight: 60 }}></Box>}
                                        <Box 
                                        data-testid={`getMaterialIcons${index}`} 
                                        style={{ cursor: 'pointer' }} 
                                        onClick={() => this.getMaterials(item)}
                                        >
                                            <img src={item.image} width={88} height={88} style={{borderRadius:"50%"}}/>
                                        </Box>
                                        {index !== 0 && <Box className={this.getUpsideclassName(index)}>
                                            <img src={side[0].side} />
                                        </Box>}
                                    </Box>
                                    <Box
                                        className={this.getTextContainer(index)}>
                                        <Typography className="lesson_name" >
                                            {item.label}
                                        </Typography>
                                    </Box>
                                </Box>
                            )
                        }
                        else if (index === 1 || patternIndex === 1) {
                            return this.getItemCss(index)
                        }
                    })}
                    {this.state.MaterialData &&
                        <LessonMaterialModal
                            Material={this.state.MaterialData}
                            data-testid="MaterialModal"
                            open={this.state.materialModal}
                            onClose={() => {
                                this.setState({ materialModal: false });
                            }}
                            handleSummaryDownload={this.handleDownlaodSummaryPdf}
                            handleVideoDownload={this.handleDownloadVideo}
                            handleCheetSheetDownload={this.handleDownloadCheetSheetPdf}
                        />}
                    <Portal>
                    <Snackbar
                        open={this.state.ToastOpen}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        autoHideDuration={2000}
                        style={{zIndex:9999}}
                        onClose={this.handleCloseToast}>
                       <Alert onClose={this.handleCloseToast} style={{position:'fixed',zIndex:9999}}severity="error">{this.state.ToastMessage}</Alert>
                    </Snackbar>
                    </Portal>
                </>
            </SettingContainer>
            // Customizable Area End
        )

    }

}

// Customizable Area Start
export const SettingContainer = styled(Box)(({ theme }) => ({
    padding: '10px',
    width: "100%",
    paddingTop:20,
    background:'#FEEBAF',
    height: '100%',
    display: 'flex',
    flexDirection: "column",
    '@media(max-width:960px)': {
        marginBottom: 60
    },

    '& .BoxContainer': {
        display: 'flex',
        alignItems: 'center'
    },

    '& .lesson_name': {
        textAlign: 'center',
        color: "var(--secondary-orange, #FF8400)",
        fontFamily: "Nunito",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 800,
        lineHeight: "15px", /* 93.75% */
        letterSpacing: "0.32px",
    },

    '& .nodeBox': {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        marginTop: 0
    },

    '& .nodeBoxContainer': {
        display: 'flex',
        width: "100%",
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0
    },

    '& .nodeBoxContainer1': {
        display: 'flex',
        width: "50%",
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
        marginLeft:'7%',
        '@media(max-width:570px)':{
            marginLeft:0
        }
    },

    '& .BoxImageContainer': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 90,
        marginBottom: 20,
    },

    '& .mainWhiteBox': {
        backgroundColor: '#FF8400'
    },

    '& .BoxImageContainer2': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "",
        marginBotton: ""
    },

    '& .upsideImage': {
        marginTop: '-32px',
        marginLeft: '40px'
    },
    '& .upsideImage2': {
        marginTop: '-32px',
        marginLeft: '40px'
    },
    '& .downsideImage': {
        marginTop: '60px',
        marginRight: '40px',
        '@media(max-width:375px)': {
            marginRight: "16px",
            marginLeft: "-24px",
        }
    },

    '& .downsideImage2': {
        marginTop: '60px',
        marginRight: '40px',
    },

    '& .textContainer': {
        width: '100%',
        maxWidth: "150px",
        padding: "0 10px"
    },
    '& .textContainer2': {
        width: '100%',
        maxWidth: "184px",
        padding: "0 10px"
    },

    '& .ImageTextContainer': {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
    },

}))
// Customizable Area End
