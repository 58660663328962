export const imgTeddyProfile = require("../assets/imgTeddyProfile.svg");
export const profileIcon = require("../assets/skip_profileIcon.svg");
export const freemium =  require('../assets/skip_freemiumIcon.svg')
export const moreButton = require('../assets/button_more.svg')
export const bolt = require('../assets/skip_bolt.svg')
export const rectangle = require("../assets/rectangle.svg")
export const time = require("../assets/skip_time.svg")
export const challenge = require('../assets/skip_challengesIcon.svg')
export const trophies = require('../assets/skip_trophy.svg')
export const goal =  require('../assets/skip_goals.svg')
export const doublex =  require('../assets/skip_2x.svg')
export const awardWrench =  require('../assets/award_wrench.svg')
export const awardlist1 =  require('../assets/award_1.svg')
export const awardlist2 =  require('../assets/award_2.svg')
export const awardlist3 =  require('../assets/award_3.svg')
export const awardlist4 =  require('../assets/award_4.svg')
export const award1 = require('../assets/award1.svg')
export const award2 = require('../assets/award2.svg')
export const award3 = require('../assets/award3.svg')
export const whiteDivider = require('../assets/divider.svg')
export const awardShow =  require('../assets/awardShow.svg')
export const blankBolt = require('../assets/blank_bolt.svg')
export const blankBolt1 = require('../assets/blank_bolt1.svg')
export const blankBolt2 = require('../assets/blank_bolt2.svg')
export const blankBolt3 = require('../assets/blank_bolt3.svg')
export const greenBox = require('../assets/green_box.svg')
export const premiumTeddy = require('../assets/premiumTeddy.svg')
export const percentIcon =  require('../assets/percent.svg')
export const addIcon =  require('../assets/add.svg')
export const equalIcon = require('../assets/equal.svg')
export const multipleIcon = require('../assets/multiple.svg')
export const divideIcon =  require('../assets/divide.svg')
export const rootIcon = require('../assets/root.svg')
export const minusIcon =  require('../assets/minus.svg')
export const yellowIcon =  require('../assets/yello.svg')
export const backButton = require('../assets/button_back.svg')
export const wrenchIcon = require('../assets/skip_wrench.svg')
export const happyTeddy =  require('../assets/happy_teddy.svg')
export const loader = require('../assets/loader.svg');
export const healthyTeddy1 =require('../assets/happy_orange.svg')
export const healthyTeddy2 =require('../assets/skip_healthyTeddy__2_.svg')
export const healthyTeddy3 =require('../assets/happy_lightGreen.svg')
export const healthyTeddy4 =require('../assets/happy_purple.svg')
export const healthyTeddy5 =require('../assets/happy_red.svg')
export const healthyTeddy6 =require('../assets/happy_brown.svg')
export const healthyTeddy7 =require('../assets/happy_yellow.svg')
export const healthyTeddy8 =require('../assets/happy_green.svg')
export const healthyTeddy =  require('../assets/healthyTeddy.svg')
export const worriedAvatar =  require('../assets/worried_avatar.svg')
export const happyAvatar = require('../assets/happy_avatar.svg')
export const ghostlyAvatar = require('../assets/ghostly_avatar.svg')
export const userHealthy =  require('../assets/userHealthy.svg')
export const userHappy = require('../assets/userHappy.svg')
export const userWorried =  require('../assets/userWorried.svg')
export const userWeak =  require('../assets/userWeak.svg')
export const userGhostly = require('../assets/userGhostly.svg')
export const ghostly = require('../assets/ghostlyAvatar.svg')
export const orangeHappy =  require('../assets/orange_happy.svg')
export const orangeHealthy =  require('../assets/orange_healthy.svg')
export const orangeWeak = require('../assets/orange_weak.svg')
export const orangeWorried =  require('../assets/orange_worried.svg')
export const blueHappy =  require('../assets/blue_happy.svg')
export const blueHealthy =  require('../assets/blue_healthy.svg')
export const blueWeak = require('../assets/blue_weak.svg')
export const blueWorried =  require('../assets/blue_worried.svg')
export const lightgreenHappy =  require('../assets/lightGreen_happy.svg')
export const lightgreenHealthy =  require('../assets/lightGreen_healthy.svg')
export const lightgreenWeak = require('../assets/lightGreen_weak.svg')
export const lightgreenWorried =  require('../assets/lightGreen_worried.svg')
export const purpleHappy =  require('../assets/purple_happy.svg')
export const purpleHealthy =  require('../assets/purple_healthy.svg')
export const purpleWeak = require('../assets/purple_weak.svg')
export const purpleWorried =  require('../assets/purple_worried.svg')
export const redHappy =  require('../assets/red_happy.svg')
export const redHealthy =  require('../assets/red_healthy.svg')
export const redWeak = require('../assets/red_weak.svg')
export const redWorried =  require('../assets/red_worried.svg')
export const brownHappy =  require('../assets/brown_happy.svg')
export const brownHealthy =  require('../assets/brown_healthy.svg')
export const brownWeak = require('../assets/brown_weak.svg')
export const brownWorried =  require('../assets/brown_worried.svg')
export const yellowHappy =  require('../assets/yellow_happy.svg')
export const yellowHealthy =  require('../assets/yellow_healthy.svg')
export const yellowWeak = require('../assets/yellow_weak.svg')
export const yellowWorried =  require('../assets/yellow_worried.svg')
export const greenHappy =  require('../assets/green_happy.svg')
export const greenHealthy =  require('../assets/green_healthy.svg')
export const greenWeak = require("../assets/green_weak.svg")
export const greenWorried =  require("../assets/green_worried.svg")