// Customizable Area Start
import React from 'react'
import ChallengeExerciseController, {
  Props,
} from './ChallengeExerciseController'
import NavigateLayout from '../../../components/src/NavigateLayout'
import { Box, Typography, styled } from '@material-ui/core'
import {
  bolt,
  exerciseImg,
  greyCloseButton,
} from '../../cftableforlessons/src/assets'
import { challengeMountain, flagHeader } from './assets'
import SingleChoiceExercise from '../../../components/src/MicrolessonExercise/SingleChoiceExercise'
import ChallengeSelebration from '../../../components/src/MicrolessonExercise/ChallengeSelebration'
import ChallengeFinished from '../../../components/src/MicrolessonExercise/ChallengeFinished'
import ChallengeRoulette from '../../../components/src/MicrolessonExercise/ChallengeRoulette'
import ChallengeRouletteResult from '../../../components/src/MicrolessonExercise/ChallengeRouletteResult'
import MainContentCard from '../../cftableforlessons/src/MainContentCard'
import { withTranslation } from 'react-i18next'
import MultipleChoiceExercise from '../../../components/src/MicrolessonExercise/MultipleChoiceExercise'
import MatchingExercise from '../../../components/src/MicrolessonExercise/MatchingExercise'
import FillBlanksExercise from '../../../components/src/MicrolessonExercise/FillBlanksExercise'
import SequenceExercise from '../../../components/src/MicrolessonExercise/SequenceExercise'
import { loader } from '../../stripepayments/src/assets'

class ChallengeExercise extends ChallengeExerciseController {
  constructor(props: Props) {
    super(props)
  }

  handleNextQuestion = () => {
    if (this.state.questionIndex === this.state.challengeExerciseData.length - 1) {
      const totalTime = (Date.now() - this.state.startTime) / 1000
      this.setState({ showChallengeFinish: true, totalTime: Math.floor(totalTime) })
      return
    }
    this.setState({ questionIndex: this.state.questionIndex + 1 })
  }

  handleClickContinue = ({
    isAnswerCorrect,
    isClickFromCelebrate,
    isClickFromChallengeFinish,
    isClickFromChallengeRoulette,
    isClickFromChallengeRouletteResult
  }: {
    isAnswerCorrect?: boolean
    isAnswerWrong?: boolean
    isUseSolution?: boolean
    isSkip?: boolean
    isClickFromCelebrate?: boolean
    isClickFromMistake?: boolean
    isClickFromChallengeFinish?: boolean
    isClickFromChallengeRoulette?: boolean
    isClickFromChallengeRouletteResult?: boolean
  }) => {
    if (isAnswerCorrect && !this.state.isFirstTimeCorrectAnswer) {
      this.setState({ isFirstTimeCorrectAnswer: true })
      return
    }

    if (isClickFromCelebrate) {
      this.setState({ isDisableShowCelebrate: true })
      this.handleNextQuestion()
      return
    }

    if (isClickFromChallengeFinish) {
      this.getNextChallenge()
      this.setState({
        showChallengeFinish: false,
        showRoulette: true,
      })
      return
    }

    if (isClickFromChallengeRouletteResult) {
      this.props.navigation.navigate('ChallengeExercise', {
        unitId: this.state.unitId,
        unitName: this.state.unitName,
        lessonId: this.state.nextLessonId,
        lessonName: this.state.nextLessonName,
      })
      return
    }

    this.handleNextQuestion()
  }

  handleNavigateHome = () => this.props.navigation.navigate('ChallengePage')

  renderMainContent = () => {
    const QuestionList = {
      TRUE_FALSE: SingleChoiceExercise,
      MULTIPLE_CHOICE: MultipleChoiceExercise,
      MATCHING: MatchingExercise,
      FILL_IN_BLANK: FillBlanksExercise,
      SEQUENCE: SequenceExercise,
    }

    const currentQuestion = this.state.challengeExerciseData?.[this.state.questionIndex]
    const Component =
      QuestionList[
      currentQuestion?.attributes?.questionType as keyof typeof QuestionList
      ]

    if (this.state.isLoading) {
      return (
        <Box className='loadingWrapper'>
          <img src={loader} alt="Loading" />
        </Box>
      )
    }

    if (this.state.challengeExerciseData.length === 0) {
      return <Typography className='noChallengeText'>{this.props.t("There is no exercise.")}</Typography>
    }

    if (this.state.isFirstTimeCorrectAnswer && !this.state.isDisableShowCelebrate) {
      return (
        <ChallengeSelebration 
          onClickContinue={this.handleClickContinue} 
          totalXP={this.state.totalXP} 
          onGoBack={this.handleNavigateHome}
          teddyColor={this.state.teddyColor}
        />)
    }

    if (this.state.showChallengeFinish) {
      return (
        <ChallengeFinished
          onClickContinue={this.handleClickContinue} 
          onClickGoHome={this.handleNavigateHome}
          earnedXP={this.state.totalXP - this.state.initialXPPoint}
          totalTime={this.state.totalTime}
          challengeName={this.state.lessonName}
          lessonId={this.state.lessonId}
          assessmentId={this.state.assessmentId}
          onUpdateXP={this.getSpecificAccount}
          teddyColor={this.state.teddyColor}
        />
      )
    }

    if (this.state.showRoulette) {
      return <ChallengeRoulette onGoBack={this.handleNavigateHome} />
    }

    if (this.state.showRouletteResult) {
      return (
        <ChallengeRouletteResult
          onClickContinue={this.handleClickContinue} 
          nextLessonName={this.state.nextLessonName}
          onGoBack={this.handleNavigateHome}
        />
      )
    }

    return (
      <Component
        key={this.state.questionIndex}
        {...currentQuestion}
        onClickContinue={this.handleClickContinue}
        onSubmitExerciseAnswer={this.submitExerciseAnswer}
        exerciseAnswer={undefined}
        isLastQuestion={this.state.questionIndex === this.state.challengeExerciseData.length - 1}
        onSelectReportOption={this.handleSelectReportOption}
        onSubmitReport={this.submitReport}
        lessonId={this.state.lessonId}
        microLessonId={this.state.miroLessonId}
        onUpdateXP={this.getSpecificAccount}
        shareRecordType='CHALLENGE'
      />
    )
  }

  closeDrawer = () => {
    this.setState({
      goalsOpen: false,
      trophiesOpen: false,
      mathOpen: false,
      medianOpen: false,
      openDrawer: false,
    })
  }

  handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ openDrawer: true, trophiesOpen: true })
    } else if (type === 'goal') {
      this.setState({ openDrawer: true, goalsOpen: true })
    } else if (type === 'median') {
      this.setState({ openDrawer: true, medianOpen: true })
    }
  }

  handleClickBackLandingPage = () => {
    localStorage.setItem('isBackFromMicrolessonVideo', 'true')
    this.props.navigation.navigate('LandingPageWeb')
  }

  render() {
    const { t } = this.props
    return (
      <NavigateLayout menuName="Menu" isShowBottomMenu={false}>
        <ChallengesExerciseContainer>
          <MainContentCard
            closeDrawer={this.closeDrawer}
            drawerSide={this.state.drawerSide}
            goalsOpen={this.state.goalsOpen}
            handleOpenDrawer={(name: string) => this.handleOpenDrawer(name)}
            lessonName={this.state.lessonName || 'Algebraic Operations with Real Numbers'}
            medianOpen={this.state.medianOpen}
            navigation={this.props.navigation}
            onClickBackLandingPage={this.handleClickBackLandingPage}
            openDrawer={this.state.openDrawer}
            topicName={this.state.topicName || 'Algebra'}
            trophiesOpen={this.state.trophiesOpen}
            unitName={this.state.unitName || 'Set of Real Numbers'}
            isChallenge
            lessonNumber={this.state.lessonNumber}
            unitNumber={this.state.unitNumber}
          />
          <Box className="contentContainer">
            <Box className="mainContentWrapper">
              {!(this.state.isFirstTimeCorrectAnswer && !this.state.isDisableShowCelebrate) &&
                !this.state.showChallengeFinish &&
                !this.state.showRoulette &&
                !this.state.showRouletteResult && (
                  <Box style={{ width: '100%' }}>
                    <Box className="challengeHeaderContainer">
                      <img
                        src={flagHeader}
                        width={21}
                        height="auto"
                        style={{
                          position: 'absolute',
                          top: '42%',
                          left: '66%',
                          transform: 'translate(-50%, -50%)',
                        }}
                      />
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={greyCloseButton} width={14} height={14} onClick={this.handleNavigateHome} />
                        <div className="brainImgMain">
                          <img src={exerciseImg} width={16} height={16} />
                        </div>
                        <Typography
                          style={{
                            fontFamily: 'Nunito',
                            fontWeight: 900,
                            fontSize: '16px',
                            color: '#2BCE8C',
                            textTransform: 'uppercase'
                          }}
                        >
                          {t("Challenge")}
                        </Typography>
                      </Box>
                      <Box className="boltCountMain">
                        <img src={bolt} width={14.12} height={19.16} />
                        <Typography className="number">{this.state.totalXP}</Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              <Box
                className={
                  this.state.showChallengeCelebrate
                    ? 'challengeContentContainer'
                    : 'challengeContentContainer challengeExerciseContent'
                }
              >
                {this.renderMainContent()}
              </Box>
            </Box>
          </Box>
        </ChallengesExerciseContainer>
      </NavigateLayout>
    )
  }
}
export default withTranslation()(ChallengeExercise)
const ChallengesExerciseContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#25b178',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .loadingWrapper': {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  '& .boltCountMain': {
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    padding: '4px 12px 4px 8px',
    gap: '12px',
  },

  '& .noChallengeText': {
    fontFamily: 'Quicksand',
    fontWeight: '700',
    fontSize: '20px',
    color: '#363636',
    marginTop: '48px'
  },

  '& .number': {
    fontFamily: 'Nunito',
    fontWeight: '900',
    fontSize: '18px',
    color: '#666666',
  },

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    scrollbarWidth: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'auto',
  },

  '& .contentContainer::-webkit-scrollbar': {
    display: 'none',
  },

  '& .mainContentWrapper': {
    flex: 1,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
  },

  '& .challengeHeaderContainer': {
    width: '100%',
    height: '146px',
    backgroundImage: `url(${challengeMountain})`,
    backgroundSize: '100% 100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '26px',
    paddingRight: '26px',
    justifyContent: 'space-between',
    position: 'relative',
  },

  '& .brainImgMain': {
    backgroundColor: '#2BCE8C',
    height: '22px',
    width: '22px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    margin: '0 10px',
  },

  '& .challengeContentContainer': {
    flex: 1,
    width: '100%',
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    alignItems: 'center'
  },

  '& .challengeExerciseContent': {
    borderRadius: '20px 20px 0 0',
    marginTop: '-18px',
    zIndex: 10,
  },

  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .challengeHeaderContainer': {
      paddingLeft: '60px',
      paddingRight: '60px',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px',
  },
}))

// Customizable Area End
