import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button,
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";
import {
    vextor_tedix,
    group_title,
    skip_insta,
    skip_twitter,
    skip_tiktok,
    group_illustration
} from "./assets";
import CenterPortion from './CenterPortion.web'
// Customizable Area End
import { ViewProps } from "./StripePayments";

// Customizable Area Start

const data = [
    { leftText: "SUBSCRIPTION ID", rightText: "012345" },
    { leftText: "START DATE", rightText: "06/09/2023" },
    { leftText: "NEXT RENEWAL DATE", rightText: "06/09/2024" },
    { leftText: "BILLING FREQUENCY", rightText: "ANNUAL" },
    { leftText: "PAYMENT AMMOUNT", rightText: "30 €" },
];


// Customizable Area End
const PaymentSuccessfulEmail: React.FC<ViewProps> = () => {


    return (
        // Customizable Area Start

        <SettingContainer>
            <SideBar />
            <Box className="emailPaymentsWrapper" >
                <Box className="PaymentEmailBackground">
                <CenterPortion navigation={undefined} id={""} />
                </Box>
                <Box className="EmailContainerEm" >
                    <Box>
                        <img src={vextor_tedix} />
                    </Box>

                    <Box>
                        <Typography style={webStyle.congratulationsText}>
                            Congratulations, Amy!
                        </Typography>
                    </Box>

                    <Box style={webStyle.groupTitleImg}>
                        <img src={group_title} />
                    </Box>

                    <Box style={webStyle.subscriptionTitle}>
                        <Typography style={webStyle.subscriptionDetailsText}>
                            Here are the details of your subscription:
                        </Typography>
                    </Box>

                    <Box style={webStyle.SectionBox}>
                        <Box style={webStyle.innerBox}>
                            {data.map((item, index) => (
                                <Box style={webStyle.innerBoxSection}>
                                    <div style={webStyle.leftSideText}>{item.leftText}</div>
                                    <div style={webStyle.rightSideText}>{item.rightText}</div>
                                </Box>
                            ))}
                        </Box>
                    </Box>

                    <Box style={webStyle.group_illustrationImg}>
                        <img src={group_illustration} alt="group_illustration" />
                    </Box>

                    <Box style={webStyle.thankyouSection}>
                        <Typography style={webStyle.thankYouText}>
                            Thank you for choosing <span style={webStyle.boldText}>Tedix®</span> for your learning adventure. We can't wait to see you thrive and succeed with us!
                        </Typography>
                        <Typography style={webStyle.thankSecondText}>
                            Prepare to experience learning like <span style={webStyle.boldText}>never before</span>.
                        </Typography>
                    </Box>

                    <Box style={webStyle.buttonContainer}>
                        <Button style={webStyle.buttonStyle}>
                            Start exploring
                        </Button>
                    </Box>

                    <Box style={webStyle.thankyouSection}>
                        <Typography style={webStyle.thankyouSectiontitle}>
                            If you have any questions or need assistance, our support team is here to help.
                            Feel free to reach out to our <span style={webStyle.supportTeamText}>support team</span> anytime.
                        </Typography>
                    </Box>

                    <Box style={{ borderRight: '1px solid #E6E6E6', height: '100%', width: '200px' }}>
                      
                    </Box>

                    <Box style={webStyle.lastDivFooter}>
                        <Box style={{ width: '200px' }}>
                            <Typography style={webStyle.typographyStyle}>
                                Address, Country
                            </Typography>
                            <Typography style={webStyle.typographysecStyle}>
                                Cancel subscription
                            </Typography>
                        </Box>

                        <Box>
                            <img src={skip_insta} alt="footer img" />
                            <img src={skip_twitter} alt="twitter img" />
                            <img src={skip_tiktok} alt="tiktok img" />
                        </Box>
                    </Box>

                </Box>

            </Box>
        </SettingContainer>

        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

   
    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },

    '& .emailPaymentsWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .PaymentEmailBackground': {
        backgroundColor: '#fff',
    },



    '& .paymentEmailImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        
        
    },




    '& .settingesTitleEmai': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .EmailContainerEm': {
        flex: '6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundPosition: 'center',
        overflow: 'auto',
        backgroundColor: '#fff',
    },

    '& .teddyesImagesEmai': {
        marginLeft: '0',
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        Width: '245px',
        marginRight: '10px',
    },




    [theme.breakpoints.up('md')]: {


        '& .emailPaymentsWrapper': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },


        '& .teddyesImagesEmai': {
            maxWidth: 'unset',
        },

        '& .EmailContainerEm': {
            paddingTop: '90px',
        },


        '& .PaymentEmailBackground': {
            flex: 5,
        },
        '& .paymentEmailImgesWrapper': {
            paddingTop: 'unset',
        },



        '& .settingesTitleEmai': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

    },

    [theme.breakpoints.up('lg')]: {


        '& .emailPaymentsWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


    },
    [theme.breakpoints.down('sm')]: {
        '& .PaymentEmailBackground': {
            display: 'none',
        },
        '& .EmailContainerEm': {
            flex: '6',
            display: 'flex',
            padding: '45px 24px 100px',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },



    },
    [theme.breakpoints.down('xs')]: {
        '& .PaymentEmailBackground': {
            display: 'none',
        },
        '& .EmailContainerEm': {
            flex: '6',
            display: 'flex',
            padding: '45px 24px 100px',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },


    },

}))

const webStyle = {
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },
    typographyStyle: {
        fontFamily: 'Quicksand',
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '7px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999',
    },
    typographysecStyle: {
        fontFamily: 'Quicksand',
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '7px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999',
        marginTop: '9px'
    },
    lastDivFooter: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '14px 20px',
        borderBottom: '1px solid #E6E6E6',
    },
    leftSideText: {
        fontFamily: 'Quicksand',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '9px',
        letterSpacing: '0.008em',
        textAlign: 'left' as 'left',
        color: '#7F7F7F',
    },
    rightSideText: {
        fontFamily: 'Quicksand',
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '9px',
        letterSpacing: '0.008em',
        textAlign: 'right' as 'right',
        color: '#363636',
    },
    congratulationsText: {
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontWeight: 400,
        lineHeight: '19px',
        letterSpacing: '0em',
        textAlign: 'center' as 'center',
        color: '#363636',
        marginTop: '20px'
    },
    groupTitleImg: {
        marginTop: '50px',
    },
    subscriptionDetailsText: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0em',
        textAlign: 'left' as 'left',
        color: '#363636',
    },
    subscriptionTitle: {
        marginTop: '20px'
    },
    SectionBox: {
        marginTop: '20px',
    },
    innerBox: {
        width: '288px',
        height: '193px',
        top: '551px',
        left: '53px',
        borderRadius: '10px',
        border: '1px solid #E6E6E6',
    },
    innerBoxSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '14px 20px',
        borderBottom: '1px solid #E6E6E6',
    },
    group_illustrationImg: {
        marginTop: '20px'
    },
    buttonContainer: {
        width: '260px',
        height: '40px',
        borderRadius: '12px',
        backgroundColor: '#36BFFF',
    },
    buttonStyle: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '0.800000011920929px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center' as 'center',
    },
    thankYouText: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0em' as '0em',
        textAlign: 'center' as 'center',
        color: '#363636',
    },
    thankSecondText: {
        fontFamily: 'Quicksand',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0em' as '0em',
        textAlign: 'center' as 'center',
        color: '#363636',
        marginTop: '10px'
    },
    boldText: {
        fontWeight: 'bold' as 'bold',
    },
    thankyouSection: {
        padding: '20px',
        width: '295px',
        height: '193px',
    },
    thankyouSectiontitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '14px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#666666',
    },
    supportTeamText: {
        color: '#62CDFF', // Color for the "support team" text
    },

};
// Customizable Area End

export default PaymentSuccessfulEmail;
