import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  Box,
  Typography,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import NavigateLayout from "../../../components/src/NavigateLayout";
import "react-datepicker/dist/react-datepicker.css";
import { profileIcon, freemium, award1, award2, award3, whiteDivider, awardShow, loader} from './assets';
import UserProfileDetail from "./UserProfileDetail.web";
import StatisticsDetail from "./StatisticsDetail.web";
import { withTranslation } from "react-i18next";
import ChangeAvatar from "./ChangeAvatar.web";
import Awards from './Awards.web'
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start

    const { premiumUser, xp_points } = this.state;
    const total_XP = xp_points?.total_xp;

    const numberOfBoxes = Math.min(Math.floor(total_XP / 100), 10);
    const blueNumberBoxes = Math.min(Math.floor(total_XP / this.getDivider(total_XP)??200), 10);

    const renderBoxes = (count:any, color:any) =>
        Array.from({ length: count }, (_, index) => (
            <span
                key={index}
                style={{
                    background: color,
                    width: '8px',
                    height: '12px',
                    content: '',
                    display: 'block',
                    borderRadius: '2px'
                }}
            ></span>
        ));

    const greenBoxes = renderBoxes(numberOfBoxes, '#B7F0AD');
    const blueBoxes = renderBoxes(blueNumberBoxes, 'linear-gradient(90deg, #62CDFF 0%, #A95EDB 100%)');
   
    const progressColor = premiumUser ? blueBoxes : greenBoxes;

    
    const selectHeading = (
        <Typography className="setPremium">
            {premiumUser ?
                <span onClick={this.changeAvatar} className="premiumText">{this.props.t('Change avatar')}</span> :
               <span className="CustomizeText">  Customize your avatar with <span onClick={() => this.props.navigation.navigate('StripePaymentsView')} className="premiumText">
                {this.props.t('Premium')}
               </span>
              </span>
              }
        </Typography>
    );  
    let renderContent;

      if(this.state.changeAvatar){
        renderContent =  <Box style={{marginTop:'-33px', width:'100%'}}>
        <ChangeAvatar callbackFromParent={this.getDataFromChild} navigation={undefined} id={""} avatarColor={this.state.avatarColor} callParentFunction={this.getUserDetail} />
      </Box>
      } else if(this.state.openAwards){
        renderContent = <Box style={{marginTop:'-33px', width:'100%'}}>
        <Awards callbackParent={this.getDataChild}  />
        </Box>
    
      }
      else {
        renderContent =  <Box style={{width:'90%'}}>
        <Box className="userBox">
          <img src={profileIcon} width='15px' height='18px'/>
          <Box className="profileName">
            <Typography className="mainName">{this.state.profileUserName}</Typography>
            <Typography>{this.state.userEmail}</Typography>
          </Box>
          <Box className="moodShown">
            <img src={this.state.userMood} />
          </Box>
        </Box>

        <Box className="freemiumBox">
          <img src={freemium} width='21px' height='16px'/>
          <Box className="profileName">
            <Typography className='profileMode'>{this.props.t(this.getPremium())}</Typography>
          </Box>
          <Box style={{display: 'flex', gap: '2px'}}>
           {progressColor} 
          </Box>
          <Box className="moodShown">
            <Typography className="profilePoint">{total_XP} XP</Typography>
          </Box>
        </Box>

        <Box className="detailList">
          <UserProfileDetail premium={premiumUser} xp_detail={this.state.xp_points}/>
        </Box>
        <Box className="detailList">
          <StatisticsDetail premium={premiumUser} staticsData={this.state.statisticsInfo}/>
        </Box>

        <Box className="detailAwards">
          <Typography className="awardHead">{this.props.t("Awards")}</Typography>
          <Box className="AwardImage">
            <img src={award1} width='90px' height='100px'/>
            <img src={award2} width='90px' height='100px'/>
            <img src={award3} width='90px' height='100px'/>
          </Box>
          <img src={whiteDivider} width='100%' style={{marginTop:'14px'}}/>
          <Box className="viewMore">
            <Typography onClick={this.showAwards} className="viewButton">{this.props.t('View More')}</Typography>
            <img src={awardShow} width='18px' height='18px' onClick={this.showAwards} style={{cursor: 'pointer'}}/>
          </Box>
        </Box>
      </Box>
    } 

    return (
      <NavigateLayout menuName="Profile">
      <ProfileContainer>
     {this.state.isLoading && this.state.loader ? ( 
          <Box className="loaderShown">
          <img src={loader} alt="Loading" />
        </Box>
         ) : 
         <>
          <Box className="settingBackground">
            <Box className={this.getBlurClassName()}>
              <Box className={this.getClassNameChangeAvatartAndAward()}>
                <Typography className="profileTitle">{this.props.t('Profile')}</Typography>
                
                <Box style={{marginTop: 'auto'}}>
                <Box>
                <img src={this.getImage()} className="teddyImage" alt="Teddy Profile" onClick={this.blurBackground} data-test-id="blurButton"/>
                <img src={this.getImage()} className="teddyImageMobile" alt="Teddy Profile" onClick={this.blurBackground} data-test-id="blurButton"/>
                </Box>
                </Box> 
              </Box>
            </Box>
            {this.state.blur &&  selectHeading}
          </Box>
         
         <Box className="contentContainer">
          { renderContent}
         </Box>
         </> 
         } 
        </ProfileContainer>
      </NavigateLayout>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(CustomisableUserProfiles)
const ProfileContainer = styled(Box)(({theme}) => ({
  backgroundColor: '#FF8400',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position:'relative',
  '& .shownTop': {
    height: '100%',
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FF8400',
    borderBottomRightRadius: '20px',
    paddingTop: '43px',
  },
  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100%', 
    width: "calc(100% - 240px)",
     marginLeft: "100px" , 
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },
  '& .setPremium': {
    position: "absolute",
    width: '100%',
    textAlign:'center',
    bottom: "24px",
    top: 'unset',
    color: "var(--Basic-White, var(--0, #FFF))",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18.5px"
  },
  '& .CustomizeText': {
    fontFamily: "Quicksand",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "18.5px",
  textAlign: "left",
  width: "184px",
  height: "19px",
  top: "174px",
  left: "68px",
  gap: "0px",
  opacity: "0px"
  },

  '& .premiumText': {
    color: "var(--Primary-Blue-Blue-Primary, #62CDFF)",
    textAlign: "right",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18.5px",
    letterSpacing: "0.8px",
    textDecorationLine: "underline", 
    cursor:'pointer'
  },

  '& .settingBackground': {
    backgroundColor: '#fff',
    position: 'relative'
  },

  '& .settingBackgroundBlur': {
    filter: 'blur(2px)'
  },

  '& .settingImgWrapper': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FF8400',
    borderBottomRightRadius: '20px',
    paddingTop: '43px',
    "@media(max-width: 960px)": {
      height: 'auto',
  }
  },

  '& .profileTitle': {
    fontSize: '30px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    color: '#fff',
    marginBottom: '-32px',
    minHeight: 'calc(100% + 0px)',
    display: 'flex',
    alignItems: 'center',
  },

  '& .teddyImage': {
    display: 'none',  
  },

  '& .teddyImageMobile': {
    display: 'block',
    width: '400px',
    height: 'auto', 
    marginTop:'40px'
  },
'& .detailList': {
  width:'100%'
},
'& .detailAwards': {
  borderRadius: "10px",
  border: "2px solid  #F7F7F7",
  background: "#F7F7F7", 
  padding: "10px 20px 10px 20px",
  marginTop:'20px',
  width:'100%'
},

'& .AwardImage': {
  display: 'flex', 
  justifyContent: 'space-around', 
  marginTop:'20px'
},
'& .viewMore': {
     display:'flex', 
     justifyContent:'space-between', 
     paddingTop:'20px'

},

  '& .contentContainer': {
    flex: '6',
    backgroundColor: '#fff',
    overflow: 'scroll',
    padding: '32px 0 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    scrollbarWidth: 'none'
  },

  '& .contentContainer::-webkit-scrollbar': {
    display: 'none'
  },

   '& .userBox': {
    width: "100%",
    height: "65px",
    borderRadius: "10px",
    background: "#F7F7F7", 
    display:'flex',
    padding: "0px 20px 0px 20px", 
    alignItems:'center'
   },
   '& .freemiumBox': {
    width: "100%",
    minHeight: "43px",
    borderRadius: "10px",
    background: "#F7F7F7", 
    display:'flex',
    padding: "0px 20px 0px 20px",
    marginTop:'20px',
    alignItems:'center'
   },
   '& .profileName': {
       padding:'10px 10px 10px 15px'
   },

   '& .mainName': {
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18.5px",
    letterSpacing: "1px"
   },

   '& .subName': {
    color: "var(--Primary-Neutrals-Warm-gray-500, #7F7F7F)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "13px",
    letterSpacing: "1px"
   },

 '& .profileMode': {
  color: "var(--Primary-Blue-Dark-Gray, #363636)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Quicksand",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "18.5px",
  letterSpacing: "1px"
 },

 '& .profilePoint': {
  color: "var(--Primary-Neutrals-Warm-gray-500, #7F7F7F)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Quicksand",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "8px"
 },
   '& .moodShown': {
    padding: "10px 0px",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px"
   },

   '& .awardHead': {
    color: "var(--Primary-Blue-Dark-Gray, #363636)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    letterSpacing: "1px"
   },

   '& .viewButton': {
    color: "var(--Primary-Blue-Blue-Primary, #62CDFF)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "13px",
    letterSpacing: "1px", 
    cursor:'pointer'
   },


  [theme.breakpoints.up('md')]: {
    paddingLeft: '240px',
    flexDirection: 'row',

    '& .settingBackground': {
      flex: 5,
    },

    '& .setPremium': {
      top: "40%",
      bottom: 'unset',
    },

    '& .settingImgWrapper': {
      justifyContent: 'center',
      paddingTop: 'unset',
      position: 'relative'
    },

    '& .teddyImage': {
      width: "400px",
      display: "block",
      margin: "0 auto",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0
    },

    '& .teddyImageMobile': {
      display: 'none',
      flex: 5,
    },

    '& .settingTitle': {
      fontSize: '30px',
      marginBottom: 'unset',
    },
    '& .shownTop': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#FF8400',
      borderBottomRightRadius: '20px',
    },
  },

  [theme.breakpoints.up('lg')]: {
    paddingLeft: '282px', 
    '& .settingImgWrapper': {
      position: 'relative'
    },

    '& .teddyImage': {
      width: "400px",
      display: "block",
      // transform: "translate(-50%, 0px)"
    },
  }
}))
// Customizable Area End
