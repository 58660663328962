export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const BackArrow = require("../assets/BackArrow.png");
export const Language = require("../assets/lunguage.png");
export const RadioBtn_off = require("../assets/redioBtn.png");
export const RadioBtn_on = require("../assets/redioBtnOn.png");
export const uk_flag = require('../assets/uk_Flag.jpeg');
export const Save = require('../assets/save.png');
export const imgLanguageHeader = require('../assets/language_header.svg');
export const imgCheckedCheckbox = require('../assets/checked_checkbox.svg');
export const imgUncheckCheckbox = require('../assets/uncheck_checkbox.svg');
