import { Box, Snackbar, Typography, styled } from '@material-ui/core';
import React, { Component } from 'react';
import { Error } from "@material-ui/icons";
import DoneIcon from '@material-ui/icons/Done';
interface NetworkState {
  isOnline: boolean;
  showErrorAlert: boolean;
  showOnlineMessage: boolean;
}

class NetworkStatusChecker extends Component<{}, NetworkState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isOnline: navigator.onLine,
      showErrorAlert: false,
      showOnlineMessage: false,
    };
  }

  componentDidMount() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ isOnline: true, showErrorAlert: false, showOnlineMessage: true });
  };

  handleOffline = () => {
    this.setState({ isOnline: false, showErrorAlert: true, showOnlineMessage: false });
  };

  handleCloseErrorAlert = () => {
    this.setState({ showErrorAlert: false });
  };

  handleCloseOnlineMessage = () => {
    this.setState({ showOnlineMessage: false });
  };

  render() {
    const { isOnline, showErrorAlert, showOnlineMessage } = this.state;

    return (
      <NetworkStyle>
        <Snackbar
          className="snackBar"
          open={showErrorAlert}
          onClose={this.handleCloseErrorAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={2000}
        >
          <Box className="snackBarWrapper">
            <Error />
            <Typography className="snackBarText" >
              {isOnline ? (
                <p>You are online.</p>
              ) : (
                <p className="error-message">You are offline. Please check your network connection.</p>
              )}
            </Typography>
          </Box>
        </Snackbar>

        {showOnlineMessage && (
          <Snackbar
            className="onlineMessage"
            open={showOnlineMessage}
            onClose={this.handleCloseOnlineMessage}
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            autoHideDuration={2000}
          >
            <Box className="snackBarWrapperSuccess">
              <DoneIcon/>
              <Typography className="snackBarText" >
                <p>You are back online.</p>
              </Typography>
            </Box>
          </Snackbar>
        )}
      </NetworkStyle>
    );
  };
}

export default NetworkStatusChecker;

const NetworkStyle = styled("div")({
  '& .snackBarWrapper': {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    maxWidth: '300px',
    backgroundColor: '#F96855',
    borderRadius: '4px',
    padding: '6px 16px',
  },

  "& .snackBarWrapperSuccess":{
    maxWidth: '300px',
    backgroundColor: '#2e7d32',
    borderRadius: '4px',
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    color: '#fff',
  },

  '& .snackBarText': {
    fontSize: '16px',
    overflow: 'hidden',
    wordWrap: 'break-word',
    fontFamily: "Quicksand",
    marginLeft: '12px',
    fontWeight: '500',
  },
})
