import React from "react";
// Customizable Area Start
import { Box, Typography, styled } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PremiumDashboardController from "./PremiumDashboardController.web";

 class CenterPortion extends PremiumDashboardController {

    render() {

        return (
            <SettingContainer>
               <Box className="setting-img-wrapper" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography className="settingTitle">{this.props.t('Settings')}</Typography>
                    <img src={this.state.settingTeddy} className="teddy-setting-image" alt="Teddy Setting" style={{ alignSelf: 'center' }} />
                </Box>
        </SettingContainer>
    )
    }
}

export default withTranslation()(CenterPortion)
const SettingContainer = styled(Box)(({ theme }) => ({
    "& .setting-img-wrapper": {
        height: '100%',
        flexDirection: 'column',
        paddingLeft: '33px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        display: 'flex',
        paddingBottom: '30px',
        position: 'relative',

    },
    "& .teddy-setting-image": {
        bottom: '0px',
        Width: '245px',
        marginLeft: '0',
        position: 'absolute',
        display: 'block',
        marginRight: '10px',
        padding: '0',
        left: 'calc(45% - 207px)',

    },
    '& .settingTitle': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '-32px'
      },
    [theme.breakpoints.up('md')]: { 
        "& .setting-img-wrapper": {
            paddingTop: 'unset'
        },
        "& .teddy-setting-image": {
            width: '360px',
            maxWidth: 'unset',
            left: 'calc(45% - 140px)'
        },
        '& .settingTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
          }
        },
       
        [theme.breakpoints.up('lg')]: {
            "& .teddy-setting-image": {
                left: 'calc(45% - 140px)',
                width: '360px'
            }
        }, 
        [theme.breakpoints.down('sm')]: { 
            "& .teddy-setting-image": {
                display: 'none'
            }
        },
        [theme.breakpoints.down('xs')]: { 
            "& .teddy-setting-image": {
                display: 'none'
            }
        },
    
}))
// Customizable Area End