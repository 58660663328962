import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils"
export const configJSON = require("./config");
import {skipachiever, skipchallengesIcon, skipfeedback, skipsocialiser, TeddyBlue, TeddyYellow, TeddyPurple, TeddyGreen, TeddyLightGreen, TeddyBrown, TeddyRed, TeddyOrange,} from "./assets"
import { IError } from "./MedianController.web";
import { TFunction } from "i18next";

export interface IXpTrackerAttributes{
     account_id: number,
     event_point_id: number,
     accounts_booster_id:number| null,
     xp: number,
     description: string,
     metadata: {
      lesson_name:string
     },
     created_at: string,
     updated_at: string,
     event_point_key: string,
     event_point_category_type:string
}

export interface IXPTrackerResponse{
    data:Array<IXpTracker>
}

export interface IXPTrackerKeyData{
    [key:string]: IXPTrackerData
  }

  export interface IXPTrackerData{
    image:string|null,
    description:string,
    backgroundColor:string
  }


export interface IXpTracker{
    id:string,
    type:string,
    attributes:IXpTrackerAttributes
}

export interface ISpecificAccountAttributes{
  payment_premium:boolean,
  learning_premium:boolean,
  total_xp:number,
  first_name:string|null,
  last_name:string|null 
}

export interface ISpecificAccount{
  id:string,
  type:string,
  attributes:ISpecificAccountAttributes
}

export interface ISpecificAccountResponse{
  data:ISpecificAccount
}

// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t:TFunction
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // open: boolean;
    XpData:Array<IXpTracker>
    total_xp:number
    premiumUser:boolean
    loading:boolean
    avtarSadImage:string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class XPTrackerController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
     getXpTracker: string="";
     getSpecificAccount:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            XpData:[],
            total_xp:0,
            premiumUser:false,
            loading:false,
            avtarSadImage:''
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        this.getXpTrackerAPiCall()
        this.getSpecificAccountApiCall()
    }
    // Customizable Area End

    // Customizable Area Start
        getXpTrackerAPiCall=()=>{
          this.setState({
            loading:true
          })
        const token = localStorage.getItem("token")
        this.getXpTracker = sendAPIRequest(configJSON.getXpTrackerEndPoint,{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              token
            },
          })
        }


        getSpecificAccountApiCall=()=>{
          const token = localStorage.getItem("token")
          this.getSpecificAccount = sendAPIRequest("account_block/accounts/specific_account",{
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                token
              },
            })
        }

        getColorMap = () => {
          return {
              '#62cdff':  TeddyBlue ,
              '#fdce36':  TeddyYellow ,
              '#a95edb':  TeddyPurple ,
              '#2bce8c':  TeddyGreen,
              '#b7f0ad':  TeddyLightGreen,
              '#884a39':  TeddyBrown,
              '#f96855':  TeddyRed ,
              '#ff8400':  TeddyOrange,
          };
      };

        getXpTrackerData=(XPTrackerattributes:IXpTrackerAttributes)=>{
          const xpData:IXPTrackerData = this.XpTrackerData[XPTrackerattributes.event_point_key]
          return {...xpData,xpPoints:XPTrackerattributes.xp}       
        }


        handleXpPoints=(xpItem:string)=>{
            switch(xpItem){
              case "daily_goal_1_lessons":
                return 1
              case "daily_goal_2_lessons":
                return 2  
              case "daily_goal_3_lessons":
                return 3 
              case "daily_goal_4_lessons":
                return 4
            
              default :
                return 2
            }
        }

      handleProgressBar=(responseJson:ISpecificAccountResponse)=>{
        this.setState({
          premiumUser:responseJson.data.attributes.payment_premium,
          total_xp:responseJson.data.attributes.total_xp,
        })
      }
      
      handleGoBack=()=>{
        this.props.navigation.goBack()
      }

      getImage = ()=>{
        const avatarColor = localStorage.getItem("avatarColor")
      const avatarImage:{ [key:string]:string }= this.getColorMap()
      const image = avatarColor??"#62cdff"
     
        return avatarImage[image]
      
      }

      getTotalXpProgressValue=()=>{
        return this.state.total_xp > 1000 ? 100 : this.state.total_xp / 10
      }

        XpTrackerData:IXPTrackerKeyData={
          "daily_goal_2_lessons":{
            description:this.props.t("Daily goal 2 completed!"),
            backgroundColor:'#B7F0AD',
            image:null
           },
            "good_answer_on_lesson_quiz":{
              description:this.props.t("Great Answer!"),
              backgroundColor:"#C9EEFF",
              image:skipachiever

            },
            "daily_goal_1_lesson":{
              description:this.props.t("Daily goal 1 completed!"),
              backgroundColor:'#B7F0AD',
              image:null
             },
            "finish_lesson":{
              description:this.props.t("Lesson completed!"),
              backgroundColor:"#C9EEFF",
              image:skipachiever
             },
               
            "daily_goal_4_lessons":{
             description:this.props.t("Daily goal 4 completed!"),
             backgroundColor:'#B7F0AD',
             image:null
            },
            "streak_goal_achieved":{
             description:this.props.t("You've achieved your streak goal!"),
             backgroundColor:'#FEF3C7',
             image:skipchallengesIcon
            },
            "daily_goal_3_lessons":{
              description:this.props.t("Daily goal 3 completed!"),
              backgroundColor:'#FEF3C7',
              image:null
             },
            "share_scorecard":{
             description:this.props.t("Your social sharing earned you XP!"),
             backgroundColor:'#FEF3C7',
             image:skipsocialiser
            },
            "share_microlesson":{
            description:this.props.t("Your social sharing earned you XP!"),
            backgroundColor:'#FEF3C7',
            image:skipsocialiser
            },
            "feedback":{
            description:this.props.t("Thanks for your feedback, you've earned XP!"),
            backgroundColor:'rgb(169 94 219 / 30%)',
            image:skipfeedback
            },           
            "rate":{
            description:this.props.t("Thanks for your feedback, you've earned XP!"),
            backgroundColor:'rgb(169 94 219 / 30%)',
            image:skipfeedback
            },
            "report_a_problem":{
            description:this.props.t("Thanks for your feedback, you've earned XP!"),
            backgroundColor:'rgb(169 94 219 / 30%)',
            image:skipfeedback
            },            
            "week_of_inactivity":{
            description:this.props.t("Oh no, you've lost XP for inactivity!"),
            backgroundColor:'rgba(249, 104, 85, 0.3)',
            image:this.getImage()
            },

            "challenge_start_challenge":{
             description:this.props.t("Challenge started!"),
             backgroundColor:'#B7F0AD',
             image:skipchallengesIcon
            },
            "challenge_good_answer":{
             description:this.props.t("Great answer!"),
             backgroundColor:'#B7F0AD',
             image:skipchallengesIcon
            },
            "challenge_share_challenge":{
             description:this.props.t("Your social sharing earned you XP!"),
             backgroundColor:'#FEF3C7',
             image:skipsocialiser
            },
            "share_exersice":{
             description:this.props.t("Your social sharing earned you XP!"),
             backgroundColor:'#FEF3C7',
             image:skipsocialiser
            },
            "challenge_share_scorecard":{
            description:this.props.t("Your social sharing earned you XP!"),
            backgroundColor:'#FEF3C7',
            image:skipsocialiser
            },
            "challenge_report_a_problem":{
             description:this.props.t("Thanks for your feedback, you've earned XP!"),
             backgroundColor:"rgb(169 94 219 / 30%)",
             image:skipfeedback
            }
        }



        handleXpTracker=(responseJson:IXPTrackerResponse & IError)=>{

            if(!responseJson.errors){
                this.setState({
                    loading:false,
                    XpData:responseJson.data
                })
            }

        }

        async receive(from: string, message: Message) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
              const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              if (apiRequestCallId && responseJson) {

                if(apiRequestCallId === this.getXpTracker){
                    this.handleXpTracker(responseJson)
                }else if(apiRequestCallId === this.getSpecificAccount){
                   this.handleProgressBar(responseJson)
                }

                
              }
        }     
    // Customizable Area End

    // Customizable Area End
}