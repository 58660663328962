// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import React from "react";
import { sendAPIRequest } from "../utils";
import html2canvas from 'html2canvas'
import { Message } from '../../../framework/src/Message'
import { handleSaveImage } from "./ExerciseComponents";
import { ShareRecordType } from "../../../blocks/educationalgamification/src/MicroLessonExercise";

export interface Props {
    id: string;
    t?: any;
    navigation?: any
    open: boolean
    handleClickClose: () => void
    children?: any
    onAfterShare: () => void
    onUpdateXp: (token: string) => void
    shareRecordType: ShareRecordType
    lessonAttemptId?: string | null
    lessonId: string
    microLessonId: number
    assessmentId: number
    questionBankId: number
}

interface S {
    dynamicShareUrl: string
    socialType: string
}

interface SS {

}

export default class ShareModalController extends BlockComponent<Props, S, SS> {
    submitShareActionId: string = ''

    whatsappRef: React.RefObject<HTMLButtonElement>
    facebookRef: React.RefObject<HTMLButtonElement>
    twitterRef: React.RefObject<HTMLButtonElement>
    telegramRef: React.RefObject<HTMLButtonElement>

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.whatsappRef = React.createRef<HTMLButtonElement>()
        this.facebookRef = React.createRef<HTMLButtonElement>()
        this.twitterRef = React.createRef<HTMLButtonElement>()
        this.telegramRef = React.createRef<HTMLButtonElement>()

        this.state = {
            dynamicShareUrl: '',
            socialType: '',
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        )

        if (responseJson && !responseJson?.errors && apiRequestCallId === this.submitShareActionId) {
            const token = localStorage.getItem('token') || ''
            this.props.onUpdateXp(token)
            const imageUrl = responseJson?.data?.attributes?.image

            this.setState({ dynamicShareUrl: imageUrl }, () => {
                switch (this.state.socialType) {
                    case 'WhatsApp':
                        this.whatsappRef.current && this.whatsappRef.current.click()
                        break
                    case 'Facebook':
                        this.facebookRef.current && this.facebookRef.current.click()
                        break
                    case 'Twitter':
                        this.twitterRef.current && this.twitterRef.current.click()
                        break
                    case 'Telegram':
                        this.telegramRef.current && this.telegramRef.current.click()
                        break
                    default:
                        console.log('Error sharing!')
                }
            })
            this.props.onAfterShare()
        }
    }

    submitShareAction = (socialType: string) => {
        this.setState({ socialType })
        const element = document.getElementById("img-for-share") as HTMLElement
        if (element) {
            html2canvas(element, { useCORS: true })
                .then((canvas: HTMLCanvasElement) => {
                    const imgData = canvas.toDataURL("image/png");
                    const blob = this.base64ToBlob(imgData, "image/png");

                    const shareRecordType = this.getShareRecordType(this.props.shareRecordType)

                    const formData = new FormData();
                    formData.append("data[image]", blob, "image.png");
                    formData.append("data[share_record_type]", shareRecordType)
                    formData.append("data[lesson_id]", this.props.lessonId)

                    if ((this.props.shareRecordType === 'EXERCISE' || this.props.shareRecordType === 'SCORECARD') && this.props.lessonAttemptId) {
                        formData.append("data[lesson_attempt_id]", this.props.lessonAttemptId)
                    }

                    if (this.props.shareRecordType !== 'SCORECARD') {
                        formData.append("data[assessment_id]", String(this.props.assessmentId))
                    }

                    if (this.props.shareRecordType === 'EXERCISE') {
                        formData.append("data[micro_lesson_id]", String(this.props.microLessonId))
                        formData.append("data[question_bank_id]", String(this.props.questionBankId))
                    }

                    this.submitShareActionId = sendAPIRequest(
                        "bx_block_share/share",
                        {
                            method: "POST",
                            headers: {
                                token: localStorage.getItem('token'),
                            },
                            body: formData
                        }
                    )

                })
                .catch(err => {
                    console.log('Share error!', err);
                });
        }
    }

    getBase64Image = () => {
        const element = document.getElementById("img-for-share");
        if (element) {
            html2canvas(element)
                .then((canvas: any) => {
                    const imgData = canvas.toDataURL("img/png");
                    const base64Parts = imgData.split(",");
                    const imageType = base64Parts[0].split(":")[1].split(";")[0];
                    const imageBlob = this.base64ToBlob(base64Parts[1], imageType)
                    return imageBlob
                })
                .catch(err => {
                    console.log('Share error!', err)
                })
        }
    }

    base64ToBlob = (base64: string, mime: string = ''): Blob => {
        const sliceSize = 1024;
        const byteChars = window.atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        const byteArrays: Uint8Array[] = [];

        for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
            const slice = byteChars.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mime });
    }

    getShareRecordType = (shareRecordType: ShareRecordType) => {
        switch (shareRecordType) {
            case 'EXERCISE':
                return 'exercise'
            case 'CHALLENGE':
                return 'challenge'
            case 'SCORECARD':
                return 'scorecard'
            default:
                return 'exercise'
        }
    }

    handleSaveShareImage = () => {
        handleSaveImage()
        this.props.onAfterShare()
    }
}
// Customizable Area End