// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import i18next from "i18next";
export const configJSON = require("./config");
import {bluePartyTeddy, redPartyTeddy, orangePartyTeddy, purplePartyTeddy, brownPartyTeddy, greenPartyTeddy, mintPartyTeddy, yellowPartyTeddy} from '../../../components/src/assets'
export interface Props {
    id?: string;
    t?:any;
    navigation: {
        goBack(): void;
        navigate: (screenName: string) => void,
    };
}

interface BeforeInstallPromptEvent extends Event {
    prompt(): Promise<void>;
    userChoice: Promise<{
      outcome: 'accepted' | 'dismissed',
      platform: string
    }>;
  }
  
  declare global {
    interface Window {
      deferredPrompt: BeforeInstallPromptEvent | null;
    }
  }
export interface S {
    // Customizable Area Start
    selectValue: string;
    spinner: boolean;
    open: boolean;
    emailSignUpAddress: {userName:string}
    emailName:string | null;
    deferredPrompt: Event | null;
    partyTeddyBear: any
    // Customizable Area End
}
export interface SS {
    id: string;
}
export default class EmailRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    accountCreationCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage)
            // Customizable Area End
        ];
        this.state = {
            // Customizable Area Start
            selectValue: '',
            spinner: false,
            open: false,
            emailSignUpAddress:{userName:' '},
             emailName:'',
             deferredPrompt: null,
             partyTeddyBear:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start


    async componentDidMount() {
        this.updateTeddyBear()
        setTimeout(() => { this.setState({ spinner: !this.state.spinner,}) }, 3000);

        const email:string | null=localStorage.getItem('email')
        this.setState({
            emailSignUpAddress: JSON.parse(localStorage.getItem('emailSignUpAddress') ?? ''),
            emailName:email
          })
    }

    async componentWillUnmount() {
        window.removeEventListener('beforeinstallprompt', this.handleBeforeInstallPrompt);
    }    
  
    // Customizable Area End
    // Customizable Area Start
    
    selectHandleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ selectValue: event.target.value as string });      
       i18next.changeLanguage(event.target.value as string )
    }

    handleBeforeInstallPrompt = (event: any) => {
        event.preventDefault();
    };

    getStarted = () => {
        this.props.navigation.navigate('EmailRegistrationWeb')
    }
    haveAccount = () => {
        this.props.navigation.navigate('EmailLogin')
    }
    handleOpen = () => {
        this.setState({open:true})
    };

    handleClose = () => {
       this.props.navigation.navigate('LandingPageWeb')
    };
    
    installApp= () =>{
        if (window.deferredPrompt) {
          (window.deferredPrompt).prompt();
          (window.deferredPrompt).userChoice.then((choiceResult: any) => {
            if (choiceResult.outcome === 'accepted') {
                this.setState({open:false})
                this.props.navigation.navigate('LandingPageWeb')
              console.log('User accepted the installation');
            } else {
              console.log('User declined the installation');
            }
          });
        }
    
    }

    updateTeddyBear = () => {
        const color = localStorage.getItem('avatarColor') || '#62cdff';
        const colorToUpdateTeddy:any = {
          '#ff8400': orangePartyTeddy,
          '#62cdff': bluePartyTeddy,
          '#b7f0ad': mintPartyTeddy,
          '#a95edb': purplePartyTeddy,
          '#f96855': redPartyTeddy,
          '#884a39': brownPartyTeddy,
          '#fdce36': yellowPartyTeddy,
          '#2bce8c': greenPartyTeddy
        };
      
        const partyTeddy = colorToUpdateTeddy[color] || bluePartyTeddy;
            this.setState({  partyTeddyBear: partyTeddy });
      } 
    // Customizable Area End
}
// Customizable Area End