// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { Props, configJSON } from "./StripePayments";
import { sendAPIRequest } from "../../../components/src/utils";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "framework/src/IBlock";
import { amexCardLogo, dinerCardLogo, discoverCardLogo, jcbCardLogo, masterCardLogo, unionCardLogo, visa_img } from "./assets";
const baseURL = require("../../../framework/src/config.js").baseURL

interface S {
    openPopup: boolean;
    isNewCard: boolean;
    showDeleteCard: boolean;
    formData: {
        id: string;
        cardholderName: string;
        cardNumber: string;
        expire: string;
        cvv: string;
        agreeToTerms: boolean
    }
    itemsArray: Array<any>;
    selectedCardIndex: number;
    amountToPay: string;
    formSubmitTrigger: boolean;
    cardErrorMessageStripe: string;
    edittedField: {
        cardholderName: boolean;
        cardNumber: boolean;
        expire: boolean;
        cvv: boolean;
    }
    getCardListLoading: boolean;
    createNewCardLoading: boolean;
}

interface SS {
    id: string;
    getListPlanCallId: string;
}

export default class StripePaymentsMethodController extends BlockComponent<Props, S, SS> {
    createCardTokenCallId: string = ''
    getListCardCallId: string = ''
    deleteCardCallId: string = ''

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handlePopupClose = this.handlePopupClose.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickGoBack = this.handleClickGoBack.bind(this);
        this.handleShowDeleteCard = this.handleShowDeleteCard.bind(this);
        this.handleClickDeleteCard = this.handleClickDeleteCard.bind(this);
        this.handleClickAddNewCard = this.handleClickAddNewCard.bind(this);
        this.handleChangeCarousel = this.handleChangeCarousel.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.disablePayBtn = this.disablePayBtn.bind(this);
        this.handleStripeCardError = this.handleStripeCardError.bind(this);
        this.handleClickCancel = this.handleClickCancel.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            openPopup: false,
            isNewCard: false,
            showDeleteCard: false,
            formData: {
                id: '',
                cardholderName: '',
                cardNumber: '',
                expire: '',
                cvv: '',
                agreeToTerms: false
            },
            itemsArray: [],
            selectedCardIndex: 0,
            amountToPay: '',
            formSubmitTrigger: false,
            cardErrorMessageStripe: '',
            edittedField: {
                cardholderName: false,
                cardNumber: false,
                expire: false,
                cvv: false
            },
            getCardListLoading: true,
            createNewCardLoading: false
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleCloseCardError() {
        this.setState({ cardErrorMessageStripe: '' })
    }

    disablePayBtn() {
        if (this.state.itemsArray[this.state.selectedCardIndex]) {
            return false
        } else {
            if (this.state.isNewCard) {
                return !this.state.formData.cardNumber || !this.state.formData.cardholderName || !this.state.formData.cvv || !this.state.formData.expire
            } else {
                return true
            }
        }
    }

    handlePopupClose() {
        this.setState({ openPopup: false, showDeleteCard: false })
    }

    handleInputChange(event: any) {
        const { name, value, type, checked, textLength } = event.target;

        let formattedValue: string | boolean;

        if (type === 'checkbox') {
            localStorage.setItem('isCardSaved', String(checked));
            formattedValue = checked;
        } else if (name === "expire") {
            formattedValue = String(value).replace(/\D\//g, '')

            if (textLength === 2 && !this.state.formData.expire.includes("/") && !value.includes("/")) {
                formattedValue += "/"
            }

            formattedValue = formattedValue.slice(0, 5);
        } else if (name === "cardholderName") {
            formattedValue = value.replace(/[^a-zA-Z\s]/g, '');
        } else if (name === "cardNumber") {
            formattedValue = value.replace(/\D/g, '').slice(0, 16);
        } else if (name === "cvv") {
            formattedValue = value.replace(/\D/g, '').slice(0, 3);
        }

        this.setState((prevState => {
            return {
                ...prevState,
                formData: {
                    ...prevState.formData,
                    [name]: formattedValue
                },
                edittedField: {
                    ...prevState.edittedField,
                    [name]: true
                }
            }
        }))
    }

    handleClickCancel() {
        this.props.navigation.navigate("StripePaymentsView")
    }

    validate(key: string, value: string = "") {
        let isValidate = true;

        switch (key) {
            case "cardholderName":
                isValidate = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/.test(value);
                break;

            case "cardNumber":
                isValidate = /^\d{14,16}$/.test(value);
                break;

            case "expire":
                isValidate = /^(0[1-9]|1[0-2])\/(\d{2})$/.test(value);
                break;

            case "cvv":
                isValidate = /^\d{3}$/.test(value) && value !== "000";
                break;

            default:
                break;
        }

        return isValidate;
    }

    handleFormSubmit() {
        const selectedCard = this.state.itemsArray[this.state.selectedCardIndex];

        if (selectedCard && selectedCard.id) {
            localStorage.setItem("useSavedCard", "true");
            localStorage.setItem("cardId", selectedCard.id)
            this.props.navigation.navigate("ProcessingPayment");
        } else {
            this.setState({
                formSubmitTrigger: true
            })

            if (!this.validate("cardholderName", this.state.formData.cardholderName)) {
                return this.handleStripeCardError({ error: { message: "Invalid cardholder name." } })
            }

            if (!this.validate('cardNumber', this.state.formData.cardNumber)) {
                return this.handleStripeCardError({ error: { message: "Invalid card number." } })
            }

            if (!this.validate("expire", this.state.formData.expire)) {
                return this.handleStripeCardError({ error: { message: "Invalid expire date." } })
            }

            if (!this.validate('cvv', this.state.formData.cvv)) {
                return this.handleStripeCardError({ error: { message: "Invalid cvv code." } })
            }

            const values = {
                cardholderName: this.state.formData.cardholderName,
                cardNumber: this.state.formData.cardNumber,
                expire: this.state.formData.expire,
                cvv: this.state.formData.cvv,
            }

            if (Object.entries(values).every(value => this.validate(value[0], value[1]))) {
                localStorage.setItem("useSavedCard", "false");
                localStorage.removeItem("cardId");

                const stripeSecretKey = 
                    baseURL === 'https://tedix-335846-ruby.b335846.prod.germanywestcentral.az.svc.builder.ai'
                    ? 'pk_live_51NmhSIEHcDhXTyQacEzlg0W0BMJwipV9b22Fufx3I7zJ5RHHPigDP5CQG2JK9pdUd8i8una62KDQRrRfLc7M3dSO00fg7fFUSi' 
                    : 'pk_test_51NmhSIEHcDhXTyQaBywa47u5PhZGodznJiuFLKLKvl9hSNgLWaNNqI58r4CgIAcUwZ8OVloi0Ux6BWumK8e2LHFi00rUBxYUkB'

                const data = {
                    "card[number]": this.state.formData.cardNumber,
                    "card[exp_month]": this.state.formData.expire.split('/')[0],
                    "card[exp_year]": this.state.formData.expire.split("/")[1],
                    "card[cvc]": this.state.formData.cvv,
                    "card[name]": this.state.formData.cardholderName
                };

                const headers = {
                    "Authorization": `Bearer ${stripeSecretKey}`,
                    "Content-Type": configJSON.createStripeTokenContentType,
                }

                const body = Object.entries(data)
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                    .join('&')

                const requestMessage = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                )

                this.createCardTokenCallId = requestMessage.messageId;

                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.createStripeTokenEndPoint
                )

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.createStripeTokenMethod
                )

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    body
                )

                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    headers
                )

                runEngine.sendMessage(requestMessage.id, requestMessage)

                this.setState({ createNewCardLoading: true })
            }
        }
    }

    getListCard() {
        this.getListCardCallId = sendAPIRequest(
            configJSON.getListCardEndPoint,
            {
                method: configJSON.getListCardMethod,
                headers: {
                    "Content-Type": configJSON.apiContentType,
                    token: localStorage.getItem("token") || ""
                }
            }
        )
    }

    deleteCard() {
        this.deleteCardCallId = sendAPIRequest(
            `${configJSON.deleteCardEndPoint}/${this.state.itemsArray[this.state.selectedCardIndex].id}`,
            {
                method: configJSON.deleteCardMethod,
                headers: {
                    "Content-Type": configJSON.apiContentType,
                    token: localStorage.getItem("token") || ""
                },
            }
        )
    }

    handleStripeCardError(responseJson: any) {
        const { message } = responseJson.error;
        this.setState({ cardErrorMessageStripe: message })
    }

    async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        )

        if (apiRequestCallId === this.createCardTokenCallId) {
            const { id } = responseJson;

            this.setState({ createNewCardLoading: false })

            if (id) {
                localStorage.setItem("newCardToken", id);
                this.props.navigation.navigate("ProcessingPayment");
            } else {
                this.handleStripeCardError(responseJson);
            }
        }

        if (apiRequestCallId === this.getListCardCallId) {
            if (responseJson.data) {
                const paymentList = responseJson.data.map((item: any) => {
                    return {
                        id: item.id,
                        cardholderName: item.attributes.billing_details.name,
                        cardNumber: item.attributes.card.last4,
                        expire: `${item.attributes.card.exp_month < 10 ? "0" : ""}${item.attributes.card.exp_month}/${item.attributes.card.exp_year}`,
                        cvv: "••••",
                        brand: item.attributes.card.brand
                    }
                })

                this.setState({
                    itemsArray: paymentList
                })
            }

            this.setState({ getCardListLoading: false })
        }

        if (apiRequestCallId === this.deleteCardCallId) {
            if (responseJson.message) {
                this.getListCard();

                this.setState({
                    showDeleteCard: false,
                    openPopup: false
                })
            }
        }
    }

    handleClickGoBack() {
        this.props.navigation.goBack();
    }

    handleShowDeleteCard() {
        this.setState({
            showDeleteCard: true
        })
    }

    handleClickDeleteCard() {
        this.setState({
            openPopup: true,
        })
    }

    handleClickAddNewCard() {
        this.setState({
            isNewCard: true,
        })
    }

    handleChangeCarousel(currentItemObject: { object: any, index: number }, currentPageIndex: number) {
        this.setState({
            showDeleteCard: false,
            selectedCardIndex: currentPageIndex,
            isNewCard: false,
        })
    }

    async componentDidMount() {
        localStorage.setItem("isCardSaved", 'false')
        const amountToPay = localStorage.getItem("amountToPay") || ""
        this.setState({ amountToPay })
        this.getListCard();
    }

    getCardLogo(brand: string) {
        let imgUrl;

        switch (brand) {
            case "diners club":
                imgUrl = dinerCardLogo;
                break;
            case "visa":
                imgUrl = visa_img;
                break;
            case "amex":
                imgUrl = amexCardLogo;
                break;
            case "discover":
                imgUrl = discoverCardLogo;
                break;
            case "unionpay":
                imgUrl = unionCardLogo;
                break;
            case "jcb":
                imgUrl = jcbCardLogo;
                break;
            case "mastercard":
                imgUrl = masterCardLogo;
                break;
            default:
                imgUrl = visa_img
                break;
        }

        return imgUrl;
    }
}

// Customizable Area End