import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Message } from "framework/src/Message";
import { sendAPIRequest } from "../../../components/src/utils";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { TFunction } from "i18next";

export interface IList {
   option:string,
   optionData:string,
   data:number
}

 type GoalType = {
  [key:string]:string
 }

export interface IGoalProgressRes{
  medal:number,
  streak_goal_dates:Array<string>
  today_completed_lesson_counts:number
  daily_goal_settings:Array<GoalType>
  errors?:string
  streak_goal_settings:Array<GoalType>
}

export interface IUpdateGoalRes{
   id:string,
   type:string,
   attributes:Object,
   errors?:string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  stateOpen: boolean;
    drawerOpen:boolean;
    closeDrawer:()=>void;
    t:TFunction
    streakGoal?:string
    dailyGoal?:string
    updateCurrentProfile?:()=>void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  toggleGoalDetails:boolean;
  streakGoalDates:Array<string>;
  medal:number|undefined;
  streakGoalList:Array<IList>
  dailyGoalList:Array<IList>
  completeLesson:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class GoalController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    getGoalProgressId:string=''
    updateGoalId:string=''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            completeLesson:0,
            toggleGoalDetails:false,
            medal:undefined,
            streakGoalDates:[],
            streakGoalList:[],
            dailyGoalList:[]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start


  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if(prevProps.streakGoal!==this.props.streakGoal||
       prevProps.dailyGoal!==this.props.dailyGoal
      ){
        this.getGoalProgress()
      }
  }

    handleGoalDetails=()=>{
        this.setState({
            toggleGoalDetails:true
        })
    }


    handelGoBack=()=>{
      this.setState({
        toggleGoalDetails:false
    })
    }


    getGoalProgress=()=>{
      const token  = localStorage.getItem('token')
      this.getGoalProgressId = sendAPIRequest(
        "bx_block_profile/profiles/goal_progress",
        {
          method: "GET",
          headers: {
            token: token,
            "Content-Type": "application/json"
          },
        }
      )
    }


    handleGoalProgressResponse=(responseJson:IGoalProgressRes)=>{
      if(!responseJson?.errors){
      this.setState({
        streakGoalDates:responseJson.streak_goal_dates,
        medal:responseJson.medal,
        streakGoalList:this.convertListData(responseJson.streak_goal_settings),
        dailyGoalList:this.convertListData(responseJson.daily_goal_settings),
        completeLesson:responseJson.today_completed_lesson_counts
      })
      }
    }

    getDailyGoalProgress=()=>{
      const value = this.getGoalProgressValue(this.state.dailyGoalList,this.props.dailyGoal??"")*this.state.completeLesson
    return  value>100?100:value
    }
 
    getStreakGoalProgress=()=>{
      const value = this.getGoalProgressValue(this.state.streakGoalList,this.props.streakGoal??"")*this.state.streakGoalDates.length
    return  value>100?100:value
    }


    getGoalOptiondata=(list:Array<IList>,goal:string)=>{
       
      const GoalValue = list.find((item)=>item.optionData===goal)
      return GoalValue?.data ?? 0
    }


    getGoalProgressValue=(list:Array<IList> , goal:string)=>{
      let value = 0
       const dailyGoalValue = list.find((item)=>item.optionData===goal)
       if(dailyGoalValue){
        value = Math.floor(100/dailyGoalValue.data)
       }
      return value 
    }


    handleUserUpdateResponse=(responseJson:IUpdateGoalRes)=>{
     if(!responseJson?.errors){
      this.props.updateCurrentProfile && this.props.updateCurrentProfile()
     }
    }


    handleUpdateUser=(value:{dailyGoal:string,streakGoal:string})=>{
      this.updateGoalId = sendAPIRequest(
        "bx_block_profile/profiles/update_user_profile",
        {
          method: "PUT",
          headers: {
            token: localStorage.getItem('token'),
            "Content-Type": "application/json"
          },
          body: {
            streak_goal: value?.streakGoal,
            daily_goal:value?.dailyGoal
          }
        }
    )
    }


    getStreakGoal=(streakGoal:string)=>{
      const data = this.state.streakGoalList.find((item:IList)=>item.optionData=== streakGoal)
      return data?.option
    }
 
    getheLessonCountOfDailyGoal=(dailyGoal:string)=>{
      const data = this.state.dailyGoalList.find((item:IList)=>item.optionData=== dailyGoal)
      return data?.data
    }

    getStringValue=(inputString:string)=>{
     const wordsArray  =  inputString.split('_');
     wordsArray[0] = wordsArray[0].charAt(0).toUpperCase() + wordsArray[0].slice(1);
     for (let item = 1; item < wordsArray.length; item++) {
      wordsArray[item] = wordsArray[item].toLowerCase();
    }
    let resultString = wordsArray.join(' ');
    return resultString
    }


     convertListData=(data:Object)=>{
     const list =   Object.entries(data as {[key: string]: number}).map(([keys,value])=>{
             return {
              optionData:keys,
              data:value,
              option:this.getStringValue(keys)
             }
        })
        return list
     }


    convertDateStringtoDate=(DataArray:Array<string>)=>{
        const data : Array<Date> = DataArray.map((item:string)=>{
            return new Date(item.split('-').join("-"))
        })
        return data
    }
    


    apiSuccessCallBackController = (
      apiRequestCallId: string,
      responseJSON: IGoalProgressRes & IUpdateGoalRes
    ) => {
      const successCallbackMap = {
        [this.getGoalProgressId]: this.handleGoalProgressResponse,
        [this.updateGoalId]: this.handleUserUpdateResponse
      }
  
      if (apiRequestCallId) {
        const successCallback: (responseJSON: IGoalProgressRes & IUpdateGoalRes) => void = successCallbackMap[apiRequestCallId]
        !!successCallback && successCallback(responseJSON)
      }
    }
  

    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Received", message);
  
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      this.apiSuccessCallBackController(apiRequestCallId, responseJSON)
    }
    // Customizable Area End

}    