import React from "react";

export const configJSON = require("./config");
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import {
  NativeSyntheticEvent,
  NativeScrollEvent,
  ScrollView,
} from "react-native";
import { IconsType } from "./personas-avatar/types";
// Customizable Area End

export interface Props {
  title: string;
  icons?: IconsType;
  colors?: IconsType;
  selectedIcon?: string;
  selectedColor?: string;
  onIconPress?: (value: string) => void;
  onColorPress?: (value: string) => void;
  type: string;
  open: string | null;
  openToggle: Function;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  currentXOffset: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SelectOptionsAvatarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  svgRef: React.RefObject<ScrollView>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      currentXOffset: 0,
      // Customizable Area End
    };

    // Customizable Area Start
    this.svgRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  rightArrow = () => {
    this.svgRef.current &&
      this.svgRef.current.scrollTo({
        x: this.state.currentXOffset + 200,
        animated: true,
      });
  };

  leftArrow = () => {
    this.svgRef.current &&
      this.svgRef.current.scrollTo({
        x: this.state.currentXOffset - 200,
        animated: true,
      });
  };

  handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    this.setState({
      currentXOffset: event.nativeEvent.contentOffset.x,
    });
  };

  getColor = (): string => {
    const selected = this.props.selectedColor as keyof IconsType;
    return this.props.colors && this.props.selectedColor
      ? this.props.colors[selected]
      : "";
  };
  // Customizable Area End
}
