import React from "react";
// Customizable Area Start

import { styled } from "@material-ui/core/styles";
import { Box, Modal, IconButton } from '@material-ui/core'
import ChallengeShuffleModalController from "./ChallengeShuffleModalController.web";
import { cross_icon, downArrow, roulette, upArrow } from "./assets";
import "./Challenge.css"
import { withTranslation } from "react-i18next";
// Customizable Area End

 class ChallengeShuffleModal extends ChallengeShuffleModalController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {t} = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <SettingContainer
                open={this.props.open}
                onClose={this.props.onClose}
                style={{ zIndex: 2000 }}
                keepMounted
            >
                <Box className="Modal_style">
                    <Box className="Boxcontainer">
                        <Box className="cross-Icon">
                            <IconButton onClick={this.props.onClose}>
                                <img src={cross_icon} />
                            </IconButton>
                        </Box>
                        <Box className="content">
                            <Box>
                                <img src={upArrow} />
                                <object type="image/svg+xml" key={this.state.animationKey} data={roulette} height={70} width={70} className="image-rotation">svg-animation</object>
                                <img src={downArrow} style={{ marginBottom: 32 }} />
                            </Box>
                            <Box className="content_title">
                                {t("Challenge shuffler")}
                            </Box>
                            <Box className="content_description">
                                {t("Hold tight as the challenge shuffler selects your next exciting learning journey!")}
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ width: "100%", position: 'fixed', bottom: 1 }}>
                        <Box className={this.props.open?"bottomBorder":"bottom complete"}></Box>
                    </Box>
                </Box>
            </SettingContainer>
            // Customizable Area End
        )
    }

}

// Customizable Area Start
export default withTranslation()(ChallengeShuffleModal)
export const SettingContainer = styled(Modal)(({ theme }) => ({

    '& .Modal_style': {
        width: 295,
        background: '#DCFFD6',
        height: '296px',
        border: '5px solid #fff',
        borderRadius: '47px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    '& .Boxcontainer': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    '& .cross-Icon': {
        justifyContent: 'flex-end',
        width: '100%',
        paddingTop: '20px',
        paddingRight: '16px',
        display: 'flex',
    },
    '& ,content': {
        textAlign: 'center'
    },

    '& .content_title': {
        marginTop: '22px',
        color: ' var(--primary-blue-dark-gray, #363636)',
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "17px", /* 94.444% */
        letterSpacing: " 0.144px",
        height: '17px',
    },
    '& .content_description': {
        padding: '8px 32px',
        color: 'var(--primary-blue-dark-gray, #363636)',
        textAlign: 'center',
        fontFamily: 'Quicksand',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
    },

    '& .image-rotation':{
        
    }


}))

// Customizable Area End