import React from "react";
// Customizable Area Start
import { Box, styled, } from "@material-ui/core";
import NavigateLayout from "../../../components/src/NavigateLayout";
import CenterCard from "../../../components/src/CenterCard.web";
import {  rectangle,  } from '../../landingpage/src/assets';
import Trophies from '../../landingpage/src/Trophies.web';
import Goal from '../../landingpage/src/Goal.web';
import UnitTopic from "./UnitsTopic.web";
import LessonMaterialController from "./LessonMaterialController.web";
import { closedBook,listIcon,groupMaskMath } from "./assets";
// Customizable Area End

export default class LessonMaterial extends LessonMaterialController {
  // Customizable Area Start
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigateLayout menuName="Menu">
        <SettingContainer style={{background: '#FEEBAF',}}>
          <Box className="mainContentWrapper">
            <Box className="settingBackground">

              <Box className="settingImgWrapper"
              style={{ backgroundImage: `url(${groupMaskMath})` }}
              >
                <Box className="nomargin">
                  <CenterCard 
                  Topic={this.state.topicName} 
                  Unit={this.state.unitName} 
                  openDrawer={this.state.openDrawer} 
                  trophiesOpen={this.state.trophiesOpen} 
                  goalsOpen={this.state.goalsOpen} 
                  data-testid="centerCard"
                  topicIcon={closedBook}
                  multilevelIcon={listIcon}
                  toggleState ={this.goBackScreen} 
                  handleOpenDrawer={(name:string)=>this.handleOpenDrawer(name)} 
                  navigation={this.props.navigation}/>
                  <Trophies data-testid ="trophies"stateOpen={this.state.trophiesOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer}/>
                  <Goal data-testid ="goal" stateOpen={this.state.goalsOpen} drawerOpen={this.state.drawerSide} closeDrawer={this.closeDrawer}/>
                </Box>
              </Box>
            </Box>
            <Box className="mainLanding">
              <Box style={{ position: "relative" }}>
                <DrawerStyle>
                <UnitTopic data-testid="UnitTopics" data={this.state.LessonData} handleGoBack={this.handleBackToNonPremiumUser} isPremium={this.state.isPremium}/>
                </DrawerStyle>
              </Box>
            </Box>

          </Box>

        </SettingContainer>
      </NavigateLayout>
      // Customizable Area End
    )
  }
}

// Customizable Area Start

export const SettingContainer = styled(Box)(({ theme }) => ({
  background: '#FEEBAF',
  height: '100%',
  width: '100%',
  "@media(max-width: 959px)": {
    background: '#FEEBAF',
  },
  '& .nomargin': {
    width: '100% !important',
  },

  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 ':{
    backgroundColor:'var(--secondary-orange, #FF8400) !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .rectangleBlock1 > .rectangleBlock':{
    backgroundColor:'var(--secondary-orange, #FF8400) !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox':{
    backgroundColor:'var(--secondary-orange, #FF8400) !important',
  },
  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle':{
    backgroundColor:'var(--secondary-orange, #F17D01) !important'
  },

  '& .nomargin > .MuiBox-root > .mainWhiteBox > .backImage > .viewRectangle > .units':{
    backgroundColor:'var(--secondary-orange, #F17D01) !important',
    color : '#fff',
    opacity:0.9,
   },

  '& .mainContentWrapper': {
    display: 'flex',
    flexDirection: 'column',
  },
  
  '& .settingBackground': {
    backgroundColor: '#fff',
    height: 'auto'
  },
  '& .fontSet': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    fontFamily: 'Nunito',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '900',
    lineHeight: '18.5px',
    letterSpacing: '0.128px',
  },
  '& .mainLanding': {
    flex: 6,
    alignItems: 'center',
    margin: 'auto',
    // backgroundImage: `url(${groupDecoration})`,
    '@media(max-width:960px)':{
      margin:0,
    }
  },

  '& .button_position': {
    position: "absolute",
    left: "42%",
    bottom: "5%",
    "@media(max-width: 600px)": {
      left: "36%"
    },
    "@media(max-width: 959px)": {
      bottom: '27%',
    },
  },

  '& .BackImage': {
    alignSelf: "flex-start",
    '& .MuiIconButton-root': {
      padding: "2px"
    },
    "@media(max-width: 520px)": {
      marginRight: '0px',
    },
  },

  '& .iconContainerscroll': {
    backgroundImage: `url(${rectangle})`,
    width: '261px',
    gap: '15px',
    padding: "10px",
    height: '65px',
    display: 'flex',
    background: "#fff",
    borderRadius: "100px 20px 20px 100px",
    marginTop: "24px",
    alignItems: "center",
    backgroundColor: 'var(--primary-neutrals-warm-gray-50, #F2F2F2)'
  },

  '& .Material-btn': {
    zIndex: `100`,
    color: "var(--primary-blue-blue-primary, #62CDFF)",
    textDecoration: "uppercase",
    lineHeight: "12px", /* 75% */
    letterSpacing: "0.8px",
    borderRadius: "12px",
    background:"var(--basic-white, #FFF)",
    fontFamily: "Nunito",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "700",
    height: "41px",
    border: "2px solid var(--primary-blue-blue-primary-light, #C9EEFF)",
  },
  '& .scrollboxtitlewrapper': {
    fontFamily: "Nunito",
    fontWeight: 900,
    display: 'flex',
    gap: '10px',
    color: "#00AEFF",
    lineHeight: "18.5px",
    letterSpacing: "0.128px"
  },
  '& .scrollBox': {
    display: "flex",
    padding: "10px",
    minHeight: "150px",
    alignItems: "baseline",
    // backgroundImage: `url(${groupscroll})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100%",
    gap: '10px',
    backgroundSize: "cover",
    justifyContent: "center",
    background:'#FF8400',
    position: 'relative',
    width: '80%',
    top: 0,
    '@media(max-width:520px)': {
      width: '100%'
    }
  },

  '& .languageButton1': {
    display: 'flex',
    gap: '10px',
    position: "relative", top: "-12px",
    marginLeft: 5,
    '@media(max-width:520px)': {
      marginLeft: 0
    },
    '& .MuiIconButton-root': {
      padding: "2px"
    },
  },
  '& .settingImgWrapper': {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'var(--secondary-orange, #FF8400)',
    borderBottomRightRadius: '0px',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundSize: '80%',
    "@media(max-width: 959px)": {
      height: 'auto'
    }
  },
  '& .scrollboxdetail': {
    color: "#F17D01",
    fontFamily: "Nunito",
    fontSize: "16px",
    lineHeight: "15px",
    letterSpacing: "0.104px",
    fontStyle: "normal",
    fontWeight: 800,
    marginTop: '10px'
  },
  [theme.breakpoints.up('md')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
  },
  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },
  [theme.breakpoints.up('md')]: {
   
    '& .settingBackground': {
      flex: 5,
      height: 'auto'
    },
    '& .settingImgWrapper': {
      height: '100vh',
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',

    },
    '& .nomargin': {
      margin: 'auto !important',
    },
    '& .mainContentWrapper': {
      width: `calc(100% - 240px)`,
      flexDirection: 'row',
      marginLeft: '240px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      margin: 0,
      width: '100% !important',
    }
  }
}))

export const DrawerStyle = styled('div')(({ theme }) => ({
  // padding: '10px',
  display: 'flex',
  fontFamily: "Quicksand",
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: 'auto',
  [theme.breakpoints.up('md')]: {
    height: '100vh',
  }

}))

// Customizable Area End
