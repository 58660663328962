import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Button
} from "@material-ui/core";
import SideBar from "../../../components/src/Sidebar";
import { group_success,blueSuccess, redSuccess, yellowSuccess, brownSuccess, purpleSuccess, greenSuccess, mintSuccess,orangeSuccess} from "./assets";
import { Props } from "./StripePayments";
import { useTranslation } from "react-i18next";
// Customizable Area End
import { ViewProps } from "./StripePayments";

// Customizable Area Start
import CenterPortion from './CenterPortion.web'

// Customizable Area End
// Customizable Area Start
const PaymentSuccessFul: React.FC<Props& { avatarColor: string }> = ({ navigation, avatarColor })  => {
    const handleClickContinue = () => {
        navigation.navigate("LandingPageWeb");
    }
    const color = localStorage.getItem('avatarColor') || '#62cdff';
    const { t } = useTranslation();
  
      const colorToUpdateTeddy:any = {
        '#ff8400': orangeSuccess,
        '#62cdff': blueSuccess,
        '#b7f0ad': mintSuccess,
        '#a95edb': purpleSuccess,
        '#f96855': redSuccess,
        '#884a39': brownSuccess,
        '#fdce36': yellowSuccess,
        '#2bce8c': greenSuccess
      };
  
      const updatedTeddy = colorToUpdateTeddy[color] || blueSuccess;
     
    // Customizable Area End
    return (
        // Customizable Area Start

        <SettingContainer>
            <SideBar />
            <Box className="mainPaymentsWrapperSucc" >
                <Box className="PaymentCheckBackground">
                <CenterPortion navigation={undefined} id={""} />
                </Box>
                <Box className="ProcessContainerSuccess" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box className="skipImgesWrapper" >
                        <Box style={webStyle.teddyImageparent} >
                            <img src={updatedTeddy} alt="Loading" />
                        </Box>
                        <Box>
                            <Typography style={webStyle.titleText}>
                                {t("Welcome to")}
                            </Typography>
                            <Typography style={webStyle.PremiumtitleText}>
                                {t("Premium")}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography style={webStyle.superboxText}>
                                It's time to <span style={webStyle.boldText}>supercharge</span> your learning journey!
                            </Typography>
                        </Box>
                        <Box className="buttonClass"
                        >
                            <Button
                                data-test-id="start-exploring-btn"
                                onClick={handleClickContinue}
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '16px',
                                    letterSpacing: '0.800000011920929px',
                                    textAlign: 'center',
                                    color: '#FFFFFF',
                                    width: '100%',
                                    marginTop: '5px',
                                    textTransform: 'none'
                                }}
                            >
                                {t("Start Exploring")}
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </SettingContainer>

        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',




    '& .mainPaymentsWrapperSucc': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .PaymentCheckBackground': {
        backgroundColor: '#fff',
    },



    '& .paymentImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .skipImgesWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        //paddingLeft: '33px',
        paddingBottom: '30px',
        position: 'relative',
    },

    '& .buttonClass': {
        width: '260px',
        height: '41.9px',
        borderRadius: '12px',
        border: '2px solid #36BFFF',
        borderBottom: '4px solid #36BFFF',
        background: '#36BFFF',
        borderColor: '#36BFFF',
        marginTop:"5vh",
        bottom: '0px', 
    },

    '& .settingesTitle': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
        marginBottom: '12px',
        paddingTop: '22px'
    },

    '& .ProcessContainerSuccess': {
        flex: '6',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '254px 24px 0px',
        backgroundColor: '#FFFFFF',
        backgroundImage: `url(${group_success})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflow: 'auto',
    },

    '& .teddyesImagesucc': {
        padding: '0',
        position: 'absolute',
        bottom: '0px',
        Width: '245px',
        marginRight: '10px',
        marginLeft: '0',
    },




    [theme.breakpoints.up('md')]: {


        '& .mainPaymentsWrapperSucc': {
            flexDirection: 'row',
            width: `calc(100% - 240px)`,
            marginLeft: '240px',

        },

        '& .paymentImgesWrapper': {
            paddingTop: 'unset',
        },

        '& .skipImgesWrapper': {
            paddingTop: 'unset',
        },


        '& .teddyesImagesucc': {
            maxWidth: 'unset',
        },

        '& .ProcessContainerSuccess': {
            paddingTop: '90px',
        },


        '& .PaymentCheckBackground': {
            flex: 5,
        },


        '& .settingesTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
        },

    },

    [theme.breakpoints.up('lg')]: {


        '& .mainPaymentsWrapperSucc': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

    },
   
    [theme.breakpoints.down('sm')]: {
        '& .PaymentCheckBackground': {
            display: 'none',
        },
        '& .ProcessContainerSuccess': {
            flex: '6',
            display: 'flex',
            padding: '177px 24px 100px',
            backgroundImage: `url(${group_success})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipImgesWrapper': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderBottomRightRadius: '20px',
            //paddingLeft: '33px',
            paddingBottom: '30px',
            position: 'relative',
        },

    },
    [theme.breakpoints.down('xs')]: {
        '& .PaymentCheckBackground': {
            display: 'none',
        },
        '& .ProcessContainerSuccess': {
            flex: '6',
            display: 'flex',
            padding: '177px 24px 100px',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage: `url(${group_success})`,
            overflow: 'auto',
        },
        '& .lastImage': {
            width: '305px',
            height: '200px',
            paddingTop: '20px'
        },

        '& .skipImgesWrapper': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderBottomRightRadius: '20px',
            //paddingLeft: '33px',
            paddingBottom: '30px',
            position: 'relative',
        },
    },

}))

const webStyle = {
    container: {
        flexDirection: "column" as "column",
        display: "flex",
        minHeight: "30vh",

    },


    superboxText: {
        fontFamily: 'Quicksand',
        fontSize: '17px',
        fontWeight: 500,
        lineHeight: '25px',
        letterSpacing: '0px',
        textAlign: 'center' as 'center',
        color: '#363636',
        marginTop: '20px',
        width: '80%',
        margin: '20px auto 0px auto'
    },
    boldText: {
        fontWeight: 700,
    },
    titleText: {
        textAlign: "center" as "center",
        fontFamily: 'Nunito',
        fontSize: '20px',
        fontWeight: 900,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#363636',
        marginTop: '20px'
    },
    PremiumtitleText: {
        fontFamily: 'Nunito',
        fontSize: '40px',
        fontWeight: 900,
        lineHeight: '32px',
        letterSpacing: '0.008em',
        textAlign: 'center' as 'center',
        backgroundImage: 'linear-gradient(90deg, #A95EDB 20.22%, #62CDFF 78.82%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginTop: '10px'
    },
    teddyImageparent: {
        alignItems: 'center',
        justifyContent: 'center',
       // width: '232px'
    }

};
// Customizable Area End

export default PaymentSuccessFul;
