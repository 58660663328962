export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const imgLogo = require("../assets/tedixLogo.png");
export const imgNerdTeddy = require("../assets/nerdTeddy.svg");
export const loader =  require("../assets/Loader.svg")
export const guestScoreBg =  require("../assets/guestScoreBg.svg")
export const guestPanner =  require("../assets/guestPanner.png")
export const celebrateTeddy =  require("../assets/celebrate_teddy.svg")