import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { sendAPIRequest, CommonApiCall } from '../../../components/src/utils'
import {orangeHappy,ghostlyAvatar, orangeHealthy, orangeWeak, orangeWorried, blueHappy, blueHealthy, blueWeak, blueWorried, lightgreenHappy, lightgreenHealthy, lightgreenWeak, lightgreenWorried, purpleHappy, purpleHealthy, purpleWeak, purpleWorried, redHappy, redHealthy, redWeak, redWorried, brownHappy, brownHealthy, brownWeak, brownWorried, yellowHappy, yellowHealthy, yellowWeak, yellowWorried, greenHappy, greenHealthy, greenWeak, greenWorried, readyBlue, readyBrown, readyGreen, readyLightgreen, readyOrange, readyPurple, readyRed, readyYellow} from './assets'
import { INodeInfo } from "./GradeController.web";
import { TFunction } from "i18next";

export interface IProgressList{
  id: string,
  image: string,
  label: string,
  shape: string,
  type: string,
  size: number,
  progress: number
}

export interface ISearchedData{
  data:INodeInfo|undefined,
  name:string,
  image?:string,
  section?:string
}

export interface ILessonAttributes{
  cheatsheet_pdf: string;
  summary_pdf: string;
  summary_video: string;
  id: number,
  name: string,
  description: string,
  content: string,
  course_id: number,
  created_at: string,
  updated_at: string,
  category_id: number,
  sub_category_id: number,
  unit_id: number,
  first_progress:number,
  unit_name:string,
  unit_number:string,
  main_id: number,
  image?:string;
  message?:string,
}

export interface ILesson{
  id:string,
  type:string,
  attributes: ILessonAttributes
}


export interface ILessonListResponse{
  data: Array<ILesson>
}

export interface ISearchedGraph{
  data:INodeInfo
}


export interface IReceivedSearch{
searchList:Array<ISearchedData>,
searchTerm:string,
historyList?:Array<string>
}


export interface ICurrentProfileResponse{
  data:{
      attributes:ICurrentResponseAttributes
  }
}
export interface ICurrentResponseAttributes{
  avatar_health:string,
  favorite_color:string,
  app_country:{
      id:number|string
  },
  topic_name:string;
  unit_name:string;
  unitid:number|string;
  grade:string;
  payment_premium:boolean
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  t:TFunction;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  progressStart:{id:number,progress:number}
  selectValue: string;
  searchedGraph:ISearchedGraph|undefined
  open: boolean;
  gradeOpen: boolean;
  trophiesOpen: boolean;
  goalsOpen: boolean;
  drawerSide: boolean;
  openDrawer: boolean;
  childGrage9: boolean;
  childMath: boolean;
  guideMessage: boolean;
  topicName: string,
  unitName: string;
  medianOpen: boolean;
  isScrolled: boolean;
  firstLogin: boolean;
  showGrade:boolean;
  showGradeList:boolean;
  filterById: string;
  selectCat: string;
  selectSubCat: string;
  centerShown: boolean;
  isMobile: boolean;
  lessonParentId: string;
  lessonList: Array<IProgressList>;
  backImage: boolean;
  noRecord:boolean;
  country_id:string;
  gradeId:string;
  selectedGrade:string;
  avatarHealth: string;
  avatarColor: string;
  avatarHealthIcon:string;
  readyTeddy:string;
  isLoading:boolean;
  unitNumber:string;
  userUnitId:number|null
  showAlert:boolean;
  errorText:string;
  searchedItem:ISearchedData|undefined
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    
    this.subScribedMessages = [
      getName(MessageEnum.LandingPageSearchedMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationLandingPageMessage),
    ];

    this.state = {
      progressStart:{
        id:0,
        progress:0
      },
      selectValue: '',
      searchedGraph:undefined,
      open:false,
      gradeOpen: false,
      trophiesOpen: false,
      goalsOpen: false,
      drawerSide: false,
      openDrawer: false,
      childGrage9: true,
      childMath: true,
      guideMessage: false,
      isScrolled: false,
      firstLogin: false,
      showGrade:false,
      showGradeList:false,
      topicName: "Welcome to Tedix",
      unitName: "Let's explore the world of knowledge",
      medianOpen: false,
      filterById : "", 
      selectCat: this.props.t("Current Grade"),
      selectSubCat:this.props.t('Select a Subject'),
      centerShown: false,
      isMobile: false,
      lessonParentId: '',
      lessonList: [] ,
      backImage: true,
      noRecord: false, 
      country_id:'', 
      gradeId:'', 
      selectedGrade:'', 
      avatarHealth: 'healthy', 
      avatarColor: '',
      avatarHealthIcon: '',
      readyTeddy:'',
      isLoading: true,
      unitNumber:'',
      userUnitId: null,
      errorText:'',
      showAlert:false,
      searchedItem:undefined
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  validationApiCallId: string = '';
  getLessonsList: string = "";
  getCurrentProfileId: string = "";
  changeunitIdAPICallId:string = "";

  async componentDidMount() {
    this.getCurrentUserProfile()
 
    this.isBackFromMicrolesson()

    
      window.addEventListener('resize', this.handleWindowSizeChange);
      window.addEventListener('scroll', this.handleScroll);  
      this.handleWindowSizeChange(); 
      }
     
    async componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    getMessageDisPlayed=()=>{
      const guideMessageDisplayed = localStorage.getItem('guideMessageDisplayed');
    if (!guideMessageDisplayed) {
      setTimeout(() => {
        this.setState({ guideMessage: true });
      }, 500);

      setTimeout(() => {
        this.setState({ guideMessage: false });
        localStorage.setItem('guideMessageDisplayed', 'true');
      }, 4000); 
    }
    }
   
    isBackFromMicrolesson =() => {
    const isBackFromMicroLesson = localStorage.getItem('isBackFromMicrolessonVideo')
    if (isBackFromMicroLesson === "true") {
      this.toggleState()
      localStorage.removeItem('isBackFromMicrolessonVideo')
    } else {
      this.callFunction()
    }
    }

    callFunction = async()=> {
      let userDataString = localStorage.getItem('userDetail')!;
      let userData = JSON.parse(userDataString);
      let updatedNameString = localStorage.getItem('updatedName')!;
      let updatedName = JSON.parse(updatedNameString)
      let loginCount = userData?.loginCount;
      if(loginCount > 1) {
       const lessonId = this.state.userUnitId;
       if((lessonId)){
      this.setState({lessonParentId:lessonId.toString(), topicName: updatedName?.topicName,
      unitName: `Unit ${updatedName?.unitNumber}: ${updatedName?.unitName}`,
      backImage: true})
      this.getLessonsData(lessonId);
    } else {
      this.setState({ topicName: "Welcome to Tedix",
      unitName: "Let's explore the world of knowledge", backImage: false })
    }
     } else {
       this.setState({ topicName: "Welcome to Tedix",
        unitName: "Let's explore the world of knowledge", backImage: false })
     }
    }

    handleSearchGraphClose=()=>{
      this.setState({
        searchedGraph:undefined
      })
    }

    handleSearchResult=(item:ISearchedData)=>{ 
        this.handleLandingPage(item)
    }

    handleLandingPage=(item:ISearchedData)=>{
      const searchItemData = item.data as INodeInfo
      if(searchItemData.type===configJSON.unit){
        const getUnitLessonData =  searchItemData.attributes?.lesson?.data[0]
        if(getUnitLessonData){
        const customUnitNumber = Number( searchItemData.attributes.unit_number)
        const Unitdata = { 
          main: getUnitLessonData?.attributes?.course_name as string,
          subMain: getUnitLessonData?.attributes?.unit_name,
          unitNumber: isNaN(customUnitNumber) ? '' : customUnitNumber,
          centerShown: true,
          lessonId: searchItemData.id
        }
        this.receiveDataFromChild(Unitdata)
       }else{
           this.setState({
            showAlert:true,
            errorText:"Unit has no lessons"
           })
       }     
      }
      else if(searchItemData.type===configJSON.lesson){
        this.props.navigation.navigate('Cftableforlessons', { lessonId: searchItemData.id, lessonNumber: 0,  lessonName: encodeURIComponent(item.name)})
      }else{
       this.setState({
        searchedGraph:item as ISearchedGraph,
        showGrade:true,
       })
      }
    }

    handleCloseAlert=()=>{
      this.setState({
        showAlert:false,
        errorText:''
      })
    }

    handleWindowSizeChange = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 960) {
        this.setState({isMobile:true})
        this.setState({ drawerSide: false });
      } else {
        this.setState({ drawerSide: true });
      }
    };
     toggleState=()=> {
      if(this.state.lessonList.length===0){
        this.setState({showGradeList: true, selectCat: this.props.t('Current Grade'), selectSubCat:this.state.selectedGrade})
      }else{
          const LessonData  = JSON.parse(this.state.lessonList[0].id)
          const payload = {
            data:{
                type:'main',
                id:LessonData.attributes.main_id.toString(),
                attributes:LessonData.attributes
            },
            name:'',
          }
         this.handleSearchResult(payload)
          
       }
     }
  closeDrawer = () => {
    this.setState({ goalsOpen: false, trophiesOpen: false, openDrawer: false, gradeOpen: false, medianOpen: false });
  };
  
   handleOpenDrawer = (type: string) => {
    if (type === 'trophi') {
      this.setState({ trophiesOpen: true, openDrawer: true })
    }
    else if (type === 'median') {
      this.setState({ medianOpen: true, openDrawer: true })
    }
  }

  handleScroll = () => {
    if (window.scrollY > 100) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

 receiveDataFromChild = (data: {
  main:string,
  subMain:string,
  centerShown?:boolean
  unitNumber?:number|string
  lessonId?:string
}) => {
    this.setState({ selectCat: data.main, selectSubCat: data.subMain, centerShown: data.centerShown as boolean, lessonParentId: data.lessonId as string, unitNumber: data.unitNumber as string })
    if (this.state.centerShown) {
      this.setState({
        topicName: data.main,
        unitName: `Unit ${data.unitNumber}: ${data.subMain}`
      });
    }
    setTimeout(()=>{
    if (this.state.lessonParentId) {
      localStorage.setItem('updatedName', JSON.stringify({topicName: this.state.selectCat, unitName:this.state.selectSubCat, unitNumber: this.state.unitNumber, unitMainId: this.state.lessonParentId}))
      this.getLessonsData(parseInt(this.state.lessonParentId));
      this.updateUnitId()
      this.setState({topicName: this.state.selectCat,unitName: `Unit ${this.state.unitNumber}: ${this.state.selectSubCat}`,backImage: true})
       }},50)
  }

  receieveData = (data:{openGradeList:boolean}) => {
    this.setState({showGradeList: data.openGradeList, selectCat: this.props.t('Current Grade'), selectSubCat:this.state.selectedGrade})
  }

  updateUnitId= async () => {
    let profile={
      unitid:this.state.lessonParentId, 
      unit_name: this.state.selectSubCat,
      topic_name: this.state.selectCat
    }
    this.changeunitIdAPICallId = await CommonApiCall({
      contentType: "application/json",
      method: "PUT",
      endPoint: 'bx_block_profile/profiles/update_user_profile',
      body:profile
    });
  };

  handleGradeId = (gradeId:number) => {
    this.setState({gradeId: gradeId.toString(),selectSubCat: 'Select a subject', showGrade: true, showGradeList:false , centerShown:false})
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.NavigationPayLoadMessage) === message.id){
      const searchedGraph = await message.getData(getName(MessageEnum.LandingPageSearchedMessage));
      if(searchedGraph){
        this.setState({
          searchedItem:searchedGraph
        })
        this.handleSearchResult(searchedGraph)
    }
  }

    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      this.getApiRequests(apiRequestCallId, responseJson)
      this.getCurrentUserDetail(apiRequestCallId, responseJson)
    }
   
  }

  getCurrentUserProfile = () => {
    const token = localStorage.getItem('token')
    this.getCurrentProfileId = sendAPIRequest(
      
      'bx_block_profile/profiles/current_user_profile',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token,
        },
      }
    )
  }

  getLessonsData = (lessonId: number) => {
    const token = localStorage.getItem('token')
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLessonsList = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.lessonAPI}?parent_id=${lessonId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getApiRequests = (apiRequestCallId: string, responseJson: ILessonListResponse) => {
    if (apiRequestCallId === this.getLessonsList) {
    
      const result = responseJson && responseJson?.data?.map((lesson: ILesson) => {
        return {
          id: JSON.stringify(lesson),
          image: lesson.attributes.image,
          label: lesson.attributes.name,
          shape: "circularImage",
          type: lesson.type,
          size: 15,
          progress: lesson.attributes.first_progress
        }
      })  as Array<IProgressList>
      if(result){
      const filterByProgressList=result.filter((item:IProgressList)=>item.progress!==100)
      if(filterByProgressList.length>0){
      const startButtonObj = {
        id:JSON.parse(filterByProgressList[0].id).attributes.id as number,
        progress:filterByProgressList[0].progress
       }         
       this.setState({
         progressStart:startButtonObj
       })
      }
      this.setState({lessonList: result, showGrade: false, backImage: true, isLoading: false })
      }
    } else {
      console.log(responseJson)
    }

  }

  getCurrentUserDetail = (apiRequestCallId: string, responseJson: ICurrentProfileResponse) => {
    if (apiRequestCallId === this.getCurrentProfileId) {
      this.setState({country_id: responseJson?.data?.attributes?.app_country.id as string,
         selectSubCat: responseJson?.data?.attributes?.grade,
          selectedGrade: responseJson?.data?.attributes?.grade, 
          avatarHealth: responseJson?.data?.attributes?.avatar_health,
         avatarColor: responseJson?.data?.attributes?.favorite_color,
         userUnitId: responseJson?.data?.attributes?.unitid as number,
         unitName: responseJson?.data?.attributes?.unit_name,
         topicName: responseJson?.data?.attributes?.topic_name,
        })
        localStorage.setItem('updatedName', JSON.stringify({topicName: this.state.topicName, unitName:this.state.unitName, unitNumber: this.state.unitNumber}))
        this.updateAvatarIcon();
        this.getMessageDisPlayed()
        this.updateReadyAvatar();
        if(this.state.searchedItem===undefined){
        this.callFunction()
        }
    } else {
      console.log(responseJson)
    }
  }

  goBack = () => {
    this.setState({showGradeList: false, showGrade:false,})
    this.callFunction()
  }

  openSearch = () => {
    const addMessage: Message = new Message(getName(MessageEnum.NavigationSearchPageMessage));
    addMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    addMessage.addData(getName(MessageEnum.SearchPagePropsMessage),this.state.gradeId);
    this.send(addMessage);
  }

getColors = () => {
    return {
      '#fdce36': { healthy: yellowHealthy, happy: yellowHappy, weak: yellowWeak, worried: yellowWorried, ghostly: ghostlyAvatar },
        '#2bce8c': { healthy: greenHealthy, happy: greenHappy, weak: greenWeak, worried: greenWorried , ghostly: ghostlyAvatar},
        '#ff8400': { healthy: orangeHealthy, happy: orangeHappy, weak: orangeWeak, worried: orangeWorried, ghostly: ghostlyAvatar },
        '#a95edb': { healthy: purpleHealthy, happy: purpleHappy, weak: purpleWeak, worried: purpleWorried, ghostly: ghostlyAvatar },
        '#f96855': { healthy: redHealthy, happy: redHappy, weak: redWeak, worried: redWorried, ghostly: ghostlyAvatar },
        '#884a39': { healthy: brownHealthy, happy: brownHappy, weak: brownWeak, worried: brownWorried, ghostly: ghostlyAvatar },
        '#62cdff': { healthy: blueHealthy, happy: blueHappy, weak: blueWeak, worried: blueWorried, ghostly: ghostlyAvatar },
        '#b7f0ad': { healthy: lightgreenHealthy, happy: lightgreenHappy, weak: lightgreenWeak, worried: lightgreenWorried, ghostly: ghostlyAvatar },   
    };
};

updateAvatarIcon() {
  const { avatarColor, avatarHealth } = this.state;
  const colorMoodMap = this.getColors();

  const healthIcon = (avatarColor !== undefined && avatarHealth !== undefined) 
    ? ((colorMoodMap as {[key:string]:{[key:string]:string}})[avatarColor]?.[avatarHealth] || null) 
    : blueHealthy;

  this.setState({ avatarHealthIcon: healthIcon, isLoading: false });
}


updateReadyAvatar = () => {
  const colorToReadyTeddyMap = {
      '#ff8400': readyOrange,
      '#62cdff': readyBlue,
      '#b7f0ad': readyLightgreen,
      '#a95edb': readyPurple,
      '#f96855': readyRed,
      '#884a39': readyBrown,
      '#fdce36': readyYellow,
      '#2bce8c': readyGreen
  };

  const readyTeddy = (colorToReadyTeddyMap as {[key:string]:string})[this.state.avatarColor] || readyBlue;
      this.setState({ readyTeddy: readyTeddy, isLoading:false });
}

  
  // Customizable Area End
}
