import React from "react";
// Customizable Area Start

import { Box, Typography, styled, Button, IconButton, Popover, Checkbox, FormControlLabel, FormControl} from "@material-ui/core";
import { divider, filterButton, graySearch, Back, crossButton, loader,skipHistory, groupMaskMathBack} from './assets';
import SearchBarController, { IList } from "./SearchBarController.web";
import { withTranslation } from "react-i18next";
import NavigateLayout from "../../../components/src/NavigateLayout";
// Customizable Area End

 class SearchBar extends SearchBarController {
  
  // Customizable Area Start

  dialogBox() {
    return (
      <PopOverContainer
      open={this.state.setOpen}
      data-testid={`popover`}
      onClose={this.handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
            }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
     <OpenPopOver>
      <Box className="firstLine">
      <Box className='rightIcons'>
        <img src={filterButton}/>
        <Typography className='filterHead'>Filters</Typography>
      </Box>
      <img src={crossButton} data-testid="dialogclose" style={{marginLeft: 'auto', cursor:'pointer'}} onClick={this.handleClose}/>
      </Box>
      {this.state.listFilters?.map((name:string,index:number)=> {   
        return(
        <Box 
        key={index}
        data-testid={'filterOption'}
        className="filterList">
    <FormControl style={{width: '100%'}}> 
    <FormControlLabel
     value="top"
     data-testid={`Check${index}`}
     control={
     <Checkbox 
      data-testid={`Check`}
      className={this.state.checked[name] ? 'customCheckBoxChecked' : 'customCheckBox'}
      checked={this.state.checked[name] || false} 
      icon={<span className='iconShown' />}
      onChange={()=> this.handleChange(name)} 
      name={name}
      />}
     label={name}
     labelPlacement="start"
     className={this.state.checked[name] ? "boxSpacingChecked" : "boxSpacing"}
   />
      </FormControl>
        </Box> )})}
      <Button className="saveFilter" data-testid="filter_button" onClick={this.handleSetFilter}>filter</Button>
      </OpenPopOver>
    </PopOverContainer>
    )
  }

  boldMatchingText = (result:string, searchTerm: string) => {
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    const parts = result.split(regex);

    return parts?.map((part: string, index: number) => (
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    )) ?? [];
    
  }


  renderSearchComponent=()=>{
    return (
      <SettingContainer>
      <Box className="settingBackgroudSearch" style={{backgroundImage: `url(${groupMaskMathBack})`}}>
               <Box className="settingImgWrapper" >
                 <Box className="setCenter" >
                   <Box>
                     <IconButton className="back-btn" >
                       <img src={Back} height="22px" width="20px" onClick={this.closeSearch}/>
                     </IconButton>
                   </Box>
                     <Box className="searchContainer">
                       <img src={graySearch} /> 
                       <input  
                         type="text"
                         placeholder={this.props.t("Search")}
                         value={this.state.searchTerm}
                         onChange={this.handleSearchChange}
                         className="inputBox"
                         onClick={this.handleSearchFocus}
                         data-test-id="textInput"
                         />
                       <Box className="rightSide">
                       {this.state.searchTerm && <img src={crossButton} style={{marginRight:'5px'}} data-testid="clearInput" onClick={this.clearInput}/>}
                       <img  src={divider} style={{marginRight:'5px'}}/>
                       <img src={filterButton} onClick={this.openDialog} data-test-id="dialogOpen"/>
                       {this.state.setOpen && this.dialogBox()}
                       </Box>
                     </Box>
                 </Box>
               </Box>
             </Box>
     </SettingContainer>
    )
  }



  renderSearch=(searchArray:Array<IList>, showAll:boolean)=>{
    return <Box>
    {searchArray.slice(0, showAll ? undefined : 3).map((item:IList, index:number) => (
     <Box className="showSearchList" data-testid="searchResult" key={index} onClick={()=>this.handleSearchResult(item)}>
       {item.image && <img src={item.image} width="24px" height="25px" alt={item.name} style={{borderRadius: '50%'}} />}
       <Typography className="searchName">{this.boldMatchingText(item.name, this.state.term)}</Typography>
     </Box>
   ))}
   </Box>
 }

  renderSearchResults = () => (
    <Box className="searchlist">
      {this.renderSearch(this.state.searchData,this.state.showAll)}
      {!this.state.showAll && this.state.searchData.length > 3 && (
      <button data-testid="seeAll" onClick={this.handleSeeAllClick} className="seeAllButton">{this.props.t("See All")}</button>
       )}
       {this.state.searchMaterialData.length>0&&(
        <>
        <Box className="text_heading">{this.props.t("Materials")}</Box>
        {this.renderSearch(this.state.searchMaterialData,this.state.showAllMaterial)}
        {!this.state.showAllMaterial && this.state.searchMaterialData.length > 3 && (
         <button data-testid="seeAll" onClick={this.handleSeeAllMaterialClick} className="seeAllButton">{this.props.t("See All")}</button>
        )}
         </>
       )}
       {this.state.searchExerciseData.length>0&&(
        <>
        <Box className="text_heading">{this.props.t("Exercises")}</Box>
        {this.renderSearch(this.state.searchExerciseData,this.state.showAllExercise)}
        {!this.state.showAllExercise && this.state.searchExerciseData.length > 3 && (
         <button data-testid="seeAllExercise" onClick={this.handleSeeAllExerciseClick} className="seeAllButton">{this.props.t("See All")}</button>
        )}
         </>
      )}
    </Box>
  );

  renderSearchData=()=>{
    return this.state.searchDataArray.length > 0? this.renderSearchResults(): this.renderHistoryList();
  }

  renderHistoryList = () => (
    <Box className="historyListShown">
      <Box className="rightcard-search">
        {this.state.showHistory && this.state.searchHistory.length > 0 && (
          this.state.searchHistory.map((history:string, index:number) => (
            <Box key={index} className="historywrapper">
              <Box className="historyItem">
                <img src={skipHistory} alt="Skip History" />
                <Typography className="historyText" data-testid="history-text" onClick={()=>this.handleSearchTerm(history)}>{history}</Typography>
                <img style={{ marginLeft: 'auto' }} data-testid ="deleteHistory" src={crossButton} alt="Cross Button" onClick={() => this.handleDeleteHistory(history)} />
              </Box>
            </Box>
          ))
        )}
        <Box className="searchTeddyPosition">
          <img src={this.state.readyTeddy} width="102px" alt="Search Teddy" />
          <Typography className="readyTextSearch">{this.props.t("Let's Dive into Knowledge!")}</Typography>
        </Box>
      </Box>
    </Box>
  );



  renderSearchLayout=()=>{
    return this.state.searchLoading ? <Box className="loaderShown"><img src={loader} alt="Loading" /></Box> :this.renderSearchData()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
      return (  
        // Customizable Area Start
        <NavigateLayout menuName="Home">
          {this.state.profileLoading?<SearchLoader>
            <img src={loader} alt="Loading" />
            </SearchLoader>:
          <SearchBarContainer>
          <Box className="main-Content-Wrapper">
            <Box className="setting-Background">
              <Box className="setting-Image-Wrapper">
              {this.renderSearchComponent()}
              </Box>
            </Box>
              <Box className="GraphLanding">
              {this.renderSearchLayout()}
              </Box>
          </Box>
          </SearchBarContainer>
          }
        </NavigateLayout>
      // Customizable Area End
      );
    }
}
// Customizable Area Start
export default withTranslation()(SearchBar)
const PopOverContainer = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': { 
    left: 'unset !important',
    right: '0',
    transform: 'translate(-75%, 10%) !important',
   
    "@media(max-width: 1560px)": { 
      transform: 'translate(-40%, 10%) !important'
    },
    "@media(max-width: 1080px)": { 
      transform: 'translate(-25%, 10%) !important'
    },
    "@media(max-width: 959px)": { 
      top: '65 !important',
      left: '50% !important',
    right: 'unset !important',
    transform: 'translate(-43%, 0%) !important',
    marginTop: '40px'
    }
  }
}))
const OpenPopOver = styled(Box)(({ theme }) => ({
  display:'flex',
  flexDirection:'column',
  padding: '25px',
  borderRadius: '8px',
  width: '310px',
border: '2px solid var(--primary-blue-blue-shadows, #00AEFF)',
backgroundColor: 'var(--basic-white, #FFF)',
  '& .firstLine': {
    display:'flex'
 },
 '& .rightIcons': {
  display:'flex',
  gap: '5px',
  alignItems: 'center'
 },
 '& .filterHead': {
  color: "#363636",
  fontFamily: "Nunito",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 900,
  lineHeight: "14px",
  letterSpacing: "0.144px", 
  paddingLeft:'11px'
},
 '& .filterList': {
  width: '270px',
  borderRadius:'10px',
  border: '2px solid rgba(98, 205, 255, 0.50)',
  marginTop:'20px',
 },

 '& .saveFilter': {
flexShrink: 0,
padding: '15px',
borderRadius: '12px',
backgroundColor: '#36BFFF',
color: '#FFF',
textAlign: 'center',
fontFamily: 'Nunito',
fontSize: '16px',
fontStyle:' normal',
fontWeight:' 700',
lineHeight: '16px',
letterSpacing:' 0.8px',
marginTop:'20px',
textTransform: 'capitalize', 
boxShadow: '0px 5px 0px  #00AEFF',

'& span': {
  color: "#FFF",
  textAlign: "center",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Nunito",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "16px",
  letterSpacing: "0.8px"
}

 }, 
'& .boxSpacing': {
  display: "flex",
  justifyContent: "space-between",
  padding: '0px 10px 0px 30px', 
  fontFamily: "Quicksand",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "12px",
  color: '#7F7F7F',
  margin: '0 !important',

  "& span": {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "12px",
    color: '#7F7F7F',
  }
},

'& .boxSpacingChecked': {
  color: "rgb(127, 127, 127)",
  display: "flex",
  fontSize: "16px",
  fontStyle: "normal",
  fontFamily: "Quicksand",
  fontWeight: 500,
  lineHeight: "12px",
  justifyContent: "space-between",
  backgroundColor: "rgb(201, 238, 255)",
  width: "100%",
  paddingLeft: "28px", 
  paddingRight: "7px",
  margin: '0 !important',
},
'& .customCheckBox': {
    color: '#E6E6E6',
},
'& .customCheckBoxChecked': {
   color:'#36BFFF'
},
'& .iconShown': {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
}
}))

 const SearchLoader=styled(Box)(({theme})=>({
    height: "100%",
    width: "calc(100% - 240px)",
    alignItems: "center",
    background:"#C9EEFF",
    display: "flex",
    marginLeft:"240px",
    justifyContent: "center",
    "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0,
     }
 }))

const SearchBarContainer = styled(Box)(({theme})=>({
  height: '100%',
  width: '100%',
  background:'#00AEFF',
  '& .main-Content-Wrapper': {
    display: 'flex',
    overflow:'auto',
    flexDirection: 'column',
  },

  '& .loaderShown': {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    height: '100vh', 
    width: "100%",
     "@media(max-width: 959px)": {
      width: '100%', 
      marginLeft:0
     }
  },

  '& .setting-Background': {
    backgroundColor: '#fff',
    height: 'auto'
  },

  '& .searchTeddyPosition': {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto"
  },

  "& .historywrapper": {
    display:'flex',
    width: '90%',
    margin: '0 auto',

  },
  "& .historyText": {
    color: "#7F7F7F",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    cusor:"pointer",
    letterSpacing: "0.144px",
    paddingTop:'5px'
  },
  "& .historyItem": {
    display: 'flex',
    borderBottom: '1px solid #F2F2F2',
    padding: '10px',
    borderRadius: '0 !important',
    color: "#7F7F7F",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.144px",
    gap: '10px',
    width: '100%',
  },

  ' & .readyTextSearch' : {
    flexShrink: 0,
    marginTop: '16.39',
    color: "var(--primary-neutrals-warm-gray-300, #B3B3B3)",
    textAlign: "center",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "0.128px",
  },

  '& .searchlist': {
    height: '100vh',
    width: '100%',
    overflow:'auto',
    backgroundColor: '#fff', 
    "@media(max-width: 959px)": {
      width: '100%',
      height: '585px',
      borderRadius: 'unset !important',
      marginTop:'20px'
    }
  },
  "& .text_heading":{
    marginTop:20,
    marginLeft:20,
    marginBottom:10,
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    color:"rgb(98, 205, 255)",
    lineHeight: "25px",
    textTransform:'uppercase'
  },
  '& .showSearchList': {
    display: 'flex',
    background: "var(--basic-white, #FFF)",
    boxShadow: "0px 2px 6px 0px rgba(98, 205, 255, 0.25)",
    padding:'20px', 
    cursor:"pointer",
    borderRadius: 'unset !important',
    wordBreak: 'break-all',
    margin: '5px'
  },
  '& .historyListShown': {
    display:'flex', 
    flexDirection:'column', 
    width: '100%',  
    borderRadius: 'unset !important', 
    "@media(max-width: 959px)": {
      marginTop:'30px'
    }
  },
  '& .searchName': {
    color: "var(--primary-blue-dark-gray, #363636)",
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: "Quicksand",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "25px",
  cursor:'pointer',
  marginLeft: '20px', 
  marginRight:'40px',
  wordBreak: "break-word"
  },
  '& .seeAllButton': {
    color: "#62CDFF",
    textAlign: "center",
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "14px",
    letterSpacing: "0.144px",
    border:'none',
    background:'transparent',
    display:'block',
    margin: 'auto',
    marginTop:'15px'
  },
  "& .rightcard-search":{
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    backgroundColor: '#fff', 
    "@media(max-width: 959px)": {
      width: '100%',
      height: '585px'
    }
  },
  '& .GraphLanding': {
    flex: 6,
    height: '100%',
    alignItems: 'center',
    backgroundColor: '#fff',
    "@media(max-width: 959px)": {
      width: '100%',
    }
  },
  '& .setting-Image-Wrapper': {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomRightRadius: '0px',
    backgroundSize: '80%',
    "@media(max-width: 959px)": {
      height: 'auto',
      minHeight:"180px",
    }
  },

  [theme.breakpoints.up('md')]: {
    '& .main-Content-Wrapper': {
      width: `calc(100% - 240px)`,
      marginLeft: '240px',
      flexDirection: 'row',
    },
    '& .setting-Image-Wrapper': {
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',

    },
    '& .setting-Background': {
      flex: 5,
      height: 'auto'
    },
  },

  [theme.breakpoints.up('lg')]: {
    '& .main-Content-Wrapper': {
      width: `calc(100% - 282px)`,
      marginLeft: '282px',
    },

  },

}))



const SettingContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#00AEFF',
  height: '100%',
  width: '100%',
  '& .searchContainer' : {
      height: '40px',
      width:'305px', 
      backgroundColor:'#fff',
      borderRadius:'10px', 
      display: 'flex', 
      alignItems:'center',
      padding:'10px'
       
  },
  '& .back-btn': {
       marginTop:'-5px',
       cursor:'pointer'
  },
  '& .rightSide' : {
     marginLeft: 'auto',
     display:'flex',
     justifyContent:'space-around'
  },
  '& .inputBox': {
       border:'none',
       outline:'none',
       fontFamily: "Quicksand",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "12px",
        color: "#363636",
        width: 'calc(100% - 80px)',
        paddingLeft: '10px',
        position: 'relative',
        top: '2px',
  },
  '& .inputBox::placeholder': {
    color: "var(--Primary-Neutrals-Warm-gray-200, #CCC)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "12px",
    width: "195px", 
    height: "13px", 
    padding:'10px'
  },
  '& .nomargin': {
    width: '100% !important',
  },
 
 
  '& .settingBackgroudSearch': {
    height: '100%',
    zIndex: 999,
    width:"100%",
    backgroundSize:"contain",
    borderRadius: "0 0 15px 0",
    backgroundRepeat:"space",
    overflow: "hidden",
  },
 
  '& .settingImgWrapper': {
    display:'flex',
    height:"100%"
  },
 
  
  '& .setCenter': {
    display: 'flex',
    justifyContent:"center",
    alignItems:'center',
    width:"100%",
    "@media(max-width: 959px)": {
      height:"180px",
    },
    "@media(max-width: 520px)": {
      height:"180px",
      width:"94%"
    }
  },
 
  '& .readyText': {
    color: 'var(--primary-blue-dark-gray, #363636)',
    textAlign: 'center',
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '19px',
    letterSpacing: '0.144px',
    flexShrink: 0,
    marginTop: '10px'
  },
 
 
  [theme.breakpoints.up('md')]: {
   
    '& .settingBackgroudSearch': {
      flex: 5,
      display:"flex",
      justifyContent:"center",
      alignItems:'center',
    },
    '& .settingImgWrapper': {
      paddingTop: 'unset',
      borderBottomRightRadius: '20px',
      width:"100%",
      display:'flex',
      height:"100%",
    },
  },

  [theme.breakpoints.up('sm')]: {
    '& .nomargin': {
      width: '100% !important',
      margin: 0,
    }
  }
}))
// Customizable Area End
