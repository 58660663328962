import * as React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
} from "@material-ui/core";
import BottomMenu from "../../../components/src/BottomMenu";
import { Link } from 'react-router-dom';
import {
    skip_wrench,
    btn_back,
    skip_vector,
    group_chk,
    button_showone,
    button_info,
    progress
} from "./assets";
import CenterPortion from './CenterPortion.web';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

const detail = ["Earn 1000XP points to level up to Premium!", "Keep your Premium with 1000XP." , "You’ll lose -100XP/week for inactivity."]

const benefits = [
    {
        imgSrc: group_chk,
        text: 'Unlock Premium Lessons',
        btnSrc: button_showone,
        detailText: "Get cool cheat sheets and summaries for better learning."
    },
    {
        imgSrc: group_chk,
        text: 'Personalize Your Avatar',
        btnSrc: button_showone,
        detailText: "Make your learning experience super fun by customizing your avatar."
    },
    {
        imgSrc: group_chk,
        text: 'Explore All Lessons',
        btnSrc: button_showone,
        detailText: "Learn anything you want with no limits!"
    },
    {
        imgSrc: group_chk,
        text: 'Win Cool Prizes',
        btnSrc: button_showone,
        detailText: "Compete with others and win exciting rewards."
    },
];
import NavigateLayout from "../../../components/src/NavigateLayout";
import { Props } from './StripePayments';
import { useTranslation } from "react-i18next";
// Customizable Area End
import { ViewProps } from "./StripePayments";

const SubscriptionPremium: React.FC<Props> = ({
    // Customizable Area Start
    navigation
    // Customizable Area End
}) => {
    const { t } = useTranslation()

    return (
        // Customizable Area Start
        <NavigateLayout menuName="Settings">
            <SettingContainer>
                <Box className="mainContentFirstWrapper">

                    <Box className="settingpremiumBackground">
                    <CenterPortion navigation={undefined} id={""} />

                    </Box>

                    <Box className="contentbenefitContainer">

                        <Box className="settingBackground">
                            <Box className="settingImgWrapper">
                                <img src={skip_wrench} className="teddyImage" alt="Teddy Setting" />
                                <Typography className="settingTitle">
                                    <img src={btn_back} className="arrowImage" alt="arrow btn" style={{ marginRight: '5px' }} onClick={navigation.goBack} />
                                    <Typography className="settingTitleText">{t("Subscription")}</Typography>
                                </Typography>
                            </Box>
                        </Box>
                        <div style={{ marginTop: '36px' }}>
                            <Grid container wrap="nowrap" spacing={2} style={{ padding: '0px 40px 0px 0px' }}>
                                <div style={webStyle.premiumMain}>
                                    <img src={skip_vector} alt="skip_vector" style={{ maxWidth: '54px' }} />
                                </div>
                                <Grid item xs style={webStyle.parentpremium}>
                                    <Typography style={webStyle.benefitsText}>
                                        {t("Premium")}
                                    </Typography>
                                    <Typography style={webStyle.getbenefitFirstTitle} >
                                        {t("Elevate your app experience:")}
                                        <br />
                                        <span style={webStyle.getbenefitSecondTitle}>
                                            {t("Become a VIP member!")}
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
                            <Grid container wrap="nowrap" spacing={2} alignItems="center" className="benefitxpMain">
                                <Grid item>
                                    <img src={progress} alt="skip_bolt" style={webStyle.imgSkip} />
                                </Grid>
                                <Grid item >
                                    <img src={button_info} alt="button_info" style={webStyle.imgSkip1} />
                                </Grid>
                            </Grid>
                        </div>

                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box className="benefitsBox">
                             {detail.map((data, index)=> (
                               
                                <ul>
                                  <li style={{listStyle: 'disc', lineHeight:'1'}}>{data}</li>
                                </ul>
                                
                             ))}
                            </Box>
                            </Box>

                        <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Box className="BenefitsPremiumSub">
                                <Box className="benefitsSubTitleFirst">
                                    <Typography style={{ textTransform: 'uppercase' }}>{t("Benefits")}</Typography>
                                </Box>
                                <Box className="benefitSectionParent">
                                    {benefits.map((benefit, index) => (
                                        <div key={index} style={{ display: 'flex', marginBottom: '10px', position: 'relative', width: '100%' }}>
                                            <Accordion elevation={0} style={{width:'100%'}}>
                                                <AccordionSummary
                                                className="Accordian_customize"
                                                expandIcon={<img src={benefit.btnSrc}/>}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                >
                                                <div style={{ display: 'flex' }}>
                                                <img src={benefit.imgSrc} alt={`benefit_${index}`} />
                                            </div>
                                            <div style={{ marginLeft: '10px' }}>
                                                <Typography style={webStyle.freefirstTextPremium}>
                                                    {t(benefit.text)}
                                                </Typography>
                                            </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                <Typography >
                                                {t(benefit.detailText)}
                                                </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <Box style={webStyle.boxparent}>
                            <Box style={webStyle.btnBoxMain}>
                                <Link to="/PremiumSubscription">
                                    <Button style={webStyle.btnTitle}>
                                        {t("Go Premium now!")}
                                    </Button>
                                </Link>
                            </Box>
                        </Box>

                        <Box style={webStyle.footerTextMain} >
                            <Typography style={webStyle.getFirstTitle}>
                                By selecting Go Premium now!, you ll be
                                <br />
                                <span style={webStyle.getSecondtitle}>
                                    opting for our paid subscription.
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                    <BottomMenu />
                </Box>
            </SettingContainer>
        </NavigateLayout>
        // Customizable Area End
    );
};

// Customizable Area Start
const SettingContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#A95EDB',
    height: '100%',
    width: '100%',

    "& .arrowImage": {
        position: 'absolute',
        top: '55%',
        left: '0'
    },
    '& .settingTitleText': {
        fontSize: '22px',
        fontWeight: '900',
        fontFamily: 'Nunito',
        color: '#fff',
    },

    '& .settingpremiumBackground': {
        backgroundColor: '#fff',
    },


    '& .Accordian_customize >.MuiAccordionSummary-expandIcon.Mui-expanded':{
        transform:'rotate(90deg)'
    },

    '& .BenefitsWrapper .MuiListItem-root': {
        display: 'inline-block',
        marginRight: '20px',
    },
    '& .benefitSectionParent': {
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        height: 'auto',
        top: 'auto',
        bottom: '0',
        backgroundColor: '#FFFFFF',
        borderRadius: '0px 0px 12px 12px',

    },
    '& .benefitsSubTitleFirst': {
        fontWeight: '700',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(90deg, #A95EDB 0%, #62CDFF 100%)',

        backgroundColor: '#e6e6e6',
        padding: '10px',
        color: '#fff',
        borderTopLeftRadius: '8px',
        fontSize: '12px',
        fontFamily: 'Quicksand',
    },
    '& .benefitsBox': {
        background: ' #E6E6E6',
        borderRadius: '12px',
        overflow: 'hidden',
        width: '100%',
        padding: '0px 11px',
        marginTop: '15px',
        marginBottom:'15px', 
        color:' #666666', 
        fontSize:'14px', 
        fontWeight:'500', 
        fontFamily:'Quicksand',
    },
    '& .MuiList-padding': {
          paddingTop: '0 !important',
          paddingBottom:'0 !important'
    },
    '& .MuiAccordionSummary-root':{
          minHeight: '0 !important'
    },
    '& .MuiAccordionDetails-root': {
         padding: '0px 45px !important'
    },
    '& .MuiAccordionSummary-content.Mui-expanded':{
         margin: '0px'
    },
    '& .BenefitsPremiumSub': {
        background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
        borderRadius: '12px',
        overflow: 'hidden',
        width: '100%',
        padding: '2px 2px 2px 2px',
        marginTop: '20px',
    },
    '& .benefitxpMain': {
        marginTop: '10px',
        padding: '10px 31px 40px 0px',
    },
    '& .parentSectionmiddle': {
        display: 'flex',
        marginBottom: '10px',
        position: 'relative',
        width: '100%',
    },
    '& .imageStyleView': {
        display: 'flex',
        paddingLeft: '10px',
        paddingTop: '7px',
        position: 'absolute',
        right: '0px',
    },
    '& .imgDiamon': {
        display: 'flex',
        paddingTop: '7px'
    },

    '& .BenefitsWrapper': {
        width: '100%',
        border: '2px solid',
        borderImageSource: 'linear-gradient(90deg, #A95EDB 0%, #62CDFF 100%)',
        borderImageSlice: 1,
        borderRadius: '12px !important',
    },

    '& .settingListTitle': {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },

    '& .mainContentFirstWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .settingBackground': {
        height: '111px',
        width: 'calc(100% + 48px)'
    },

    '& .settingImgWrapper': {
        paddingLeft: '33px',
        paddingBottom: '30px',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#A95EDB',
        height: '100%',
        display: 'flex',
        borderBottomRightRadius: '20px',
        paddingTop: '45px',
        position: 'relative'
    },

    '& .settingTitle': {
        fontSize: '22px',
        fontWeight: '900',
        position: 'relative',
        fontFamily: 'Nunito',
        marginBottom: '12px',
        paddingTop: '22px',
        display: 'flex',
        width: '100%',
        color: '#fff',
        justifyContent: 'center',
    },
    '& .teddySettingImage': {
        width: '245px',
        marginLeft: '0',
        position: 'absolute',
        marginRight: '10px',
        bottom: '0px',
        display: 'block',
        padding: '0',
    },

    '& .teddyImage': {
        Width: '245px',
        display: 'block',
        marginRight: '10px',
        position: 'absolute',
        bottom: '0px',
        marginLeft: '0',
        padding: '0',
        left: '34px'

    },
    '&.arrowImage': {
        width: "30%",
        left: '25%',
        top: '29px',
        height: "auto",
        position: 'absolute',

    },

    '& .contentbenefitContainer': {
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        overflowX: "hidden",
        padding: '32px 24px 0px',
        alignItems: 'center',
        paddingTop: '0',
        flex: '6'
    },

    '& .benefitsListTitle': {
        color: '#fff',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        background: 'linear-gradient(180deg, #A95EDB 100%, #62CDFF 100%)',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',

    },



    [theme.breakpoints.up('md')]: {

        '& .settingpremiumBackground': {
            flex: 5,
        },

        '& .mainContentFirstWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },


        '& .settingBackground': {
            width: 'calc(100% + 48px)',
            height: '111px'
        },

        '& .settingImgWrapper': {
            paddingTop: 'unset',
        },

        "& .settingImgWrapper.middle": {
            paddingLeft: '0'
        },

        '& .teddyImage': {
            maxWidth: 'unset',
            display: 'none'
        },
        '& .teddySettingImage': {
            maxWidth: 'unset',
            width: '240px',
        },

        '& .settingTitle': {
            fontSize: '30px',
            marginBottom: 'unset',
        },


        '& .BenefitsWrapper': {
            maxWidth: 450,
        },

    },

    [theme.breakpoints.up('lg')]: {

        '& .mainContentFirstWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },


        '& .teddySettingImage': {
            maxWidth: 'unset',
            width: 'auto',
        },

        '& .BenefitsWrapper': {
            width: '600px',
        },

    },

    [theme.breakpoints.down('sm')]: {
        '& .settingTitle img': {
            position: 'absolute',
            bottom: '10px',
            left: 0
        },
        '& .settingpremiumBackground': {
            display: 'none',
        },

        '& .arrowImage': {
            position: 'absolute',
            left: '0%',
            top: '31px',
        },
        '& .preiTextTitle': {
            padding: '1px'
        },
        '& .benefitxpMain': {
            marginTop: '10px',
            padding: '10px 2px 40px 0px',
        },


    },
    [theme.breakpoints.down('xs')]: {
        '& .settingpremiumBackground': {
            display: 'none',
        },

        '& .arrowImage': {
            left: '0%',
            position: 'absolute',
            top: '31px',

        },
        '& .BenefitsPremiumSub::before': {
            content: '',
            position: 'absolute',
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
            borderRadius: '12px',
            zIndex: '-1', /* Place this behind the content */
        },
        '& .preiTextTitle': {
            padding: '1px'
        },
        '& .benefitxpMain': {
            marginTop: '10px',
            padding: '10px 2px 40px 0px',
        },
        '& .BenefitsPremiumSub': {
            overflow: 'hidden', /* Clip content that goes beyond the border-radius */
            padding: '2px 2px 2px 2px',
            background: 'linear-gradient(270deg, #62CDFF 0%, #A95EDB 100%)',
            borderRadius: '12px',
            width: '100%',
            marginTop: '20px',
            position: 'relative',
        },

    },

}))

const webStyle = {
    container: {
        display: "flex",
        flexDirection: "column" as "column",
        minHeight: "30vh",
    },
    
    freefirstTextSub: {
        fontWeight: 700,
        color: '#363636',
        fontFamily: 'Quicksand',
        fontSize: '20px',
    },
    freefirstTextPremium: {
        fontFamily: 'Quicksand',
        fontWeight: 700,
        color: '#363636',
        fontSize: '20px',
    },
    benefitsParentSub: {
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        height: 'auto',
        top: 'auto',
        bottom: '0',
        backgroundColor: '#FFFFFF',
    },
    premiumMain: {
        background: '#F2F2F2',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    benefitsText: {
        fontFamily: 'Quicksand',
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '12px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636'
    },

    imgSkip: {
        width: '296px',   
    },
    imgSkip1: {
        maxWidth: '24px',
        marginRight: '10px'   
    },
    footerTextMain: {
        textAlign: 'center' as 'center',
        padding: '20px 20px'
    },
    benefitxpMain: {
        marginTop: '10px',
        padding: '10px 31px 40px 0px',
    },
    getbenefitFirstTitle: {
        fontFamily: 'Quicksand',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '10px',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',
        letterSpacing: '0px',

    },
    getbenefitSecondTitle: {
        fontFamily: 'Quicksand',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#363636',
        marginTop: '5px',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '16px',

    },
    parentpremium: {
        paddingLeft: '20px'
    },
    getFirstTitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 600,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999',
    },
    getSecondtitle: {
        fontFamily: 'Quicksand',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '15px',
        letterSpacing: '0px',
        color: '#999999'
    },

    textbenefitsTtile: {
        fontFamily: 'Quicksand',
        fontSize: '27px',
        letterSpacing: '0px',
        textAlign: 'left' as 'left',
        color: '#999999',
        fontWeight: 700,
        lineHeight: '29px',

    },
    boxparent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    btnTitle: {
        width: '350px',
        textAlign: 'center' as 'center',
        color: '#FFFFFF',
        borderRadius: '12px',
        fontFamily: 'Nunito !important',
        fontSize: '16px',
        letterSpacing: '0.800000011920929px',
        textTransform: 'none' as 'none',
        fontWeight: 700,
        lineHeight: '16px',
        height: '40px',
    },
    btnBoxMain: {
        height: 'auto',
        textAlign: 'center' as 'center',
        boxSizing: 'border-box' as 'border-box',
        background: '#36BFFF',
        width: '100%',
        borderRadius: '12px',
        margin: '45px 50px 0px 50px',
    },




};
// Customizable Area End

export default SubscriptionPremium;