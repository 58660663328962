// Customizable Area Start
import React from 'react';
import { withTranslation } from "react-i18next";
import ProgressUpdateController, { Props } from "./ProgressUpdateController.web";
import { Box, Button, Typography, styled } from "@material-ui/core";
import { badgeIcon, groupConfettiPng, streakEndIcon, calendarIcon, xpBoost, medal, congratsLeft, congratsRight } from "./assets";
import CustomGoalSreakSelect from '../../../components/src/CustomGoalSreakSelect';

class ProgressUpdate extends ProgressUpdateController {
    constructor(props: Props) {
        super(props);
    }

    renderDailyGoal = () => {
        const { currentDailyGoalSetting } = this.state

        if (currentDailyGoalSetting === 1) {
            return null
        }

        return Array.from({ length: currentDailyGoalSetting - 1 }, (_, i) => (
            <Typography key={String(_)} component='span' className="streak-day" style={{
                left: `calc(${(i + 1) / currentDailyGoalSetting * 100}% - 6px)`
            }}>{i + 1}</Typography>
        ))
    }

    renderStreak = () => {
        const { currentStreakGoalSettingData } = this.state

        if (currentStreakGoalSettingData.day === 1) {
            return <Typography component='span' className="streak-day">1</Typography>
        }

        return Array.from({ length: currentStreakGoalSettingData.day - 1 }, (_, i) => (
            <Typography key={String(_)} component='span' className="streak-day" style={{
                left: `calc(${(i + 1) / currentStreakGoalSettingData.day * 100}% - 6px)`
            }}>{i + 1}</Typography>
        ))
    }

    renderMedal = () => {
        const { medal } = this.props.goalProgress

        return <>
            <Typography component='span'>{medal - 1}</Typography>
            <Typography component='span'>{medal}</Typography>
        </>

    }

    render() {
        if (this.state.progressUpdateOpen) {
            return <ProgressUpdateContainer>
                <Box className='primary-container'>
                    <img className="badge-icon" src={badgeIcon} alt="badge" />

                    <Typography className="title">{this.props.t("Your progress update")}</Typography>

                    <Box className="progress-container">
                        <Box className="progress-row first-row">
                            <Box className="progress-title">
                                <Typography className="title-left">{this.props.t("Daily Goal")}</Typography>

                                <Typography className="title-right">
                                    {`${this.state.currentDailyGoalSetting} ${this.state.currentDailyGoalSetting > 1 ? this.props.t('lessons') : this.props.t('lesson')}`}
                                </Typography>
                            </Box>

                            <Box className="progress-content">
                                {this.renderDailyGoal()}

                                <img className="streak-end-icon" src={streakEndIcon} alt="End" />

                                <Box 
                                    className="progress-bar" 
                                    style={{ 
                                        maxWidth: this.props.goalProgress.dailyGoalAchieve 
                                        ? 'calc(100% - 6px)' 
                                        : `calc(${this.props.todayCompletedLessonCounts / this.state.currentDailyGoalSetting * 100}% - 6px)` }} 
                                    />
                            </Box>
                        </Box>

                        <Box className="progress-row second-row">
                            <Box className="progress-title">
                                <Typography className="title-left">{this.props.t("Streak Goal")}</Typography>

                                <Typography className="title-right">{this.state.currentStreakGoalSettingData.option}</Typography>
                            </Box>

                            <Box className="progress-content">
                                {this.renderStreak()}

                                <img className="streak-end-icon" src={calendarIcon} alt="End" />

                                <Box className="progress-bar" style={{ maxWidth: this.props.goalProgress.streakGoalAchieve ? 'calc(100% - 6px)' : `calc(${this.props.goalProgress.streakGoalDates.length / this.state.currentStreakGoalSettingData.day * 100}% - 6px)` }} />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box className='secondary-container'>
                    <Button className='continue-btn' onClick={this.handleContinueProgressUpdate} data-test-id="progress-update-continue-btn">{this.props.t("Continue")}</Button>
                </Box>
            </ProgressUpdateContainer>
        }

        if (this.state.dailyGoalCompleteOpen) {
            return <DailyGoalCompleteContainer>
                <Box className='content-container'>
                    <img src={xpBoost} alt="" className='xp-boost-img' />

                    <Typography component='span' className='primary-text'>{this.props.t("Superpower Boost Unlocked!")}</Typography>

                    <Typography component='span' className='secondary-text'>{this.props.t("Congrats on achieving your daily goal!")}{" "}
                    {this.props.t("Enjoy double XP for")} <Typography component="span" className='bold'>{this.props.t("30 minutes")}</Typography>
                    {this.props.t("in your lessons.")}</Typography>
                </Box>

                <Box className='bottom-container'>
                    <Button className='continue-btn' onClick={this.handleContinueDailyGoal} data-test-id="daily-goal-continue-btn">{this.props.t("Continue")}</Button>
                </Box>
            </DailyGoalCompleteContainer>
        }

        if (this.state.medalAchieveOpen) {
            return <MedalAchieveContainer>
                <img src={congratsLeft} alt="" className='congrats-img left' />

                <img src={congratsRight} alt="" className='congrats-img right' />

                <Box className='main-container'>
                    <Box className='medal-achieve-container'>
                        <Typography component="span" className='primary-text'>{this.props.t("Reaching new heights:")}</Typography>

                        <Box className='medal-count'>
                            <img src={medal} alt="" className='medal-img' />

                            <Box className='medal-count-text'>
                                {this.renderMedal()}
                            </Box>
                        </Box>

                        <Typography component='span' className='primary-text'>{this.props.t("medals achieved")}</Typography>
                    </Box>

                    <Box className='streak-goal-container'>
                        <Box className='streak-goal-text-container'>
                            <Typography component='span' className='streak-goal-text'>
                                Now you're <Typography component='span' className='streak-goal-text bold'>{this.state.currentStreakGoalSettingData.option.toLowerCase()}!</Typography>
                            </Typography>

                            <Typography component='p' className="streak-goal-text">{this.props.t("Want to upgrade yout streak goal?")}</Typography>
                        </Box>

                        <CustomGoalSreakSelect 
                            list={this.streakGoalData} 
                            setValue={(item) => {
                                this.setState({currentStreakGoalSettingData: item})
                                this.updateStreakGoal(item)
                            }}
                            value={this.state.currentStreakGoalSettingData} 
                            showSearchInput
                        />
                    </Box>
                </Box>

                <Box className='bottom-container'>
                    <Button className='continue-btn' onClick={this.handleContinueMedalAchieve} data-test-id="medal-achieve-continue-btn">{this.props.t("Continue")}</Button>
                </Box>
            </MedalAchieveContainer>
        }
    }
}

export const MedalAchieveContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
    flex: '6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '318px 26px 102px 26px',
    position: 'relative',
    overflow: 'scroll',

    "& .congrats-img": {
        position: 'absolute',

        "&.left": {
            width: '142px',
            height: '145px',
            top: '186px',
            left: '0'
        },

        "&.right": {
            width: '140px',
            height: '152px',
            top: '108px',
            right: '0'
        }
    },

    "& .main-container": {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '109px'
    },

    "& .medal-achieve-container": {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        alignItems: 'center'
    },

    "& .medal-count": {
        width: '174px',
        padding: '9px 18px 18px 19px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#C9EEFF',
        borderRadius: '10px',
        alignItems: 'center',
        height: '89px',
    },

    "& .medal-img": {
        width: '57px',
        height: '57px',
    },

    "& .medal-count-text": {
        maxHeight: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-end',

        "& span": {
            fontFamily: 'Nunito',
            fontWeight: '700',
            fontSize: '50px',
            lineHeight: '38px',
        },
    },

    "& .streak-goal-container": {
        display: 'flex',
        flexDirection: 'column',
        gap: '31px',
        alignItems: 'center',
        padding: '0 20px',
    },

    "& .streak-goal-text-container": {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    },

    "& .primary-text": {
        fontFamily: "Nunito",
        fontSize: "22px",
        fontWeight: "900",
        lineHeight: "19px",
        letterSpacing: "0.008em",
    },

    "& .streak-goal-text": {
        margin: '0',
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "23px",
        letterSpacing: "0px",

        "&.bold": {
            fontWeight: '700'
        }
    },

    "& .streak-goal-option": {
        height: '40px',
        borderRadius: '10px',
        border: 'solid 2px #E6E6E6',
        backgroundColor: '#F7F7F7',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        padding: '10px 16px 11px 15px'
    },

    "& .streak-goal-option-left": {
        display: 'flex',
        flexDirection: 'row',
        gap: '14px',
        alignItems: 'center'
    },

    "& .calendar-icon": {
        width: '18px',
    },

    "& .streak-option-text": {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "12px",
        letterSpacing: "0px",
    },

    "& .dropdown-arrow": {
        width: '18px',
        height: '18px'
    },

    "& .continue-btn": {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.8px',
        backgroundColor: '#36BFFF',
        boxShadow: '0px 3px #00AEFF',
        borderRadius: '10px',
        textTransform: 'none',
        position: 'relative',
        marginTop: '68px',

        '& :before': {
            left: '50%',
            content: '""',
            display: 'block',
            bottom: 0,
            transform: 'translateX(-50%)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'white',
            top: '45px'
        },
        '&:hover': {
            backgroundColor: '#00AEFF',
            boxShadow: '0px 3px #36BFFF',
            '&.Mui-disabled': {
                backgroundColor: '#f7f7f7',
            }
        },

        '& .MuiButton-label': {
            fontFamily: 'Nunito',
        },


        '&.Mui-disabled': {
            color: '#ccc',
            boxShadow: '0px 3px #ccc',
            backgroundColor: '#f7f7f7',
        },
    },

    [theme.breakpoints.up("md")]: {
        overflow: 'auto',
        height: 'auto',
        minHeight: '100%',
        paddingBottom: '40px',
        paddingLeft: '24px',
        paddingRight: '24px'
    }
}))

export const DailyGoalCompleteContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'white',
    flex: '6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '198px 26px 102px 26px',

    "& .content-container": {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        alignItems: 'center',
        padding: '0 5px'
    },

    "& .xp-boost-img": {
        width: '242.19px',
        height: '248.33px'
    },

    "& .primary-text": {
        fontFamily: "Nunito",
        fontSize: "22px",
        fontWeight: "900",
        lineHeight: "25px",
        letterSpacing: "0.008em",
    },

    "& .secondary-text": {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "23px",
        letterSpacing: "0px",

        "& .bold": {
            fontWeight: '700'
        }
    },

    "& .continue-btn": {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.8px',
        backgroundColor: '#36BFFF',
        boxShadow: '0px 3px #00AEFF',
        borderRadius: '10px',
        textTransform: 'none',
        position: 'relative',
        '& .MuiButton-label': {
            fontFamily: 'Nunito',
        },
        '& :before': {
            transform: 'translateX(-50%)',
            content: '""',
            display: 'block',
            bottom: 0,
            left: '50%',
            width: '100%',
            position: 'absolute',
            height: '100%',
            background: 'white',
            top: '45px'
        },
        '&:hover': {
            '&.Mui-disabled': {
                backgroundColor: '#f7f7f7',
            },
            boxShadow: '0px 3px #36BFFF',
            backgroundColor: '#00AEFF',
        },
        '&.Mui-disabled': {
            backgroundColor: '#f7f7f7',
            boxShadow: '0px 3px #ccc',
            color: '#ccc',
        },
    },

    [theme.breakpoints.up("md")]: {
        paddingBottom: '40px',
        overflow: 'hidden',
        paddingLeft: '24px',
        paddingRight: '24px'
    }
}))

export const ProgressUpdateContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    backgroundImage: `URL(${groupConfettiPng})`,
    backgroundRepeat: "round",
    textAlign: 'center',
    padding: '0 32px 0 32px',
    backgroundColor: 'white',
    flex: '6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'scroll',

    "& .badge-icon": {
        width: '60px',
        height: '60px',
        marginTop: '197px'
    },

    "& .title": {
        fontFamily: "Nunito",
        fontSize: "22px",
        fontWeight: "900",
        lineHeight: "19px",
        letterSpacing: "0.008em",
        textAlign: "center",
        marginTop: '30px'
    },

    "& .progress-container": {
        width: '100%',
        marginTop: '98px',
        display: 'flex',
        flexDirection: 'column',
        gap: "15px"
    },

    "& .progress-row": {
        borderRadius: '10px',
        backgroundColor: '#F2F2F2',
        padding: '15px 16px 13px 20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '21px',
    },

    "& .progress-title": {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },

    "& .title-left": {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "13px",
        letterSpacing: "0px",
    },

    "& .title-right": {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "13px",
        letterSpacing: "0px",
    },

    "& .progress-content": {
        width: '100%',
        position: 'relative',
        textAlign: 'center',
        backgroundColor: 'white',
        height: '12px',
        borderRadius: '15px',
        padding: '3px',
    },

    "& .progress-bar": {
        backgroundColor: '#62CDFF',
        position: 'absolute',
        left: '3px',
        height: '6px',
        borderRadius: '15px',
        zIndex: 1,
        width: 'calc(100% - 6px)'
    },

    "& .streak-day": {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: "700",
        lineHeight: "13px",
        letterSpacing: "0px",
        color: '#F7F7F7',
        textShadow: "2px 0 #B3B3B3, -2px 0 #B3B3B3, 0 2px #B3B3B3, 0 -2px #B3B3B3, 1px 1px #B3B3B3, -1px -1px #B3B3B3, 1px -1px #B3B3B3, -1px 1px #B3B3B3",
        position: 'absolute',
        left: 'calc(50% - 6px)',
        top: '-2px',
        zIndex: 2
    },

    "& .streak-end-icon": {
        width: '24px',
        height: '24px',
        position: 'absolute',
        right: '0',
        zIndex: 2,
        top: '-6px'
    },

    "& .continue-btn": {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        letterSpacing: '0.8px',
        backgroundColor: '#36BFFF',
        boxShadow: '0px 3px #00AEFF',
        borderRadius: '10px',
        textTransform: 'none',
        position: 'relative',
        '& .MuiButton-label': {
            fontFamily: 'Nunito',
        },
        '&:hover': {
            backgroundColor: '#00AEFF',
            '&.Mui-disabled': {
                backgroundColor: '#f7f7f7',
            },
            boxShadow: '0px 3px #36BFFF',
        },
        '&.Mui-disabled': {
            color: '#ccc',
            boxShadow: '0px 3px #ccc',
            backgroundColor: '#f7f7f7',
        },
        '& :before': {
            position: 'absolute',
            content: '""',
            display: 'block',
            bottom: 0,
            transform: 'translateX(-50%)',
            width: '100%',
            left: '50%',
            height: '100%',
            background: 'white',
            top: '45px'
        },
    },

    "& .secondary-container": {
        marginTop: '20px',
        marginBottom: '84px',
    },

    [theme.breakpoints.up("md")]: {
        paddingLeft: '24px',
        paddingRight: '24px'
    }
}))

export default withTranslation()(ProgressUpdate)
// Customizable Area End