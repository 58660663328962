export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const share = require("../assets/group_share.png");
export const flag = require("../assets/skip_flag.png");
export const close = require("../assets/close.png");
export const bolt = require("../assets/skip_bolt.png");
export const brain = require("../assets/skip_brain.png");
export const video = require("../assets/dummy.mp4");
export const playIcon = require("../assets/group_play.png");
export const replayIcon = require("../assets/group_replay.png");
export const expand = require("../assets/skip_expand.png");
export const volume = require("../assets/skip_volume_up.png");
export const pause = require("../assets/group_pause.png");
export const minimize = require("../assets/skip_minimize_video.png");
export const volumeDown = require("../assets/skip_volume_down.png");
export const VolumeMute = require("../assets/skip_volume_mute.png");
export const maskMath = require("../assets/group_maskMath.png");
export const skipFeedback = require("../assets/skip_feedback.png");
export const skipGrade = require("../assets/skip_grade.png");
export const skipPartyTeddy = require ("../assets/skip_partyTeddy.png");
export const skipVector = require("../assets/skip_vector.png");
export const skipVectorBg = require("../assets/skip_Vector_bg.png");
export const teddyShare = require("../assets/skip_Social.png");
export const groupStar = require("../assets/group_start.png");
export const groupDot = require("../assets/groupDot.png");
export const feedback1 = require("../assets/skip_feedback(1).png");
export const feedback2 = require("../assets/skip_feedback(2).png");
export const feedbackTeddy = require("../assets/skip_FeedbackTeddy.png");
export const groupStarPurple = require("../assets/group_start_purple.png");
export const backButton = require("../assets/button_arrow.png");
export const reportenable = require("../assets/group_report_enable.png");
export const shareEnable = require("../assets/group_share_enable.png");
export const reportFlag = require("../assets/flag.jpg");
export const redFlag = require("../assets/red_flag.svg");
export const checkBox = require("../assets/checkbox.svg");
export const checkedBox = require("../assets/checkedbox.svg");
export const thankReportBg = require("../assets/thank_report_bg.svg");
export const buttonClose = require("../assets/button_close.svg");
export const thankFeedbackTeddy = require("../assets/thank_FeedbackTeddy.svg");
export const noteBookLines = require("../assets/note_book_lines.svg");
export const blueFlag = require("../assets/blue_flag.svg");
export const greenFlag = require("../assets/green_flag.svg");
export const groupConfetti = require("../assets/group_confetti.svg");
export const groupConfettiPng = require("../assets/group_confetti.png");
export const partyTeddy = require("../assets/skip_partyTeddy.svg");
export const greenShare = require("../assets/green_share.svg");
export const wellDoneShareBg = require("../assets/well_done_share.svg");
export const socialTeddy = require("../assets/social_teddy.svg");
export const redShare = require("../assets/red_share.svg");
export const matchingImg = require("../assets/matching_img.svg");
export const solutionHintSkipIcon = require("../assets/solution_hint_skip_icon.svg");
export const skipStar = require('../assets/skip_star.png');
export const greyCloseButton = require("../assets/grey_close_button.svg");
export const exerciseImg = require("../assets/exercise_img.svg");
export const viewRectangle = require("../assets/view_rectangle.svg");
export const orangeViewRectangle = require("../assets/orange_view_rectangle.svg");
export const greenViewRectangle = require("../assets/green_view_rectangle.svg");
export const yellowViewRectangle = require("../assets/yellow_view_rectangle.svg");
export const greyViewRectangle = require("../assets/grey_view_rectangle.svg");
export const mistakeTeddy = require("../assets/mistake_teddy.svg");
export const fantasticBeginning = require("../assets/fantastic_beginning.svg");
export const skipRatingStar = require("../assets/Vector.png");
export const shareBtnEnable = require("../assets/shareEnable.png");
export const tedixLogo = require('../assets/tedix_logo.svg')
export const shareCloseBtn = require("../assets/share_close_btn.svg")
export const shareWhatsAppIcon = require("../assets/share_whatsapp_icon.png")
export const shareInstaIcon = require("../assets/share_insta_icon.png")
export const shareMsgIcon = require("../assets/share_msg_icon.png")
export const shareDownloadIcon = require("../assets/share_download_icon.svg")
export const shareMoreIcon = require("../assets/share_more_icon.svg")
export const facebookIcon = require('../assets/facebook_icon.svg')
export const messengerIcon = require('../assets/messenger_icon.svg')
export const lessonHubIcon = require('../assets/skip_wrench.png')
export const skipChallengesIcon = require('../assets/skip_challengesIcon.png')
export const badgeIcon = require('../assets/skip_challenges.png')
export const streakEndIcon = require('../assets/streak_end_icon.svg')
export const calendarIcon = require("../assets/calendar_icon.svg")
export const xpBoost = require('../assets/xp_boost.png')
export const medal = require("../assets/medal.png")
export const calendarGray = require('../assets/calendar_gray.svg')
export const dropdownArrow = require("../assets/dropdown_arrow.svg")
export const congratsLeft = require("../assets/congrats_left.png")
export const congratsRight = require("../assets/congrats_right.png")
export const homeIcon = require("../assets/homeBtn.png")
export const disableHomeIcon = require("../assets/skip_homeButton.png")
export const infoSummary = require("../assets/button_info.png")
export const skipStarIcon = require('../assets/star_icon.svg')
export const summaryImage = require('../assets/maths-examples.png')
export const reportBlue =  require('../assets/report_blue.svg')
export const reportOrange =  require('../assets/report_orange.svg')
export const reportPurple =  require('../assets/report_purple.svg')
export const reportRed =  require('../assets/report_red.svg')
export const reportYellow =  require('../assets/report_yellow.svg')
export const reportMint =  require('../assets/report_mint.svg')
export const reportGreen =  require('../assets/report_green.svg')
export const reportBrown =  require('../assets/report_brown.svg')
export const welldoneBlue =  require('../assets/welldone_blue.svg')
export const welldoneBrown =  require('../assets/welldone_brown.svg')
export const welldoneGreen =  require('../assets/welldone_green.svg')
export const welldoneMint =  require('../assets/welldone_mint.svg')
export const welldoneOrange =  require('../assets/welldone_orange.svg')
export const welldonePurple =  require('../assets/welldone_purple.svg')
export const welldoneRed =  require('../assets/welldone_red.svg')
export const welldoneYellow =  require('../assets/welldone_yellow.svg')
export const disableReport =  require('../assets/disable_report.svg')
export const enableReport =  require('../assets/enable_report.svg')
