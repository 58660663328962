import React from "react";
// Customizable Area Start
import { Box, styled, Typography } from "@material-ui/core";
import { rectangle, groupscroll, buttonNotification, groupAvtar, CenterCardBg } from "./assets";
import CenterCard from "../../../components/src/CenterCard.web";
import { withTranslation } from "react-i18next";
import InAppNotificationLayoutController, {Props} from "./InAppNotificationLayoutController.web";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { groupMultilevel, imageFlag } from "../../landingpage/src/assets";

const SettingMainContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  "@media(max-width: 959px)": {
   backgroundColor:'#fff',  
  },

  '& .hiddenBlockContainer': {
     display:'none'
  },

  '& .nomarginContainer': {
    width: '100% !important',
  },

  '& .mainContentWrapperContainer': {
    flexDirection: 'column',
    display: 'flex',
  },

  '& .settingBackgroundContainer': {
    height: 'auto',
    backgroundColor: '#fff',
    "@media(max-width: 959px)": {
      display:'none',  
     },
  },

  '& .fontSetContainer': {
    fontFamily: 'Nunito',
    fontSize: '16px',
    color: '#00AEFF',
    fontWeight: '900',
    lineHeight: '18.5px', 
    letterSpacing: '0.128px',
    fontStyle: 'normal',
  },

  '& .mainLandingContainer': {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    flex: 6,
    "@media(max-width: 959px)": {
      width: '100%',
    }
   },

  '& .iconContainerscrollContainer': {
    width: '261px',
    height: '65px',
    backgroundImage: `url(${rectangle})`,
    background: "#fff",
    display: 'flex',
    justifyContent: 'center',
    borderRadius: "100px 20px 20px 100px",
    gap: '15px',
    marginTop: "60px",
    alignItems: "center",
    backgroundColor:'var(--primary-neutrals-warm-gray-50, #F2F2F2)',
    padding: "10px",
  },

  '& .scrollBoxContainer': {   
    padding: "10px",
    display: "flex",
    alignItems: "baseline",
    minHeight: "150px",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${groupscroll})`,
    gap: '10px', 
    position:'fixed',
    backgroundPosition: "100%",
    width:'100%',
    top:0, 
  },

  '& .scrollBoxContainertitlewrapperContainer': {
    color: "#00AEFF",
    display: 'flex',
    gap: '10px',
    fontSize: "16px",
    fontFamily: "Nunito",
    fontWeight: 900,
    fontStyle: "normal",
    letterSpacing: "0.128px",
    lineHeight: "18.5px",
  },

  '& .scrollBoxContainerdetailContainer': {
    fontFamily: "Nunito",
    color: "#7F7F7F",
    fontStyle: "normal",
    fontSize: "13px",
    lineHeight: "15px",
    fontWeight: 800,
    marginTop:'10px',
    letterSpacing: "0.104px", 
  },

  '& .languageButton1Container': {
    position: "relative",
    display:'flex',
    top: "-12px", 
    gap: '10px',
  },
  
  '& .settingImgWrapperContainer': {
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    borderBottomRightRadius: '0px',
    backgroundColor: '#00AEFF',
    height: '100vh',
    backgroundSize: '80%',
    backgroundImage: `url(${CenterCardBg})`,
    backgroundRepeat: "no-repeat",
    "@media(max-width: 959px)": {
      minHeight: "295px",
      height: 'auto'
    }
  },

  
  [theme.breakpoints.up('md')]: {
    '& .mainContentWrapperContainer': {
      marginLeft: '240px',
      flexDirection: 'row',
      width: `calc(100% - 240px)`,
    },
    '& .settingBackgroundContainer': {
      height: 'auto',
      flex: 5,
    },
    '& .settingImgWrapperContainer': {
      height: '100vh',
      borderBottomRightRadius: '20px',
      paddingTop: 'unset',
    },
    '& .nomarginContainer': {
      margin: 'auto !important',
    }, 
  },

  [theme.breakpoints.up('lg')]: {
    '& .mainContentWrapperContainer': {
      marginLeft: '282px',
      width: `calc(100% - 282px)`,
    },

  },

  [theme.breakpoints.up('sm')]: {
    '& .nomarginContainer': {
      margin: 0,
      width: '100% !important',
    }
  }
}))
class InAppNotificationMainLayout extends InAppNotificationLayoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render = () => {
    // Customizable Area Start
    let updatedNameUnit = localStorage.getItem('updatedName')!;
    let updatedName = JSON.parse(updatedNameUnit)
    return (
    <div className="notificationMainScreen">
      <NavigateLayout menuName="Home">
       <SettingMainContainer>
          <Box className="mainContentWrapperContainer">
            <Box className="settingBackgroundContainer">
              <Box className="settingImgWrapperContainer" >
                <Box className="nomarginContainer">
                  <CenterCard  unreadNotifications ={this.props.unreadNotifcations} Topic={this.props.topicName} Unit={`Unit ${updatedName.unitNumber}: ${this.props.unitName}`} openDrawer={this.props.openDrawer} trophiesOpen={this.props.trophiesOpen} goalsOpen={this.props.goalsOpen} handleOpenDrawer={(name:string)=>this.props.handleOpenDrawer} navigation={this.props.navigation} data-testid={'centerCardTestId'}/>
                </Box>
              </Box>
              <Box className={"hiddenBlockContainer"}>
                <Box className="scrollBoxContainer" >
                  <Box className="iconContainerscrollContainer">
                    <img src={groupAvtar} width="51px" />
                    <Box>
                      <Box className="scrollBoxContainertitlewrapperContainer">
                        <img src={groupMultilevel} width="16px" />
                        <Typography className="fontSetContainer">Algebra</Typography>
                      </Box>
                      <Typography className="scrollBoxContainerdetailContainer" >Unit 1: Set of Real Numbers</Typography>
                    </Box>
                  </Box>
                  <Box className="languageButton1Container">
                    <img src={imageFlag} width="31px" height="22px" />
                    <img src={buttonNotification} width="20.6px" height="24px" />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="mainLandingContainer">
            {this.props.children}
            </Box>
          </Box>
        </SettingMainContainer>
      </NavigateLayout>
    </div>
    );
    // Customizable Area End
  };
}
export default withTranslation()(InAppNotificationMainLayout);
