Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cflessonbuildercoursebuilderinbackend";
exports.labelBodyText = "cflessonbuildercoursebuilderinbackend Body";
exports.accountCheckApi = 'bx_block_profile/profiles/current_user_profile';
exports.subCategories = 'bx_block_categories/sub_categories';
exports.btnExampleTitle = "CLICK ME";
exports.subCategoryType = "sub_category";
exports.course = "course";
exports.main_course="main";
exports.unit="unit";
exports.lesson="lesson";
exports.MaterialApi="bx_block_profile/materials";
exports.lessonAPI = 'bx_block_profile/list_lessons';
// Customizable Area End