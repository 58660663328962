// Customizable Area Start
import React from "react";
import { Button, Box, InputAdornment, IconButton, Typography, Container, TextField, Grid } from '@material-ui/core'
import EmailLoginController, {
    Props, 
} from "./EmailLoginController.web"
import { styled } from "@material-ui/styles"
//@ts-ignore
import Cookies from "js-cookie"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { Formik } from 'formik'
import { emailIcon, lockIcon, pandaIcon, passwordInvisible, tedixIcon, checkedIcon, uncheckIcon, pandaIcon2 } from './assets'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import SelectLanguage from "../../../components/src/SelectLanguage"
import { Toaster } from "../../../components/src/Toaster"
import { withTranslation } from "react-i18next";

 class EmailLogin extends EmailLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <EmailLoginStyle>
                <div className="header">
                    <div id='backBtn' onClick={()=>this.props.navigation.goBack()}><ArrowBackIosIcon className="backIcon" /></div>
                    <div className='logintedixtext'><img src={tedixIcon} alt="tedix-icon" /></div>
                </div>
               
                <Container maxWidth="xs">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            padding: "10px 0px",
                        }}
                    >
                        <div>
                            <Grid container spacing={0} style={{ alignItems: 'center' }}>
                                <Grid xs >
                                    <div></div>
                                </Grid>
                                <Grid xs={6} >
                                    <div className="welcome">{this.props.t("Welcome back!")}</div>
                                </Grid>
                                <Grid xs style={{display:'flex',justifyContent:'end'}}>
                                    <img src={pandaIcon} className="pandaIcon" alt='panda-icon' />
                                    <img src={pandaIcon2} className="pandaIcon2" alt='panda-icon' />
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ padding: "45px 0px 0px 0px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Toaster label={this.props.t(this.state.errorMessage)}/>
                            <div>
                                <Formik
                                    initialValues={{
                                         email:
                                         Cookies.get("email")||"", 
                                         password:
                                         Cookies.get("password")||"", 
                                         checked:
                                         Cookies.get("checked") as boolean||false
                                        }}
                                    validationSchema={this.loginSchema()}
                                    onSubmit={values => {
                                        this.state.errorObj.capital && this.state.errorObj.lower && this.state.errorObj.digit && this.state.errorObj.length && this.submitFormData(values)
                                    }}>
                                    {({ errors, touched, handleSubmit, handleChange, setFieldValue, values }) => (
                                        
                                        <form
                                            onSubmit={handleSubmit}
                                            autoComplete="off">
                                            <Box className='login'>
                                                <TextField className='input-field' name='email' placeholder={this.props.t("Enter Email")}
                                                    error={this.checkError(touched?.email as boolean , errors.email as string)}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    value={values.email}
                                                    autoComplete="off"
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontFamily: 'Quicksand'
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img src={emailIcon} alt='emailIcon' />
                                                            </InputAdornment>
                                                        ),
                                                    }
                                                    } />
                                                {this.showError(touched, errors, 'email')}
                                                <TextField className='input-field' id='password' name='password' placeholder={this.props.t("Password")}
                                                    error={this.checkError(touched?.password as boolean, errors.password  as string)}
                                                    variant='outlined' 
                                                    value={values.password}
                                                    autoComplete="off"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    onChange={(e) => { handleChange(e); this.validatePasswordStrength(e.target.value) }}
                                                    InputProps={{
                                                        style: {
                                                            color: 'black',
                                                            fontFamily: 'Quicksand'
                                                        },
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <img src={lockIcon} alt='emailIcon' />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    edge="end"
                                                                    id='password-visibility-button'
                                                                    onClick={() => this.handleClickShowPassword()}
                                                                >
                                                                    {this.state.showPassword ? <VisibilityOff style={{
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        color: '#ccc',
                                                                    }} /> : <img src={passwordInvisible} alt='emailIcon' />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {this.showError(touched, errors, 'password')}
                                                {values.password.length > 0 && this.passwordError()}
                                                <Typography className="checked-remember">
                                                    <Typography style={{ display: 'flex', width: '300px' }}>
                                                        <Typography id='checkboxClick' onClick={() => this.handleCheckClick(values, setFieldValue)} style={{ padding: '0px 8px 0px 15px',cursor:'pointer' }}>
                                                            <input name="checked" type="checkbox" id="checkbox" style={{ display: 'none' }} />
                                                            {this.state.checkFlag ? <img src={checkedIcon} alt="checkicon" /> : <img src={uncheckIcon} alt="uncheck" className={errors.checked && touched.checked ? 'witherror' : ''} />}
                                                        </Typography>
                                                        <Typography className='Remember-me'>
                                                            {this.props.t("Remember me?")}
                                                        </Typography>
                                                    </Typography>
                                                </Typography>
                                                <Typography>
                                                    <Button variant="contained" disabled={this.state.loader} className="login-btn" type="submit">{this.props.t("Login")}</Button>
                                                </Typography>
                                                <div className="google-btn">
                                                    {this.googleButton()}
                                                </div>
                                                <hr className='underline' />
                                                <div className="forgot-password" onClick={() => this.props.navigation.navigate('ForgotPassword')}>{this.props.t("Forgot password?")}</div>
                                              <Typography style={{ textAlign: 'center', margin: '25px 0 0 0' }}>                                          
                                                <SelectLanguage  />
                                                </Typography> 
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Box>
                </Container>
            </EmailLoginStyle >
            // Customizable Area End
        )
    }
}
// Customizable Area Start
export default withTranslation()(EmailLogin)
const EmailLoginStyle = styled("div")({
    "@media (max-width:600px)": {
        overflowX: 'hidden',

        "& .MuiContainer-root": {
            paddingRight: '0px',
            paddingLeft: '0px',
        },
        "& .header": {
            position: 'relative'
        }
    },
    "@media (max-width:450px)": {
        "& .pandaIcon": {
            display:'none'
        },
        "& .pandaIcon2": {
            display:'flex',
            justifyContent:'end'

        },
    },
    "@media (min-width:450px)": {
        "& .pandaIcon2": {
            display:'none'
        },
        "& .pandaIcon": {
            display:'block'
        },
    },
    "& .required-error": {
        "@media (max-width:600px)": {
            display: "flex",
            justifyContent: "center",
        }
    },
    "& .backIcon": {
        display: 'none',
        "@media (max-width:960px)": {
            margin: "51px 0px 0px 33px",
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            width: "16px",
            height: "16px",
            cursor:'pointer'
        }
    },
    "& .checked-remember": {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        "@media (max-width:600px)": {
            justifyContent: 'center',
        }
    },
    "& .input-field": {
        backgroundColor: '#F2F2F2',
        margin: '10px 0 5px 0',
        width: '400px',
        height: '40px',
        borderRadius: '10px !important',
        "@media (max-width:600px)": {
            width: '300px',
        },
    },
    '& .Mui-focused fieldset': {
        borderColor: '#363636 !important',
    },
    '& .Mui-error fieldset': {
        border: "2px solid #F96855 !important",
        borderColor: '#F96855 !important',
    },
    '& .MuiOutlinedInput-input': {
        paddingBottom: '10.5px',
        paddingTop: '10.5px',
        fontSize:'18px',     
        "@media (max-width:599px)": {
            fontSize: '16px'
        }
        
    },
    "& .logintedixtext": {
        padding: '50px 0px 20px 50px',
        "@media (max-width:960px)": {
            padding: '45px 0px 10px 0px',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    "& .Remember-me": {
        color: "#999",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "10px",
        display: 'flex',
        alignItems: 'center',
    },
    "& .forgot-password": {
        color: "#00AEFF",
        textAlign: "center",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "20px",
        cursor: 'pointer',
        margin: '22px 0 0 0'
    },
    "& .google-btn": {
        borderRadius: "5px",
        width: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0 0 0',
        "@media (max-width:599px)": {
            width: '100%',
        },
        "& button": {
            width: '400px',
            borderRadius: "12px !important",
            border: "2px solid #999 !important",
            background: "#FFF",
            boxShadow: '0px 2px #999999 !importavnt',
            height: '44px',
            justifyContent: 'center',
            "@media (max-width:600px)": {
                width: '260px',
            }
        },
        "& span": {
            color: "var(--primary-neutrals-warm-gray-500, #7F7F7F)",
            textAlign: "center",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Nunito",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: '700 !important',
            lineHeight: "12px",
            letterSpacing: "0.8px",
            "@media (max-width:600px)": {
                fontSize: "16px",
            }
        }
    },
    "& .welcome": {
        borderRadius: "12px",
        color: "#363636",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        "@media (max-width:600px)": {
            fontSize: '22px'
        }
    },
    "& .text-danger": {
        color: "#F96855",
        fontStyle: "normal",
        textAlign: 'start',
        fontWeight: 500,
        fontFamily: "Quicksand",
        fontSize: "13px",
        lineHeight: "12px",
        "@media (max-width:600px)": {
            width: '300px'
        }
    },
    "& .login-btn": {
        color: "white",
        textAlign: "center",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "16px",
        letterSpacing: "0.8px",
        backgroundColor: '#36BFFF',
        width: '400px',
        textTransform: 'none',
        height: '44px',
        boxShadow: '0px 4px #00AEFF',
        borderRadius: '12px',
        marginTop: '35px',
        "@media (max-width:600px)": {
            width: '260px',
            fontSize: '16px'
        },
        "& span": {
            color: "#FFF",
            textAlign: "center",
            fontFamily: "Nunito",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: '700 !important',
            lineHeight: "12px",
            letterSpacing: "0.8px",
            "@media (max-width:600px)": {
                fontSize: "16px",
            }
        },
        '&.Mui-disabled': {
            color: '#B3B3B3',
            backgroundColor: '#E6E6E6',
            boxShadow: 'unset',
          },
    },
    "& .error": {
        borderRadius: "8px",
        margin: '5px 8px 2px 0',
        padding: '2px 8px 2px 8px',
        color: "#999",
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontWeight: 500,
        "@media (max-width:600px)": {
            fontSize: '13px'
        }
    },
    "& .no-error": {
        color: '#2BCE8C',
        fontFamily: "Quicksand",
    },
    "& .with-error": {
        color: '#999999',
        fontFamily: "Quicksand",
    },
    "& .check-icon": {
        padding: '0 8px 0 0'
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: '10px !important'
    },
    "& .flagIcon": {
        borderRadius: '50%',
        width: '25px',
        height: '25px',
        margin: '0 13px',
    },
    "& .MuiSelect-select": {
        color: "#363636",
        fontFamily: "Quicksand",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "15px",
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px'
    },
    '& .MuiSelect-icon': {
        color: '#363636',
    },
    '& .underline': {
        width: '297px',
        color: '#E6E6E6',
        marginTop: '36px',
        "@media (max-width:600px)": {
            width: '220px'
        }
    },
    "& .errorComponent": {
        "@media (max-width:600px)": {
            width: '300px'
        }
    },
    "& .witherror": {
        // border: '2px solid #F96855 !important',
        // borderRadius: '5px'
    },
    '& .MuiInputAdornment-positionStart': {
        margin: '0 15px 0 5px'
    },
})
// Customizable Area End
// Customizable Area End
