import * as React from "react";
// Customizable Area Start

// Customizable Area End
import { ViewProps, configJSON } from "./StripePayments";

const StripePaymentsView: React.FC<ViewProps> = ({
  // Customizable Area Start
  testID,
  isPaymentMethodsLoading,
  paymentMethods,
  infoText,
  modalProps,
  errorModalProps,
  orderIdInputProps,
  cardNumberInputProps,
  expiryDateInputProps,
  cvcInputProps,
  btnCreatePaymentMethodProps,
  btnCancelProps,
  btnAddPaymentMethodProps,
  btnConfirmPaymentProps,
  btnOkProps,
  radioGroupProps,
  formControlLabelProps,
  // Customizable Area End
}) => {
  // Customizable Area Start
  // Customizable Area End
  return (
    // Customizable Area Start
    <div></div>
    // Customizable Area End
  );
};

// Customizable Area Start

// Customizable Area End

export default StripePaymentsView;
