export const defaultImage = require("../assets/defaultImage.png");
export const starBg = require("../assets/star_bg.png")
export const apprenticeLeagueBadge = require('../assets/apprentice_league_badge1.png')
export const groupDivisionApprentice = require("../assets/group_division_aprentice.png")
export const groupDivisionChallenger = require("../assets/group_division.png")
export const groupDivisiondefender = require("../assets/group_division_defender.png")
export const groupDivisionbornProtector = require("../assets/group_division_born.png")
export const groupDivisionLegends = require("../assets/group_division_legends.png")
export const countdownClock = require("../assets/countdown_clock.svg")
export const secondPlaceAvatar = require("../assets/second_place_avatar.png")
export const firstPlaceAvatar = require("../assets/first_place_avatar.png")
export const thirdPlaceAvatar = require("../assets/third_place_avatar.png")
export const defaultAvatar = require("../assets/default_avatar.png")
export const bolt = require("../assets/bolt.svg")
export const apprenticeLeagueListBg = require("../assets/apprentice_league_list_bg.png")
export const skipBook = require("../assets/skip_book.png")
export const skipBornProtectors = require("../assets/skip_bornProtectors.png")
export const skipChallengers = require("../assets/skip_Challengers.png")
export const skipDefender = require("../assets/skip_Defender.png")
export const skipLegends = require("../assets/skip_Legends.png")
export const skipLock = require("../assets/skip_lock.png")
export const legendsbg = require("../assets/legendsbg.png")
export const defenderbg = require("../assets/defenderbg.png")
export const challengeresbg = require("../assets/challengeresbg.png")
export const bornProtectorbg = require("../assets/bornProtectorbg.png")
export const bornProtector = require("../assets/bornProtector1.png")
export const challenge = require("../assets/challenge1.png")
export const defender = require("../assets/defender1.png")
export const Legends = require("../assets/Legends1.png")
export const LegendBackImage = require("../assets/LegendBackImage.png")
export const defenderBackImage = require("../assets/defenderBackImage.png")
export const ChallengeBackImage = require("../assets/ChallengeBackImage.png")
export const bornProtectorBackImage = require("../assets/bornProtectorBackImage.png")
export const loaderBlue = require('../assets/loaderBlue.svg');