import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RomanianFlag, EnglishFlag, GermanFlag, HungarianFlag } from "./assets";

// Customizable Area Start
export const configJSON = require("./config");
//@ts-ignore
import Cookies from  "js-cookie"
import { sendAPIRequest , CommonApiCall } from "../../../components/src/utils";
import { Message } from '../../../framework/src/Message'
import i18next from "i18next";


export interface LangugaeAttributes{
    id: number
    name: 'English' | 'German' | 'Hungarian' | 'Romanian'
    language_code: string
    is_content_language: string
    is_app_language: string
    emoji_flag: string
    created_at: string
    updated_at: string
  
}
export interface LanguageType {
    id: string
    type: string
    attributes: LangugaeAttributes
  }

  export interface ILangugeResponse{
    data:Array<LanguageType>,
    errors?:{}
  }

  export interface CountryAtrributes{
    id:string,
    name: string
    country_code: string
    alpha2: string
    alpha3: string
    emoji_flag: string
  }
  export interface Country {
    id: string 
    type: string
    attributes:CountryAtrributes 
  }

  export interface ICountriesResponse{
     data:Array<Country>,
     errors?:{}
  }


  export interface ISpecificAccountResponse{
      data: {
        id: string,
        type: string,
        attributes: {
          app_language_id: number,
          app_language_name: string,
          profile_id: number,
          country: CountryAtrributes,
          app_country: CountryAtrributes
        },
    },
    errors?:{}
  }
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    t?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectLanguage: string|null;
 
    selectCountry: Country|null;
    selectCountryCode:string|null;
 
    checkedId: string | null;
    LanguageCheckedId: number | null;
    currentLanguage: LanguageType | null
    ProfileId:number|null
    token:string;
    accountId: string
    isLoading:boolean;
    appLanguageId: number | null;
    appLanguageList: Array<LanguageType>
    selectCountryArray: Array<Country>
    selectLanguageData: Array<LanguageType>;
    defaultCountryCode: string
    FilteredCountryData: Array<Country>
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LanguagePageController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    changeAppLanguageId:string=""
    getCountriesId:string=""
    changeunitIdAPICallId=""
    getAppLanguageListId:string=""
    getSpecificAccountId:string=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            selectLanguage: null,

            accountId:'',
            currentLanguage:null,
            isLoading:false,
            token:"",
            selectCountry: null,
            selectCountryCode:null,
            ProfileId:null,
            appLanguageId:null,
            checkedId: null,
            appLanguageList:[],
            LanguageCheckedId: null,
  
            selectLanguageData: [],
            defaultCountryCode:'',
            selectCountryArray: [],
            FilteredCountryData: [],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const token = localStorage.getItem('token') ?? ''
        this.getSpecificAccount(token)
        this.getAppLanguageList()
        this.getCountries()
        this.setState({ token: token})
        // this.setState({ FilteredCountryData: this.state.selectCountryArray })
        // Customizable Area End
    }

    // Customizable Area Start

    languageOptions = {
        English: EnglishFlag,
        German: GermanFlag,
        Hungarian: HungarianFlag,
        Romanian: RomanianFlag
      }
   
     receive=async(from: string, message: Message) => {
        runEngine.debugLog('Message Recived', message)
    
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )
    
        if (
          apiRequestCallId &&
          responseJson &&
          apiRequestCallId === this.getSpecificAccountId
        ) {
          this.handleSpecificAccountResponse(responseJson)
        }

        if(apiRequestCallId &&
          responseJson &&
          apiRequestCallId === this.getCountriesId){
            this.handleGetCountriesResponse(responseJson)
          }
    
        if (
          apiRequestCallId &&
          responseJson &&
          apiRequestCallId === this.getAppLanguageListId
        ) {
        this.handleLanguageRespnse(responseJson)
        }
    
        if (
          apiRequestCallId &&
          responseJson &&
          apiRequestCallId === this.changeAppLanguageId
        ) {
          this.handleChangeLanguageResponse(responseJson)
        }
      }


    handleSearchCountry = (searchTerm: string) => {
        const filtered = this.state.selectCountryArray.filter((item: Country) =>
            item.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        this.setState({ FilteredCountryData: filtered });
    };
    
 
    handleLanguageListData = (dataList: Array<LanguageType>) => {
        const currentAppLanguage = dataList.find((item: LanguageType) => item.attributes?.id === this.state.appLanguageId) 
        const appLanguageOptions = dataList.filter((item: LanguageType) => item.attributes?.id !== this.state.appLanguageId)
          this.setState({
            currentLanguage: currentAppLanguage as LanguageType,
            selectLanguageData: appLanguageOptions
          })
      }

      handleGetCountriesResponse=(responseJson:ICountriesResponse)=>{
      if(responseJson?.data){
      this.setState({
        selectCountryArray:responseJson.data,
        FilteredCountryData:responseJson.data
       })
       this.handleCountryListData(responseJson.data)
      }
    }

    handleLanguageRespnse=(responseJson:ILangugeResponse)=>{
      if(responseJson?.data){
        this.setState({
          appLanguageList: responseJson.data
        })
        this.handleLanguageListData(responseJson.data)
      }
    }

    handleChangeLanguageResponse=(responseJson:ISpecificAccountResponse)=>{
       if(responseJson?.data){
        if(this.state.selectLanguage!==null){
          Cookies.set("webAppLanguage",JSON.stringify({name:this.state.selectLanguage,id:this.state.LanguageCheckedId}))
          i18next.changeLanguage(this.state.selectLanguage)
         }
         if(this.state.defaultCountryCode!==this.state.selectCountryCode){
          localStorage.removeItem("updateName")
          this.updateUnitId()
         }
        this.setState({
          LanguageCheckedId: null,
          checkedId:null,
          selectCountryCode:null
        })
        this.getSpecificAccount(this.state.token)
       }
    }

    handleSpecificAccountResponse=(responseJson:ISpecificAccountResponse)=>{
      if(responseJson?.data){
        this.setState({
        isLoading: false,
        accountId: responseJson.data.id,
        selectLanguage:responseJson.data.attributes?.app_language_name,
        ProfileId:responseJson.data.attributes?.profile_id,
        defaultCountryCode:responseJson.data.attributes.app_country.id,
        selectCountryCode:responseJson.data.attributes.app_country.id ,
        appLanguageId: responseJson.data.attributes?.app_language_id,
      })
      if (this.state.appLanguageList.length !== 0) {
        this.handleLanguageListData(this.state.appLanguageList)
      }
      if(this.state.selectCountryArray.length!==0){
        this.handleCountryListData(this.state.selectCountryArray)
      }}
    }


      handleCountryListData=(dataList:Array<Country>)=>{
        const currentAppCountry = dataList.find((item: Country) => item.id == this.state.selectCountryCode) 
        const appCountryOptions = dataList.filter((item: Country) => item.id != this.state.selectCountryCode)

          this.setState({
            selectCountry: currentAppCountry as Country,
            FilteredCountryData: appCountryOptions,
            selectCountryArray:dataList
          })
      }
    
      getCountries = () => {
        this.getCountriesId = sendAPIRequest('account_block/countries', {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        })
      }


      updateUnitId= async () => {
        let profile={
          topic_name: null,
          unitid:null,
          unit_name: null,
        }
        this.changeunitIdAPICallId = await CommonApiCall({
          contentType: "application/json",
          method: "PUT",
          endPoint: 'bx_block_profile/profiles/update_user_profile',
          body:profile
        });
      };


    getSpecificAccount = (token: string) => {
        this.setState({ isLoading: true })
        this.getSpecificAccountId = sendAPIRequest('account_block/accounts/specific_account', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'token': token
          },
        })
      }
    
   

    getAppLanguageList = () => {
        this.getAppLanguageListId = sendAPIRequest('bx_block_language_options/languages', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }


      changeAppLanguage = () => {
        const body = {
          data: {
            attributes: {
              app_language_id: this.state.LanguageCheckedId ?? this.state.appLanguageId, 
              profile_attributes: { 
               app_country_id: this.state.selectCountryCode,
               id: this.state.ProfileId
              }          
            }
          }
        }
        this.changeAppLanguageId = sendAPIRequest(
          `account_block/accounts/${this.state.accountId}`,
          {
            method: 'PUT',
            body: body,
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            },
          }
        )
      }

    // Customizable Area End
    
}