// Customizable Area Start
import React from 'react'
import {
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  styled,
  Snackbar,
} from '@material-ui/core'
import * as Yup from 'yup'
import CreateNewPasswordController, {
  Props,
} from './CreateNewPasswordController'
import { Formik } from 'formik'
import { Error, Visibility, VisibilityOff } from '@material-ui/icons'
import { createNewPassword, imgLock } from '../src/assets'
import {
  ButtonCustom,
  TextInputCustom,
} from '../../../components/src/MaterialTheme'
import Header from '../../../components/src/Header'
import SelectLanguage from '../../../components/src/SelectLanguage'
import { checkedIcon } from '../../email-account-registration/src/assets'
import { withTranslation } from 'react-i18next'
 class CreateNewPassword extends CreateNewPasswordController {
  constructor(props: Props) {
    super(props)
  }

  confirmPasswordSchema = Yup.object({
    password: Yup.string()
      .trim()
      .required(this.props.t('Password is required')),
    passwordConfirmation: Yup.string()
      .trim()
      .required(this.props.t('Password is required'))
      .oneOf([Yup.ref('password'), null], this.props.t('Passwords must match')),
  })

  renderIconShowPassword = (show: boolean) => {
    return show ? (
      <VisibilityOff
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    ) : (
      <Visibility
        style={{
          width: '18px',
          height: '18px',
          color: '#ccc',
        }}
      />
    )
  }

  handleClose = () => {
    this.setState({ showAlert: false })
    if (this.state.popupData.type === 'success') {
      this.props.navigation.navigate('EmailLogin')
    }
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  validatePassword = [
    {
      regex: /^(?=.*\d)/,
      text: this.props.t('One number'),
    },
    {
      regex: /^(?=.*[a-z])/,
      text: this.props.t('One lowercase letter'),
    },
    {
      regex: /^(.{8,})/,
      text: this.props.t('8 characters minimum'),
    },
    {
      regex: /^(?=.*[A-Z])/,
      text: this.props.t('One capital letter'),
    },
    {
      text: this.props.t('Passwords match'),
    },
  ]

  renderValidatePassword = (password: string, passwordConfirmation: string) => (
    <div className="validatePasswordWrapper">
      {this.validatePassword.map((item) => {
        let isValid: boolean
        if (item.regex) {
          isValid = item.regex.test(password)
        } else {
          isValid = password === passwordConfirmation
        }

        return (
          <div
            key={item.text}
            className="validatePasswordItem"
            style={{
              color: isValid ? '#2BCE8C' : '#999999',
              backgroundColor: isValid ? '#E3F9F0' : '#F2F2F2',
            }}
          >
            {isValid && (
              <img
                style={{ marginRight: '8px' }}
                src={checkedIcon}
              />
            )}
            {item.text}
          </div>
        )
      })}
    </div>
  )

  checkValidPassword = (password: string) => {
    const newValidatePassword = this.validatePassword.slice(0, -1)
    let isValid: boolean = true
    for (const item of newValidatePassword) {
      if (!item.regex?.test(password)) {
        isValid = false
        break
      }
    }
    return isValid
  }

  handleShowPassword = () => this.setState({showPassword: !this.state.showPassword })

  handleShowConfirmPassword = () => this.setState({
    showConfirmPassword: !this.state.showConfirmPassword,
  })

  render() {
    return (
      <CreateNewPasswordContainer>
        <Header showGoBackBtn onGoBack={this.handleGoBack} />
        <Container className="contentWrapper" maxWidth="md">
          <div />
          <Box className="content">
            <img
              alt="mail"
              src={createNewPassword}
              className="createPasswordImage"
            />
            <Typography className="createPasswordTitle">
              {this.props.t("Create new password")}
            </Typography>
            <Typography className="createPasswordDesc">
              {this.props.t("Your new password must be different from previous used password.")}
            </Typography>
            <Formik
              data-testId="createNewPassword"
              initialValues={{ password: '', passwordConfirmation: '' }}
              validationSchema={this.confirmPasswordSchema}
              onSubmit={(values) => {
                if (this.checkValidPassword(values.password)) {
                  this.submitCreateNewPassword(values.password)
                }
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values }) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  <TextInputCustom
                    data-testId='password'
                    value={values.password}
                    onChange={handleChange}
                    error={Boolean(touched.password && errors.password)}
                    disabled={!this.state.isOTPValidate || this.state.isLoading}
                    autoComplete="off"
                    name="password"
                    placeholder={this.props.t("Password")}
                    variant="outlined"
                    style={{ marginBottom: '16px' }}
                    type={this.state.showPassword ? 'password' : 'text'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="passwordInput"
                            onClick={this.handleShowPassword}
                            edge="end"
                          >
                            {this.renderIconShowPassword(
                              this.state.showPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled edge="start">
                            <img
                              src={imgLock}
                              style={{ width: '18px', height: '18px' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextInputCustom
                    data-testId='passwordConfirmation'
                    value={values.passwordConfirmation}
                    onChange={handleChange}
                    error={Boolean(
                      touched.passwordConfirmation &&
                        errors.passwordConfirmation
                    )}
                    disabled={!this.state.isOTPValidate || this.state.isLoading}
                    autoComplete="off"
                    name="passwordConfirmation"
                    placeholder={this.props.t("Confirm password")}
                    variant="outlined"
                    type={this.state.showConfirmPassword ? 'password' : 'text'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="passwordConfirmationInput"
                            onClick={this.handleShowConfirmPassword}
                            edge="end"
                          >
                            {this.renderIconShowPassword(
                              this.state.showConfirmPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton disabled edge="start">
                            <img
                              src={imgLock}
                              style={{ width: '18px', height: '18px' }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {values.password.trim().length > 0 &&
                    this.renderValidatePassword(
                      values.password,
                      values.passwordConfirmation
                    )}
                  <ButtonCustom
                    disabled={!this.state.isOTPValidate || this.state.isLoading}
                    variant="contained"
                    type="submit"
                    style={{ marginTop: '92px' }}
                  >
                    {this.props.t("Reset password")}
                  </ButtonCustom>
                </form>
              )}
            </Formik>
          </Box>
          <SelectLanguage style={{ marginBottom: '72px' }} />
        </Container>
        <Snackbar
          open={this.state.showAlert}
          onClose={this.handleClose}
          className="snackBar"
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={2000}
        >
          <Box
            className="snackBarWrapper"
            style={{
              backgroundColor:
                this.state.popupData.type === 'error' ? '#F96855' : '#4BB543',
            }}
          >
            <Error style={{ marginRight: '12px' }} />
            <Typography className="snackBarText">
              {this.state.popupData.text}
            </Typography>
          </Box>
        </Snackbar>
      </CreateNewPasswordContainer>
    )
  }
}

export default withTranslation()(CreateNewPassword)
const CreateNewPasswordContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .contentWrapper': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  '& .content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  '& .createPasswordImage': {
    marginBottom: '48px',
    width: '100px',
    height: 'auto',
  },

  '& .createPasswordTitle': {
    fontSize: '22px',
    fontWeight: '900',
    fontFamily: 'Nunito',
    textAlign: 'center',
  },

  '& .createPasswordDesc': {
    color: '#666666',
    marginTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Quicksand',
    fontWeight: '700',
  },

  '& .formContainer': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '56px',
    alignItems: 'center',
  },

  '& .snackBarWrapper': {
    width: '300px',
    borderRadius: '4px',
    padding: '6px 16px',
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
  },

  '& .snackBarText': {
    marginLeft: '12px',
    fontSize: '16px',
    fontWeight: '500',
    fontFamily: 'Quicksand',
    overflow: 'hidden',
    wordWrap: 'break-word',
  },

  '& .validatePasswordWrapper': {
    marginTop: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '300px',
  },

  '& .validatePasswordItem': {
    padding: '4px 8px',
    marginBottom: '8px',
    marginRight: '10px',
    fontSize: '13px',
    fontWeight: '500',
    fontFamily: 'Quicksand',
    borderRadius: '12px',
  },

  [theme.breakpoints.up('md')]: {
    '& .validatePasswordWrapper': {
      maxWidth: '400px',
    },
  },
}))

// Customizable Area End
