import React from "react"
// Customizable Area Start
import {
    Box,
    Button,
    InputAdornment,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    TextField,
    Typography,
    styled
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LanguagePageController, { Props, LanguageType, Country } from './LanguagePageController.web';
import SideBar from "../../../components/src/Sidebar";
import BottomMenu from "../../../components/src/BottomMenu";
import { searchIcon, skipComunications, skipwrench, skipglobe, checkedIcon, uncheckIcon, backgroundImage } from "./assets";
import NavigateLayout from "../../../components/src/NavigateLayout";
import { withTranslation } from "react-i18next";
// Customizable Area End

class LanguagePage extends LanguagePageController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    // Customizable Area Start

    handleChangeLanguage = (item: LanguageType) => {
        this.setState({ LanguageCheckedId: item.attributes.id, selectLanguage: item.attributes.name })
    }


    handleOnClick = () => {
        this.changeAppLanguage()
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <NavigateLayout menuName='Home'>
                <LanguageContainer>
                    <Box className="mainContentWrapper">
                        <Box className="LanguageBackground">
                            <Box className="LanguageImgWrapper">
                                <Box className="cardWrapper">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100%"
                                    }}>
                                        <div className="back"><ArrowBackIosIcon data-testid ="goBack-btn" onClick={()=>this.props.navigation.goBack()} className="backIcon" /></div>
                                        <div className="skip"><img src={skipwrench} style={{ marginLeft: '-30px' }} /></div>
                                        <div className="languagr-rejoin">{this.props.t('Language & Region')}</div>
                                        <div className="savewrapper"><Button variant="contained" disabled={this.state.LanguageCheckedId == null && this.state.checkedId == null} data-testId="savebtn" onClick={this.handleOnClick} className="save-btn">{t('Save')}</Button></div>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="contentContainer">
                            <Box className="inside_container">
                                <Box className="talk-language-wrapper">
                                    <Typography className="talk-language">{t('Your talk, your language!')}<img src={skipComunications} alt="comunication" className="communication-icon" /></Typography>
                                </Box>
                                <List
                                    component="nav"
                                    className="currentLanguageWrapper"
                                    subheader={
                                        <ListSubheader disableSticky component="div" className="currentLanguageTitle">
                                            {t('Current Language')}
                                        </ListSubheader>
                                    }
                                    >
                                    {this.state.currentLanguage
                                     ? (
                                        <div className="fieldBox">
                                            <ListItem >
                                                <ListItemAvatar>
                                                    <img src={this.languageOptions[this.state.currentLanguage.attributes.name]} />
                                                </ListItemAvatar>
                                                <ListItemText primary={this.state.currentLanguage.attributes.name} className="listItemText" />
                                            </ListItem>
                                        </div>) 
                                        :
                                        <Typography className="noLanguageSelectText">{this.props.t("No language selected!")}</Typography>
                                    }

                                </List>
                                <List
                                    className="changeLanguageWrapper"
                                    subheader={
                                        <ListSubheader disableSticky component="div" className="changeLanguageTitle">
                                            {t('Change Langauge')}
                                        </ListSubheader>
                                    }
                                >
                                    {this.state.selectLanguageData.map((item: LanguageType, index: number) => {
                                        const labelId = `checkbox-list-secondary-label-${item.id}`;
                                        return (
                                            <div className="fieldBox" key={item.id}>
                                                <ListItem  data-testId={`selectLanguage${index}`} button onClick={() => this.handleChangeLanguage(item)}
                                                >
                                                    <ListItemAvatar>
                                                        <img src={this.languageOptions[item.attributes.name]} />
                                                    </ListItemAvatar>
                                                    <ListItemText id={labelId} primary={item.attributes.name} className="listItemText" />
                                                    <ListItemSecondaryAction data-Testid={`selectlanguageIcon${index}`} onClick={() => this.handleChangeLanguage(item)}>
                                                        {item.attributes.id == this.state.LanguageCheckedId ? <img src={checkedIcon} alt="checkicon" /> : <img src={uncheckIcon} alt="uncheck" />}
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </div>
                                        );
                                    })}
                                </List>
                                <Box className="talk-language-wrapper">
                                    <Typography className="talk-language">{t('Where in the world are you?')}<img src={skipglobe} alt="comunication" className="communication-icon" /></Typography>
                                </Box>
                                <List
                                    component="nav"
                                    className="currentLanguageWrapper"
                                    subheader={
                                        <ListSubheader disableSticky component="div" className="currentLanguageTitle">
                                            {t('Current Country')}
                                        </ListSubheader>
                                    }
                                >
                                    {this.state.selectCountry ? <div className="fieldBox">
                                        <ListItem >
                                            <ListItemAvatar>
                                                <img
                                                    alt={this.state.selectCountry.attributes.alpha2}
                                                    src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${this.state.selectCountry.attributes.alpha2}.svg`}
                                                    className="flagIcon"
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={this.state.selectCountry.attributes.name} className="listItemText" />
                                        </ListItem>
                                    </div> :
                                        <Typography className="noLanguageSelectText">{this.props.t("No country selected!")}</Typography>
                                    }

                                </List>
                                <List
                                    className="changeLanguageWrapper1"
                                    subheader={
                                        <ListSubheader disableSticky component="div" className="changeLanguageTitle">
                                            {t('Change Country')}
                                        </ListSubheader>
                                    }
                                >
                                    <ListItem >

                                        <TextField name='Search' className='search-field' data-testId='search' placeholder={t('Search')}
                                            variant="outlined"
                                            onChange={(e) => this.handleSearchCountry(e.target.value)}
                                            fullWidth
                                            InputProps={{
                                                style: (
                                                    { color: 'black' }
                                                ),
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <img src={searchIcon} alt='searchIcon' />
                                                    </InputAdornment>
                                                ),
                                            }
                                            } />
                                    </ListItem>
                                    <div style={{ height: '25vh', overflow: 'auto' }}>
                                        {this.state.FilteredCountryData.map((value: Country, index: number) => {
                                            const labelId = `checkbox-list-secondary-label-${index}`;
                                            return (
                                                <div className="fieldBox" key={value.id}>
                                                    <ListItem data-testId={`selectCountry${index}`}  button onClick={() => {
                                                        this.setState({ checkedId: value.id, selectCountryCode: value.id.toString() })
                                                    }
                                                    }>
                                                        <ListItemAvatar>
                                                            <img
                                                                alt={value.attributes.alpha2}
                                                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value.attributes.alpha2}.svg`}
                                                                className="flagIcon"
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText id={labelId} primary={value.attributes.name} className="listItemText" />
                                                        <ListItemSecondaryAction data-testId={`selectcountryIcon${index}`} onClick={() => this.setState({ checkedId: value.id, selectCountryCode: value.id.toString() })}>
                                                            {value.id == this.state.checkedId ? <img src={checkedIcon} alt="checkicon" /> : <img src={uncheckIcon} alt="uncheck" />}
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </div>
                                            );
                                        })}
                                        {this.state.FilteredCountryData.length === 0 && <ListItem>{t("Not  Found")}</ListItem>}
                                    </div>
                                </List>
                            </Box>
                        </Box>
                        <BottomMenu />
                    </Box>
                </LanguageContainer>
            </NavigateLayout>

            // Customizable Area End
        )
    }

}
// Customizable Area Start
export default withTranslation()(LanguagePage)
const LanguageContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#00AEFF',
    height: '100%',
    width: '100%',

    '& .mainContentWrapper': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    '& .LanguageBackground': {
        backgroundColor: '#fff',
    },
    '& .flagIcon': {
        marginRight: "2rem"
    },

    '& .LanguageImgWrapper': {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00AEFF',
        borderBottomRightRadius: '20px',
        paddingTop: '72px',
        backgroundImage: `url(${backgroundImage})`,

    },

    '& .contentContainer': {
        flex: '6',
        backgroundColor: '#fff',
        // overflow: 'auto',
        width: "100%",
        padding: '0px 24px 100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    '& .currentLanguageWrapper': {
        width: '100%',
        maxWidth: 600,
        borderRadius: '12px 12px 0px 0px',
        paddingBottom: '0px',
        border: '2px solid #e6e6e6',
    },

    '& .changeLanguageWrapper': {
        width: '100%',
        overflow: 'auto',
        maxWidth: 600,
        height: 263,
        paddingBottom: '0px',
        border: '2px solid #e6e6e6',
        borderRadius: '0px 0px 12px 12px'
    },
    '& .changeLanguageWrapper1': {
        width: '100%',
        overflow: 'auto',
        maxWidth: 600,
        paddingBottom: '0px',
        border: '2px solid #e6e6e6',
        borderRadius: '0px 0px 12px 12px'
    },
    '& .currentLanguageTitle': {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        textTransform: 'uppercase'
    },
    '& .noLanguageSelectText': {
        marginLeft: '22px',
        fontFamily: 'Quicksand',
        fontWeight: '500',
        marginTop: '10px',
        fontSize: '16px',
        color: '#363636'
    },

    "& .changeLanguageTitle": {
        color: '#999999',
        fontSize: '12px',
        fontFamily: 'Quicksand',
        fontWeight: '700',
        backgroundColor: '#e6e6e6',
        textTransform: 'uppercase'
    },

    '& .listItemText': {
        '& span': {
            fontSize: '16px',
            fontStyle: 'normal',
            lineHeight: '12px',
            fontFamily: 'Quicksand',
            fontWeight: '500',
        },
    },
    '& .fieldBox': {
        minHeight: "40px",
        borderRadius: "10px",
        border: "2px solid var(--primary-neutrals-warm-gray-100, #E6E6E6)",
        background: "var(--primary-neutrals-warm-gray-10, #F7F7F7)",
        margin: '22px 25px 23px 18px'
    },
    '& .fieldBox div, .fieldBox li ': {

    },
    '& .communication-icon': {
        margin: '0px 6px'
    },
    "& .skip": {
        width: '50px'
    },

    "& .cardWrapper": {
        width: '100%',
        "@media (min-width:960px)": {
            width: "393px",
            height: "150px",
            borderRadius: "20px",
            background: "#FFF",
            backdropFilter: "blur(2px)",
            margin: '10px',
            display: 'flex',
            alignItems: 'end',
        }
    },
    "& .talk-language": {
        display: 'flex',
        color: "#666",
        fontFamily: "Nunito",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "0.144px"
    },
    "& .talk-language-wrapper": {
        width: "100%",
        maxWidth: '600px',
        margin: "30px 0px 29px 35px"
    },

    "& .inside_container": {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },

    "& .search-field": {
        background: "#E6E6E6",
        borderRadius: "8px",
        height: '32px',
        margin: '22px 9px 0px 2px',
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: '8px !important',
            borderWidth: '0px !important',
            width: 'auto !important'
        },
        "& .MuiOutlinedInput-input": {
            padding: '6px 0 7px'
        }
    },
    "& .backIcon": {
        display: 'none',
        "@media (max-width:959px)": {
            display: "flex",
            margin: "5px 0px 0px 20px",
            width: "16px",
            height: "16px",
            cursor: 'pointer',
            color: 'white'
        }
    },
    "& .languagr-rejoin": {
        color: "var(--primary-blue-white, #FFF)",
        textAlign: "center",
        fontFamily: "Nunito",
        fontSize: "22px",
        padding:'8px',
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "26px",
        letterSpacing: "0.176px",
        "@media (min-width:960px)": {
            color: "#666",
        }
    },
    "& .save-btn": {
        //  width: "70px",
        height: "30px",
        flexShrink: 0,
        borderRadius: "10px",
        backgroundColor: '#36BFFF',
        boxShadow: '0px 3px #00AEFF',
        color: "#fff",
        textAlign: "center",
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Nunito",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "12px",
        letterSpacing: "0.8px",
        marginRight: '10px',
        "@media (min-width:960px)": {
            color: "#666",
        }
    },
    '& .save-btn > .MuiButton-label': {
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        textTransform: 'capitalize',
        lineHeight: '12px', /* 85.714% */
        letterspacing: '0.8px',
    },
    [theme.breakpoints.up('md')]: {
        '& .mainContentWrapper': {
            width: `calc(100% - 240px)`,
            marginLeft: '240px',
            flexDirection: 'row',
        },

        '& .contentContainer': {
            paddingTop: '90px',
            overflow: 'auto'
        },

        '& .LanguageBackground': {
            flex: 5,
        },

        '& .LanguageImgWrapper': {
            paddingTop: 'unset',
        },
        '& .save-btn': {
            color: 'white !important'
        },
        '& .currentLanguageWrapper, .changeLanguageWrapper1': {
            maxWidth: 450,
        },

        '& .currentLanguageWrapper, .changeLanguageWrapper': {
            maxWidth: 450,
        },
    },

    [theme.breakpoints.up('lg')]: {
        '& .mainContentWrapper': {
            width: `calc(100% - 282px)`,
            marginLeft: '282px',
        },

        '& .currentLanguageWrapper, .changeLanguageWrapper': {
            maxWidth: 600,
        },
        '& .currentLanguageWrapper, .changeLanguageWrapper1': {
            maxWidth: 600,
        },
    },
    '& .savewrapper > .Mui-disabled': {
        color: '#ccc !important',
        backgroundColor: '#f7f7f7 !important',
        boxShadow: '0px 3px #ccc !important',
    },


}))
// Customizable Area End