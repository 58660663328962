import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { algebra, gradeIcon } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  t?:any;
  selectedGrade?:any;
  gradesData?:any
  openCategories?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  graph:{}
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
function flattenData(data: any) {
  const flattenedData: any = [];

  function flatten(item: any) {
    flattenedData.push({
      id: item.id,
      label: item.label,
      font:item.font,
      image: item.image,
      size: item.size,
      // type: item.type,
      shape: item.shape,
    });

    if (item.child && Array.isArray(item.child)) {
      item.child.forEach(flatten);
    }

  }
  data.forEach(flatten);
  return flattenedData;
}


function createEdges(node: any): any {
  const edges = [];
  if (node?.child && node?.child.length > 0) {
    for (const childNode of node.child) {
      edges.push({ from: node.id, to: childNode.id });
      edges.push(...createEdges(childNode));
    }
  }
  return edges;
}
// Customizable Area End



export default class MaterialGradeListController extends BlockComponent<
  Props,
  S,
  SS
> {
    // Customizable Area Start
    getMaterials=""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
          graph: {
            nodes: [],
            edges: [],
          },    
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    // Customizable Area Start

    componentDidMount(): any{
      this.handleGrade()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
       if(prevProps.gradesData!=this.props.gradesData){
         this.handleGrade()
    }
  }


     handleGrade=()=>{

      const gradeData={
        label:"Grade",
        image:gradeIcon,
        type:'grade',
        shape: "circularImage",
        size: 25,
        id:'1233445'
      }
      const gradListNode= this.props.gradesData.map((item:any)=>{
       return{
        label:item?.attributes?.name,
        image:item?.attributes?.image||algebra,
        type:item.type,
        shape: "circularImage",
        size: 25,
        id:JSON.stringify(item)
      }
      })
      const subData = [{...gradeData, child: gradListNode }]
      this.generateNewNodesAndEdges(subData)
     }
    

    generateNewNodesAndEdges = (newNodesData: any) => {
      const newNodes = newNodesData;
      const newEdges = createEdges(newNodes[0]);
      const nodesNew = flattenData(newNodes);

      this.setState((prevState: any) => ({
        graph: {
          edges: newEdges,
          nodes: nodesNew,
        },
      }));
    };


    selectGrade=({ nodes, edges }: any)=>{
      const data = JSON.parse(nodes)
      if(data?.type ==='grade_data'){
      this.props.openCategories(data)
      }
    }

    // Customizable Area End
}